import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import productContext from "pages/Products/BusinessProducts/product";
import axios from "axios";
import getHeader from "config/header";
import * as url from "./../../helpers/url_helper";

const BusinessunitSelect = () => {
    
    const {onData} = useContext(productContext)

    const location = useLocation();
  
    const [businessUnit, setBusinessUnit] = useState([])
  
    useEffect(() => {
      const token = sessionStorage.getItem('authUser')
      axios.get(url.API_BASE_URL+url.APP_BUSINESS_UNITS_URL, getHeader(token))
        .then((res) => {
            setBusinessUnit(res.data.result)
        }).catch(err => {
          console.log(err);
        })
    }, [])

  
    return (
      <React.Fragment>
        <Col lg="8">
          {location.pathname !== "/dashboard" &&
            <select onInput={(e) => onData(e.target.value)} defaultValue="" className="form-select d-inline" aria-label="Default select example">
              <option value="">Business Unit</option>
              {businessUnit.map(busUnit => (
                <option key={busUnit.app_businessunit_id} value={busUnit.app_businessunit_id}>{busUnit.app_business_unit_name}</option>
              ))}
            </select>}
        </Col>
      </React.Fragment>
    )


}

export default BusinessunitSelect