import axios from 'axios'
import getHeader from 'config/header'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, Row, Table } from "reactstrap"
import { getBusinessProducts } from 'store/actions'
import TableContainer from './TableContainer'
import { ProductID, POSItemCode, ProductName, ProductType, CouponNo } from 'pages/Sales/Opportunities/ProposalListCol'

const CouponListModal = ({ show, onCloseClick, data }) => {


    const dispatch = useDispatch();
    const { handleSubmit, register, getValues, unregister, setValue, formState: { errors }, reset } = useForm()

    // const [products, setProducts] = useState([])
    // const businessProducts = useSelector(state => state.BusinessProducts);

    // useEffect(() => {
    //     dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: "", skip: 0 }));
    // }, [])

    // useEffect(() => {
    //     if (businessProducts.products !== null) {
    //         setProducts(businessProducts.products.result);
    //     }
    // }, [businessProducts])

    const selectedBusinessUnits = (data) => {
        onDataSelect(data);
        reset()
    };

    useEffect(() => {
        if (data !== undefined) {
            // console.log("qww",data);
            setValue("app_account_keyid", data.app_account_keyid)
            setValue("credit_limit", data.credit_limit)
            setValue("account_balance", data.account_balance)
            setValue("oracle_site_ID", data.oracle_site_ID)
            if (data.oracle_credit_status === true) {
                setValue("oracle_credit_status", "active")
            } else if (data.oracle_credit_status === false) {
                setValue("oracle_credit_status", "inactive")
            }
            if (data.account_status === true) {
                setValue("account_status", "active")
            } else if (data.account_status === false) {
                setValue("account_status", "inactive")
            }
        }
    }, [data])


    const [couponList, setCouponList] = useState([
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CMDFAG356HGF"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CLKJHGRS5SJ"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CERT3453292CN"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CSYER3478HJC"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CLSHD6BE98D"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CKJDSHRE6EJW7"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CSDKJDS7632J"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"C37443H87WLK"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"C9H4D9D6TDH"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CJD63NJE7R9F"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no":"CMNR58RE3SE"
        },

    ])

    
    const proposalMultiColumn = useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: (row, index) => index + 1,
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Product ID',
                accessor: 'app_product_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductID  {...cellProps} />;
                }
            },
            {
                Header: 'Product Name',
                accessor: 'app_product_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductName {...cellProps} />;
                }
            },
            {
                Header: 'POS Item Code ',
                accessor: 'app_product_pos_item_code',
                filterable: true,
                Cell: (cellProps) => {
                    return <POSItemCode {...cellProps} />;
                }
            },
            {
                Header: 'Product Type',
                accessor: 'product_type',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductType {...cellProps} />;
                }
            },
            {
                Header: 'Coupon No',
                accessor: 'coupon_no',
                filterable: true,
                Cell: (cellProps) => {
                    return <CouponNo {...cellProps} />;
                }
            },

        ], []
    );


    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true} size='xl'>
            <Form onSubmit={handleSubmit(selectedBusinessUnits)}>
                <ModalBody className="py-5 px-2">
                    <div className="mt-2 mb-2" style={{ maxHeight: "500px", overflowY: "auto" }}>
                        <h3 className='d-flex justify-content-center mb-2'>View Coupon List </h3>
                        <TableContainer
                            columns={proposalMultiColumn}
                            data={couponList}
                            customPageSize={10}
                            className="custom-header-css"
                        />
                    </div>
                    <Row>
                        <Label lg="3" md="3" />
                        <Col lg="7" md="7">
                            <div className="text-center mt-3">
                                <button
                                    type="button"
                                    className="btn btn-danger btn-md ms-2"
                                    onClick={onCloseClick}
                                >
                                    Back
                                </button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Form>
        </Modal>
    )
}

export default CouponListModal

