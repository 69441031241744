import {
      
    GET_PRICING_MODELS_SUCCESS,
    GET_PRICING_MODELS_FAIL,
    GET_PRICING_MODELS,

    GET_PRICING_MODEL,
    GET_PRICING_MODEL_SUCCESS,
    GET_PRICING_MODEL_FAIL,
  
    POST_PRICING_MODEL,
    POST_PRICING_MODEL_SUCCESS,
    POST_PRICING_MODEL_FAIL,
  
    DELETE_PRICING_MODEL,
    DELETE_PRICING_MODEL_SUCCESS,
    DELETE_PRICING_MODEL_FAIL,
    
    UPDATE_PRICING_MODEL_SUCCESS,
    UPDATE_PRICING_MODEL_FAIL,
    UPDATE_PRICING_MODEL,
  } from "./actionTypes";
  
  /////////////////////////
  //  Get Business Unit  //
  /////////////////////////
  
  export const getPricingmodels = (payload) => ({
    type: GET_PRICING_MODELS,
    payload: payload
  });
  
  export const getPricingmodelsSuccess = products => ({
    type: GET_PRICING_MODELS_SUCCESS,
    payload: products,
  });
  
  export const getPricingmodelsFail = error => ({
    type: GET_PRICING_MODELS_FAIL,
    payload: error,
  });
  
  //////////////////////////////
  // Get By id Business Unit  //
  //////////////////////////////
  
  export const getPricingmodel = (payload) => ({
    type: GET_PRICING_MODEL,
    payload: payload
  });
  
  export const getPricingmodelSuccess = businessUnit => ({
    type: GET_PRICING_MODEL_SUCCESS,
    payload: businessUnit,
  });
  
  export const getPricingmodelFail = error => ({
    type: GET_PRICING_MODEL_FAIL,
    payload: error,
  });
  
  ////////////////////////
  // Post Business Unit///
  ////////////////////////
  
  export const postPricingmodel = (payload) => ({
    type: POST_PRICING_MODEL,
    payload: payload
  });
  
  export const postPricingmodelSuccess = products => ({
    type: POST_PRICING_MODEL_SUCCESS,
    payload: products,
  });
  
  export const postPricingmodelFail = error => ({
    type: POST_PRICING_MODEL_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Delete Business Unit //
  //////////////////////////
  
  export const deletePricingmodel = (payload) => ({
    type: DELETE_PRICING_MODEL,
    payload: payload
  });
  
  export const deletePricingmodelSuccess = () => ({
    type: DELETE_PRICING_MODEL_SUCCESS,
  });
  
  export const deletePricingmodelFail = error => ({
    type: DELETE_PRICING_MODEL_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Update Business Unit  //
  ///////////////////////////
  
  export const updatePricingmodel = (payload) => ({
    type: UPDATE_PRICING_MODEL,
    payload: payload
  });
  
  export const updatePricingmodelSuccess = payload => ({
    type: UPDATE_PRICING_MODEL_SUCCESS,
    payload: payload
  });
  
  export const updatePricingmodelFail = error => ({
    type: UPDATE_PRICING_MODEL_FAIL,
    payload: error,
  });
  
  
  
  