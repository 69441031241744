import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { logoutUser } from "../../store/actions"
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux"

const SecondaryLogout = props => {
    const history = useHistory()
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logoutUser(props.history))
    }, [dispatch])

    const handleLogout = () => {
        sessionStorage.removeItem("authUser");
        sessionStorage.removeItem("userCred");
        sessionStorage.removeItem("userName");
        history.push('/secondaryLogin')
    };

    useEffect(() => {
        handleLogout()
    }, [])

    return <></>
}

SecondaryLogout.propTypes = {
    history: PropTypes.object,
}

export default withRouter(SecondaryLogout)
