import {
  GET_APP_COUPON_GENERATE,
  GET_APP_COUPON_GENERATE_FAIL,
  GET_APP_COUPON_GENERATE_SUCCESS,

  POST_APP_COUPON_GENERATE,
  POST_APP_COUPON_GENERATE_FAIL,
  POST_APP_COUPON_GENERATE_SUCCESS,



} from "./actionTypes";

const INIT_STATE = {
  getcouponGenerate: null,
  createcouponGenerate: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const CouponGenerateReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APP_COUPON_GENERATE:
      return {
        ...state,
        loading: true,
        getcouponGenerate: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_GENERATE_SUCCESS:
      return {
        ...state,
        loading: false,
        getcouponGenerate: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_GENERATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
 
    case POST_APP_COUPON_GENERATE:
      return {
        ...state,
        loading: true,
        createcouponGenerate: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_APP_COUPON_GENERATE_SUCCESS:
      return {
        ...state,
        loading: false,
        createcouponGenerate: action.payload,
        isCreated: true,
        isDeleted: false,
        isUpdated: false
      };
    case POST_APP_COUPON_GENERATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default CouponGenerateReducer;


