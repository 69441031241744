import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Input, FormFeedback, Label, Form, } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
// Column
import { UserId, UserRoleID, UserRoleName, Status, CreatedDate, LastUpdated, UserRoleDesc } from './RoleCol';
import axios from "axios";
import getHeader from "config/header";
import { toast } from "react-toastify";
import expiredSessionHandler from "common/expiredSessionHandler";
import { getSysRoles, deleteAppSysRoles } from "store/administration/portalRole/actions"
import { getSysCredentials } from "store/credentials/sysUserCredentials/actions"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Role = props => {

  document.title = "Roles | Maxims B2B Portal";

  const history = useHistory()
  const dispatch = useDispatch();

  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const pageStatus = query.get("status")


  const [permissions, setPermissions] = useState('')
  const [roles, setRoles] = useState([])
  const [pageCount, setPageCount] = useState(1);
  const sysRolesState = useSelector(state => state.AppSysRoles)


  useEffect(() => {
    setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    dispatch(getSysCredentials())
  }, [])

  

  useEffect(() => {
    if (sysRolesState.appSysRoles !== null) {
      setRoles(sysRolesState.appSysRoles.result);
      setPageCount(Math.ceil(sysRolesState.appSysRoles.summary.filteredsize / pageLimit) == 0 ? 1
        : Math.ceil(sysRolesState.appSysRoles.summary.filteredsize / pageLimit)
      );
    }
    if (sysRolesState.isDeleted) {
      setDeleteModal(false);
      handlePageClick(page);
    }
    if (sysRolesState.error) {
      setDeleteModal(false);
    }
  }, [sysRolesState])

  //=======================global filter ============================//
  const [customFilterData, setCustomFilterData] = useState("")

  useEffect(() => {
    // dispatch(getSysRoles({ filter: `{"is_active":${pageStatus}}`, limit: 5, skip: 0 }));
    handlePageClick()
  }, [pageStatus, pageLimit, page, customFilterData])

  useEffect(() => {
    if (customFilterData.length > 0) {
      history.push(`/userRole?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
    }
  }, [customFilterData])

  const handlePageClick = (index) => {
    let filter = `{"is_active":${pageStatus},"$or":[
      {"sys_role_keyid":{"$regex":"${customFilterData}","$options":"i"}},
      {"sys_role_name":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`
    let skips = 0;
    // setPage(page);
    if (page > 0) {
      skips = page * pageLimit;
      dispatch(getSysRoles({ filter: filter, limit: pageLimit, skip: skips }));
    }
    else {
      dispatch(getSysRoles({ filter: filter, limit: pageLimit, skip: 0 }));
    }
  }

  // Company Column
  const columns = useMemo(
    () => [
      {
        Header: 'Role ID',
        accessor: 'sys_role_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <UserRoleID {...cellProps} />;
        }
      },
      {
        Header: 'Role Name',
        accessor: 'sys_role_name',
        filterable: true,
        Cell: (cellProps) => {
          return <UserRoleName {...cellProps} />;
        }
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
          return (
            // <div className="d-flex gap-3">
            //   {(permissionAction.includes("system.administration.portalroles.read")) ?
            //     <>
            //       <button
            //         onClick={() => {
            //           history.push(`/updateRole/${cellProps.row.original.sys_role_id}`)
            //         }} className="btn btn-success btn-sm">View</button>
            //     </> : <div className="text-muted">No Access</div>
            //   }
            // </div>
            <div className="d-flex gap-3">
              {(permissionAction.includes("system.administration.portalroles.read")) ?
                <>
                  <button
                    onClick={() => {
                      history.push(`/viewRole/${cellProps.row.original.sys_role_id}`)
                    }} className="btn btn-success btn-sm">View</button>
                </> : <div className="text-muted">No Access</div>
              }
              {(permissionAction.includes("system.administration.portalroles.read")) && (permissionAction.includes("system.administration.portalroles.update")) ||
                (permissionAction.includes("system.administration.portalroles.read")) && (permissionAction.includes("system.administration.portalroles.delete"))
                ?
                <>
                  <button
                    onClick={() => {
                      history.push(`/updateRole/${cellProps.row.original.sys_role_id}`)
                    }} className="btn btn-info btn-sm">Edit</button>
                </> : <div className="text-muted">No Access</div>
              }

            </div>

          );
        }
      },
    ],
    [roles]
  );


  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [roleTBD, setRoleTBD] = useState('')

  const onClickDelete = (sysRole) => {
    setRoleTBD(sysRole)
    setDeleteModal(true)
  }

  const handleDeleteSysRole = () => {
    if (roleTBD.sys_role_id) {
      const data = {
        businessUnitId: roleTBD.sys_role_id
      };
      dispatch(deleteAppSysRoles(data));
    }
  }



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSysRole}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {permissions.includes("system.administration.portalroles.read") ? <Container fluid>
          <Breadcrumbs title="Roles Management" breadcrumbItem="Portal" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={roles}
                    isGlobalFilter={true}
                    className="custom-header-css"
                    customPageCount={pageCount}
                    isRolesList={permissions.includes("system.administration.portalroles.write") ? true : false}
                    customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                  // handleCompanyClick={handleCompanyClick}
                  // customPageSize={10}
                  // customPageStatus={(data) => setPageStatus(data)}
                  // handlePageClick={handlePageClick}
                  // customPageIndex={page}
                  // pageSizeTransfer={(data) => setPageLimit(data)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
          :
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                      <div>
                        <h1><i className='bx bxs-lock'></i></h1>
                        <h3>Access Denied</h3>
                        <div className="text-muted">You don't have permission to access this module</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
    </React.Fragment>
  );
};


export default Role;
