import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import getHeader from 'config/header';
import React, { useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

export const azureAuthenticate = () => {
    const { instance } = useMsal();
    const history = useHistory()
    const activeAccount = instance.getActiveAccount();

    const [logoutBtnShow, setLogOutBtnShow] = useState(false)

    setTimeout(() => {
        setLogOutBtnShow(true)
    }, 12000);

    const handleLogout = () => {
        sessionStorage.removeItem("authUser");
        instance.logout()
    };

    const getUserToken = (azureToken) => {
        const BodyData = { ssotoken: azureToken }
        axios.post("/api/v2/auth/ssotoken", BodyData)
            .then((res) => {
                sessionStorage.removeItem("azureToken");
                const token = res.data.token_string
                if (token) {
                    axios.get('/api/v2/sysusers/profile', getHeader(token))
                        .then(profileRes => {
                            let userID = profileRes.data.sys_user_id
                            axios.get('/api/v2/sysusers/' + userID, getHeader(token))
                                .then(userRes => {
                                    let roleID = userRes.data.sys_user_roles
                                    let value = []
                                    roleID.forEach((id) => {
                                        axios.get(`/api/v2/sysroles/${id}/credentials`, getHeader(token))
                                            .then((response) => {
                                                let credentialsData = response.data.credentials
                                                credentialsData.map((items) => (
                                                    value.push(items.credential)
                                                ))
                                                sessionStorage.setItem("authUser", token)
                                                sessionStorage.setItem('userName', userRes.data.sys_user_name)
                                                sessionStorage.setItem('userCred', JSON.stringify(value))
                                                history.push('/dashboard?skip=0&&limit=10&&transactionStatus=all')
                                            })
                                            .catch((error) => {
                                                console.error(error);
                                                toast.error("Failed to login")
                                                // setButtonState(true)
                                                $('#loginBtn').attr("disabled", false)
                                            });
                                    });
                                }).catch(userErr => {
                                    console.log(userErr);
                                    toast.error("Failed to login")
                                    // setButtonState(true)
                                    $('#loginBtn').attr("disabled", false)
                                })
                        }).catch(profileErr => {
                            console.log(profileErr);
                            toast.error("Failed to login")
                            // setButtonState(true)
                            $('#loginBtn').attr("disabled", false)
                        })
                }
            })
            .catch((err) => {
                toast.error(err.response.data.ErrorDetail)
                setTimeout(() => {handleLogout()},3000)
                // history.push('/login')
            })
    }

    useEffect(() => {
        const azureToken = sessionStorage.getItem("azureToken")
        if (azureToken && azureToken != null)
            getUserToken(azureToken)
    }, [sessionStorage.getItem("azureToken")])

 

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh", marginTop: "-10vh" }}>
            <div className='text-center'>
                <div className='d-flex justify-content-center'>
                    <Oval
                        ariaLabel="loading-indicator"
                        height={100}
                        width={100}
                        strokeWidth={5}
                        strokeWidthSecondary={1}
                        color="blue"
                        secondaryColor="white"
                    />
                </div>
                <p className='azure-helper'>Authenticating.. Please Wait..</p>
                {activeAccount && logoutBtnShow && <button className='btn btn-primary' onClick={handleLogout}> Logout and Login again</button>}
            </div>
        </div>
    )
}
