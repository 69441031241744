import PropTypes from "prop-types";
import React, { useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, socialLogin } from "../store/actions";
import profile from "assets/images/profile-img.png";
import logo from "assets/images/maxims/Maxims-logo.png";
import { toast } from "react-toastify";
import { facebook, google } from "../config";
import getHeader from "config/header";
import { useState } from "react";
import * as url from "./../helpers/url_helper";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';


const SecondaryLogin = props => {

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

//  const handleRedirect = () => {
//     instance
//       .loginRedirect({
//         ...loginRequest,
//         prompt: 'create',
//       })
//       .catch((error) => console.log(error));
//   };

//   useEffect(() => {
//     setTimeout(() => {
//       handleRedirect()
//     }, 1)
//   }, [])

  const history = useHistory()
  document.title = "Login | B2B Maxims";
  const dispatch = useDispatch();
  const [buttonState, setButtonState] = useState(true)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      setButtonState(false)
      $('#loginBtn').attr("disabled", true)
      const headerData = {
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: process.env.REACT_APP_SCOPE,
        username: values.email,
        password: values.password
      }
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(headerData),
        url: url.API_BASE_URL+url.AUTH_TOKEN
      }
      
      axios(options)
        .then(res => {
          const token = res.data.access_token
          if (token) {
            axios.get(url.API_BASE_URL + url.SYSUSER_PROFILE, getHeader(token))
              .then(profileRes => {
                let userID = profileRes.data.sys_user_id
                axios.get(url.API_BASE_URL+url.SYS_USER_URL+"/"+ userID, getHeader(token))
                  .then(userRes => {
                    let roleID = userRes.data.sys_user_roles
                    let value = []
                    roleID.forEach((id) => {
                      axios.get(url.API_BASE_URL+url.SYS_ROLE_URL+'/'+id+'/credentials', getHeader(token))
                        .then((response) => {
                          let credentialsData = response.data.credentials
                          credentialsData.map((items) => (
                            value.push(items.credential)
                          ))
                          sessionStorage.setItem("authUser", token)
                          sessionStorage.setItem('userName', userRes.data.sys_user_name)
                          sessionStorage.setItem('userCred', JSON.stringify(value))
                          history.push('/dashboard?skip=0&&limit=10&&transactionStatus=all')
                        })
                        .catch((error) => {
                          console.error(error);
                          toast.error("Failed to login")
                          setButtonState(true)
                          $('#loginBtn').attr("disabled", false)
                        });
                    });
                  }).catch(userErr => {
                    console.log(userErr);
                    toast.error("Failed to login")
                    setButtonState(true)
                    $('#loginBtn').attr("disabled", false)
                  })
              }).catch(profileErr => {
                console.log(profileErr);
                toast.error("Failed to login")
                setButtonState(true)
                $('#loginBtn').attr("disabled", false)
              })
          }
        }).catch(err => {
          toast.error("Invalid Credentials !!")
          console.log(err);
          setButtonState(true)
          $('#loginBtn').attr("disabled", false)
        })
    }
  });


  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome !</h5>
                        <p>Sign in to Maxims B2B Portal.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title bg-transparent">
                          <img
                            src={logo}
                            alt=""
                            height="54"
                            style={{ paddingLeft: "45px", marginTop: "-20px" }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2 ">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          id="loginBtn"
                        >
                          {buttonState ? "Log In" : <>Please Wait <i className='bx bx-loader-alt bx-spin'></i></>}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                  <div className="p-2 text-center">
                    {/* <WrappedView /> */}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Maxims B2B Portal.
                </p>
              </div>
            </Col>
          </Row>
        </Container>


      </div>
    </React.Fragment>
  );
};

export default withRouter(SecondaryLogin);

SecondaryLogin.propTypes = {
  history: PropTypes.object,
};
