export const GET_CONTACTS = "GET_CONTACTS"
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS"
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL"

export const POST_CONTACT = "POST_CONTACT"
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS"
export const POST_CONTACT_FAIL = "POST_CONTACT_FAIL"

export const DELETE_CONTACT = "DELETE_CONTACT"
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS"
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL"

export const GET_CONTACT = "GET_CONTACT"
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS"
export const GET_CONTACT_FAIL = "GET_CONTACT_FAIL"

export const GET_ALL_CONTACT = "GET_ALL_CONTACT"
export const GET_ALL_CONTACT_SUCCESS = "GET_ALL_CONTACT_SUCCESS"
export const GET_ALL_CONTACT_FAIL = "GET_ALL_CONTACT_FAIL"

export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS"
export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL"