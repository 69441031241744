export const GET_SYS_ROLES = "GET_SYS_ROLES"
export const GET_SYS_ROLES_SUCCESS = "GET_SYS_ROLES_SUCCESS"
export const GET_SYS_ROLES_FAIL = "GET_SYS_ROLES_FAIL"

export const POST_SYS_ROLES = "POST_SYS_ROLES"
export const POST_SYS_ROLES_SUCCESS = "POST_SYS_ROLES_SUCCESS"
export const POST_SYS_ROLES_FAIL = "POST_SYS_ROLES_FAIL"

export const DELETE_SYS_ROLES = "DELETE_SYS_ROLES"
export const DELETE_SYS_ROLES_SUCCESS = "DELETE_SYS_ROLES_SUCCESS"
export const DELETE_SYS_ROLES_FAIL = "DELETE_SYS_ROLES_FAIL"

export const GET_SYS_ROLE = "GET_SYS_ROLE"
export const GET_SYS_ROLE_SUCCESS = "GET_SYS_ROLE_SUCCESS"
export const GET_SYS_ROLE_FAIL = "GET_SYS_ROLE_FAIL"

export const UPDATE_SYS_ROLES = "UPDATE_SYS_ROLES"
export const UPDATE_SYS_ROLES_SUCCESS = "UPDATE_SYS_ROLES_SUCCESS"
export const UPDATE_SYS_ROLES_FAIL = "UPDATE_SYS_ROLES_FAIL"

