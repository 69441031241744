import { takeEvery, put, call } from "redux-saga/effects"
import {
    getContactssSuccess, getContactssFail,
    getContactSuccess, getContactFail,
    getAllContactSuccess, getAllContactFail,
    postContactSuccess, postContactFail,
    deleteContactSuccess, deleteContactFail,
    updateContactSuccess, updateContactFail,
} from "./actions"

// Api Handler 
import {
    getContacts,
    getContact,
    getAllContact,
    postContact,
    updateContact,
    deleteContact,
} from "helpers/backendApi_helpers";

import {
    GET_CONTACTS,
    GET_CONTACT,
    GET_ALL_CONTACT,
    POST_CONTACT,
    DELETE_CONTACT,
    UPDATE_CONTACT
} from "./actionTypes";

function* fetchAppContacts({ payload: payload }) {
    try {
        const response = yield call(getContacts, payload);
        yield put(getContactssSuccess(response))
    } catch (error) {
        yield put(getContactssFail(error))
    }
}

function* postAppContact({ payload: payload }) {
    try {
        const response = yield call(postContact, payload);
        yield put(postContactSuccess(response))
    } catch (error) {
        yield put(postContactFail(error))
    }
}

function* deleteAppContact({ payload: payload }) {
    try {
        const response = yield call(deleteContact, payload);
        yield put(deleteContactSuccess(response))
    } catch (error) {
        yield put(deleteContactFail(error))
    }
}

function* getAppContact({ payload: payload }) {
    try {
        const response = yield call(getContact, payload);
        yield put(getContactSuccess(response))
    } catch (error) {
        yield put(getContactFail(error))
    }
}

function* getAppAllContact({ payload: payload }) {
    try {
        const response = yield call(getAllContact, payload);
        yield put(getAllContactSuccess(response))
    } catch (error) {
        yield put(getAllContactFail(error))
    }
}

function* updateAppContact({ payload: payload }) {
    try {
        const response = yield call(updateContact, payload);
        yield put(updateContactSuccess(response))
    } catch (error) {
        yield put(updateContactFail(error))
    }
}

function* ContactsSaga() {
    yield takeEvery(GET_CONTACTS, fetchAppContacts)
    yield takeEvery(POST_CONTACT, postAppContact)
    yield takeEvery(DELETE_CONTACT, deleteAppContact)
    yield takeEvery(GET_CONTACT, getAppContact)
    yield takeEvery(GET_ALL_CONTACT, getAppAllContact)
    yield takeEvery(UPDATE_CONTACT, updateAppContact)
}

export default ContactsSaga


