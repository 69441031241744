import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import BusinessProductsSaga from "./products/products/saga"
import AppBusinessUnits from "./administration/businessUnit/saga"
import AppSysRolesSaga from './administration/portalRole/saga';
import AppPlatformActionsSaga from './actions/platformActions/saga';
import SysUsersSaga from './administration/portalUser/saga'
import SysCredentialsSaga from "./credentials/sysUserCredentials/saga"
import BusinessSaga from "./customers/company/saga"
import GenericIndustrySaga from "./customers/genericIndustry/saga"
import DepartmentSaga from "./customers/company/department/saga"
import ContactsSaga from "./customers/company/contacts/saga"
import OwnerSaga from "./customers/company/owner/saga"

import AppRolesSaga from "./administration/customerRole/saga"
import AppUsersSaga from "./administration/customerUser/saga"
import AppBusinessActionsSaga from "./actions/businessActions/saga"
import AppCredentialsSaga from "./credentials/appUserCredentials/saga"
import CreditsSaga from "./customers/credits/saga"
import LoginRetrysaga from "./settings/loginRetry/saga"
import OtpTimeOutsSaga from "./settings/otpTimeout/saga"
import PasswordPolicysSaga from "./settings/passwordPolicy/saga"
import ActivitiesSaga from "./activityLog/saga"
import BUDivisionSaga from "./administration/businessUnitDivision/saga"
import AddressSaga from "./customers/company/address/saga"
import Smtpsaga from "./settings/smtp/saga"
import AppOpportunitiesSaga from "./Sales/Opportunities/saga"
import AppQuotationSaga from "./Sales/Quotation/saga"
import AppInvoiceSaga from "./finance/Invoice/saga"
import PricingModelSaga from "./products/pricingModel/saga"
import AppAccountSaga from "./finance/accounts/saga"
import AppSysUserProductsaga from "./Sales/sysUserProduct/saga"
import AppTransactionSaga from "./finance/transaction/saga"
import AppPropsalVersion from "./Sales/ProposalVersion/saga"
import AppFinalizeProposalSaga from "./Sales/finalizeProposal/saga"
import AppOpportunityCloneVersionSaga from "./Sales/opportunitycloneversion/saga"
import AppProposalCloneVersionSaga from "./Sales/proposalcloneVersion/saga"
import AppplicationTemplateSaga from "./applicationTemplate/saga"
import AppSynchronizeSaga from "./synchronize/saga"
import AppCoupon from "./Sales/coupon/saga"
import AttachmentSaga from "./settings/attachments/saga"
import AppDownloadLogSaga from "./Sales/downloadlog/saga"
import AppCouponGenerate from "./Sales/CouponGenerate/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(BusinessProductsSaga),
    fork(AppBusinessUnits),
    fork(AppPlatformActionsSaga),
    fork(AppSysRolesSaga),
    fork(SysUsersSaga),
    fork(SysCredentialsSaga),
    fork(BusinessSaga),
    fork(GenericIndustrySaga),
    fork(DepartmentSaga),
    fork(ContactsSaga),
    fork(OwnerSaga),
    fork(AppRolesSaga),
    fork(AppUsersSaga),
    fork(AppBusinessActionsSaga),
    fork(AppCredentialsSaga),
    fork(CreditsSaga),
    fork(LoginRetrysaga),
    fork(OtpTimeOutsSaga),
    fork(PasswordPolicysSaga),
    fork(ActivitiesSaga),
    fork(BUDivisionSaga),
    fork(AddressSaga),
    fork(Smtpsaga),
    fork(AppOpportunitiesSaga),
    fork(AppQuotationSaga),
    fork(AppInvoiceSaga),
    fork(PricingModelSaga),
    fork(AppAccountSaga),
    fork(AppSysUserProductsaga),
    fork(AppTransactionSaga),
    fork(AppPropsalVersion),
    fork(AppFinalizeProposalSaga),
    fork(AppOpportunityCloneVersionSaga),
    fork(AppProposalCloneVersionSaga),
    fork(AppplicationTemplateSaga),
    fork(AppSynchronizeSaga),
    fork(AppCoupon),
    fork(AttachmentSaga),
    fork(AppDownloadLogSaga),
    fork(AppCouponGenerate),
  ])
}
