import {
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  activities: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  error: {},
};

const ActivitiesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITIES:
      return {
        ...state,
        loading: true,
        activities: null,
        isCreated: false,
        isDeleted: false,
      };
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: action.payload,
        isCreated: false,
        isDeleted: false,
      };
    case GET_ACTIVITIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
      };
     
    default:
      return state;
  }
};

export default ActivitiesReducer;

