/* EVENTS */

// Get business Unit
export const GET_PRICING_MODELS = "GET_PRICING_MODELS";
export const GET_PRICING_MODELS_SUCCESS = "GET_PRICING_MODELS_SUCCESS";
export const GET_PRICING_MODELS_FAIL = "GET_PRICING_MODELS_FAIL"

// Get Business Unit by id
export const GET_PRICING_MODEL = "GET_PRICING_MODEL";
export const GET_PRICING_MODEL_SUCCESS = "GET_APP_BUSPRICING_MODEL_SUCCESS";
export const GET_PRICING_MODEL_FAIL = "GET_APP_BUSPRICING_MODEL_FAIL";

// Post Business Unit
export const POST_PRICING_MODEL = "POST_PRICING_MODEL";
export const POST_PRICING_MODEL_SUCCESS = "POST_PRICING_MODEL_SUCCESS";
export const POST_PRICING_MODEL_FAIL = "POST_PRICING_MODEL_FAIL"

// Delete Business Unit
export const DELETE_PRICING_MODEL = "DELETE_PRICING_MODEL";
export const DELETE_PRICING_MODEL_SUCCESS = "DELETE_PRICING_MODEL_SUCCESS";
export const DELETE_PRICING_MODEL_FAIL = "DELETE_PRICING_MODEL_FAIL"

// Update Business Unit
export const UPDATE_PRICING_MODEL = "UPDATE_PRICING_MODEL";
export const UPDATE_PRICING_MODEL_SUCCESS = "UPDATE_PRICING_MODEL_SUCCESS";
export const UPDATE_PRICING_MODEL_FAIL = "UPDATE_PRICING_MODEL_FAIL";