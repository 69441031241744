import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, CardBody, UncontrolledTooltip, Label } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import axios from "axios"
import getHeader from "config/header"
import Approval from "../../../../components/Common/ApproveModal";
import { useHistory, Link } from "react-router-dom"
import { API_BASE_URL, BUSINESS_URL } from "helpers/url_helper"
import { useDispatch, useSelector } from "react-redux"
import { getAppBusiness, getInvoices, getPricingmodels, updateInvoice, updatePricingmodel } from "store/actions"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ApproveCreatePricingModal from "components/Common/ApprovalModelComponent/ApproveCreatePricingModal"
import { InvoiceID,Company,SiteName,SiteId,BusinessUnit,OpportunityId,Total,Status } from "../../../Finance/Invoices/InvoiceListCol";
import ApproveInvoiceModal from "components/Common/ApprovalModelComponent/ApproveInvoiceModal"


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

function ApprovalInvoice() {
    const query = useQuery();
    const history = useHistory()
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const dispatch = useDispatch();
    const queryMultistatus = query.get("queryMultistatus");
    const Busid = query.get("Busid");
    const [pageCount, ] = useState(1);
    const [invoiceList, setInvoiceList] = useState([]);
    const [customFilterData, setCustomFilterData] = useState("");
    const appInvoiceReducerState = useSelector(state => state.AppInvoiceReducer);

    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, Busid, queryMultistatus, customFilterData])


    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/approvals/opportunities?tab=${1}?skip=${0}&&limit=${pageLimit}&&queryMultistatus=${"all"}&&Busid=${Busid}`)
        }
    }, [customFilterData])
  

    const handlePageClick = (index) => {
        const Business_id = Busid === "null" ? "" : Busid

        let filter1 = Business_id === "" ? `{"invoice_confirmed":${true},"$or":[
            {"invoice_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}}

        ]}`
            : `{"invoice_confirmed":${true},"app_business_id":"${Business_id}","$or":[
               {"invoice_keyid":{"$regex":"${customFilterData}","$options":"i"}},
               {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
               {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
               {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`

           
        let filter2 = Business_id === "" ? `{"invoice_confirmed":${true},"approval_status":"${queryMultistatus}","$or":[
            {"invoice_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}}

        ]}`
            : `{"invoice_confirmed":${true},"approval_status":"${queryMultistatus}","app_business_id":"${Business_id}","$or":[
               {"invoice_keyid":{"$regex":"${customFilterData}","$options":"i"}},
               {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
               {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
               {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`

        let skips = 0;
        if (queryMultistatus === "all") {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getInvoices({ filter: filter1, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getInvoices({ filter: filter1, limit: pageLimit, skip: 0 }));
            }
        } else {
            if (page > 0) {
                skips = page * pageLimit;
                 dispatch(getInvoices({ filter: filter2, limit: pageLimit, skip: skips }));
            }
            else {
                 dispatch(getInvoices({ filter: filter2, limit: pageLimit, skip: 0 }));
            }

        }
    }

    useEffect(() => {
        // console.log("appInvoiceReducerState", appInvoiceReducerState);
        // if (typeof appInvoiceReducerState?.invoices === "string") {
        //     const parsedData = JSON.parse(appInvoiceReducerState.invoices)
        //     console.log("parsedData", parsedData);
        //     if (parsedData !== null) {
        //         setInvoiceList(parsedData.result);
        //         setPageCount(Math.ceil(parsedData.summary.filteredsize / pageLimit));
        //     }
        // } else {
        //     setInvoiceList([])
        //     setPageCount(0)
        // }


        if (appInvoiceReducerState?.invoices !== null) {
            setInvoiceList(appInvoiceReducerState.invoices.result);
            setPageCount(Math.ceil(appInvoiceReducerState.invoices.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(appInvoiceReducerState.invoices.summary.filteredsize / pageLimit)
            );
        }

        if (appInvoiceReducerState.isUpdated) {
            handlePageClick()
        }
    },[appInvoiceReducerState])

  

    const [approveInvoice, setApproveInvoice] = useState(false);
    const[invoiceDataTBA,setInvoiceDataTBA] = useState('')

    const onClickApproveInvoice = (invoiceData) => {
        setApproveInvoice(true);
        setInvoiceDataTBA(invoiceData)
    }

    const handleApproveInvoice = () => {
    
        if (invoiceDataTBA.invoice_id) {
            const Data = {
                invoiceId: invoiceDataTBA.invoice_id,
                updatedData: {
                  "approval_status": "Approved"
                }
              }
              dispatch(updateInvoice(Data))
            setApproveInvoice(false);
        }
    };

    const handleRejectInvoice = () => {
        if (invoiceDataTBA.invoice_id) {
            const Data = {
                invoiceId: invoiceDataTBA.invoice_id,
                updatedData: {
                  "approval_status": "Rejected"
                }
              }
              dispatch(updateInvoice(Data))
            setApproveInvoice(false);
        }
    };


    const columns = useMemo(
        () => [
            {
                Header: 'Invoice ID',
                accessor: 'invoice_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <InvoiceID {...cellProps} />;
                }
            },
            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Company {...cellProps} />
                }
            },
            // {
            //     Header: 'Site ID',
            //     accessor: 'app_site_id',
            //     filterable: true,
            //     // Cell: (cellProps) => {
            //     //     return <SiteId {...cellProps} />;
            //     // }
            // },
            {
                Header: 'Site Name',
                accessor: 'app_department_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteName {...cellProps} />;
                }
            },
            // {
            //     Header: 'Business Unit',
            //     accessor: 'app_businessunit_name',
            //     filterable: true,
            //     // Cell: (cellProps) => {
            //     //     return <BusinessUnit {...cellProps} />;
            //     // }
            // },
            {
                Header: 'Opportunity ID',
                accessor: 'opportunity_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <OpportunityId  {...cellProps} />;
                }
            },
            {
                Header: 'GrandTotal',
                accessor: 'grandTotals',
                filterable: true,
                Cell: (cellProps) => {
                    return <Total  {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'approval_status',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {/* <button
                                onClick={() => {
                                       history.push(`/viewinvoice/${cellProps.row.original.app_invoice_id}`)
                                }} className="btn btn-success btn-sm">View</button>
                                 <button
                                onClick={() => {
                                    history.push(`/updateinvoice/${cellProps.row.original.app_invoice_id}`)
                                }} className="btn btn-primary btn-sm">Edit</button> */}
                            {<href
                                className="text-info"
                                onClick={() => {
                                    const companyData = cellProps.row.original;
                                    onClickApproveInvoice(companyData);
                                }}
                            >
                                <i className='mdi mdi-checkbox-marked-outline font-size-18' id={"approveTooltip" + cellProps.row.original.invoice_id}></i>
                                <UncontrolledTooltip placement="top" target={"approveTooltip" + cellProps.row.original.invoice_id}>
                                    Approve
                                </UncontrolledTooltip>
                            </href>}
                        </div>
                    );
                }
            },
        ], [invoiceList]
    );


    return (
        <React.Fragment>

            < ApproveInvoiceModal
              show={approveInvoice}
              onCloseClick={() => setApproveInvoice(false)}
              onApproveClick={handleApproveInvoice}
              onRejectClick={handleRejectInvoice}
              data = {invoiceDataTBA}
              title="Invoice"
            />
            
            <Row>
                <Label><u>Invoice </u></Label>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns}
                                data={invoiceList}
                                isGlobalFilter={true}
                                customPageSize={10}
                                className="custom-header-css"
                                customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                handlePageClick={handlePageClick}
                                isCompanyDropdownforInvoiceList={true}
                                customPageCount={pageCount}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default ApprovalInvoice



