import React, { useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, CardBody } from "reactstrap"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import axios from 'axios'
import getHeader from 'config/header'
import getFormHeader from 'config/formHeader'
import StatusModal from 'components/Common/StatusModal'
import { useDispatch, useSelector } from 'react-redux'
import { geAccountDetail, getBusinesses, getCredit, getCredits, getDepartment, getDepartments, updateCredit } from 'store/actions'
import departmentReducer from 'store/customers/company/department/reducer'
import TableContainer from 'components/Common/TableContainer'
import ApproveModal from 'components/Common/ApproveModal'

const CreateReturns = () => {
    const history = useHistory();

    const columns1 = useMemo(
        () => [
            {
                Header: 'Invoice ID',
                accessor: 'app_invoice_id',
                filterable: true,
                // Cell: (cellProps) => {
                //     return <InvoiceID {...cellProps} />;
                // }
            },
            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                // Cell: (cellProps) => {
                //     return <Company {...cellProps} />
                // }
            },
            {
                Header: 'Site ID',
                accessor: 'app_site_id',
                filterable: true,
                // Cell: (cellProps) => {
                //     return <SiteId {...cellProps} />;
                // }
            },
            {
                Header: 'Site Name',
                accessor: 'app_site_name',
                filterable: true,
                // Cell: (cellProps) => {
                //     return <SiteName {...cellProps} />;
                // }
            },
            {
                Header: 'Business Unit',
                accessor: 'app_businessunit_name',
                filterable: true,
                // Cell: (cellProps) => {
                //     return <BusinessUnit {...cellProps} />;
                // }
            },
            {
                Header: 'Opportunity ID',
                accessor: 'app_opportunity_id',
                filterable: true,
                // Cell: (cellProps) => {
                //     return <OpportunityId  {...cellProps} />;
                // }
            },
            {
                Header: 'Total',
                accessor: 'app_total',
                filterable: true,
                // Cell: (cellProps) => {
                //     return <Total  {...cellProps} />;
                // }
            },
            {
                Header: 'Status',
                accessor: 'app_status',
                filterable: true,
                // Cell: (cellProps) => {
                //     return <Status {...cellProps} />;
                // }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <button
                                onClick={() => {
                                    history.push(`/viewinvoice/${cellProps.row.original.app_invoice_id}`)
                                }} className="btn btn-success btn-sm">View</button>
                            <button
                                onClick={() => {
                                    history.push(`/updateinvoice/${cellProps.row.original.app_invoice_id}`)
                                }} className="btn btn-primary btn-sm">Edit</button>
                        </div>
                    );
                }
            },
        ], []
    );

    const [removeButton, setRemoveButton] = useState(false)
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([])

    const removeBtnClick = (i) => {
        let newImages = images.map(item => ({ ...item }))
        let newFiles = files.map(item => ({ ...item }))
        newImages.splice(i, 1)
        setImages(newImages)
        newFiles.splice(i, 1)
        setFiles(newFiles)
    }

    const handleChange = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleFiles(chosenFiles)
        let imageData = e.target.files
        const len = imageData.length
        for (var i = 0; i < len; i++) {
            var imageBlob = imageData[i];
            var fileReader = new FileReader()  //1
            fileReader.onload = ({ target }) => {  // 3
                setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
            }
            fileReader.readAsDataURL(imageBlob) //2
        }
    }

    const handleFiles = (inputFile) => {
        const filesData = [...files]
        inputFile.some(file => {
            if (filesData.findIndex((f) => f.name === file.name) === -1) {
                filesData.push(file)
            }
        })
        setFiles(filesData)
    }

    const getAwsStorageUrl = (file) => {
        return new Promise((resolve, reject) => {
            const
                token = localStorage.getItem('reposeCmsToken')
            axios.get("/business/api/v1/storage/signedurlput?image_folder=product_images", getBusinessHeader(token))
                .then(async res => {
                    let fileKey = res.data.filekey
                    let signedURLPut = res.data.signed_url_put
                    await uploadImage(file, signedURLPut)
                    resolve(fileKey)
                }).catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    const uploadImage = (file, signedURLPut) => {
        return new Promise(async (resolve, reject) => {
            await fetch(signedURLPut, {
                method: 'PUT',
                headers: getImageHeader(),
                mode: 'cors',
                body: file
            })
            resolve()
        })
    }

    const getImageHeader = () => {
        // { 'Content-Type': 'image/png' }
        const myHeaders = new Headers()
        myHeaders.append('Access-Control-Allow-Origin', '*')
        return myHeaders
    }

    const getImages = (list) => {
        let data = []
        for (let i in list) {
            data.push({ seq_no: parseInt(i) + 1, image_filekey: list[i] })
        }
        return data
    }

    const openImage = (e) => {
        let data = e.target.currentSrc;
        let w = window.open('about:blank');
        let image = new Image();
        image.src = data;
        setTimeout(function () {
            w.document.write(image.outerHTML);
        }, 0);
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const newItems = Array.from(images);
        const newFiles = Array.from(files)
        const [removed] = newItems.splice(startIndex, 1);
        const [removedFiles] = newFiles.splice(startIndex, 1)
        newItems.splice(endIndex, 0, removed);
        newFiles.splice(endIndex, 0, removedFiles)
        newItems.forEach((item, index) => {
            item.seq_no = index + 1;
        });
        setImages(newItems)
        setFiles(newFiles)
    }


    const BackButton = () => {
        return history.push(`/returnslist?skip=${0}&&limit=${10}&&status=${true}`)
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="create returns" breadcrumbItem="Returns" />
                <Container fluid>
                    <h1>create returns</h1>
                </Container>
            </div>

        </React.Fragment>
    )
}
export default CreateReturns