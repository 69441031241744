import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Input, FormFeedback, Label, Form, } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
import { getFinalizeProposals, getInvoices } from "store/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { InvoiceID, Company, SiteName, SiteId, BusinessUnit, OpportunityId, Total, Status } from "./InvoiceListCol";
import getHeader from "config/header";
import axios from "axios";


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const InvoiceList = props => {
    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const Busid = query.get("Busid")
    const SiteId = query.get("SiteId")
    const history = useHistory()
    const [pageCount, setPageCount] = useState(1);
    const [permissions, setPermissions] = useState('')
    const queryMultistatus = query.get("queryMultistatus");

    const dispatch = useDispatch();

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    const appFinalizeProposalReducer = useSelector(state => state.AppFinalizeProposalReducer);
    const [invoiceList, setInvoiceList] = useState([]);

    // useEffect(() => {
    //     dispatch(getFinalizeProposals( {filter: `{"invoice_approval_status":"Pending"}`, limit:0, skip: 0}))
    // },[])

    // useEffect(() => {
    //     console.log("xxxxxxx",appFinalizeProposalReducer.finalizeproposals);
    //     // if(appFinalizeProposalReducer.finalizeproposals !== null){

    //     //     setInvoiceList(appFinalizeProposalReducer.finalizeproposals.result)
    //     // }
    // },[appFinalizeProposalReducer])



    const columns = useMemo(
        () => [
            {
                Header: 'Finalize Proposal ID',
                accessor: 'finalize_proposal_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <InvoiceID {...cellProps} />;
                }
            },
            {
                Header: 'Invoice ID',
                accessor: 'invoice_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <InvoiceID {...cellProps} />;
                }
            },

            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Company {...cellProps} />
                }
            },

            {
                Header: 'Site Name',
                accessor: 'app_department_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteName {...cellProps} />;
                }
            },
            {
                Header: 'Opportunity ID',
                accessor: 'opportunity_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <OpportunityId {...cellProps} />;
                }
            },

            {
                Header: 'Total',
                accessor: 'grandTotals',
                filterable: true,
                Cell: (cellProps) => {
                    return <Total  {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'invoice_approval_status',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {(permissionAction.includes("system.finances.invoices.read")) ?
                                <button
                                    onClick={() => {
                                        history.push(`/viewinvoice/${cellProps.row.original.finalize_proposal_id}`)
                                    }} className="btn btn-success btn-sm">View</button> : <div className="text-muted">No Access</div>

                            }

                            {/* <button
                                onClick={() => {
                                    history.push(`/updateinvoice/${cellProps.row.original.app_invoice_id}`)
                                }} className="btn btn-primary btn-sm">Edit</button> */}
                        </div>
                    );
                }
            },
        ], [invoiceList]
    );

    const [customFilterData, setCustomFilterData] = useState("")

    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, Busid, SiteId, queryMultistatus, customFilterData])


    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/invoicelist?skip=${0}&&limit=${pageLimit}&&queryMultistatus=${queryMultistatus}&&Busid=${Busid}`)
        }
    }, [customFilterData])

    

    const handlePageClick = (index) => {
        // dispatch(getFinalizeProposals( {filter: `{"invoice_approval_status":"Pending"}`, limit:0, skip: 0}))

        const Business_id = Busid === "null" ? "" : Busid

        let filter1 = Business_id === "" ? `{"$or":[
            {"invoice_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"finalize_proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}}
          

        ]}`
            : `{"app_business_id":"${Business_id}","$or":[
               {"invoice_keyid":{"$regex":"${customFilterData}","$options":"i"}},
               {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
               {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
               {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
               {"finalize_proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}}
         
        ]}`


        let filter2 = Business_id === "" ? `{"invoice_approval_status":"${queryMultistatus}","$or":[
            {"invoice_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"finalize_proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}}
           

        ]}`
            : `{"invoice_approval_status":"${queryMultistatus}","app_business_id":"${Business_id}","$or":[
               {"invoice_keyid":{"$regex":"${customFilterData}","$options":"i"}},
               {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
               {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
               {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
               {"finalize_proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}}

        ]}`

        let skips = 0;
        if (queryMultistatus === "all") {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getFinalizeProposals({ filter: filter1, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getFinalizeProposals({ filter: filter1, limit: pageLimit, skip: 0 }));
            }
        } else {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getFinalizeProposals({ filter: filter2, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getFinalizeProposals({ filter: filter2, limit: pageLimit, skip: 0 }));
            }

        }
    }

    useEffect(() => {
        if (appFinalizeProposalReducer?.finalizeproposals !== null) {
            setInvoiceList(appFinalizeProposalReducer.finalizeproposals.result);
            setPageCount(Math.ceil(appFinalizeProposalReducer.finalizeproposals.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(appFinalizeProposalReducer.finalizeproposals.summary.filteredsize / pageLimit)
            );

        }
    }, [appFinalizeProposalReducer])

    return (
        <React.Fragment>
            <div className="page-content">
                {permissions.includes("system.finances.invoices.read") ? <Container fluid>
                    <Breadcrumbs title="Invoices" breadcrumbItem="Finance" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={invoiceList}
                                        isGlobalFilter={true}
                                        className="custom-header-css"
                                        customPageCount={pageCount}
                                        isCompanyDropdownforInvoiceList={true}
                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                        handlePageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :

                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    );
};

export default InvoiceList;

