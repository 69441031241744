import {
    GET_DEPARTMENTS,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAIL,

    GET_DEPARTMENT,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_FAIL,

    GET_ALL_DEPARTMENT,
    GET_ALL_DEPARTMENT_SUCCESS,
    GET_ALL_DEPARTMENT_FAIL,

    POST_DEPARTMENT,
    POST_DEPARTMENT_SUCCESS,
    POST_DEPARTMENT_FAIL,

    DELETE_DEPARTMENT,
    DELETE_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_FAIL,

    UPDATE_DEPARTMENT,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get sys departments  //
/////////////////////////


export const getDepartments = (payload) => ({
    type: GET_DEPARTMENTS,
    payload: payload
})

export const getDepartmentsSuccess = departments => ({
    type: GET_DEPARTMENTS_SUCCESS,
    payload: departments
})

export const getDepartmentsFail = error => ({
    type: GET_DEPARTMENTS_FAIL,
    payload: error
})

//////////////////////////////
// Get By id departments //
//////////////////////////////

export const getDepartment = (payload) => ({
    type: GET_DEPARTMENT,
    payload: payload
});

export const getDepartmentSuccess = department => ({
    type: GET_DEPARTMENT_SUCCESS,
    payload: department,
});

export const getDepartmentFail = error => ({
    type: GET_DEPARTMENT_FAIL,
    payload: error,
});

//////////////////////////////
// Get All departments //
//////////////////////////////

export const getAllDepartment = (payload) => ({
    type: GET_ALL_DEPARTMENT,
    payload: payload
});

export const getAllDepartmentSuccess = department => ({
    type: GET_ALL_DEPARTMENT_SUCCESS,
    payload: department,
});

export const getAllDepartmentFail = error => ({
    type: GET_ALL_DEPARTMENT_FAIL,
    payload: error,
});

////////////////////////
// Post sys departments     ///
////////////////////////

export const postDepartment = (payload) => ({
    type: POST_DEPARTMENT,
    payload: payload
});

export const postDepartmentSuccess = department => ({
    type: POST_DEPARTMENT_SUCCESS,
    payload: department,
});

export const postDepartmentFail = error => ({
    type: POST_DEPARTMENT_FAIL,
    payload: error,
});

///////////////////////////
// Delete sys departments //
//////////////////////////

export const deleteDepartment= (payload) => ({
    type: DELETE_DEPARTMENT,
    payload: payload
});

export const deleteDepartmentSuccess = () => ({
    type: DELETE_DEPARTMENT_SUCCESS,
});

export const deleteDepartmentFail = error => ({
    type: DELETE_DEPARTMENT_FAIL,
    payload: error,
});

///////////////////////////
// Update sys departments  //
///////////////////////////

export const updateDepartment = (payload) => ({
    type: UPDATE_DEPARTMENT,
    payload: payload
});

export const updateDepartmentSuccess = payload => ({
    type: UPDATE_DEPARTMENT_SUCCESS,
    payload: payload
});

export const updateDepartmentFail = error => ({
    type: UPDATE_DEPARTMENT_FAIL,
    payload: error,
});


