import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, CardBody } from "reactstrap"

import axios from 'axios';
import getHeader from 'config/header';
import { useEffect } from "react";
import { toast } from "react-toastify";
import expiredSessionHandler from "common/expiredSessionHandler";
import Select from 'react-select'
import { getAttachment, postAttachment, updateAttachment } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

function Attachments() {


    const dispatch = useDispatch()

    const { handleSubmit, register, setValue, formState: { errors } } = useForm()

    const [editState, setEditState] = useState(false)
    const [createState, setCreateState] = useState(true)
    const [triggeredCancel, setTriggeredCancel] = useState(false)
    const [permissionAction, setPermissions] = useState('')



    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])


    const ReadPermisson = (permissionAction.includes("system.settings.attachment.read")) ? false : true
    const UpdatePermission = (permissionAction.includes("system.settings.attachment.update")) ? false : true

    useEffect(() => {
        dispatch(getAttachment())
    }, [triggeredCancel])

    const attachmentState = useSelector(state => state.AttachmentReducer)


    useEffect(() => {
        if (attachmentState.attachment !== null) {
            extensionData(attachmentState.attachment.extension)
            setValue("minSize", attachmentState.attachment.minSize +  " MB ")
            setValue("maxSize", attachmentState.attachment.maxSize +  " MB ")
            setCreateState(false)
        }
        if (attachmentState.isCreated) {
            dispatch(getAttachment())
            toast.success("Saved")
            setEditState(!editState)
            setCreateState(false)
        }
        else if (attachmentState.isUpdated) {
            dispatch(getAttachment())
            toast.success("Updated")
            setEditState(!editState)
        }
    }, [attachmentState])


    const submitAttachment = (items) => {


        // console.log("items", items);
        let extensionArray = []
        let asd = items?.extension
        for (let i in asd) {
            extensionArray.push(asd[i].value)
        }
        // console.log("extensionArray", extensionArray);



        let data = {
            minSize: items.minSize.replace(/\D/g,''),
            maxSize: items.maxSize.replace(/\D/g,''),
            extension: extensionArray
        }
        
        if (createState) {
            dispatch(postAttachment({ data }))
        } else {
            dispatch(updateAttachment({ data }))
        }
    }

    const handleEditState = () => {
        setEditState(!editState)
    }

    const handleCancelEditState = () => {
        setTriggeredCancel(!triggeredCancel)
        setEditState(!editState)
    }

    // manage bu divisioattachmentn multiselect
    register('extension', { required: true });
    const [selectedMultiExtension, setselectedMultiExtension] = useState([]);
    const [optionGroupExtension, setOptionGroupExtension] = useState([
        { label: "Select All", value: "select_all" },
        { value: ".pdf", label: "PDF" },
        { value: "image/png", label: "PNG" },
        { value: "image/jpeg", label: "JPEG" },
        { value: ".ods", label: "ODS" },
        { value: ".xlsx", label: "XLSX" },
        // { value: ".docx", label: "DOCX" },
        // { value: "xlsx", label: "XLSX" }
    ])




    const extensionData = (Items) => {
        if (Items?.length > 0 && optionGroupExtension) {
            let Data = Items?.map((item) => {
                let optionGroup = optionGroupExtension.find(option => option.value === item)
                if (optionGroup) {
                    return {
                        label: optionGroup.label,
                        value: optionGroup.value
                    }
                }
            }).filter(Boolean)
            setselectedMultiExtension(Data)
            setValue("extension", Data)
        }
    }

    const handleChangeExtension = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupExtension.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('extension', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('extension', postArray)
                }
            }
        });
        // setBuDivisionList(postArray)
        setselectedMultiExtension({
            value: postArray,
        });
        // manageCurrentDivision(postArray)
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Attachment"
                    handleEditState={handleEditState}
                    handleCancelEditState={handleCancelEditState}
                    isEditSmtpBtn={!editState}
                    breadcrumbItem="Settings" />

                {!ReadPermisson ? <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(submitAttachment)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "></Label>
                                            <Col lg="5" md="6"> </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Extension <span style={{ color: "red" }}>* </span> :</Label>
                                            <Col lg="5" md="6">
                                                <Select
                                                    isDisabled={!editState}
                                                    value={selectedMultiExtension.length > 0 ? selectedMultiExtension : selectedMultiExtension.value}
                                                    isMulti={true}
                                                    onChange={handleChangeExtension}
                                                    options={optionGroupExtension}
                                                    // name={"app_division_id"}
                                                    className="modal__input"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Min Size <span style={{ color: "red" }}>* </span> :</Label>
                                            <Col lg="5" md="6">
                                                <input readOnly={!editState} className="form-control form-control-solid" type="text" placeholder="in Megabyte" {...register("minSize", { required: true })} />
                                                {errors.minSize && <span className="text-danger">This field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Max Size <span style={{ color: "red" }}>* </span> :</Label>
                                            <Col lg="5" md="6">
                                                <input readOnly={!editState} className="form-control form-control-solid" type="text" placeholder="in Megabyte" {...register("maxSize", { required: true })} />
                                                {errors.maxSize && <span className="text-danger">This field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    {editState && <Row>
                                        <Col lg="6" className="text-center ms-5">
                                            {
                                                !UpdatePermission &&
                                                <Button color="success" type="submit">{createState ? "Create" : "Update"}</Button>
                                            }

                                        </Col>
                                    </Row>}
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "></Label>
                                            <Col lg="5" md="6">  </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "></Label>
                                            <Col lg="5" md="6">  </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "></Label>
                                            <Col lg="5" md="6"> </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "></Label>
                                            <Col lg="5" md="6">  </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "></Label>
                                            <Col lg="5" md="6"> </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
}
export default Attachments
