import {
  GET_GENERIC_INDUSTRY,
  GET_GENERIC_INDUSTRY_SUCCESS,
  GET_GENERIC_INDUSTRY_FAIL,


  GET_GENERIC_INDUSTRY_ID,
  GET_GENERIC_INDUSTRY_ID_SUCCESS,
  GET_GENERIC_INDUSTRY_ID_FAIL,


  POST_GENERIC_INDUSTRY,
  POST_GENERIC_INDUSTRY_SUCCESS,
  POST_GENERIC_INDUSTRY_FAIL,
} from "./actionTypes";

export const getGenericIndustry = (payload) => ({
  type: GET_GENERIC_INDUSTRY,
  payload: payload
});

export const getGenericIndustrySuccess = genericIndustry => ({
  type: GET_GENERIC_INDUSTRY_SUCCESS,
  payload: genericIndustry,
});

export const getGenericIndustryFail = error => ({
  type: GET_GENERIC_INDUSTRY_FAIL,
  payload: error,
});


//////////////////////////////
// Get By id                //
//////////////////////////////

export const getGenericIndustryId = (payload) => ({
    type: GET_GENERIC_INDUSTRY_ID,
    payload: payload
});

export const getGenericIndustryIdSuccess = sysUsers => ({
    type: GET_GENERIC_INDUSTRY_ID_SUCCESS,
    payload: sysUsers,
});

export const getGenericIndustryIdFail = error => ({
    type: GET_GENERIC_INDUSTRY_ID_FAIL,
    payload: error,
});


////////////////////////
// Post Industry     ///
////////////////////////

export const postAppGenericIndustry = (payload) => ({
  type: POST_GENERIC_INDUSTRY,
  payload: payload
});

export const postAppGenericIndustrySuccess = industry => ({
  type: POST_GENERIC_INDUSTRY_SUCCESS,
  payload: industry,
});

export const postAppGenericIndustryFail = error => ({
  type: POST_GENERIC_INDUSTRY_FAIL,
  payload: error,
});

