import {
    GET_APP_USERS,
    GET_APP_USERS_SUCCESS,
    GET_APP_USERS_FAIL,

    GET_APP_USER,
    GET_APP_USER_SUCCESS,
    GET_APP_USER_FAIL,

    POST_APP_USER,
    POST_APP_USER_SUCCESS,
    POST_APP_USER_FAIL,

    DELETE_APP_USER,
    DELETE_APP_USER_SUCCESS,
    DELETE_APP_USER_FAIL,

    UPDATE_APP_USER,
    UPDATE_APP_USER_SUCCESS,
    UPDATE_APP_USER_FAIL
} from "./actionTypes"

const INIT_STATE = {
    appUsers: null,
    appUser: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
}

const appUsersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APP_USERS:
            return {
                ...state,
                loading: true,
                appUsers: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                appUsers: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_USERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_USER:
            return {
                ...state,
                loading: true,
                appUsers: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                appUser: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_APP_USER:
            return {
                ...state,
                loading: true,
                appUsers: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_APP_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                appUser: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_APP_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_USER:
            return {
                ...state,
                loading: true,
                appUser: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                appUser: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_APP_USER:
            return {
                ...state,
                loading: true,
                // appUsers: null,
                appUser: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_APP_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                appUser: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_APP_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default appUsersReducer;