import axios from 'axios';
import getHeader from 'config/header';
import { API_BASE_URL, BUSINESS_URL, DEPARTMENT_URL } from 'helpers/url_helper';
import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';


const CreatedDate = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

const OrderID = (cell) => {
    return cell.value ? cell.value : '';
};

const Company = (cell) => {
    const [business, setBusiness] = useState()
    useEffect(() => {
        if (cell.value) {
            const token = sessionStorage.getItem('authUser')
            axios.get(API_BASE_URL + BUSINESS_URL + "/" + cell.value, getHeader(token))
                .then(res => {
                    // console.log(res.data);
                    setBusiness(res.data.app_business_name)
                }).catch(err => {
                    console.log(err);
                })
        }
    }, [])
    return business ? business : '';
};

const Site = (cell) => {
    const [site, setSite] = useState()
    useEffect(() => {
        if (cell.value) {
            const token = sessionStorage.getItem('authUser')
            axios.get(API_BASE_URL + DEPARTMENT_URL + "/" + cell.BusId + '/' + cell.value, getHeader(token))
                .then(res => {
                    setSite(res.data.app_department_name)
                }).catch(err => {
                    console.log(err);
                })
        }
    }, [])
    return site ? site : '';
};

const POAmount = (cell) => {
    return cell.value ? cell.value : '';
};


const BusinessUnit = (cell) => {
    return cell.value ? cell.value : '';
};

const AppProductLocalName = (cell) => {
    return cell.value ? cell.ProductKeyId + " | " + cell.ProductName + " | " + cell.value : '';
};

const OriginalPrice = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1);
    return (<span className=''> {floatValue ? " $ " + floatValue : ''} </span>);
};


const Quantity = (cell) => {
    return (<input className='form form-control' type='number' onChange={(e) => cell.handleQty(cell.row.original?.app_product_id, cell.row.original?.pack_unit, e.target.value)} />)
};

const ProductType = (cell) => {
    // if (cell.value === "D") {
    //     return cell.value ? "Digital" : '';
    // } else {
    //     return cell.value ? "Print" : '';
    // }

    return cell.value ? cell.value : '';

};

const DiscountCode = (cell) => {
    return cell.value ? cell.value : '';
};

const DiscountValue = (cell) => {
    return cell.value ? cell.value : '';
};


const DiscountUnitPrice = (cell) => {

    if(isNaN(cell.value)) return 
    const floatValue = parseFloat(cell.value).toFixed(1);
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " + floatValue : ''} </span>);
};

const SubTotal = (cell) => {
    // console.log("index",cell.index);
    if(cell.row.id == cell.index){
          return (<span className=''> {cell.qty? " $ " + cell.qty: ''} </span>)
    }
  ;
};



const ProductID = (cell) => {
    return cell.value ? cell.value : '';
};

const ProductName = (cell) => {
    return cell.value ? cell.value : '';
};

const POSItemCode = (cell) => {
    return cell.value ? cell.value : '';
};

const CouponNo = (cell) => {
    return cell.value ? cell.value : '';
};

const Qty = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (<span className='ListPageFloatRight'>{floatValue} </span>);
}

const UnitPrice = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (<span className='ListPageFloatRight'> {floatValue ? "$" + " " + floatValue : ''} </span>);
};


export {
    CreatedDate, OrderID, Company, Site, POAmount, Status,
    BusinessUnit, AppProductLocalName, OriginalPrice, UnitPrice, Quantity, DiscountCode, DiscountValue, SubTotal, ProductType,
    ProductID, ProductName, POSItemCode,Qty,DiscountUnitPrice, CouponNo,
};