import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
import { CreatedDate, OrderID, Company, Site, POAmount, Status } from './OpportunitiesListCol';
import { getOpportunitieses, updateOpportunities, } from "store/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CancelOpportunityModal from "components/Common/cancelOpportunityModal";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Opportunities = props => {

  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const opportunityStatus = query.get("opportunityStatus")
  const [pageCount, setPageCount] = useState(1);

  const history = useHistory()
  const dispatch = useDispatch();
  document.title = "Opportunities | Maxims B2B Portal";

  const [permissions, setPermissions] = useState('')

  useEffect(() => {
    setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
  }, [])

  const [opportunitieses, setOpportunitieses] = useState([])

  const columns = useMemo(
    () => [
      {
        Header: 'Opportunities ID',
        accessor: 'opportunity_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <OrderID Opportunitiesdata={cellProps.row.original}  {...cellProps} />;
        }
      },
      {
        Header: 'Opportunities Date',
        accessor: 'opportunity_date',
        filterable: true,
        Cell: (cellProps) => {
          return <CreatedDate {...cellProps} />;
        }
      },
      {
        Header: 'Company',
        accessor: 'app_business_name',
        filterable: true,
        Cell: (cellProps) => {
          return <Company {...cellProps} />;
        }
      },
      {
        Header: 'Site',
        accessor: 'app_department_name',
        filterable: true,
        Cell: (cellProps) => {
          return <Site  {...cellProps} />;
        }
      },
      {
        Header: 'PO Amount',
        accessor: 'po_amount',
        filterable: true,
        Cell: (cellProps) => {
          return <POAmount {...cellProps} />;
        }
      },
      {
        Header: ' Status',
        accessor: 'status',
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      // {
      //   Header: 'Action',
      //   Cell: (cellProps) => {
      //     return (
      //       <div className="d-flex gap-3">
      //         <button
      //           onClick={() => {
      //             history.push(`/viewOpportunities/${cellProps.row.original.opportunity_id}?tab=1`)
      //           }} className="btn btn-success btn-sm">View</button>
      //         {/* <button
      //           onClick={() => {
      //             // history.push(`/updateOpportunities/${cellProps.row.original.opportunity_id}`)
      //             // history.push('/previewOpportunities')
      //           }} className="btn btn-danger btn-sm"  >Cancel</button> */}
      //         <button className="btn btn-danger btn-sm"
      //           onClick={() => {
      //             const opportunityData = cellProps.row.original;
      //             getOpportunityData(opportunityData)
      //           }}
      //         >cancel</button>
      //       </div>
      //     );
      //   }
      // },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
          return (
            <div className="d-flex gap-3">
              {(permissionAction.includes("system.sales.opportunities.read")) && (permissionAction.includes("system.sales.opportunities.update")) ?
                <>
                  <button
                    onClick={() => {
                      history.push(`/viewOpportunities/${cellProps.row.original.opportunity_id}?tab=1`)
                    }} className="btn btn-success btn-sm">View</button>
                </> : <div className="text-muted">No Access</div>
              }
              {(permissionAction.includes("system.sales.opportunities.read")) && (permissionAction.includes("system.sales.opportunities.update"))
                ?
                <>
                 {(cellProps?.row.original?.status === "Cancel") ? "" : <button className="btn btn-danger btn-sm"
                    onClick={() => {
                      const opportunityData = cellProps.row.original;
                      getOpportunityData(opportunityData)
                    }}
                  >cancel</button>}
                </> : <div className="text-muted">No Access</div>
              }

            </div>
          );
        }
      },
    ],
    [opportunitieses]
  );


  //============global search=====================================//
  const [customFilterData, setCustomFilterData] = useState("")

  useEffect(() => {
    handlePageClick()
  }, [page, pageLimit, opportunityStatus, customFilterData])


  useEffect(() => {
    if (customFilterData.length > 0) {
      history.push(`/opportunities?skip=${0}&&limit=${pageLimit}&&opportunityStatus=${"all"}`)
    }
  }, [customFilterData])

  const opprtunitiesState = useSelector(state => state.AppOpportunitiesReducer)

  const handlePageClick = (index) => {
    let filter1 = `{"$or":[
      {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
      {"status":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`
    let filter2 = `{"status":"${opportunityStatus}","$or":[
        {"opportunity_keyid":{"$regex":"${customFilterData}","$options":"i"}},
        {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
        {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
        {"status":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`

    let skips = 0;
    if (opportunityStatus === "all") {
      if (page > 0) {
        skips = page * pageLimit;
        dispatch(getOpportunitieses({ filter: filter1, limit: pageLimit, skip: skips }));
      }
      else {
        dispatch(getOpportunitieses({ filter: filter1, limit: pageLimit, skip: 0 }));
      }
    } else {
      if (page > 0) {
        skips = page * pageLimit;
        dispatch(getOpportunitieses({ filter: filter2, limit: pageLimit, skip: skips }));
      }
      else {
        dispatch(getOpportunitieses({ filter: filter2, limit: pageLimit, skip: 0 }));
      }
    }
  }

  useEffect(() => {
    if (opprtunitiesState.opportunitieses) {
      setOpportunitieses(opprtunitiesState.opportunitieses.result);
      setPageCount(Math.ceil(opprtunitiesState.opportunitieses.summary.filteredsize / pageLimit) == 0 ? 1
        :
        Math.ceil(opprtunitiesState.opportunitieses.summary.filteredsize / pageLimit)
      );
    }
    if (opprtunitiesState.isDeleted) {
      handlePageClick(page);
    }
    if (opprtunitiesState.isUpdated) {
      handlePageClick()

    }
  }, [opprtunitiesState])

  const [opportunityModal, setOpportunityModal] = useState(false);
  const [opportunityModalData, setOpportunityModalData] = useState('')

  const getOpportunityData = (opportunityData) => {
    setOpportunityModalData(opportunityData)
    setOpportunityModal(true)
  }

  const cancelOpportunity = () => {
    const Data = {
      opportunitiesId: opportunityModalData.opportunity_id,
      updatedData: {
        "step": 0,
        "status": "Cancel"
      }
    }
    dispatch(updateOpportunities(Data))
    setOpportunityModal(false)
  }

  useEffect(() => {
    sessionStorage.setItem("opportunityListQuery", JSON.stringify({
      skip: page,
      limit: pageLimit,
      opportunityStatus: opportunityStatus,

    }))
  }, [page, pageLimit, opportunityStatus])

  return (
    <React.Fragment>
      <CancelOpportunityModal
        show={opportunityModal}
        onCancelClick={cancelOpportunity}
        onCloseClick={() => setOpportunityModal(false)}
        data={opportunityModalData}
      />
      <div className="page-content">
        {permissions.includes("system.sales.opportunities.read") ? <Container fluid>
          <Breadcrumbs title="Opportunities" breadcrumbItem="Sales" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={opportunitieses}
                    isGlobalFilter={true}
                    isAddOpportunities={permissions.includes("system.sales.opportunities.write") ? true : false}
                    customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                    customPageSize={10}
                    className="custom-header-css"
                    handlePageClick={handlePageClick}
                    customPageCount={pageCount}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
          :
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                      <div>
                        <h1><i className='bx bxs-lock'></i></h1>
                        <h3>Access Denied</h3>
                        <div className="text-muted">You don't have permission to access this module</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }

      </div>
    </React.Fragment>
  );
};

export default Opportunities;


