import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';
import { UserID, Name, LoginEmail, LockStatus, UserRoleName, CompanyName, Status } from './CustomerUserListCol';
import axios from "axios";
import getHeader from "config/header";
import { toast } from "react-toastify";
import customerUserContext from "./customerUserContext";
import expiredSessionHandler from "common/expiredSessionHandler";
import { useDispatch, useSelector } from "react-redux";
import { deleteAppUser, getAppUsers } from "store/administration/customerUser/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const CustomerUser = props => {


    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const pageStatus = query.get("status")
    const Busid = query.get("Busid")


    const history = useHistory()
    const dispatch = useDispatch()

    document.title = "Companies | Maxims B2B Portal";

    const [permissions, setPermissions] = useState('')
    const [users, setUsers] = useState([])
    // const [pageStatus, setPageStatus] = useState(true)
    const [pageCount, setPageCount] = useState(1);
    // const [page, setPage] = useState(0);
    // const [pageLimit, setPageLimit] = useState(10);

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    const appUsersState = useSelector(state => state.appUserReducer)

    useEffect(() => {
        if (appUsersState.appUsers !== null) {
            setUsers(appUsersState.appUsers.result);
            setPageCount(Math.ceil(appUsersState.appUsers.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(appUsersState.appUsers.summary.filteredsize / pageLimit)
            );
        }
    }, [appUsersState])

    const [customFilterData, setCustomFilterData] = useState("")

    useEffect(() => {
        handlePageClick()
    }, [pageStatus, pageLimit, page, Busid, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/customerUser?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
        }
    }, [customFilterData])

    const handlePageClick = (index) => {

        let filter1 = `{"app_business_id":${Busid},"is_active":${pageStatus}, "$or":[
            {"app_user_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_user_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_user_email":{"$regex":"${customFilterData}","$options":"i"}},
            {"is_lock_app_user":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`
        let filter2 = `{"is_active":${pageStatus},"$or":[
            {"app_user_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_user_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_user_email":{"$regex":"${customFilterData}","$options":"i"}},
            {"is_lock_app_user":{"$regex":"${customFilterData}","$options":"i"}}
          ]}`
        let skips = 0;
        // setPage(index);
        if (Busid !== "null") {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getAppUsers({ filter: filter1, limit: pageLimit, skip: skips }));

                // dispatch(getAppUsers({ filter: `{"is_active":${pageStatus},"app_business_id":"${Busid}"}`, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getAppUsers({ filter: filter1, limit: pageLimit, skip: 0 }));

                // dispatch(getAppUsers({ filter: `{"is_active":${pageStatus},"app_business_id":"${Busid}"}`, limit: pageLimit, skip: 0 }));
            }
        } else {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getAppUsers({ filter: filter2, limit: pageLimit, skip: skips }));

                // dispatch(getAppUsers({ filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getAppUsers({ filter: filter2, limit: pageLimit, skip: 0 }));

                // dispatch(getAppUsers({ filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
            }
        }
    }


    // Company Column
    const columns = useMemo(
        () => [
            {
                Header: 'User ID',
                accessor: 'app_user_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <UserID {...cellProps} />;
                }
            },
            {
                Header: 'User Name',
                accessor: 'app_user_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Name {...cellProps} />;
                }
            },
            {
                Header: 'User Email',
                accessor: 'app_user_email',
                filterable: true,
                Cell: (cellProps) => {
                    return <LoginEmail {...cellProps} />;
                }
            },
            {
                Header: 'Access',
                accessor: 'is_lock_app_user',
                filterable: true,
                Cell: (cellProps) => {
                    return <LockStatus {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        // <div className="d-flex gap-3">
                        //     {(permissionAction.includes("system.administration.customerusers.read")) ?
                        //         <>
                        //             <button
                        //                 onClick={() => {
                        //                     history.push(`/updateCustomerUser/${cellProps.row.original.app_user_id}`)
                        //                 }} className="btn btn-success btn-sm">View</button>
                        //         </> : <div className="text-muted">No Access</div>
                        //     }
                        // </div>
                        <div className="d-flex gap-3">
                            {(permissionAction.includes("system.administration.customerusers.read")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/viewCustomerUser/${cellProps.row.original.app_user_id}`)
                                        }} className="btn btn-success btn-sm">View</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                            {(permissionAction.includes("system.administration.customerusers.read")) && (permissionAction.includes("system.administration.customerusers.update")) ||
                                (permissionAction.includes("system.administration.customerusers.read")) && (permissionAction.includes("system.administration.customerusers.delete"))
                                ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/updateCustomerUser/${cellProps.row.original.app_user_id}`)
                                        }} className="btn btn-info btn-sm">Edit</button>
                                </> : <div className="text-muted">No Access</div>
                            }

                        </div>
                    );
                }
            },
        ], [users]);


    // const handleCompanyUsers = (id) => {

    // }

    // const ctxValues = {
    //     onFetch: handleCompanyUsers
    // }

    return (
        <React.Fragment>
            <div className="page-content">
                {permissions.includes("system.administration.customerusers.read") ? <Container fluid>
                    <Breadcrumbs title="User Management" breadcrumbItem="Customers" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={users}
                                        isGlobalFilter={true}
                                        className="custom-header-css"
                                        customPageCount={pageCount}
                                        isCompanyDropdown={true}
                                        isAddCustomerUserList={permissions.includes("system.administration.customerusers.write") ? true : false}
                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                                    // customPageSize={10}
                                    // customPageStatus={(data) => setPageStatus(data)}
                                    // handlePageClick={handlePageClick}
                                    // customPageIndex={page}
                                    // pageSizeTransfer={(data) => setPageLimit(data)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    );
};

export default CustomerUser;
