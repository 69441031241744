import {
    GET_SYS_CREDENTIALS,
    GET_SYS_CREDENTIALS_SUCCESS,
    GET_SYS_CREDENTIALS_FAIL,

    // GET_SYS_USER,
    // GET_SYS_USER_SUCCESS,
    // GET_SYS_USER_FAIL,

    POST_SYS_CREDENTIALS,
    POST_SYS_CREDENTIALS_SUCCESS,
    POST_SYS_CREDENTIALS_FAIL,

    // DELETE_SYS_USERS,
    // DELETE_SYS_USERS_SUCCESS,
    // DELETE_SYS_USERS_FAIL,

    // UPDATE_SYS_CREDENTIALS,
    // UPDATE_SYS_CREDENTIALS_SUCCESS,
    // UPDATE_SYS_CREDENTIALS_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get sys Credentials  //
/////////////////////////


export const getSysCredentials = (payload) => ({
    type: GET_SYS_CREDENTIALS,
    payload: payload
})

export const getSysCredentialsSuccess = sysCredentials => ({
    type: GET_SYS_CREDENTIALS_SUCCESS,
    payload: sysCredentials
})

export const getSysCredentialsFail = error => ({
    type: GET_SYS_CREDENTIALS_FAIL,
    payload: error
})
//////////////////////////////
// Get By id sys User //
//////////////////////////////

// export const getAppSysUser = (payload) => ({
//     type: GET_SYS_USER,
//     payload: payload
// });

// export const getAppSysUserSuccess = sysUsers => ({
//     type: GET_SYS_USER_SUCCESS,
//     payload: sysUsers,
// });

// export const getAppSysUserFail = error => ({
//     type: GET_SYS_USER_FAIL,
//     payload: error,
// });

////////////////////////
// Post sys USER     ///
////////////////////////

export const postSysCredentials = (payload) => ({
    type: POST_SYS_CREDENTIALS,
    payload: payload
});

export const postSysCredentialsSuccess = sysCredentials => ({
    type: POST_SYS_CREDENTIALS_SUCCESS,
    payload: sysCredentials,
});

export const postSysCredentialsFail = error => ({
    type: POST_SYS_CREDENTIALS_FAIL,
    payload: error,
});

///////////////////////////
// Delete sys USERS //
//////////////////////////

// export const deleteAppSysUsers= (payload) => ({
//     type: DELETE_SYS_USERS,
//     payload: payload
// });

// export const deleteAppSysUsersSuccess = () => ({
//     type: DELETE_SYS_USERS_SUCCESS,
// });

// export const deleteAppSysUsersFail = error => ({
//     type: DELETE_SYS_USERS_FAIL,
//     payload: error,
// });

///////////////////////////
// Update sys USERS  //
///////////////////////////

// export const updateSysUsers = (payload) => ({
//     type: UPDATE_SYS_USERS,
//     payload: payload
// });

// export const updateSysUsersSuccess = payload => ({
//     type: UPDATE_SYS_USERS_SUCCESS,
//     payload: payload
// });

// export const updateSysUsersFail = error => ({
//     type: UPDATE_SYS_USERS_FAIL,
//     payload: error,
// });



