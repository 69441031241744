import { takeEvery, put, call } from "redux-saga/effects"
import {
  getTransactionSuccess,
  gettTransactionFail,
  getTransactionDetailSuccess,
  getTransactionDetailFail,
  postTransactionSuccess,
  postTransactionFail,
  updateTransactionSuccess,
  updateTransactionFail,
  deleteTransactionSuccess,
  deleteTransactionFail
} from "./actions"

import {
  getTransaction,
  getTransactionDetail,
  postTransaction,
  updateTransaction,
  deleteTransaction,
} from "helpers/backendApi_helpers";

import {
  GET_TRANSACTION,
  GET_TRANSACTION_DETAIL,
  POST_TRANSACTION,
  UPDATE_TRANSACTION,
  DELETE_TRANSACTION,
} from "./actionTypes";

function* getAppTransaction({ payload: payload }) {
  try {
    const response = yield call(getTransaction, payload);
    yield put(getTransactionSuccess(response))
  } catch (error) {
    yield put(gettTransactionFail(error))
  }
}

function* getAppTransactionDetail({ payload: payload }) {
  try {
    const response = yield call(getTransactionDetail, payload);
    yield put(getTransactionDetailSuccess(response))
  } catch (error) {
    yield put(getTransactionDetailFail(error))
  }
}

function* postAppTransaction({ payload: payload }) {
  try {
    const response = yield call(postTransaction, payload);
    yield put(postTransactionSuccess(response))
  } catch (error) {
    yield put(postTransactionFail(error))
  }
}

function* deleteAppTransacttion({ payload: payload }) {
  try {
    const response = yield call(deleteTransaction, payload);
    yield put(deleteTransactionSuccess(response))
  } catch (error) {
    yield put(deleteTransactionFail(error))
  }
}

function* updateAppTransaction({ payload: payload }) {
  try {
    const response = yield call(updateTransaction, payload);
    yield put(updateTransactionSuccess(response))
  } catch (error) {
    yield put(updateTransactionFail(error))
  }
}

function* AppTransactionSaga() {
  yield takeEvery(GET_TRANSACTION, getAppTransaction)
  yield takeEvery(GET_TRANSACTION_DETAIL, getAppTransactionDetail)
  yield takeEvery(POST_TRANSACTION, postAppTransaction)
  yield takeEvery(DELETE_TRANSACTION, deleteAppTransacttion)
  yield takeEvery(UPDATE_TRANSACTION, updateAppTransaction)
}

export default AppTransactionSaga

