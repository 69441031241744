import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
// import creditData from "./Credit";
import { Address, AddressesName, Status, Company, Site } from './AddressesListCol';
import { getAddresses, getAllAddress } from "store/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Addresses = props => {

    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const Busid = query.get("Busid")
    const SiteId = query.get("SiteId")
    const pageStatus = query.get("status")

    const history = useHistory()
    const [permissions, setPermissions] = useState('')
    const [addresses, setAddresses] = useState([])


    const [pageCount, setPageCount] = useState(1);
    // const [page, setPage] = useState(0);
    // const [pageLimit, setPageLimit] = useState(10);

    const addressState = useSelector(state => state.addressReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    // Company Column
    const columns = useMemo(
        () => [

            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Company {...cellProps} />
                }
            },
            {
                Header: 'Site',
                accessor: 'app_department_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Site {...cellProps} />
                }
            },
            {
                Header: 'Site ID',
                accessor: 'app_department_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <Site {...cellProps} />
                }
            },
            {
                Header: 'Address Name',
                accessor: 'app_address_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <AddressesName  {...cellProps} />
                }
            },
            {
                Header: ' Delivery Address',
                accessor: 'app_delivery_address',
                filterable: true,
                Cell: (cellProps) => {
                    return <Address {...cellProps} />
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {(permissionAction.includes("system.sales.address.read")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/viewAddress/${cellProps.row.original.app_address_id}?BusId=${cellProps.row.original.app_business_id}`)
                                        }} className="btn btn-success btn-sm">View</button>
                                </> : ""
                            }
                            {(permissionAction.includes("system.sales.address.read")) && (permissionAction.includes("system.sales.address.updates")) ||
                                (permissionAction.includes("system.sales.address.read")) && (permissionAction.includes("system.sales.address.delete")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/updateAddress/${cellProps.row.original.app_address_id}?BusId=${cellProps.row.original.app_business_id}`)
                                        }} className="btn btn-info btn-sm">Edit</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                        </div>
                    );
                }
            },
        ], [addresses]
    );


    useEffect(() => {
        if (Busid) {
            if (addressState.addresses !== null) {
                setAddresses(addressState.addresses.result)
                setPageCount(Math.ceil(addressState.addresses.summary.filteredsize / pageLimit) == 0 ? 1
                    :
                    Math.ceil(addressState.addresses.summary.filteredsize / pageLimit)
                );
            }
        }

        if (addressState.error) {
        }
    }, [addressState])

    //======================global filter==================================//
    const [customFilterData, setCustomFilterData] = useState("")

    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, Busid, SiteId, pageStatus, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/addresses?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        }
    }, [customFilterData])

    const handlePageClick = (index) => {
        let filter1 = `{"app_department_id":${JSON.stringify(SiteId)},"is_active":${pageStatus}, "$or":[
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_address_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_delivery_address":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`

        let filter2 = `{"is_active":${pageStatus},"$or":[
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_address_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_delivery_address":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`
        const companyID = Busid
        if (Busid !== "null" && SiteId !== "null") {
            let skips = 0
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getAddresses({ companyID, filter: filter1, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getAddresses({ companyID, filter: filter1, limit: pageLimit, skip: 0 }));
            }
        } else {
            let skips = 0
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getAllAddress({ filter: filter2, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getAllAddress({ filter: filter2, limit: pageLimit, skip: 0 }));
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {permissions.includes("system.sales.address.read") ? <Container fluid>
                    <Breadcrumbs title="Addresses " breadcrumbItem="Sales" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={addresses}
                                        isGlobalFilter={true}
                                        className="custom-header-css"
                                        customPageCount={pageCount}
                                        isCompanyDropdownWithSite={true}
                                        isSiteDropdown={true}
                                        isAddSalesAddress={permissions.includes("system.sales.address.write") ? true : false}
                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                    // isDepartmentList={permissions.includes("system.customers.credit.write") ? true : false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    );
};

export default Addresses;