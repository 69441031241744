import {
    GET_CREDITS,
    GET_CREDITS_SUCCESS,
    GET_CREDITS_FAIL,

    GET_CREDIT,
    GET_CREDIT_SUCCESS,
    GET_CREDIT_FAIL,

    GET_ALL_CREDIT,
    GET_ALL_CREDIT_SUCCESS,
    GET_ALL_CREDIT_FAIL,

    POST_CREDIT,
    POST_CREDIT_SUCCESS,
    POST_CREDIT_FAIL,

    DELETE_CREDIT,
    DELETE_CREDIT_SUCCESS,
    DELETE_CREDIT_FAIL,

    UPDATE_CREDIT,
    UPDATE_CREDIT_SUCCESS,
    UPDATE_CREDIT_FAIL,
} from './actionsTypes'

//////////////////////
//  get Credits     //
//////////////////////


export const getCredits = (payload) => ({
    type: GET_CREDITS,
    payload: payload
})

export const getCreditsSuccess = Credits => ({
    type: GET_CREDITS_SUCCESS,
    payload: Credits
})

export const getCreditsFail = error => ({
    type: GET_CREDITS_FAIL,
    payload: error
})

//////////////////////////////
// Get  id Credit           //
//////////////////////////////

export const getCredit = (payload) => ({
    type: GET_CREDIT,
    payload: payload
});

export const getCreditSuccess = Credit => ({
    type: GET_CREDIT_SUCCESS,
    payload: Credit,
});

export const getCreditFail = error => ({
    type: GET_CREDIT_FAIL,
    payload: error,
});

//////////////////////////////
// Get All Credit           //
//////////////////////////////

export const getAllCredit = (payload) => ({
    type: GET_ALL_CREDIT,
    payload: payload
});

export const getAllCreditSuccess = Credit => ({
    type: GET_ALL_CREDIT_SUCCESS,
    payload: Credit,
});

export const getAllCreditFail = error => ({
    type: GET_ALL_CREDIT_FAIL,
    payload: error,
});

////////////////////////
// Post Credit        //
////////////////////////

export const postCredit = (payload) => ({
    type: POST_CREDIT,
    payload: payload
});

export const postCreditSuccess = Credit => ({
    type: POST_CREDIT_SUCCESS,
    payload: Credit,
});

export const postCreditFail = error => ({
    type: POST_CREDIT_FAIL,
    payload: error,
});

///////////////////////////
// Delete Credit         //
//////////////////////////

export const deleteCredit= (payload) => ({
    type: DELETE_CREDIT,
    payload: payload
});

export const deleteCreditSuccess = () => ({
    type: DELETE_CREDIT_SUCCESS,
});

export const deleteCreditFail = error => ({
    type: DELETE_CREDIT_FAIL,
    payload: error,
});

///////////////////////////
// Update  Credit        //
///////////////////////////

export const updateCredit = (payload) => ({
    type: UPDATE_CREDIT,
    payload: payload
});

export const updateCreditSuccess = payload => ({
    type: UPDATE_CREDIT_SUCCESS,
    payload: payload
});

export const updateCreditFail = error => ({
    type: UPDATE_CREDIT_FAIL,
    payload: error,
});