import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

import BusinessProducts from './products/products/reducer'
import AppBusinessUnits from './administration/businessUnit/reducer';
import BUDivisionReducer from "./administration/businessUnitDivision/reducer"

// administration
import AppSysRoles from "./administration/portalRole/reducer"
import sysUsersReducer from "./administration/portalUser/reducer"

import AppRoles from "./administration/customerRole/reducer"
import appUserReducer from "./administration/customerUser/reducer"

// platform Actions 
import AppPlatformActions from "./actions/platformActions/reducer"

// Business Action
import AppBusinessActions from "./actions/businessActions/reducer"

// sys credentials
import sysCredentialsReducer from "./credentials/sysUserCredentials/reducer"
import IndustryReducer from "./customers/genericIndustry/reducer"

// APP credentials
import appCredentialsReducer from "./credentials/appUserCredentials/reducer"

// business 
import businessReducer from "./customers/company/reducer"
import departmentReducer from "./customers/company/department/reducer"
import contactsReducer from "./customers/company/contacts/reducer"
import addressReducer from "./customers/company/address/reducer"
import ownerReducer from "./customers/company/owner/reducer"

// Credits 
import creditsReducer from "./customers/credits/reducer"

//Login Attempt 
import LoginRetry from "./settings/loginRetry/reducer"

// Otp TimeOut
import OtpTimeouts from "./settings/otpTimeout/reducer"

// Password Policy
import ActivitiesReducer from "./activityLog/reducer"

//smtp
import SmtpReducer from "./settings/smtp/reducer"
import PassWordPolicysReducer from "./settings/passwordPolicy/reducer"
import AppOpportunitiesReducer from "./Sales/Opportunities/reducer"
import AppQuotationReducer from "./Sales/Quotation/reducer"
import AppInvoiceReducer from "./finance/Invoice/reducer"

//pricing model
import PricingModelReducer from "./products/pricingModel/reducer"

//accounts
import AppAccounts from "./finance/accounts/reducer"

import AppsysUserProductReducer from "./Sales/sysUserProduct/reducer"

//transaction
import AppTransactionReducer from "./finance/transaction/reducer"

//proposal version
import ProposalVersionReducer from "./Sales/ProposalVersion/reducer"


//finalize proposal
import AppFinalizeProposalReducer from "./Sales/finalizeProposal/reducer"

//proposal clone version
import ProposalCloneVersionReducer from "./Sales/proposalcloneVersion/reducer"

//opportunity clone version
import OpportunityCloneVersionReducer from "./Sales/opportunitycloneversion/reducer"

//Application Template
import ApplicationTemplateReducer from "./applicationTemplate/reducer"

import AppSynchronize from "./synchronize/reducer"
import CouponReducer from "./Sales/coupon/reducer"
import AttachmentReducer from "./settings/attachments/reducer"

import DownloadLogReducer from "./Sales/downloadlog/reducer"
import CouponGenerateReducer from "./Sales/CouponGenerate/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  Dashboard,
  DashboardSaas,
  BusinessProducts,
  AppBusinessUnits,
  AppPlatformActions,
  AppSysRoles,
  sysUsersReducer,
  sysCredentialsReducer,
  businessReducer,
  IndustryReducer,
  departmentReducer,
  contactsReducer,
  ownerReducer,
  AppRoles,
  AppBusinessActions,
  appCredentialsReducer,
  appUserReducer,
  creditsReducer,
  LoginRetry,
  OtpTimeouts,
  // PassWordPolicys,
  ActivitiesReducer,
  BUDivisionReducer,
  addressReducer,
  SmtpReducer,
  PassWordPolicysReducer,
  AppOpportunitiesReducer,
  AppQuotationReducer,
  AppInvoiceReducer,
  PricingModelReducer,
  AppAccounts,
  AppsysUserProductReducer,
  AppTransactionReducer,
  ProposalVersionReducer,
  AppFinalizeProposalReducer,
  ProposalCloneVersionReducer,
  OpportunityCloneVersionReducer,
  ApplicationTemplateReducer,
  AppSynchronize,
  CouponReducer,
  AttachmentReducer,
  DownloadLogReducer,
  CouponGenerateReducer,
})

export default rootReducer
