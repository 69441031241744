const languages = {
  en: {
    label: "EN"
  },
  // zh: {
  //   label: "中文"
  // },
}

export default languages
