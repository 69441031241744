import React from "react"
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from "react-select";
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Collapse,
    Nav, NavItem, NavLink, TabContent, TabPane, CardText, Table
} from "reactstrap"
import classnames from 'classnames';
import axios from 'axios';
import getHeader from 'config/header';
import { useDispatch, useSelector } from "react-redux";
import { postSysRoles } from "helpers/backendApi_helpers";
import { postAppSysRoles } from "store/administration/portalRole/actions"
import { getPlatformActions } from "store/actions/platformActions/actions"
import { getSysUsers, updateSysUsers } from "store/administration/portalUser/actions"
import { postSysCredentials } from "store/credentials/sysUserCredentials/actions"
import { toast } from "react-toastify";

function CreateRole() {

    const history = useHistory()
    const { register, handleSubmit, setValue, formState: { errors } } = useForm()

    const [permissionName, setPermissionName] = useState(null)
    const [permissions, setPermissions] = useState([])

    const dispatch = useDispatch();

    const platformActions = useSelector(state => state.AppPlatformActions.actions);


    useEffect(() => {
        if (platformActions !== null) {
            setPermissionName(platformActions.app_name);
            setPermissions(platformActions.modules);
        }
    }, [platformActions]);


    //platform Action Api
    useEffect(() => {
        dispatch(getPlatformActions());
    }, [dispatch]);

    // Accordions Tab State
    const [col, setCol] = useState([])
    const [activeTab, setactiveTab] = useState("");


    // Manage Departments's MultiSelect
    const [usersID, setUsersID] = useState([])
    const [userList, setUserList] = useState([])
    const [selectedMulti, setselectedMulti] = useState([]);
    const [optionGroup, setOptionGroup] = useState([{ options: [] }])

    const sysUserFullData = useSelector(state => state.sysUsersReducer.sysUsers);

    // Sys User Api
    useEffect(() => {
        dispatch(getSysUsers({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));
    }, []);

    useEffect(() => {
        setOptionGroup('')
        setselectedMulti([])
        if (sysUserFullData !== null) {
            setUserList(sysUserFullData.result)
        }
    }, [sysUserFullData]);

    const sysCredentialState = useSelector(state => state.sysCredentialsReducer);

    useEffect(() => {
        if (userList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in userList) {
                let data = {
                    label: userList[i].sys_user_name,
                    value: userList[i].sys_user_id
                }
                options.push(data)
            }
            setOptionGroup(options)
        }
    }, [userList])

    const handlePostChange = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroup.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setUsersID(postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setUsersID(postArray)
                }
            }
        });
        setselectedMulti({
            sys_user_id: postArray,
        });
    };

    useEffect(() => {
        if (sysCredentialState.isCreated) {
            history.goBack()
        }
    }, [sysCredentialState])


    // Create Role
    const createRole = async (data) => {
        $('#createRoleBtn').attr('disabled', true)
        const token = sessionStorage.getItem('authUser')
        let value
        if (data.is_active === "active") {
            value = true
        } else if (data.is_active === "inactive") {
            value = false
        }

        const newRoleData = {
            ...data,
            is_active: value,
        }

        const sysUsersRolePut = async (UserID, roleArray) => {
            const sysUsersRolePutData = {
                sysUserId: UserID,
                updatedData: roleArray
            }
            console.log("sysUsersRolePutData", sysUsersRolePutData);
            dispatch(updateSysUsers(sysUsersRolePutData));
        }

        const sysRoleIdcredential = async (roleID, newCredentialData) => {
            const credentialData = {
                sysRoleId: roleID,
                data: newCredentialData
            }
            dispatch(postSysCredentials(credentialData));
            $('#createRoleBtn').attr('disabled', false)
        }
        try {
            delete newRoleData.credential
            const response = await postSysRoles(newRoleData)
            if (response) {
                const roleID = response.sys_role_id
                const newCredentialData = {
                    credentials: []
                }
                let dataPermissions = data.credential
                for (let i in dataPermissions) {
                    let credentialData = {
                        credential: dataPermissions[i]
                    }
                    newCredentialData.credentials.push(credentialData)
                }
                // role add in users
                for (let x of usersID) {
                    let UserID = x.value
                    let [dta] = userList.filter((items) => items.sys_user_id === UserID)
                    const roleArray = { sys_user_roles: [...dta.sys_user_roles, roleID] }
                    // const roleArray = { sys_user_role_id: [...dta.sys_user_role_id, roleID] }
                    await sysUsersRolePut(UserID, roleArray)
                }
                await sysRoleIdcredential(roleID, newCredentialData)
            }
        } catch (error) {
            console.log("error", error);
            if (error.ErrorStatus == 4023) {
                $('#createRoleBtn').attr('disabled', false)
                toast.info(error.ErrorDetail)
            }
        }
    }

    const [selectedPermissions, setSelectedPermissions] = useState({}); // Separate state for selected permissions

    const toggleAccordionCollapse = (index) => {
        let colItems = [...col];
        if (colItems[index] === null || colItems[index] === undefined || colItems[index] === false) {
            for (let i in colItems) {
                colItems[i] = false;
            }
            colItems[index] = true;
            setactiveTab(index + '-' + "0");
        } else {
            colItems[index] = false;
        }
        setCol(colItems);
    };

    const toggleAccordionTabs = (index, subIndex) => {
        let activeTab = index + '-' + subIndex;
        setactiveTab(activeTab);
    };

    const handleSelectAll = (index, subIndex) => {
        const selectedPermissionsCopy = { ...selectedPermissions };
        selectedPermissionsCopy[`${index}-${subIndex}`] = !selectedPermissions[`${index}-${subIndex}`];

        // Toggle all function checkboxes for the submodule
        permissions[index].submodules[subIndex].functions.forEach((_, functIndex) => {
            selectedPermissionsCopy[`${index}-${subIndex}-${functIndex}`] = selectedPermissionsCopy[`${index}-${subIndex}`];
        });

        setSelectedPermissions(selectedPermissionsCopy);
    };

    const handleFunctionCheckbox = (index, subIndex, functIndex) => {
        const selectedPermissionsCopy = { ...selectedPermissions };
        selectedPermissionsCopy[`${index}-${subIndex}-${functIndex}`] = !selectedPermissions[`${index}-${subIndex}-${functIndex}`];

        // Check if all function checkboxes for the submodule are selected, then set submodule checkbox to true
        const allFunctionsSelected = permissions[index].submodules[subIndex].functions.every(
            (_, i) => selectedPermissionsCopy[`${index}-${subIndex}-${i}`]
        );
        selectedPermissionsCopy[`${index}-${subIndex}`] = allFunctionsSelected;

        setSelectedPermissions(selectedPermissionsCopy);
    };



    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Portal Role" breadcrumbItem="Portal Role" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createRole)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Role Name<span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="8" md="8">
                                                <input type="text" className='form form-control' {...register('sys_role_name', { required: true })} />
                                                {errors.sys_role_name && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Role Description<span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="8" md="8">
                                                <input type="text" className='form form-control' {...register('sys_role_description', { required: true })} />
                                                {errors.sys_role_description && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Permissions <span style={{ color: "steelblue" }}>[{permissionName}]</span></Label>
                                            <Col lg="8" md="8">
                                            <div className="accordion" id="accordion">
                                                    {permissions && permissions.map((permission, index) => {
                                                        return (
                                                            <div key={index} className="accordion-item">
                                                                <h2 className="accordion-header" id="headingTwo">
                                                                    <button
                                                                        className={classnames("accordion-button", "fw-medium", { collapsed: !col[index] })}
                                                                        type="button"
                                                                        onClick={() => toggleAccordionCollapse(index)}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {permission.module_name}
                                                                    </button>
                                                                </h2>
                                                                <Collapse isOpen={col[index] ? col[index] : false} className="accordion-collapse">
                                                                    <div className="accordion-body">
                                                                        <div className="text-muted">
                                                                            <Nav tabs className="d-flex justify-content-between">
                                                                                {permission.submodules.map((subModules, subIndex) => {
                                                                                    return (
                                                                                        <NavItem key={subIndex}>
                                                                                            <NavLink
                                                                                                style={{ cursor: "pointer" }}
                                                                                                className={classnames({
                                                                                                    active: activeTab === (index + '-' + subIndex),
                                                                                                })}
                                                                                                onClick={() => {
                                                                                                    toggleAccordionTabs(index, subIndex);
                                                                                                }}
                                                                                            >
                                                                                                {subModules.module_name}
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    );
                                                                                })}
                                                                            </Nav>
                                                                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                                                                {permission.submodules.map((submodules, subIndex) => {
                                                                                    return (
                                                                                        <TabPane key={subIndex} tabId={index + '-' + subIndex}>
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="mb-0">
                                                                                                        <Table striped hover>
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>#</th>
                                                                                                                    <th onClick={() => handleSelectAll(index, subIndex)}>Function Name</th>
                                                                                                                    <th onClick={() => handleSelectAll(index, subIndex)}>
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            checked={selectedPermissions[`${index}-${subIndex}`]}
                                                                                                                            // onClick={() => handleSelectAll(index, subIndex)}
                                                                                                                            value={submodules.permission} {...register(`credential`)}
                                                                                                                        />
                                                                                                                        Select All
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            {submodules.functions.map((funct, functIndex) => {
                                                                                                                return (
                                                                                                                    <tbody key={functIndex}>
                                                                                                                        <tr>
                                                                                                                            <td>{functIndex + 1}</td>
                                                                                                                            <td onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}>{funct.function_name}</td>
                                                                                                                            <td onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}>
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    checked={selectedPermissions[`${index}-${subIndex}-${functIndex}`]}
                                                                                                                                    // onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}
                                                                                                                                    value={funct.permission}  {...register(`credential`)}
                                                                                                                                />
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                );
                                                                                                            })}
                                                                                                        </Table>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </TabPane>
                                                                                    );
                                                                                })}
                                                                            </TabContent>
                                                                        </div>
                                                                    </div>
                                                                </Collapse>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Users
                                            </Label>
                                            <Col lg="8" md="8">
                                                <Select
                                                    value={selectedMulti.sys_user_id}
                                                    isMulti={true}
                                                    onChange={handlePostChange}
                                                    options={optionGroup}
                                                    name={"sys_user_id"}
                                                    className="modal__input"
                                                    maxMenuHeight={250}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="8" md="8">
                                                <select className="form-select d-inline" {...register('is_active')}>
                                                    {/* <option hidden value="">Select Status</option> */}
                                                    <option value="inactive">INACTIVE</option>
                                                    <option value="active">ACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="8" md="8">
                                                <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                                                    Back
                                                </Button>
                                                <Button id="createRoleBtn" color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default CreateRole