import {
    GET_APP_ROLES,
    GET_APP_ROLES_SUCCESS,
    GET_APP_ROLES_FAIL,

    GET_APP_ROLE,
    GET_APP_ROLE_SUCCESS,
    GET_APP_ROLE_FAIL,

    POST_APP_ROLES,
    POST_APP_ROLES_SUCCESS,
    POST_APP_ROLES_FAIL,

    DELETE_APP_ROLES,
    DELETE_APP_ROLES_SUCCESS,
    DELETE_APP_ROLES_FAIL,

    UPDATE_APP_ROLES,
    UPDATE_APP_ROLES_SUCCESS,
    UPDATE_APP_ROLES_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get app roles      //
/////////////////////////

export const getAppRoles = (payload) => ({
    type: GET_APP_ROLES,
    payload: payload
})

export const getAppRolesSuccess = appRoles => ({
    type: GET_APP_ROLES_SUCCESS,
    payload: appRoles
})

export const getAppRolesFail = error => ({
    type: GET_APP_ROLES_FAIL,
    payload: error
})

///////////////////////////
//   Get By id app role  //
///////////////////////////

export const getAppRole = (payload) => ({
    type: GET_APP_ROLE,
    payload: payload
})

export const getAppRoleSuccess = appRole => ({
    type: GET_APP_ROLE_SUCCESS,
    payload: appRole
})

export const getAppRoleFail = error => ({
    type: GET_APP_ROLE_FAIL,
    payload: error
})

///////////////////////////
//   Post app Role       //
///////////////////////////

export const postAppRoles = (payload) => ({
    type: POST_APP_ROLES,
    payload: payload
})

export const postAppRolesSuccess = appRoles => ({
    type: POST_APP_ROLES_SUCCESS,
    payload: appRoles
})

export const postAppRolesFail = error => ({
    type: POST_APP_ROLES_FAIL,
    payload: error
})

///////////////////////////
// Delete Business Unit //
//////////////////////////

export const deleteAppRoles = (payload) => ({
    type: DELETE_APP_ROLES,
    payload: payload
})

export const deleteAppRolesSuccess = () => ({
    type: DELETE_APP_ROLES_SUCCESS,
})

export const deleteAppRolesFail = error => ({
    type: DELETE_APP_ROLES_FAIL,
    payload: error
})

///////////////////////////
// Update Business Unit  //
///////////////////////////

export const updateAppRoles = (payload) => ({
    type: UPDATE_APP_ROLES,
    payload: payload
});

export const updateAppRolesSuccess = payload => ({
    type: UPDATE_APP_ROLES_SUCCESS,
    payload: payload
});

export const updateAppRolesFail = error => ({
    type: UPDATE_APP_ROLES_FAIL,
    payload: error,
});