import {
    GET_SMTP,
    GET_SMTP_SUCCESS,
    GET_SMTP_FAIL,
    POST_SMTP,
    POST_SMTP_SUCCESS,
    POST_SMTP_FAIL,
    UPDATE_SMTP,
    UPDATE_SMTP_SUCCESS,
    UPDATE_SMTP_FAIL
} from "./actionTypes"

const INIT_STATE = {
    smtp: null,
    loading: false,
    isCreated: false,
    isUpdated: false,
    error: {}
}

const SmtpReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SMTP:
            return {
                ...state,
                loading: true,
                smtp: null,
                isCreated: false,
                isUpdated: false,
            }
        case GET_SMTP_SUCCESS:
            return {
                ...state,
                loading: false,
                smtp: action.payload,
                isCreated: false,
                isUpdated: false,
            }
        case GET_SMTP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isUpdated: false
            }
        case POST_SMTP:
            return {
                ...state,
                loading: true,
                smtp: null,
                isCreated: false,
                isUpdated: false
            }
        case POST_SMTP_SUCCESS:
            return {
                ...state,
                loading: false,
                smtp: action.payload,
                isCreated: true,
                isUpdated: false
            }
        case POST_SMTP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isUpdated: false
            }
        case UPDATE_SMTP:
            return {
                ...state,
                loading: true,
                smtp: null,
                isCreated: false,
                isUpdated: false
            }
        case UPDATE_SMTP_SUCCESS:
            return {
                ...state,
                loading: false,
                smtp: action.payload,
                isCreated: false,
                isUpdated: true
            }
        case UPDATE_SMTP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isUpdated: false
            }
        default:
            return state;
    }
}
export default SmtpReducer