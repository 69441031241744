import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CHARTS_DATA } from "./actionTypes";
import {
    apiSuccess, apiFail,
    getSysUsersProfileSuccess, getSysUsersProfileFail,
    getNotificationApprovalStatusSuccess, getNotificationApprovalStatusFail,
    getNotificationPendingStatusSuccess, getNotificationPendingStatusFail,
} from "./actions";


//Include Both Helper File with needed methods
import {
    getWeeklyData,
    getYearlyData,
    getMonthlyData
} from "../../helpers/fakebackend_helper";

import { getSysUserProfile, getNotificationApproval, getNotificationPending } from "../../helpers/backendApi_helpers"

import { GET_SYS_USERS_PROFILE,GET_NOTIFICATION_APPROVAL_STATUS, GET_NOTIFICATION_PENDING_STATUS } from "./actionTypes";


function* fetchSysUsersProfile({ payload: payload }) {
    try {
        const response = yield call(getSysUserProfile, payload);
        yield put(getSysUsersProfileSuccess(response))
    } catch (error) {
        yield put(getSysUsersProfileFail(error))
    }
}

function* fetchNotificationApprovalStatus({ payload: payload }) {
    try {
        const response = yield call(getNotificationApproval, payload);
        yield put(getNotificationApprovalStatusSuccess(response))
    } catch (error) {
        yield put(getNotificationApprovalStatusFail(error))
    }
}

function* fetchNotificationPendingStatus({ payload: payload }) {
    try {
        const response = yield call(getNotificationPending, payload);
        yield put(getNotificationPendingStatusSuccess(response))
    } catch (error) {
        yield put(getNotificationPendingStatusFail(error))
    }
}



function* getChartsData({ payload: periodType }) {
    try {
        var response;
        if (periodType == "monthly") {
            response = yield call(getWeeklyData, periodType);
        }
        if (periodType == "yearly") {
            response = yield call(getYearlyData, periodType);
        }
        if (periodType == "weekly") {
            response = yield call(getMonthlyData, periodType);
        }

        yield put(apiSuccess(GET_CHARTS_DATA, response));
    } catch (error) {
        yield put(apiFail(GET_CHARTS_DATA, error));
    }
}

export function* watchGetChartsData() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);
}

function* dashboardSaga() {
    yield all([fork(watchGetChartsData)]);
    yield takeEvery(GET_SYS_USERS_PROFILE, fetchSysUsersProfile);
    yield takeEvery(GET_NOTIFICATION_APPROVAL_STATUS, fetchNotificationApprovalStatus)
    yield takeEvery(GET_NOTIFICATION_PENDING_STATUS, fetchNotificationPendingStatus)
}

export default dashboardSaga;
