import axios from 'axios';
import getHeader from 'config/header';
import moment from 'moment/moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Badge } from 'reactstrap';


const PricingModelType = (cell) => {
    return cell.value ? cell.value : '';
};

const PricingModelCode = (cell) => {
    return cell.value ? cell.value : '';
};

const PricingModelName = (cell) => {
    return cell.value ? cell.value : '';
};
const ApprovalStatus = (cell) => {
    return (
        <Badge
            className={"font-size-12 badge-soft-" +
                (cell.value === "Approved" ? "success" : "danger" && cell.value === "Pending" ? "warning" : "danger")}
            pill
        >
            {cell.value}
        </Badge>
    )
};

const ValidPeriod = (cell) => {
    return cell.value ? moment(cell.value).format('YYYY-MM-DD') + " to " + moment(cell.EndDate).format('YYYY-MM-DD') : "";
};

const Status = (cell) => {
    return (
        <Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
            {cell.value ? "ACTIVE" : "INACTIVE"}
        </Badge>
    )
};

// const Status = (cell) => {
//     return (
//         <Badge
//             className={"font-size-12 badge-soft-" +
//                 (cell.value === "Approved" ? "success" : "danger" && cell.value === "Pending" ? "warning" : "danger")}
//             pill
//         >
//             {cell.value}
//         </Badge>
//     )
// };


export {
    PricingModelType,
    PricingModelCode,
    PricingModelName,
    ValidPeriod,
    Status,
    ApprovalStatus
};