import { takeEvery, put, call } from "redux-saga/effects"
import {
    postOpportunityCloneVersionSuccess,
    postOpportunityCloneVersionFail
} from "./actions"

import {
  postOpportunityCloneVersion
}
  from "helpers/backendApi_helpers";

import {
POST_OPPORTUNITY_CLONE_VERSION,
} from "./actionTypes";




function* postAppOpportunityCloneVersion({ payload: payload }) {
  try {
    const response = yield call(postOpportunityCloneVersion, payload);
    yield put(postOpportunityCloneVersionSuccess(response))
  } catch (error) {
    yield put(postOpportunityCloneVersionFail(error))
  }
}
 
function* AppOpportunityCloneVersionSaga() {
  yield takeEvery(POST_OPPORTUNITY_CLONE_VERSION,postAppOpportunityCloneVersion)
}

export default AppOpportunityCloneVersionSaga





