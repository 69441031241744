import {
  GET_OPPORTUNITIES,
  GET_OPPORTUNITIES_SUCCESS,
  GET_OPPORTUNITIES_FAIL,

  POST_OPPORTUNITIES,
  POST_OPPORTUNITIES_SUCCESS,
  POST_OPPORTUNITIES_FAIL,

  DELETE_OPPORTUNITIES,
  DELETE_OPPORTUNITIES_SUCCESS,
  DELETE_OPPORTUNITIES_FAIL,

  GET_OPPORTUNITIES_DETAIL_SUCCESS,
  GET_OPPORTUNITIES_DETAIL_FAIL,
  GET_OPPORTUNITIES_DETAIL,
  
  UPDATE_OPPORTUNITIES_SUCCESS,
  UPDATE_OPPORTUNITIES_FAIL,
  UPDATE_OPPORTUNITIES,
} from "./actionTypes";

/////////////////////////
//  Get OPPORTUNITIES  //
/////////////////////////

export const getOpportunitieses = (payload) => ({
  type: GET_OPPORTUNITIES,
  payload: payload
});

export const getOpportunitiesesSuccess = products => ({
  type: GET_OPPORTUNITIES_SUCCESS,
  payload: products,
});

export const getOpportunitiesesFail = error => ({
  type: GET_OPPORTUNITIES_FAIL,
  payload: error,
});

//////////////////////////////
// Get By id OPPORTUNITIES  //
//////////////////////////////

export const getOpportunities = (payload) => ({
  type: GET_OPPORTUNITIES_DETAIL,
  payload: payload
});

export const getOpportunitiesDetailSuccess = businessUnit => ({
  type: GET_OPPORTUNITIES_DETAIL_SUCCESS,
  payload: businessUnit,
});

export const getOpportunitiesDetailFail = error => ({
  type: GET_OPPORTUNITIES_DETAIL_FAIL,
  payload: error,
});

////////////////////////
// Post Opportunities///
////////////////////////

export const postOpportunities = (payload) => ({
  type: POST_OPPORTUNITIES,
  payload: payload
});

export const postOpportunitiesSuccess = products => ({
  type: POST_OPPORTUNITIES_SUCCESS,
  payload: products,
});

export const postOpportunitiesFail = error => ({
  type: POST_OPPORTUNITIES_FAIL,
  payload: error,
});

///////////////////////////
// Delete Opportunities //
//////////////////////////

export const deleteOpportunities = (payload) => ({
  type: DELETE_OPPORTUNITIES,
  payload: payload
});

export const deleteOpportunitiesSuccess = () => ({
  type: DELETE_OPPORTUNITIES_SUCCESS,
});

export const deleteOpportunitiesFail = error => ({
  type: DELETE_OPPORTUNITIES_FAIL,
  payload: error,
});

///////////////////////////
// Update OPPORTUNITIES  //
///////////////////////////

export const updateOpportunities = (payload) => ({
  type: UPDATE_OPPORTUNITIES,
  payload: payload
});

export const updateOpportunitiesSuccess = payload => ({
  type: UPDATE_OPPORTUNITIES_SUCCESS,
  payload: payload
});

export const updateOpportunitiesFail = error => ({
  type: UPDATE_OPPORTUNITIES_FAIL,
  payload: error,
});



