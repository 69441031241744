import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Input, FormFeedback, Label, Form, } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
import axios from "axios";
import { geAccountDetail, getAccount } from "store/actions";

import getHeader from "config/header";
import expiredSessionHandler from "common/expiredSessionHandler";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SpinnerModal from "components/Common/SpinnerModal";
import { AccountID, Company, SiteName, CreditLimit, AccountBalance, OracleStatus, Status } from "./AccountListCol"


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const AccountList = props => {
    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const Busid = query.get("Busid")
    const SiteId = query.get("SiteId")
    const history = useHistory()
    const [pageCount, setPageCount] = useState(1);
    const [permissions, setPermissions] = useState('')
    const pageStatus = query.get("status")

    const dispatch = useDispatch();

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    //Get account list
    const appAccountsState = useSelector(state => state.AppAccounts)
    const [accountList, setAccountList] = useState([])

    useEffect(() => {
        if (appAccountsState.appAccounts !== null) {
            setAccountList(appAccountsState.appAccounts.result)
            setPageCount(Math.ceil(appAccountsState.appAccounts.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(appAccountsState.appAccounts.summary.filteredsize / pageLimit)
            );
        }
    }, [appAccountsState])

    const columns = useMemo(
        () => [
            {
                Header: 'Account ID',
                accessor: 'app_account_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <AccountID {...cellProps} />;
                }
            },
            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Company {...cellProps} />
                }
            },
            {
                Header: 'Site Name',
                accessor: 'app_department_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteName {...cellProps} />;
                }
            },
            {
                Header: 'Credit Limit',
                accessor: 'credit_limit',
                filterable: true,
                Cell: (cellProps) => {
                    return <CreditLimit {...cellProps} />;
                }
            },
            {
                Header: 'Account Balance',
                accessor: 'app_account_balance',
                filterable: true,
                Cell: (cellProps) => {
                    return <AccountBalance  {...cellProps} />;
                }
            },
            {
                Header: 'Available Limit',
                accessor: 'available_limit',
                filterable: true,
                Cell: (cellProps) => {
                    return <AccountBalance  {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {
                                (permissionAction.includes("system.finances.accounts.read")) ?
                                    <button
                                        onClick={() => {
                                            history.push(`/viewaccount/${cellProps.row.original.app_account_id}?Busid=${cellProps.row.original.app_business_id}&&skip=${0}&&limit=${10}`)
                                        }} className="btn btn-success btn-sm">View</button> : <div className="text-muted">No Access</div>
                            }

                        </div>
                    );
                }
            },
        ], [accountList]
    );

    const [customFilterData, setCustomFilterData] = useState("");

    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, Busid, SiteId, pageStatus, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/accountlist?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}&&Busid=${Busid}`)
        }
    }, [customFilterData])

    const handlePageClick = (index) => {
        const Business_id = Busid === "null" ? "" : Busid
        let filter = Business_id === "" ? `{"is_active":${pageStatus},"$or":[
            {"app_account_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"credit_limit":{"$regex":"${customFilterData}","$options":"i"}},
            {"oracle_site_ID":{"$regex":"${customFilterData}","$options":"i"}},
            {"oracle_credit_status":{"$regex":"${customFilterData}","$options":"i"}}

        ]}`
            : `{"is_active":${pageStatus},"app_business_id":"${Business_id}","$or":[
            {"app_account_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"credit_limit":{"$regex":"${customFilterData}","$options":"i"}},
            {"oracle_site_ID":{"$regex":"${customFilterData}","$options":"i"}},
            {"oracle_credit_status":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`

        let skips = 0
        if (page > 0) {
            skips = page * pageLimit;
            dispatch(getAccount({ filter: filter, limit: pageLimit, skip: skips }));
        }
        else {
            dispatch(getAccount({ filter: filter, limit: pageLimit, skip: 0 }));
        }
    }

    useEffect(() => {
        sessionStorage.setItem("accountListQuery", JSON.stringify({
            skip: page,
            limit: pageLimit,
            status: pageStatus,
            Busid: Busid,

        }))
    }, [page, pageLimit, pageStatus])

    return (
        <React.Fragment>
            <div className="page-content">
                {permissions.includes("system.finances.accounts.read") ? <Container fluid>
                    <Breadcrumbs title="Account " breadcrumbItem="Finance" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>

                                    <TableContainer
                                        columns={columns}
                                        data={accountList}
                                        isGlobalFilter={true}
                                        className="custom-header-css"
                                        customPageCount={pageCount}
                                        isCompanyDropdown={true}
                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                    // isSiteDropdown={true}
                                    // isAddCredit={permissions.includes("system.finances.creditaccounts.write") ? true : false}
                                    // customPageSize={10}
                                    // handlePageClick={handlePageClick}
                                    // customPageIndex={page}
                                    // pageSizeTransfer={(data) => setPageLimit(data)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :

                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }

            </div>
        </React.Fragment>
    );
};

export default AccountList;