import {
    GET_SYS_USERS,
    GET_SYS_USERS_SUCCESS,
    GET_SYS_USERS_FAIL,

    GET_SYS_USER,
    GET_SYS_USER_SUCCESS,
    GET_SYS_USER_FAIL,

    POST_SYS_USERS,
    POST_SYS_USERS_SUCCESS,
    POST_SYS_USERS_FAIL,

    DELETE_SYS_USERS,
    DELETE_SYS_USERS_SUCCESS,
    DELETE_SYS_USERS_FAIL,

    UPDATE_SYS_USERS,
    UPDATE_SYS_USERS_SUCCESS,
    UPDATE_SYS_USERS_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get sys Users  //
/////////////////////////


export const getSysUsers = (payload) => ({
    type: GET_SYS_USERS,
    payload: payload
})

export const getSysUsersSuccess = sysUsers => ({
    type: GET_SYS_USERS_SUCCESS,
    payload: sysUsers
})

export const getSysUsersFail = error => ({
    type: GET_SYS_USERS_FAIL,
    payload: error
})

//////////////////////////////
// Get By id sys User //
//////////////////////////////

export const getAppSysUser = (payload) => ({
    type: GET_SYS_USER,
    payload: payload
});

export const getAppSysUserSuccess = sysUsers => ({
    type: GET_SYS_USER_SUCCESS,
    payload: sysUsers,
});

export const getAppSysUserFail = error => ({
    type: GET_SYS_USER_FAIL,
    payload: error,
});

////////////////////////
// Post sys USER     ///
////////////////////////

export const postAppSysUsers = (payload) => ({
    type: POST_SYS_USERS,
    payload: payload
});

export const postAppSysUsersSuccess = sysUsers => ({
    type: POST_SYS_USERS_SUCCESS,
    payload: sysUsers,
});

export const postAppSysUsersFail = error => ({
    type: POST_SYS_USERS_FAIL,
    payload: error,
});

///////////////////////////
// Delete sys USERS //
//////////////////////////

export const deleteAppSysUsers= (payload) => ({
    type: DELETE_SYS_USERS,
    payload: payload
});

export const deleteAppSysUsersSuccess = () => ({
    type: DELETE_SYS_USERS_SUCCESS,
});

export const deleteAppSysUsersFail = error => ({
    type: DELETE_SYS_USERS_FAIL,
    payload: error,
});

///////////////////////////
// Update sys USERS  //
///////////////////////////

export const updateSysUsers = (payload) => ({
    type: UPDATE_SYS_USERS,
    payload: payload
});

export const updateSysUsersSuccess = payload => ({
    type: UPDATE_SYS_USERS_SUCCESS,
    payload: payload
});

export const updateSysUsersFail = error => ({
    type: UPDATE_SYS_USERS_FAIL,
    payload: error,
});



