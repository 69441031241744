import {
    GET_PROPOSAL_VERSION ,
    GET_PROPOSAL_VERSION_FAIL,
    GET_PROPOSAL_VERSION_SUCCESS,

    POST_PROPOSAL_VERSION,
    POST_PROPOSAL_VERSION_FAIL,
    POST_PROPOSAL_VERSION_SUCCESS,

    GET_PROPOSAL_VERSION_DETAIL,
    GET_PROPOSAL_VERSION_DETAIL_FAIL,
    GET_PROPOSAL_VERSION_DETAIL_SUCCESS,

    UPDATE_PROPOSAL_VERSION,
    UPDATE_PROPOSAL_VERSION_FAIL,
    UPDATE_PROPOSAL_VERSION_SUCCESS,

    DELETE_PROPOSAL_VERSION,
    DELETE_PROPOSAL_VERSION_FAIL,
    DELETE_PROPOSAL_VERSION_SUCCESS,

    GET_PROPOSAL_BY_OPPORTUNITY_ID,
    GET_PROPOSAL_BY_OPPORTUNITY_ID_FAIL,
    GET_PROPOSAL_BY_OPPORTUNITY_ID_SUCCESS

  } from "./actionTypes";
  
  const INIT_STATE = {
    Proposalversions: null,
    Proposalversion: null,
    Proposalbyopportunityid:null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
  };
  
  const ProposalVersionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_PROPOSAL_VERSION:
        return {
          ...state,
          loading: true,
          Proposalversions: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_PROPOSAL_VERSION_SUCCESS:
        return {
          ...state,
          loading: false,
          Proposalversions: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_PROPOSAL_VERSION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case POST_PROPOSAL_VERSION:
        return {
          ...state,
          loading: true,
          Proposalversion: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case POST_PROPOSAL_VERSION_SUCCESS:
        return {
          ...state,
          loading: false,
          Proposalversion: action.payload,
          isCreated: true,
          isDeleted: false,
          isUpdated: false
        };
      case POST_PROPOSAL_VERSION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case DELETE_PROPOSAL_VERSION:
        return {
          ...state,
          loading: true,
          Proposalversion: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case DELETE_PROPOSAL_VERSION_SUCCESS:
        return {
          ...state,
          loading: false,
          Proposalversions: null,
          isCreated: false,
          isDeleted: true,
          isUpdated: false
        };
      case DELETE_PROPOSAL_VERSION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_PROPOSAL_VERSION_DETAIL:
        return {
          ...state,
          loading: true,
          Proposalversion: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_PROPOSAL_VERSION_DETAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          Proposalversion: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_PROPOSAL_VERSION_DETAIL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case UPDATE_PROPOSAL_VERSION:
        return {
          ...state,
          loading: true,
          Proposalversions: null,
          Proposalversion: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case UPDATE_PROPOSAL_VERSION_SUCCESS:
        return {
          ...state,
          loading: false,
          Proposalversion: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: true
        };
      case UPDATE_PROPOSAL_VERSION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
        case GET_PROPOSAL_BY_OPPORTUNITY_ID:
          return {
            ...state,
            loading: true,
            Proposalversions: null,
            Proposalversion: null,
            Proposalbyopportunityid:null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_PROPOSAL_BY_OPPORTUNITY_ID_SUCCESS:
          return {
            ...state,
            loading: false,
            Proposalbyopportunityid: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_PROPOSAL_BY_OPPORTUNITY_ID_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
      default:
        return state;
    }
  };
  
  export default ProposalVersionReducer;
  
  
  