import { takeEvery, put, call } from "redux-saga/effects"
import {
    getAppUsersSuccess, getAppUsersFail,
    getAppUserSuccess, getAppUserFail,
    postAppUserSuccess, postAppUserFail,
    deleteAppUserSuccess, deleteAppUserFail,
    updateAppUserSuccess, updateAppUserFail,
} from "./actions"

// Api Handler 
import {
    getAppUsers,
    postAppUser,
    deleteAppUser,
    getAppUser,
    updateAppUser,

} from "helpers/backendApi_helpers";


import {
    GET_APP_USERS,
    POST_APP_USER,
    DELETE_APP_USER,
    GET_APP_USER,
    UPDATE_APP_USER,
} from "./actionTypes";


function* fetchAppUsers({ payload: payload }) {
    try {
        const response = yield call(getAppUsers, payload);
        yield put(getAppUsersSuccess(response))
    } catch (error) {
        yield put(getAppUsersFail(error))
    }
}

function* postUser({ payload: payload }) {
    try {
        const response = yield call(postAppUser, payload);
        yield put(postAppUserSuccess(response))
    } catch (error) {
        yield put(postAppUserFail(error))
    }
}

function* deleteUser({ payload: payload }) {
    try {
        const response = yield call(deleteAppUser, payload);
        yield put(deleteAppUserSuccess(response))
    } catch (error) {
        yield put(deleteAppUserFail(error))
    }
}

function* getUser({ payload: payload }) {
    try {
        const response = yield call(getAppUser, payload);
        yield put(getAppUserSuccess(response))
    } catch (error) {
        yield put(getAppUserFail(error))
    }
}

function* updateUser({ payload: payload }) {
    try {
        const response = yield call(updateAppUser, payload);
        yield put(updateAppUserSuccess(response))
    } catch (error) {
        yield put(updateAppUserFail(error))
    }
}

function* AppUsersSaga() {
    yield takeEvery(GET_APP_USERS, fetchAppUsers)
    yield takeEvery(POST_APP_USER, postUser)
    yield takeEvery(DELETE_APP_USER, deleteUser)
    yield takeEvery(GET_APP_USER, getUser)
    yield takeEvery(UPDATE_APP_USER, updateUser)
}

export default AppUsersSaga






