import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { Badge } from 'reactstrap';

const UserId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const UserRoleID = (cell) => {
    return cell.value ? cell.value : '';
};

const UserRoleName = (cell) => {
    return cell.value ? cell.value : '';
};;

const UserRoleDesc = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return (
        <Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
            {cell.value ? "ACTIVE" : "INACTIVE"}
        </Badge>
    )
};

const CreatedDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';
};

const LastUpdated = (cell) => {
    return cell.value ? moment(cell.value).format('l'): '';
}

export {
    UserId,
    UserRoleID,
    UserRoleName,
    Status,
    CreatedDate,
    LastUpdated,
    UserRoleDesc
};