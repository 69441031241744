import { takeEvery, put, call } from "redux-saga/effects"
import {
    getSysRolesSuccess, getSysRolesFail,
    getAppSysRoleSuccess, getAppSysRoleFail,
    postAppSysRolesSuccess, postAppSysRolesFail,
    deleteAppSysRolesSuccess, deleteAppSysRolesFail,
    updateSysRolesSuccess, updateSysRolesFail,
} from "./actions"

// Api Handler 
import {
    getSysRoles,
    postSysRoles,
    deleteSysRole,
    getSysRole,
    updateSysRoles,

} from "helpers/backendApi_helpers";


import {
    DELETE_SYS_ROLES,
    GET_SYS_ROLE,
    POST_SYS_ROLES,
    UPDATE_SYS_ROLES,
    GET_SYS_ROLES
} from "./actionTypes";




function* fetchAppSysRoles({ payload: payload }) {
    try {
        const response = yield call(getSysRoles, payload);
        yield put(getSysRolesSuccess(response))
    } catch (error) {
        yield put(getSysRolesFail(error))
    }
}

function* postAppSysRoles({ payload: payload }) {
    try {
        const response = yield call(postSysRoles, payload);
        yield put(postAppSysRolesSuccess(response))
    } catch (error) {
        yield put(postAppSysRolesFail(error))
    }
}

function* deleteAppSysRoles({ payload: payload }) {
    try {
        const response = yield call(deleteSysRole, payload);
        yield put(deleteAppSysRolesSuccess(response))
    } catch (error) {
        yield put(deleteAppSysRolesFail(error))
    }
}

function* getAppSysRole({ payload: payload }) {
    try {
        const response = yield call(getSysRole, payload);
        yield put(getAppSysRoleSuccess(response))
    } catch (error) {
        yield put(getAppSysRoleFail(error))
    }
}

function* updateAppSysRoles({ payload: payload }) {
    try {
        const response = yield call(updateSysRoles, payload);
        yield put(updateSysRolesSuccess(response))
    } catch (error) {
        yield put(updateSysRolesFail(error))
    }
}

function* AppSysRolesSaga() {
    yield takeEvery(GET_SYS_ROLES, fetchAppSysRoles)
    yield takeEvery(POST_SYS_ROLES, postAppSysRoles)
    yield takeEvery(DELETE_SYS_ROLES, deleteAppSysRoles)
    yield takeEvery(GET_SYS_ROLE, getAppSysRole)
    yield takeEvery(UPDATE_SYS_ROLES, updateAppSysRoles)
}

export default AppSysRolesSaga






