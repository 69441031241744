import {
    GET_APP_SYNCHRONIZE,
    GET_APP_SYNCHRONIZE_SUCCESS,
    GET_APP_SYNCHRONIZE_FAIL,

  } from "./actionTypes";
  
  /////////////////////////
  //  Get Synchronize  //
  /////////////////////////
  
  export const getSynchronize = (payload) => ({
    type: GET_APP_SYNCHRONIZE,
    payload: payload
  });
  
  export const getSynchronizeSuccess = synchronize => ({
    type: GET_APP_SYNCHRONIZE_SUCCESS,
    payload: synchronize,
  });
  
  export const getSynchronizeFail = error => ({
    type: GET_APP_SYNCHRONIZE_FAIL,
    payload: error,
  });

