import {
    GET_BUSINESSES,
    GET_BUSINESSES_SUCCESS,
    GET_BUSINESSES_FAIL,

    GET_BUSINESS,
    GET_BUSINESS_SUCCESS,
    GET_BUSINESS_FAIL,

    POST_BUSINESS,
    POST_BUSINESS_SUCCESS,
    POST_BUSINESS_FAIL,

    DELETE_BUSINESS,
    DELETE_BUSINESS_SUCCESS,
    DELETE_BUSINESS_FAIL,

    UPDATE_BUSINESS,
    UPDATE_BUSINESS_SUCCESS,
    UPDATE_BUSINESS_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get sys Business  //
/////////////////////////


export const getBusinesses = (payload) => ({
    type: GET_BUSINESSES,
    payload: payload
})

export const getBusinessesSuccess = businesses => ({
    type: GET_BUSINESSES_SUCCESS,
    payload: businesses
})

export const getBusinessesFail = error => ({
    type: GET_BUSINESSES_FAIL,
    payload: error
})
//////////////////////////////
// Get By id Business //
//////////////////////////////

export const getBusiness = (payload) => ({
    type: GET_BUSINESS,
    payload: payload
});

export const getBusinessSuccess = business => ({
    type: GET_BUSINESS_SUCCESS,
    payload: business,
});

export const getBusinessFail = error => ({
    type: GET_BUSINESS_FAIL,
    payload: error,
});

////////////////////////
// Post sys Business     ///
////////////////////////

export const postBusiness = (payload) => ({
    type: POST_BUSINESS,
    payload: payload
});

export const postBusinessSuccess = business => ({
    type: POST_BUSINESS_SUCCESS,
    payload: business,
});

export const postBusinessFail = error => ({
    type: POST_BUSINESS_FAIL,
    payload: error,
});

///////////////////////////
// Delete sys Business //
//////////////////////////

export const deleteBusiness= (payload) => ({
    type: DELETE_BUSINESS,
    payload: payload
});

export const deleteBusinessSuccess = () => ({
    type: DELETE_BUSINESS_SUCCESS,
});

export const deleteBusinessFail = error => ({
    type: DELETE_BUSINESS_FAIL,
    payload: error,
});

///////////////////////////
// Update sys Business  //
///////////////////////////

export const updateBusiness = (payload) => ({
    type: UPDATE_BUSINESS,
    payload: payload
});

export const updateBusinessSuccess = payload => ({
    type: UPDATE_BUSINESS_SUCCESS,
    payload: payload
});

export const updateBusinessFail = error => ({
    type: UPDATE_BUSINESS_FAIL,
    payload: error,
});


