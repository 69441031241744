import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"

import axios from 'axios';
import getHeader from 'config/header';

function CreateProducts() {
    const history = useHistory()
    const { register, handleSubmit, formState: { errors }, getValues } = useForm()

    const [status, setStatus] = useState({
        state: false,
        name: "Inactive"
    })

    const statusChange = () => {
        const value = getValues("is_active")
        if (value) {
            setStatus({
                state: true,
                name: "Active"
            })
        }
        else {
            setStatus({
                state: false,
                name: "Inactive"
            })
        }
    }

    const [businessUnitList, setBusinessUnitList] = useState([])

    
    const createProduct = (data) => {
    
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Products" breadcrumbItem="Contracts" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createProduct)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Business Unit <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <select className="form-select d-inline" aria-label="Default select example" {...register("app_business_unit", { required: true })}>
                                                    <option hidden value="">Business Unit</option>
                                                    {businessUnitList.map((businessunit, i) => (
                                                        <option value={businessunit.app_businessunit_id} key={i}>{businessunit.app_business_unit_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_business_unit && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Product Name <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className='form form-control' {...register('app_product_name', { required: true })} />
                                                {errors.app_product_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Product Tag <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className='form form-control' {...register('app_product_tag', { required: true })} />
                                                {errors.app_product_tag && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">POS Item Code<span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className='form form-control' {...register('app_product_pos_item_code', { required: true })} />
                                                {errors.app_product_pos_item_code && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Unit Price<span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className='form form-control' {...register('app_product_unit_price', { required: true })} />
                                                {errors.app_product_unit_price && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="5" md="6">
                                                <div className="form-check form-switch form-switch-lg mb-3">
                                                    <input type="checkbox" className="form-check-input" id="roleStatus" onInput={statusChange} defaultChecked={false} {...register('is_active')} />
                                                    <label className="form-check-label" htmlFor="roleStatus">
                                                        {status.name}
                                                    </label>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button'  onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button color='success' id='createproduct' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateProducts