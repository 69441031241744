import {
  GET_APP_BUSINESS_UNIT,
  GET_APP_BUSINESS_UNIT_SUCCESS,
  GET_APP_BUSINESS_UNIT_FAIL,

  POST_APP_BUSINESS_UNIT,
  POST_APP_BUSINESS_UNIT_SUCCESS,
  POST_APP_BUSINESS_UNIT_FAIL,

  DELETE_APP_BUSINESS_UNIT,
  DELETE_APP_BUSINESS_UNIT_SUCCESS,
  DELETE_APP_BUSINESS_UNIT_FAIL,

  GET_APP_BUSINESS_UNIT_DETAIL_SUCCESS,
  GET_APP_BUSINESS_UNIT_DETAIL_FAIL,
  GET_APP_BUSINESS_UNIT_DETAIL,
  
  UPDATE_APP_BUSINESS_UNIT_SUCCESS,
  UPDATE_APP_BUSINESS_UNIT_FAIL,
  UPDATE_APP_BUSINESS_UNIT,
} from "./actionTypes";

/////////////////////////
//  Get Business Unit  //
/////////////////////////

export const getAppBusinessUnits = (payload) => ({
  type: GET_APP_BUSINESS_UNIT,
  payload: payload
});

export const getAppBusinessUnitsSuccess = products => ({
  type: GET_APP_BUSINESS_UNIT_SUCCESS,
  payload: products,
});

export const getAppBusinessUnitsFail = error => ({
  type: GET_APP_BUSINESS_UNIT_FAIL,
  payload: error,
});

//////////////////////////////
// Get By id Business Unit  //
//////////////////////////////

export const getAppBusinessUnit = (payload) => ({
  type: GET_APP_BUSINESS_UNIT_DETAIL,
  payload: payload
});

export const getAppBusinessUnitsDetailSuccess = businessUnit => ({
  type: GET_APP_BUSINESS_UNIT_DETAIL_SUCCESS,
  payload: businessUnit,
});

export const getAppBusinessUnitsDetailFail = error => ({
  type: GET_APP_BUSINESS_UNIT_DETAIL_FAIL,
  payload: error,
});

////////////////////////
// Post Business Unit///
////////////////////////

export const postAppBusinessUnits = (payload) => ({
  type: POST_APP_BUSINESS_UNIT,
  payload: payload
});

export const postAppBusinessUnitsSuccess = products => ({
  type: POST_APP_BUSINESS_UNIT_SUCCESS,
  payload: products,
});

export const postAppBusinessUnitsFail = error => ({
  type: POST_APP_BUSINESS_UNIT_FAIL,
  payload: error,
});

///////////////////////////
// Delete Business Unit //
//////////////////////////

export const deleteAppBusinessUnits = (payload) => ({
  type: DELETE_APP_BUSINESS_UNIT,
  payload: payload
});

export const deleteAppBusinessUnitsSuccess = () => ({
  type: DELETE_APP_BUSINESS_UNIT_SUCCESS,
});

export const deleteAppBusinessUnitsFail = error => ({
  type: DELETE_APP_BUSINESS_UNIT_FAIL,
  payload: error,
});

///////////////////////////
// Update Business Unit  //
///////////////////////////

export const updateAppBusinessUnits = (payload) => ({
  type: UPDATE_APP_BUSINESS_UNIT,
  payload: payload
});

export const updateAppBusinessUnitsSuccess = payload => ({
  type: UPDATE_APP_BUSINESS_UNIT_SUCCESS,
  payload: payload
});

export const updateAppBusinessUnitsFail = error => ({
  type: UPDATE_APP_BUSINESS_UNIT_FAIL,
  payload: error,
});



