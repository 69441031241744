import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter, NavLink, useLocation, useHistory, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const location = useLocation();
  const ref = useRef();
  const history = useHistory();
  const [permissions, setPermissions] = useState('');

  useEffect(() => {
    setPermissions(JSON.parse(sessionStorage.getItem('userCred')));
  }, []);

  useEffect(() => {
    const initMenu = () => {
      const menu = new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    let parent = item.parentElement;
    while (parent) {
      if (parent.id === "side-menu") break;
      if (parent.tagName === "UL") {
        parent.classList.add("mm-show");
        parent.parentElement.classList.add("mm-active");
      }
      parent = parent.parentElement;
    }
    scrollElement(item);
  }

  function handleNavLinkClick(event, path) {
    event.preventDefault();
    history.push(path);
  }

  // const isActiveMenu = (path) => {
  //   console.log("xxx",location.pathname.startsWith(path));
  //   // console.log("qqqq",path);
  //   return location.pathname.startsWith(path);
  // };



  const isActiveMenu = (path) => {
    var pathname = location.pathname;
    var parts = pathname.split('/');

    if (parts.length > 1) {
      var secondSlashName = parts[1];
      return "/" + secondSlashName == path;
    } else {
      // console.log("Pathname does not have a second slash part.");
    }
  };

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <NavLink
                to={`/dashboard?skip=${0}&&limit=${10}&&transactionStatus=${"all"}`}
                activeClassName="mm-active"
              >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </NavLink>
            </li>

            {/* ==============products ================================ */}
            <li className={isActiveMenu("/products") ||
              isActiveMenu("/viewProducts") || isActiveMenu("/updateproducts") ||
              isActiveMenu("/viewPricingModels") || isActiveMenu("/updatePricingModels") ? "mm-active" : ""}>

              <Link to="/products" className="has-arrow">
                <i className="bx bxs-layer"></i>
                <span>{props.t("Products")}</span>
              </Link>
              <ul className="sub-menu">

                <li className={isActiveMenu("/viewProducts") || isActiveMenu("/updateproducts") ? "mm-active" : ""}>
                  <a
                    href={`/products?skip=${0}&&limit=${10}&&status=${true}`}
                    onClick={(event) => handleNavLinkClick(event, `/products?skip=${0}&&limit=${10}&&status=${true}`)}
                  >
                    {props.t("Products List")}
                  </a>
                </li>
                <li className={isActiveMenu("/viewPricingModels") || isActiveMenu("/updatePricingModels") ? "mm-active" : ""}>
                  <a
                    href={`/pricingModels?skip=${0}&&limit=${10}&&queryMultistatus=${"Approved"}`}
                    onClick={(event) => handleNavLinkClick(event, `/pricingModels?skip=${0}&&limit=${10}&&queryMultistatus=${"Approved"}`)}
                  >
                    {props.t("Pricing Models")}
                  </a>
                </li>
              </ul>
            </li>

            {/* =========== sales ===================================== */}

            <li className={isActiveMenu("/Sales") ||
              isActiveMenu("/viewCustomerSites") || isActiveMenu("/updateCustomerSites") || isActiveMenu("/createCustomerSites") ||
              isActiveMenu("/viewContact") || isActiveMenu("/updateContact") || isActiveMenu("/createContact") ||
              isActiveMenu("/viewAddress") || isActiveMenu("/updateAddress") || isActiveMenu("/createAddress") ||
              isActiveMenu("/viewOpportunities") || isActiveMenu("/createOpportunities")
              ? "mm-active" : ""}>

              <Link to="/Sales" className="has-arrow ">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Sales")}</span>
              </Link>
              <ul className="sub-menu">
                <li className={isActiveMenu("/viewCustomerSites") || isActiveMenu("/updateCustomerSites") || isActiveMenu("/createCustomerSites") ? "mm-active" : ""}>
                  <a
                    href={`/customerSites?skip=${0}&&limit=${10}&&status=${true}}&&Busid=null`}
                    onClick={(event) => handleNavLinkClick(event, `/customerSites?skip=${0}&&limit=${10}&&status=${true}&&Busid=null`)}>{props.t("Customer Sites")}
                  </a>
                </li>

                <li className={isActiveMenu("/viewContact") || isActiveMenu("/updateContact") || isActiveMenu("/createContact") ? "mm-active" : ""}>
                  <a href={`/contacts?skip=${0}&&limit=${10}&&status=${true}&&Busid=null&&SiteId=null`}
                    onClick={(event) => handleNavLinkClick(event, `/contacts?skip=${0}&&limit=${10}&&status=${true}&&Busid=null&&SiteId=null`)}>{props.t("Contacts")}
                  </a>
                </li>

                <li className={isActiveMenu("/viewAddress") || isActiveMenu("/updateAddress") || isActiveMenu("/createAddress") ? "mm-active" : ""}>
                  <a href={`/addresses?skip=${0}&&limit=${10}&&status=${true}&&Busid=null&&SiteId=null`}
                    onClick={(event) => handleNavLinkClick(event, `/addresses?skip=${0}&&limit=${10}&&status=${true}&&Busid=null&&SiteId=null`)}>{props.t("Addresses")}
                  </a>
                </li>


                <li className={isActiveMenu("/viewOpportunities") || isActiveMenu("/createOpportunities") ? "mm-active" : ""}>
                  <a href={`/opportunities?skip=${0}&&limit=${10}&&opportunityStatus=${"all"}`}
                    onClick={(event) => handleNavLinkClick(event, `/opportunities?skip=${0}&&limit=${10}&&opportunityStatus=${"all"}`)}>{props.t("Opportunities")}
                  </a>
                </li>
                <li>
                  <a href={`/custompricingModels?skip=${0}&&limit=${10}&&queryMultistatus=${"Pending"}`}
                    onClick={(event) => handleNavLinkClick(event, `/custompricingModels?skip=${0}&&limit=${10}&&queryMultistatus=${"Pending"}`)}>{props.t("Custom Pricing ")}
                  </a>
                </li>
              </ul>
            </li>


            {/* ================finance======================= */}

            <li className={isActiveMenu("/Finance") ||
              isActiveMenu("/viewaccount") || isActiveMenu("/createTransaction") ||
              isActiveMenu("/viewinvoice") ? "mm-active" : ""}>

              <Link to="/Finance" className="has-arrow ">
                <i className="bx bxs-package"></i>
                <span>{props.t("Finance")}</span>
              </Link>
              <ul className="sub-menu">

                <li className={isActiveMenu("/viewaccount") || isActiveMenu("/createTransaction") ? "mm-active" : ""}>
                  <a href={`/accountlist?skip=${0}&&limit=${10}&&status=${true}&&Busid=null`}
                    onClick={(event) => handleNavLinkClick(event, `/accountlist?skip=${0}&&limit=${10}&&status=${true}&&Busid=null`)}>{props.t("Account")}</a>
                </li>

                <li className={isActiveMenu("/viewinvoice") ? "mm-active" : ""}>
                  <a href={`/invoicelist?skip=${0}&&limit=${10}&&queryMultistatus=${"Pending"}`}
                    onClick={(event) => handleNavLinkClick(event, `/invoicelist?skip=${0}&&limit=${10}&&queryMultistatus=${"Pending"}&&Busid=null`)}>{props.t("Invoices")}</a>
                </li>
                <li>
                  <a href={`/returnslist`}
                    onClick={(event) => handleNavLinkClick(event, `/returnslist`)}>{props.t("Returns")}</a>
                </li>
              </ul>
            </li>


            {/*  ===========customers============================= */}

            <li>
              <Link to={`/companyList?skip=${0}&&limit=${10}&&status=${true}`} className={isActiveMenu("/viewCompany") || isActiveMenu("/updateCompany") || isActiveMenu("/createCompany") || isActiveMenu("/companyList") ? "mm-active" : ""}>
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Customers")}</span>
              </Link>
            </li>

            {/* =========================Reports================================================= */}

            <li>
              <Link to="/#" className={`${location.pathname === '/Reports' && 'mm-active'}`}>
                <i className="bx bxs-report"></i>
                <span>{props.t("Reports")}</span>
              </Link>
            </li>

            <li>
              <Link to={`/activity?skip=${0}&&limit=${10}&&status=${true}`} className={`${location.pathname === '/activity' && 'mm-active'}`} >
                <i className="bx bx-chalkboard"></i>
                <span>{props.t("Activity")}</span>
              </Link>
            </li>




            {/* =============administration================================== */}
            {/* 
            <li className={isActiveMenu("/Administration") ||
              isActiveMenu("/viewRole") || isActiveMenu("/updateRole") || isActiveMenu("/createRole") ||
              isActiveMenu("/viewUser") || isActiveMenu("/updateUser") || isActiveMenu("/createUser") ||
              isActiveMenu("/viewCustomerRole") || isActiveMenu("/updateCustomerRole") || isActiveMenu("/createCustomerRole") ||
              isActiveMenu("/viewCustomerUser") || isActiveMenu("/updateCustomerUser") || isActiveMenu("/createCustomerUser") ||

                (isActiveMenu("/viewBusinessUnit") && !isActiveMenu("/viewBusinessUnitDivision")) ||
                (isActiveMenu("/updateBusinessUnit") && !isActiveMenu("/updateBusinessUnitDivision")) ||
                ( isActiveMenu("/createBusinessUnit") && !isActiveMenu("/createBusinessUnitDivision")) ||


                (isActiveMenu("/viewBusinessUnitDivision") && !isActiveMenu("/viewBusinessUnit")) ||
                (isActiveMenu("/updateBusinessUnitDivision") && !isActiveMenu("/updateBusinessUnit")) ||
                (isActiveMenu("/createBusinessUnitDivision") && !isActiveMenu("/createBusinessUnit")) 
              ? "mm-active" : ""}>

              <Link to="/Administration" className="has-arrow ">
                <i className='bx bx-buildings'></i>
                <span>{props.t("Administration")}</span>
              </Link>

              <ul >
                <li className={isActiveMenu("/Portal") ||
                  isActiveMenu("/viewRole") || isActiveMenu("/updateRole") || isActiveMenu("/createRole") ||
                  isActiveMenu("/viewUser") || isActiveMenu("/updateUser") || isActiveMenu("/createUser")
                  ? "mm-active" : ""}>

                  <Link to="/Portal" className="has-arrow ">
                    <i className='mdi mdi-account'></i>
                    <span>{props.t("Portal")}</span>
                  </Link>

                  <ul className={`sub-menu ${isActiveMenu("/Portal") || isActiveMenu("/viewRole") || isActiveMenu("/updateRole") || isActiveMenu("/createRole") ||
                    isActiveMenu("/viewUser") || isActiveMenu("/updateUser") || isActiveMenu("/createUser")
                    ? "mm-show" : ""}`}>

                    <li className={isActiveMenu("/viewRole") || isActiveMenu("/updateRole") || isActiveMenu("/createRole") ? "mm-active" : ""}>
                      <a href={`/userRole?skip=${0}&&limit=${10}&&status=${true}`}
                        onClick={(event) => handleNavLinkClick(event, `/userRole?skip=${0}&&limit=${10}&&status=${true}`)}>{props.t("Roles Management")}</a>
                    </li>

                    <li className={isActiveMenu("/viewUser") || isActiveMenu("/updateUser") || isActiveMenu("/createUser") ? "mm-active" : ""}>
                      <a href={`/userList?skip=${0}&&limit=${10}&&status=${true}`}
                        onClick={(event) => handleNavLinkClick(event, `/userList?skip=${0}&&limit=${10}&&status=${true}`)}>{props.t("Users Management")}</a>
                    </li>

                  </ul>
                </li>

                <li className={isActiveMenu("/Customer") ||
                  isActiveMenu("/viewCustomerRole") || isActiveMenu("/updateCustomerRole") || isActiveMenu("/createCustomerRole") ||
                  isActiveMenu("/viewCustomerUser") || isActiveMenu("/updateCustomerUser") || isActiveMenu("/createCustomerUser")
                  ? "mm-active" : ""}>

                  <Link to="/Customer" className="has-arrow ">
                    <i className='mdi mdi-account-multiple'></i>
                    <span>{props.t("Customer")}</span>
                  </Link>

                  <ul className={`sub-menu ${isActiveMenu("/Customer") ||
                    isActiveMenu("/viewCustomerRole") || isActiveMenu("/updateCustomerRole") || isActiveMenu("/createCustomerRole") ||
                    isActiveMenu("/viewCustomerUser") || isActiveMenu("/updateCustomerUser") || isActiveMenu("/createCustomerUser")
                    ? "mm-show" : ""}`}>

                    <li className={isActiveMenu("/viewCustomerRole") || isActiveMenu("/updateCustomerRole") || isActiveMenu("/createCustomerRole") ? "mm-active" : ""}>
                      <a href={`/customerRoleManagement?skip=${0}&&limit=${10}&&status=${true}`}
                        onClick={(event) => handleNavLinkClick(event, `/customerRoleManagement?skip=${0}&&limit=${10}&&status=${true}`)}>{props.t("Roles Management")}
                      </a>
                    </li>

                    <li className={isActiveMenu("/viewCustomerUser") || isActiveMenu("/updateCustomerUser") || isActiveMenu("/createCustomerUser") ? "mm-active" : ""}>
                      <a href={`/customerUser?skip=${0}&&limit=${10}&&status=${true}`}
                        onClick={(event) => handleNavLinkClick(event, `/customerUser?skip=${0}&&limit=${10}&&status=${true}`)}>{props.t("Users Management")}
                      </a>
                    </li>
                  </ul>
                </li>

                <li className={
                  isActiveMenu("/viewBusinessUnit") && !isActiveMenu("/viewBusinessUnitDivision") ||
                    isActiveMenu("/updateBusinessUnit") && !isActiveMenu("/updateBusinessUnitDivision") ||
                    isActiveMenu("/createBusinessUnit") && !isActiveMenu("/createBusinessUnitDivision") ||
                    isActiveMenu("/businessUnits") && !isActiveMenu("/businessUnitDivision")
                    ? "mm-active" : ""
                }>
                  <a href={`/businessUnits?skip=${0}&limit=${10}&status=${true}`}
                    onClick={(event) => handleNavLinkClick(event, `/businessUnits?skip=${0}&limit=${10}&status=${true}`)}>
                    <i className="bx bxs-business"></i>
                    <span>{props.t("Business Units")}</span>
                  </a>
                </li>

                <li className={
                    isActiveMenu("/viewBusinessUnitDivision") && !isActiveMenu("/viewBusinessUnit") ||
                    isActiveMenu("/updateBusinessUnitDivision") && !isActiveMenu("/updateBusinessUnit") ||
                    isActiveMenu("/createBusinessUnitDivision") && !isActiveMenu("/createBusinessUnit") ||
                    isActiveMenu("/businessUnitDivision") && !isActiveMenu("/businessUnits")
                    ? "mm-active" : ""
                }>
                  <a href={`/businessUnitDivision?skip=${0}&limit=${10}&status=${true}`}
                    onClick={(event) => handleNavLinkClick(event, `/businessUnitDivision?skip=${0}&limit=${10}&status=${true}`)}>
                    <i className="bx bx-store"></i>
                    <span>{props.t("Business Unit Division")}</span>
                  </a>
                </li>

              </ul>
            </li> */}


            <li className={
              isActiveMenu("/Administration") ||
                isActiveMenu("/viewRole") || isActiveMenu("/updateRole") || isActiveMenu("/createRole") ||
                isActiveMenu("/viewUser") || isActiveMenu("/updateUser") || isActiveMenu("/createUser") ||
                isActiveMenu("/viewCustomerRole") || isActiveMenu("/updateCustomerRole") || isActiveMenu("/createCustomerRole") ||
                isActiveMenu("/viewCustomerUser") || isActiveMenu("/updateCustomerUser") || isActiveMenu("/createCustomerUser") ||
                isActiveMenu("/viewBusinessUnitDivision") || isActiveMenu("/updateBusinessUnitDivision") || isActiveMenu("/createBusinessUnitDivision") ||
                isActiveMenu("/viewBusinessUnit") || isActiveMenu("/updateBusinessUnit") || isActiveMenu("/createBusinessUnit")
                ? "mm-active" : ""
            }>

              <Link to="/Administration" className="has-arrow ">
                <i className='bx bx-buildings'></i>
                <span>{props.t("Administration")}</span>
              </Link>

              <ul>

                <li className={isActiveMenu("/Portal") ||
                  isActiveMenu("/viewRole") || isActiveMenu("/updateRole") || isActiveMenu("/createRole") ||
                  isActiveMenu("/viewUser") || isActiveMenu("/updateUser") || isActiveMenu("/createUser") ? "mm-active" : ""
                }>
                  <Link to="/Portal" className="has-arrow ">
                    <i className='mdi mdi-account'></i>
                    <span>{props.t("Portal")}</span>
                  </Link>

                  <ul className={`sub-menu ${isActiveMenu("/Portal") ||
                    isActiveMenu("/viewRole") || isActiveMenu("/updateRole") || isActiveMenu("/createRole") ||
                    isActiveMenu("/viewUser") || isActiveMenu("/updateUser") || isActiveMenu("/createUser") ? "mm-show" : ""}`}>

                    <li className={isActiveMenu("/viewRole") || isActiveMenu("/updateRole") || isActiveMenu("/createRole") ? "mm-active" : ""}>
                      <a href={`/userRole?skip=${0}&limit=${10}&status=${true}`}
                        onClick={(event) => handleNavLinkClick(event, `/userRole?skip=${0}&limit=${10}&status=${true}`)}>
                        {props.t("Roles Management")}
                      </a>
                    </li>

                    <li className={isActiveMenu("/viewUser") || isActiveMenu("/updateUser") || isActiveMenu("/createUser") ? "mm-active" : ""}>
                      <a href={`/userList?skip=${0}&limit=${10}&status=${true}`}
                        onClick={(event) => handleNavLinkClick(event, `/userList?skip=${0}&limit=${10}&status=${true}`)}>
                        {props.t("Users Management")}
                      </a>
                    </li>
                  </ul>
                </li>

                <li className={isActiveMenu("/Customer") ||
                  isActiveMenu("/viewCustomerRole") || isActiveMenu("/updateCustomerRole") || isActiveMenu("/createCustomerRole") ||
                  isActiveMenu("/viewCustomerUser") || isActiveMenu("/updateCustomerUser") || isActiveMenu("/createCustomerUser") ? "mm-active" : ""
                }>
                  <Link to="/Customer" className="has-arrow ">
                    <i className='mdi mdi-account-multiple'></i>
                    <span>{props.t("Customer")}</span>
                  </Link>

                  <ul className={`sub-menu ${isActiveMenu("/Customer") ||
                    isActiveMenu("/viewCustomerRole") || isActiveMenu("/updateCustomerRole") || isActiveMenu("/createCustomerRole") ||
                    isActiveMenu("/viewCustomerUser") || isActiveMenu("/updateCustomerUser") || isActiveMenu("/createCustomerUser") ? "mm-show" : ""}`}>

                    <li className={isActiveMenu("/viewCustomerRole") || isActiveMenu("/updateCustomerRole") || isActiveMenu("/createCustomerRole") ? "mm-active" : ""}>
                      <a href={`/customerRoleManagement?skip=${0}&limit=${10}&status=${true}`}
                        onClick={(event) => handleNavLinkClick(event, `/customerRoleManagement?skip=${0}&limit=${10}&status=${true}`)}>
                        {props.t("Roles Management")}
                      </a>
                    </li>

                    <li className={isActiveMenu("/viewCustomerUser") || isActiveMenu("/updateCustomerUser") || isActiveMenu("/createCustomerUser") ? "mm-active" : ""}>
                      <a href={`/customerUser?skip=${0}&limit=${10}&status=${true}&&Busid=null`}
                        onClick={(event) => handleNavLinkClick(event, `/customerUser?skip=${0}&limit=${10}&status=${true}&&Busid=null`)}>
                        {props.t("Users Management")}
                      </a>
                    </li>
                  </ul>
                </li>


                <li className={isActiveMenu("/viewBusinessUnitDivision") || isActiveMenu("/updateBusinessUnitDivision") || isActiveMenu("/createBusinessUnitDivision")
                  ? "mm-active" : ""}>

                  <a href={`/businessUnitDivision?skip=${0}&limit=${10}&status=${true}`}
                    onClick={(event) => handleNavLinkClick(event, `/businessUnitDivision?skip=${0}&limit=${10}&status=${true}`)}>
                    <i className="bx bx-store"></i>
                    <span>{props.t("Business Unit Division")}</span>
                  </a>
                </li>

                <li className={isActiveMenu("/viewBusinessUnit") || isActiveMenu("/updateBusinessUnit") || isActiveMenu("/createBusinessUnit")
                  ? "mm-active" : ""}>
                  <a href={`/businessUnits?skip=${0}&limit=${10}&status=${true}`}
                    onClick={(event) => handleNavLinkClick(event, `/businessUnits?skip=${0}&limit=${10}&status=${true}`)}>
                    <i className="bx bxs-business"></i>
                    <span>{props.t("Business Units")}</span>
                  </a>
                </li>

              </ul>
            </li>

            {/* =============settings================================== */}

            <li className={isActiveMenu("/Settings") ||
              isActiveMenu("/updateApplicationTemplate") || isActiveMenu("/createApplicationTemplate") ? "mm-active" : ""}
            >
              <Link to="/Settings" className="has-arrow ">
                <i className="bx bx-package"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">

                <li>
                  <a href="/azureSSO" onClick={(event) => handleNavLinkClick(event, "/azureSSO")}>{props.t("Azure AD SSO")}</a>
                </li>

                <li>
                  <a href="/smtpSettings" onClick={(event) => handleNavLinkClick(event, "/smtpSettings")} >{props.t("Email Settings")}</a>
                </li>

                <li>
                  <a href="/attachment" onClick={(event) => handleNavLinkClick(event, "/attachment")} >{props.t("Attachments")}</a>
                </li>

                <li>
                  <a href="/securities" onClick={(event) => handleNavLinkClick(event, "/securities")} >{props.t("Security")}</a>
                </li>

                <li className={isActiveMenu("/updateApplicationTemplate") || isActiveMenu("/createApplicationTemplate") ? "mm-active" : ""}>
                  <a href={`/applicationTemplate?skip=${0}&&limit=${10}`}
                    onClick={(event) => handleNavLinkClick(event, `/applicationTemplate?skip=${0}&&limit=${10}`)}>{props.t("Template")}
                  </a>
                </li>


                <li>
                  <Link to="/Approvals" className="has-arrow ">
                    {/* <i className="bx bxs-package"></i> */}
                    <span>{props.t("Approvals")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <a href={`/approvals/pricingmodels?tab=${1}&&skip=${0}&&limit=${10}&&queryMultistatus=${"Pending"}`}
                        onClick={(event) => handleNavLinkClick(event, `/approvals/pricingmodels?tab=${1}&&skip=${0}&&limit=${10}&&queryMultistatus=${"Pending"}`)}>{props.t("Pricing Models")}</a>
                    </li>

                    <li>
                      <a href={`/approvals/companySites?tab=${1}&&skip=${0}&&limit=${10}&&status=${true}`}
                        onClick={(event) => handleNavLinkClick(event, `/approvals/companySites?tab=${1}&&skip=${0}&&limit=${10}&&status=${true}`)}>{props.t("Sites")}</a>
                    </li>



                  </ul>
                </li>

              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));







