
export const POST_LOGIN_RETRY = "POST_LOGIN_RETRY"
export const POST_LOGIN_RETRY_SUCCESS = "POST_LOGIN_RETRY_SUCCESS"
export const POST_LOGIN_RETRY_FAIL = "POST_LOGIN_RETRY_FAIL"

export const GET_LOGIN_RETRY = "GET_LOGIN_RETRY"
export const GET_LOGIN_RETRY_SUCCESS = "GET_LOGIN_RETRY_SUCCESS"
export const GET_LOGIN_RETRY_FAIL = "GET_LOGIN_RETRY_FAIL"

export const UPDATE_LOGIN_RETRY = "UPDATE_LOGIN_RETRY"
export const UPDATE_LOGIN_RETRY_SUCCESS = "UPDATE_LOGIN_RETRY_SUCCESS"
export const UPDATE_LOGIN_RETRY_FAIL = "UPDATE_LOGIN_RETRY_FAIL"