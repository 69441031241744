import React, { useEffect, useState, useMemo } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Label, Row, Table, Form, FormGroup } from "reactstrap";
import { isEmpty, map } from "lodash";
import Breadcrumbs from "components/Common/Breadcrumb";
import logo from "../../../assets/images/maxims/maxims.png";

import { useSelector, useDispatch } from "react-redux";
import TableContainer from "components/Common/TableContainer";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { UnitPrice, ProductID, Discount, DiscountUnitPrice, Qty, SubTotal } from "../../../components/Common/PricingModelComponent/CustomOpportunityListCol";
import { useFieldArray, useForm } from "react-hook-form";
import { confirmFinalizeProposal, getFinalizeProposal, updateInvoice, updateOpportunities, } from "store/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ApproveInvoiceModal from "components/Common/ApprovalModelComponent/ApproveInvoiceModal";
import AddTransactionModal from "components/Common/ApprovalModelComponent/AddTransactionModal";
import { API_BASE_URL } from "helpers/url_helper";
import getHeader from "config/header";
import axios from "axios";

import pdfImage from "../../../assets/images/maxims/pdf_image.jpg"


let grandTotals

function ViewInvoice() {

    document.title = "Invoice Detail";

    const { id } = useParams();
    const dispatch = useDispatch()
    const history = useHistory();

    const [standardProductList, setStandardProductList] = useState([]);
    const [customProductList, setCustomProductList] = useState([]);
    const [flatRateProductList, setFlatRateProductList] = useState([]);
    const [tierProductList, setTierProductList] = useState([]);
    const [confirmedFinalizeProposalModal, setconfirmedFinalizeProposalModal] = useState(false);
    const [showAddTransaction, setShowAddTransaction] = useState(false)
    const [invoiceData, setInvoiceData] = useState('');

    const appFinalizeProposalReducer = useSelector(state => state.AppFinalizeProposalReducer);


    const { setValue, register, handleSubmit, control, formState: { errors }, getValues } = useForm()

    const { fields, append, remove } = useFieldArray({
        control,
        name: "flatRateProducts",
    });

    const { fields: fieldsTier, append: appendTier, remove: removeTier } = useFieldArray({
        control,
        name: "tierProducts"
    });


    const appTransactionState = useSelector(state => state.AppTransactionReducer);

    useEffect(() => {
        if (id) {
            const data = { finalizeProposalId: id };
            dispatch(getFinalizeProposal(data))
        }

        if (appTransactionState.isCreated) {
            if (id) {
                const data = { finalizeProposalId: id };
                dispatch(getFinalizeProposal(data))
            }
        }
        // }, [id, appTransactionState])
    }, [])



    useEffect(() => {
        // appFinalizeProposalReducer.finalizeproposal = null 
        if (appFinalizeProposalReducer?.finalizeproposal !== null) {
            // console.log(appFinalizeProposalReducer.finalizeproposal);
            setInvoiceData(appFinalizeProposalReducer.finalizeproposal)
            setStandardProductList(appFinalizeProposalReducer?.finalizeproposal?.standardProducts);
            setCustomProductList(appFinalizeProposalReducer?.finalizeproposal?.customProducts);
            setValue("flatRateProducts", appFinalizeProposalReducer?.finalizeproposal?.flatRateProducts)
            setValue("tierProducts", appFinalizeProposalReducer?.finalizeproposal?.tierProducts)
            setImages([])

            const token = sessionStorage.getItem('authUser')
            appFinalizeProposalReducer?.finalizeproposal?.attachment_file?.map((file) => {
                axios.get(API_BASE_URL + "/filestorage/getbyid" + "/" + file?.file_id, getHeader(token))
                    .then(res => {
                        //   console.log(res);
                        const imageData = res.data.image_buffer;
                        const mime_type = res.data.mime_type;
                        setImages(prevImages => [...prevImages, { imgSrc: `data:${mime_type};base64,${imageData}` }]);
                        // src={`data:image/jpeg;base64,${data}`}
                    }).catch(err => {
                        console.log(err);
                    })
            })
        }
    }, [appFinalizeProposalReducer])


    const columns = useMemo(
        () => [
            {
                Header: 'Product',
                accessor: 'app_product_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductID datas={cellProps.row.original}{...cellProps} />;
                }
            },
            {
                Header: 'Unit Price',
                accessor: 'unit_price',
                filterable: true,
                Cell: (cellProps) => {
                    return <UnitPrice {...cellProps} />;
                }
            },
            {
                Header: 'Discount',
                accessor: 'discount',
                filterable: true,
                Cell: (cellProps) => {
                    return <Discount {...cellProps} />;
                }
            },
            {
                Header: 'Discounted Unit Price',
                accessor: 'discounted_unit_price',
                filterable: true,
                Cell: (cellProps) => {
                    return <DiscountUnitPrice {...cellProps} />;
                }
            },
            {
                Header: 'Qty',
                accessor: 'quantity',
                filterable: true,
                Cell: (cellProps) => {
                    return <Qty {...cellProps} />;
                }
            },
            {
                Header: 'Sub Total',
                accessor: 'rowTotal',
                filterable: true,
                Cell: (cellProps) => {
                    return <SubTotal {...cellProps} />;
                }
            },
        ],
        [standardProductList, customProductList, flatRateProductList, tierProductList]
    );

    const getGrandTotal = (standardProductList, customProductList, flatRateProductList, tierProductList) => {
        let total1 = 0;
        standardProductList?.forEach(item => total1 += parseFloat(item.rowTotal ? item.rowTotal : 0));

        let total2 = 0;
        customProductList?.forEach(item => total2 += parseFloat(item.rowTotal ? item.rowTotal : 0));

        let total3 = 0;
        flatRateProductList?.forEach(a => a?.flatRateList?.forEach(b => total3 += parseFloat(b.rowTotal ? b.rowTotal : 0)))

        let total4 = 0;
        tierProductList?.forEach(a => a?.tierList?.forEach(b => total4 += parseFloat(b.rowTotal ? b.rowTotal : 0)))


        let grandTotal = total1 + total2 + total3 + total4;

        return parseFloat(grandTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    }

    const displayTierValues = (tierProductList) => {
        const tiers = tierProductList?.tiers;

        const tierValues = tiers?.map((tier, index) => {
            const startRange = index === 0 ? 1 : parseInt(tiers[index - 1]?.app_tier_qualifying_input || 0) + 1;
            const endRange = index === tiers.length - 1 ? "Infinity" : tier.app_tier_qualifying_input;
            return (
                <div key={tier.localID}>
                    {/* <h5>
                        <b>Qualifying Value:</b> {startRange} - {endRange}: <b>Discount:</b> {tier.app_tier_discount_value}
                    </h5> */}
                    <span style={{ fontSize: "15px" }}>
                        Qualifying Value: {startRange} - {endRange}: Discount: {tier.app_tier_discount_value}
                    </span>
                </div>
            );
        });
        return tierValues;
    }

    const backButton = () => {
        history.push('/invoicelist?skip=0&&limit=10&&queryMultistatus=Pending&&Busid=null');
        appFinalizeProposalReducer.finalizeproposal = null
    };


    const handleConfirmInvoice = () => {
        setconfirmedFinalizeProposalModal(true)
    }

    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([])
    const [removeButton, setRemoveButton] = useState(false)

    const handleChange = (e) => {
        const selectedFiles = e.target.files;
        setFiles([...files, ...selectedFiles]);

        let imageData = e.target.files
        const len = imageData.length
        for (var i = 0; i < len; i++) {
            var imageBlob = imageData[i];
            var fileReader = new FileReader()  //1
            fileReader.onload = ({ target }) => {  // 3
                setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
            }
            fileReader.readAsDataURL(imageBlob) //2
        }
    }



    const removeBtnClick = (i) => {
        let newImages = images.map(item => item)
        let newFiles = files.map(item => item)
        newImages.splice(i, 1)
        setImages(newImages)
        newFiles.splice(i, 1)
        setFiles(newFiles)
    }


    // const openImage = (image) => {
    //     let data = image.imgSrc;
    //     let w = window.open('about:blank');
    //     let embed = document.createElement('embed');
    //     embed.src = data;
    //     embed.type = 'application/pdf';
    //     embed.style.width = '100%';
    //     embed.style.height = '100%';
    //     w.document.write(embed.outerHTML);
    // }


    const openImage = (image) => {
        //console.log("image", image);
        let data = image.imgSrc;
        let w = window.open('about:blank');
        let iframe = document.createElement('iframe');
        iframe.src = data;
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        w.document.body.style.margin = '0';
        w.document.body.style.height = '100vh';
        w.document.body.appendChild(iframe);
    }


    return (
        <React.Fragment>
            <ApproveInvoiceModal
                show={confirmedFinalizeProposalModal}
                onCloseClick={() => setconfirmedFinalizeProposalModal(false)}
                data={invoiceData}
                title="Invoice"
            />

            <AddTransactionModal
                show={showAddTransaction}
                onCloseClick={() => setShowAddTransaction(false)}
                data={invoiceData}
                title="Invoice"
            />

            <div >
                <Container fluid>
                    <Breadcrumbs title="Invoice Details" breadcrumbItem="Invoice Details" />
                    {!isEmpty(invoiceData) && (
                        <Form>
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col className="mt-3">
                                                    <div>
                                                        <div className="d-flex justify-content-center">
                                                            <img src={logo} alt="logo" height="60" />
                                                        </div>
                                                        <div className="d-flex justify-content-center mt-3">
                                                            <h4>Invoice</h4>
                                                        </div>
                                                    </div>

                                                </Col>
                                            </Row>

                                            <hr />

                                            <Row>
                                                <Col lg="2" className="mt-3">
                                                    <strong className="d-flex justify-content-end">Company &nbsp;&nbsp;&nbsp;</strong>
                                                </Col>
                                                <Col lg="4" className="mt-3">
                                                    <address>
                                                        <span>{invoiceData.app_business_name}</span> |  <span>{invoiceData.app_department_name}</span>

                                                    </address>
                                                </Col>
                                                <Col lg="2" className="mt-3 text-center">
                                                    <strong className="d-flex justify-content-end">Invoice Id&nbsp;&nbsp;&nbsp;</strong>
                                                </Col>
                                                <Col lg="4" className="mt-3">
                                                    <address>
                                                        <span>{invoiceData.invoice_keyid}</span>

                                                    </address>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="2">
                                                    <strong className="d-flex justify-content-end">Billing Details&nbsp;&nbsp;&nbsp;</strong>
                                                </Col>
                                                <Col lg="4">
                                                    <address>
                                                        {invoiceData.app_billing_contact_name}
                                                        <br />
                                                        {invoiceData.app_billing_address}
                                                        <br />
                                                        {invoiceData.app_billing_contact_email}

                                                    </address>
                                                </Col>

                                                <Col lg="2" className="text-center">
                                                    <strong className="d-flex justify-content-end">Date&nbsp;&nbsp;&nbsp;</strong>
                                                </Col>
                                                <Col lg="4">
                                                    <address>
                                                        <span>{invoiceData.opportunity_date}</span>
                                                    </address>

                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col lg="2">
                                                    <strong className="d-flex justify-content-end">Delivery Details&nbsp;&nbsp;&nbsp;</strong>
                                                </Col>
                                                <Col lg="4">
                                                    <address>
                                                        {invoiceData.app_delivery_contact_name}
                                                        <br />
                                                        {invoiceData.app_delivery_address}
                                                        <br />
                                                        {invoiceData.app_delivery_contact_email}

                                                    </address>
                                                </Col>

                                                <Col lg="2" className="text-center">
                                                    <strong className="d-flex justify-content-end">Reference&nbsp;&nbsp;&nbsp;</strong>
                                                </Col>
                                                <Col lg="4">
                                                    <address>
                                                        <span>Remarks Here</span>
                                                    </address>
                                                </Col>
                                            </Row>

                                            <hr />

                                            <Row className="mt-3">
                                                <Label >   <h4>Invoice Information</h4>  </Label>
                                                <br></br>
                                                <Col>
                                                    <span dangerouslySetInnerHTML={{ __html: invoiceData?.template_information }} />
                                                </Col>
                                            </Row>

                                            {
                                                standardProductList.length > 0 && (
                                                    <Row className="mt-3">
                                                        <h4>Pricing Model - Standard</h4>

                                                        <Col>
                                                            <TableContainer
                                                                columns={columns}
                                                                data={standardProductList}
                                                            />
                                                        </Col>

                                                    </Row>

                                                )
                                            }

                                            {
                                                customProductList.length > 0 && (
                                                    <Row className="mt-3">
                                                        <h4>Pricing Model - Custom</h4>

                                                        <Col>
                                                            <TableContainer
                                                                columns={columns}
                                                                data={customProductList}
                                                            />
                                                        </Col>
                                                    </Row>

                                                )
                                            }

                                            {fields && fields.length > 0 && <Row>
                                                <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                                                    <h4> Pricing Model - Flat Rate</h4>

                                                </Col>
                                            </Row>
                                            }
                                            {fields && fields.length > 0 && fields.map((item, index) => (
                                                <div key={item.id}>
                                                    <Row>

                                                        <Col lg="6" md="6" sm="6" xs="6">
                                                            <div className="float-start">
                                                                <span style={{ fontSize: "15px" }}>   Pricing Model Name: &nbsp; {item.app_pricing_model_name}</span><br></br>
                                                                <span style={{ fontSize: "15px" }}>  Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                                                <span style={{ fontSize: "15px" }}>  Qualifying Value:&nbsp; {item.app_pricing_model_qualifying_value}</span><br></br>
                                                                <span style={{ fontSize: "15px" }}>  Discount Type: &nbsp;  {item.app_pricing_model_discount_type}</span><br></br>
                                                                <span style={{ fontSize: "15px" }}>  Discount Value: &nbsp;  {item.app_pricing_model_discount_value}</span><br></br>

                                                                {/* <h5 > <b> Pricing Model Name: &nbsp; </b> {item.app_pricing_model_name}</h5>
                                                                <h5 > <b> Qualifying Type: &nbsp; </b> {item.app_pricing_model_qualifying_type}</h5>
                                                                <h5 > <b> Qualifying Value:&nbsp;  </b>{item.app_pricing_model_qualifying_value}</h5>
                                                                <h5 > <b> Discount Type: &nbsp;  </b> {item.app_pricing_model_discount_type}</h5>
                                                                <h5 > <b> Discount Value: &nbsp;  </b> {item.app_pricing_model_discount_value}</h5> */}
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col xs="12">
                                                            <Card>
                                                                <CardBody>
                                                                    <TableContainer
                                                                        columns={columns}
                                                                        data={item.flatRateList || []}
                                                                        customPageSize={10}
                                                                        className="custom-header-css"
                                                                    />
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}


                                            {fieldsTier && fieldsTier.length > 0 && <Row>
                                                <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                                                    <h4> Pricing Model - Tier  </h4>
                                                    <br></br>
                                                </Col>
                                            </Row>}

                                            {fieldsTier && fieldsTier.length > 0 && fieldsTier.map((item, index) => (
                                                <div key={item.id}>
                                                    <Row>

                                                        <Col lg="6" md="6" sm="6" xs="6">
                                                            <div className="float-start">
                                                                <span style={{ fontSize: "15px" }}>Pricing Model Name: &nbsp;{item.app_pricing_model_name}</span><br></br>
                                                                <span style={{ fontSize: "15px" }}>Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                                                <span style={{ fontSize: "15px" }}>Discount Type: &nbsp; {item.app_pricing_model_discount_type}</span><br></br>
                                                                {displayTierValues(item)}
                                                                {/* <h5><b> Pricing Model Name: &nbsp; </b>{item.app_pricing_model_name}</h5>
                                                                <h5><b> Qualifying Type: &nbsp; </b>{item.app_pricing_model_qualifying_type}</h5>
                                                                <h5><b> Discount Type: &nbsp; </b>{item.app_pricing_model_discount_type}</h5>
                                                                {displayTierValues(item)} */}
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col xs="12">
                                                            <Card>
                                                                <CardBody>
                                                                    <TableContainer
                                                                        columns={columns}
                                                                        data={item.tierList || []}
                                                                        customPageSize={10}
                                                                        className="custom-header-css"
                                                                    />
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}

                                            <Row className="mt-3">
                                                <Label> <h4>Terms and Conditions</h4> </Label>
                                                <br />
                                                <Col>
                                                    {/* <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('terms_and_condition', { required: true })} value={watch('terms_and_condition')} /> */}
                                                    <span dangerouslySetInnerHTML={{ __html: invoiceData?.terms_and_condition }} />

                                                </Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Label> <h4>Payment Methods  </h4>   </Label>
                                                <br />
                                                <Col>
                                                    {/* <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('payment_method', { required: true })} value={watch('payment_method')} /> */}
                                                    <span dangerouslySetInnerHTML={{ __html: invoiceData?.payment_method }} />

                                                </Col>
                                            </Row>

                                            <hr />

                                            <Row>
                                                <Col>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Required Date<span style={{ color: "red" }}>*</span></Label>
                                                        <Col lg="7" md="6">
                                                            <input type="date" disabled style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`}  {...register('finalize_proposal_date', { required: true })} value={invoiceData?.finalize_proposal_date} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Invoice No  </Label>
                                                        <Col lg="7" md="6">
                                                            <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={invoiceData?.invoice_keyid} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Payment File<span className='text-danger'>*</span></Label>
                                                        <Col lg="7" md="6">
                                                            {(!(invoiceData?.attachment_file?.length > 0)) &&
                                                                <input className='form form-control' disabled={(invoiceData?.attachment_file === null)}
                                                                    style={(invoiceData?.attachment_file === null) ? { backgroundColor: "#D2D4D1" } : {}} accept='application/pdf, image/png, image/jpeg' multiple type="file" onChange={handleChange} />}
                                                            <div className="d-flex flex-wrap">
                                                                {images?.map((image, i) => (
                                                                    <div key={i} className="thumbSpan thumbContainer"  >
                                                                        <img className='thumbImage ms-3' id="fileImg" src={image?.imgSrc} alt="pdf"
                                                                            onMouseEnter={() => setRemoveButton(true)}
                                                                            onClick={() => openImage(image)}
                                                                            onError={(e) => { e.target.src = pdfImage; }}
                                                                            style={{
                                                                                border: "1px solid #ddd",
                                                                                borderRadius: "4px",
                                                                                padding: "5px",
                                                                                width: "125px",
                                                                                height: "125px",
                                                                                margin: "15px"
                                                                            }}
                                                                        />
                                                                        {!(invoiceData?.attachment_file?.length > 0) && removeButton &&
                                                                            <button type='button'
                                                                                onClick={() => removeBtnClick(i)}
                                                                                className='thumbClose btn btn-danger btn-sm'>
                                                                                X
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            {errors.app_payment_file && <span style={{ color: "red" }}>This Field is required</span>}
                                                        </Col>

                                                    </FormGroup>
                                                </Col>

                                                <Col>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Payment Notes </Label>
                                                        <Col lg="7" md="6">
                                                            <input className={`form form-control`} readOnly style={{ backgroundColor: "#D2D4D1" }} placeholder="Payment Notes "  {...register('payment_notes', { required: true })} value={invoiceData?.payment_notes} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row >
                                                <Col>
                                                    <div className="d-flex gap-3 justify-content-end" >
                                                        <strong className="mt-2" style={{ fontSize: "15px" }}>Account Balance : $ {parseFloat(invoiceData?.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</strong>
                                                    </div>
                                                    <div className="d-flex gap-3 justify-content-end  mb-3" >
                                                        <strong className="mt-2" style={{ fontSize: "15px" }}>Available Limit : $ {parseFloat(invoiceData?.available_limit).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</strong>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="justify-content-end">
                                                <Col>
                                                    <div className="d-flex  justify-content-end" >
                                                        <strong className="mt-2" style={{ fontSize: "20px" }}>Total :  ${" " + getGrandTotal(standardProductList, customProductList, getValues("flatRateProducts"), getValues("tierProducts"))}</strong>
                                                        <Button className="btn btn-secondary m-2 mb-5" onClick={backButton}>Back</Button>
                                                        {invoiceData?.available_limit < (parseFloat(invoiceData?.grandTotals)) && <Button className="btn btn-info m-2 mb-5" onClick={() => setShowAddTransaction(true)}>Account Adjustment</Button>}
                                                        {invoiceData?.invoice_approval_status === "Pending" && <Button
                                                            disabled={invoiceData?.available_limit < (parseFloat(invoiceData?.grandTotals))}
                                                            className="btn btn-info m-2 mb-5" onClick={handleConfirmInvoice}>Approval for Invoice</Button>}
                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewInvoice;

