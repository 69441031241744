/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";

export const GET_SYS_USERS_PROFILE = "GET_SYS_USERS_PROFILE"
export const GET_SYS_USERS_PROFILE_SUCCESS = "GET_SYS_USERS_PROFILE_SUCCESS"
export const GET_SYS_USERS_PROFILE_FAIL = "GET_SYS_USERS_PROFILE_FAIL"

export const GET_NOTIFICATION_APPROVAL_STATUS = "GET_NOTIFICATION_APPROVAL_STATUS"
export const GET_NOTIFICATION_APPROVAL_STATUS_SUCCESS = "GET_NOTIFICATION_APPROVAL_STATUS_SUCCESS"
export const GET_NOTIFICATION_APPROVAL_STATUS_FAIL = "GET_NOTIFICATION_APPROVAL_STATUS_FAIL"

export const GET_NOTIFICATION_PENDING_STATUS = "GET_NOTIFICATION_PENDING_STATUS"
export const GET_NOTIFICATION_PENDING_STATUS_SUCCESS = "GET_NOTIFICATION_PENDING_STATUS_SUCCESS"
export const GET_NOTIFICATION_PENDING_STATUS_FAIL = "GET_NOTIFICATION_PENDING_STATUS_FAIL"

