export const GET_APP_COUPON = "GET_APP_COUPON";
export const GET_APP_COUPON_SUCCESS = "GET_APP_COUPON_SUCCESS";
export const GET_APP_COUPON_FAIL = "GET_APP_COUPON_FAIL";

export const GET_APP_COUPON_STATUS = "GET_APP_COUPON_STATUS";
export const GET_APP_COUPON_STATUS_SUCCESS = "GET_APP_COUPON_STATUS_SUCCESS";
export const GET_APP_COUPON_STATUS_FAIL = "GET_APP_COUPON_STATUS_FAIL";


export const GET_APP_COUPON_STATUS_BY_INVOICE_ID = "GET_APP_COUPON_STATUS_BY_INVOICE_ID";
export const GET_APP_COUPON_STATUS_BY_INVOICE_ID_SUCCESS = "GET_APP_COUPON_STATUS_BY_INVOICE_ID_SUCCESS";
export const GET_APP_COUPON_STATUS_BY_INVOICE_ID_FAIL = "GET_APP_COUPON_STATUS_BY_INVOICE_ID_FAIL";


export const GET_APP_REQUEST_COUPON_STATUS = "GET_APP_REQUEST_COUPON_STATUS";
export const GET_APP_REQUEST_COUPON_STATUS_SUCCESS = "GET_APP_REQUEST_COUPON_STATUS_SUCCESS";
export const GET_APP_REQUEST_COUPON_STATUS_FAIL = "GET_APP_REQUEST_COUPON_STATUS_FAIL";


// Post Coupon
export const POST_APP_COUPON = "POST_APP_COUPON";
export const POST_APP_COUPON_SUCCESS = "POST_APP_COUPON_SUCCESS";
export const POST_APP_COUPON_FAIL = "POST_APP_COUPON_FAIL";