import { takeEvery, put ,call } from "redux-saga/effects";
import { getBusinessActionsSuccess,getBusinessActionsFail } from "./actions";
import { getBusinessActions} from "helpers/backendApi_helpers"
import { GET_BUSINESS_ACTIONS } from "./actionTypes";

function* fetchAppBusiness({ payload: payload}){
    try {
        const response = yield call(getBusinessActions, payload)
        yield put(getBusinessActionsSuccess(response))
    } catch(error) {
        yield put (getBusinessActionsFail(error))
    }
}

function* AppBusinessActionsSaga(){
    yield takeEvery(GET_BUSINESS_ACTIONS, fetchAppBusiness)
} 

export default AppBusinessActionsSaga