/* EVENTS */

// Get business Unit
export const GET_OPPORTUNITIES = "GET_OPPORTUNITIES";
export const GET_OPPORTUNITIES_SUCCESS = "GET_OPPORTUNITIES_SUCCESS";
export const GET_OPPORTUNITIES_FAIL = "GET_OPPORTUNITIES_FAIL"

// Post Business Unit
export const POST_OPPORTUNITIES = "POST_OPPORTUNITIES";
export const POST_OPPORTUNITIES_SUCCESS = "POST_OPPORTUNITIES_SUCCESS";
export const POST_OPPORTUNITIES_FAIL = "POST_OPPORTUNITIES_FAIL"

// Delete Business Unit
export const DELETE_OPPORTUNITIES = "DELETE_OPPORTUNITIES";
export const DELETE_OPPORTUNITIES_SUCCESS = "DELETE_OPPORTUNITIES_SUCCESS";
export const DELETE_OPPORTUNITIES_FAIL = "DELETE_OPPORTUNITIES_FAIL"

// Get Business Unit by id
export const GET_OPPORTUNITIES_DETAIL = "GET_OPPORTUNITIES_DETAIL";
export const GET_OPPORTUNITIES_DETAIL_SUCCESS = "GET_OPPORTUNITIES_DETAIL_SUCCESS";
export const GET_OPPORTUNITIES_DETAIL_FAIL = "GET_OPPORTUNITIES_DETAIL_FAIL";

// Update Business Unit
export const UPDATE_OPPORTUNITIES = "UPDATE_OPPORTUNITIES";
export const UPDATE_OPPORTUNITIES_SUCCESS = "UPDATE_OPPORTUNITIES_SUCCESS";
export const UPDATE_OPPORTUNITIES_FAIL = "UPDATE_OPPORTUNITIES_FAIL";