import {
    GET_LOGIN_RETRY,
    GET_LOGIN_RETRY_SUCCESS,
    GET_LOGIN_RETRY_FAIL,

    POST_LOGIN_RETRY,
    POST_LOGIN_RETRY_SUCCESS,
    POST_LOGIN_RETRY_FAIL,

    UPDATE_LOGIN_RETRY,
    UPDATE_LOGIN_RETRY_SUCCESS,
    UPDATE_LOGIN_RETRY_FAIL,
} from "./actionTypes";



//////////////////////////////
// Get By id LoginRetry //
//////////////////////////////

export const getLoginRetry = (payload) => ({
    type: GET_LOGIN_RETRY,
    payload: payload
});

export const getLoginRetrySuccess = loginRetry => ({
    type: GET_LOGIN_RETRY_SUCCESS,
    payload: loginRetry,
});

export const getLoginRetryFail = error => ({
    type: GET_LOGIN_RETRY_FAIL,
    payload: error,
});

///////////////////////////////
// Post sys LoginRETRYs   ///
//////////////////////////////

export const postLoginRetry = (payload) => ({
    type: POST_LOGIN_RETRY,
    payload: payload
});

export const postLoginRetrySuccess = loginRetry => ({
    type: POST_LOGIN_RETRY_SUCCESS,
    payload: loginRetry,
});

export const postLoginRetryFail = error => ({
    type: POST_LOGIN_RETRY_FAIL,
    payload: error,
});


///////////////////////////////
// Update sys LoginRetrys  //
//////////////////////////////

export const updateLoginRetry = (payload) => ({
    type: UPDATE_LOGIN_RETRY,
    payload: payload
});

export const updateLoginRetrySuccess = payload => ({
    type: UPDATE_LOGIN_RETRY_SUCCESS,
    payload: payload
});

export const updateLoginRetryFail = error => ({
    type: UPDATE_LOGIN_RETRY_FAIL,
    payload: error,
});


