export const POST_ATTACHMENT = "POST_ATTACHMENT"
export const POST_ATTACHMENT_SUCCESS = "POST_ATTACHMENT_SUCCESS"
export const POST_ATTACHMENT_FAIL = "POST_ATTACHMENT_FAIL"

export const GET_ATTACHMENT = "GET_ATTACHMENT"
export const GET_ATTACHMENT_SUCCESS = "GET_ATTACHMENT_SUCCESS"
export const GET_ATTACHMENT_FAIL = "GET_ATTACHMENT_FAIL"

export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT"
export const UPDATE_ATTACHMENT_SUCCESS = "UPDATE_ATTACHMENT_SUCCESS"
export const UPDATE_ATTACHMENT_FAIL = "UPDATE_ATTACHMENT_FAIL"