
import React from 'react';


const TransactionId = (cell) => {
    return cell.value ? cell.value : '';
};

const TransactionDate = (cell) => {
    return cell.value ? cell.value : '';
};

const TransactionType = (cell) => {
    return cell.value ? cell.value : '';

};

const TransactionValue = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " +  " " + floatValue : ' '} </span>);
};

export {
   
    TransactionId,
    TransactionDate,
    TransactionType,
    TransactionValue
};
