import {
    GET_APP_CREDENTIALS,
    GET_APP_CREDENTIALS_SUCCESS,
    GET_APP_CREDENTIALS_FAIL,

    POST_APP_CREDENTIALS,
    POST_APP_CREDENTIALS_SUCCESS,
    POST_APP_CREDENTIALS_FAIL,

} from "./actionTypes"

////////////////////////
// Get APP Cerdential //
////////////////////////

export const getAppCredentials =(payload) =>({
    type : GET_APP_CREDENTIALS,
    payload: payload
})

export const getappCredentialsSuccess = appCredentials =>({
    type : GET_APP_CREDENTIALS_SUCCESS,
    payload : appCredentials
})

export const getappCredentialsFail = error =>({
    type : GET_APP_CREDENTIALS_FAIL,
    payload : error
})

///////////////////////
//    Post App User  //
///////////////////////

export const postAppCredentials =(payload) =>({
    type : POST_APP_CREDENTIALS,
    payload: payload
})

export const postAppCredentialsSuccess = appCredentials =>({
    type : POST_APP_CREDENTIALS_SUCCESS,
    payload : appCredentials
})

export const postAppCredentialsFail = error =>({
    type : POST_APP_CREDENTIALS_FAIL,
    payload : error
})
