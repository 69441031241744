/* EVENTS */

// Get business Unit
export const GET_QUOTATION = "GET_QUOTATION";
export const GET_QUOTATION_SUCCESS = "GET_QUOTATION_SUCCESS";
export const GET_QUOTATION_FAIL = "GET_QUOTATION_FAIL"

// Post Business Unit
export const POST_QUOTATION = "POST_QUOTATION";
export const POST_QUOTATION_SUCCESS = "POST_QUOTATION_SUCCESS";
export const POST_QUOTATION_FAIL = "POST_QUOTATION_FAIL"

// Delete Business Unit
export const DELETE_QUOTATION = "DELETE_QUOTATION";
export const DELETE_QUOTATION_SUCCESS = "DELETE_QUOTATION_SUCCESS";
export const DELETE_QUOTATION_FAIL = "DELETE_QUOTATION_FAIL"

// Get Business Unit by id
export const GET_QUOTATION_DETAIL = "GET_QUOTATION_DETAIL";
export const GET_QUOTATION_DETAIL_SUCCESS = "GET_QUOTATION_DETAIL_SUCCESS";
export const GET_QUOTATION_DETAIL_FAIL = "GET_QUOTATION_DETAIL_FAIL";

// Update Business Unit
export const UPDATE_QUOTATION = "UPDATE_QUOTATION";
export const UPDATE_QUOTATION_SUCCESS = "UPDATE_QUOTATION_SUCCESS";
export const UPDATE_QUOTATION_FAIL = "UPDATE_QUOTATION_FAIL";