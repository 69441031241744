import { takeEvery, put, call } from "redux-saga/effects"
import {
  getAppBusinessUnitsFail,
  getAppBusinessUnitsSuccess,
  postAppBusinessUnitsSuccess,
  postAppBusinessUnitsFail,
  deleteAppBusinessUnitsFail,
  deleteAppBusinessUnitsSuccess,
  getAppBusinessUnitsDetailSuccess,
  getAppBusinessUnitsDetailFail,
  updateAppBusinessUnitsSuccess,
  updateAppBusinessUnitsFail
} from "./actions"

import {
  getAppBusinessUnits,
  postAppBusinessUnits,
  deleteAppBusinessUnit,
  getAppBusinessUnit,
  updateAppBusinessUnits
}
  from "helpers/backendApi_helpers";

import {
  DELETE_APP_BUSINESS_UNIT,
  GET_APP_BUSINESS_UNIT,
  GET_APP_BUSINESS_UNIT_DETAIL,
  POST_APP_BUSINESS_UNIT,
  UPDATE_APP_BUSINESS_UNIT
} from "./actionTypes";

function* fetchAppBusinessUnit({ payload: payload }) {
  try {
    const response = yield call(getAppBusinessUnits, payload);
    yield put(getAppBusinessUnitsSuccess(response))
  } catch (error) {
    yield put(getAppBusinessUnitsFail(error))
  }
}

function* postAppBusinessUnit({ payload: payload }) {
  try {
    const response = yield call(postAppBusinessUnits, payload);
    yield put(postAppBusinessUnitsSuccess(response))
  } catch (error) {
    yield put(postAppBusinessUnitsFail(error))
  }
}

function* deleteBusinessUnit({ payload: payload }) {
  try {
    const response = yield call(deleteAppBusinessUnit, payload);
    yield put(deleteAppBusinessUnitsSuccess(response))
  } catch (error) {
    yield put(deleteAppBusinessUnitsFail(error))
  }
}

function* getBusinessUnit({ payload: payload }) {
  try {
    const response = yield call(getAppBusinessUnit, payload);
    yield put(getAppBusinessUnitsDetailSuccess(response))
  } catch (error) {
    yield put(getAppBusinessUnitsDetailFail(error))
  }
}

function* updateAppBusinessUnit({ payload: payload }) {
  try {
    const response = yield call(updateAppBusinessUnits, payload);
    yield put(updateAppBusinessUnitsSuccess(response))
  } catch (error) {
    yield put(updateAppBusinessUnitsFail(error))
  }
}

function* AppBusinessUnitsSaga() {
  yield takeEvery(GET_APP_BUSINESS_UNIT, fetchAppBusinessUnit)
  yield takeEvery(POST_APP_BUSINESS_UNIT, postAppBusinessUnit)
  yield takeEvery(DELETE_APP_BUSINESS_UNIT, deleteBusinessUnit)
  yield takeEvery(GET_APP_BUSINESS_UNIT_DETAIL, getBusinessUnit)
  yield takeEvery(UPDATE_APP_BUSINESS_UNIT, updateAppBusinessUnit)
}

export default AppBusinessUnitsSaga





