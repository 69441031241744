import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, } from "reactstrap";
import { getTransaction } from "store/actions";
import { TransactionValue, TransactionId, TransactionType, TransactionDate } from "./LatestTranactionCol"
import TableContainer from "../../components/Common/TableContainer";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";


function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

const LatestTranaction = props => {

  const dispatch = useDispatch();

  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const [pageCount, setPageCount] = useState(1);
  const [transaction, setTransaction] = useState([]);
  const queryMultistatus = query.get("transactionStatus");
  const history = useHistory();

  const [customFilterData, setCustomFilterData] = useState("");

  const handlePageClick = (index) => {
    let filter1 = `{"$or":[
      {"app_transaction_keyid":{"$regex": "${customFilterData}", "$options": "i"}},
      {"app_transaction_type": {"$regex": "${customFilterData}", "$options": "i"}},
      {"created_on":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_transaction_value":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`
    let filter2 = `{"app_transaction_type":"${queryMultistatus}","$or":[
      {"app_transaction_keyid":{"$regex":"${customFilterData}","$options":"i"}},
      {"created_on":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_transaction_value":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_transaction_type": {"$regex": "${customFilterData}", "$options": "i"}}
    ]}`

    let skips = 0;
    if (queryMultistatus === "all") {
      if (page > 0) {
        skips = page * pageLimit;
        dispatch(getTransaction({ filter: filter1, limit: pageLimit, skip: skips }));
      }
      else {
        dispatch(getTransaction({ filter: filter1, limit: pageLimit, skip: 0 }));
      }
    } else {
      if (page > 0) {
        skips = page * pageLimit;
        dispatch(getTransaction({ filter: filter2, limit: pageLimit, skip: skips }));
      }
      else {
        dispatch(getTransaction({ filter: filter2, limit: pageLimit, skip: 0 }));
      }

    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Transaction ID',
        accessor: 'app_transaction_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionId {...cellProps} />;
        }
      }, {
        Header: 'Transaction Date',
        accessor: 'created_on',
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionDate {...cellProps} />
        }
      },
      {
        Header: 'Transaction Type',
        accessor: 'app_transaction_type',
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionType {...cellProps} />
        }
      },
      {
        Header: 'Transaction Value',
        accessor: 'app_transaction_value',
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionValue {...cellProps} />;
        }
      },
    ], []
  );


  const transactionState = useSelector(state => state.AppTransactionReducer);

  useEffect(() => {
    handlePageClick()
  }, [pageLimit, page, queryMultistatus, customFilterData]);


  useEffect(() => {
    if (customFilterData.length > 0) {
      history.push(`/dashboard?skip=${page}&&limit=${pageLimit}&&transactionStatus=${queryMultistatus}`)
    }
  }, [customFilterData])


  useEffect(() => {
    if (transactionState.appTransactions !== null) {
      setTransaction(transactionState.appTransactions.result);
      setPageCount(Math.ceil(transactionState.appTransactions.summary.filteredsize / pageLimit) == 0 ? 1
        :
        Math.ceil(transactionState.appTransactions.summary.filteredsize / pageLimit)
      );
    }
  }, [transactionState])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Latest Transaction</div>
          <TableContainer
            columns={columns}
            data={transaction}
            customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
            isGlobalFilter={true}
            customPageCount={pageCount}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(LatestTranaction);
