export const GET_ADDRESSES = "GET_ADDRESSES"
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS"
export const GET_ADDRESSES_FAIL = "GET_ADDRESSES_FAIL"

export const POST_ADDRESS = "POST_ADDRESS"
export const POST_ADDRESS_SUCCESS = "POST_ADDRESS_SUCCESS"
export const POST_ADDRESS_FAIL = "POST_ADDRESS_FAIL"

export const DELETE_ADDRESS = "DELETE_ADDRESS"
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS"
export const DELETE_ADDRESS_FAIL = "DELETE_ADDRESS_FAIL"

export const GET_ADDRESS = "GET_ADDRESS"
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS"
export const GET_ADDRESS_FAIL = "GET_ADDRESS_FAIL"

export const GET_ALL_ADDRESS = "GET_ALL_ADDRESS"
export const GET_ALL_ADDRESS_SUCCESS = "GET_ALL_ADDRESS_SUCCESS"
export const GET_ALL_ADDRESS_FAIL = "GET_ALL_ADDRESS_FAIL"

export const UPDATE_ADDRESS = "UPDATE_ADDRESS"
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS"
export const UPDATE_ADDRESS_FAIL = "UPDATE_ADDRESS_FAIL"