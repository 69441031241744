import {
    GET_DEPARTMENTS,
    GET_DEPARTMENTS_SUCCESS,
    GET_DEPARTMENTS_FAIL,

    GET_DEPARTMENT,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_FAIL,

    GET_ALL_DEPARTMENT,
    GET_ALL_DEPARTMENT_SUCCESS,
    GET_ALL_DEPARTMENT_FAIL,

    POST_DEPARTMENT,
    POST_DEPARTMENT_SUCCESS,
    POST_DEPARTMENT_FAIL,

    DELETE_DEPARTMENT,
    DELETE_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_FAIL,

    UPDATE_DEPARTMENT,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAIL,
    
} from "./actionTypes";

const INIT_STATE = {
    departments: null,
    department: null,
    singledepartment: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const departmentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DEPARTMENTS:
            return {
                ...state,
                loading: true,
                departments: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_DEPARTMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                departments: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_DEPARTMENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_DEPARTMENT:
            return {
                ...state,
                loading: true,
                departments: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                department: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_DEPARTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_DEPARTMENT:
            return {
                ...state,
                loading: true,
                departments: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                department: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_DEPARTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_DEPARTMENT:
            return {
                ...state,
                loading: true,
                singledepartment: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                singledepartment: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_DEPARTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_DEPARTMENT:
            return {
                ...state,
                loading: true,
                // sysUsers: null,
                department: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                department: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_DEPARTMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
            case GET_ALL_DEPARTMENT:
                return {
                    ...state,
                    loading: true,
                    departments: null,
                    isCreated: false,
                    isDeleted: false,
                    isUpdated: false
                };
            case GET_ALL_DEPARTMENT_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    departments: action.payload,
                    isCreated: false,
                    isDeleted: false,
                    isUpdated: false
                };
            case GET_ALL_DEPARTMENT_FAIL:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                    isCreated: false,
                    isDeleted: false,
                    isUpdated: false
                };
        default:
            return state;
    }
};

export default departmentReducer;
