export const GET_CREDITS ="GET_CREDITS"
export const GET_CREDITS_SUCCESS ="GET_CREDITS_SUCCESS"
export const GET_CREDITS_FAIL ="GET_CREDITS_FAIL"

export const GET_CREDIT ="GET_CREDIT"
export const GET_CREDIT_SUCCESS ="GET_CREDIT_SUCCESS"
export const GET_CREDIT_FAIL ="GET_CREDIT_FAIL"

export const GET_ALL_CREDIT ="GET_ALL_CREDIT"
export const GET_ALL_CREDIT_SUCCESS ="GET_ALL_CREDIT_SUCCESS"
export const GET_ALL_CREDIT_FAIL ="GET_ALL_CREDIT_FAIL"

export const POST_CREDIT ="POST_CREDIT"
export const POST_CREDIT_SUCCESS ="POST_CREDIT_SUCCESS"
export const POST_CREDIT_FAIL ="POST_CREDIT_FAIL"

export const DELETE_CREDIT ="DELETE_CREDIT"
export const DELETE_CREDIT_SUCCESS ="DELETE_CREDIT_SUCCESS"
export const DELETE_CREDIT_FAIL ="DELETE_CREDIT_FAIL"

export const UPDATE_CREDIT ="UPDATE_CREDIT"
export const UPDATE_CREDIT_SUCCESS ="UPDATE_CREDIT_SUCCESS"
export const UPDATE_CREDIT_FAIL ="UPDATE_CREDIT_FAIL"
