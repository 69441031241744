import {
  GET_QUOTATION,
  GET_QUOTATION_SUCCESS,
  GET_QUOTATION_FAIL,

  POST_QUOTATION,
  POST_QUOTATION_SUCCESS,
  POST_QUOTATION_FAIL,

  DELETE_QUOTATION,
  DELETE_QUOTATION_SUCCESS,
  DELETE_QUOTATION_FAIL,

  GET_QUOTATION_DETAIL_SUCCESS,
  GET_QUOTATION_DETAIL_FAIL,
  GET_QUOTATION_DETAIL,
  
  UPDATE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_FAIL,
  UPDATE_QUOTATION,
} from "./actionTypes";

/////////////////////////
//  Get Quotation  //
/////////////////////////

export const getQuotations = (payload) => ({
  type: GET_QUOTATION,
  payload: payload
});

export const getQuotationsSuccess = Quotations => ({
  type: GET_QUOTATION_SUCCESS,
  payload: Quotations,
});

export const getQuotationsFail = error => ({
  type: GET_QUOTATION_FAIL,
  payload: error,
});

//////////////////////////////
// Get By id Quotation  //
//////////////////////////////

export const getQuotation = (payload) => ({
  type: GET_QUOTATION_DETAIL,
  payload: payload
});

export const getQuotationDetailSuccess = businessUnit => ({
  type: GET_QUOTATION_DETAIL_SUCCESS,
  payload: businessUnit,
});

export const getQuotationDetailFail = error => ({
  type: GET_QUOTATION_DETAIL_FAIL,
  payload: error,
});

////////////////////////
// Post Quotation///
////////////////////////

export const postQuotation = (payload) => ({
  type: POST_QUOTATION,
  payload: payload
});

export const postQuotationSuccess = Quotations => ({
  type: POST_QUOTATION_SUCCESS,
  payload: Quotations,
});

export const postQuotationFail = error => ({
  type: POST_QUOTATION_FAIL,
  payload: error,
});

///////////////////////////
// Delete Quotation //
//////////////////////////

export const deleteQuotation = (payload) => ({
  type: DELETE_QUOTATION,
  payload: payload
});

export const deleteQuotationSuccess = () => ({
  type: DELETE_QUOTATION_SUCCESS,
});

export const deleteQuotationFail = error => ({
  type: DELETE_QUOTATION_FAIL,
  payload: error,
});

///////////////////////////
// Update Quotation  //
///////////////////////////

export const updateQuotation = (payload) => ({
  type: UPDATE_QUOTATION,
  payload: payload
});

export const updateQuotationSuccess = payload => ({
  type: UPDATE_QUOTATION_SUCCESS,
  payload: payload
});

export const updateQuotationFail = error => ({
  type: UPDATE_QUOTATION_FAIL,
  payload: error,
});



