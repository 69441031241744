import React, { useEffect } from 'react';

const ProductID = (cell) => {
    return cell.value ?  cell?.datas?.app_product_pos_item_code + " | " +  cell?.datas?.app_product_payment_code + " | " +  cell?.datas?.app_product_local_name : "  ";
};

const ProductName = (cell) => {
    return cell.value ? cell.value : '';
};

const PosItemCode = (cell) => {
    return cell.value ? cell.value : '';
};

const ProductType = (cell) => {
    // if (cell.value === "D") {
    //     return cell.value ? "Digital" : '';
    // } else {
    //     return cell.value ? "Print" : '';
    // }

    return cell.value ? cell.value : '';
};

const OriginalPrice = (cell) => {

    const floatValue = parseFloat(cell.value).toFixed(1);
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " + " " + floatValue : ''} </span>);
};

const UnitPrice = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1);
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " + " " + floatValue : ''} </span>);
};


let timeout

const StandardQuantity = (cell) => {
    if (isNaN(cell.row.original?.unit_price)) return
    const handleQty = cell.handleQty
    const ID = cell.row.original?.app_product_id

    const debounce = (id, price, value) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            handleQty(id, price, value)
        }, 800);
    }

    useEffect(() => {
        $(`#inputQty-${ID}`)?.val(cell.value)
    }, [])

    return (
        <input className='form form-control' id={`inputQty-${ID}`}
            style={{ width: "100px", textAlign: "right", marginTop: "-5px" }} type='number' min={"0"}
            onChange={(e) => {
                debounce(ID, cell.row.original?.unit_price, e.target.value)
            }}
        />)
};


// const SubTotal = (cell) => {
//     let floatValue = parseFloat(cell.value);
//     if (isNaN(floatValue)) return null;
//     floatValue = floatValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
//     return (
//         <span className='ListPageFloatRight'> {"$" + floatValue} </span>
//     );
// };


const Qty = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (<span className='ListPageFloatRight'>{floatValue} </span>);
}



const CustomQuantity = (cell) => {


    const handleQty = cell.handleQty
    const ID = cell.row.original?.app_product_id

    const debounce = (id, price, value) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            handleQty(id, price, value)
        }, 800);
    }

    useEffect(() => {
        $(`#inputQty-${ID}`)?.val(cell.value)
    }, [])

    return (
        <input className='form form-control' id={`inputQty-${ID}`}
            style={{ width: "100px", textAlign: "right", marginTop: "-5px" }} type='number'  min={"0"}
            onChange={(e) => {
                debounce(ID, cell.row.original?.unit_price, e.target.value)
            }}
        />)
};

const CustomUnitPrice = (cell) => {


    const handleUnitPrice = cell.handleUnitPrice
    const ID = cell.row.original?.app_product_id

    const debounce = (id, price, value, qty) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            handleUnitPrice(id, price, value, qty)
        }, 800);
    }

    useEffect(() => {
        $(`#inputUnitPrice-${ID}`)?.val(cell.value)
    }, [])

    return (
        <input className='form form-control' id={`inputUnitPrice-${ID}`}
            style={{ width: "100px", textAlign: "right", marginTop: "-5px" }} type='number' step={"any"}  min={"0"}
            onChange={(e) => {
                debounce(ID, e.target.value, cell.row.original?.quantity)
            }}
        />)
};

const FlatQuantity = (cell) => {


    const handleQty = cell.handleQty
    const ID = cell.row.original?.app_product_id

    const debounce = (id, price, value) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            handleQty(id, price, value)
        }, 800);
    }

    useEffect(() => {
        $(`#inputQty-${ID}`)?.val(cell.value)
    }, [])

    return (
        <input className='form form-control' id={`inputQty-${ID}`}
            style={{ width: "100px", textAlign: "right", marginTop: "-5px" }} type='number'  min={"0"}
            onChange={(e) => {
                debounce(ID, cell.row.original?.unit_price, e.target.value)
            }}
        />)
};

const TierQuantity = (cell) => {
    // return <input className='form form-control' style={{ width: "100px" }}></input>
    const handleQty = cell.handleQty
    const ID = cell.row.original?.app_product_id

    const debounce = (id, price, value) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            handleQty(id, price, value)
        }, 800);
    }

    useEffect(() => {
        $(`#inputQty-${ID}`)?.val(cell.value)
    }, [])

    return (
        <input className='form form-control' id={`inputQty-${ID}`}
            style={{ width: "100px", textAlign: "right", marginTop: "-5px" }} type='number'  min={"0"}
            onChange={(e) => {
                debounce(ID, cell.row.original?.selling_price, e.target.value)
            }}
        />)
};

// const RowTotal = (cell) => {
//     const floatValue = parseFloat(cell.value).toFixed(1);
//     if (isNaN(floatValue)) return
//     return (<span className='ListPageFloatRight'> {floatValue ? " $ " + floatValue : ''} </span>);
// };

const RowTotal = (cell) => {
    let floatValue = parseFloat(cell.value);
    if (isNaN(floatValue)) return null;
    floatValue = floatValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (
        <span className='ListPageFloatRight'> {"$" + " " + floatValue} </span>
    );
};


const SubTotal = (cell) => {
    let floatValue = parseFloat(cell.value);
    if (isNaN(floatValue)) return null;
    floatValue = floatValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (
        <span className='ListPageFloatRight'> {"$" + " " +floatValue} </span>
    );
};


const Discount = (cell) => {
    return (<span className='ListPageFloatRight'> {cell.value ? cell.value : ''} </span>);
};


const DiscountUnitPrice = (cell) => {
    if (isNaN(cell.value)) return
    const floatValue = parseFloat(cell.value).toFixed(1);
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " + " " + floatValue : ''} </span>);
};



export {
    ProductID, ProductName, ProductType, PosItemCode, OriginalPrice, UnitPrice,
    StandardQuantity, FlatQuantity, TierQuantity, CustomQuantity, CustomUnitPrice, RowTotal,
    SubTotal, Discount, DiscountUnitPrice, Qty
};


