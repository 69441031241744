import PropTypes from "prop-types";
import React from "react";
import qs from "qs";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

import { withRouter, Link, useLocation, useHistory } from "react-router-dom";
import * as url from "./../../helpers/url_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "assets/images/maxims/Maxims-logo.png";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import getHeader from "../../config/header"

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const VerifyUser = props => {

  const history = useHistory()

  document.title = "User Verification |  Maxims B2B Portal";

  // Get QUERY from URL
  let query = useQuery();
  const userID = query.get("userid")
  const otpPrefix = query.get("prefix")

  const [commonToken, setCommonToken] = useState("")

  // Get Common Token 
  useEffect(() => {
    if (userID) {
      const headerData = {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      }
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(headerData),
        url: url.API_BASE_URL+url.AUTH_TOKEN
      }
      axios(options)
        .then(res => {
          setCommonToken(res.data.access_token)
          toast.info("Check your email for an OTP !!", { autoClose: false, position: "bottom-center" })
        }).catch(err => {
          console.log(err);
        })
    }
  }, [userID])

  // Verify OTP
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: '',
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Please enter your OTP"),
    }),
    onSubmit: (values) => {
      $('#verifyOtpBtn').attr("disabled", true)
      const data = {
        otp_string: values.otp
      }
      axios.put(url.API_BASE_URL+url.APP_USER_URL+"/"+ userID + "/activate", data, getHeader(commonToken))
        .then(res => {
          toast.dismiss();
          history.push('/setuppassword?userid=' + userID)
        }).catch(err => {
          console.log(err);
          toast.error("Invalid OTP!!")
          $('#verifyOtpBtn').attr("disabled", false)
        })
    }
  });


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div style={{ color: "white" }} className="text p-4">
                        <h5 style={{ color: "white" }} className="text">User Verification</h5>
                        <p>Enter OTP to Verify </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title bg-transparent">
                          <img
                            src={logo}
                            alt=""
                            height="54"
                            style={{ paddingLeft: "45px", marginTop: "-20px" }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">


                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">OTP</Label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">{otpPrefix ? otpPrefix : "0000"} -</span>
                          </div>
                          <Input
                            name="otp"
                            className="form-control"
                            placeholder="Enter OTP"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.otp || ""}
                            invalid={
                              validation.touched.otp && validation.errors.otp ? true : false
                            } />
                            {validation.touched.otp && validation.errors.otp ? (<FormFeedback>{validation.errors.otp}</FormFeedback>) : null}
                        </div>
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                            disabled={(commonToken && userID) ? false : true}
                            id="verifyOtpBtn"
                          >
                            Verify
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {/* © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand */}
                  © {new Date().getFullYear()} Maxims B2B Portal.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

VerifyUser.propTypes = {
  history: PropTypes.object,
};

export default withRouter(VerifyUser);
