import {
    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    GET_TRANSACTION_FAIL,
    
    POST_TRANSACTION,
    POST_TRANSACTION_SUCCESS,
    POST_TRANSACTION_FAIL,
  
    DELETE_TRANSACTION,
    DELETE_TRANSACTION_SUCCESS,
    DELETE_TRANSACTION_FAIL,

    GET_TRANSACTION_DETAIL,
    GET_TRANSACTION_DETAIL_SUCCESS,
    GET_TRANSACTION_DETAIL_FAIL,
   
    UPDATE_TRANSACTION,
    UPDATE_TRANSACTION_SUCCESS,
    UPDATE_TRANSACTION_FAIL,
    
  } from "./actionTypes";
  

   //////////////////////////////
  // Get list transaction  //
  //////////////////////////////
  
  export const getTransaction = (payload) => ({
    type: GET_TRANSACTION,
    payload: payload
  });
  
  export const getTransactionSuccess = accounts => ({
    type: GET_TRANSACTION_SUCCESS,
    payload: accounts,
  });
  
  export const gettTransactionFail = error => ({
    type: GET_TRANSACTION_FAIL,
    payload: error,
  });

   //////////////////////////////
  // Get By id transaction  //
  //////////////////////////////
  
  export const getTransactionDetail = (payload) => ({
    type: GET_TRANSACTION_DETAIL,
    payload: payload
  });
  
  export const getTransactionDetailSuccess = accounts => ({
    type: GET_TRANSACTION_DETAIL_SUCCESS,
    payload: accounts,
  });
  
  export const getTransactionDetailFail = error => ({
    type: GET_TRANSACTION_DETAIL_FAIL,
    payload: error,
  });
  
  ////////////////////////
  // Post transaction ///
  ////////////////////////
  
  export const postTransaction = (payload) => ({
    type: POST_TRANSACTION,
    payload: payload
  });
  
  export const postTransactionSuccess = products => ({
    type: POST_TRANSACTION_SUCCESS,
    payload: products,
  });
  
  export const postTransactionFail = error => ({
    type: POST_TRANSACTION_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Delete transaction//
  //////////////////////////

  export const deleteTransaction = (payload) => ({
    type: DELETE_TRANSACTION,
    payload: payload
  });
  
  export const deleteTransactionSuccess = () => ({
    type: DELETE_TRANSACTION_SUCCESS,
  });
  
  export const deleteTransactionFail = error => ({
    type: DELETE_TRANSACTION_FAIL,
    paACCOUNT
  });
  
  ///////////////////////////
  // Update transaction //
  ///////////////////////////
  
  export const updateTransaction = (payload) => ({
    type: UPDATE_TRANSACTION,
    payload: payload
  });
  
  export const updateTransactionSuccess = payload => ({
    type: UPDATE_TRANSACTION_SUCCESS,
    payload: payload
  });
  
  export const updateTransactionFail = error => ({
    type: UPDATE_TRANSACTION_FAIL,
    payload: error,
  });
  
  
  
  