import {
    GET_APPLICATION_TEMPLATE,
    GET_APPLICATION_TEMPLATE_SUCCESS,
    GET_APPLICATION_TEMPLATE_FAIL,
 
    GET_APPLICATION_TEMPLATE_DETAIL,
    GET_APPLICATION_TEMPLATE_DETAIL_SUCCESS,
    GET_APPLICATION_TEMPLATE_DETAIL_FAIL,
  
    POST_APPLICATION_TEMPLATE,
    POST_APPLICATION_TEMPLATE_SUCCESS,
    POST_APPLICATION_TEMPLATE_FAIL,
  
    DELETE_APPLICATION_TEMPLATE,
    DELETE_APPLICATION_TEMPLATE_SUCCESS,
    DELETE_APPLICATION_TEMPLATE_FAIL,
    
    UPDATE_APPLICATION_TEMPLATE_SUCCESS,
    UPDATE_APPLICATION_TEMPLATE_FAIL,
    UPDATE_APPLICATION_TEMPLATE,
  } from "./actionTypes";
  
  /////////////////////////
  //  Get Application Template  //
  /////////////////////////
  
  export const getApplicationTemplate = (payload) => ({
    type:   GET_APPLICATION_TEMPLATE,
    payload: payload
  });
  
  export const getApplicationTemplateSuccess = products => ({
    type:   GET_APPLICATION_TEMPLATE_SUCCESS,
    payload: products,
  });
  
  export const getApplicationTemplateFail = error => ({
    type:   GET_APPLICATION_TEMPLATE_FAIL,
    payload: error,
  });
  
  //////////////////////////////
  // Get By id Application Template  //
  //////////////////////////////
  
  export const getApplicationTemplateDetail = (payload) => ({
    type: GET_APPLICATION_TEMPLATE_DETAIL,
    payload: payload
  });
  
  export const getApplicationTemplateDetailSuccess = businessUnit => ({
    type: GET_APPLICATION_TEMPLATE_DETAIL_SUCCESS,
    payload: businessUnit,
  });
  
  export const getApplicationTemplateDetailFail = error => ({
    type: GET_APPLICATION_TEMPLATE_DETAIL_FAIL,
    payload: error,
  });
  
  ////////////////////////
  // Post Application Template///
  ////////////////////////
  
  export const postApplicationTemplate = (payload) => ({
    type:POST_APPLICATION_TEMPLATE,
    payload: payload
  });
  
  export const postApplicationTemplateSuccess = products => ({
    type: POST_APPLICATION_TEMPLATE_SUCCESS,
    payload: products,
  });
  
  export const postApplicationTemplateFail = error => ({
    type: POST_APPLICATION_TEMPLATE_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Delete Application Template //
  //////////////////////////
  
  export const deleteApplicationTemplate = (payload) => ({
    type: DELETE_APPLICATION_TEMPLATE,
    payload: payload
  });
  
  export const deleteApplicationTemplateSuccess = () => ({
    type: DELETE_APPLICATION_TEMPLATE_SUCCESS,
  });
  
  export const deleteApplicationTemplateFail = error => ({
    type: DELETE_APPLICATION_TEMPLATE_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Update Application Template  //
  ///////////////////////////
  
  export const updateApplicationTemplate = (payload) => ({
    type: UPDATE_APPLICATION_TEMPLATE,
    payload: payload
  });
  
  export const updateApplicationTemplateSuccess = payload => ({
    type: UPDATE_APPLICATION_TEMPLATE_SUCCESS,
    payload: payload
  });
  
  export const updateApplicationTemplateFail = error => ({
    type: UPDATE_APPLICATION_TEMPLATE_FAIL,
    payload: error,
  });
  
  
  
  