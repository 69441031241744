import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';
import { ProductName, ProductID, POSItemCode, UnitPrice, ProductType, Tags, ProductStatus, SellingPrice } from './ProductListCol';
import axios from "axios";
import getHeader from "config/header";
import expiredSessionHandler from "common/expiredSessionHandler";
import productContext from "./product";
import { getBusinessProducts } from "store/products/products/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const ProductsList = props => {
    const pathname = location.pathname

    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const pageStatus = query.get("status")

    const dispatch = useDispatch();
    const history = useHistory()

    //meta title
    document.title = "Products | Maxims B2B Portal";

    const [products, setProducts] = useState([])
    const [pageCount, setPageCount] = useState(1);
    const [permissions, setPermissions] = useState('')
    // const [page, setPage] = useState(0);
    // const [pageStatus, setPageStatus] = useState(true)
    // const [pageLimit, setPageLimit] = useState(10);

    const businessProducts = useSelector(state => state.BusinessProducts);

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')));
    }, [])

    useEffect(() => {
        if (businessProducts.products !== null) {
            setProducts(businessProducts.products.result);
            setPageCount(Math.ceil(businessProducts.products.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(businessProducts.products.summary.filteredsize / pageLimit));

        }
    }, [businessProducts])


    const [customFilterData, setCustomFilterData] = useState("")    ////////

    useEffect(() => {
        handlePageClick()
    }, [pageStatus, pageLimit, page, customFilterData])


    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/products?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
        }
    }, [customFilterData])


    const handlePageClick = (index) => {
        let filter = `{"is_active":${pageStatus},"$or":[
            {"app_product_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_product_local_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_product_pos_item_code":{"$regex":"${customFilterData}","$options":"i"}},
            {"product_type":{"$regex":"${customFilterData}","$options":"i"}},
            {"selling_price":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_product_tags_names":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`
        let skips = 0;
        if (page > 0) {
            skips = page * pageLimit;
            dispatch(getBusinessProducts({ filter: filter, limit: pageLimit, skip: skips }));
        }
        else {
            dispatch(getBusinessProducts({ filter: filter, limit: pageLimit, skip: 0 }));
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Product ID ',
                accessor: 'app_product_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductID {...cellProps} />;
                }
            },
            {
                Header: 'Product Name',
                accessor: 'app_product_local_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductName {...cellProps} />;
                }
            },
            {
                Header: 'POS Item Code',
                accessor: 'app_product_pos_item_code',
                filterable: true,
                Cell: (cellProps) => {
                    return <POSItemCode {...cellProps} />;
                }
            },
            {
                Header: 'Product Type',
                accessor: 'product_type',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductType {...cellProps} />;
                }
            },
            {
                Header: 'Selling Price ',
                accessor: 'selling_price',
                filterable: true,
                Cell: (cellProps) => {
                    return <SellingPrice {...cellProps} />;
                }
            },
            {
                Header: 'Tags',
                accessor: 'app_product_tags_names',
                filterable: true,
                Cell: (cellProps) => {
                    return <Tags {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductStatus {...cellProps} />;
                }
            },
            {
                Header: 'Actions    ',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {(permissionAction.includes("system.products.product.read")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/viewProducts/${cellProps.row.original.app_product_id}`)
                                        }} className="btn btn-success btn-sm">View</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                            {(permissionAction.includes("system.products.product.read")) && (permissionAction.includes("system.products.product.update")) ||
                                (permissionAction.includes("system.products.product.read")) && (permissionAction.includes("system.products.product.delete"))
                                ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/updateproducts/${cellProps.row.original.app_product_id}`)
                                        }} className="btn btn-info btn-sm">Edit</button>
                                </> : <div className="text-muted">No Access</div>
                            }

                        </div>
                    );
                }
            },
        ], [products]
    );

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);
    const [productTBD, setProductTBD] = useState('')

    const onClickDelete = (products) => {
        setProductTBD(products);
        setDeleteModal(true);
    };

    const handleDeleteCustomer = () => {

    };

    const handleBusinessUnit = () => {

    }

    const productValue = {
        onData: handleBusinessUnit
    }

    const appSynchronizeState = useSelector(state => state.AppSynchronize)

    useEffect(() => {
        handlePageClick()
    }, [appSynchronizeState])


    return (
        <React.Fragment>
            <productContext.Provider value={productValue}>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteCustomer}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <div className="page-content">
                    {permissions.includes("system.products.product.read") ? <Container fluid>
                        <Breadcrumbs title="Products List" breadcrumbItem="Product " />
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={products}
                                            isGlobalFilter={true}
                                            isProductsList={permissions.includes("system.products.product.write") ? true : false}
                                            customPageCount={pageCount}
                                            className="custom-header-css"
                                            customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                        // customPageSize={5}
                                        // handlePageClick={handlePageClick}
                                        // customPageIndex={page}
                                        // customPageStatus={(data) => setPageStatus(data)}
                                        // pageSizeTransfer={(data) => setPageLimit(data)}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                        :
                        <Container fluid>
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                                <div>
                                                    <h1><i className='bx bxs-lock'></i></h1>
                                                    <h3>Access Denied</h3>
                                                    <div className="text-muted">You don't have permission to access this module</div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    }
                </div>
            </productContext.Provider>
        </React.Fragment>
    );
};

ProductsList.propTypes = {
    customers: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onAddNewCustomer: PropTypes.func,
    onDeleteCustomer: PropTypes.func,
    onUpdateCustomer: PropTypes.func,
};

export default ProductsList;


