import {
    GET_DOWNLOAD_LOG,
    GET_DOWNLOAD_LOG_SUCCESS,
    GET_DOWNLOAD_LOG_FAIL,
  
    POST_DOWNLOAD_LOG,
    POST_DOWNLOAD_LOG_SUCCESS,
    POST_DOWNLOAD_LOG_FAIL,
  
    DELETE_DOWNLOAD_LOG,
    DELETE_DOWNLOAD_LOG_SUCCESS,
    DELETE_DOWNLOAD_LOG_FAIL,
  
    GET_DOWNLOAD_LOG_DETAIL_SUCCESS,
    GET_DOWNLOAD_LOG_DETAIL_FAIL,
    GET_DOWNLOAD_LOG_DETAIL,
  } from "./actionTypes";
  
  const INIT_STATE = {
    downloadLog: null,
    downloadLogs: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
  };
  
  const DownloadLogReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case  GET_DOWNLOAD_LOG:
        return {
          ...state,
          loading: true,
          downloadLogs: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_DOWNLOAD_LOG_SUCCESS:
        return {
          ...state,
          loading: false,
          downloadLogs: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_DOWNLOAD_LOG_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
        case POST_DOWNLOAD_LOG:
          return {
            ...state,
            loading: true,
            downloadLog: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case POST_DOWNLOAD_LOG_SUCCESS:
          return {
            ...state,
            loading: false,
            downloadLog: action.payload,
            isCreated: true,
            isDeleted: false,
            isUpdated: false
          };
        case POST_DOWNLOAD_LOG_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case DELETE_DOWNLOAD_LOG:
          return {
            ...state,
            loading: true,
            downloadLog: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case DELETE_DOWNLOAD_LOG_SUCCESS:
          return {
            ...state,
            loading: false,
            downloadLog: null,
            isCreated: false,
            isDeleted: true,
            isUpdated: false
          };
        case DELETE_DOWNLOAD_LOG_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_DOWNLOAD_LOG_DETAIL:
          return {
            ...state,
            loading: true,
            downloadLog: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_DOWNLOAD_LOG_DETAIL_SUCCESS:
          return {
            ...state,
            loading: false,
            downloadLog: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_DOWNLOAD_LOG_DETAIL_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
      default:
        return state;
    }
  };
  
  export default DownloadLogReducer;
  
  
