import logo from "assets/images/maxims/Maxims-logo.png";
import { Link } from "react-router-dom";

function LeftSection() {
    return (
        <div className="leftSection col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="leftContent">
                <img
                    src={logo}
                    alt="Maxims Logo"
                    height="54"
                    style={{ paddingLeft: "45px", marginTop: "-20px" }}
                />
                <h1 className="leftSectionHead">Maxims B2B</h1>
                Welcome to Maxims B2B! <Link to="/login"> <span className="signIn">SignIn</span> </Link> here
                <div className="mt-5 text-center">
                    <p className="leftSectionHead">
                        © {new Date().getFullYear()} Maxims B2B Portal.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default LeftSection