import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label } from "reactstrap"
import axios from 'axios';
import getHeader from 'config/header';
import Select from 'react-select'
import StatusModal from 'components/Common/StatusModal';
import { WithContext as ReactTags } from 'react-tag-input';
import { getAppBusinessUnits, getBusinessProductDetail, getBusinesses, getDepartment, postDepartment, updateBusinessProduct, updateDepartment } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


function createCustomerSites() {

    const history = useHistory()
    const dispatch = useDispatch()
    const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()

    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const UpadatePermission = (permissionAction.includes("system.sales.customersites.update")) ? false : true
    const DeletePermission = (permissionAction.includes("system.sales.customersites.delete")) ? false : true

    const [businessUnit, setBusinessUnit] = useState([])
    const [businessList, setBusinessList] = useState([])

    const businessUnitSate = useSelector(state => state.AppBusinessUnits)
    const businessesState = useSelector(state => state.businessReducer)
    const departmentState = useSelector(state => state.departmentReducer)

    useEffect(() => {
        dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: "", skip: '' }))
    }, [])

    useEffect(() => {
        if (businessesState.businesses !== null) {
            setBusinessList(businessesState.businesses.result)
        }
        if (departmentState.isCreated) {
            history.goBack()
        }
    }, [businessesState, departmentState])

    useEffect(() => {
        dispatch(getAppBusinessUnits({ filter: `{"is_active":${true}}`, limit:0, skip: '' }));
    }, [])

    useEffect(() => {
        if (businessUnitSate.appBusinessUnits !== null) {
            setBusinessUnit(businessUnitSate.appBusinessUnits.result);
        }
    }, [businessUnitSate])


    const createSite = (datas) => {
        const siteValue = datas
        let value
        if (siteValue.is_active === "active") {
            value = true
        } else if (siteValue.is_active === "inactive") {
            value = false
        }

        const siteItems = {
            ...siteValue,
            is_active: value,
        }

        const data = {
            companyID: datas.app_business_id,
            siteItems
        }

        dispatch(postDepartment(data))
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Customer Site" breadcrumbItem="Customer Sites" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createSite)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="8" md="6">
                                                <select className="form-select d-inline" aria-label="Default select example" {...register("app_business_id", { required: true })}>
                                                    <option hidden value=""> Select Company</option>
                                                    {businessList.map((business) => (
                                                        <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Site Name <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="8" md="6">
                                                <input className='form form-control' {...register(`app_department_name`, { required: true })} />
                                                {errors.app_department_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Site Description
                                            </Label>
                                            <Col lg="8" md="6">
                                                <textarea className="form form-control" type="textarea"
                                                    {...register(`department_description`, { required: true })} />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Business Unit <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="8" md="6">
                                                <select defaultValue="" className='form-select d-inline' aria-label="Default select example" {...register("app_businessunit_id", { required: true })}>
                                                    <option disabled hidden value="">Select the Business Unit</option>
                                                    {businessUnit.map((businessunitlist) => (
                                                        <option key={businessunitlist.app_businessunit_id} value={businessunitlist.app_businessunit_id}>{businessunitlist.app_business_unit_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_businessunit_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="8" md="6">
                                                <select defaultValue={"active"} className="form-select d-inline" {...register('is_active', { required: true })}>
                                                    {/* <option hidden value="">Select Status</option> */}
                                                    <option value="inactive">INACTIVE</option>
                                                    <option value="active">ACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button id='updateProduct' color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default createCustomerSites

