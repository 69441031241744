import { takeEvery, put, call } from "redux-saga/effects"
import {
    getBusinessUnitDivisionsSuccess, getBusinessUnitDivisionsFail,
    getBusinessUnitDivisionSuccess, getBusinessUnitDivisionFail,
    postBusinessUnitDivisionSuccess, postBusinessUnitDivisionFail,
    deleteBusinessUnitDivisionSuccess, deleteBusinessUnitDivisionFail,
    updateBusinessUnitDivisionSuccess, updateBusinessUnitDivisionFail,
} from "./actions"

// Api Handler 
import {
    getBusinessUnitDivisions,
    getBusinessUnitDivision,
    postBusinessUnitDivision,
    updateBusinessUnitDivision,
    deleteBusinessUnitDivision,
} from "helpers/backendApi_helpers";


import {
    GET_BUSINESS_UNIT_DIVISIONS,
    GET_BUSINESS_UNIT_DIVISION,
    POST_BUSINESS_UNIT_DIVISION,
    DELETE_BUSINESS_UNIT_DIVISION,
    UPDATE_BUSINESS_UNIT_DIVISION
} from "./actionTypes";


function* fetchAppBUDivisions({ payload: payload }) {
    try {
        const response = yield call(getBusinessUnitDivisions, payload);
        yield put(getBusinessUnitDivisionsSuccess(response))
    } catch (error) {
        yield put(getBusinessUnitDivisionsFail(error))
    }
}

function* postAppBUDivision({ payload: payload }) {
    try {
        const response = yield call(postBusinessUnitDivision, payload);
        yield put(postBusinessUnitDivisionSuccess(response))
    } catch (error) {
        yield put(postBusinessUnitDivisionFail(error))
    }
}

function* deleteAppBUDivision({ payload: payload }) {
    try {
        const response = yield call(deleteBusinessUnitDivision, payload);
        yield put(deleteBusinessUnitDivisionSuccess(response))
    } catch (error) {
        yield put(deleteBusinessUnitDivisionFail(error))
    }
}

function* getAppBUDivision({ payload: payload }) {
    try {
        const response = yield call(getBusinessUnitDivision, payload);
        yield put(getBusinessUnitDivisionSuccess(response))
    } catch (error) {
        yield put(getBusinessUnitDivisionFail(error))
    }
}

function* updateAppBUDivision({ payload: payload }) {
    try {
        const response = yield call(updateBusinessUnitDivision, payload);
        yield put(updateBusinessUnitDivisionSuccess(response))
    } catch (error) {
        yield put(updateBusinessUnitDivisionFail(error))
    }
}

function* BUDivisionSaga() {
    yield takeEvery(GET_BUSINESS_UNIT_DIVISIONS, fetchAppBUDivisions)
    yield takeEvery(POST_BUSINESS_UNIT_DIVISION, postAppBUDivision)
    yield takeEvery(DELETE_BUSINESS_UNIT_DIVISION, deleteAppBUDivision)
    yield takeEvery(GET_BUSINESS_UNIT_DIVISION, getAppBUDivision)
    yield takeEvery(UPDATE_BUSINESS_UNIT_DIVISION, updateAppBUDivision)
}

export default BUDivisionSaga


    