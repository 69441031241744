import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
import { ContactName, Position, Department, Email, Status, Site, Company } from './ContactsListCol';
import { getAllContact, getContactss } from "store/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Contacts = props => {

    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const Busid = query.get("Busid")
    const SiteId = query.get("SiteId")
    const pageStatus = query.get("status")

    const history = useHistory()
    const [permissions, setPermissions] = useState('')
    const [contacts, setContacts] = useState([])


    const [pageCount, setPageCount] = useState(1);
    // const [page, setPage] = useState(0);
    // const [pageLimit, setPageLimit] = useState(10);

    const contactState = useSelector(state => state.contactsReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    // Company Column
    const columns = useMemo(
        () => [
            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Company {...cellProps} />
                }
            },
            {
                Header: 'Site Name',
                accessor: 'app_department_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Site {...cellProps} />
                }
            },
            {
                Header: 'Site ID',
                accessor: 'app_department_keyid',
                filterable: true,
                Cell: (cellProps) => {
               
                    return <Site {...cellProps} />
                }
            },
            {
                Header: 'Contact Name',
                accessor: 'app_contact_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <ContactName  {...cellProps} />
                }
            },
            {
                Header: 'Contact Position',
                accessor: 'position',
                filterable: true,
                Cell: (cellProps) => {
                    return <Position {...cellProps} />
                }
            },
            {
                Header: 'Contact Department',
                accessor: 'department',
                filterable: true,
                Cell: (cellProps) => {
                    return <Department {...cellProps} />
                }
            },
            {
                Header: 'Contact Email',
                accessor: 'app_contact_email',
                filterable: true,
                Cell: (cellProps) => {
                    return <Email {...cellProps} />
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />
                }
            },
            {
                Header: 'Actions',
                Cell: (cellProps) => {
                    // return (
                    //     <div className="d-flex gap-3">
                    //         <button
                    //             onClick={() => {
                    //                 history.push(`/updateContact/${cellProps.row.original.app_contact_id}?BusId=${cellProps.row.original.app_business_id}`)
                    //             }} className="btn btn-success btn-sm">View</button>
                    //     </div>
                    // );

                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {(permissionAction.includes("system.sales.contact.read")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/viewContact/${cellProps.row.original.app_contact_id}?BusId=${cellProps.row.original.app_business_id}`)
                                        }} className="btn btn-success btn-sm">View</button>
                                </> : ""
                            }
                            {(permissionAction.includes("system.sales.contact.read")) && (permissionAction.includes("system.sales.contact.updates")) ||
                                (permissionAction.includes("system.sales.contact.read")) && (permissionAction.includes("system.sales.contact.delete")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/updateContact/${cellProps.row.original.app_contact_id}?BusId=${cellProps.row.original.app_business_id}`)                                        }} className="btn btn-info btn-sm">Edit</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                        </div>
                    )
                }
            },
        ], [contacts]
    );

    useEffect(() => {
        if (contactState.contacts !== null) {
            // console.log("contactState.contacts", contactState.contacts);
            setContacts(contactState.contacts.result)
            setPageCount(Math.ceil(contactState.contacts.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(contactState.contacts.summary.filteredsize / pageLimit)
            );
        }
    }, [contactState])

  //============================global filter ===============================//
    const [customFilterData, setCustomFilterData] = useState("")

    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, Busid, SiteId, pageStatus,customFilterData])

    useEffect(() => {
        if(customFilterData.length > 0){
            history.push(`/contacts?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        }
    },[customFilterData])

    const handlePageClick = (index) => {

        let filter1 = `{"app_department_id":${JSON.stringify(SiteId)},"is_active":${pageStatus}, "$or":[
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_contact_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"position":{"$regex":"${customFilterData}","$options":"i"}},
            {"department":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_contact_email":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`

        let filter2 = `{"is_active":${pageStatus},"$or":[{"app_contact_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_department_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_contact_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"position":{"$regex":"${customFilterData}","$options":"i"}},
            {"department":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_contact_email":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`
    
        const companyID = Busid
        if (Busid !== "null" && SiteId !== "null") {
            let skips = 0
            if (page > 0) {
                skips = page * pageLimit;
               dispatch(getContactss({ companyID, filter: filter1, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getContactss({ companyID, filter: filter1, limit: pageLimit, skip: 0 }));
            }
        } else {
            let skips = 0
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getAllContact({ filter: filter2, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getAllContact({ filter: filter2, limit: pageLimit, skip: 0 }));
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {permissions.includes("system.sales.contact.read") ? <Container fluid>
                    <Breadcrumbs title="Contacts " breadcrumbItem="Sales" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={contacts}
                                        isGlobalFilter={true}
                                        className="custom-header-css"
                                        customPageCount={pageCount}
                                        isCompanyDropdownWithSite={true}
                                        isSiteDropdown={true}
                                        isAddSalesContact={permissions.includes("system.sales.contact.write") ? true : false}
                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                    // isDepartmentList={permissions.includes("system.customers.credit.write") ? true : false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    );
};

export default Contacts;