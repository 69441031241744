import {
    GET_SYS_ROLES,
    GET_SYS_ROLES_SUCCESS,
    GET_SYS_ROLES_FAIL,

    GET_SYS_ROLE,
    GET_SYS_ROLE_SUCCESS,
    GET_SYS_ROLE_FAIL,

    POST_SYS_ROLES,
    POST_SYS_ROLES_SUCCESS,
    POST_SYS_ROLES_FAIL,

    DELETE_SYS_ROLES,
    DELETE_SYS_ROLES_SUCCESS,
    DELETE_SYS_ROLES_FAIL,

    UPDATE_SYS_ROLES,
    UPDATE_SYS_ROLES_SUCCESS,
    UPDATE_SYS_ROLES_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get sys roles  //
/////////////////////////


export const getSysRoles = (payload) => ({
    type: GET_SYS_ROLES,
    payload: payload
})

export const getSysRolesSuccess = sysRoles => ({
    type: GET_SYS_ROLES_SUCCESS,
    payload: sysRoles
})

export const getSysRolesFail = error => ({
    type: GET_SYS_ROLES_FAIL,
    payload: error
})
//////////////////////////////
// Get By id sys role //
//////////////////////////////

export const getAppSysRole = (payload) => ({
    type: GET_SYS_ROLE,
    payload: payload
});

export const getAppSysRoleSuccess = sysRoles => ({
    type: GET_SYS_ROLE_SUCCESS,
    payload: sysRoles,
});

export const getAppSysRoleFail = error => ({
    type: GET_SYS_ROLE_FAIL,
    payload: error,
});

////////////////////////
// Post sys Role     ///
////////////////////////

export const postAppSysRoles = (payload) => ({
    type: POST_SYS_ROLES,
    payload: payload
});

export const postAppSysRolesSuccess = sysRoles => ({
    type: POST_SYS_ROLES_SUCCESS,
    payload: sysRoles,
});

export const postAppSysRolesFail = error => ({
    type: POST_SYS_ROLES_FAIL,
    payload: error,
});

///////////////////////////
// Delete Business Unit //
//////////////////////////

export const deleteAppSysRoles = (payload) => ({
    type: DELETE_SYS_ROLES,
    payload: payload
});

export const deleteAppSysRolesSuccess = () => ({
    type: DELETE_SYS_ROLES_SUCCESS,
});

export const deleteAppSysRolesFail = error => ({
    type: DELETE_SYS_ROLES_FAIL,
    payload: error,
});

///////////////////////////
// Update Business Unit  //
///////////////////////////

export const updateSysRoles = (payload) => ({
    type: UPDATE_SYS_ROLES,
    payload: payload
});

export const updateSysRolesSuccess = payload => ({
    type: UPDATE_SYS_ROLES_SUCCESS,
    payload: payload
});

export const updateSysRolesFail = error => ({
    type: UPDATE_SYS_ROLES_FAIL,
    payload: error,
});



