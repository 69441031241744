import {
    GET_FINALIZEPROPOSAL,
    GET_FINALIZEPROPOSAL_SUCCESS,
    GET_FINALIZEPROPOSAL_FAIL,

    GET_FINALIZEPROPOSAL_DETAIL_SUCCESS,
    GET_FINALIZEPROPOSAL_DETAIL_FAIL,
    GET_FINALIZEPROPOSAL_DETAIL,

    POST_FINALIZEPROPOSAL,
    POST_FINALIZEPROPOSAL_SUCCESS,
    POST_FINALIZEPROPOSAL_FAIL,

    CONFIRM_FINALIZEPROPOSAL,
    CONFIRM_FINALIZEPROPOSAL_SUCCESS,
    CONFIRM_FINALIZEPROPOSAL_FAIL,

} from "./actionTypes";

/////////////////////////
//  Get  FinalizeProposals//
/////////////////////////

export const getFinalizeProposals = (payload) => ({
    type: GET_FINALIZEPROPOSAL,
    payload: payload
});

export const getFinalizeProposalsSuccess = products => ({
    type: GET_FINALIZEPROPOSAL_SUCCESS,
    payload: products,
});

export const getFinalizeProposalsFail = error => ({
    type: GET_FINALIZEPROPOSAL_FAIL,
    payload: error,
});

//////////////////////////////
// Get By id FinalizeProposal  //
//////////////////////////////

export const getFinalizeProposal  = (payload) => ({
    type: GET_FINALIZEPROPOSAL_DETAIL,
    payload: payload
});

export const getFinalizeProposalDetailSuccess = businessUnit => ({
    type: GET_FINALIZEPROPOSAL_DETAIL_SUCCESS,
    payload: businessUnit,
});

export const getFinalizeProposalDetailFail = error => ({
    type: GET_FINALIZEPROPOSAL_DETAIL_FAIL,
    payload: error,
});

////////////////////////
// Post FinalizeProposals///
////////////////////////

export const postFinalizeProposal = (payload) => ({
    type: POST_FINALIZEPROPOSAL,
    payload: payload
});

export const postFinalizeProposalSuccess = products => ({
    type: POST_FINALIZEPROPOSAL_SUCCESS,
    payload: products,
});

export const postFinalizeProposalFail = error => ({
    type: POST_FINALIZEPROPOSAL_FAIL,
    payload: error,
});


////////////////////////
// Update FinalizeProposal///
////////////////////////

export const confirmFinalizeProposal = (payload) => ({
    type: CONFIRM_FINALIZEPROPOSAL,
    payload: payload
});

export const confirmFinalizeProposalSuccess = products => ({
    type: CONFIRM_FINALIZEPROPOSAL_SUCCESS,
    payload: products,
});

export const confirmFinalizeProposalFail = error => ({
    type: CONFIRM_FINALIZEPROPOSAL_FAIL,
    payload: error,
});



