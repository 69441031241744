import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// sys role 
export const getSysRoles = (payload) => get(url.SYS_ROLE_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getSysRole = (payload) => payload.roleID ? get(url.SYS_ROLE_URL + `/${payload.roleID}`) : null;
export const postSysRoles = (payload) => post(url.SYS_ROLE_URL, payload);
export const updateSysRoles = (payload) => put(url.SYS_ROLE_URL + `/${payload.sysRoleId}`, payload.updatedData);
export const deleteSysRole = (payload) => payload.businessUnitId ? del(url.SYS_ROLE_URL + `/${payload.businessUnitId}`) : null;

//  sys Users
export const getSysUsers = (payload) => get(url.SYS_USER_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getSysUser = (payload) => payload.sysUserID ? get(url.SYS_USER_URL + `/${payload.sysUserID}`) : null;
export const postSysUsers = (payload) => post(url.SYS_USER_URL, payload);
export const updateSysUser = (payload) => payload.sysUserId ? put(url.SYS_USER_URL + `/${payload.sysUserId}`, payload.updatedData) : null
export const deleteSysUser = (payload) => payload.businessUnitId ? del(url.SYS_USER_URL + `/${payload.businessUnitId}`) : null;

// Sys User Credential 
export const getSysCredentials = (payload) => payload.sysRoleId ? get(url.SYS_CREDENTIALS + `/${payload.sysRoleId}` + "/credentials") : null;
export const postSysCredentials = (payload) => payload.sysRoleId ? post(url.SYS_CREDENTIALS + `/${payload.sysRoleId}` + "/credentials", payload.data) : null;

// App role
export const getAppRoles = (payload) => get(url.APP_ROLE_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const postAppRoles = (payload) => post(url.APP_ROLE_URL, payload);
export const updateAppRoles = (payload) => put(url.APP_ROLE_URL + `/${payload.appRoleId}`, payload.updatedData);
export const deleteAppRole = (payload) => payload.businessUnitId ? del(url.APP_ROLE_URL + `/${payload.businessUnitId}`) : null;
export const getAppRole = (payload) => payload.roleID ? get(url.APP_ROLE_URL + `/${payload.roleID}`) : null;

// App Users
export const getAppUsers = (payload) => get(url.APP_USER_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getAppUser = (payload) => payload ? get(url.APP_USER_URL + `/${payload}`) : null;
export const postAppUser = (payload) => post(url.APP_USER_URL, payload);
export const updateAppUser = (payload) => payload.appUserID ? put(url.APP_USER_URL + `/${payload.appUserID}`, payload.updatedData) : null
export const deleteAppUser = (payload) => payload.customerUserID ? del(url.APP_USER_URL + `/${payload.customerUserID}`) : null;

// App user Credential 
export const getAppCredentials = (payload) => payload.appRoleId ? get(url.APP_CREDENTIALS + `/${payload.appRoleId}` + "/credentials") : null;
export const postAppCredentials = (payload) => payload.appRoleId ? post(url.APP_CREDENTIALS + `/${payload.appRoleId}` + "/credentials", payload.data) : null;

// sys user profile get 
export const getSysUserProfile = (payload) => get(url.SYS_USER_PROFILE);

//  Platform Action
export const getPlatformActions = (payload) => get(url.PLATFORM_ACTION);

// Business Action 
export const getBusinessActions = (payload) => get(url.BUSINESS_ACTION)

// Generic Industry
export const getGenericIndustry = (payload) => get(url.INDUSTRY)
export const getGenericIndustryID = (payload) => get(url.INDUSTRY_ID + `/${payload}`)
export const postAppGenericindustry = (payload) => post(url.INDUSTRY, payload)

// Business company
export const getAppBusinesses = (payload) => get(url.BUSINESS_URL);


//  Opportunities
export const getAppOpportunitieses = (payload) => get(url.OPPORTUNITIES_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getAppOpportunities = (payload) => payload.opportunitiesId ? get(url.OPPORTUNITIES_URL + `/${payload.opportunitiesId}`) : null;
export const postAppOpportunities = (payload) => post(url.OPPORTUNITIES_URL, payload);
export const updateAppOpportunities = (payload) => payload.opportunitiesId ? put(url.OPPORTUNITIES_URL + `/${payload.opportunitiesId}`, payload.updatedData) : null
export const deleteAppOpportunities = (payload) => payload.businessUnitId ? del(url.OPPORTUNITIES_URL + `/${payload.businessUnitId}`) : null;

//  Quotation
export const getAppQuotation = (payload) => payload.quotationId ? get(url.QUOTATION_VERSION_URL + `/${payload.quotationId}`) : null;
export const updateAppQuotation = (payload) => payload.quotationId ? put(url.QUOTATION_VERSION_URL + `/${payload.quotationId}`, payload.updatedData) : null
export const postAppQuotation = (payload) => post(url.QUOTATION_VERSION_URL, payload);

export const getAppQuotations = (payload) => get(url.OPPORTUNITIES_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const deleteAppQuotation = (payload) => payload.businessUnitId ? del(url.OPPORTUNITIES_URL + `/${payload.businessUnitId}`) : null;

//  Invoices
export const getAppInvoice = (payload) => payload.invoiceId ? get(url.INVOICE_VERSION_URL + `/${payload.invoiceId}`) : null;
export const getAppInvoices = (payload) => post(url.INVOICE_VERSION_URL + "/list" + `?filter=${payload.filter}`)
export const postAppInvoice = (payload) => post(url.INVOICE_VERSION_URL, payload);
export const updateAppInvoice = (payload) => payload.invoiceId ? put(url.INVOICE_VERSION_URL + `/${payload.invoiceId}`, payload.updatedData) : null
export const deleteAppInvoice = (payload) => payload.businessUnitId ? del(url.INVOICE_VERSION_URL + `/${payload.businessUnitId}`) : null;
export const getAppInvoiceConfirm = (payload) => get(url.INVOICE_VERSION_URL + `/confirminvoice/${payload.opportunityId}`)

// business
export const getBusinesses = (payload) => get(url.BUSINESS_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getBusiness = (payload) => payload.roleID ? get(url.BUSINESS_URL + `/${payload.roleID}`) : null;
export const postBusiness = (payload) => post(url.BUSINESS_URL, payload);
export const updateBusiness = (payload) => put(url.BUSINESS_URL + `/${payload.sysRoleId}`, payload.updatedData);
export const deleteBusiness = (payload) => payload.businessId ? del(url.BUSINESS_URL + `/${payload.businessId}`) : null;

// Department
export const getAllDepartment = (payload) => get(url.DEPARTMENT_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getDepartments = (payload) => payload.companyID ? get(url.DEPARTMENT_URL + `/${payload.companyID}` + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`) : null;
export const getDepartment = (payload) => payload.businessID ? get(url.DEPARTMENT_URL + `/${payload.businessID}` + `/${payload.departmentID}`) : null;
export const postDepartment = (payload) => post(url.DEPARTMENT_URL + `/${payload.companyID}`, payload.siteItems);
export const updateDepartment = (payload) => put(url.DEPARTMENT_URL + `/${payload.companyID}` + `/${payload.editDepartmentID}`, payload.UpdateSiteData);
export const deleteDepartment = (payload) => payload.companyID ? del(url.DEPARTMENT_URL + `/${payload.companyID}` + `/${payload.DepartmentID}`) : null;

// Contact  
export const getAllContact = (payload) => get(url.CONTACT_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getContacts = (payload) => payload.companyID ? get(url.CONTACT_URL + `/${payload.companyID}` + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`) : null;
export const getContact = (payload) => payload.businessID ? get(url.CONTACT_URL + `/${payload.businessID}` + `/${payload.contactID}`) : null;
export const postContact = (payload) => post(url.CONTACT_URL + `/${payload.companyID}`, payload.contactValues);
export const updateContact = (payload) => put(url.CONTACT_URL + `/${payload.companyID}` + `/${payload.editContactID}`, payload.value);
export const deleteContact = (payload) => payload.companyID ? del(url.CONTACT_URL + `/${payload.companyID}` + `/${payload.ContactID}`) : null;

// Address
export const getAllAddress = (payload) => get(url.ADDRESS_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getAddresses = (payload) => payload.companyID ? get(url.ADDRESS_URL + `/${payload.companyID}` + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`) : null;
export const getAddress = (payload) => payload.businessID ? get(url.ADDRESS_URL + `/${payload.businessID}` + `/${payload.addressID}`) : null;
export const postAddress = (payload) => post(url.ADDRESS_URL + `/${payload.companyID}`, payload.value);
export const updateAddress = (payload) => put(url.ADDRESS_URL + `/${payload.companyID}` + `/${payload.editAddressID}`, payload.value);
export const deleteAddress = (payload) => payload.companyID ? del(url.ADDRESS_URL + `/${payload.companyID}` + `/${payload.AddressID}`) : null;

//  Owners
export const getOwners = (payload) => payload.companyID ? get(url.OWNER_URL + `/${payload.companyID}` + `?limit=${payload.limit}&skip=${payload.skip}`) : null;
export const getOwner = (payload) => payload.sysUserID ? get(url.OWNER_URL + `/${payload.sysUserID}`) : null;
export const postOwner = (payload) => post(url.OWNER_URL, payload);
export const updateOwner = (payload) => payload.sysUserId ? put(url.OWNER_URL + `/${payload.sysUserId}`, payload.updatedData) : null  // never use
export const deleteOwner = (payload) => payload ? del(url.OWNER_URL + `/${payload}`) : null;

// Business Unit 
export const getAppBusinessUnits = (payload) => get(url.APP_BUSINESS_UNITS_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`);
export const postAppBusinessUnits = (payload) => post(url.APP_BUSINESS_UNITS_URL, payload);
export const updateAppBusinessUnits = (payload) => put(url.APP_BUSINESS_UNITS_URL + `/${payload.businessUnitId}`, payload.updateData);
export const deleteAppBusinessUnit = (payload) => payload.businessUnitId ? del(url.APP_BUSINESS_UNITS_URL + `/${payload.businessUnitId}`) : null;
export const getAppBusinessUnit = (payload) => payload.businessUnitId ? get(url.APP_BUSINESS_UNITS_URL + `/${payload.businessUnitId}`) : null;

// Business Unit Division
export const getBusinessUnitDivisions = (payload) => get(url.BUSINESS_UNIT_DIVISION_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`);
export const postBusinessUnitDivision = (payload) => post(url.BUSINESS_UNIT_DIVISION_URL, payload);
export const updateBusinessUnitDivision = (payload) => put(url.BUSINESS_UNIT_DIVISION_URL + `/${payload.busUnitDivisionId}`, payload.updateData);
export const deleteBusinessUnitDivision = (payload) => payload.businessUnitId ? del(url.BUSINESS_UNIT_DIVISION_URL + `/${payload.businessUnitId}`) : null;
export const getBusinessUnitDivision = (payload) => payload.businessUniDivisiontId ? get(url.BUSINESS_UNIT_DIVISION_URL + `/${payload.businessUniDivisiontId}`) : null;

// Product 
export const getBusinessProducts = (payload) => get(url.BUSINESS_PRODUCTS_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`);
export const updateBusinessProduct = (payload) => put(url.BUSINESS_PRODUCTS_URL + `/${payload.productId}`, payload.updateData);
export const getBusinessProduct = (payload) => payload.businessProductId ? get(url.BUSINESS_PRODUCTS_URL + `/${payload.businessProductId}`) : null;

// Credits
export const getAllCredit = (payload) => get(url.CREDITS_URL + `?limit=${payload.limit}&skip=${payload.skip}`)
export const getCredits = (payload) => payload.id ? get(url.CREDITS_URL + `/${payload.id}` + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`) : null;
export const postCredit = (payload) => post(url.CREDITS_URL, payload);
export const updateCredit = (payload) => put(url.CREDITS_URL + `/${payload.BusID}` + `/${payload.id}`, payload.values);
export const deleteCredit = (payload) => payload.creditID ? del(url.CREDITS_URL + `/${payload.creditID}` + "/business" + `/${payload.businessid}`) : null;
export const getCredit = (payload) => payload.id ? get(url.CREDITS_URL + `/${payload.BusID}` + `/${payload.id}`) : null;

// Login Attempts
export const getLoginRetry = () => get(url.LOGIN_RETRY_URL)
export const postLoginRetry = (payload) => post(url.LOGIN_RETRY_URL, payload.newData2);
export const updateLoginRetry = (payload) => put(url.LOGIN_RETRY_URL, payload.newData2);

// Otp TimeOuts
export const getOtpTimeout = () => get(url.OTP_TIMEOUT_URL)
export const postOtpTimeout = (payload) => post(url.OTP_TIMEOUT_URL, payload.value);
export const updateOtpTimeout = (payload) => put(url.OTP_TIMEOUT_URL, payload.value);


// Password Policy
export const getPassWordPolicys = () => get(url.PASSWORD_POLICY_URL);
export const postPassWordPolicy = (payload) => post(url.PASSWORD_POLICY_URL, payload.data);
export const updatePassWordPolicy = (payload) => put(url.PASSWORD_POLICY_URL, payload.data);

//Activities Log  
export const getActivities = (payload) => {
    let filter = `{${getQueryParams(payload.filter)}}`
    return get(url.ACTIVITIES + `?limit=${payload.limit}&skip=${payload.skip}&filter=${filter}`)
};

function getQueryParams(obj) {
    let qparamArr = [];
    Object.entries(obj).forEach((ele) => {
        ele[1] = ele[1].trim();
        if (ele[1]) {
            qparamArr.push(`"${ele[0]}":{"$regex":"${ele[1]}", "$options": "i"}`);
        }
    })
    return qparamArr.join(",");
}

//Simple mail transfer protocol(smtp)
export const getSmtp = () => get(url.SMTP_URL);
export const postSmtp = (payload) => post(url.SMTP_URL, payload.data);
export const updateSmtp = (payload) => put(url.SMTP_URL, payload.data);


//PRICING MODEL
export const getPricingModels = (payload) => get(url.PRICING_MODEL_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const postPricingModel = (payload) => post(url.PRICING_MODEL_URL, payload);
export const updatePricingModel = (payload) => put(url.PRICING_MODEL_URL + `/${payload.pricingModelId}`, payload.updateData);
export const deletePricingModel = (payload) => payload.creditID ? del(url.PRICING_MODEL_URL + `/${payload.PRICING_MODEL_URL}` + "/business" + `/${payload.businessid}`) : null;
export const getPricingModel = (payload) => payload.pricingModelId ? get(url.PRICING_MODEL_URL + `/${payload.pricingModelId}`) : null;

//ACCOUNTS
export const getAccount = (payload) => get(url.ACCOUNTS_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const postAccount = (payload) => post(url.ACCOUNTS_URL, payload);
export const updateAccount = (payload) => put(url.ACCOUNTS_URL + `/${payload.accountId}`, payload.updateData);
export const deleteAccount = (payload) => payload.accountID ? del(url.ACCOUNTS_URL + `/${payload.ACCOUNTS_URL}` + "/business" + `/${payload.businessid}`) : null;
export const getAccountDetail = (payload) => payload.id ? get(url.ACCOUNTS_URL + `/${payload.BusID}` + `/${payload.id}`) : null;



// Sys User Product 
export const getSysUserProduct = () => get(url.SYS_USER_PRODUCT_URL)

//TRANSACTION
export const getTransaction = (payload) => get(url.TRANSACTION_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`);
export const getTransactionDetail = (payload) => get(url.TRANSACTION_URL + `/${payload.transactionID}`);
export const postTransaction = (payload) => post(url.TRANSACTION_URL, payload);
export const updateTransaction = (payload) => put(url.TRANSACTION_URL + `/${payload.accountId}`, payload.updateData);
export const deleteTransaction = (payload) => payload.transactionID ? del(url.TRANSACTION_URL + `/${payload.TRANSACTION_URL}` + "/business" + `/${payload.businessid}`) : null;

//  Proposal version
// export const getAppProposalversions = (payload) => get(url.PROPOSAL_VERSION_URL +`?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`)
export const getProposalVersion = (payload) => get(url.PROPOSAL_VERSION_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`);
export const getProposalVersionDetail = (payload) => payload.proposalId ? get(url.PROPOSAL_VERSION_URL + `/${payload.proposalId}`) : null;
export const postAppProposalversion = (payload) => post(url.PROPOSAL_VERSION_URL, payload);
export const updateAppProposalversion = (payload) => payload.proposalId ? put(url.PROPOSAL_VERSION_URL + `/${payload.proposalId}`, payload.updatedData) : null
export const deleteAppProposalversion = (payload) => payload.proposalId ? del(url.PROPOSAL_VERSION_URL + `/${payload.proposalId}`) : null;

//proposal opportunity get url
export const getProposalbyOpportunityid = (payload) => get(url.PROPOSAL_OPPORTUNITY_GET_URL + `/${payload.opportunity_id}` + `?filter=${payload.filter}`);
//export const getProposalbyOpportunityid = (payload) => payload.opportunity_id? get(url.PROPOSAL_OPPORTUNITY_GET_URL +`/${payload.opportunity_id}`+`?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`) : null;
// export const getProposalbyOpportunityid = (payload) => get(url.PROPOSAL_OPPORTUNITY_GET_URL + `/${payload.opportunity_id}`);

//finalize proposal
export const getFinalizeProposal = (payload) => get(url.FINALIZE_PROPOSAL_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`);
export const getAppFinalizeProposalDetail = (payload) => get(url.FINALIZE_PROPOSAL_URL + `/${payload.finalizeProposalId}`)
export const postAppFinalizeProposal = (payload) => post(url.FINALIZE_PROPOSAL_URL, payload);
export const confirmAppFinalizeProposal = (payload) => put(url.FINALIZE_PROPOSAL_URL + "/confirm" + `/${payload.finalizedProposalId}`, payload.updateData);

//proposal clone version
// export const postPeoposalCloneVersionDetail = (payload) => payload.PROPOSAL_CLONE_VERSION_URL? get(url.PROPOSAL_CLONE_VERSION_URL + `/${payload.proposalId}`) : null;
export const postProposalCloneVersion = (payload) => post(url.PROPOSAL_CLONE_VERSION_URL + `/${payload}`);

//opportunity clone version
export const postOpportunityCloneVersion = (payload) => post(url.OPPORTUNITY_CLONE_VERSION_URL + `/${payload.opportunityId}`, payload.data);

//Application Template
export const getApplicationTemplate = (payload) => get(url.APPLICATION_TEMPLATE_URL + `?filter=${payload.filter}&limit=${payload.limit}&skip=${payload.skip}`);
export const getApplicationTemplateDetail = (payload) => payload.templateId ? get(url.APPLICATION_TEMPLATE_URL + `/${payload.templateId}`) : null;
export const postApplicationTemplate = (payload) => post(url.APPLICATION_TEMPLATE_URL, payload);
export const updateApplicationTemplate = (payload) => payload.templateId ? put(url.APPLICATION_TEMPLATE_URL + `/${payload.templateId}`, payload.updatedData) : null
export const deleteApplicationTemplate = (payload) => payload.templateId ? del(url.APPLICATION_TEMPLATE_URL + `/${payload.templateId}`) : null;

//Product synchronize
export const getSynchronize = (payload) => get(url.PRODUCT_SYNCHRONIZE_URL + `?request_start_date=${payload.request_start_date}&request_end_date=${payload.request_end_date}`);


//Product coupon
export const postAppCoupon = (payload) => post(url.COUPON_POST_URL, payload);
export const getAppCoupon = (payload) => get(url.COUPON_GET_URL);
export const getAppRequestCoupon = (payload) => get(url.COUPON_GET_URL+`?request_id=${payload.requestId}`);
export const getAppCouponStatus = (payload) => get(url.COUPON_GET_URL+"nos"+`?request_id=${payload.requestId}`);
export const getAppCouponStatusByInvoiceId = (payload) => get(url.COUPON_GET_URL + `/status/${payload.invoiceId}`);


//Attachment
export const getAttachments = (payload) => get(url.ATTACHMENT_URL);
export const postAttachments = (payload) => post(url.ATTACHMENT_URL, payload.data);
export const updateAttachments = (payload) => put(url.ATTACHMENT_URL, payload.data);

//Downloads
// export const getDownloadlog = (payload) => get(url.DOWNLOAD_LOG_URL + `/${payload.requestId}`);
export const getDownloadlog = (payload) => get(url.DOWNLOAD_LOG_URL + `?filter=${payload.filter}`);
export const getDownloadlogDetail = (payload) => payload.downloadId ? get(url.DOWNLOAD_LOG_URL + `/${payload.downloadId}`) : null;
export const postDownloadlog = (payload) => post(url.DOWNLOAD_LOG_URL, payload);
export const deleteDownloadlog = (payload) => payload.downloadId ? del(url.DOWNLOAD_LOG_URL + `/${payload.downloadId}`) : null;


// coupon generate

export const postAppCouponGenerate = (payload) => post(url.COUPON_GENERATE_URL, payload);
export const getAppCouponGenerate = (payload) => get(url.COUPON_GENERATE_URL+ `/${payload.requestId}`);

//dashboard notification

export const getNotificationApproval = (payload) => get(url.NOTIFICATION_APPROVAL_STATUS_URL);
export const getNotificationPending = (payload) => get(url.NOTIFICATION_PENDING_STATUS_URL);


