import {
    GET_BUSINESS_ACTIONS,
    GET_BUSINESS_ACTIONS_SUCCESS,
    GET_BUSINESS_ACTIONS_FAIL,
}from "./actionTypes"

////////////////////
//     GET        //
////////////////////

export const getBusinessActions = (payload) =>({
    type: GET_BUSINESS_ACTIONS,
    payload: payload
})

export const getBusinessActionsSuccess = actions =>({
    type : GET_BUSINESS_ACTIONS_SUCCESS,
    payload: actions
})

export const getBusinessActionsFail = error =>({
    type :GET_BUSINESS_ACTIONS_FAIL,
    payload :error
})