import {
  GET_QUOTATION,
  GET_QUOTATION_SUCCESS,
  GET_QUOTATION_FAIL,

  POST_QUOTATION,
  POST_QUOTATION_SUCCESS,
  POST_QUOTATION_FAIL,

  DELETE_QUOTATION,
  DELETE_QUOTATION_SUCCESS,
  DELETE_QUOTATION_FAIL,

  GET_QUOTATION_DETAIL_SUCCESS,
  GET_QUOTATION_DETAIL_FAIL,
  GET_QUOTATION_DETAIL,

  UPDATE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_FAIL,
  UPDATE_QUOTATION,
} from "./actionTypes";

const INIT_STATE = {
  Quotations: null,
  Quotation: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const AppQuotationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUOTATION:
      return {
        ...state,
        loading: true,
        Quotations: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        Quotations: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_QUOTATION:
      return {
        ...state,
        loading: true,
        Quotation: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        Quotation: action.payload,
        isCreated: true,
        isDeleted: false,
        isUpdated: false
      };
    case POST_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case DELETE_QUOTATION:
      return {
        ...state,
        loading: true,
        Quotation: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case DELETE_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        Quotation: null,
        isCreated: false,
        isDeleted: true,
        isUpdated: false
      };
    case DELETE_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_QUOTATION_DETAIL:
      return {
        ...state,
        loading: true,
        Quotation: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_QUOTATION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        Quotation: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_QUOTATION_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case UPDATE_QUOTATION:
      return {
        ...state,
        loading: true,
        Quotations: null,
        Quotation: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case UPDATE_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        Quotation: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: true
      };
    case UPDATE_QUOTATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default AppQuotationReducer;


