import React from 'react'
import { useForm } from "react-hook-form"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label } from "reactstrap"
import { useContext } from 'react'
import PricingModelContext from '../PricingModelContext'


function CreateTiers() {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const { setPageState, tiers, setTiers } = useContext(PricingModelContext)

    const getRndInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const createTier = (data) => {
        data = {
            ...data,
            is_active: true,
            localID: getRndInteger(1, 999)
        }
        const newTier = [
            ...tiers,
            data
        ]
        setTiers(newTier)
        setPageState(true)
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Tiers" breadcrumbItem="Pricing Models" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createTier)}>

                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Tier No<span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                            <Col lg="5" md="6">
                                                <input type="number" className='form form-control' {...register('app_tier_no', { required: true })} />
                                                {errors.app_tier_no && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Tier Name <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className='form form-control' {...register('app_tier_name', { required: true })} />
                                                {errors.app_tier_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Qualifying Input <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                            <Col lg="5" md="6">
                                                <input type="number" className='form form-control' {...register('app_tier_qualifying_input', { required: true })} />
                                                {errors.app_tier_qualifying_input && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Discount Value <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className='form form-control' {...register('app_tier_discount_Type', { required: true })} />
                                                {errors.app_tier_discount_Type && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Discount Value <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                            <Col lg="5" md="6">
                                                <input type="number" className='form form-control' {...register('app_tier_discount_value', { required: true })} />
                                                {errors.app_tier_discount_value && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => setPageState(true)}>
                                                    Back
                                                </Button>
                                                <Button id="createtier" color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateTiers