
export const POST_OTP_TIMEOUT = "POST_OTP_TIMEOUT"
export const POST_OTP_TIMEOUT_SUCCESS = "POST_OTP_TIMEOUT_SUCCESS"
export const POST_OTP_TIMEOUT_FAIL = "POST_OTP_TIMEOUT_FAIL"

export const GET_OTP_TIMEOUT = "GET_OTP_TIMEOUT"
export const GET_OTP_TIMEOUT_SUCCESS = "GET_OTP_TIMEOUT_SUCCESS"
export const GET_OTP_TIMEOUT_FAIL = "GET_OTP_TIMEOUT_FAIL"

export const UPDATE_OTP_TIMEOUT = "UPDATE_OTP_TIMEOUT"
export const UPDATE_OTP_TIMEOUT_SUCCESS = "UPDATE_OTP_TIMEOUT_SUCCESS"
export const UPDATE_OTP_TIMEOUT_FAIL = "UPDATE_OTP_TIMEOUT_FAIL"