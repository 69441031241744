import {
    GET_OWNERS,
    GET_OWNERS_SUCCESS,
    GET_OWNERS_FAIL,

    GET_OWNER,
    GET_OWNER_SUCCESS,
    GET_OWNER_FAIL,

    POST_OWNER,
    POST_OWNER_SUCCESS,
    POST_OWNER_FAIL,

    DELETE_OWNER,
    DELETE_OWNER_SUCCESS,
    DELETE_OWNER_FAIL,

    UPDATE_OWNER,
    UPDATE_OWNER_SUCCESS,
    UPDATE_OWNER_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get sys owners  //
/////////////////////////


export const getOwners = (payload) => ({
    type: GET_OWNERS,
    payload: payload
})

export const getOwnersSuccess = owners => ({
    type: GET_OWNERS_SUCCESS,
    payload: owners
})

export const getOwnersFail = error => ({
    type: GET_OWNERS_FAIL,
    payload: error
})
//////////////////////////////
// Get By id sys owners //
//////////////////////////////

export const getOwner = (payload) => ({
    type: GET_OWNER,
    payload: payload
});

export const getOwnerSuccess = owners => ({
    type: GET_OWNER_SUCCESS,
    payload: owners,
});

export const getOwnerFail = error => ({
    type: GET_OWNER_FAIL,
    payload: error,
});

////////////////////////
// Post sys owners     ///
////////////////////////

export const postOwner = (payload) => ({
    type: POST_OWNER,
    payload: payload
});

export const postOwnerSuccess = owners => ({
    type: POST_OWNER_SUCCESS,
    payload: owners,
});

export const postOwnerFail = error => ({
    type: POST_OWNER_FAIL,
    payload: error,
});

///////////////////////////
// Delete sys owners //
//////////////////////////

export const deleteOwner= (payload) => ({
    type: DELETE_OWNER,
    payload: payload
});

export const deleteOwnerSuccess = () => ({
    type: DELETE_OWNER_SUCCESS,
});

export const deleteOwnerFail = error => ({
    type: DELETE_OWNER_FAIL,
    payload: error,
});

///////////////////////////
// Update sys owners  //
///////////////////////////

export const updateOwner = (payload) => ({
    type: UPDATE_OWNER,
    payload: payload
});

export const updateOwnerSuccess = payload => ({
    type: UPDATE_OWNER_SUCCESS,
    payload: payload
});

export const updateOwnerFail = error => ({
    type: UPDATE_OWNER_FAIL,
    payload: error,
});



