import {
    GET_OWNERS,
    GET_OWNERS_SUCCESS,
    GET_OWNERS_FAIL,

    GET_OWNER,
    GET_OWNER_SUCCESS,
    GET_OWNER_FAIL,

    POST_OWNER,
    POST_OWNER_SUCCESS,
    POST_OWNER_FAIL,

    DELETE_OWNER,
    DELETE_OWNER_SUCCESS,
    DELETE_OWNER_FAIL,

    UPDATE_OWNER,
    UPDATE_OWNER_SUCCESS,
    UPDATE_OWNER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    owners: null,
    owner: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const ownerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OWNERS:
            return {
                ...state,
                loading: true,
                owners: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_OWNERS_SUCCESS:
            return {
                ...state,
                loading: false,
                owners: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_OWNERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_OWNER:
            return {
                ...state,
                loading: true,
                owners: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_OWNER_SUCCESS:
            return {
                ...state,
                loading: false,
                owner: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_OWNER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_OWNER:
            return {
                ...state,
                loading: true,
                owners: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_OWNER_SUCCESS:
            return {
                ...state,
                loading: false,
                owner: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_OWNER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_OWNER:
            return {
                ...state,
                loading: true,
                owner: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_OWNER_SUCCESS:
            return {
                ...state,
                loading: false,
                owner: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_OWNER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_OWNER:
            return {
                ...state,
                loading: true,
                // owners: null,
                owner: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_OWNER_SUCCESS:
            return {
                ...state,
                loading: false,
                owner: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_OWNER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default ownerReducer;
