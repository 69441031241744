import { takeEvery, put, call } from "redux-saga/effects"
import {
    getApplicationTemplateSuccess,
    getApplicationTemplateFail,
    getApplicationTemplateDetailSuccess,
    getApplicationTemplateDetailFail,
    postApplicationTemplateSuccess,
    postApplicationTemplateFail,
    deleteApplicationTemplateSuccess,
    deleteApplicationTemplateFail,
    updateApplicationTemplateSuccess,
    updateApplicationTemplateFail
} from "./actions"

import {
 getApplicationTemplate,
 getApplicationTemplateDetail,
 postApplicationTemplate,
 deleteApplicationTemplate,
 updateApplicationTemplate
 
}
  from "helpers/backendApi_helpers";

import {
 GET_APPLICATION_TEMPLATE,
 GET_APPLICATION_TEMPLATE_DETAIL,
 POST_APPLICATION_TEMPLATE,
 DELETE_APPLICATION_TEMPLATE,
 UPDATE_APPLICATION_TEMPLATE
} from "./actionTypes";

function* fetchApplicationTemplate({ payload: payload }) {
  try {
    const response = yield call(getApplicationTemplate, payload);
    yield put(getApplicationTemplateSuccess(response))
  } catch (error) {
    yield put(getApplicationTemplateFail(error))
  }
}


function* getTemplateDetail({ payload: payload }) {
    try {
      const response = yield call(getApplicationTemplateDetail, payload);
      yield put(getApplicationTemplateDetailSuccess(response))
    } catch (error) {
      yield put(getApplicationTemplateDetailFail(error))
    }
  }
  

function* postTemplate({ payload: payload }) {
  try {
    const response = yield call(postApplicationTemplate, payload);
    yield put(postApplicationTemplateSuccess(response))
  } catch (error) {
    yield put(postApplicationTemplateFail(error))
  }
}


function* updateTemplate({ payload: payload }) {
  try {
    const response = yield call(updateApplicationTemplate, payload);
    yield put(updateApplicationTemplateSuccess(response))
  } catch (error) {
    yield put(updateApplicationTemplateFail(error))
  }
}

function* deleteTemplate({ payload: payload }) {
    try {
      const response = yield call(deleteApplicationTemplate, payload);
      yield put(deleteApplicationTemplateSuccess(response))
    } catch (error) {
      yield put(deleteApplicationTemplateFail(error))
    }
  }

function* AppplicationTemplateSaga() {
  yield takeEvery( GET_APPLICATION_TEMPLATE, fetchApplicationTemplate)
  yield takeEvery( GET_APPLICATION_TEMPLATE_DETAIL, getTemplateDetail)
  yield takeEvery( POST_APPLICATION_TEMPLATE, postTemplate)
  yield takeEvery( UPDATE_APPLICATION_TEMPLATE, updateTemplate)
  yield takeEvery( DELETE_APPLICATION_TEMPLATE, deleteTemplate)

}

export default AppplicationTemplateSaga;





