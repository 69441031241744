import { takeEvery, put, call } from "redux-saga/effects"
import {
    getLoginRetrySuccess, getLoginRetryFail,
    postLoginRetrySuccess, postLoginRetryFail,
    updateLoginRetrySuccess, updateLoginRetryFail,
} from "./actions"

// Api Handler 
import {
    getLoginRetry,
    postLoginRetry,
    updateLoginRetry,
} from "helpers/backendApi_helpers"


import {
    GET_LOGIN_RETRY,
    POST_LOGIN_RETRY,
    UPDATE_LOGIN_RETRY
} from "./actionTypes";


function* postLoginretry({ payload: payload }) {
    try {
        const response = yield call(postLoginRetry, payload);
        yield put(postLoginRetrySuccess(response))
    } catch (error) {
        yield put(postLoginRetryFail(error))
    }
}

function* getLoginretry({ payload: payload }) {
    try {
        const response = yield call(getLoginRetry, payload);
        yield put(getLoginRetrySuccess(response))
    } catch (error) {
        yield put(getLoginRetryFail(error))
    }
}

function* updateLoginretry({ payload: payload }) {
    try {
        const response = yield call(updateLoginRetry, payload);
        yield put(updateLoginRetrySuccess(response))
    } catch (error) {
        yield put(updateLoginRetryFail(error))
    }
}

function* LoginRetrysaga() {
    yield takeEvery(POST_LOGIN_RETRY, postLoginretry)
    yield takeEvery(GET_LOGIN_RETRY, getLoginretry)
    yield takeEvery(UPDATE_LOGIN_RETRY, updateLoginretry)
}

export default LoginRetrysaga


    