import {
   POST_PROPOSAL_CLONE_VERSION,
   POST_PROPOSAL_CLONE_VERSION_SUCCESS,
   POST_PROPOSAL_CLONE_VERSION_FAIL

} from "./actionTypes";


//////////////////////////////
// Get By id OPPORTUNITIES  //
//////////////////////////////

export const postProposalCloneVersion  = (payload) => ({
    type: POST_PROPOSAL_CLONE_VERSION,
    payload: payload
});

export const postProposalCloneVersionSuccess = businessUnit => ({
    type: POST_PROPOSAL_CLONE_VERSION_SUCCESS,
    payload: businessUnit,
});

export const postProposalCloneVersionFail = error => ({
    type: POST_PROPOSAL_CLONE_VERSION_FAIL,
    payload: error,
});




