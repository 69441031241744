import React, { useMemo } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'
import TableContainer from 'components/Common/TableContainer'
import {
    Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Collapse,
    Nav, NavItem, NavLink, TabContent, TabPane, CardBody, Table, UncontrolledTooltip
} from "reactstrap"
import classnames from "classnames"


import Breadcrumbs from "components/Common/Breadcrumb";
import axios from 'axios';
import getHeader from 'config/header';

import { Status, CompanyName } from './company/CompanyCol';
import StatusModal from 'components/Common/StatusModal'

import { useDispatch, useSelector } from 'react-redux'

import { getSysRoles } from "store/administration/portalRole/actions"
import { getAppBusinessUnits } from "store/administration/businessUnit/actions"
import { getAppSysUser, updateSysUsers } from "store/administration/portalUser/actions"



function UpdateUser() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const { register, handleSubmit, formState: { errors }, getValues, unregister, setValue } = useForm()
    const [roles, setRoles] = useState([])
    const [user, setUser] = useState('')


    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const UpadatePermission = (permissionAction.includes("system.administration.portalusers.update")) ? false : true
    const DeletePermission = (permissionAction.includes("system.administration.portalusers.delete")) ? false : true

    // Get Roles
    const sysRolesState = useSelector(state => state.AppSysRoles)

    useEffect(() => {
        if (sysRolesState.appSysRoles !== null) {
            setRoles(sysRolesState.appSysRoles.result)
        }
    }, [sysRolesState])

    useEffect(() => {
        dispatch(getSysRoles({ filter: `{"is_active":true}`, limit: 0, skip: 0 }))
    }, [])



    const [businessUnitList, setBusinessUnitList] = useState([])
    const businessUnitSate = useSelector(state => state.AppBusinessUnits)

    useEffect(() => {
        dispatch(getAppBusinessUnits({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));
    }, [])

    useEffect(() => {
        if (businessUnitSate.appBusinessUnits !== null) {
            setBusinessUnitList(businessUnitSate.appBusinessUnits.result)
            setAppBusinessUnit(businessUnitSate.appBusinessUnits.result)
        }
    }, [businessUnitSate])


    const sysUserState = useSelector(state => state.sysUsersReducer)
    useEffect(() => {
        if (id && roles && businessUnitList) {
            const data = { sysUserID: id }
            dispatch(getAppSysUser(data))
        }
    }, [roles, businessUnitList])

    const [azureSSO, setAzureSSO] = useState({
        state: false,
        name: "Inactive"
    })

    useEffect(() => {
        if (sysUserState.sysUser !== null) {
            const res = sysUserState.sysUser
            setUser(res)
            if (res.is_active === true) {
                setValue("is_active", "active")
            } else if (res.is_active === false) {
                setValue("is_active", "inactive")
            }
            setValue("sys_user_keyid", res.sys_user_keyid)
            // setValue("sys_user_company", res.sys_user_company)
            // updateDepartmentsOptions(res.sys_user_department);
            setValue("sys_user_email", res.sys_user_email)
            setValue("sys_user_id", res.sys_user_id)
            setValue("sys_user_name", res.sys_user_name)
            setValue("sys_user_password", res.sys_user_password)
            // setValue("is_azure_user", res.is_azure_user)
            sysRolesID(res.sys_user_roles ? res.sys_user_roles : [])
            // sysBusUnitID(userData.sys_user_business_unit)

            if (res?.is_azure_user) {
              setAzureSSO({
                state: true,
                name: 'Active'
              })
            } else {
              setAzureSSO({
                state: false,
                name: 'Inactive'
              })
            }
        }
        if (sysUserState.isUpdated) {
            history.goBack()
        }
    }, [sysUserState])






    // Create User
    const updateUser = (data) => {

        let roleID = data.sys_user_roles
        let Dta = []
        let RoleName = []
        for (let i in roleID) {
            Dta.push(roleID[i].value)
            RoleName.push(roleID[i].label)
        }
        // let busUnitID = data.sys_user_business_unit
        // let busDta = []
        // for (let i in busUnitID) {
        //     busDta.push(busUnitID[i].value)
        // }

        let value
        if (data.is_active === "active") {
            value = true
        } else if (data.is_active === "inactive") {
            value = false
        }



        const values = {
            // ...user,
            // sys_user_role_id: Dta,
            // sys_user_role_name: RoleName,
            // sys_user_business_unit: busDta,
            // ...data,


            sys_user_id: data.sys_user_id,
            sys_user_name: data.sys_user_name,
            sys_user_roles: Dta,
            is_active: value
        }

        const Data = {
            sysUserId: id,
            updatedData: values
        }

        dispatch(updateSysUsers(Data))

    }

    const [activeTab, setactiveTab] = useState("1")

    // Customers
    const [customerTableState, setCustomerTableState] = useState(true)
    const [owner, setOwner] = useState([])
    const [businessList, setBusinessList] = useState([])
    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const [trigger, setTrigger] = useState(false)

    useEffect(() => {
        setOwner([])
        if (id) {

        }
    }, [id, customerTableState, trigger])

    const customerColumns = useMemo(
        () => [
            {
                Header: 'Company Name',
                accessor: 'business_id',
                filterable: true,
                Cell: (cellProps) => {
                    return <CompanyName {...cellProps} />
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    const ownerData = cellProps.row.original
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    unLink(ownerData.owner_id);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ], []);

    const fetchAllbusinesses = () => {

    }

    const fetchPermittedBusiness = (businessList) => {
        let owners = owner
        if (owners) {
            for (let i in owners) {
                var index = businessList.findIndex(business => business.app_business_id === owners[i].business_id)
                businessList[index] = { ...businessList[index], access: true }
            }
        }
        setBusinessList(businessList)
    }

    const handleAddCustomer = () => {
        setCustomerTableState(false)
    }

    const updateBusinessAccess = (checked, businessID) => {
        if (checked) {
            setDisabledCheckbox(true)
            const token = sessionStorage.getItem('authUser')
            const newUserAcessData = {
                business_id: businessID,
                sys_user_id: id
            }

        }
    }

    const unLink = (id) => {
        const token = sessionStorage.getItem('authUser')

    }

    // const [usersID, setUserRoleID] = useState([])
    const [selectedMulti, setselectedMulti] = useState([]);
    register('sys_user_roles', { required: true });

    const [selectedRole, setSelectedRole] = useState([])
    const [accordionShow, setAccordionShow] = useState(false)

    const handlePostChange = (Data) => {
        let len = Data.length
        if (len > 0) {
            setAccordionShow(true)
        } else {
            setAccordionShow(false)
        }
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroup.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('sys_user_roles', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('sys_user_roles', postArray)
                }
            }
        });

        setSelectedRole(postArray)
        setselectedMulti({
            sys_role_id: postArray,
        });
    };

    const [optionGroup, setOptionGroup] = useState([{ options: [] }])

    useEffect(() => {
        setOptionGroup('')
        setselectedMulti([])
    }, [])

    const sysRolesID = (Items) => {
        let len = Items.length
        if (len > 0) {
            setAccordionShow(true)
        } else {
            setAccordionShow(false)
        }
        if (Items.length > 0) {
            let Data = Items.map((item) => {
                let role = roles.find(role => role.sys_role_id === item);
                if (role) {
                    return {
                        label: role.sys_role_name,
                        value: role.sys_role_id
                    };
                }
                return null;
            }).filter(Boolean);
            setSelectedRole(Data)
            setselectedMulti(Data);
            setValue("sys_user_roles", Data);
        }
    }

    useEffect(() => {
        if (roles) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in roles) {
                let data = {
                    label: roles[i].sys_role_name,
                    value: roles[i].sys_role_id
                }
                options.push(data)
            }
            setOptionGroup(options)
        }
    }, [roles])


    // status type value

    const [confirmStatus, setConfirmStatus] = useState()
    const statusChange = (e) => {
        setConfirmStatus(e.target.value)
        if (e.target.value === "active") {
            setStatusTBC(true)
            setStatusModal(true)
        } else if (e.target.value === "inactive") {
            setStatusTBC(false);
            setStatusModal(true)
        }
    }

    const [statusModal, setStatusModal] = useState(false)
    const [statusTBC, setStatusTBC] = useState()

    const handleStatus = () => {
        setValue("is_active", confirmStatus)
        setStatusModal(false)
    }

    const closeClick = () => {
        const value = getValues("is_active");
        if (value === "inactive") {
            let dta = "active"
            setValue("is_active", dta)
            setStatusModal(false)
            console.log("singleValue", dta);
        } else if (value === "active") {
            let dta = "inactive"
            setValue("is_active", dta)
            setStatusModal(false)
            console.log("singleValue", dta);
        }
    }

    // Manage Business Unit MultiSelect

    //    const [usersID, setUserRoleID] = useState([])
    const [selectedMultiBusUnit, setselectedMultiBusUnit] = useState([]);
    // register('sys_user_business_unit', { required: true });
    const [optionGroupBusUnit, setOptionGroupBusUnit] = useState([{ options: [] }])

    useEffect(() => {
        setOptionGroupBusUnit('')
        setselectedMultiBusUnit([])
    }, [])

    const sysBusUnitID = (Items) => {
        if (Items.length > 0) {
            let Data = Items.map((item) => {
                let role = businessUnitList.find(role => role.app_businessunit_id === item)
                if (role) {
                    return {
                        label: role.app_business_unit_name,
                        value: role.app_businessunit_id
                    }
                }
            }).filter(Boolean)
            setselectedMultiBusUnit(Data)
            setValue("sys_user_business_unit", Data)
        }
    }

    useEffect(() => {
        if (businessUnitList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in businessUnitList) {
                let data = {
                    label: businessUnitList[i].app_business_unit_name,
                    value: businessUnitList[i].app_businessunit_id
                }
                options.push(data)
            }
            setOptionGroupBusUnit(options)
        }
    }, [businessUnitList])

    const handlePostChangeBus = (Data) => {
        console.log(Data);
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupBusUnit.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('sys_user_business_unit', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('sys_user_business_unit', postArray)
                }
            }
        });

        setselectedMultiBusUnit({
            app_businessunit_id: postArray,
        });
    };

    // Get Permissions
    const [appBusinessUnit, setAppBusinessUnit] = useState(null)
    const [sysRoles, setSysRoles] = useState([])


    // Accordions Tab State
    const [col, setCol] = useState([])
    const [activeTabAccord, setactiveTabAccord] = useState("");



    useEffect(() => {
        if (sysRolesState.appSysRoles !== null && selectedRole) {
            //       console.log(selectedRole);
            let resValue = sysRolesState.appSysRoles.result
            let Data = resValue.filter(items => selectedRole.map(val => val.value).includes(items.sys_role_id));
            setSysRoles(Data)
        }
    }, [sysRolesState, selectedRole])

    const customStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: "#D2D4D1", // Change this to your desired background color
        }),
    };

    const [passwordState, setPasswordState] = useState(true)
    const eyeIcon = () => {
        setPasswordState(abc => !abc)
    }

    const peekPassCss = {
        border: "1px solid #CED4DA",
        borderLeft: "none",
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
        backgroundColor: "#E9ECF8"
    }

    const peekPassCssError = {
        border: "1px solid #F46A6A",
        borderLeft: "none",
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0"
    }




    return (
        <React.Fragment>
            <StatusModal
                show={statusModal}
                onDeleteClick={handleStatus}
                onCloseClick={closeClick}
                data={statusTBC}
            />
            <div className="page-content">
                <Breadcrumbs title="Update Portal User" breadcrumbItem="Portal User" />
                <div className="checkout-tabs mb-4">
                    <Row>
                        <Col xl="2" sm="3">
                            <Nav className="flex-column" pills>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === "1" })}
                                        onClick={() => {
                                            setactiveTab("1")
                                        }}>
                                        <i className="bx bx-buildings d-block check-nav-icon mt-4 mb-2" />
                                        <p className="font-weight-bold mb-4">User Details</p>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "2" })}
                                        onClick={() => {
                                            setactiveTab("2")
                                        }}
                                    >
                                        <i className="bx bx-buildings d-block check-nav-icon mt-4 mb-2" />
                                        <p className="font-weight-bold mb-4">Customers</p>
                                    </NavLink>
                                </NavItem> */}
                            </Nav>
                        </Col>
                        <Col xl="10" sm="9">
                            <Card>
                                <CardBody>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1"> <Container fluid>
                                            <Row>
                                                <Col lg="12">
                                                    <Form onSubmit={handleSubmit(updateUser)}>
                                                        {/* <Row>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <Label>User ID</Label>
                                                                        <input type="text" readOnly={true} className='form form-control' {...register('sys_user_id', { required: true })} />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row> */}
                                                        <Row>
                                                            <FormGroup row>
                                                                <Label lg="2" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Azure AD SSO</Label>
                                                                <Col lg="9" md="8">
                                                                    <div className="form-check form-switch form-switch-lg mb-3">
                                                                        <input type="checkbox" className="form-check-input" id="azureStatus" disabled  checked={azureSSO.state} {...register('azure_sso')} />
                                                                        <label className="form-check-label" htmlFor="azureStatus">
                                                                            {azureSSO.name}
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Row>
                                                        <Row>
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User ID<span style={{ color: "red" }}>*</span></Label>
                                                                <Col lg="5" md="6">
                                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" autoComplete='off' className='form form-control' {...register('sys_user_keyid', { required: true })} />
                                                                    {errors.sys_user_keyid && <p style={{ color: "red" }}>This Field is required</p>}
                                                                </Col>
                                                            </FormGroup>
                                                        </Row>
                                                        <Row>
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User Name</Label>
                                                                <Col lg="5" md="6">
                                                                    <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} type="text" autoComplete='off' className='form form-control' {...register('sys_user_name', { required: true })} />
                                                                    {errors.sys_user_name && <p style={{ color: "red" }}>This Field is required</p>}
                                                                </Col>
                                                            </FormGroup>
                                                        </Row>
                                                        <Row>
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User email<span style={{ color: "red" }}>*</span></Label>
                                                                <Col lg="5" md="6">
                                                                    <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} type="email" autoComplete='off' className='form form-control' {...register('sys_user_email', { required: true })} />
                                                                    {errors.sys_user_email && <p style={{ color: "red" }}>This Field is required</p>}
                                                                </Col>
                                                            </FormGroup>
                                                        </Row>
                                                        {/* <Row>
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Password<span style={{ color: "red" }}>*</span></Label>
                                                                <Col lg="5" md="6">
                                                                    <div className="input-group ">
                                                                        <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} type={passwordState ? 'password' : 'text'} className="form-control" {...register('sys_user_password', { required: true })} placeholder="Password" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                                        <div onClick={eyeIcon} style={{ cursor: "pointer" }} className="input-group-append">
                                                                            <span style={{ border: "1px solid #CED4DA" }} className="input-group-text btn" id="basic-addon2">{passwordState ? <i className='bx bxs-hide' ></i> : <i className='bx bxs-show'></i>}</span>
                                                                        </div>
                                                                    </div>
                                                                    {errors.sys_user_password && <p style={{ color: "red" }}>This Field is required</p>}
                                                                </Col>
                                                            </FormGroup>
                                                        </Row> */}
                                                        {/* <Row>
                                                                <FormGroup row>
                                                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Role<span style={{ color: "red" }}>*</span></Label>
                                                                    <Col lg="5" md="6">
                                                                        <select defaultValue="" className="form-select d-inline" aria-label="Default select example"  {...register('sys_user_roles', { required: true })}>
                                                                            <option disabled hidden value="">Role</option>
                                                                            {roles.map((role, i) => (
                                                                                <option value={role.sys_role_id} key={i}>{role.sys_role_name}</option>
                                                                            ))}
                                                                        </select>
                                                                        {errors.sys_user_roles && <p style={{ color: "red" }}>This Field is required</p>}                                                                    </Col>
                                                                </FormGroup>
                                                            </Row> */}
                                                        {/* <Row>
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                                    Business Unit
                                                                </Label>
                                                                <Col lg="5" md="6">
                                                                    <Select
                                                                        value={selectedMultiBusUnit.length > 0 ? selectedMultiBusUnit : selectedMultiBusUnit.app_businessunit_id}
                                                                        isMulti={true}
                                                                        onChange={handlePostChangeBus}
                                                                        options={optionGroupBusUnit}
                                                                        name={"app_businessunit_id"}
                                                                        className="modal__input"
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        </Row> */}

                                                        <Row>
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                                    Roles <span style={{ color: "red" }}>*</span>
                                                                </Label>
                                                                <Col lg="5" md="6">

                                                                    {UpadatePermission ?
                                                                        <Select
                                                                            isDisabled
                                                                            styles={customStyles}
                                                                            value={selectedMulti.length > 0 ? selectedMulti : selectedMulti.sys_role_id}
                                                                            isMulti={true}
                                                                            onChange={handlePostChange}
                                                                            options={optionGroup}
                                                                            name={"sys_role_id"}
                                                                            className="modal__input"
                                                                        /> :
                                                                        <Select
                                                                            value={selectedMulti.length > 0 ? selectedMulti : selectedMulti.sys_role_id}
                                                                            isMulti={true}
                                                                            onChange={handlePostChange}
                                                                            options={optionGroup}
                                                                            name={"sys_role_id"}
                                                                            className="modal__input"
                                                                        />}

                                                                </Col>
                                                            </FormGroup>
                                                        </Row>

                                                        {/* {accordionShow && <Row>
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "></Label>
                                                                <Col lg="5" md="6">
                                                                    <div className="accordion" id="accordion">
                                                                        {sysRoles.map((permission, index) => {
                                                                            const toggleAccordionCollapse = (index) => {
                                                                                let colItems = [...col]
                                                                                if (colItems[index] === null || colItems[index] === undefined || colItems[index] === false) {
                                                                                    for (let i in colItems) {
                                                                                        colItems[i] = false
                                                                                    }
                                                                                    colItems[index] = true
                                                                                    setactiveTabAccord(index + '-' + "0")
                                                                                } else {
                                                                                    colItems[index] = false
                                                                                }
                                                                                setCol(colItems)
                                                                            }

                                                                            return (
                                                                                <div key={index} className="accordion-item">
                                                                                    <h2 className="accordion-header" id="headingTwo">
                                                                                        <button
                                                                                            className={classnames(
                                                                                                "accordion-button",
                                                                                                "fw-medium",
                                                                                                { collapsed: !col[index] }
                                                                                            )}
                                                                                            type="button"
                                                                                            onClick={() => toggleAccordionCollapse(index)}
                                                                                            style={{ cursor: "pointer" }}
                                                                                        >
                                                                                            {permission.sys_role_name}
                                                                                        </button>
                                                                                    </h2>
                                                                                    <Collapse isOpen={col[index] ? col[index] : false} className="accordion-collapse">
                                                                                        <div className="accordion-body">
                                                                                            <div className="text-muted">
                                                                                                <TabContent activeTab={activeTabAccord} className="p-3 text-muted">
                                                                                                    {appBusinessUnit.map((submodules, subIndex) => {
                                                                                                        return (
                                                                                                            <TabPane key={subIndex} tabId={index + '-' + subIndex}>
                                                                                                                <Row>
                                                                                                                    <Col sm="12">
                                                                                                                        <div className="mb-0">
                                                                                                                            <Table striped hover>
                                                                                                                                <thead>
                                                                                                                                    <tr>
                                                                                                                                        <th>#</th>
                                                                                                                                        <th>Business Units </th>
                                                                                                                                        <th>Read</th>
                                                                                                                                        <th>Write</th>
                                                                                                                                    </tr>
                                                                                                                                </thead>
                                                                                                                                {appBusinessUnit.map((funct, functIndex) => {
                                                                                                                                    return (
                                                                                                                                        <tbody key={functIndex}>
                                                                                                                                            <tr>
                                                                                                                                                <td>{functIndex + 1}</td>
                                                                                                                                                <td>{funct.app_business_unit_name}</td>
                                                                                                                                                <td><input type="checkbox" {...register(`user.sys_user_business_unit.${funct.app_businessunit_id}.read`)} /></td>
                                                                                                                                                <td><input type="checkbox" {...register(`user.sys_user_business_unit.${funct.app_businessunit_id}.write`)} /></td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>)
                                                                                                                                })}
                                                                                                                            </Table>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </TabPane>)
                                                                                                    })}
                                                                                                </TabContent>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Collapse>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Row>
                                                        } */}

                                                        {/* <Row>
                                                                <FormGroup row>
                                                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Business Unit <span style={{ color: "red" }}>*</span></Label>
                                                                    <Col lg="5" md="6">
                                                                        <select defaultValue="" className="form-select d-inline" aria-label="Default select example"  {...register('sys_user_business_unit', { required: true })}>
                                                                            <option disabled hidden value="">Business Unit</option>
                                                                            {businessUnitList.map((businessunit) => (
                                                                                <option value={businessunit.app_businessunit_id} key={businessunit.app_businessunit_id}>{businessunit.app_business_unit_name}</option>
                                                                            ))}
                                                                        </select>
                                                                        {errors.sys_user_business_unit && <p style={{ color: "red" }}>This Field is required</p>}                                                                 </Col>
                                                                </FormGroup>
                                                            </Row> */}
                                                        {/* <Row>
                                                                <Col lg="4">
                                                                    <FormGroup>
                                                                        <div className="mb-3">
                                                                            <label className="control-label">
                                                                                Department
                                                                            </label>
                                                                            <Select
                                                                                value={selectedMulti}
                                                                                isMulti={true}
                                                                                onChange={(optionGroup) => {
                                                                                    handleMulti(optionGroup);
                                                                                }}
                                                                                options={optionGroup}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                            {errors.sys_user_department && <p style={{ color: "red" }}>This Field is required</p>}
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row> */}
                                                        <Row>
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                                                <Col lg="5" md="6">
                                                                    <select style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={DeletePermission} onInput={(e) => { statusChange(e) }} className="form-select d-inline" {...register('is_active')}>
                                                                        <option hidden value="">Select Status</option>
                                                                        <option value="active">ACTIVE</option>
                                                                        <option value="inactive">INACTIVE</option>
                                                                    </select>
                                                                </Col>
                                                            </FormGroup>
                                                        </Row>
                                                        {/* <Row>
                                                                <FormGroup row>
                                                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                                                    <Col lg="5" md="6">
                                                                        <div className="form-check form-switch form-switch-lg mb-3">
                                                                            <input type="checkbox" className="form-check-input" id="roleStatus" onInput={statusChange} {...register('is_active')} />
                                                                            <label className="form-check-label" htmlFor="roleStatus">
                                                                                {status.name}
                                                                            </label>
                                                                        </div>
                                                                    </Col>
                                                                </FormGroup>
                                                            </Row> */}
                                                        <Row className="mt-4">
                                                            <FormGroup row>
                                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                                                <Col lg="5" md="6">
                                                                    <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                                                                        Back
                                                                    </Button>
                                                                    {!UpadatePermission && <Button color='success' type='submit' className='m-2'>
                                                                        Update
                                                                    </Button>}
                                                                </Col>
                                                            </FormGroup>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Container>
                                        </TabPane>
                                        {/* <TabPane
                                            tabId="2"
                                            id="v-pills-payment"
                                            role="tabpanel"
                                            aria-labelledby="v-pills-payment-tab">
                                            <Container fluid>
                                                {customerTableState ?
                                                    <TableContainer
                                                        columns={customerColumns}
                                                        data={owner}
                                                        isGlobalFilter={true}
                                                        isLinkCustomer={true}
                                                        customPageSize={10}
                                                        handleAddCustomer={handleAddCustomer}
                                                        className="custom-header-css" />
                                                    :
                                                    <div>
                                                        <h4 id="zzz" style={{ textDecoration: "underline" }}>Link Company</h4>
                                                        <Row className="mt-4 d-flex justify-content-center">
                                                            <Col lg="6">
                                                                <Table striped bordered>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Name</th>
                                                                            <th className="text-center">Access</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {businessList.map((business, i) => (
                                                                            <tr key={i}>
                                                                                <th scope="row">{i + 1}</th>
                                                                                <td>{business.app_business_name}</td>
                                                                                <td className="text-center">
                                                                                    <input type="checkbox"
                                                                                        checked={business.access ? true : false}
                                                                                        disabled={disabledCheckbox || (business.access ? true : false)}
                                                                                        onChange={() => { }}
                                                                                        onClick={(e) => updateBusinessAccess(business.access ? false : true, business.app_business_id)} />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <div className="text-end">
                                                                <Button className="mt-2" onClick={() => setCustomerTableState(true)} color="danger">Close</Button>
                                                            </div>
                                                        </Row>
                                                    </div>}
                                            </Container>
                                        </TabPane> */}
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </div>
        </React.Fragment>
    )
}

export default UpdateUser