export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS"
export const GET_DEPARTMENTS_FAIL = "GET_DEPARTMENTS_FAIL"

export const POST_DEPARTMENT = "POST_DEPARTMENT"
export const POST_DEPARTMENT_SUCCESS = "POST_DEPARTMENT_SUCCESS"
export const POST_DEPARTMENT_FAIL = "POST_DEPARTMENT_FAIL"

export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT"
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS"
export const DELETE_DEPARTMENT_FAIL = "DELETE_DEPARTMENT_FAIL"

export const GET_DEPARTMENT = "GET_DEPARTMENT"
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS"
export const GET_DEPARTMENT_FAIL = "GET_DEPARTMENT_FAIL"


export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT"
export const GET_ALL_DEPARTMENT_SUCCESS = "GET_ALL_DEPARTMENT_SUCCESS"
export const GET_ALL_DEPARTMENT_FAIL = "GET_ALL_DEPARTMENT_FAIL"

export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT"
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS"
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL"