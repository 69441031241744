import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, FormGroup, Label } from "reactstrap";
import { Filter, DefaultColumnFilter, SelectColumnFilter } from "./filters";
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import CompanySelect from "./CompanySelect";
import CompanySelectCredit from "./CompanySelectCredit";
import SiteSelectCredit from "./SiteSelectCredit";
import CompanySelectInvoiceList from "./CompanySelectInvoiceList";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);
    return (
        <Col sm={3}>
            <div className="search-box mb-1 d-inline-block search-pad">
                <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">
                            Search this table
                        </span>
                        <input
                            onChange={e => {
                                setValue(e.target.value);
                                onChange(e.target.value);
                            }}
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`${count} records...`}
                            value={value || ""}
                            size="50"
                        />
                    </label>
                    <i className="bx bx-search-alt search-icon"></i>
                </div>
            </div>
        </Col>
    );
}

let debounceTimer

const TableContainer2 = ({

    columns, data, isGlobalFilter, customPageSize, pageSizeTransfer, className, handleUserClick, handleCompanyClick,
    customPageSizeOptions, customGlobalFilterDataTransfer, customPageStatus, customPageCount, handlePageClick,
    customPageIndex, isActivityFilter, isStatusDropDown, handleProductClick, pageFilterDataTransfer,
    isCompanyDropdown, isCompanyDropdownforInvoiceList, isCompanyDropdownWithSite, isSiteDropdown, isAddTransaction,

    handleOrderClicks, handleCustomerClick, handleAddContact, handleAddSite, handleAddAddress, handleAddUser,
    handleAddCustomer, accountID, handleAddtier, accountId,

    isAddUserList, isRolesList, isDepartmentList, isCustomerRoleManagementList, isContractsList, isBusinessUnitsList,
    isBusinessUnitDivision, isAddCustList, isAddCompList, isAddCustomerUserList, isAddContact, isAddSite,
    isAddAddress, isLinkUser, isLinkCustomer, isProductsList, isAddAccountList, isAddPricingModels, isAddTier,
    isAddOrder, isAddOpportunities, isAddCredit, isAddCreditAccount, isAddOptions, handleAddCompList, isViewAllOrder,
    isAddCustomerSites, isAddSalesContact, isAddSalesAddress, isAddReturns


}) => {

    const {
        getTableProps, getTableBodyProps, headerGroups, page, prepareRow, canPreviousPage, canNextPage,
        pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state, preGlobalFilteredRows, setGlobalFilter,
        state: { pageIndex, pageSize, },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: customPageIndex ? customPageIndex : 0,
                pageSize: customPageSize ? customPageSize : 10,
                sortBy: [
                    // {
                    //   desc: true,
                    // },
                ],
            },
            manualPagination: true,
            pageCount: customPageCount ? customPageCount : null,
            // pageStatus:true
        },

        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    //===================================================================//
    const tableTotal = page.map(item => {
        const { values } = item;
        return { values };
    });

    let totalRow = 0;
    let totalQuantity = 0;
    let grandTotal = 0;
    let totalGrandquantities = 0;

    //==============================subtotal ===========================//
    if (tableTotal.length > 0) {
        totalRow = tableTotal.reduce((acc, curr) => {

            if (isNaN(curr.values.rowTotal)) return acc;

            if (curr.values.rowTotal === undefined) return acc;

            return acc + parseFloat(curr.values.rowTotal);
        }, 0).toFixed(1);
    } else {
        totalRow = "0"
    }

    if (tableTotal.length > 0) {
        totalQuantity = tableTotal.reduce((acc, curr) => {
            if (isNaN(curr.values.quantity)) return acc;
            if (curr.values.quantity === undefined) return acc;
            return acc + parseFloat(curr.values.quantity);

        }, 0);
    } else {
        totalQuantity = "0";
    }

    //=================================grand total ===================================//
    if (tableTotal.length > 0) {
        grandTotal = tableTotal.reduce((acc, curr) => {

            if (isNaN(curr.values.grandTotals)) return acc;

            if (curr.values.grandTotals === undefined) return acc;

            return acc + parseFloat(curr.values.grandTotals);
        }, 0).toFixed(1);
    } else {
        grandTotal = 0;
    }

    if (tableTotal.length > 0) {
        totalGrandquantities = tableTotal.reduce((acc, curr) => {
            if (isNaN(curr.values.quantities)) return acc;
            if (curr.values.quantities === undefined) return acc;
            return acc + parseFloat(curr.values.quantities);

        }, 0);
    } else {
        totalGrandquantities = 0;
    }


    const history = useHistory()
    const location = useLocation()

    const query = useQuery();
    const skip = query.get("skip")
    const limit = query.get("limit")
    const querystatus = query.get("status")
    const queryMultistatus = query.get("queryMultistatus")
    const Busid = query.get("Busid")
    const tab = query.get("tab")
    const SiteId = query.get("SiteId")
    const opportunityQueryStatus = query.get("opportunityStatus")

    // useEffect(() => {
    //   console.log("pageIndex", pageIndex);
    //   handlePageClick(pageIndex);
    // }, [pageIndex])

    // const currentURL = window.location.href // returns the absolute URL of a page

    // const pathname = window.location.pathname


    const pathname = location.pathname

    const [pageStatus, setPageStatus] = useState(true)
    const [pageMultiStatus, setPageMultiStatus] = useState("all")
    const [opportunityPageStatus, setOpportunityPageStatus] = useState("all")

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
    };


    //======================= Active inActive ================
    const onChangeStatus = event => {
        // customPageStatus(event.target.value)
        setPageStatus(event.target.value)
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&status=${event.target.value}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}&&Busid=${Busid}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/addresses") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&status=${event.target.value}&&Busid=${Busid}`)
        }
        else {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}`)
        }
    };


    //  Pending Approved Reject 
    const onChangeMultiStatus = (event) => {
        setPageMultiStatus(event.target.value)
        if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}&&Busid=${Busid}`)
        }  else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}`)
        }else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}&&Busid=${Busid}`)
        }
    }


    //  open proposal close 
    const onChangeOpportunityMultiStatus = (event) => {
        setOpportunityPageStatus(event.target.value)
        if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&opportunityStatus=${event.target.value}`)
        } 
    }

    //   pagelimit 
    const onChangeInSelect = event => {
        // pageSizeTransfer(Number(event.target.value));
        setPageSize(Number(event.target.value));
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}&&Busid=${Busid}`)
        }
        else {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}`)
        }
    };


    //  page number inputbox 
    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        // gotoPage(page);
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${page}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        }else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&Busid=${Busid}`)

        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${page}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${page}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${page}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        }
        else {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}`)
        }
    };

    const prevPage = () => {
        // previousPage()
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        }

        else {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}`)
        }
    }

    const nxtPage = () => {
        // nextPage()
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        }
        else {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}`)
        }
    }

    const fistPage = () => {
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${(0)}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${(0)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${(0)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        }
        else {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&status=${pageStatus}`)
        }
    }

    const lastPage = () => {
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany") {
            history.push(`${pathname}?tab=${tab}&&sskip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        }
        else {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}`)
        }
    }

    const toCreate = () => {
        if (isAddCompList) {
            handleAddCompList()
        } else if (isRolesList) {
            history.push("/createRole")
        } else if (isAddUserList) {
            history.push("/createUser")
        } else if (isDepartmentList) {
            history.push("/createDepartment")
        } else if (isCustomerRoleManagementList) {
            history.push("/createCustomerRole")
        } else if (isAddCustomerUserList) {
            history.push('/createCustomerUser')
        } else if (isContractsList) {
            history.push('/createContract')
        } else if (isBusinessUnitsList) {
            history.push('/createBusinessUnit')
        } else if (isBusinessUnitDivision) {
            history.push('/createBusinessUnitDivision')
        } else if (isProductsList) {
            history.push('/createProducts')
        } else if (isAddAccountList) {
            history.push('/createAccount')
        } else if (isAddPricingModels) {
            history.push('/createPricingModels')
        } else if (isAddOrder) {
            history.push('/createOrders')
        } else if (isAddOpportunities) {
            history.push('/createOpportunities')
        } else if (isAddCredit) {
            history.push('/createAccounts')
        } else if (isAddCreditAccount) {
            history.push('/#')
        } else if (isAddCustomerSites) {
            history.push('/createCustomerSites')
        } else if (isAddSalesContact) {
            history.push('/createContact')
        } else if (isAddSalesAddress) {
            history.push('/createAddress')
        } else if (isViewAllOrder) {
            history.push('/orders')
        } else if (isAddReturns) {
            history.push('/createReturns')
        } if (isAddTransaction) {
            history.push(`/createTransaction?AccId=${accountId.AccId}`)
        }
    }

    const [activityFilter, setActivityFilter] = useState({
        app_activity_keyid: "", action: "", module: "", created_on: "", username: "", reference: ""
    })

    {
        isActivityFilter && useEffect(() => {
            pageFilterDataTransfer(activityFilter)
        }, [activityFilter])
    }

    const customsFilteredValue = (id, e) => {
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => {
            setActivityFilter(prevState => ({ ...prevState, [id]: e.target.value }))
        }, 1500);

        // pageFilterDataTransfer({ ...activityFilter, [id]: e.target.value })
    }

    return (
        <Fragment>
            <Row className="mb-2 mt-2">
         
              

            

         

                {location.pathname !== '/createPricingModels' &&
                    (location.pathname.substring(0, 20) !== "/updatePricingModels") &&
                    (location.pathname.substring(0, 18) !== "/viewPricingModels") && isGlobalFilter && (
                        // <GlobalFilter
                        //     preGlobalFilteredRows={preGlobalFilteredRows}
                        //     globalFilter={state.globalFilter}
                        //     setGlobalFilter={setGlobalFilter}
                        // />

                        <Col sm={3}>
                            <div className="search-box mb-1 d-inline-block search-pad">
                                <div className="position-relative">
                                    <label htmlFor="search-bar-0" className="search-label">
                                        <span id="search-bar-0-label" className="sr-only">
                                            Search this table
                                        </span>
                                        <input
                                            onChange={e => { customGlobalFilterDataTransfer(e.target.value) }}
                                            id="search-bar-0"
                                            type="text"
                                            className="form-control"
                                            placeholder={`Search records...`}
                                            size="50"
                                        />
                                    </label>
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                    )}

                <>
                    {isAddTransaction && (
                        <Col sm="12">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus " />
                                    Add Transaction
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddSalesAddress && (
                        <Col sm="12">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus " />
                                    Add Address
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddSalesContact && (
                        <Col sm="12">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus " />
                                    Add Contact
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCustomerSites && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Customer Sites
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddTier && (
                        <Col sm="12">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleAddtier}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Tier
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddPricingModels && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Pricing Models
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddOptions && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Add New Order
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddUserList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Portal User
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddAccountList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Account
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isProductsList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                // onClick={toCreate}
                                >
                                    {/* <i className="mdi mdi-plus me-1" /> */}
                                    Sync Product
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCustomerUserList && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create New User
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCustList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleCustomerClick}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Customers
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddContact && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddContact}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Contact
                                </Button>
                            </div>
                        </Col>
                    )}

                    {isAddSite && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddSite}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Site
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isRolesList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Roles
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isLinkCustomer && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddCustomer}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Link Customer
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isLinkUser && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddUser}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Link User
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isDepartmentList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                // onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Sites
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isCustomerRoleManagementList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Customer Role
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isContractsList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Contracts
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isBusinessUnitsList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Business Unit
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isBusinessUnitDivision && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Business Unit Division
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddAddress && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddAddress}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Address
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCompList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create New Customer
                                </Button>
                            </div>
                        </Col>
                    )}

                    {isViewAllOrder && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className=" mb-2 "
                                    onClick={toCreate}
                                >
                                    View All Orders List
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddOrder && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Order
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddOpportunities && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Opportunities
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCredit && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Transaction
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddReturns && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Returns
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCreditAccount && (
                        <Col sm="7">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Account
                                </Button>
                            </div>
                        </Col>
                    )}
                </>

            </Row>

            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th key={column.id}>
                                        {isActivityFilter && <Row>
                                            <FormGroup row className="d-flex justify-content-around">
                                                <Col lg="12" md="12">
                                                    <Label className="d-flex justify-content-lg-start justify-content-md-start">
                                                        {column.render('Header')}
                                                    </Label>
                                                    <input
                                                        placeholder={`Search ...`}
                                                        type="text"
                                                        className="form form-control"
                                                        onChange={(e) => customsFilteredValue(column.id, e)}
                                                    // {...column.getFilterProps()} 
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>}
                                        {!isActivityFilter && <div className="" {...column.getSortByToggleProps()}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr>
                                        {row.cells.map(cell => {
                                            return (
                                                <td key={cell.id} {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </Fragment>
                            );
                        })}

                    </tbody>

                    {((location.pathname === '/createOpportunities' || location.pathname.substring(0, 18) === '/viewOpportunities') || 
                    (location.pathname.substring(0, 12) === '/viewinvoice') || (location.pathname.substring(0, 24) === '/custompricingModels')) && (
                        <tbody>
                            <tr>

                                {totalQuantity !== 0 && totalRow !== 0 &&
                                    <>
                                        <td colSpan={headerGroups[0].headers.length - 2}
                                            style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>Sub Total</td>

                                        <td style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>
                                            <span>{totalQuantity}</span>
                                        </td>

                                        <td style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>
                                            <span>$ {totalRow}</span>
                                        </td>
                                    </>
                                }

                                {/* {grandTotal !== 0 && totalGrandquantities !== 0 &&
                                    <>
                                        <td colSpan={headerGroups[0].headers.length - 3}
                                            style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>Grand Total</td>

                                        <td style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>
                                            <span>{totalGrandquantities}</span>
                                        </td>

                                        <td style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>
                                            <span>$ {grandTotal}</span>
                                        </td>

                                    </>
                                } */}

                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>

            {/* {((location.pathname === '/createOpportunities' || location.pathname.substring(0, 18) === '/viewOpportunities') && (
                        < tr >
                            <td colSpan={headerGroups[0].headers.length}>


                                <h4 className="d-flex justify-content-lg-end justify-content-md-end mt-2">
                            
                               
                                    {totalQuantity !== 0 && totalRow !== 0 &&
                                        <span>{"QTY" + " : " + totalQuantity + " | " + "SubTotal" + " : " + " $" + totalRow}</span>
                                    }
                                     {grandTotal !== 0 && totalGrandquantities !== 0 &&
                                        <span >{"Grand QTY " + " : " + totalGrandquantities + " | " + "Grand Total" + ": " + " $ " + grandTotal}</span>
                                    }

                                </h4> 

                            </td>
                        </tr>
                    ))}  */}


            {/* <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>

                    {isActivityFilter && <Filter column={column} />} 
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div> */}

       
        </Fragment>
    );
};

TableContainer2.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer2;
