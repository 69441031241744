import React, { useEffect } from 'react';

const ProductID = (cell) => {
    return cell.value ? cell.value + " " + " | " + cell.datas.app_product_local_name : "";
};

const ProductName = (cell) => {
    return cell.value ? cell.value : '';
};

const PosItemCode = (cell) => {
    return cell.value ? cell.value : '';
};

const ProductType = (cell) => {
    // if (cell.value === "D") {
    //     return cell.value ? "Digital" : '';
    // } else {
    //     return cell.value ? "Print" : '';
    // }

    return cell.value ? cell.value : '';
};

const OriginalPrice = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1);
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " + floatValue : ''} </span>);
};


let timeout


const CustomQuantity = (cell) => {

    const handleQty = cell.handleQty
    const ID = cell.row.original?.app_product_id

    const debounce = (id, price, value) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            handleQty(id, price, value)
        }, 800);
    }

    useEffect(() => {
        $(`#inputQty-${ID}`)?.val(cell.value)
    }, [])

    return (
        <input className='form form-control' id={`inputQty-${ID}`} readOnly
            style={{ width: "100px", textAlign: "right", marginTop: "-5px" }} type='number' min={"0"}
            onChange={(e) => {
                debounce(ID, cell.row.original?.unit_price, e.target.value)
            }}
        />)
};

const CustomUnitPrice = (cell) => {

    const handleUnitPrice = cell.handleUnitPrice
    const ID = cell.row.original?.app_product_id

    const debounce = (id, price, value, qty) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            handleUnitPrice(id, price, value, qty)
        }, 800);
    }

    useEffect(() => {
        $(`#inputUnitPrice-${ID}`)?.val(cell.value)
    }, [])

    return (
        <input className='form form-control' id={`inputUnitPrice-${ID}`} readOnly 
            style={{ width: "100px", textAlign: "right", marginTop: "-5px", outline: "none" }} type='number' step={"any"} min={"0"}
            onChange={(e) => {
                debounce(ID, e.target.value, cell.row.original?.quantity)
            }}
        />)
};


const RowTotal = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    // if (isNaN(floatValue)) return
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " + " " + floatValue : ''} </span>);
};



export {
    ProductID, ProductName, ProductType, PosItemCode, OriginalPrice, CustomQuantity, CustomUnitPrice, RowTotal
};
