import React, { createContext, useMemo } from "react";
import { Card, CardBody, Container, Row, Col, FormGroup, Label } from "reactstrap";
import BreadcrumbForActivity from "components/Common/BreadcrumbForActivity";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import getHeader from "config/header";
import ActivityModal from "components/Activity/ActivityModal";
import { ActivityID, ActivityType, Module, Reference, UpdatedDate, UpdatedBy } from './ActivityListCol'
import TableContainer from "components/Common/TableContainer";
import { getActivitiesList } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import useDidMountEffect from "components/Common/useDidMountEffect";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SpinnerModal from "components/Common/SpinnerModal";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Activity = () => {

    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const pageStatus = query.get("status")

    const [filterKeys, setFilterKeys] = useState("")
    const [filterValues, setFilterValues] = useState()
    const [activities, setActivities] = useState([])
    const [pageCount, setPageCount] = useState(1);

    const dispatch = useDispatch()

    const activitiesState = useSelector(state => state.ActivitiesReducer)

    // useDidMountEffect(() => {
    //     // react please run me if 'key' changes, but not on initial render
    //     handlePageClick()
    // }, [pageLimit]);


    useEffect(() => {
        if (activitiesState.activities !== null) {
            setActivities(activitiesState.activities.result);
            setPageCount(Math.ceil(activitiesState.activities.summary.filteredsize / pageLimit) == 0 ?  1
                :
                Math.ceil(activitiesState.activities.summary.filteredsize / pageLimit));
        }
    }, [activitiesState])

    useEffect(() => {
        const data = {
            ...(filterKeys.action !== null && filterKeys.action !== undefined && filterKeys.action !== '') ? { action: filterKeys.action } : {},
            ...(filterKeys.app_activity_keyid !== null && filterKeys.app_activity_keyid !== undefined && filterKeys.app_activity_keyid !== '') ? { app_activity_keyid: filterKeys.app_activity_keyid } : {},
            ...(filterKeys.module !== null && filterKeys.module !== undefined && filterKeys.module !== '') ? { module: filterKeys.module } : {},
            ...(filterKeys.reference !== null && filterKeys.reference !== undefined && filterKeys.reference !== '') ? { reference: filterKeys.reference } : {},
            ...(filterKeys.created_on !== null && filterKeys.created_on !== undefined && filterKeys.created_on !== '') ? { created_on: filterKeys.created_on } : {},
            ...(filterKeys.user_name !== null && filterKeys.user_name !== undefined && filterKeys.user_name !== '') ? { user_name: filterKeys.user_name } : {},
        };
        setFilterValues(data)
    }, [filterKeys])

    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, filterValues])

    const handlePageClick = (index) => {
        let skips = 0;
        if (page > 0) {
            skips = page * pageLimit;
            dispatch(getActivitiesList({ limit: pageLimit, skip: skips, filter: filterValues }));
        }
        else {
            dispatch(getActivitiesList({ limit: pageLimit, skip: 0, filter: filterValues }));
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Activity ID',
                accessor: 'app_activity_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <ActivityID {...cellProps} />;
                }
            },
            {
                Header: 'Activity Type',
                accessor: 'action',
                filterable: true,
                Cell: (cellProps) => {
                    return <ActivityType {...cellProps} />;
                }
            },
            {
                Header: 'Module',
                accessor: 'module',
                filterable: true,
                Cell: (cellProps) => {
                    return <Module {...cellProps} />;
                }
            },
            {
                Header: 'Reference',
                accessor: 'reference',
                filterable: true,
                Cell: (cellProps) => {
                    return <Reference {...cellProps} />;
                }
            },
            {
                Header: 'Updated Date',
                accessor: 'created_on',
                filterable: true,
                Cell: (cellProps) => {
                    return <UpdatedDate {...cellProps} />;
                }
            },
            {
                Header: 'Updated By',
                accessor: 'user_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <UpdatedBy {...cellProps} />;
                }
            },
        ], [activities]
    );


    // Activity Modal
    const [activityModal, setActivityModal] = useState(false)
    const [activity, setActivity] = useState('')

    return (
        <React.Fragment>
            <ActivityModal
                show={activityModal}
                onCloseClick={() => setActivityModal(false)}
                data={activity}
            />
            {activitiesState.loading && <SpinnerModal
                show={true}
            />}
            <div className="page-content">
                <BreadcrumbForActivity title="Activity" breadcrumbItem="Settings" />
                <Container fluid>
                    <Row>
                        <Card>
                            <CardBody>
                                {true ?
                                    <TableContainer
                                        columns={columns}
                                        data={activities}
                                        isActivityFilter={true}
                                        customPageCount={pageCount}
                                        className="custom-header-css"
                                        pageFilterDataTransfer={(data) => setFilterKeys(data)}
                                    />
                                    :
                                    <>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bx-chalkboard'></i></h1>
                                                <h3>No Activity yet</h3>
                                                <div className="text-muted">When you make changes to your site, You'll be able to see your activity history here</div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default Activity;