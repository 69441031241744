import axios from 'axios'
import getHeader from 'config/header'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, Row, Table } from "reactstrap"
import { getBusinessProducts } from 'store/actions'

const AccountDetailModal = ({ show, onCloseClick, onDeleteClick, departmentDetails }) => {


    const dispatch = useDispatch();
    const { handleSubmit, register, getValues, unregister, setValue, formState: { errors }, reset } = useForm()

    // const [products, setProducts] = useState([])
    // const businessProducts = useSelector(state => state.BusinessProducts);

    // useEffect(() => {
    //     dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: "", skip: 0 }));
    // }, [])

    // useEffect(() => {
    //     if (businessProducts.products !== null) {
    //         setProducts(businessProducts.products.result);
    //     }
    // }, [businessProducts])

    const selectedBusinessUnits = (data) => {
        onDataSelect(data);
        reset()
    };

    const [accountDetails, setAccountDetails] = useState()
    const [accountStatus, setAccountStatus] = useState()

    useEffect(() => {
        if (departmentDetails !== undefined) {

            setAccountDetails(departmentDetails)

            if (departmentDetails.is_active === true) {
                setAccountStatus("Active")
            } else if (departmentDetails.is_active === false) {
                setAccountStatus("Inactive")
            }




            // console.log("qww",departmentDetails);
     
            // if (departmentDetails.oracle_credit_status === true) {
            //     setValue("oracle_credit_status", "active")
            // } else if (departmentDetails.oracle_credit_status === false) {
            //     setValue("oracle_credit_status", "inactive")
            // }
            // if (departmentDetails.account_status === true) {
            //     setValue("account_status", "active")
            // } else if (departmentDetails.account_status === false) {
            //     setValue("account_status", "inactive")
            // }
        }
    }, [departmentDetails])


    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true} size='lg'>
            <Form onSubmit={handleSubmit(selectedBusinessUnits)}>
                <ModalBody className="py-5 px-2">
                    <div className="mt-2 mb-2">
                        <h3 className='d-flex justify-content-center mb-4'>Account Details</h3>
                         {/* <Row>
                            <FormGroup row>
                                <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Account ID </Label>
                                <Col lg="8" md="6">
                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly className='form form-control'  {...register("app_account_keyid")} />
                                    {errors.app_account_keyid && <p style={{ color: 'red' }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup row>
                                <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Credit Limit  </Label>
                                <Col lg="8" md="6">
                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly className='form form-control' {...register("credit_limit")} />
                                    {errors.credit_limit && <p style={{ color: 'red' }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                       
                        <Row>
                            <FormGroup row>
                                <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Oracle Site ID </Label>
                                <Col lg="8" md="6">
                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly className='form form-control'{...register("oracle_site_ID")} />
                                    {errors.oracle_site_ID && <p style={{ color: 'red' }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup row>
                                <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end ">Oracle Credit Status </Label>
                                <Col lg="8" md="6">
                                    <select defaultValue={"inactive"} style={{ backgroundColor: "#D2D4D1" }} disabled className="form-select d-inline" {...register('oracle_credit_status')}>
                                        <option value="inactive">INACTIVE</option>
                                        <option value="active">ACTIVE</option>
                                    </select>
                                    {errors.oracle_credit_status && <p style={{ color: 'red' }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup row>
                                <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Account Status</Label>
                                <Col lg="8" md="6">
                                    <select style={{ backgroundColor: "#D2D4D1" }} disabled className="form-select d-inline" {...register('account_status', { required: true })}>
                                        <option value="inactive">INACTIVE</option>
                                        <option value="active">ACTIVE</option>
                                    </select>
                                    {errors.account_status && <p style={{ color: 'red' }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>  */}

                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Account ID </Label>
                                    <Col lg="7" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountDetails?.app_account_keyid} />
                                        {errors.app_account_keyid && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                    <Col lg="7" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountStatus} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>

                            <Col>
                                <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Company</Label>
                                    <Col lg="7" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountDetails?.app_business_name} />
                                        {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Site Name</Label>
                                    <Col lg="7" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountDetails?.app_department_name} />
                                        {errors.app_department_id && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Credit Limit</Label>
                                    <Col lg="7" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + " " + parseFloat(accountDetails?.credit_limit).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                        {errors.credit_limit && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Balance </Label>
                                    <Col lg="7" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + " " + parseFloat(accountDetails?.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                        {errors.account_balance && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col>
                                <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Oracle Site ID</Label>
                                    <Col lg="7" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountDetails?.oracle_site_ID} />
                                        {errors.oracle_site_ID && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                </FormGroup>
                            </Col> */}
                            {/* <Col>
                                <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Oracle Status</Label>
                                    <Col lg="7" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={oracleCreditStatus} />
                                        {errors.oracle_credit_status && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                </FormGroup>
                            </Col> */}
                        </Row>
                        <Row>
                            <Label lg="3" md="3" />
                            <Col lg="7" md="7">
                                <div className="text-center mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-md ms-2"
                                        onClick={onCloseClick}
                                    >
                                        Back
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Form>
        </Modal>
    )
}

export default AccountDetailModal