import { takeEvery, put, call } from "redux-saga/effects"
import {
  getGenericIndustrySuccess, getGenericIndustryFail,
  getGenericIndustryIdSuccess, getGenericIndustryIdFail,
  postAppGenericIndustrySuccess,postAppGenericIndustryFail
} from "./actions"
import { getGenericIndustry, getGenericIndustryID, postAppGenericindustry } from "helpers/backendApi_helpers";
import { GET_GENERIC_INDUSTRY , GET_GENERIC_INDUSTRY_ID, POST_GENERIC_INDUSTRY} from "./actionTypes";


function* fetchGenericIndustry({ payload: payload }) {
  try {
    const response = yield call(getGenericIndustry, payload);
    yield put(getGenericIndustrySuccess(response))
  } catch (error) {
    yield put(getGenericIndustryFail(error))
  }
}

function* fetchGenericIndustryId({ payload: payload }) {
  try {
    const response = yield call(getGenericIndustryID, payload);
    yield put(getGenericIndustryIdSuccess(response))
  } catch (error) {
    yield put(getGenericIndustryIdFail(error))
  }
}

function* postAppGenericIndustry({ payload: payload }) {
  try {
    const response = yield call(postAppGenericindustry, payload);
    yield put(postAppGenericIndustrySuccess(response))
  } catch (error) {
    yield put(postAppGenericIndustryFail(error))
  }
}

function* GenericIndustrySaga() {
  yield takeEvery(GET_GENERIC_INDUSTRY, fetchGenericIndustry)
  yield takeEvery(GET_GENERIC_INDUSTRY_ID, fetchGenericIndustryId)
  yield takeEvery(POST_GENERIC_INDUSTRY, postAppGenericIndustry)
}

export default GenericIndustrySaga




