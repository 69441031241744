import PropTypes from 'prop-types'
import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { Audio, InfinitySpin, Triangle, FallingLines } from 'react-loader-spinner'

const SpinnerModal = ({ show }) => {
    return (
        <Modal style={{width:"150px"}} isOpen={show} centered={true}>
            <div style={{height:"150px",}} className='d-flex justify-content-center align-items-center'>
                 <Triangle
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="triangle-loading"
                wrapperStyle={{ }}
                wrapperClassName=""
                visible={true}
            />
            </div>
        </Modal>
    )
}

SpinnerModal.propTypes = {
    show: PropTypes.any
}

export default SpinnerModal
