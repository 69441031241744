import { takeEvery, put, call } from "redux-saga/effects"
import {
    getCouponSuccess,
    getCouponFail,
    postCouponSuccess,
    postCouponFail,
    getCouponStatusSuccess,
    getCouponStatusFail,
    getCouponStatusByInvoiceIdSuccess,
    getCouponStatusByInvoiceIdFail,
    getRequestCouponStatusSuccess,
    getRequestCouponStatusFail
} from "./actions"

import {
    postAppCoupon,
    getAppCoupon,
    getAppCouponStatusByInvoiceId,
    getAppCouponStatus,
    getAppRequestCoupon
}
    from "helpers/backendApi_helpers";

import {
    GET_APP_COUPON,
    POST_APP_COUPON,
    GET_APP_COUPON_STATUS,
    GET_APP_COUPON_STATUS_BY_INVOICE_ID,
    GET_APP_REQUEST_COUPON_STATUS

} from "./actionTypes";

function* fetchgetCoupon({ payload: payload }) {
    try {
        const response = yield call(getAppCoupon, payload);
        yield put(getCouponSuccess(response))
    } catch (error) {
        yield put(getCouponFail(error))
    }
}

function* fetchgetCouponStatus({ payload: payload }) {
    try {
        const response = yield call(getAppCouponStatus, payload);
        yield put(getCouponStatusSuccess(response))
    } catch (error) {
        yield put(getCouponStatusFail(error))
    }
}

function* fetchgetCouponStatusByInvoiceID({ payload: payload }) {
    try {
        const response = yield call(getAppCouponStatusByInvoiceId, payload);
        yield put(getCouponStatusByInvoiceIdSuccess(response))
    } catch (error) {
        yield put(getCouponStatusByInvoiceIdFail(error))
    }
}

function* fetchgetRequestCouponStatus({ payload: payload }) {
    try {
        const response = yield call(getAppRequestCoupon, payload);
        yield put(getRequestCouponStatusSuccess(response))
    } catch (error) {
        yield put(getRequestCouponStatusFail(error))
    }
}

function* postCoupon({ payload: payload }) {
    try {
        const response = yield call(postAppCoupon, payload);
        yield put(postCouponSuccess(response))
    } catch (error) {
        yield put(postCouponFail(error))
    }
}



function* AppCoupon() {
    yield takeEvery(GET_APP_COUPON, fetchgetCoupon)
    yield takeEvery(GET_APP_COUPON_STATUS, fetchgetCouponStatus)
    yield takeEvery(GET_APP_COUPON_STATUS_BY_INVOICE_ID, fetchgetCouponStatusByInvoiceID)
    yield takeEvery(POST_APP_COUPON, postCoupon)
    yield takeEvery(GET_APP_REQUEST_COUPON_STATUS, fetchgetRequestCouponStatus)
}

export default AppCoupon





