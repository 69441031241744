import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label } from "reactstrap"
import axios from 'axios';
import getHeader from 'config/header';
import Select from 'react-select'
import StatusModal from 'components/Common/StatusModal';
import { WithContext as ReactTags } from 'react-tag-input';
import { getAppBusinessUnits, getBusinessProductDetail, getBusinesses, getContact, getDepartment, getDepartments, postContact, updateBusinessProduct, updateContacts, updateDepartment } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function CreateContact() {

    const history = useHistory()
    const dispatch = useDispatch()
    const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()

    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const UpadatePermission = (permissionAction.includes("system.sales.contact.update")) ? false : true
    const DeletePermission = (permissionAction.includes("system.sales.contact.update")) ? false : true



    const [businessList, setBusinessList] = useState([])
    const [departments, setDepartment] = useState([])
    const businessesState = useSelector(state => state.businessReducer)
    const departmentState = useSelector(state => state.departmentReducer)

    useEffect(() => {
        dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: "", skip: '' }))
    }, [])

    useEffect(() => {
        if (businessesState.businesses !== null) {
            const res = businessesState.businesses.result
            setBusinessList(res)
        }
    }, [businessesState])


    useEffect(() => {
        if (departmentState.departments !== null) {
            setDepartment(departmentState.departments.result)
        }
    }, [departmentState])

    const selectBusiness = (e) => {
        setDepartment([])
        let companyID = e.target.value
        dispatch(getDepartments({ companyID, filter: true, limit: 0, skip: 0 }))
    }



    const contactState = useSelector(state => state.contactsReducer)


    // const [editOfficePhone, setEditOfficePhone] = useState()

    // useEffect(() => {
    //     if (editOfficePhone) {
    //         setValue("office_phone", editOfficePhone)
    //     } else {
    //         setValue("office_phone", "")
    //     }
    // }, [editOfficePhone])

    // const [editMobilePhone, setEditMobilePhone] = useState()
    // useEffect(() => {
    //     if (editMobilePhone) {
    //         setValue("mobile_phone", editMobilePhone)
    //     } else {
    //         setValue("mobile_phone", "")
    //     }
    // }, [editMobilePhone])

    const [officePhone, setOfficePhone] = useState()
    const [countryCode, setCountryCode] = useState("")
    register("office_phone", { required: true })

    useEffect(() => {
        if (officePhone !== undefined) {
            setValue("office_phone", officePhone)
            errors.office_phone = false
        } else {
            setValue("office_phone", "")
            // errors.office_phone = true
        }
    }, [officePhone])

    const changeCountry = (e) => {
        if (e !== undefined) {
            let value = getCountryCallingCode(e)
            setCountryCode(value);
        } else {
            setCountryCode()
        }
    }

    const isValidOfficePhone = () => {
        if (!officePhone) {
            return false;
        }
        const phoneNumber = `${officePhone}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

        return parsedPhoneNumber && parsedPhoneNumber.isValid();
    };


    const [mobilePhone, setMobilePhone] = useState()
    const [countryCode2, setCountryCode2] = useState("")
    register("mobile_phone", { required: true })

    useEffect(() => {
        if (mobilePhone !== undefined) {
            setValue("mobile_phone", mobilePhone)
            errors.mobile_phone = false
        } else {
            setValue("mobile_phone", "")
            // errors.mobile_phone = true
        }
    }, [mobilePhone])

    const changeCountry2 = (e) => {
        if (e !== undefined) {
            let value = getCountryCallingCode(e)
            setCountryCode2(value);
        } else {
            setCountryCode2()
        }
    }

    const isValidMobilePhone = () => {
        if (!mobilePhone) {
            return false;
        }
        const phoneNumber = `${mobilePhone}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

        return parsedPhoneNumber && parsedPhoneNumber.isValid();
    };


    const [businessUnit, setBusinessUnit] = useState([])

    const businessUnitSate = useSelector(state => state.AppBusinessUnits)

    useEffect(() => {
        dispatch(getAppBusinessUnits({ filter: `{"is_active":${true}}`, limit:0, skip: '' }));
    }, [])

    useEffect(() => {
        if (businessUnitSate.appBusinessUnits !== null) {
            setBusinessUnit(businessUnitSate.appBusinessUnits.result);
        }
    }, [businessUnitSate])


    const createContact = (contactValue) => {
        // console.log("contactValue", contactValue);
        if (contactValue.app_business_id && contactValue.app_contact_name && contactValue.department && contactValue.position && contactValue.app_contact_email && contactValue.office_phone && contactValue.mobile_phone) {


            let statusValue
            if (contactValue.is_active === "active") {
                statusValue = true
            } else if (contactValue.is_active === "inactive") {
                statusValue = false
            }

            const contactValues = {
                ...contactValue,
                is_active: statusValue,
                app_contact_email:(contactValue.app_contact_email).toLowerCase()
                //   app_department_id: contactValue.app_department_id
            }
            const data = {
                companyID: contactValue.app_business_id,
                contactValues
            }

            // console.log("data",data);
            dispatch(postContact(data))
        }
    }

    useEffect(() => {
        if (contactState.isCreated) {
            history.goBack()
        }
    }, [contactState])

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Contact" breadcrumbItem="Contacts" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createContact)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select onInput={selectBusiness} className="form-select d-inline" aria-label="Default select example" {...register("app_business_id", { required: true })}>
                                                    <option value="">Company</option>
                                                    {businessList.map((business) => (
                                                        <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Site <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select className={`form-select d-inline `} aria-label="Default select example" {...register("app_department_id", { required: true })}>
                                                    <option hidden value="">Select the Site</option>
                                                    {departments.map((department) => (
                                                        <option key={department.app_department_id} value={department.app_department_id}>{department.app_department_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_department_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
{/* 
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Business Unit <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select defaultValue="" className='form-select d-inline' aria-label="Default select example" {...register("app_businessunit_id", { required: true })}>
                                                    <option disabled hidden value="">Select the Business Unit</option>
                                                    {businessUnit.map((businessunitlist) => (
                                                        <option key={businessunitlist.app_businessunit_id} value={businessunitlist.app_businessunit_id}>{businessunitlist.app_business_unit_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_businessunit_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row> */}
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Name <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input className={`form form-control`} placeholder="Enter Contact Person Name" {...register(`app_contact_name`, { required: true })} />
                                                {errors.app_contact_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Position <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input className={`form form-control`} placeholder="Enter Position" {...register(`position`, { required: true })} />
                                                {errors.position && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Department <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input className={`form form-control`} placeholder="Enter Department" {...register(`department`, { required: true })} />
                                                {errors.department && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row >
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Email <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input type="email" className={`form form-control`} placeholder="Enter Email" {...register(`app_contact_email`, { required: true })} />
                                                {errors.app_contact_email && <p style={{ color: "red" }}>Valid Email is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    {/* <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Office <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input maxLength={"20"} className={`form form-control `} placeholder="Enter office phone" {...register(`office_phone`, { required: true })} />
                                                {errors.office_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Mobile <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input maxLength={"20"} className={`form form-control`} placeholder="Enter mobile phone" {...register(`mobile_phone`, { required: true })} />
                                                {errors.mobile_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row> */}
                                    <Row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                            Office  <span style={{ color: "red" }}>*</span> {countryCode && <span style={{ color: "steelblue" }}>{`(+${countryCode})`}</span>}
                                        </Label>
                                        <Col lg="5" md="6">
                                            <FormGroup row
                                                className="form form-control form-phone " >
                                                <PhoneInput
                                                    onCountryChange={e => changeCountry(e)}
                                                    placeholder="Enter Office Phone Number"
                                                    value={officePhone}
                                                    onChange={setOfficePhone} />
                                            </FormGroup>
                                            {officePhone && (isValidOfficePhone() ? "" : <p style={{ color: 'red' }}>Invalid Office number</p>)}
                                            {errors.office_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                            Mobile <span style={{ color: "red" }}>*</span> {countryCode2 && <span style={{ color: "steelblue" }}>{`(+${countryCode2})`}</span>}
                                        </Label>
                                        <Col lg="5" md="6">
                                            <FormGroup row
                                                className="form form-control form-phone " >
                                                <PhoneInput
                                                    onCountryChange={e => changeCountry2(e)}
                                                    placeholder="Enter Mobile Phone Number"
                                                    value={mobilePhone}
                                                    onChange={setMobilePhone} />
                                            </FormGroup>
                                            {mobilePhone && (isValidMobilePhone() ? "" : <p style={{ color: 'red' }}>Invalid Mobile number</p>)}
                                            {errors.mobile_phone && <p style={{ color: 'red' }}>This Field is required</p>}

                                        </Col>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Remarks
                                            </Label><Col lg="5" md="6">
                                                <input className="form form-control" placeholder="Enter Remarks" {...register(`remarks`, { required: true })} />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="5" md="6">
                                                <select defaultValue={"active"} className="form-select d-inline" {...register('is_active', { required: true })}>
                                                    {/* <option hidden value="">Select Status</option> */}
                                                    <option value="inactive">INACTIVE</option>
                                                    <option value="active">ACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button id='createProduct' color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateContact

