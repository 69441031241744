import {
  GET_SYS_USER_PRODUCT,
  GET_SYS_USER_PRODUCT_SUCCESS,
  GET_SYS_USER_PRODUCT_FAIL,

} from "./actionTypes";

/////////////////////////
// Get SysUserProduct  //
/////////////////////////

export const getSysUserProduct = (payload) => ({
  type: GET_SYS_USER_PRODUCT,
  payload: payload
});

export const getSysUserProductSuccess = products => ({
  type: GET_SYS_USER_PRODUCT_SUCCESS,
  payload: products,
});

export const getSysUserProductFail = error => ({
  type: GET_SYS_USER_PRODUCT_FAIL,
  payload: error,
});

