import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';


// const WrappedView = () => {
//     const { instance } = useMsal();
//     const activeAccount = instance.getActiveAccount();

//     // const handleRedirect = () => {
//     //     instance
//     //         .loginRedirect({
//     //             ...loginRequest,
//     //             prompt: 'create',
//     //         })
//     //         .catch((error) => console.log(error));
//     // };

//     const handleLogout = () => {
//         sessionStorage.removeItem("authUser");
//         sessionStorage.removeItem("userCred");
//         sessionStorage.removeItem("userName");
//         instance.logout()
//     };

//     return (
//         <div className="App">
//             <AuthenticatedTemplate>
//                 {activeAccount ? (
//                     <div>
//                         <p>Authenticated Successfully</p>
//                         <button onClick={handleLogout}> Logout</button>
//                     </div>
//                 ) : null}
//             </AuthenticatedTemplate>
//             {/* <UnauthenticatedTemplate>
//                 <button onClick={handleRedirect}>Login OR SignUp</button>
//             </UnauthenticatedTemplate> */}
//         </div>
//     );
// };


const Logout = props => {
    // const dispatch = useDispatch()
    // useEffect(() => {
    //   dispatch(logoutUser(props.history))
    // }, [dispatch])


    const { instance } = useMsal();
    // const activeAccount = instance.getActiveAccount();

    const handleLogout = () => {
        instance.logout()
        // sessionStorage.removeItem("authUser");


        sessionStorage.removeItem("authUser");
        sessionStorage.removeItem("userCred");
        sessionStorage.removeItem("userName");
    };
    
    useEffect(() => {
        handleLogout()
    }, [])

    return 
}



export default withRouter(Logout)
