import {
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAIL,

  POST_TRANSACTION,
  POST_TRANSACTION_SUCCESS,
  POST_TRANSACTION_FAIL,

  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAIL,

  GET_TRANSACTION_DETAIL,
  GET_TRANSACTION_DETAIL_SUCCESS,
  GET_TRANSACTION_DETAIL_FAIL,

  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAIL,

} from "./actionTypes";

const INIT_STATE = {
  appTransaction: null,
  appTransactions: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const AppTransactionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_TRANSACTION:
      return {
        ...state,
        loading: true,
        appTransactions: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        appTransactions: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_TRANSACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };

    case GET_TRANSACTION_DETAIL:
      return {
        ...state,
        loading: true,
        appTransaction: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        appTransaction: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_TRANSACTION_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_TRANSACTION:
      return {
        ...state,
        loading: true,
        appTransaction: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        appTransaction: action.payload,
        isCreated: true,
        isDeleted: false,
        isUpdated: false
      };
    case POST_TRANSACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case DELETE_TRANSACTION:
      return {
        ...state,
        loading: true,
        appTransaction: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        appTransaction: null,
        isCreated: false,
        isDeleted: true,
        isUpdated: false
      };
    case DELETE_TRANSACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case UPDATE_TRANSACTION:
      return {
        ...state,
        loading: true,
        appTransaction: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        appTransaction: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: true
      };
    case UPDATE_TRANSACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default AppTransactionReducer;


