import React, { useState, useEffect } from "react"
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import axios from "axios";
import getHeader from "config/header";
import { useContext } from "react";
import creditData from "pages/Finance/Credits/Credit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as url from "./../../helpers/url_helper";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

function SiteSelectCredit() {

  const query = useQuery();
  const skip = query.get("skip")
  const limit = query.get("limit")
  const Busid = query.get("Busid")
  const SiteId = query.get("SiteId")
  const pageStatus = query.get("status")

  const history = useHistory()
  const location = useLocation();
  const [siteList, setSiteList] = useState([])
  
  // const pathname = window.location.pathname
  const pathname = location.pathname

  useEffect(() => {
    if (Busid !== null && Busid !== "null") {
      const token = sessionStorage.getItem('authUser')
      axios.get(url.API_BASE_URL+url.DEPARTMENT_URL+"/"+Busid+`?filter={"is_active":true}`, getHeader(token))
        .then((res) => {
          setSiteList(res.data.result)
          history.push(`${pathname}?skip=${Number(skip)}&&limit=${Number(limit)}&&status=${true}&&Busid=${Busid}&&SiteId=${null}`)
        }).catch(err => {
          console.log(err);
        })
    }
  }, [Busid])

  const selectedID = (e) => {
    history.push(`${pathname}?skip=${Number(skip)}&&limit=${Number(limit)}&&status=${true}&&Busid=${Busid}&&SiteId=${e.target.value}`)
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          {location.pathname !== "/dashboard" &&
            <select onInput={(e) => selectedID(e)} value={SiteId} className="form-select d-inline" aria-label="Default select example">
              <option value="null">Site</option>
              {siteList.map(department => (
                <option key={department.app_department_id} value={department.app_department_id}>{department.app_department_name}</option>
              ))}
            </select>}
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default SiteSelectCredit