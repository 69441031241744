import React from 'react';

const ContactName = (cell) => {
    return cell.value ? cell.value : '';
};

const Position = (cell) => {
    return cell.value ? cell.value : '';
};

const Department = (cell) => {
    return cell.value ? cell.value: '';
};

const Email = (cell) => {
    return cell.value ? cell.value: '';
};

export {
    ContactName,
    Position,
    Department,
    Email
};