/* EVENTS */

// Get Download log
export const GET_DOWNLOAD_LOG = "GET_DOWNLOAD_LOG";
export const GET_DOWNLOAD_LOG_SUCCESS = "GET_DOWNLOAD_LOG_SUCCESS";
export const GET_DOWNLOAD_LOG_FAIL = "GET_DOWNLOAD_LOG_FAIL";

// Get download log detail BY ID
export const GET_DOWNLOAD_LOG_DETAIL = "GET_DOWNLOAD_LOG_DETAIL";
export const GET_DOWNLOAD_LOG_DETAIL_SUCCESS = "GET_DOWNLOAD_LOG_DETAIL_SUCCESS";
export const GET_DOWNLOAD_LOG_DETAIL_FAIL = "GET_DOWNLOAD_LOG_DETAIL_FAIL";

// Post  download log
export const POST_DOWNLOAD_LOG = "POST_DOWNLOAD_LOG";
export const POST_DOWNLOAD_LOG_SUCCESS = "POST_DOWNLOAD_LOG_SUCCESS";
export const POST_DOWNLOAD_LOG_FAIL = "POST_DOWNLOAD_LOG_FAIL"

// Delete  download log
export const DELETE_DOWNLOAD_LOG = "DELETE_DOWNLOAD_LOG";
export const DELETE_DOWNLOAD_LOG_SUCCESS = "DELETE_DOWNLOAD_LOG_SUCCESS";
export const DELETE_DOWNLOAD_LOG_FAIL = "DELETE_DOWNLOAD_LOG_FAIL"


