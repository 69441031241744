import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"

import axios from 'axios';
import getHeader from 'config/header';

function CreateContracts() {
    const history = useHistory()
    const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()


    const createContract = (data) => {
        $('#createcontract').attr("disabled", true)
    }

    const [businessList, setBusinessList] = useState([])

    useEffect(() => {
     
    }, [])

    const [sites, setSites] = useState([])

    const fetchSite = (businessID) => {
        setSites([])
    }

    const cancel = () => {
        history.push('/contracts')
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Contracts" breadcrumbItem="Contracts" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createContract)}>
                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label>Contract Name <span style={{ color: "red" }}>*</span></Label>
                                                <input type="text" className='form form-control' {...register('app_contract_name', { required: true })} />
                                                {errors.app_contract_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label>Company <span style={{ color: "red" }}>*</span> </Label>
                                                <select onInput={(e) => fetchSite(e.target.value)}  className="form-select d-inline" aria-label="Default select example" {...register("app_contract_business", { required: true })}>
                                                    <option value="">Company</option>
                                                    {businessList.map((business) => (
                                                        <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_contract_business && <p style={{ color: "red" }}>This Field is required</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label>Site <span style={{ color: "red" }}>*</span> </Label>
                                                <select defaultValue="" className='form form-select' {...register('department_id', { required: true })}>
                                                    <option disabled hidden value="">Select the Site</option>
                                                    {sites.map((site, i) => (
                                                        <option key={i} value={site.department_id}>{site.department_name}</option>
                                                    ))}
                                                </select>
                                                {errors.department_id && <p style={{ color: "red" }}>This Field is required</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label>Contract Description <span style={{ color: "red" }}>*</span></Label>
                                                <input type="text" className='form form-control' {...register('app_contract_description', { required: true })} />
                                                {errors.app_contract_description && <p style={{ color: "red" }}>This Field is required</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Button color='secondary' type='button' className='m-2' onClick={cancel}>
                                                    Cancel
                                                </Button>
                                                <Button color='success' id='createcontract' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateContracts