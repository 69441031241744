import { takeEvery, put, call } from "redux-saga/effects"
import { getPlatformActionsSuccess, getPlatformActionsFail } from "./actions"
import { getPlatformActions,  } from "helpers/backendApi_helpers";
import {  GET_PLATFORM_ACTIONS } from "./actionTypes";


function* fetchAppBusinessUnit({ payload: payload }) {
    try {
        const response = yield call(getPlatformActions, payload);
        yield put(getPlatformActionsSuccess(response))
    } catch (error) {
        yield put(getPlatformActionsFail(error))
    }
}


function* AppPlatformActionsSaga() {
    yield takeEvery(GET_PLATFORM_ACTIONS, fetchAppBusinessUnit)
}

export default AppPlatformActionsSaga






