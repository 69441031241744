import {
    GET_APP_USERS,
    GET_APP_USERS_SUCCESS,
    GET_APP_USERS_FAIL,

    GET_APP_USER,
    GET_APP_USER_SUCCESS,
    GET_APP_USER_FAIL,

    POST_APP_USER,
    POST_APP_USER_SUCCESS,
    POST_APP_USER_FAIL,

    DELETE_APP_USER,
    DELETE_APP_USER_SUCCESS,
    DELETE_APP_USER_FAIL,

    UPDATE_APP_USER,
    UPDATE_APP_USER_SUCCESS,
    UPDATE_APP_USER_FAIL,
} from "./actionTypes"

/////////////////////////
//  Get app Users      //
/////////////////////////

export const getAppUsers = (payload) => ({
    type: GET_APP_USERS,
    payload: payload
})

export const getAppUsersSuccess = appUsers => ({
    type: GET_APP_USERS_SUCCESS,
    payload: appUsers
})

export const getAppUsersFail = error => ({
    type: GET_APP_USERS_FAIL,
    payload: error
})

///////////////////////////
//   Get By id app user  //
///////////////////////////

export const getAppUser = (payload) => ({
    type: GET_APP_USER,
    payload: payload
})

export const getAppUserSuccess = appUser => ({
    type: GET_APP_USER_SUCCESS,
    payload: appUser
})

export const getAppUserFail = error => ({
    type: GET_APP_USER_FAIL,
    payload: error
})

///////////////////////////
//   Post app User      //
///////////////////////////

export const postAppUser = (payload) => ({
    type: POST_APP_USER,
    payload: payload
})

export const postAppUserSuccess = appUser => ({
    type: POST_APP_USER_SUCCESS,
    payload: appUser
})

export const postAppUserFail = error => ({
    type: POST_APP_USER_FAIL,
    payload: error
})

///////////////////////////
// Delete App user       //
//////////////////////////

export const deleteAppUser = (payload) => ({
    type: DELETE_APP_USER,
    payload: payload
})

export const deleteAppUserSuccess = () => ({
    type: DELETE_APP_USER_SUCCESS,
})

export const deleteAppUserFail = error => ({
    type: DELETE_APP_USER_FAIL,
    payload: error
})

///////////////////////////
// Update App User       //
///////////////////////////

export const updateAppUser = (payload) => ({
    type: UPDATE_APP_USER,
    payload: payload
});

export const updateAppUserSuccess = payload => ({
    type: UPDATE_APP_USER_SUCCESS,
    payload: payload
});

export const updateAppUserFail = error => ({
    type: UPDATE_APP_USER_FAIL,
    payload: error,
});
