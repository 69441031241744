import {
    GET_SYS_CREDENTIALS,
    GET_SYS_CREDENTIALS_SUCCESS,
    GET_SYS_CREDENTIALS_FAIL,

    // GET_SYS_USER,
    // GET_SYS_USER_SUCCESS,
    // GET_SYS_USER_FAIL,

    POST_SYS_CREDENTIALS,
    POST_SYS_CREDENTIALS_SUCCESS,
    POST_SYS_CREDENTIALS_FAIL,

    // DELETE_SYS_USERS,
    // DELETE_SYS_USERS_SUCCESS,
    // DELETE_SYS_USERS_FAIL,

    // UPDATE_SYS_USERS,
    // UPDATE_SYS_USERS_SUCCESS,
    // UPDATE_SYS_USERS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    sysCredentials: null,
    sysCredential: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const sysCredentialsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SYS_CREDENTIALS:
            return {
                ...state,
                loading: true,
                sysCredentials: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                sysCredentials: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_CREDENTIALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_SYS_CREDENTIALS:
            return {
                ...state,
                loading: true,
                sysCredentials: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_SYS_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                sysCredential: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_SYS_CREDENTIALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        // case DELETE_SYS_USERS:
        //     return {
        //         ...state,
        //         loading: true,
        //         sysUsers: null,
        //         isCreated: false,
        //         isDeleted: false,
        //         isUpdated: false
        //     };
        // case DELETE_SYS_USERS_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         sysUser: null,
        //         isCreated: false,
        //         isDeleted: true,
        //         isUpdated: false
        //     };
        // case DELETE_SYS_USERS_FAIL:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.payload,
        //         isCreated: false,
        //         isDeleted: false,
        //         isUpdated: false
        //     };
        // case GET_SYS_USER:
        //     return {
        //         ...state,
        //         loading: true,
        //         sysUser: null,
        //         isCreated: false,
        //         isDeleted: false,
        //         isUpdated: false
        //     };
        // case GET_SYS_USER_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         sysUser: action.payload,
        //         isCreated: false,
        //         isDeleted: false,
        //         isUpdated: false
        //     };
        // case GET_SYS_USER_FAIL:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.payload,
        //         isCreated: false,
        //         isDeleted: false,
        //         isUpdated: false
        //     };
        // case UPDATE_SYS_USERS:
        //     return {
        //         ...state,
        //         loading: true,
        //         // sysUsers: null,
        //         sysUser: null,
        //         isCreated: false,
        //         isDeleted: false,
        //         isUpdated: false
        //     };
        // case UPDATE_SYS_USERS_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         sysUser: action.payload,
        //         isCreated: false,
        //         isDeleted: false,
        //         isUpdated: true
        //     };
        // case UPDATE_SYS_USERS_FAIL:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: action.payload,
        //         isCreated: false,
        //         isDeleted: false,
        //         isUpdated: false
        //     };
        default:
            return state;
    }
};

export default sysCredentialsReducer;
