import axios from 'axios';
import getHeader from 'config/header';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Badge } from 'reactstrap';
import * as url from "./../../../../helpers/url_helper";

const CompanyName = (cell) => {
    const [businessName, setBusinessName] = useState("")
    useEffect(() => {
        if (cell.value) {
            const token = sessionStorage.getItem('authUser')
            axios.get(url.API_BASE_URL+url.BUSINESS_URL+"/"+ cell.value, getHeader(token))
                .then(res => {
                    setBusinessName(res.data.app_business_name)
                }).catch(err => {
                    console.log(err);
                })
        }
    }, [])
    return businessName ? businessName : ""
};

const Status = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

export {
    Status,
    CompanyName
};