import axios from 'axios'
import getHeader from 'config/header'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import { Col, Form, Modal, ModalBody, Row, Table } from "reactstrap"
import * as url from "./../../helpers/url_helper";

const BusinessUnitModal = ({ show, onCloseClick, onDeleteClick, onDataSelect }) => {


    const { handleSubmit, register, getValues, unregister, setValue } = useForm()

    const [businessUnitList, setBusinessUnitList] = useState([])
    useEffect(() => {
        const token = sessionStorage.getItem("authUser")
        axios.get(url.API_BASE_URL+url.APP_BUSINESS_UNITS_URL+'?filter={"is_active" : true}', getHeader(token))
            .then(businessRes => {
                // console.log(businessRes.data.result);
                setBusinessUnitList(businessRes.data.result)
            }).catch(businessErr => {
                console.log(businessErr);
            })
    }, [])



    const selectedBusinessUnits = (data) => {
        onDataSelect(data);
    };

    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <Form onSubmit={handleSubmit(selectedBusinessUnits)}>
                <ModalBody className="py-3 px-5" style={{ maxHeight: '500px', overflow: 'auto' }}>
                    <Row>
                        <Col>
                            <div className="mb-0">
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Business Units </th>
                                            <th>Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {businessUnitList.map((data, BUDIndex) => (
                                            <tr key={BUDIndex}>
                                                <td>{BUDIndex + 1}</td>
                                                <td>{data.app_business_unit_name}</td>
                                                <td><input type="checkbox" value={data.app_businessunit_id} {...register("businessUnits")} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>

                </ModalBody>
                <Row>
                    <Col>
                        <div className="text-center mt-3">
                            <button
                                className="btn btn-success btn-lg ms-2"
                                onClick={onDeleteClick}
                                type="submit"
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger btn-lg ms-2"
                                onClick={onCloseClick}
                            >
                                No
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}


BusinessUnitModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any
}

export default BusinessUnitModal

