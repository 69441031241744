
// Get Application template
export const GET_APPLICATION_TEMPLATE = "GET__APPLICATION_TEMPLATE";
export const GET_APPLICATION_TEMPLATE_SUCCESS = "GET__APPLICATION_TEMPLATE_SUCCESS";
export const GET_APPLICATION_TEMPLATE_FAIL = "GET__APPLICATION_TEMPLATE_FAIL"

// Get Application template by id
export const GET_APPLICATION_TEMPLATE_DETAIL = "GET_APPLICATION_TEMPLATE_DETAIL";
export const GET_APPLICATION_TEMPLATE_DETAIL_SUCCESS = "GET_APP_BU_APPLICATION_TEMPLATE_DETAIL_SUCCESS";
export const GET_APPLICATION_TEMPLATE_DETAIL_FAIL = "GET_APP_BU_APPLICATION_TEMPLATE_DETAIL_FAIL";

// Post Application template
export const POST_APPLICATION_TEMPLATE = "POST_APPLICATION_TEMPLATE";
export const POST_APPLICATION_TEMPLATE_SUCCESS = "POST_APPLICATION_TEMPLATE_SUCCESS";
export const POST_APPLICATION_TEMPLATE_FAIL = "POST_APPLICATION_TEMPLATE_FAIL"

// Delete Application template
export const DELETE_APPLICATION_TEMPLATE = "DELETE_APPLICATION_TEMPLATE";
export const DELETE_APPLICATION_TEMPLATE_SUCCESS = "DELET_APPLICATION_TEMPLATE_SUCCESS";
export const DELETE_APPLICATION_TEMPLATE_FAIL = "DELET_APPLICATION_TEMPLATE_FAIL"

// Update Application template
export const UPDATE_APPLICATION_TEMPLATE = "UPDATE_APPLICATION_TEMPLATE";
export const UPDATE_APPLICATION_TEMPLATE_SUCCESS = "UPDATE_APPLICATION_TEMPLATE_SUCCESS";
export const UPDATE_APPLICATION_TEMPLATE_FAIL = "UPDATE_APPLICATION_TEMPLATE_FAIL";