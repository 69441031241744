import {
    GET_OTP_TIMEOUT,
    GET_OTP_TIMEOUT_SUCCESS,
    GET_OTP_TIMEOUT_FAIL,

    POST_OTP_TIMEOUT,
    POST_OTP_TIMEOUT_SUCCESS,
    POST_OTP_TIMEOUT_FAIL,

    UPDATE_OTP_TIMEOUT,
    UPDATE_OTP_TIMEOUT_SUCCESS,
    UPDATE_OTP_TIMEOUT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    otpTimeouts: null,
    otpTimeout: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const OtpTimeouts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_OTP_TIMEOUT:
            return {
                ...state,
                loading: true,
                otpTimeout: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_OTP_TIMEOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                otpTimeout: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_OTP_TIMEOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_OTP_TIMEOUT:
            return {
                ...state,
                loading: true,
                otpTimeout: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_OTP_TIMEOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                otpTimeout: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_OTP_TIMEOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_OTP_TIMEOUT:
            return {
                ...state,
                loading: true,
                otpTimeouts: null,
                otpTimeout: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_OTP_TIMEOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                otpTimeout: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_OTP_TIMEOUT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default OtpTimeouts;
