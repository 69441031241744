import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label } from "reactstrap"
import axios from 'axios';
import getHeader from 'config/header';
import Select from 'react-select'
import StatusModal from 'components/Common/StatusModal';
import { WithContext as ReactTags } from 'react-tag-input';
import { getAppBusinessUnits, getBusinessProductDetail, getBusinesses, getDepartment, updateBusinessProduct, updateDepartment } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search])
}

function updateCustomerSites() {

	const query = useQuery();
	const BusId = query.get("BusId")
	const { id } = useParams()
	const history = useHistory()
	const dispatch = useDispatch()
	const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()

	const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
	const UpadatePermission = (permissionAction.includes("system.sales.customersites.update")) ? false : true
	const DeletePermission = (permissionAction.includes("system.sales.customersites.delete")) ? false : true

	const [businessUnit, setBusinessUnit] = useState([])

	const businessUnitSate = useSelector(state => state.AppBusinessUnits)

	useEffect(() => {
		dispatch(getAppBusinessUnits({ filter: `{"is_active":${true}}`, limit:0, skip: '' }));
	}, [])

	useEffect(() => {
		if (businessUnitSate.appBusinessUnits !== null) {
			setBusinessUnit(businessUnitSate.appBusinessUnits.result);
		}
	}, [businessUnitSate])

	useEffect(() => {
		if (businessUnit && id && BusId) {
			let departmentID = id
			let businessID = BusId
			const data = { departmentID, businessID }
			dispatch(getDepartment(data))
		}
	}, [])

	const departmentState = useSelector(state => state.departmentReducer)

	useEffect(() => {
		if (departmentState.singledepartment !== null) {
			// console.log(departmentState.singledepartment);
			const siteData = departmentState.singledepartment
			setValue("app_department_name", siteData.app_department_name)
			setValue("app_department_keyid", siteData.app_department_keyid)
			setValue("department_description", siteData.department_description)
			setValue("app_businessunit_id", siteData.app_businessunit_id)
			setValue("app_business_id", siteData.app_business_id)
			setValue("app_account_keyid", siteData.app_account_keyid)
			setValue("credit_limit", siteData.credit_limit)
			setValue("account_balance", siteData.account_balance)
			setValue("oracle_site_ID", siteData.oracle_site_ID)
			if (siteData.oracle_credit_status === true) {
				setValue("oracle_credit_status", "active")
			} else if (siteData.oracle_credit_status === false) {
				setValue("oracle_credit_status", "inactive")
			}
			if (siteData.account_status === true) {
				setValue("account_status", "active")
			} else if (siteData.account_status === false) {
				setValue("account_status", "inactive")
			}
			if (siteData.is_active === true) {
				setValue("is_active", "active")
			} else if (siteData.is_active === false) {
				setValue("is_active", "inactive")
			}
		}
		if (departmentState.isUpdated) {
			history.goBack()
		}
	}, [departmentState])

	const updateSite = (data) => {
		const siteValue = data
		let value
		if (siteValue.is_active === "active") {
			value = true
		} else if (siteValue.is_active === "inactive") {
			value = false
		}

		// let oracle_credit_status
		// if (siteValue.oracle_credit_status === "active") {
		// 	oracle_credit_status = true
		// } else if (siteValue.oracle_credit_status === "inactive") {
		// 	oracle_credit_status = false
		// }

		// let account_status
		// if (siteValue.account_status === "active") {
		// 	account_status = true
		// } else if (siteValue.account_status === "inactive") {
		// 	account_status = false
		// }

		const siteItems = {
			// ...siteValue,
			is_active: value,
			app_business_id: siteValue.app_business_id,
			app_businessunit_id: siteValue.app_businessunit_id,
			app_department_keyid: siteValue.app_department_keyid,
			app_department_name: siteValue.app_department_name,
			department_description: siteValue.department_description
			// account_status: account_status,
			// oracle_credit_status: oracle_credit_status
		}

		const datas = {
			companyID: BusId,
			editDepartmentID: id,
			UpdateSiteData: siteItems
		}
		// console.log(datas);
		dispatch(updateDepartment(datas))
	}

	const [businessList, setBusinessList] = useState([])
	const businessesState = useSelector(state => state.businessReducer)
  
	useEffect(() => {
	  dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: "", skip: '' }))
	}, [])
  
	useEffect(() => {
	  if (businessesState.businesses !== null) {
		const res = businessesState.businesses.result
		setBusinessList(res)
	  }
	}, [businessesState])
  


	return (
		<React.Fragment>
			<div className="page-content">
				<Breadcrumbs title="Update Customer Site" breadcrumbItem="Customer Sites" />
				<Container fluid>
					<Row>
						<Col lg="12">
							<Card body>
								<Form onSubmit={handleSubmit(updateSite)}>
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Site Code <span style={{ color: "red" }}>*</span> </Label>
											<Col lg="8" md="6">
												<input style={{ backgroundColor: "#D2D4D1" }} readOnly className='form form-control'  {...register(`app_department_keyid`, { required: true })} />
												{errors.app_department_keyid && <p style={{ color: 'red' }}>This Field is required</p>}
											</Col>
										</FormGroup>
									</Row>
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
												Site Name <span style={{ color: "red" }}>*</span>
											</Label>
											<Col lg="8" md="6">
												<input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} className='form form-control' {...register(`app_department_name`, { required: true })} />
												{errors.app_department_name && <p style={{ color: 'red' }}>This Field is required</p>}
											</Col>
										</FormGroup>
									</Row>
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
												Site Description
											</Label>
											<Col lg="8" md="6">
												<textarea style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} className="form form-control" type="textarea" {...register(`department_description`, { required: true })} />
											</Col>
										</FormGroup>
									</Row>
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Business Unit <span style={{ color: "red" }}>*</span> </Label>
											<Col lg="8" md="6">
												<select style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={UpadatePermission} defaultValue="" className='form-select d-inline' aria-label="Default select example" {...register("app_businessunit_id", { required: true })}>
													<option disabled hidden value="">Select the Business Unit</option>
													{businessUnit.map((businessunitlist) => (
														<option key={businessunitlist.app_businessunit_id} value={businessunitlist.app_businessunit_id}>{businessunitlist.app_business_unit_name}</option>
													))}
												</select>
												{errors.app_businessunit_id && <p style={{ color: 'red' }}>This Field is required</p>}
											</Col>
										</FormGroup>
									</Row>

									<Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                      <Col lg="8" md="6">
                                        <select disabled style={{ backgroundColor: "#D2D4D1" }}  className={`form-select d-inline`} aria-label="Default select example" id="app_business_id" {...register("app_business_id", { required: true })}>
                                          <option value="">Company</option>
                                          {businessList.map((business) => (
                                            <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                          ))}
                                        </select>
                                        {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
									<hr />
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Account ID </Label>
											<Col lg="8" md="6">
												<input style={{ backgroundColor: "#D2D4D1" }} readOnly className='form form-control'  {...register("app_account_keyid")} />
												{errors.app_account_keyid && <p style={{ color: 'red' }}>This Field is required</p>}
											</Col>
										</FormGroup>
									</Row>
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Credit Limit  </Label>
											<Col lg="8" md="6">
												<input style={{ backgroundColor: "#D2D4D1" }} readOnly className='form form-control' {...register("credit_limit")} />
												{errors.credit_limit && <p style={{ color: 'red' }}>This Field is required</p>}
											</Col>
										</FormGroup>
									</Row>
									{/* <Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Account Balance </Label>
											<Col lg="8" md="6">
												<input style={{ backgroundColor: "#D2D4D1" }} readOnly className='form form-control ' {...register("account_balance")} />
												{errors.account_balance && <p style={{ color: 'red' }}>This Field is required</p>}
											</Col>
										</FormGroup>
									</Row> */}
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Oracle Site ID </Label>
											<Col lg="8" md="6">
												<input style={{ backgroundColor: "#D2D4D1" }} readOnly className='form form-control'{...register("oracle_site_ID")} />
												{errors.oracle_site_ID && <p style={{ color: 'red' }}>This Field is required</p>}
											</Col>
										</FormGroup>
									</Row>
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Oracle Credit Status </Label>
											<Col lg="8" md="6">
												<select defaultValue={"inactive"} style={{ backgroundColor: "#D2D4D1" }} disabled className="form-select d-inline" {...register('oracle_credit_status')}>
													{/* <option hidden value="">Select Status</option> */}
													<option value="inactive">INACTIVE</option>
													<option value="active">ACTIVE</option>
												</select>
												{/* {custErrors.oracle_credit_status && <p style={{ color: 'red' }}>This Field is required</p>} */}
											</Col>
										</FormGroup>
									</Row>
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Account Status</Label>
											<Col lg="8" md="6">
												<select style={{ backgroundColor: "#D2D4D1" }} disabled className="form-select d-inline" {...register('account_status')}>
													{/* <option hidden value="">Select Status</option> */}
													<option value="inactive">INACTIVE</option>
													<option value="active">ACTIVE</option>
												</select>
												{errors.account_status && <p style={{ color: 'red' }}>This Field is required</p>}
											</Col>
										</FormGroup>
									</Row>
									<hr />
									<Row>
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
											<Col lg="8" md="6">
												<select style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={DeletePermission} className="form-select d-inline" {...register('is_active', { required: true })}>
													{/* <option hidden value="">Select Status</option> */}
													<option value="inactive">INACTIVE</option>
													<option value="active">ACTIVE</option>
												</select>
											</Col>
										</FormGroup>
									</Row>
									<Row className="mt-4">
										<FormGroup row>
											<Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
											<Col lg="5" md="6">
												<Button color='secondary' type='button' onClick={() => history.goBack()}>
													Back
												</Button>
												{!UpadatePermission && <Button id='updateProduct' color='success' type='submit' className='m-2'>
													Update
												</Button>}
											</Col>
										</FormGroup>
									</Row>
								</Form>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	)
}

export default updateCustomerSites

