import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';
import { CompanyName, CustKeyId, Industry, BusinessUnitOwner, Status } from './CompanyListCol';
import axios from "axios";
import getHeader from "config/header";
import expiredSessionHandler from "common/expiredSessionHandler";
import Approval from "../../../components/Common/ApproveModal";
import RequestModal from "components/Common/RequestModal";
import { deleteBusiness, getBusinesses } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import * as url from "./../../../helpers/url_helper";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const Company = props => {

    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const pageStatus = query.get("status")


    const history = useHistory()
    const dispatch = useDispatch()

    document.title = "Customers | Maxims B2B Portal ";

    const [permissions, setPermissions] = useState('')
    const [companies, setCompanies] = useState([]);
    const [pageCount, setPageCount] = useState(1);

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    const businessState = useSelector(state => state.businessReducer)



    useEffect(() => {
        if (businessState.businesses !== null) {
            setCompanies(businessState.businesses.result);
            setPageCount(Math.ceil(businessState.businesses.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(businessState.businesses.summary.filteredsize / pageLimit)
            );
        }
        if (businessState.isDeleted) {
            setDeleteModal(false);
            handlePageClick(page);
        }
        if (businessState.error) {
            setDeleteModal(false);
        }
    }, [businessState])

    const [customFilterData, setCustomFilterData] = useState("")

    useEffect(() => {
        handlePageClick()
    }, [pageStatus, pageLimit, page, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/companyList?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
        }
    }, [customFilterData])

    const handlePageClick = (index) => {
        let filter = `{"is_active":${pageStatus},"$or":[
            {"app_business_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_sales_industry":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`
        let skips = 0;
        if (page > 0) {
            skips = page * pageLimit;
            dispatch(getBusinesses({ filter: filter, limit: pageLimit, skip: skips }));
        }
        else {
            dispatch(getBusinesses({ filter: filter, limit: pageLimit, skip: 0 }));
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Company ID',
                accessor: 'app_business_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <CustKeyId {...cellProps} />;
                }
            },
            {
                Header: 'Company Name',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <CompanyName {...cellProps} />;
                }
            },
            {
                Header: 'Industry',
                accessor: 'app_sales_industry',
                filterable: true,
                Cell: (cellProps) => {
                    return <Industry {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {(permissionAction.includes("system.customers.business.read")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/viewCompany/${cellProps.row.original.app_business_id}?tab=${1}&&skip=${0}&&limit=${10}&&status=${true}`)
                                        }} className="btn btn-success btn-sm">View</button>
                                </> : ""
                            }
                            {(permissionAction.includes("system.customers.business.read")) && (permissionAction.includes("system.customers.business.updates")) ||
                                (permissionAction.includes("system.customers.business.read")) && (permissionAction.includes("system.customers.business.delete")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/updateCompany/${cellProps.row.original.app_business_id}?tab=${1}&&skip=${0}&&limit=${10}&&status=${true}`)
                                        }} className="btn btn-info btn-sm">Edit</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                        </div>
                    );
                }
            },
        ], [companies]
    );

    // const [deleteModal, setDeleteModal] = useState(false);
    // const [companyTBD, setCompanyTBD] = useState('')

    // const onClickDelete = (company) => {
    //     setCompanyTBD(company);
    //     setDeleteModal(true);
    // };

    // const handleDeleteCustomer = () => {

    // };

    //delete Business

    const [deleteModal, setDeleteModal] = useState(false);
    const [companyTBD, setCompanyTBD] = useState('')

    const onClickDelete = (sysRole) => {
        setCompanyTBD(sysRole)
        setDeleteModal(true)
    }

    const handleDeleteBusiness = () => {
        if (companyTBD.app_business_id) {
            const data = {
                businessId: companyTBD.app_business_id
            };
            dispatch(deleteBusiness(data));
        }
    }

    const [approveModal, setApproveModal] = useState(false);
    const [companyTBA, setCompanyTBA] = useState('')

    const onClickApprove = (company) => {
        setCompanyTBA(company);
        setApproveModal(true);
    };

    const [requestModal, setRequestModal] = useState(false)
    const [companyData, setCompanyData] = useState('')

    const onClickRequest = (company) => {
        setCompanyData(company);
        setRequestModal(true);
    };

    const handleApproveCustomer = () => {
        const companyValue = {
            is_active: true,
            is_approve: true
        }
        if (companyTBA.app_business_id) {
            let ID = companyTBA.app_business_id
            const token = sessionStorage.getItem('authUser')
            axios.put(url.API_BASE_URL + url.BUSINESS_URL + '/' + ID, companyValue, getHeader(token))
                .then(res => {
                    setApproveModal(false);
                    getCompanyData()
                }).catch(err => {
                    console.log(err);
                    setApproveModal(false);
                })
        }
    };

    const handleAddCompList = () => {
        sessionStorage.setItem("companyListQuery", JSON.stringify({
            skip: page,
            limit: pageLimit,
            status: pageStatus
        }))
        history.push(`/createCompany?tab=${1}&&skip=${0}&&limit=${10}&&status=${true}`)
    }

    useEffect(() => {
        sessionStorage.setItem("companyListQuery", JSON.stringify({
            skip: page,
            limit: pageLimit,
            status: pageStatus
        }))
    }, [page, pageLimit, pageStatus])

    return (
        <React.Fragment>
            <RequestModal
                show={requestModal}
                //  onDeleteClick={handleDeleteCustomer}
                onCloseClick={() => setRequestModal(false)}
            />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteBusiness}
                onCloseClick={() => setDeleteModal(false)}
            />
            {/* <Approval
                name={companyTBA.app_business_name}
                title="Company"
                show={approveModal}
                onApproveClick={handleApproveCustomer}
                onCloseClick={() => setApproveModal(false)}
            /> */}
            <div className="page-content">
                {permissions.includes("system.customers.business.read") ? <Container fluid>
                    <Breadcrumbs title="Customers" breadcrumbItem="Customers" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={companies}
                                        isGlobalFilter={true}
                                        customPageCount={pageCount}
                                        className="custom-header-css"
                                        handleAddCompList={handleAddCompList}
                                        isAddCompList={permissions.includes("system.customers.business.write") ? true : false}
                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                                    // customPageSize={10}
                                    // customPageStatus={(data) => setPageStatus(data)}
                                    // handlePageClick={handlePageClick}
                                    // customPageIndex={page}
                                    // pageSizeTransfer={(data) => setPageLimit(data)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    );
};


export default Company;
