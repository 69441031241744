import { takeEvery, put, call } from "redux-saga/effects"
import {
getProposalVersionSuccess,
getProposalVersionFail,
postProposalVersionSuccess,
postProposalVersionFail,
deleteProposalVersionSuccess,
deleteProposalVersionFail,
getProposalVersionDetailSuccess,
getProposalVersionDetailFail,
updateProposalVersionSuccess,
updateProposalVersionFail,
getProposalbyOpportunityIdSuccess,
getProposalbyOpportunityIdFail
} from "./actions"

import {
  getProposalVersion,
  getProposalVersionDetail,
  postAppProposalversion,
  updateAppProposalversion,
  deleteAppProposalversion,
  getProposalbyOpportunityid
}
  from "helpers/backendApi_helpers";

import {
 GET_PROPOSAL_VERSION,
 GET_PROPOSAL_VERSION_DETAIL,
 POST_PROPOSAL_VERSION,
 UPDATE_PROPOSAL_VERSION, 
 DELETE_PROPOSAL_VERSION,
 GET_PROPOSAL_BY_OPPORTUNITY_ID
} from "./actionTypes";

function* fetchPropsalversion({ payload: payload }) {
  try {
    const response = yield call(getProposalVersion, payload);
    yield put(getProposalVersionSuccess(response))
  } catch (error) {
    yield put(getProposalVersionFail(error))
  }
}

function* getPropsalversionDetail({ payload: payload }) {
  try {
    const response = yield call(getProposalVersionDetail, payload);
    yield put(getProposalVersionDetailSuccess(response))
  } catch (error) {
    yield put(getProposalVersionDetailFail(error))
  }
}

function* postPropsalversion({ payload: payload }) {
  try {
    const response = yield call(postAppProposalversion, payload);
    yield put(postProposalVersionSuccess(response))
  } catch (error) {
    yield put(postProposalVersionFail(error))
  }
}

function* deletePropsalversion({ payload: payload }) {
  try {
    const response = yield call( deleteAppProposalversion, payload);
    yield put(deleteProposalVersionSuccess(response))
  } catch (error) {
    yield put(deleteProposalVersionFail(error))
  }
}


function* updatePropsalversion({ payload: payload }) {
  try {
    const response = yield call( updateAppProposalversion, payload);
    yield put(updateProposalVersionSuccess(response))
  } catch (error) {
    yield put(updateProposalVersionFail(error))
  }
}

function* getPropsalbyOpportunity({ payload: payload }) {
  try {
    const response = yield call( getProposalbyOpportunityid, payload);
    yield put(getProposalbyOpportunityIdSuccess(response))
  } catch (error) {
    yield put(getProposalbyOpportunityIdFail(error))
  }
}

function* AppPropsalVersion() {
  yield takeEvery(GET_PROPOSAL_VERSION, fetchPropsalversion)
  yield takeEvery(POST_PROPOSAL_VERSION, postPropsalversion)
  yield takeEvery(DELETE_PROPOSAL_VERSION, deletePropsalversion)
  yield takeEvery(GET_PROPOSAL_VERSION_DETAIL, getPropsalversionDetail)
  yield takeEvery(UPDATE_PROPOSAL_VERSION, updatePropsalversion)
  yield takeEvery(GET_PROPOSAL_BY_OPPORTUNITY_ID, getPropsalbyOpportunity)
}

export default AppPropsalVersion





