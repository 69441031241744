import {
    GET_CONTACTS,
    GET_CONTACTS_SUCCESS,
    GET_CONTACTS_FAIL,

    GET_CONTACT,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAIL,

    GET_ALL_CONTACT,
    GET_ALL_CONTACT_SUCCESS,
    GET_ALL_CONTACT_FAIL,

    POST_CONTACT,
    POST_CONTACT_SUCCESS,
    POST_CONTACT_FAIL,

    DELETE_CONTACT,
    DELETE_CONTACT_SUCCESS,
    DELETE_CONTACT_FAIL,

    UPDATE_CONTACT,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get sys contact  //
/////////////////////////


export const getContactss = (payload) => ({
    type: GET_CONTACTS,
    payload: payload
})

export const getContactssSuccess = contacts => ({
    type: GET_CONTACTS_SUCCESS,
    payload: contacts
})

export const getContactssFail = error => ({
    type: GET_CONTACTS_FAIL,
    payload: error
})

//////////////////////////////
// Get By id contact //
//////////////////////////////

export const getContact = (payload) => ({
    type: GET_CONTACT,
    payload: payload
});

export const getContactSuccess = contact => ({
    type: GET_CONTACT_SUCCESS,
    payload: contact,
});

export const getContactFail = error => ({
    type: GET_CONTACT_FAIL,
    payload: error,
});


//////////////////////////////
// Get All contact //
//////////////////////////////

export const getAllContact = (payload) => ({
    type: GET_ALL_CONTACT,
    payload: payload
});

export const getAllContactSuccess = contact => ({
    type: GET_ALL_CONTACT_SUCCESS,
    payload: contact,
});

export const getAllContactFail = error => ({
    type: GET_ALL_CONTACT_FAIL,
    payload: error,
});

////////////////////////
// Post sys contact     ///
////////////////////////

export const postContact = (payload) => ({
    type: POST_CONTACT,
    payload: payload
});

export const postContactSuccess = contact => ({
    type: POST_CONTACT_SUCCESS,
    payload: contact,
});

export const postContactFail = error => ({
    type: POST_CONTACT_FAIL,
    payload: error,
});

///////////////////////////
// Delete sys contact //
//////////////////////////

export const deleteContact= (payload) => ({
    type: DELETE_CONTACT,
    payload: payload
});

export const deleteContactSuccess = () => ({
    type: DELETE_CONTACT_SUCCESS,
});

export const deleteContactFail = error => ({
    type: DELETE_CONTACT_FAIL,
    payload: error,
});

///////////////////////////
// Update sys contact  //
///////////////////////////

export const updateContacts = (payload) => ({
    type: UPDATE_CONTACT,
    payload: payload
});

export const updateContactSuccess = payload => ({
    type: UPDATE_CONTACT_SUCCESS,
    payload: payload
});

export const updateContactFail = error => ({
    type: UPDATE_CONTACT_FAIL,
    payload: error,
});


