import React, { useContext } from "react"
import PropTypes from 'prop-types'
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const BreadcrumbActivity = props => {

  const query = useQuery();
  const skip = query.get("skip")
  const limit = query.get("limit")
  const status = query.get("status")

  const history = useHistory()

  // const pathname = window.location.pathname
  const pathname = location.pathname

  const onChangeInSelect = event => {
    history.push(`${"/activity"}?skip=${Number(skip)}&&limit=${Number(event.target.value)}&&status=${status}`)
  };

  return (
    <>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="col-lg-12 mb-0 font-size-18">{props.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4>
              <select
                className="form-select"
                value={limit}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50, 100, 200].map(pageSizes => (
                  <option key={pageSizes} value={pageSizes}>
                    Show {pageSizes}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Col>
      </Row>
    </>


  )
}

BreadcrumbActivity.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  isActivityLimit: PropTypes.bool,
}

BreadcrumbActivity.defaultProps = {
  isActivityLimit: false,
}

export default BreadcrumbActivity
