import axios from 'axios';
import getHeader from 'config/header';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap'



const SiteID = (cell) => {
    return cell.value ? cell.value : '';
};

const SiteName = (cell) => {
    return cell.value ? cell.value : '';
};

const Business = (cell) => {
    return cell.value ? cell.value : '';
};

const BusinessUnits = (cell) => {

    return cell.value ? cell.value : '';
// let Data =  cell.value
    // let Dta = Data.map((item) => item.app_business_unit_name)
    // return Dta ? Dta : ''
}

const SiteMenu = (cell) => {
    return (<Button />)
};

const SiteDescription = (cell) => {
    return cell.value ? cell.value : '';
};
const SiteStatus = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

export {
    SiteID,
    SiteName,
    SiteDescription,
    SiteStatus,
    SiteMenu,
    BusinessUnits,
    Business
};