import React, { useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, CardBody } from "reactstrap"
import axios from 'axios'
import getHeader from 'config/header'
import getFormHeader from 'config/formHeader'
import StatusModal from 'components/Common/StatusModal'
import { useDispatch, useSelector } from 'react-redux'
import { geAccountDetail, getTransaction } from 'store/actions'
import departmentReducer from 'store/customers/company/department/reducer'
import { TransactionValue, TransactionId, TransactionType, TransactionDate } from "./AccountListCol"
import TableContainer from 'components/Common/TableContainer'
import ApproveModal from 'components/Common/ApproveModal'

function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

function ViewAccount() {
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm()

    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const { id } = useParams();
    const BusID = query.get("Busid");
    const [permissions, setPermissions] = useState('')

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    //Table pagination
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const [pageCount, setPageCount] = useState(1);
    const pageStatus = query.get("status");

    //Get account id details
    const appAccountsState = useSelector(state => state.AppAccounts);

    useEffect(() => {
        if (id && BusID) {
            dispatch(geAccountDetail({ id, BusID }))
        }
    }, [id, BusID])

    const [accountId, setAccountId] = useState({ AccId: "" })
    const [accountValues, setAccountValues] = useState({})
    const [accountStatus, setAccountStatus] = useState()
    const [oracleCreditStatus, setOracleCreditStatus] = useState()

    useEffect(() => {
        if (appAccountsState.appAccount !== null) {
            let res = appAccountsState.appAccount;
            setAccountValues(res)
            if (res.is_active === true) {
                setAccountStatus("Active")
            } else if (res.is_active === false) {
                setAccountStatus("Inactive")
            }

            if (res.oracle_credit_status === true) {
                setOracleCreditStatus("Active")
            } else if (res.oracle_credit_status === false) {
                setOracleCreditStatus("Inactive")
            }
            setAccountId({
                AccId: res.app_account_id
            })
        }
    }, [appAccountsState])

    const appTransactionState = useSelector(state => state.AppTransactionReducer);
    const [transactionDetails, setTransactionDetail] = useState([]);

    // useEffect(() => {
    //     if (id) {
    //         let accountId = id;
    //         dispatch(getTransaction({ filter:`{"app_account_id":"${accountId}"}`, limit: 0, skip: 0 }));
    //     }
    // }, [])

    //============================ global search filter ==============================//
    const [customFilterData, setCustomFilterData] = useState("");
    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, customFilterData])

    useEffect(() => {
        let accountId = id;
        if (customFilterData.length > 0) {
            history.push(`/viewaccount/${accountId}?Busid=${BusID}&&skip=${0}&&limit=${pageLimit}`)
        }
    }, [customFilterData])

    const handlePageClick = (index) => {
        let accountId = id;
        let filter = JSON.stringify({
            "app_account_id": accountId,
            "$or": [
                { "app_transaction_keyid": { "$regex": customFilterData, "$options": "i" } },
                { "app_transaction_type": { "$regex": customFilterData, "$options": "i" } },
                { "created_on": { "$regex": customFilterData, "$options": "i" } },
                { "app_transaction_value": { "$regex": customFilterData, "$options": "i" } }
            ]
        });

        let skips = 0;
        if (page > 0) {
            skips = page * pageLimit;
            dispatch(getTransaction({ filter: filter, limit: pageLimit, skip: skips }));
        } else {
            dispatch(getTransaction({ filter: filter, limit: pageLimit, skip: 0 }));
        }
    }

    useEffect(() => {
        if (appTransactionState.appTransactions !== null) {
            setTransactionDetail(appTransactionState.appTransactions.result);
            setPageCount(Math.ceil(appTransactionState.appTransactions.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(appTransactionState.appTransactions.summary.filteredsize / pageLimit)
            );
        }
    }, [appTransactionState])


    const columns = useMemo(
        () => [
            {
                Header: 'Transaction ID',
                accessor: 'app_transaction_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <TransactionId {...cellProps} />;
                }
            }, {
                Header: 'Transaction Date',
                accessor: 'created_on',
                filterable: true,
                Cell: (cellProps) => {
                    return <TransactionDate {...cellProps} />
                }
            },
            {
                Header: 'Transaction Type',
                accessor: 'app_transaction_type',
                filterable: true,
                Cell: (cellProps) => {
                    return <TransactionType {...cellProps} />
                }
            },
            {
                Header: 'Transaction Value',
                accessor: 'app_transaction_value',
                filterable: true,
                Cell: (cellProps) => {
                    return <TransactionValue {...cellProps} />;
                }
            },
            // {
            //     Header: 'Action',
            //     Cell: (cellProps) => {
            //         const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
            //         return (
            //             <div className="d-flex gap-3">
            //                 {
            //                     (permissionAction.includes("system.finances.accounts.read")) ?
            //                         <button
            //                             onClick={() => {
            //                                 setApproveModal(true)
            //                                 setTransactionSingleData(cellProps.row.original)
            //                                 // history.push(`/viewaccount/${cellProps.row.original.app_account_id}?busID=${cellProps.row.original.app_business_id}`)
            //                             }} className="btn btn-info btn-sm" type='button'>Approve</button>
            //                         : <div className="text-muted">No Access</div>
            //                 }

            //                 {/* <button
            //                     onClick={() => {
            //                         history.push(`/updateTransaction/${cellProps.row.original.app_transaction_id}?Busid=${cellProps.row.original.app_business_id}`)
            //                     }} className="btn btn-success btn-sm" type='button'>Edit</button> */}
            //                 {/* <button
            //                     onClick={() => {
            //                         setApproveModal(true)
            //                         setTransactionSingleData(cellProps.row.original)
            //                         // history.push(`/viewaccount/${cellProps.row.original.app_account_id}?busID=${cellProps.row.original.app_business_id}`)
            //                     }} className="btn btn-info btn-sm" type='button'>Approve</button> */}
            //             </div>
            //         );
            //     }
            // },
        ], []
    );

    useEffect(() => {
        sessionStorage.setItem("accountListQuery", JSON.stringify({
            skip: page,
            limit: pageLimit,
            status: pageStatus,
            Busid: BusID,

        }))
    }, [page, pageLimit, pageStatus])

    const BackButton = () => {
        const { skip, limit, status, Busid } = JSON.parse(sessionStorage.getItem("accountListQuery"))
        return history.push(`/accountlist?skip=${skip}&&limit=${limit}&&status=${status}&&Busid=${null}`)
    }

    const [approveModal, setApproveModal] = useState(false);
    const [transactionSingleData, setTransactionSingleData] = useState('')

    const handleApproveCustomer = () => {
        setApproveModal(false)
        const companyValue = {
            is_active: true,
            is_approve: true
        }

        if (transactionSingleData.app_business_id) {
            let ID = transactionSingleData.app_business_id
            const token = sessionStorage.getItem('authUser')
            // axios.put(API_BASE_URL + BUSINESS_URL + "/" + ID, companyValue, getHeader(token))
            //     .then(res => {
            //         setApproveModal(false);
            //         getCompanyData()
            //     }).catch(err => {
            //         console.log(err);
            //         setApproveModal(false);
            //     })
        }

    };


    useEffect(() => {
        sessionStorage.setItem("viewaccountListQuery", JSON.stringify({
            AccountId: id,
            Busid: BusID,
            skip: page,
            limit: pageLimit
        }))
    }, [id, BusID, page, pageLimit])


    return (
        <React.Fragment>
            <div className="page-content">
                <ApproveModal
                    show={approveModal}
                    onApproveClick={handleApproveCustomer}
                    onCloseClick={() => setApproveModal(false)}
                    name={transactionSingleData}
                    title="Transaction"
                />
                <Breadcrumbs title="View Account" breadcrumbItem="Accounts" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Account ID </Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues?.app_account_keyid} />
                                                    {errors.app_account_keyid && <p style={{ color: "red" }}>This Field is required</p>}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountStatus} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Company</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues?.app_business_name} />
                                                    {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Site Name</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues?.app_department_name} />
                                                    {errors.app_department_id && <p style={{ color: "red" }}>This Field is required</p>}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Credit Limit</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + " " + parseFloat(accountValues?.credit_limit).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                                    {errors.credit_limit && <p style={{ color: "red" }}>This Field is required</p>}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Balance </Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + " " + parseFloat(accountValues?.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                                    {errors.account_balance && <p style={{ color: "red" }}>This Field is required</p>}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Oracle Site ID</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues?.oracle_site_ID} />
                                                    {errors.oracle_site_ID && <p style={{ color: "red" }}>This Field is required</p>}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Oracle Status</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={oracleCreditStatus} />
                                                    {errors.oracle_credit_status && <p style={{ color: "red" }}>This Field is required</p>}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs="12">
                                            <Card>
                                                <CardBody>
                                                    <TableContainer
                                                        columns={columns}
                                                        data={transactionDetails}
                                                        isGlobalFilter={true}
                                                        className="custom-header-css"
                                                        accountId={accountId}
                                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                                        isAddTransaction={permissions.includes("system.finances.accounts.write") ? true : false}
                                                        customPageCount={pageCount}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " ></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => { BackButton() }}>
                                                    Back
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}

export default ViewAccount

