import { takeEvery, put, call } from "redux-saga/effects"
import {
    getAttachmentSuccess, getAttachmentFail,
    postAttachmentSuccess, postAttachmentFail,
    updateAttachmentSuccess, updateAttachmentFail,
} from "./actions"

// Api Handler 
import {
    getAttachments,
    postAttachments,
    updateAttachments,
} from "helpers/backendApi_helpers"


import {
    GET_ATTACHMENT,
    POST_ATTACHMENT,
    UPDATE_ATTACHMENT
} from "./actionTypes";


function* postAttachment({ payload: payload }) {
    try {
        const response = yield call(postAttachments, payload);
        yield put(postAttachmentSuccess(response))
    } catch (error) {
        yield put(postAttachmentFail(error))
    }
}

function* getAttachment({ payload: payload }) {
    try {
        const response = yield call(getAttachments, payload);
        yield put(getAttachmentSuccess(response))
    } catch (error) {
        yield put(getAttachmentFail(error))
    }
}

function* updateAttachment({ payload: payload }) {
    try {
        const response = yield call(updateAttachments, payload);
        yield put(updateAttachmentSuccess(response))
    } catch (error) {
        yield put(updateAttachmentFail(error))
    }
}

function* AttachmentSaga() {
    yield takeEvery(POST_ATTACHMENT, postAttachment)
    yield takeEvery(GET_ATTACHMENT, getAttachment)
    yield takeEvery(UPDATE_ATTACHMENT, updateAttachment)
}

export default AttachmentSaga


    