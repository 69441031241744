import { takeEvery, put, call  } from "redux-saga/effects";
import {
    getAppRolesSuccess, getAppRolesFail,
    getAppRoleSuccess, getAppRoleFail,
    postAppRolesSuccess, postAppRolesFail,
    deleteAppRolesSuccess, deleteAppRolesFail,
    updateAppRolesSuccess, updateAppRolesFail,
} from "./action"

//Api Handler 
import {
    getAppRoles,
    postAppRoles,
    deleteAppRole,
    getAppRole,
    updateAppRoles,

} from "helpers/backendApi_helpers";

import {
        DELETE_APP_ROLES,
        GET_APP_ROLE,
        POST_APP_ROLES,
        UPDATE_APP_ROLES,
        GET_APP_ROLES
} from "./actionTypes"



function* fetchAppRoles({ payload: payload }) {
    try {
        const response = yield call(getAppRoles, payload);
        yield put(getAppRolesSuccess(response))
    } catch (error) {
        yield put(getAppRolesFail(error))
    }
}

function* postAppsRoles({ payload: payload }) {
    try {
        const response = yield call(postAppRoles, payload);
        yield put(postAppRolesSuccess(response))
    } catch (error) {
        yield put(postAppRolesFail(error))
    }
}

function* deleteAppRoles({ payload: payload }) {
    try {
        const response = yield call(deleteAppRole, payload);
        yield put(deleteAppRolesSuccess(response))
    } catch (error) {
        yield put(deleteAppRolesFail(error))
    }
}

function* getAppsRole({ payload: payload }) {
    try {
        const response = yield call(getAppRole, payload);
        yield put(getAppRoleSuccess(response))
    } catch (error) {
        yield put(getAppRoleFail(error))
    }
}

function* updateAppsRoles({ payload: payload }) {
    try {
        const response = yield call(updateAppRoles, payload);
        yield put(updateAppRolesSuccess(response))
    } catch (error) {
        yield put(updateAppRolesFail(error))
    }
}

function* AppRolesSaga() {
    yield takeEvery(GET_APP_ROLES, fetchAppRoles)
    yield takeEvery(POST_APP_ROLES, postAppsRoles)
    yield takeEvery(DELETE_APP_ROLES, deleteAppRoles)
    yield takeEvery(GET_APP_ROLE, getAppsRole)
    yield takeEvery(UPDATE_APP_ROLES, updateAppsRoles)
}

export default AppRolesSaga 