import React from "react"
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"

import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Collapse,
  Nav, NavItem, NavLink, TabContent, TabPane, CardText, Table
} from "reactstrap"

import classnames from 'classnames';
import axios from 'axios';
import getHeader from 'config/header';
import StatusModal from "components/Common/StatusModal";
import { useDispatch, useSelector } from "react-redux";
import { getPlatformActions } from "store/actions/platformActions/actions"
import { getAppSysRole, updateSysRoles } from "store/administration/portalRole/actions"
import { getSysCredentials } from "store/credentials/sysUserCredentials/actions"
import * as url from "./../../../helpers/url_helper";

function UpdateRole() {

  const { id } = useParams()

  const history = useHistory()
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues, setValue } = useForm()


  // Get Permissions
  const [permissionName, setPermissionName] = useState(null)
  const [permissions, setPermissions] = useState([])
  const [submitData, setSubmitData] = useState()

  const platformActions = useSelector(state => state.AppPlatformActions.actions);
  
  const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
  const UpadatePermission = (permissionAction.includes("system.administration.portalroles.update")) ? false : true
  const DeletePermission = (permissionAction.includes("system.administration.portalroles.delete")) ? false : true
  const [checkDisabled, setCheckDisabled] = useState(false)


  useEffect(() => {
    if (platformActions !== null) {
      setPermissionName(platformActions.app_name);
      setPermissions(platformActions.modules);
    }
  }, [platformActions]);


  //platform Action Api
  useEffect(() => {
    dispatch(getPlatformActions());
  }, [dispatch]);


  useEffect(() => {
    if (id) {
      const data = { roleID: id }
      dispatch(getAppSysRole(data))
    }
  }, [id])



  const sysRoleState = useSelector(state => state.AppSysRoles);

  const getCredentialbuIdState = useSelector(state => state.sysCredentialsReducer);

  useEffect(() => {
    if (sysRoleState.appSysRole !== null) {
      setValue("sys_role_keyid", sysRoleState.appSysRole.sys_role_keyid)
      setValue("sys_role_name", sysRoleState.appSysRole.sys_role_name)
      setValue("sys_role_description", sysRoleState.appSysRole.sys_role_description)
      if (sysRoleState.appSysRole.is_active === true) {
        setValue("is_active", "active")
      } else if (sysRoleState.appSysRole.is_active === false) {
        setValue("is_active", "inactive")
      }

      const data = { sysRoleId: id }
      dispatch(getSysCredentials(data))
    }

    if (sysRoleState.isUpdated && submitData !== null) {
      const updateCredentialData = {
        credentials: []
      }
      let dataPermissions = submitData.credential
      for (let i in dataPermissions) {
        let credentialData = {
          credential: dataPermissions[i]
        }
        updateCredentialData.credentials.push(credentialData)
      }
      const token = sessionStorage.getItem('authUser')
      axios.put(url.API_BASE_URL+url.SYS_ROLE_URL+"/"+ id +"/credentials", updateCredentialData, getHeader(token))
        .then(credRes => {
          console.log("credRes", credRes);
        }).catch(credErr => {
          console.log(credErr);
        })

      history.goBack()
    }

  }, [sysRoleState])


  useEffect(() => {
    if (getCredentialbuIdState.sysCredentials) {
      let credentialsData = getCredentialbuIdState.sysCredentials.credentials
      let dataCredUI = []
      for (let i in credentialsData) {
        dataCredUI.push(credentialsData[i].credential)
      }
      setValue("credential", dataCredUI)
      setCheckDisabled(UpadatePermission)
    }
  }, [getCredentialbuIdState])

  // Update Role
  const updateRole = async (data) => {
    setSubmitData(data)
    let value
    if (data.is_active === "active") {
      value = true
    } else if (data.is_active === "inactive") {
      value = false
    }

    const updateRoleData = {
      ...data,
      is_active: value,
    }
    delete updateRoleData.credential

    const Data = {
      sysRoleId: id,
      updatedData: updateRoleData
    }
    await dispatch(updateSysRoles(Data))

  }



  // Accordions Tab State
  const [col, setCol] = useState([])
  const [colTabs, setColTabs] = useState([])
  const [activeTab, setactiveTab] = useState("");

  const [confirmStatus, setConfirmStatus] = useState()
  const statusChange = (e) => {
    setConfirmStatus(e.target.value)
    if (e.target.value === "active") {
      setStatusTBC(true)
      setStatusModal(true)
    } else if (e.target.value === "inactive") {
      setStatusTBC(false);
      setStatusModal(true)
    }
  }

  const [statusModal, setStatusModal] = useState(false)
  const [statusTBC, setStatusTBC] = useState()


  const handleStatus = () => {
    setValue("is_active", confirmStatus)
    setStatusModal(false)
  }

  const closeClick = () => {
    const value = getValues("is_active");
    if (value === "inactive") {
      let dta = "active"
      setValue("is_active", dta)
      setStatusModal(false)
      console.log("singleValue", dta);
    } else if (value === "active") {
      let dta = "inactive"
      setValue("is_active", dta)
      setStatusModal(false)
      console.log("singleValue", dta);
    }

  }


  const [selectedPermissions, setSelectedPermissions] = useState({}); // Separate state for selected permissions

  const handleSelectAll = (index, subIndex) => {
      const selectedPermissionsCopy = { ...selectedPermissions };
      selectedPermissionsCopy[`${index}-${subIndex}`] = !selectedPermissions[`${index}-${subIndex}`];

      // Toggle all function checkboxes for the submodule
      permissions[index].submodules[subIndex].functions.forEach((_, functIndex) => {
          selectedPermissionsCopy[`${index}-${subIndex}-${functIndex}`] = selectedPermissionsCopy[`${index}-${subIndex}`];
      });

      setSelectedPermissions(selectedPermissionsCopy);
  };

  const handleFunctionCheckbox = (index, subIndex, functIndex) => {
      const selectedPermissionsCopy = { ...selectedPermissions };
      selectedPermissionsCopy[`${index}-${subIndex}-${functIndex}`] = !selectedPermissions[`${index}-${subIndex}-${functIndex}`];

      // Check if all function checkboxes for the submodule are selected, then set submodule checkbox to true
      const allFunctionsSelected = permissions[index].submodules[subIndex].functions.every(
          (_, i) => selectedPermissionsCopy[`${index}-${subIndex}-${i}`]
      );
      selectedPermissionsCopy[`${index}-${subIndex}`] = allFunctionsSelected;

      setSelectedPermissions(selectedPermissionsCopy);
  };


  return (
    <React.Fragment>
      <StatusModal
        show={statusModal}
        onDeleteClick={handleStatus}
        onCloseClick={closeClick}
        data={statusTBC}
      />
      <div className="page-content">
        <Breadcrumbs title="Update Portal Role" breadcrumbItem="Portal Role" />
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card body>
                <Form onSubmit={handleSubmit(updateRole)}>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Role ID</Label>
                      <Col lg="8" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} type="text" readOnly className='form form-control' {...register('sys_role_keyid', { required: true })} />
                        {errors.sys_role_keyid && <span style={{ color: "red" }}>This Field is required</span>}
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Role Name<span style={{ color: "red" }}>*</span></Label>
                      <Col lg="8" md="6">
                        <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} type="text" className='form form-control' {...register('sys_role_name', { required: true })} />
                        {errors.sys_role_name && <span style={{ color: "red" }}>This Field is required</span>}
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Role Description<span style={{ color: "red" }}>*</span></Label>
                      <Col lg="8" md="6">
                        <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} type="text" className='form form-control' {...register('sys_role_description', { required: true })} />
                        {errors.sys_role_description && <span style={{ color: "red" }}>This Field is required</span>}
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Permissions <span style={{ color: "steelblue" }}>[{permissionName}]</span></Label>
                      <Col lg="8" md="6">
                      <div className="accordion" id="accordion">
                                                    {permissions.map((permission, index) => {
                                                        const toggleAccordionCollapse = (index) => {
                                                            let colItems = [...col]
                                                            if (colItems[index] === null || colItems[index] === undefined || colItems[index] === false) {
                                                                for (let i in colItems) {
                                                                    colItems[i] = false
                                                                }
                                                                colItems[index] = true
                                                                setactiveTab(index + '-' + "0")
                                                            } else {
                                                                colItems[index] = false
                                                            }
                                                            setCol(colItems)
                                                        }

                                                        return (
                                                            <div key={index} className="accordion-item">
                                                                <h2 className="accordion-header" id="headingTwo">
                                                                    <button
                                                                        className={classnames(
                                                                            "accordion-button",
                                                                            "fw-medium",
                                                                            { collapsed: !col[index] }
                                                                        )}
                                                                        type="button"
                                                                        onClick={() => toggleAccordionCollapse(index)}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {permission.module_name}
                                                                    </button>
                                                                </h2>
                                                                <Collapse isOpen={col[index] ? col[index] : false} className="accordion-collapse">
                                                                    <div className="accordion-body">
                                                                        <div className="text-muted">
                                                                            <Nav tabs className="d-flex justify-content-between">
                                                                                {permission.submodules.map((subModules, subIndex) => {
                                                                                    const toggleAccordionTabs = (index, subIndex) => {
                                                                                        let activeTab = index + '-' + subIndex
                                                                                        setactiveTab(activeTab)
                                                                                    }
                                                                                    return (
                                                                                        <NavItem key={subIndex}>
                                                                                            <NavLink
                                                                                                style={{ cursor: "pointer" }}
                                                                                                className={classnames({
                                                                                                    active: activeTab === (index + '-' + subIndex),
                                                                                                })}
                                                                                                onClick={() => {
                                                                                                    toggleAccordionTabs(index, subIndex);
                                                                                                }}
                                                                                            >
                                                                                                {subModules.module_name}
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    )
                                                                                })}
                                                                            </Nav>
                                                                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                                                                {permission.submodules.map((submodules, subIndex) => {
                                                                                    return (
                                                                                        <TabPane key={subIndex} tabId={index + '-' + subIndex}>
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                <div className="mb-0">
                                                                                                        <Table striped hover>
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>#</th>
                                                                                                                    <th onClick={() => handleSelectAll(index, subIndex)}>Function Name</th>
                                                                                                                    <th onClick={() => handleSelectAll(index, subIndex)}>
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            checked={selectedPermissions[`${index}-${subIndex}`]}
                                                                                                                            // onClick={() => handleSelectAll(index, subIndex)}
                                                                                                                            value={submodules.permission} {...register(`credential`)}
                                                                                                                            disabled={checkDisabled}
                                                                                                                        />
                                                                                                                        Select All
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            {submodules.functions.map((funct, functIndex) => {
                                                                                                                return (
                                                                                                                    <tbody key={functIndex}>
                                                                                                                        <tr>
                                                                                                                            <td>{functIndex + 1}</td>
                                                                                                                            <td onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}>{funct.function_name}</td>
                                                                                                                            <td onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}>
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    checked={selectedPermissions[`${index}-${subIndex}-${functIndex}`]}
                                                                                                                                    // onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}
                                                                                                                                    value={funct.permission}  {...register(`credential`)}
                                                                                                                                    disabled={checkDisabled}
                                                                                                                                />
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                );
                                                                                                            })}
                                                                                                        </Table>
                                                                                                    </div>
                                                                                                    {/* <div className="mb-0">
                                                                                                        <Table striped hover>
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>#</th>
                                                                                                                    <th>Function Name</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            {submodules.functions.map((funct, functIndex) => {
                                                                                                                return (
                                                                                                                    <tbody key={functIndex}>
                                                                                                                        <tr>
                                                                                                                            <td>{functIndex + 1}</td>
                                                                                                                            <td>{funct.function_name}</td>
                                                                                                                            <td><input disabled={checkDisabled} type="checkbox" value={funct.permission} {...register(`credential`)} /></td>
                                                                                                                        </tr>
                                                                                                                    </tbody>)
                                                                                                            })}
                                                                                                        </Table>
                                                                                                    </div> */}
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </TabPane>)
                                                                                })}
                                                                            </TabContent>
                                                                        </div>
                                                                    </div>
                                                                </Collapse>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                      <Col lg="8" md="6">
                        <select   style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={DeletePermission}  onInput={(e) => { statusChange(e) }} className="form-select d-inline" {...register('is_active')}>
                          <option hidden value="">Select Status</option>
                          <option value="active">ACTIVE</option>
                          <option value="inactive">INACTIVE</option>
                        </select>
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                      <Col lg="8" md="6">
                        <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                          Back
                        </Button>
                        {!UpadatePermission &&<Button color='success' type='submit' className='m-2'>
                          Update
                        </Button>}
                      </Col>
                    </FormGroup>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default UpdateRole