import {
  GET_GENERIC_INDUSTRY,
  GET_GENERIC_INDUSTRY_SUCCESS,
  GET_GENERIC_INDUSTRY_FAIL,

  GET_GENERIC_INDUSTRY_ID,
  GET_GENERIC_INDUSTRY_ID_SUCCESS,
  GET_GENERIC_INDUSTRY_ID_FAIL,


  POST_GENERIC_INDUSTRY,
  POST_GENERIC_INDUSTRY_SUCCESS,
  POST_GENERIC_INDUSTRY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  genericIndustry: null,
  genericIndustryId: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  error: {},
};

const IndustryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GENERIC_INDUSTRY:
      return {
        ...state,
        loading: true,
        genericIndustry: null,
        isCreated: false,
        isDeleted: false,
      };
    case GET_GENERIC_INDUSTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        genericIndustry: action.payload,
        isCreated: false,
        isDeleted: false,
      };
    case GET_GENERIC_INDUSTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
      };
    case GET_GENERIC_INDUSTRY_ID:
      return {
        ...state,
        loading: true,
        genericIndustryId: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_GENERIC_INDUSTRY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        genericIndustryId: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_GENERIC_INDUSTRY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_GENERIC_INDUSTRY:
      return {
        ...state,
        loading: true,
        genericIndustry: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_GENERIC_INDUSTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        genericIndustry: action.payload,
        isCreated: true,
        isDeleted: false,
        isUpdated: false
      };
    case POST_GENERIC_INDUSTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default IndustryReducer;
