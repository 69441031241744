import { takeEvery, put, call } from "redux-saga/effects"
import {
  getQuotationsFail,
  getQuotationsSuccess,
  postQuotationSuccess,
  postQuotationFail,
  deleteQuotationFail,
  deleteQuotationSuccess,
  getQuotationDetailSuccess,
  getQuotationDetailFail,
  updateQuotationSuccess,
  updateQuotationFail
} from "./actions"

import {
  getAppQuotations,
  getAppQuotation,
  postAppQuotation,
  updateAppQuotation,
  deleteAppQuotation
}
  from "helpers/backendApi_helpers";

import {
  DELETE_QUOTATION,
  GET_QUOTATION,
  GET_QUOTATION_DETAIL,
  POST_QUOTATION,
  UPDATE_QUOTATION
} from "./actionTypes";

function* fetchQuotation({ payload: payload }) {
  try {
    const response = yield call(getAppQuotations, payload);
    yield put(getQuotationsSuccess(response))
  } catch (error) {
    yield put(getQuotationsFail(error))
  }
}

function* postQuotation({ payload: payload }) {
  try {
    const response = yield call(postAppQuotation, payload);
    yield put(postQuotationSuccess(response))
  } catch (error) {
    yield put(postQuotationFail(error))
  }
}

function* deleteQuotation({ payload: payload }) {
  try {
    const response = yield call(deleteAppQuotation, payload);
    yield put(deleteQuotationSuccess(response))
  } catch (error) {
    yield put(deleteQuotationFail(error))
  }
}

function* getQuotation({ payload: payload }) {
  try {
    const response = yield call(getAppQuotation, payload);
    yield put(getQuotationDetailSuccess(response))
  } catch (error) {
    yield put(getQuotationDetailFail(error))
  }
}

function* updateQuotation({ payload: payload }) {
  try {
    const response = yield call(updateAppQuotation, payload);
    yield put(updateQuotationSuccess(response))
  } catch (error) {
    yield put(updateQuotationFail(error))
  }
}

function* AppQuotationSaga() {
  yield takeEvery(GET_QUOTATION, fetchQuotation)
  yield takeEvery(POST_QUOTATION, postQuotation)
  yield takeEvery(DELETE_QUOTATION, deleteQuotation)
  yield takeEvery(GET_QUOTATION_DETAIL, getQuotation)
  yield takeEvery(UPDATE_QUOTATION, updateQuotation)
}

export default AppQuotationSaga





