import {
    GET_CONTACTS,
    GET_CONTACTS_SUCCESS,
    GET_CONTACTS_FAIL,

    GET_CONTACT,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAIL,

    GET_ALL_CONTACT,
    GET_ALL_CONTACT_SUCCESS,
    GET_ALL_CONTACT_FAIL,

    POST_CONTACT,
    POST_CONTACT_SUCCESS,
    POST_CONTACT_FAIL,

    DELETE_CONTACT,
    DELETE_CONTACT_SUCCESS,
    DELETE_CONTACT_FAIL,

    UPDATE_CONTACT,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    contacts: null,
    contact: null,
    singlecontact: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const contactsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CONTACTS:
            return {
                ...state,
                loading: true,
                contacts: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CONTACTS_SUCCESS:
            return {
                ...state,
                loading: false,
                contacts: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CONTACTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_CONTACT:
            return {
                ...state,
                loading: true,
                contacts: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contact: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_CONTACT:
            return {
                ...state,
                loading: true,
                contacts: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contact: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CONTACT:
            return {
                ...state,
                loading: true,
                singlecontact: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                singlecontact: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_CONTACT:
            return {
                ...state,
                loading: true,
                contacts: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contacts: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_CONTACT:
            return {
                ...state,
                loading: true,
                contacts: null,
                contact: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contact: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default contactsReducer;
