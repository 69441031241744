import React, { useEffect, useMemo, useState, useRef } from "react"
import {
  Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, NavItem, NavLink, Row, TabContent, TabPane,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { useForm, useFieldArray } from "react-hook-form";
import {
  AppProductLocalName, OriginalPrice, UnitPrice, Qty, Quantity, SubTotal,
  ProductType, DiscountUnitPrice, ProductID, ProductName, POSItemCode
} from './ProposalListCol';
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  getAddresses, getContactss, getDepartments, getBusinesses,
  getAttachment, getCouponStatusByInvoiceId,
  getInvoice, getQuotation, getInvoiceConfirm,
  getOpportunities, updateOpportunities, postOpportunityCloneVersion,
  getSysUserProduct, postCouponGenerate, postDownloadLog,
  getProposalVersionDetail, getProposalbyOpportunityId, postProposalCloneVersion, postProposalVersion, updateProposalVersion
} from "store/actions"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import TableContainer from "components/Common/TableContainer"
import StandardComponenet from "components/Common/PricingModelComponent/StandardComponent"
import CustomComponent from "components/Common/PricingModelComponent/CustomComponent"
import FlatComponent from "components/Common/PricingModelComponent/FlatRateComponent"
import TierComponent from "components/Common/PricingModelComponent/TierComponent"
import { Amount, ProductkeyID, CloseQuantity, Date, Remarks, Version, Quantities } from "./OpportunitiesListCol"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from "react-toastify"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import InvoicePdf from "../../../components/Common/PdfComponent/InvoicePreview"
import QuatationPdf from "../../../components/Common/PdfComponent/QuotationPreview"
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import { PDFDocument, rgb } from "pdf-lib";
// import { saveAs } from 'file-saver';
import { PDFViewer, ReactPDF, PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceDocument from "../../../components/Common/PdfComponent/InvoiceDownload"
import QuatationDocument from "../../../components/Common/PdfComponent/QuotationDownload"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ProposalCloneVersionModal from "components/Common/OpportunityModelComponent/ProposalCloneVersionModal"
import OpportunityCloneVersionModal from "components/Common/OpportunityModelComponent/OpportunityCloneVersionModal"
import ApproveSubmitInvoiceModal from "components/Common/OpportunityModelComponent/ApproveSubmitInvoiceModal";
import axios from "axios";
import { API_BASE_URL } from "helpers/url_helper";
import getHeader from "config/header";
import CouponStatusModal from "components/Common/OpportunityModelComponent/CouponStatusModal";
import pdfImage from "../../../assets/images/maxims/pdf_image.jpg"
import CouponListModal from "components/Common/CouponListModal";


import JSZip, { filter } from 'jszip';
import LogModal from "components/Common/OpportunityModelComponent/LogModal";
import RequestStatusModal from "components/Common/OpportunityModelComponent/RequestStatusModal";
import CustomAlert from "components/CustomAlert/customAlert";

let grandTotals
let grandQuantities

let grandTotalsstep3
let grandQuantitiesstep3

let flatrateFieldArrayIndex
let flatrateFieldArrayTierIndex


let step4Data = null


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const ViewOpportunities = () => {

  const { id } = useParams();

  const opportunitiesState = useSelector(state => state.AppOpportunitiesReducer)
  const [opportunityApprovedStatus, setOpportunityApprovedStatus] = useState('')

  useEffect(() => {
    if (id) {
      const data = { opportunitiesId: id }
      dispatch(getOpportunities(data))
    }
  }, [])

  useEffect(() => {
    if (opportunitiesState.opportunities !== null) {
      setOpportunityApprovedStatus(opportunitiesState.opportunities.status);
    }
  }, [opportunitiesState, id])

  const query = useQuery();
  const Tab = query.get("tab")

  const history = useHistory()
  const dispatch = useDispatch()
  document.title = "View Opportunities | B2B Maxims";

  const { setValue, register, handleSubmit, control, formState: { errors }, getValues } = useForm()
  const { setValue: setValue2, reset: reset2, register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, getValues: getValues2 } = useForm()
  const { setValue: setValue3, register: register3, handleSubmit: handleSubmit3, formState: { errors: errors3 } } = useForm()

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])


  useEffect(() => {
    toggleTab(Tab)
  }, [Tab])

  function toggleTab(tab) {
    tab = parseInt(tab)
    if (activeTab !== tab) {
      let customPassedSteps = []
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        if (tab === 4) {
          customPassedSteps = [...passedSteps, 1, 2, 3, 4]
        } else if (tab === 3) {
          customPassedSteps = [...passedSteps, 1, 2, 3]
        } else if (tab === 2) {
          customPassedSteps = [...passedSteps, 1, 2]
        } else {
          customPassedSteps = [...passedSteps, 1]
        }
        setactiveTab(tab)
        setPassedSteps(customPassedSteps)
      }
    }
  }

  // Opportunities

  const [businessList, setBusinessList] = useState([])
  const [departments, setDepartment] = useState([])
  const [contactList, setContactList] = useState([])
  const [addressesList, setAddressesList] = useState([])
  const businessesState = useSelector(state => state.businessReducer)
  const departmentState = useSelector(state => state.departmentReducer)
  const contactState = useSelector(state => state.contactsReducer)
  const addressState = useSelector(state => state.addressReducer)

  useEffect(() => {
    dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: 0, skip: 0 }))
  }, [])


  useEffect(() => {
    if (contactState.contacts !== null) {
      setContactList(contactState.contacts.result)
    }

    if (addressState.addresses !== null) {
      setAddressesList(addressState.addresses.result)
    }

    if (businessesState.businesses !== null) {
      setBusinessList(businessesState.businesses.result)
    }

    if (departmentState.departments !== null) {
      setDepartment(departmentState.departments.result)
    }
  }, [contactState, addressState, businessesState, departmentState])

  const [businessId, setBusinessId] = useState()

  useEffect(() => {
    setDepartment([])
    let companyID = businessId
    dispatch(getDepartments({ companyID, filter: `{"is_active":${true}}`, limit: 0, skip: 0 }))
  }, [businessId])


  const createOpportunites = (data) => {
    // toggleTab(2)
    history.push(`/viewOpportunities/${id}?tab=2`)
  }

  // Proposal 

  const [pricingModelList, setPricingModelList] = useState([])
  const [singlePricingModelTier, setSinglePricingModelTier] = useState()
  const [singlePricingModelFlatRate, setSinglePricingModelFlatRate] = useState()
  const [pricingModelIdList, setPricingModelIdList] = useState([])
  const [productList, setProductList] = useState([])
  const [showProductData, setShowProductData] = useState(false)
  const [opportunitieses, setOpportunitieses] = useState([])
  const businessProducts = useSelector(state => state.BusinessProducts);


  const [createProposalPage, setCreateProposalPage] = useState(false)
  const [customPage, setCustomPage] = useState(false)
  const [standardPage, setStandardPage] = useState(false)
  const [flatRatePage, setFlatRatePage] = useState(false)
  const [flatRatePage2, setFlatRatePage2] = useState(false)
  const [tierPage, setTierPage] = useState(false)
  const [tierPage2, setTierPage2] = useState(false)
  const [addGroupFlatRate, setAddGroupFlatRate] = useState(false)
  const [addGroupTier, setAddGroupTier] = useState(false)
  const pricingModelState = useSelector(state => state.PricingModelReducer)
  const [newVersion, setNewVersion] = useState(false)


  const [couponGenerate, setCouponGenerate] = useState()

  const createProposal = (data, type) => {
    if (type === "draft") {
      if (proposalId === "") {
        let newData = {
          ...data,
          grandTotals: grandTotals,
          quantities: grandQuantities,
          confirm_proposal: false,
          proposal_status: "Draft",
          opportunity_id: opportunities.opportunity_id,
          customProducts: customProductList,
          standardProducts: standardProductList,
          flatRateProducts: data.flatRateProducts,
          tierProducts: data.tierProducts,
          approval_custom_pricingmodel_status: "Pending"
        };

        dispatch(postProposalVersion(newData))
        setCreateProposalPage(false)
        setVersionListingPage(true);
      } else {
        let newData = {
          ...data,
          grandTotals: grandTotals,
          quantities: grandQuantities,
          confirm_proposal: false,
          proposal_status: "Draft",
          opportunity_id: opportunities.opportunity_id,
          customProducts: customProductList,
          standardProducts: standardProductList,
          flatRateProducts: data.flatRateProducts,
          tierProducts: data.tierProducts,
          approval_custom_pricingmodel_status: "Pending"
        };

        const updateDatas = {
          proposalId: proposalId,
          updatedData: newData
        }

        dispatch(updateProposalVersion(updateDatas))
        setCreateProposalPage(false)
        setVersionListingPage(true);
      }
    } else if (type === "confirmProposal") {


      if (proposalId === "") {
        // create proposal

        if (customProductList.length > 0) {
          let newData = {
            ...data,
            grandTotals: grandTotals,
            quantities: grandQuantities,
            confirm_proposal: false,
            proposal_status: "Waiting for Custom Approval",
            opportunity_id: opportunities.opportunity_id,
            customProducts: customProductList,
            standardProducts: standardProductList,
            flatRateProducts: data.flatRateProducts,
            tierProducts: data.tierProducts,
            approval_custom_pricingmodel_status: "Pending"
          };
          console.log("create with custom", newData);
          dispatch(postProposalVersion(newData))
          setCreateProposalPage(false)
          setVersionListingPage(true);
        } else {
          let newData = {
            ...data,
            grandTotals: grandTotals,
            quantities: grandQuantities,
            confirm_proposal: true,
            proposal_status: "Confirmed",
            opportunity_id: opportunities.opportunity_id,
            customProducts: customProductList,
            standardProducts: standardProductList,
            flatRateProducts: data.flatRateProducts,
            tierProducts: data.tierProducts,
          };

          console.log("create without custom", newData);
          dispatch(postProposalVersion(newData))
          setCreateProposalPage(false)
          setVersionListingPage(true);
        }

      } else {
        // update  proposal

        if (customProductList.length > 0) {
          let newData = {
            ...data,
            grandTotals: grandTotals,
            quantities: grandQuantities,
            confirm_proposal: false,
            proposal_status: "Waiting for Custom Approval",
            opportunity_id: opportunities.opportunity_id,
            customProducts: customProductList,
            standardProducts: standardProductList,
            flatRateProducts: data.flatRateProducts,
            tierProducts: data.tierProducts,
            approval_custom_pricingmodel_status: "Pending"
          };

          const updateDatas = {
            proposalId: proposalId,
            updatedData: newData
          }

          const Datas = {
            opportunitiesId: opportunities.opportunity_id,
            updatedData: {
              "step": 2,
              "status": "Waiting for Custom Approval"
            }
          }
          dispatch(updateOpportunities(Datas))

          dispatch(updateProposalVersion(updateDatas))
          setCreateProposalPage(false)
          setVersionListingPage(true);
        } else {
          let newData = {
            ...data,
            grandTotals: grandTotals,
            quantities: grandQuantities,
            confirm_proposal: true,
            proposal_status: "Confirmed",
            opportunity_id: opportunities.opportunity_id,
            customProducts: customProductList,
            standardProducts: standardProductList,
            flatRateProducts: data.flatRateProducts,
            tierProducts: data.tierProducts,
          };

          const updateDatas = {
            proposalId: proposalId,
            updatedData: newData
          }

          console.log("create without  custom", updateDatas);
          dispatch(updateProposalVersion(updateDatas))
          setCreateProposalPage(false)
          setVersionListingPage(true);
        }
      }
    }
    setNewVersion(false);
  }


  // Proposal Listing
  const proposalMultiColumn = useMemo(
    () => [
      {
        Header: 'No',
        accessor: (row, index) => index + 1,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Product ID',
        accessor: 'app_product_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductID datas={cellProps.row.original} {...cellProps} />;
        }
      },
      {
        Header: 'Product Name',
        accessor: 'app_product_name',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'POS Item Code ',
        accessor: 'app_product_pos_item_code',
        filterable: true,
        Cell: (cellProps) => {
          return <POSItemCode {...cellProps} />;
        }
      },
      {
        Header: 'Product Type',
        accessor: 'product_type',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductType {...cellProps} />;
        }
      },
      {
        Header: 'Unit Price',
        accessor: 'unit_price',
        filterable: true,
        Cell: (cellProps) => {
          return <UnitPrice {...cellProps} />;
        }
      },
      {
        Header: 'Qty',
        accessor: 'quantity',
        filterable: true,
        Cell: (cellProps) => {
          return <Qty {...cellProps} />;
        }
      },
      {
        Header: 'Row Total',
        accessor: 'rowTotal',
        filterable: true,
        Cell: (cellProps) => {
          return <UnitPrice {...cellProps} />;
        }
      },
      // {
      //   Header: 'Action',
      //   Cell: (cellProps) => {
      //     return (
      //       <div className="d-flex gap-3">
      //         <button onClick={() => { setCreateProposalPage(true) }} className="btn btn-success btn-sm">Create</button>
      //       </div>
      //     );
      //   }
      // },
    ], []
  );

  const proposalMultiColumnstep4 = useMemo(
    () => [
      {
        Header: 'No',
        accessor: (row, index) => index + 1,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Product ID',
        accessor: 'app_product_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductID datas={cellProps.row.original} {...cellProps} />;
        }
      },
      {
        Header: 'Product Name',
        accessor: 'app_product_name',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'POS Item Code ',
        accessor: 'app_product_pos_item_code',
        filterable: true,
        Cell: (cellProps) => {
          return <POSItemCode {...cellProps} />;
        }
      },
      {
        Header: 'Product Type',
        accessor: 'product_type',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductType {...cellProps} />;
        }
      },
      {
        Header: 'Qty',
        accessor: 'quantity',
        filterable: true,
        Cell: (cellProps) => {
          return <Qty {...cellProps} />;
        }
      },
      {
        Header: 'CSMS Coupon Status',
        Cell: (cellProps) => {
          return (
            <div className=" d-flex gap-3">
              <button
                type="button"
                onClick={() => {
                  setrequestStatusModal(true);
                  // console.log(cellProps.row.original.request_id);
                  setPassRequestModalData(cellProps.row.original.request_id)
                }}
                className="btn btn-warning btn-sm"
              >
                CSMS  Status
              </button>
              {/* <button
                type="button"
                onClick={() => {
                  setCouponStatusModal(true);
                  // console.log(cellProps.row.original.request_id);
                  setPassRequestModalData(cellProps.row.original.request_id)
                }}
                className="btn btn-warning btn-sm"
              >
                Coupon Status
              </button> */}
            </div>

          );
        }
      },


      {
        Header: 'Actions',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">

              {/* <button className="btn btn-info btn-sm" type="button" onClick={exportCSV}>Generate</button> */}
              <button className="btn btn-info btn-sm" type="button"
                onClick={() => {
                  postcoupongenerate(cellProps.row.original)
                  couponGenerateLog(cellProps.row.original)
                  setIsModalOpen(true);
                }}>
                Generate
                {/* Generate {(couponGenerate?.request_id == cellProps.row.original.request_id) ? couponGenerate?.job_status : ""} */}
              </button>
              {/* <button className="btn btn-danger btn-sm" type="button"
                onClick={() => {
                  dispatch(getCouponGenerate(cellProps.row.original.request_id))
                }} >
                <i className='mdi mdi-refresh' > </i></button> */}
              <button type="button"
                onClick={() => {
                  setLogModal(true);
                  // handleLogDetails();
                  setRequestId(cellProps.row.original?.request_id)
                }}
                className="btn btn-success btn-sm">Log</button>
            </div>
          );
        }
      },
    ], []
  );


  const invoiceState = useSelector(state => state.AppInvoiceReducer)

  const [opportunities, setOpportunities] = useState({})

  const navigationRef = useRef(false);

  useEffect(() => {
    if (!navigationRef.current && opportunitiesState.opportunities !== null && businessList.length > 0) {
      history.push(`/viewOpportunities/${id}?tab=${opportunitiesState.opportunities.step}`)
      navigationRef.current = true;
    }
  }, [opportunitiesState, businessList])

  useEffect(() => {
    if (opportunitiesState.opportunities !== null && businessList.length > 0) {
      // console.log("opportunitiesState.opportunities ", opportunitiesState.opportunities);
      setOpportunities(opportunitiesState.opportunities)
      setValue("app_business_id", opportunitiesState.opportunities.app_business_id)
      setBusinessId(opportunitiesState.opportunities.app_business_id)
    }
    if (opportunitiesState.opportunities !== null && businessList.length > 0 && departments.length > 0) {
      setValue("app_department_id", opportunitiesState.opportunities.app_department_id)
    }
  }, [opportunitiesState, businessList, departments, businessId])

  useEffect(() => {
    if (opportunities.app_business_id && opportunities.app_department_id && activeTab === 2) {
      let departmentID = opportunities.app_department_id
      let companyID = opportunities.app_business_id
      dispatch(getContactss({ companyID, filter: `{"is_active":${true},"app_department_id":"${departmentID}"}`, limit: 0, skip: 0 }));
      dispatch(getAddresses({ companyID, filter: `{"is_active":${true},"app_department_id":"${departmentID}"}`, limit: 0, skip: 0 }));
    }
  }, [opportunities, activeTab])


  ///        sys user Product              /// 

  const [sysUserProductList, setSysUserProductList] = useState()
  const [standardProductList, setStandardProductList] = useState([])
  const [customProductList, setCustomProductList] = useState([])
  const [flatRateProductList, setFlatRateProductList] = useState()
  const [tierProductList, setTierProductList] = useState()

  const sysUserProductState = useSelector(state => state.AppsysUserProductReducer)

  useEffect(() => {
    if (activeTab === 2) {
      dispatch(getSysUserProduct())
    }
  }, [activeTab])


  useEffect(() => {
    if (sysUserProductState.sysUserProducts !== null) {
      setSysUserProductList(sysUserProductState.sysUserProducts.result);
    }
  }, [sysUserProductState])


  const [versionListingPage, setVersionListingPage] = useState(true)
  const [versionListingPageTable, setVersionListingPageTable] = useState(false)
  const [quotationPage, setQuotationPage] = useState(false)
  const [invoicePage, setInvoicePage] = useState(false)

  //====proposal version list table ==========================//

  const [versionList, setVersionList] = useState([]);
  const [quotId, setQuotId] = useState('');
  const [invId, setInvId] = useState('');
  const [confirmProposalCloneVersionModal, setConfirmProposalCloneVersionModal] = useState(false);
  const [confirmOpportunityCloneVersionModal, setConfirmOpportunityCloneVersionModal] = useState(false);
  const [proposalCloneData, setProposalCloneData] = useState("");
  const [opportunityCloneData, setOpportunityCloneData] = useState('')


  const [proposalId, setProposalId] = useState("")

  const versionColumn = useMemo(
    () => [
      {
        Header: 'Proposal ID',
        accessor: 'proposal_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductkeyID  {...cellProps} />;
        }
      },
      {
        Header: 'Version',
        accessor: 'proposal_version',
        filterable: true,
        Cell: (cellProps) => {
          return <Version statusdata={cellProps.row.original} {...cellProps} />;
        }
      },
      {
        Header: 'Date',
        accessor: 'proposal_date',
        filterable: true,
        Cell: (cellProps) => {
          return <Date  {...cellProps} />;
        }
      },
      {
        Header: 'Quantities ',
        accessor: 'quantities',
        filterable: true,
        Cell: (cellProps) => {
          return <Quantities {...cellProps} />;
        }
      },
      {
        Header: 'Amount',
        accessor: 'grandTotals',
        filterable: true,
        Cell: (cellProps) => {
          return <Amount {...cellProps} />;
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">

              {opportunityApprovedStatus === "Pending Approval" ||
                opportunityApprovedStatus === "Approved" ||
                opportunityApprovedStatus === "Cancel" ||
                opportunityApprovedStatus === "Rejected" ? (
                <button onClick={() => {
                  setStandardProductList([]);
                  setCustomProductList([]);
                  setFlatRateProductList();
                  setTierProductList();
                  setSelectedBillingAddressId();
                  setSelectedDeliveryAddressId();
                  dispatch(getProposalVersionDetail({ proposalId: cellProps.row.original.proposal_id }));
                  setVersionListingPage(false);
                  setCreateProposalPage(false);
                  setProposalId(cellProps.row.original.proposal_id);
                }} className="btn btn-success">
                  View
                </button>
              ) : (
                <button onClick={() => {
                  setStandardProductList([]);
                  setCustomProductList([]);
                  setFlatRateProductList();
                  setTierProductList();
                  setSelectedBillingAddressId();
                  setSelectedDeliveryAddressId();
                  dispatch(getProposalVersionDetail({ proposalId: cellProps.row.original.proposal_id }));
                  setVersionListingPage(false);
                  setCreateProposalPage(false);
                  setProposalId(cellProps.row.original.proposal_id);
                }} className={cellProps.row.original.proposal_status === "Confirmed" ||
                  cellProps.row.original.proposal_status === "Waiting for Custom Approval" ? "btn btn-success btn-sm" : "btn btn-info btn-sm"} >
                  {cellProps.row.original.proposal_status === "Confirmed" ||
                    cellProps.row.original.proposal_status === "Waiting for Custom Approval" ||
                    cellProps.row.original.proposal_status === "Rejected" ? "View" : "Edit"}
                </button>
              )}


              {cellProps.row.original.confirm_proposal &&
                <button onClick={() => {
                  setQuotId(cellProps.row.original.quotation_id)
                  let quotationId = cellProps.row.original.quotation_id;
                  const data = { quotationId }
                  dispatch(getQuotation(data))
                  setQuotationPage(true);
                  setVersionListingPage(true);
                  setVersionListingPageTable(true);
                }}
                  className="btn btn-warning btn-sm">Quotation</button>
              }

              {cellProps.row.original.confirm_proposal &&
                <button onClick={() => {
                  setInvId(cellProps.row.original.invoice_id)
                  let invoiceId = cellProps.row.original.invoice_id;
                  const data = { invoiceId }
                  dispatch(getInvoice(data))
                  setInvoicePage(true);
                  setVersionListingPage(true);
                  setVersionListingPageTable(true);
                }}
                  className="btn btn-dark btn-sm">Invoice</button>

              }



              {opportunityApprovedStatus !== "Pending Approval" &&
                opportunityApprovedStatus !== "Approved" &&
                opportunityApprovedStatus !== "Cancel" &&
                opportunityApprovedStatus !== "Rejected" && (
                  <button
                    onClick={() => {
                      const proposalClone = cellProps.row.original;
                      setProposalCloneData(proposalClone);
                      setConfirmProposalCloneVersionModal(true);
                    }}
                    className="btn btn-secondary btn-sm">
                    Clone Version
                  </button>
                )}



              <button onClick={() => {
                const opportunityClone = cellProps.row.original;
                setOpportunityCloneData(opportunityClone);
                setConfirmOpportunityCloneVersionModal(true)
              }} className="btn btn-secondary btn-sm">Clone Opportunity</button>

              {/* <button onClick={() => {
                dispatch(postOpportunityCloneVersion(cellProps.row.original.opportunity_id))
              }} className="btn btn-secondary btn-sm">Clone Opportunity</button> */}

            </div>
          );
        }
      },
    ],
    [versionList, quotId, invId]
  );

  //============proposal version by opportunity id global search filter ===================//

  const proposalVersionState = useSelector(state => state.ProposalVersionReducer);
  const [customFilterData, setCustomFilterData] = useState("");


  useEffect(() => {
    if (activeTab === 2) {
      handlePageClick()
    }
  }, [activeTab, customFilterData, opportunities, invoiceState])


  useEffect(() => {
    if (customFilterData.length > 0) {
      history.push('/viewOpportunities/' + id)
    }
  }, [customFilterData])



  const handlePageClick = (index) => {
    let opportunity_id = id;
    let filter = `{"$or":[
        {"proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}},
        {"version":{"$regex":"${customFilterData}","$options":"i"}},
        {"amount":{"$regex":"${customFilterData}","$options":"i"}},
        {"quantities":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`
    dispatch(getProposalbyOpportunityId({ opportunity_id, filter }))
  }

  const ProposalCloneVersionState = useSelector(state => state.ProposalCloneVersionReducer)
  const OpportunityCloneVersionState = useSelector(state => state.OpportunityCloneVersionReducer)
  const [confirmedInvoiceBtn, setConfirmedInvoiceBtn] = useState(false)

  useEffect(() => {
    if (proposalVersionState.Proposalbyopportunityid !== null && activeTab === 2) {
      let booleanCheck = proposalVersionState.Proposalbyopportunityid.result.map((data) => {
        return data.invoice_status == "Confirmed"
      })
      if (booleanCheck.includes(true)) {
        setConfirmedInvoiceBtn(true);
      } else {
        setConfirmedInvoiceBtn(false);
      }
    }
  }, [proposalVersionState, invoiceState])

  useEffect(() => {
    if (proposalVersionState.Proposalbyopportunityid !== null && activeTab === 2) {
      setVersionList(proposalVersionState.Proposalbyopportunityid.result);
    }

    if (proposalVersionState.isCreated && opportunities.opportunity_id && activeTab === 2 && ProposalCloneVersionState) {
      handlePageClick()
    }

    if (proposalVersionState.isUpdated && opportunities.opportunity_id) {
      handlePageClick()
    }

    if (ProposalCloneVersionState.isCreated === true) {
      handlePageClick()
      toast.success("Successfully create Proposal clone")
      ProposalCloneVersionState.isCreated = false
    }

    if (OpportunityCloneVersionState.isCreated === true) {
      handlePageClick()
      toast.success("Successfully create opportunity clone")
      OpportunityCloneVersionState.isCreated = false
    }

  }, [proposalVersionState, activeTab, opportunities, ProposalCloneVersionState, OpportunityCloneVersionState])


  useEffect(() => {
    if (proposalVersionState.Proposalversion !== null && activeTab === 2) {
      // console.log("aa", proposalVersionState.Proposalversion);
      setValue("opportunities_remarks", proposalVersionState?.Proposalversion?.opportunities_remarks)
      setValue("app_billing_contact_id", proposalVersionState?.Proposalversion?.app_billing_contact_id)
      setValue("app_delivery_contact_id", proposalVersionState?.Proposalversion?.app_delivery_contact_id)
      setSelectedDeliveryAddressId(proposalVersionState?.Proposalversion?.app_delivery_address_id)
      setSelectedBillingAddressId(proposalVersionState?.Proposalversion?.app_billing_address_id)
      setValue("app_delivery_address_id", proposalVersionState?.Proposalversion?.app_delivery_address_id)
      setValue("app_billing_address_id", proposalVersionState?.Proposalversion?.app_billing_address_id)
      setStandardProductList(proposalVersionState?.Proposalversion?.standardProducts)
      setCustomProductList(proposalVersionState?.Proposalversion?.customProducts)
      if (proposalVersionState?.Proposalversion?.flatRateProducts2) {
        setAddGroupFlatRate(true)
      }
      if (proposalVersionState?.Proposalversion?.tierProducts2) {
        setAddGroupTier(true)
      }

      // setFlatRateProductList(proposalVersionState?.Proposalversion?.flatRateProducts)
      setValue("flatRateProducts", proposalVersionState?.Proposalversion?.flatRateProducts)
      // setTierProductList(proposalVersionState?.Proposalversion?.tierProducts)
      setValue("tierProducts", proposalVersionState?.Proposalversion?.tierProducts)


    }
  }, [proposalVersionState])



  const handleDataSelectionforStandard = (updatedData) => {
    setStandardProductList(updatedData)
  };

  const handleDataSelectionforCustom = (updatedData) => {
    setCustomProductList(updatedData)
  };

  const handleDataSelectionforFlatRate = (updatedData) => {
    let oldFieldData = getValues("flatRateProducts")
    oldFieldData[flatrateFieldArrayIndex] = updatedData
    // setFlatRateProductList(updatedData)
    setFlatRateProductList(null)
    setValue("flatRateProducts", oldFieldData)
    flatrateFieldArrayIndex = -1
    return
  };



  const handleDataSelectionforTier = (updatedData) => {
    let oldFieldData = getValues("tierProducts")
    oldFieldData[flatrateFieldArrayTierIndex] = updatedData
    // setFlatRateProductList(updatedData)
    setTierProductList(null)
    setValue("tierProducts", oldFieldData)
    flatrateFieldArrayTierIndex = -1
    return
  };


  useEffect(() => {
    if (pricingModelState.pricingmodel !== null && pricingModelState.pricingmodel.tiers.length > 0) {
      setSinglePricingModelTier(pricingModelState.pricingmodel)
    }
    if (pricingModelState.pricingmodel !== null) {
      setSinglePricingModelFlatRate(pricingModelState.pricingmodel)
    }
  }, [pricingModelState])

  const displayTierValues = (tierProductList) => {
    const tiers = tierProductList?.tiers;

    const tierValues = tiers?.map((tier, index) => {
      const startRange = index === 0 ? 1 : parseInt(tiers[index - 1]?.app_tier_qualifying_input || 0) + 1;
      const endRange = index === tiers.length - 1 ? "Infinity" : tier.app_tier_qualifying_input;
      return (
        <div key={tier.localID}>
          <span style={{ fontSize: "15px" }}>
            Qualifying Value: {startRange} - {endRange}: Discount: {tier.app_tier_discount_value}
          </span>
        </div>
      );
    });
    return tierValues;
  }

  //===========================get Quotation data====================================//
  const [quotationData, setQuotationData] = useState();
  const appQuotationReducerState = useSelector(state => state.AppQuotationReducer);


  useEffect(() => {
    if (appQuotationReducerState.Quotation !== null) {
      setQuotationData(appQuotationReducerState.Quotation);
    }

  }, [appQuotationReducerState])

  //===========================get Invoice data =====================================//
  const [invoiceData, setInvoiceData] = useState();
  const appInvoiceReducerState = useSelector(state => state.AppInvoiceReducer);



  useEffect(() => {
    if (appInvoiceReducerState.invoice !== null) {
      setInvoiceData(appInvoiceReducerState.invoice)
    }

  }, [appInvoiceReducerState])



  const getGrandTotal = (standardProductList, customProductList, flatRateProductList, tierProductList) => {
    let total1 = 0;
    standardProductList?.forEach(item => total1 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total2 = 0;
    customProductList?.forEach(item => total2 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total3 = 0;
    flatRateProductList?.forEach(a => a?.flatRateList?.forEach(b => total3 += parseFloat(b.rowTotal ? b.rowTotal : 0)))

    let total4 = 0;
    tierProductList?.forEach(a => a?.tierList?.forEach(b => total4 += parseFloat(b.rowTotal ? b.rowTotal : 0)))

    let grandTotal = total1 + total2 + total3 + total4;

    grandTotals = parseFloat(grandTotal).toFixed(1)
    return parseFloat(grandTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }

  const getGrandTotalstep3 = (standardProductList, customProductList, flatRateProductList, tierProductList) => {
    let total1 = 0;
    standardProductList?.forEach(item => total1 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total2 = 0;
    customProductList?.forEach(item => total2 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total3 = 0;
    flatRateProductList?.forEach(a => a?.flatRateList?.forEach(b => total3 += parseFloat(b.rowTotal ? b.rowTotal : 0)))

    let total4 = 0;
    tierProductList?.forEach(a => a?.tierList?.forEach(b => total4 += parseFloat(b.rowTotal ? b.rowTotal : 0)))

    let grandTotal = total1 + total2 + total3 + total4;

    grandTotalsstep3 = parseFloat(grandTotal).toFixed(1)
    return parseFloat(grandTotalsstep3).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }

  const getGrandTotalQuantity = (standardProductList, customProductList, flatRateProductList, tierProductList,) => {
    let total1 = 0;
    standardProductList?.forEach(item => total1 += parseFloat(item.quantity ? item.quantity : 0));

    let total2 = 0;
    customProductList?.forEach(item => total2 += parseFloat(item.quantity ? item.quantity : 0));

    let total3 = 0;
    flatRateProductList?.forEach(a => a?.flatRateList?.forEach(b => total3 += parseFloat(b.quantity ? b.quantity : 0)))

    let total4 = 0;
    tierProductList?.forEach(a => a?.tierList?.forEach(b => total4 += parseFloat(b.quantity ? b.quantity : 0)))

    let grandTotalQty = total1 + total2 + total3 + total4;

    grandQuantities = grandTotalQty

    return parseFloat(grandQuantities).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }

  const getGrandTotalQuantitystep3 = (standardProductList, customProductList, flatRateProductList, tierProductList,) => {
    let total1 = 0;
    standardProductList?.forEach(item => total1 += parseFloat(item.quantity ? item.quantity : 0));

    let total2 = 0;
    customProductList?.forEach(item => total2 += parseFloat(item.quantity ? item.quantity : 0));

    let total3 = 0;
    flatRateProductList?.forEach(a => a?.flatRateList?.forEach(b => total3 += parseFloat(b.quantity ? b.quantity : 0)))

    let total4 = 0;
    tierProductList?.forEach(a => a?.tierList?.forEach(b => total4 += parseFloat(b.quantity ? b.quantity : 0)))

    let grandTotalQty = total1 + total2 + total3 + total4;

    grandQuantitiesstep3 = grandTotalQty

    return parseFloat(grandQuantitiesstep3).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }



  const [confirmedProposal, setConfirmedProposal] = useState(null);

  useEffect(() => {
    if ((activeTab == 3) || (activeTab == 4)) {
      if (id) {
        const data = { opportunityId: id };
        dispatch(getInvoiceConfirm(data))
      }
    }

  }, [activeTab])


  useEffect(() => {
    if (invoiceState.invoice !== null &&
      invoiceState.invoice !== undefined
    ) {

      setImages([])
      setConfirmedProposal(invoiceState?.invoice)
      setValue("flatRateProducts", invoiceState.invoice?.flatRateProducts)
      setValue("tierProducts", invoiceState.invoice?.tierProducts)

      const token = sessionStorage.getItem('authUser')

      invoiceState?.invoice?.attachment_file?.map((file) => {
        axios.get(API_BASE_URL + "/filestorage/getbyid" + "/" + file?.file_id, getHeader(token))
          .then(res => {
            const imageData = res.data.image_buffer;
            const mime_type = res.data.mime_type;
            setImages(prevImages => [...prevImages, { imgSrc: `data:${mime_type};base64,${imageData}` }]);
            // src={`data:image/jpeg;base64,${data}`}
          }).catch(err => {
            console.log(err);
          })
      })
    }
  }, [invoiceState])


  //==================billing address ========================================================//  

  const [selectedBillingAddressId, setSelectedBillingAddressId] = useState("");
  const [selectedBillingAddress, setSelectedBillingAddress] = useState("");

  const handleTextBillingAddress = (e) => {
    const selectedBillingAddressId = e.target.value;
    const address = addressesList.find(address => address.app_address_id === selectedBillingAddressId);
    const billingAddressTextarea = document.getElementById("billingAddressTextarea");
    if (billingAddressTextarea) {
      if (address) {
        billingAddressTextarea.value = address.app_billing_address;
        setSelectedBillingAddress(address.app_billing_address);
      } else {
        billingAddressTextarea.value = "";
        setSelectedBillingAddress("");
      }
    }
  }

  useEffect(() => {
    handleTextBillingAddress({ target: { value: selectedBillingAddressId } });
  }, [selectedBillingAddressId]);

  // ===================================delivery address===========================================

  const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] = useState("");
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState("");

  const handleTextDeliveryAddress = (e) => {
    const selectedDeliveryAddressId = e.target.value;
    const address = addressesList.find(address => address.app_address_id === selectedDeliveryAddressId);
    const deliveryAddressTextarea = document.getElementById("billingAddressTextarea");
    if (deliveryAddressTextarea) {
      if (address) {
        deliveryAddressTextarea.value = address.app_delivery_address;
        setSelectedDeliveryAddress(address.app_delivery_address);
      } else {
        deliveryAddressTextarea.value = "";
        setSelectedDeliveryAddress("");
      }
    }
  }

  useEffect(() => {
    handleTextDeliveryAddress({ target: { value: selectedDeliveryAddressId } });
  }, [selectedDeliveryAddressId]);



  // const handleTextDeliveryAddress = (e) => {
  //   const selectedBillingAddressId = e.target.value;
  //   const selectedBillingAddress = addressesList.find(address => address.app_address_id === selectedBillingAddressId);
  //   document.getElementById("deliveryAddressTextarea").value = selectedBillingAddress ? selectedBillingAddress.app_delivery_address : '';
  // }

  const handleConfirmProposalClone = () => {
    setConfirmProposalCloneVersionModal(false)
    dispatch(postProposalCloneVersion(proposalCloneData.proposal_id))
  };


  const handleConfirmOpportunityClone = () => {
    setConfirmOpportunityCloneVersionModal(false)
    let data = {
      proposal_id: opportunityCloneData.proposal_id
    }
    dispatch(postOpportunityCloneVersion({ opportunityId: opportunityCloneData.opportunity_id, data }))

  }


  const { fields, append, remove } = useFieldArray({
    control,
    name: "flatRateProducts",
  });

  const { fields: fieldsTier, append: appendTier, remove: removeTier } = useFieldArray({
    control,
    name: "tierProducts"
  });


  const { fields: fieldsStep4, append: appendStep4, remove: removeStep4 } = useFieldArray({
    control,
    name: "flatRateProductsStep4",
  });

  const { fields: fieldsTierStep4, append: appendTierStep4, remove: removeTierStep4 } = useFieldArray({
    control,
    name: "tierProductsStep4"
  });




  const backButton = () => {
    const { skip, limit, opportunityStatus } = JSON.parse(sessionStorage.getItem("opportunityListQuery"))
    history.push(`/opportunities?skip=${skip}&&limit=${limit}&&opportunityStatus=${opportunityStatus}`)
  }

  const [submitApprovalInvoice, setSubmitApprovalInvoice] = useState(false);


  const [submittedData, setSubmittedData] = useState()

  const handleApprovalInvoice = (dta) => {

    $('#submitApproval').attr("disabled", true);
    setSubmitApprovalInvoice(true);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append(`attach_file`, file);
    });

    const token = sessionStorage.getItem('authUser');
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    };
    
    if (files.length > 0) {
      axios.post(API_BASE_URL + "/filestorage", formData, config)
        .then(res => {

          let confirmedVersionData = {
            ...confirmedProposal,
            invoice_approval_status: "Pending",
            finalize_proposal_date: dta.finalize_proposal_date,
            payment_notes: dta.payment_notes,
            attachment_file: res.data.files_uploaded
          };
          setSubmittedData(confirmedVersionData)

        }).catch(err => {
          console.log(err);
        });
    } else {
      toast.error("please upload one or more files")
    }
  }



  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([])
  const [removeButton, setRemoveButton] = useState(false)

  // const handleChange = (e) => {
  //   const selectedFiles = e.target.files;
  //   setFiles([...files, ...selectedFiles]);

  //   let imageData = e.target.files
  //   const len = imageData.length
  //   for (var i = 0; i < len; i++) {
  //     var imageBlob = imageData[i];
  //     var fileReader = new FileReader()  //1
  //     fileReader.onload = ({ target }) => {  // 3
  //       setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
  //     }
  //     fileReader.readAsDataURL(imageBlob) //2
  //   }
  // }

  const [attachmentSize, setAttachmentSize] = useState("");

  const MAX_FILE_SIZE = attachmentSize.maxSize * 1024 * 1024;
  const MIN_FILE_SIZE = attachmentSize.minSize * 1024 * 1024;
  const fileInputRef = useRef(null);
  const handleChange = (e) => {

    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(file => {
      return file.size >= MIN_FILE_SIZE && file.size <= MAX_FILE_SIZE;
    });

    const validFilessss = selectedFiles.filter(file => {
      if (file.size < MIN_FILE_SIZE || file.size > MAX_FILE_SIZE) {
        alert(`File Size should be ${MIN_FILE_SIZE / (1024 * 1024)} - ${MAX_FILE_SIZE / (1024 * 1024)} MB and were not added.`);
        return false;
      }
      return true;
    });


    setFiles([...files, ...validFiles]);

    validFiles.forEach((file, index) => {
      const fileReader = new FileReader();
      fileReader.onload = ({ target }) => {
        setImages(prevState => ([...prevState, { seq_no: index, imgSrc: target.result }]));
      };
      fileReader.readAsDataURL(file);
    });
    if (validFiles.length === 0) {
      fileInputRef.current.value = '';
    }
  };


  const removeBtnClick = (i) => {
    let newImages = images.map(item => item)
    let newFiles = files.map(item => item)
    newImages.splice(i, 1)
    setImages(newImages)
    newFiles.splice(i, 1)
    setFiles(newFiles)
    if (newFiles.length === 0) {
      fileInputRef.current.value = '';
    }
  }

  // const openImage = (e) => {
  //   let data = e.target.currentSrc;
  //   let w = window.open('about:blank');
  //   let image = new Image();
  //   image.src = data;
  //   setTimeout(function () {
  //     w.document.write(image.outerHTML);
  //   }, 0);
  // }


  // const openImage = (image) => {
  //   let data = image.imgSrc;
  //   let w = window.open('about:blank');
  //   let embed = document.createElement('embed');
  //   embed.src = data;
  //   embed.type = 'application/pdf';
  //   embed.style.width = '100%';
  //   embed.style.height = '100%';
  //   w.document.write(embed.outerHTML);
  // }

  const openImage = (image) => {
    let data = image.imgSrc;
    let w = window.open('about:blank');
    let iframe = document.createElement('iframe');
    iframe.src = data;
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    w.document.body.style.margin = '0';
    w.document.body.style.height = '100vh';
    w.document.body.appendChild(iframe);
  }


  const [couponListModal, setCouponListModal] = useState(false)
  const [couponList, setCouponList] = useState([])

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 9000) + 1000;
  };

  const exportCSV = () => {
    const csvData = getCsvData();
    const zip = new JSZip();
    zip.file('coupons.csv', csvData);
    zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })
      .then(function (content) {
        saveAs(content, 'coupons.zip');
      });

    const number = generateRandomNumber();
    const data = {
      "requestId": "ZSOQTQSY2KFY1YN",
      password: number,
      unique_download_log_id: generateRandomNumber()
    }

    console.log("data", data);
    // dispatch(postDownloadLog(data))

  };

  const getCsvData = () => {
    const headers = Object.keys(couponList[0]).join(',');
    const rows = couponList.map(coupon => Object.values(coupon).join(','));
    return headers + '\n' + rows.join('\n');
  };




  const [couponStatusModal, setCouponStatusModal] = useState(false);
  const [requestStatusModal, setrequestStatusModal] = useState(false);
  const [logModal, setLogModal] = useState(false);

  const attachmentstate = useSelector(state => state.AttachmentReducer);

  useEffect(() => {
    dispatch(getAttachment())
  }, [])

  const [attachmentarray, setAttachmentarray] = useState([]);

  useEffect(() => {
    if (attachmentstate.attachment !== null) {
      setAttachmentarray(attachmentstate?.attachment?.extension);
      setAttachmentSize(attachmentstate?.attachment)
    }
  }, [attachmentstate])




  //   step 4   

  useEffect(() => {
    if (activeTab == "4") {
      if (confirmedProposal?.invoice_id) {
        let value = {
          "invoiceId": confirmedProposal?.invoice_id
        }
        dispatch(getCouponStatusByInvoiceId(value))
      }
    }

  }, [confirmedProposal?.invoice_id, activeTab])


  const couponStatusState = useSelector(state => state.CouponReducer)

  // const [step4Data, setStep4Data] = useState(null)

  const [passRequestModalData, setPassRequestModalData] = useState(null)

  // useEffect(() => {
  //   console.log("passRequestModalData", passRequestModalData);
  // }, [passRequestModalData])

  useEffect(() => {
    if (couponStatusState !== null && (activeTab == "3" || activeTab == "4")) {
      let status = couponStatusState?.getcouponstatusbyinvoiceid?.result
      // console.log("status", status);
      const standardProducts = status?.filter(product => product.pricing_model_type === "standard");
      const customProducts = status?.filter(product => product.pricing_model_type === "custom");
      const flatrateProducts = status?.filter(product => product.pricing_model_type === "flatrate");
      const tierProducts = status?.filter(product => product.pricing_model_type === "tier");

      // console.log("standardProducts", standardProducts);
      // console.log("customProducts", customProducts);
      // console.log("flatrateProducts", flatrateProducts);
      // console.log("tierProducts", tierProducts);


      // console.log("ConfirmedProposal", confirmedProposal);

      let duplicateConfirmedProposal = confirmedProposal



      //////////////////////////////                standard                                /////////////////////////////////

      function standardPushMatchingItems(standardProducts, items) {
        // Ensure duplicateConfirmedProposal is defined
        if (!duplicateConfirmedProposal) {
          return [];
        }

        standardProducts?.forEach(standardProduct => {
          items?.forEach(item => {
            if (standardProduct?.app_product_id === item?.app_product_id) {
              let matchedProduct = items.find(dp => dp.app_product_id === standardProduct.app_product_id);

              duplicateConfirmedProposal?.standardProducts.push(matchedProduct);
            }
          });
        });

        return duplicateConfirmedProposal.standardProducts.flat();
      }

      const standardMatchedItem = standardPushMatchingItems(duplicateConfirmedProposal?.standardProducts, standardProducts);

      const standardMergedData = standardMatchedItem?.reduce((acc, obj) => {
        // Find existing object with the same app_product_id
        let existingObj = acc.find(item => item.app_product_id === obj.app_product_id);
        if (existingObj) {
          Object.assign(existingObj, obj);    // Merge properties
        } else {
          acc.push({ ...obj });    // Add new object if no existing object found
        }
        return acc;
      }, []);


      ////////////////////////////                   custom                                    ///////////////////////


      function customPushMatchingItems(customProducts, items) {
        // Ensure duplicateConfirmedProposal is defined
        if (!duplicateConfirmedProposal) {
          return [];
        }

        customProducts?.forEach(customProduct => {
          items?.forEach(item => {
            if (customProduct?.app_product_id === item?.app_product_id) {
              let matchedProduct = items.find(dp => dp.app_product_id === customProduct.app_product_id);

              duplicateConfirmedProposal?.customProducts.push(matchedProduct);
            }
          });
        });

        return duplicateConfirmedProposal.customProducts.flat();
      }

      const customMatchedItem = customPushMatchingItems(duplicateConfirmedProposal?.customProducts, customProducts);

      const customMergedData = customMatchedItem?.reduce((acc, obj) => {
        // Find existing object with the same app_product_id
        let existingObj = acc.find(item => item.app_product_id === obj.app_product_id);
        if (existingObj) {
          Object.assign(existingObj, obj);    // Merge properties
        } else {
          acc.push({ ...obj });    // Add new object if no existing object found
        }
        return acc;
      }, []);


      //////////////////////////                    Flat Rate                                   ////////////////////////

      function flatRatePushMatchingItems(flatRateProducts, items) {
        if (!duplicateConfirmedProposal) {
          return [];
        }

        flatRateProducts?.forEach((flatRateProduct, i) => {
          flatRateProduct?.flatRateList?.forEach((flatRateItem, j) => {
            items?.forEach(item => {
              if (flatRateItem?.app_product_id === item?.app_product_id) {
                let matchedProduct = items?.find(dp => dp.app_product_id === flatRateItem.app_product_id);
                if (matchedProduct) {
                  matchedProduct = { ...flatRateItem, ...matchedProduct };
                  duplicateConfirmedProposal.flatRateProducts[i].flatRateList[j] = matchedProduct
                }
              }
            });
          });
        });

        return duplicateConfirmedProposal
      }

      const flatRateMatchedItem = flatRatePushMatchingItems(duplicateConfirmedProposal?.flatRateProducts, flatrateProducts);

      /////////////////////////                      Tier                                          ///////////////////////


      function tierPushMatchingItems(tierProducts, items) {
        // Ensure duplicateConfirmedProposal is defined
        if (!duplicateConfirmedProposal) {
          return [];
        }

        tierProducts?.forEach((tierProduct, i) => {
          tierProduct?.tierList?.forEach((tierItem, j) => {
            items?.forEach(item => {
              if (tierItem?.app_product_id === item?.app_product_id) {
                let matchedProduct = items.find(dp => dp.app_product_id === tierItem.app_product_id);
                if (matchedProduct) {
                  matchedProduct = { ...tierItem, ...matchedProduct };
                  duplicateConfirmedProposal.tierProducts[i].tierList[j] = matchedProduct
                }
              }
            });
          });
        });

        return duplicateConfirmedProposal
      }

      const tierMatchedItem = tierPushMatchingItems(duplicateConfirmedProposal?.tierProducts, tierProducts);

      // console.log("standardfinal", standardMergedData);
      // console.log("customfinal", customMergedData);
      // console.log("flatrateFinal", FlatRateMergedData);
      // console.log("tierfinal", tierMergedData);

      let FinalData = {
        ...duplicateConfirmedProposal,
        standardProducts: standardMergedData,
        customProducts: customMergedData,
      };


      // console.log("FinalData", FinalData);

      // setStep4Data(FinalData)

      step4Data = FinalData

      setValue("flatRateProductsStep4", FinalData?.flatRateProducts)
      setValue("tierProductsStep4", FinalData?.tierProducts)

    }
  }, [couponStatusState, activeTab])




  // useEffect(() => {
  //   console.log("######################", step4Data);
  // }, [step4Data])


  const couponGenerateState = useSelector(state => state.CouponGenerateReducer)

  const postcoupongenerate = (data) => {
    let value = {
      "request_id": data?.request_id,
      // "request_id": "XTTRGMINN6NF3BJ",  
    }
    dispatch(postCouponGenerate(value))
  }

  const couponGenerateLog = (value) => {
    let data = {
      "action": "Generate",
      "request_id": value?.request_id,
      // "request_id": "XTTRGMINN6NF3BJ",
    }
    dispatch(postDownloadLog(data))
  }

  // const [generateCouponStatus, setGenerateCouponStatus] = useState("");


  useEffect(() => {

    // if (couponGenerateState?.getcouponGenerate !== null) {
    //   setCouponGenerate(couponGenerateState?.getcouponGenerate)
    // }

    // if (couponGenerateState?.createcouponGenerate !== null) {
    //   setGenerateCouponStatus(couponGenerateState?.createcouponGenerate?.status);
    // }

    if (couponGenerateState.error) {
      // console.log(couponGenerateState);
      toast.error(couponGenerateState.error.ErrorDetail)
      couponGenerateState.error = null
    }
  }, [couponGenerateState])
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestId, setRequestId] = useState('')

  return (
    <React.Fragment>

      <CustomAlert
        show={isModalOpen}
        onCloseClick={() => setIsModalOpen(false)}
       />


      <ApproveSubmitInvoiceModal
        show={submitApprovalInvoice}
        onCloseClick={() => { setSubmitApprovalInvoice(false); $('#submitApproval').attr("disabled", false) }}
        // onConfirmClick={handleApprovalInvoice}
        // acc={opportunities.app_account_balance}
        data={submittedData}
      />
      <ProposalCloneVersionModal
        show={confirmProposalCloneVersionModal}
        onProposalCloneConfirmClick={handleConfirmProposalClone}
        onCloseClick={() => setConfirmProposalCloneVersionModal(false)}
        data={proposalCloneData}
      />
      <OpportunityCloneVersionModal
        show={confirmOpportunityCloneVersionModal}
        onOpportunityCloneConfirmClick={handleConfirmOpportunityClone}
        onCloseClick={() => setConfirmOpportunityCloneVersionModal(false)}
        data={opportunities}
      />
      <CouponListModal
        show={couponListModal}
        onCloseClick={() => setCouponListModal(false)}
        data={''}
      />
      {/* not in use */}
      <CouponStatusModal
        show={couponStatusModal}
        onCloseClick={() => setCouponStatusModal(false)}
        data={passRequestModalData}
      />

      <RequestStatusModal
        show={requestStatusModal}
        onCloseClick={() => setrequestStatusModal(false)}
        data={passRequestModalData}
      />

      <LogModal
        show={logModal}
        onCloseClick={() => setLogModal(false)}
        data={requestId}
      />

      <div className="page-content " style={{ marginTop: "-40px" }}>
        <Breadcrumbs title="View Opportunities" breadcrumbItem="Opportunities" />
        <Container fluid={true}>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              // setactiveTab(1)
                              history.push(`/viewOpportunities/${id}?tab=1`)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">01</span> Open
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              // setactiveTab(2)
                              history.push(`/viewOpportunities/${id}?tab=2`)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">02</span> Proposal
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              // setactiveTab(3)
                              history.push(`/viewOpportunities/${id}?tab=3`)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">03</span> Close
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              // setactiveTab(4)
                              history.push(`/viewOpportunities/${id}?tab=4`)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">04</span> Distribution
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix mt-4">
                      <TabContent activeTab={activeTab}>

                        <TabPane tabId={1}>
                          <Form onSubmit={handleSubmit(createOpportunites)}>
                            <Row>
                              <FormGroup row>
                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                <Col lg="5" md="6">
                                  <select disabled className="form-select d-inline" aria-label="Default select example" {...register("app_business_id", { required: true })}>
                                    <option hidden value="">Company</option>
                                    {businessList.map((business) => (
                                      <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_keyid} {" | "}{business.app_business_name}</option>
                                    ))}
                                  </select>
                                  {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                </Col>
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup row>
                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Site <span style={{ color: "red" }}>*</span> </Label>
                                <Col lg="5" md="6">
                                  <select disabled className={`form-select d-inline `} aria-label="Default select example" {...register("app_department_id", { required: true })}>
                                    <option hidden value="">Select Site</option>
                                    {departments.map((department) => (
                                      <option key={department.app_department_id} value={department.app_department_id}>{department.app_department_keyid} {" | "}{department.app_department_name}</option>
                                    ))}
                                  </select>
                                  {errors.app_department_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                </Col>
                              </FormGroup>
                            </Row>
                            <div className="actions clearfix  d-flex justify-content-end">
                              {/* <Button onClick={() => history.push("/opportunities?skip=0&&limit=10&&status=true")} color='danger' type='button' className='m-2'>
                                  Cancel
                              </Button> */}
                              <Button onClick={() => backButton()} color='danger' type='button' className='m-2'>
                                Back to Listing
                              </Button>
                              <Button id='createOpportunities' color='success' type='submit' className='m-2'
                                onClick={() => { history.push(`/viewOpportunities/${id}?tab=2`) }}>
                                Next
                              </Button>
                            </div>
                          </Form>
                        </TabPane>

                        <TabPane tabId={2}>

                          {/* proposal version List page */}
                          {versionListingPage ?
                            <div>
                              {!versionListingPageTable ? <div>
                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end"> Opportunities ID </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.opportunity_keyid} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Company </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.app_business_name} />
                                      </Col>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Site </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.app_department_name} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end">  Account Balance </Label>
                                      <Col lg="7  " md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={" $ " + parseFloat(opportunities.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end">Available limit </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={" $ " + parseFloat(opportunities.available_limit).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <hr />
                                {/*  version Listing  table */}
                                {opportunityApprovedStatus !== "Approved" &&
                                  opportunityApprovedStatus !== "Pending Approval" &&
                                  opportunityApprovedStatus !== "Cancel" &&
                                  opportunityApprovedStatus !== "Rejected" && (
                                    <Row>
                                      <Col lg="6" md="6" sm="6" xs="12">
                                        <h4><b> Version Listing</b> </h4>
                                      </Col>
                                      <Col lg="6" md="6" sm="6" xs="12">
                                        <div className="text-sm-end">
                                          <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded  mb-2 me-2"
                                            onClick={() => {
                                              setProposalId("")
                                              setSinglePricingModelTier()
                                              setSinglePricingModelFlatRate()
                                              setValue("opportunities_remarks", "")
                                              setValue("app_billing_contact_id", "")
                                              setValue("app_delivery_contact_id", "")
                                              setStandardProductList([])
                                              setCustomProductList([])
                                              setFlatRateProductList()
                                              setTierProductList([])
                                              setSelectedBillingAddressId("")
                                              setSelectedDeliveryAddressId("")
                                              setVersionListingPage(false)
                                              setCreateProposalPage(false)
                                              setValue("flatRateProducts", [])
                                              setValue("tierProducts", [])
                                              setNewVersion(true)
                                              proposalVersionState.Proposalversion = null
                                            }}
                                          >
                                            <i className="mdi mdi-plus" />
                                            New Version
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}


                                <Row>
                                  <Col xs="12">
                                    <Card>
                                      <CardBody>
                                        <TableContainer
                                          columns={versionColumn}
                                          data={versionList}
                                          isGlobalFilter={true}
                                          // isAddOpportunities={permissions.includes("system.orders.order.write") ? true : false}
                                          customPageSize={10}
                                          className="custom-header-css"
                                          handlePageClick={handlePageClick}
                                          // customPageCount={pageCount}
                                          customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                        />
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>

                                <div className="actions clearfix  d-flex justify-content-end">
                                  <Button onClick={() => backButton()} color='danger' type='button' className='m-2'>
                                    Back to Listing
                                  </Button>
                                  <Button color='info' type='button' className='m-2' onClick={() => history.push(`/viewOpportunities/${id}?tab=1`)}>
                                    Previous
                                  </Button>
                                  {confirmedInvoiceBtn &&
                                    <Button color='info' type='button' className='m-2'
                                      onClick={() => history.push(`/viewOpportunities/${id}?tab=3`)}>
                                      Next
                                    </Button>}
                                </div>
                              </div> :
                                <div>
                                  {quotationPage && quotationData !== null && quotationData !== undefined && <div>

                                    <div id="pdf-content">
                                      <QuatationPdf quotationData={quotationData}
                                        onCloseClick={() => { setVersionListingPage(true); setVersionListingPageTable(false); setQuotationPage(false) }} />
                                    </div>
                                    {/* <PDFDownloadLink document={<QuatationDocument quotationData={quotationData} />} type='button' className='m-2' fileName={`${"Quotation " + " - " + quotationData.quotation_keyid}.pdf`}>
            {({ blob, url, loading, error }) => loading ? "Loading document..." : <Button className="btn btn-info  ">Save & Download</Button>
            }
          </PDFDownloadLink> */}
                                  </div>}

                                  {invoicePage && invoiceData !== null && invoiceData !== undefined && <div>
                                    <div id="pdf-content">
                                      <InvoicePdf invoiceData={invoiceData}
                                        onCloseClick={() => { setVersionListingPage(true); setVersionListingPageTable(false); setInvoicePage(false) }} />
                                    </div>


                                    {/* <PDFDownloadLink document={<InvoiceDocument invoiceData={invoiceData} />} type='button' className='m-2' fileName={`${"Invoice " + " - " + invoiceData.invoice_keyid}.pdf`}>
            {({ blob, url, loading, error }) => loading ? "Loading document..." : <Button className="btn btn-info  ">Save & Download</Button>
            }
          </PDFDownloadLink> */}
                                  </div>}
                                </div>
                              }

                            </div>
                            :
                            // Edit new Proposal 
                            createProposalPage ?
                              <div>
                                {standardPage && < StandardComponenet
                                  show={""}
                                  onDeleteClick={""}
                                  onUpdatedData={handleDataSelectionforStandard}
                                  onData={standardProductList.length > 0 ? standardProductList : []}
                                  onCloseClick={() => { setCreateProposalPage(false); setStandardPage(false) }} />}
                                {customPage && < CustomComponent
                                  show={""}
                                  onDeleteClick={""}
                                  onUpdatedData={handleDataSelectionforCustom}
                                  onData={customProductList.length > 0 ? customProductList : []}
                                  onCloseClick={() => { setCreateProposalPage(false); setCustomPage(false) }} />}
                                {flatRatePage && < FlatComponent
                                  show={""}
                                  onUpdatedData={handleDataSelectionforFlatRate}
                                  onData={flatRateProductList ? flatRateProductList : []}
                                  onCloseClick={() => { setCreateProposalPage(false); setFlatRatePage(false) }} />}
                                {tierPage && < TierComponent
                                  show={""}
                                  onUpdatedData={handleDataSelectionforTier}
                                  onData={tierProductList ? tierProductList : []}
                                  onCloseClick={() => { setCreateProposalPage(false); setTierPage(false) }} />}
                              </div>
                              :
                              // new version Page
                              <div>
                                <Form>

                                  <Row>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Opportunities ID </Label>
                                        <Col lg="7" md="6">
                                          <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" {...register("opportunity_keyid", { required: true })} value={opportunities.opportunity_keyid} />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Date </Label>
                                        <Col lg="7" md="6">
                                          <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" {...register("opportunity_date", { required: true })} value={opportunities.opportunity_date} />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Company </Label>
                                        <Col lg="7" md="6">
                                          <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" {...register("app_business_name", { required: true })} value={opportunities.app_business_name} />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Site </Label>
                                        <Col lg="7" md="6">
                                          <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder=""  {...register("app_department_name", { required: true })} value={opportunities.app_department_name} />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">  Account Balance </Label>
                                        <Col lg="7  " md="6">
                                          <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={" $ " + parseFloat(opportunities.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">  Remarks </Label>
                                        <Col lg="7" md="6">
                                          <input className={`form form-control`} placeholder="" {...register("opportunities_remarks")} value={opportunities.app_opportunities_remarks} />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Billing Contact </Label>
                                        <Col lg="7" md="6">
                                          <select className={`form-select d-inline `} aria-label="Default select example" {...register("app_billing_contact_id", { required: true })}>
                                            <option hidden value="">Select Contact</option>
                                            {contactList.map((Contact) => (
                                              <option key={Contact.app_contact_id} value={Contact.app_contact_id}>{Contact.app_contact_name} {" | "}{Contact.app_contact_email}</option>
                                            ))}
                                          </select>
                                          {/* <textarea readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} value={"Anandraja | anandraja@gmail.com"} name="" id="" rows="1" /> */}
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Delivery Contact </Label>
                                        <Col lg="7" md="6">
                                          <select className={`form-select d-inline `} aria-label="Default select example" {...register("app_delivery_contact_id", { required: true })}>
                                            <option hidden value="">Select Contact</option>
                                            {contactList.map((Contact) => (
                                              <option key={Contact.app_contact_id} value={Contact.app_contact_id}>{Contact.app_contact_name} {" | "}{Contact.app_contact_email}</option>
                                            ))}
                                          </select>
                                          {/* <textarea readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} value={"Rahul | rahul@gmail.com"} name="" id="" rows="1" /> */}
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Billing Address </Label>
                                        <Col lg="7" md="6">
                                          <select
                                            className={`form-select d-inline `}
                                            aria-label="Default select example"
                                            {...register("app_billing_address_id", { required: true })}
                                            value={selectedBillingAddressId}
                                            onChange={(e) => setSelectedBillingAddressId(e.target.value)}
                                          >
                                            <option hidden value="">Select Address</option>
                                            {addressesList.map((address) => (
                                              <option key={address.app_address_id} value={address.app_address_id}>{address.app_billing_address}</option>
                                            ))}
                                          </select>

                                          <textarea className={`form form-control mt-2`} id="billingAddressTextarea" rows="4" value={selectedBillingAddress}></textarea>
                                        </Col>
                                      </FormGroup>
                                    </Col>

                                    <Col>
                                      <FormGroup row>
                                        <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Delivery Address </Label>
                                        <Col lg="7" md="6">
                                          <select
                                            className={`form-select d-inline `}
                                            aria-label="Default select example"
                                            {...register("app_delivery_address_id", { required: true })}
                                            value={selectedDeliveryAddressId}
                                            onChange={(e) => setSelectedDeliveryAddressId(e.target.value)}
                                          >
                                            <option hidden value="">Select Address</option>
                                            {addressesList.map((Address) => (
                                              <option key={Address.app_address_id} value={Address.app_address_id}>{Address.app_delivery_address}</option>
                                            ))}
                                          </select>
                                          <textarea className={`form form-control mt-2`} id="billingAddressTextarea" rows="4" value={selectedDeliveryAddress}></textarea>
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <hr />
                                  {/*            standard Table            */}
                                  <Row>
                                    <Col lg="6" md="6" sm="6" xs="12" className="d-flex justify-content-start">
                                      <h4>Pricing Model - Standard</h4>
                                    </Col>

                                    {/* <Col lg="2" md="2" sm="6" xs="12"> */}
                                    <Col lg="6" className="d-flex justify-content-end">

                                      {
                                        ((proposalVersionState.Proposalversion !== null &&
                                          proposalVersionState.Proposalversion.proposal_status !== "Confirmed") &&

                                          (proposalVersionState.Proposalversion !== null &&
                                            proposalVersionState.Proposalversion.proposal_status !== "Waiting for Custom Approval") &&

                                          (proposalVersionState.Proposalversion !== null &&
                                            proposalVersionState.Proposalversion.proposal_status !== "Rejected") &&

                                          (!(opportunityApprovedStatus === "Approved") &&
                                            !(opportunityApprovedStatus === "Pending Approval") &&
                                            !(opportunityApprovedStatus === "Cancel") &&
                                            !(opportunityApprovedStatus === "Rejected")) ||

                                          proposalVersionState.Proposalversion === null) &&

                                        <div className="text-sm-end">

                                          <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded  mb-2 me-4"
                                            onClick={() => { setCreateProposalPage(true); setStandardPage(true) }}
                                          >
                                            <i className="mdi mdi-plus " />
                                            Edit Product
                                          </Button>
                                        </div>
                                      }

                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs="12">
                                      <Card>
                                        <CardBody>
                                          <TableContainer
                                            columns={proposalMultiColumn}
                                            data={standardProductList}
                                            customPageSize={10}
                                            className="custom-header-css"
                                          />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>

                                  {/*             Custom Table              */}
                                  <Row>

                                    <Col lg="6" md="6" sm="6" xs="12" className="d-flex justify-content-start">
                                      <h4>Pricing Model - Custom</h4>
                                    </Col>

                                    <Col lg="6" className="d-flex justify-content-end">
                                      {
                                        ((proposalVersionState.Proposalversion !== null &&
                                          proposalVersionState.Proposalversion.proposal_status !== "Confirmed") &&

                                          (proposalVersionState.Proposalversion !== null &&
                                            proposalVersionState.Proposalversion.proposal_status !== "Waiting for Custom Approval") &&

                                          (proposalVersionState.Proposalversion !== null &&
                                            proposalVersionState.Proposalversion.proposal_status !== "Rejected") &&

                                          (!(opportunityApprovedStatus === "Approved") &&
                                            !(opportunityApprovedStatus === "Pending Approval") &&
                                            !(opportunityApprovedStatus === "Cancel") &&
                                            !(opportunityApprovedStatus === "Rejected")) ||

                                          proposalVersionState.Proposalversion === null) && <div className="text-sm-end">
                                          <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded  mb-2 me-4"
                                            onClick={() => { setCreateProposalPage(true); setCustomPage(true) }}
                                          >
                                            <i className="mdi mdi-plus " />
                                            Edit Product
                                          </Button>
                                        </div>
                                      }

                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs="12">
                                      <Card>
                                        <CardBody>
                                          <TableContainer
                                            columns={proposalMultiColumn}
                                            data={customProductList}
                                            customPageSize={10}
                                            className="custom-header-css"
                                          />
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>


                                  {/*            Flat Rate table            */}
                                  <Row>
                                    <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                                      <h4> Pricing Model - Flat Rate</h4>
                                      <br></br>
                                    </Col>
                                    <Col lg="6" className="d-flex justify-content-end">
                                      {
                                        ((proposalVersionState.Proposalversion !== null &&
                                          proposalVersionState.Proposalversion.proposal_status !== "Confirmed") &&

                                          (proposalVersionState.Proposalversion !== null &&
                                            proposalVersionState.Proposalversion.proposal_status !== "Waiting for Custom Approval") &&

                                          (proposalVersionState.Proposalversion !== null &&
                                            proposalVersionState.Proposalversion.proposal_status !== "Rejected") &&

                                          (!(opportunityApprovedStatus === "Approved") &&
                                            !(opportunityApprovedStatus === "Pending Approval") &&
                                            !(opportunityApprovedStatus === "Cancel") &&
                                            !(opportunityApprovedStatus === "Rejected")) ||

                                          proposalVersionState.Proposalversion === null) &&
                                        <div className="text-sm-end">
                                          <Button
                                            type="button"
                                            color="warning"
                                            className="btn-rounded  mb-2 me-2"
                                            onClick={() => append({})}
                                          >
                                            <i className="mdi mdi-plus " />
                                            Add Group
                                          </Button>
                                        </div>
                                      }

                                    </Col>
                                  </Row>

                                  {fields && fields.length > 0 && fields.map((item, index) => (
                                    <div key={item.id}>
                                      <Row>

                                        <Col lg="6" md="6" sm="6" xs="6">
                                          <div className="float-start">
                                            <span style={{ fontSize: "15px" }}>   Pricing Model Name: &nbsp; {item.app_pricing_model_name}</span><br></br>
                                            <span style={{ fontSize: "15px" }}>  Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                            <span style={{ fontSize: "15px" }}>  Qualifying Value:&nbsp; {item.app_pricing_model_qualifying_value}</span><br></br>
                                            <span style={{ fontSize: "15px" }}>  Discount Type: &nbsp;  {item.app_pricing_model_discount_type}</span><br></br>
                                            <span style={{ fontSize: "15px" }}>  Discount Value: &nbsp;  {item.app_pricing_model_discount_value}</span><br></br>

                                            {/* <h5 > <b> Pricing Model Name: &nbsp; </b> {item.app_pricing_model_name}</h5>
                                            <h5 > <b> Qualifying Type: &nbsp; </b> {item.app_pricing_model_qualifying_type}</h5>
                                            <h5 > <b> Qualifying Value:&nbsp;  </b>{item.app_pricing_model_qualifying_value}</h5>
                                            <h5 > <b> Discount Type: &nbsp;  </b> {item.app_pricing_model_discount_type}</h5>
                                            <h5 > <b> Discount Value: &nbsp;  </b> {item.app_pricing_model_discount_value}</h5> */}
                                          </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-end">
                                          {
                                            ((proposalVersionState.Proposalversion !== null &&
                                              proposalVersionState.Proposalversion.proposal_status !== "Confirmed") &&

                                              (proposalVersionState.Proposalversion !== null &&
                                                proposalVersionState.Proposalversion.proposal_status !== "Waiting for Custom Approval") &&

                                              (proposalVersionState.Proposalversion !== null &&
                                                proposalVersionState.Proposalversion.proposal_status !== "Rejected") &&

                                              (!(opportunityApprovedStatus === "Approved") &&
                                                !(opportunityApprovedStatus === "Pending Approval") &&
                                                !(opportunityApprovedStatus === "Cancel") &&
                                                !(opportunityApprovedStatus === "Rejected")) ||

                                              proposalVersionState.Proposalversion === null) &&
                                            <div className="text-sm-end">
                                              <Button
                                                type="button"
                                                color="danger"
                                                className="btn-rounded  mb-2 me-2"
                                                onClick={() => remove(index)}
                                              >
                                                <i className="mdi mdi-minus " />
                                                Remove This Group
                                              </Button>
                                            </div>
                                          }

                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-end">
                                          {
                                            ((proposalVersionState.Proposalversion !== null &&
                                              proposalVersionState.Proposalversion.proposal_status !== "Confirmed") &&

                                              (proposalVersionState.Proposalversion !== null &&
                                                proposalVersionState.Proposalversion.proposal_status !== "Waiting for Custom Approval") &&

                                              (proposalVersionState.Proposalversion !== null &&
                                                proposalVersionState.Proposalversion.proposal_status !== "Rejected") &&

                                              (!(opportunityApprovedStatus === "Approved") &&
                                                !(opportunityApprovedStatus === "Pending Approval") &&
                                                !(opportunityApprovedStatus === "Cancel") &&
                                                !(opportunityApprovedStatus === "Rejected")) ||

                                              proposalVersionState.Proposalversion === null) &&

                                            <div className="text-sm-end">
                                              <Button
                                                type="button"
                                                color="success"
                                                className="btn-rounded  mb-2 me-4"
                                                onClick={() => {
                                                  setCreateProposalPage(true);
                                                  setFlatRatePage(true);
                                                  flatrateFieldArrayIndex = index
                                                  setFlatRateProductList(item)
                                                }}
                                              >
                                                <i className="mdi mdi-plus " />
                                                Edit Product
                                              </Button>
                                            </div>

                                          }

                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs="12">
                                          <Card>
                                            <CardBody>
                                              <TableContainer
                                                columns={proposalMultiColumn}
                                                data={item.flatRateList || []}
                                                customPageSize={10}
                                                className="custom-header-css"
                                              />
                                            </CardBody>
                                          </Card>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}

                                  {/*            Tier table            */}

                                  <Row>
                                    <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                                      <h4>Pricing Model - Tier</h4>
                                      <br></br>
                                    </Col>
                                    <Col lg="6" className="d-flex justify-content-end">
                                      {
                                        ((proposalVersionState.Proposalversion !== null &&
                                          proposalVersionState.Proposalversion.proposal_status !== "Confirmed") &&

                                          (proposalVersionState.Proposalversion !== null &&
                                            proposalVersionState.Proposalversion.proposal_status !== "Waiting for Custom Approval") &&

                                          (proposalVersionState.Proposalversion !== null &&
                                            proposalVersionState.Proposalversion.proposal_status !== "Rejected") &&

                                          (!(opportunityApprovedStatus === "Approved") &&
                                            !(opportunityApprovedStatus === "Pending Approval") &&
                                            !(opportunityApprovedStatus === "Cancel") &&
                                            !(opportunityApprovedStatus === "Rejected")) ||

                                          proposalVersionState.Proposalversion === null) &&
                                        <div className="text-sm-end">
                                          <Button
                                            type="button"
                                            color="warning"
                                            className="btn-rounded  mb-2 me-2"
                                            onClick={() => appendTier({})}
                                          >
                                            <i className="mdi mdi-plus " />
                                            Add Group
                                          </Button>
                                        </div>
                                      }

                                    </Col>
                                  </Row>

                                  {fieldsTier && fieldsTier.length > 0 && fieldsTier.map((item, index) => (
                                    <div key={item.id}>
                                      <Row>

                                        <Col lg="6" md="6" sm="6" xs="6">
                                          <div className="float-start">
                                            {/* <h5><b> Pricing Model Name: &nbsp; </b>{item.app_pricing_model_name}</h5>
                                            <h5><b> Qualifying Type: &nbsp; </b>{item.app_pricing_model_qualifying_type}</h5>
                                            <h5><b> Discount Type: &nbsp; </b>{item.app_pricing_model_discount_type}</h5> */}
                                            <span style={{ fontSize: "15px" }}>Pricing Model Name: &nbsp;{item.app_pricing_model_name}</span><br></br>
                                            <span style={{ fontSize: "15px" }}>Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                            <span style={{ fontSize: "15px" }}>Discount Type: &nbsp; {item.app_pricing_model_discount_type}</span><br></br>
                                            {displayTierValues(item)}
                                          </div>
                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-end">
                                          {
                                            ((proposalVersionState.Proposalversion !== null &&
                                              proposalVersionState.Proposalversion.proposal_status !== "Confirmed") &&

                                              (proposalVersionState.Proposalversion !== null &&
                                                proposalVersionState.Proposalversion.proposal_status !== "Waiting for Custom Approval") &&

                                              (proposalVersionState.Proposalversion !== null &&
                                                proposalVersionState.Proposalversion.proposal_status !== "Rejected") &&

                                              (!(opportunityApprovedStatus === "Approved") &&
                                                !(opportunityApprovedStatus === "Pending Approval") &&
                                                !(opportunityApprovedStatus === "Cancel") &&
                                                !(opportunityApprovedStatus === "Rejected")) ||

                                              proposalVersionState.Proposalversion === null) &&
                                            <div className="text-sm-end">
                                              <Button
                                                type="button"
                                                color="danger"
                                                className="btn-rounded  mb-2 me-2"
                                                onClick={() => removeTier(index)}
                                              >
                                                <i className="mdi mdi-minus " />
                                                Remove This Group
                                              </Button>
                                            </div>
                                          }

                                        </Col>
                                        <Col lg="12" className="d-flex justify-content-end">
                                          {
                                            ((proposalVersionState.Proposalversion !== null &&
                                              proposalVersionState.Proposalversion.proposal_status !== "Confirmed") &&

                                              (proposalVersionState.Proposalversion !== null &&
                                                proposalVersionState.Proposalversion.proposal_status !== "Waiting for Custom Approval") &&

                                              (proposalVersionState.Proposalversion !== null &&
                                                proposalVersionState.Proposalversion.proposal_status !== "Rejected") &&

                                              (!(opportunityApprovedStatus === "Approved") &&
                                                !(opportunityApprovedStatus === "Pending Approval") &&
                                                !(opportunityApprovedStatus === "Cancel") &&
                                                !(opportunityApprovedStatus === "Rejected")) ||

                                              proposalVersionState.Proposalversion === null) &&
                                            <div className="text-sm-end">
                                              <Button
                                                type="button"
                                                color="success"
                                                className="btn-rounded  mb-2 me-4"
                                                onClick={() => {
                                                  setCreateProposalPage(true);
                                                  setTierPage(true);
                                                  flatrateFieldArrayTierIndex = index
                                                  setTierProductList(item)
                                                }}
                                              >
                                                <i className="mdi mdi-plus " />
                                                Edit Product
                                              </Button>
                                            </div>

                                          }

                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs="12">
                                          <Card>
                                            <CardBody>
                                              <TableContainer
                                                columns={proposalMultiColumn}
                                                data={item.tierList || []}
                                                customPageSize={10}
                                                className="custom-header-css"
                                              />
                                            </CardBody>
                                          </Card>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                  <Row>
                                    <Col className="d-flex justify-content-end" xs="12">
                                      <h4>
                                        {"Grand Qty " + " : " + `${getGrandTotalQuantity(standardProductList, customProductList, getValues("flatRateProducts"), getValues("tierProducts"))}`}
                                      </h4>
                                      &nbsp; &nbsp; &nbsp;
                                      <h4 className="me-4">
                                        {"Grand Total " + " : " + " $ " + `${getGrandTotal(standardProductList, customProductList, getValues("flatRateProducts"), getValues("tierProducts"))}`}
                                      </h4>
                                    </Col>
                                  </Row>




                                  <div className="actions clearfix  d-flex justify-content-end">


                                    {((proposalVersionState.Proposalversion !== null &&
                                      (proposalVersionState.Proposalversion.proposal_status === "Confirmed" ||
                                        proposalVersionState.Proposalversion.proposal_status === "Waiting for Custom Approval" ||
                                        proposalVersionState.Proposalversion.proposal_status === "Rejected")) ||
                                      (opportunityApprovedStatus === "Pending Approval" ||
                                        opportunityApprovedStatus === "Approved" ||
                                        opportunityApprovedStatus === "Cancel" ||
                                        opportunityApprovedStatus === "Rejected")) &&
                                      <>
                                        <Button color='danger' type='button' className='m-2' onClick={() => { setVersionListingPage(true); }}>
                                          Back
                                        </Button>
                                      </>}
                                    {(proposalVersionState.Proposalversion !== null &&
                                      proposalVersionState.Proposalversion.proposal_status === "Draft" &&
                                      !(opportunityApprovedStatus === "Pending Approval") &&
                                      !(opportunityApprovedStatus === "Approved") &&
                                      !(opportunityApprovedStatus === "Cancel") &&
                                      !(opportunityApprovedStatus === "Rejected")) &&
                                      <>
                                        <Button color='danger' type='button' className='m-2' onClick={() => { setVersionListingPage(true); }}>
                                          Back
                                        </Button>
                                        <Button id="draftBtn" color='secondary' onClick={handleSubmit(dta => createProposal(dta, "draft"))} type='button' className='m-2'>
                                          Save Draft
                                        </Button>
                                        <Button id="confirmProposalBtn" color='info' onClick={handleSubmit(dta => createProposal(dta, "confirmProposal"))} type='button' className='m-2 me-4'  >
                                          Confirm Version
                                        </Button>
                                      </>}
                                    {(newVersion) &&
                                      <>
                                        <Button color='danger' type='button' className='m-2' onClick={() => { setVersionListingPage(true); setNewVersion(false) }}>
                                          Back
                                        </Button>
                                        <Button id="draftBtn" color='secondary' onClick={handleSubmit(dta => createProposal(dta, "draft"))} type='button' className='m-2'>
                                          Save Draft
                                        </Button>
                                        <Button id="confirmProposalBtn" color='info' onClick={handleSubmit(dta => createProposal(dta, "confirmProposal"))} type='button' className='m-2 me-4'>
                                          Confirm Version
                                        </Button>
                                      </>}
                                    {/* <Button color='info' type='button' className='m-2' onClick={() => toggleTab(3)} >
                                        Next
                                      </Button> */}
                                  </div>
                                </Form>
                              </div>
                          }
                        </TabPane>


                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <div>
                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end"> Opportunities ID </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.opportunity_keyid} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Date  </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.opportunity_date} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Company </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.app_business_name} />
                                      </Col>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Site </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.app_department_name} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Account Balance </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={" $ " + parseFloat(opportunities.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                      </Col>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Available limit </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={" $ " + parseFloat(opportunities.available_limit).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                      </Col>
                                    </FormGroup>
                                  </Col>


                                </Row>


                                <hr></hr>


                                <Row className="mt-3">
                                  <Label >   <h4>Invoice Information</h4>  </Label>
                                  <br></br>
                                  <Col>
                                    <span dangerouslySetInnerHTML={{ __html: confirmedProposal?.template_information }} />
                                  </Col>
                                </Row>

                                {confirmedProposal?.standardProducts.length > 0 &&
                                  <>
                                    <Row>
                                      <Col lg="6" md="6" sm="6" xs="12">
                                        <h4> Pricing Model - Standard</h4>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="12">
                                        <Card>
                                          <CardBody>
                                            <TableContainer
                                              columns={proposalMultiColumn}
                                              data={confirmedProposal?.standardProducts || []}
                                              customPageSize={10}
                                              className="custom-header-css"
                                            />
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </>
                                }

                                {confirmedProposal?.customProducts?.length > 0 &&
                                  <>
                                    <Row>
                                      <Col lg="6" md="6" sm="6" xs="12">
                                        <h4> Pricing Model - Custom </h4>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xs="12">
                                        <Card>
                                          <CardBody>
                                            <TableContainer
                                              columns={proposalMultiColumn}
                                              data={confirmedProposal?.customProducts || []}
                                              // isGlobalFilter={true}
                                              // isAddOpportunities={permissions.includes("system.orders.order.write") ? true : false}
                                              customPageSize={10}
                                              className="custom-header-css"
                                            // handlePageClick={handlePageClick}
                                            // customPageCount={pageCount}
                                            />
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </>
                                }
                                {fields && fields.length > 0 && <Row>
                                  <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                                    <h4> Pricing Model - Flat Rate</h4>
                                    <br></br>
                                  </Col>
                                </Row>}

                                {fields && fields.length > 0 && fields.map((item, index) => (
                                  <div key={item.id}>
                                    <Row>

                                      <Col lg="6" md="6" sm="6" xs="6">
                                        <div className="float-start">
                                          <span style={{ fontSize: "15px" }}>   Pricing Model Name: &nbsp; {item.app_pricing_model_name}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>  Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>  Qualifying Value:&nbsp; {item.app_pricing_model_qualifying_value}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>  Discount Type: &nbsp;  {item.app_pricing_model_discount_type}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>  Discount Value: &nbsp;  {item.app_pricing_model_discount_value}</span><br></br>

                                          {/* <h5 > <b> Pricing Model Name: &nbsp; </b> {item.app_pricing_model_name}</h5>
                                          <h5 > <b> Qualifying Type: &nbsp; </b> {item.app_pricing_model_qualifying_type}</h5>
                                          <h5 > <b> Qualifying Value:&nbsp;  </b>{item.app_pricing_model_qualifying_value}</h5>
                                          <h5 > <b> Discount Type: &nbsp;  </b> {item.app_pricing_model_discount_type}</h5>
                                          <h5 > <b> Discount Value: &nbsp;  </b> {item.app_pricing_model_discount_value}</h5> */}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="12">
                                        <Card>
                                          <CardBody>
                                            <TableContainer
                                              columns={proposalMultiColumn}
                                              data={item.flatRateList || []}
                                              customPageSize={10}
                                              className="custom-header-css"
                                            />
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}



                                {fieldsTier && fieldsTier.length > 0 && <Row>
                                  <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                                    <h4> Pricing Model - Tier </h4>
                                    <br></br>
                                  </Col>
                                </Row>}

                                {fieldsTier && fieldsTier.length > 0 && fieldsTier.map((item, index) => (
                                  <div key={item.id}>
                                    <Row>
                                      <Col lg="6" md="6" sm="6" xs="6">
                                        <div className="float-start">
                                          <span style={{ fontSize: "15px" }}>Pricing Model Name: &nbsp;{item.app_pricing_model_name}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>Discount Type: &nbsp; {item.app_pricing_model_discount_type}</span><br></br>
                                          {/* <h5><b> Pricing Model Name: &nbsp; </b>{item.app_pricing_model_name}</h5>
                                          <h5><b> Qualifying Type: &nbsp; </b>{item.app_pricing_model_qualifying_type}</h5>
                                          <h5><b> Discount Type: &nbsp; </b>{item.app_pricing_model_discount_type}</h5> */}
                                          {displayTierValues(item)}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="12">
                                        <Card>
                                          <CardBody>
                                            <TableContainer
                                              columns={proposalMultiColumn}
                                              data={item.tierList || []}
                                              customPageSize={10}
                                              className="custom-header-css"
                                            />
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}

                                <Row className="mt-3">
                                  <Label> <h4>Terms and Conditions</h4> </Label>
                                  <br />
                                  <Col>
                                    <span dangerouslySetInnerHTML={{ __html: confirmedProposal?.terms_and_condition }} />

                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Label> <h4>Payment Methods  </h4>   </Label>
                                  <br />
                                  <Col>
                                    <span dangerouslySetInnerHTML={{ __html: confirmedProposal?.payment_method }} />

                                  </Col>
                                </Row>

                                <Row>
                                  <Col className="d-flex justify-content-end" xs="12">
                                    <h4>
                                      {"Grand Qty " + " : " + `${getGrandTotalQuantitystep3(confirmedProposal?.standardProducts, confirmedProposal?.customProducts, getValues("flatRateProducts"), getValues("tierProducts"),)}`}
                                    </h4>
                                    &nbsp; &nbsp; &nbsp;
                                    <h4 className="me-4">
                                      {"Grand Total " + " : " + " $ " + `${getGrandTotalstep3(confirmedProposal?.standardProducts, confirmedProposal?.customProducts, getValues("flatRateProducts"), getValues("tierProducts"))}`}
                                    </h4>
                                  </Col>
                                </Row>
                                <hr></hr>

                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end"> Required Date<span style={{ color: "red" }}>*</span></Label>
                                      <Col lg="7" md="6">
                                        <input type="date" readOnly={confirmedProposal?.finalize_proposal_date ? true : false}
                                          style={confirmedProposal?.finalize_proposal_date ? { backgroundColor: "#D2D4D1" } : {}}
                                          className={`form form-control`} value={confirmedProposal?.finalize_proposal_date}
                                          {...register2('finalize_proposal_date', { required: true })} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Invoice No  </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={confirmedProposal?.invoice_keyid} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                {/* accept='application/pdf, image/png, image/jpeg'  */}

                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end " >Payment File<span className='text-danger'>*</span></Label>
                                      <Col lg="7" md="6">
                                        {(!(confirmedProposal?.attachment_file?.length > 0)) &&
                                          <input className='form form-control' disabled={(confirmedProposal?.attachment_file === null)}  ref={fileInputRef}
                                            style={(confirmedProposal?.attachment_file === null) ? { backgroundColor: "#D2D4D1" } : {}} accept={attachmentarray} multiple type="file" onChange={handleChange} />}
                                        <div className="d-flex flex-wrap">
                                          {images?.map((image, i) => (
                                            <div key={i} className="thumbSpan thumbContainer"  >
                                              <img className='thumbImage ms-3' id="fileImg" src={image?.imgSrc} alt="pdf"
                                                onMouseEnter={() => setRemoveButton(true)}
                                                onClick={() => openImage(image)}
                                                onError={(e) => { e.target.src = pdfImage; }}
                                                style={{
                                                  border: "1px solid #ddd",
                                                  borderRadius: "4px",
                                                  padding: "5px",
                                                  width: "125px",
                                                  height: "125px",
                                                  margin: "15px"
                                                }}
                                              />
                                              {!(confirmedProposal?.attachment_file?.length > 0) && removeButton &&
                                                <button type='button'
                                                  onClick={() => removeBtnClick(i)}
                                                  className='thumbClose btn btn-danger btn-sm'>
                                                  X
                                                </button>
                                              }
                                            </div>
                                          ))}
                                        </div>
                                        {errors.app_payment_file && <span style={{ color: "red" }}>This Field is required</span>}
                                      </Col>


                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Payment Notes </Label>
                                      <Col lg="7" md="6">
                                        <input className={`form form-control`} placeholder="Payment Notes "
                                          readOnly={confirmedProposal?.payment_notes ? true : false}
                                          style={confirmedProposal?.payment_notes ? { backgroundColor: "#D2D4D1" } : {}}
                                          value={confirmedProposal?.payment_notes}  {...register2('payment_notes', { required: true })} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end"> Approval Status</Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} value={confirmedProposal?.invoice_approval_status != undefined ? confirmedProposal?.invoice_approval_status : " "} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Remarks</Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} value={confirmedProposal?.finalize_proposal_remarks != undefined ? confirmedProposal?.finalize_proposal_remarks : " "} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <div className="actions clearfix  d-flex justify-content-end ">
                                  {activeTab === 3 && (
                                    <h5 className="d-flex justify-content-end mt-3" style={{ marginRight: "30px" }}>
                                      {/* {"Total: $" + getGrandTotal(confirmedProductsList?.standardProducts, confirmedProductsList?.customProducts, confirmedProductsList?.flatRateProducts, confirmedProductsList?.tierProducts)} */}
                                    </h5>
                                  )}
                                  <Button onClick={() => backButton()} color='danger' type='button' className='m-2'>
                                    Back to Listing
                                  </Button>
                                  <Button color='btn btn-info' type='button' className='m-2' onClick={() => history.push(`/viewOpportunities/${id}?tab=2`)}>
                                    Previous
                                  </Button>
                                  {/* <Button color='success' type='button' className='m-2' onClick={() => history.push(`/viewOpportunities/${id}?tab=4`)}>
                                    Save
                                  </Button> */}

                                  {(confirmedProposal?.invoice_approval_status === "Approved") && <Button color='btn btn-info' type='button' className='m-2' onClick={() => history.push(`/viewOpportunities/${id}?tab=4`)}>
                                    Next
                                  </Button>}

                                  {/* {(confirmedProposal?.invoice_approval_status === "Pending" && confirmedProposal?.invoice_approval_status === "Approved") && <Button color='info' id='submitApproval' type='button' className='m-2' onClick={() => handleApprovalInvoice(confirmedProposal)} >
                                    Submit for Approval
                                  </Button>} */}

                                  {/* {console.log((confirmedProposal?.invoice_approval_status === "Approved") || (confirmedProposal?.invoice_approval_status === "Pending"))} */}

                                  {(confirmedProposal?.invoice_approval_status === "Approved") ||
                                    (confirmedProposal?.invoice_approval_status === "Rejected") ||
                                    (confirmedProposal?.invoice_approval_status === "Pending") ||
                                    (opportunityApprovedStatus === "Cancel") ||
                                    (opportunityApprovedStatus === "Rejected") ||
                                    (opportunityApprovedStatus === "Approved") ||
                                    (opportunityApprovedStatus === "Pending Approval") ? " "
                                    : <Button id="submitApproval" color='info' onClick={handleSubmit2(dta => { handleApprovalInvoice(dta) })} type='button' className='m-2 me-4'>
                                      Submit for Approval
                                    </Button>}

                                </div>
                              </div>

                            </Form>
                          </div>
                        </TabPane>

                        <TabPane tabId={4}>
                          <div>
                            <Form>
                              <div>
                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end"> Opportunities ID </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.opportunity_keyid} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Date  </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.opportunity_date} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Company </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.app_business_name} />
                                      </Col>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Site </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={opportunities.app_department_name} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end "> Account Balance </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={" $ " + parseFloat(opportunities.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                      </Col>
                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Available limit </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={" $ " + parseFloat(opportunities.available_limit).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <hr></hr>


                                <Row className="mt-3">
                                  <Label >   <h4>Invoice Information</h4>  </Label>
                                  <br></br>
                                  <Col>
                                    <span dangerouslySetInnerHTML={{ __html: step4Data?.template_information }} />
                                  </Col>
                                </Row>

                                {step4Data?.standardProducts.length > 0 &&
                                  <>
                                    <Row>
                                      <Col lg="6" md="6" sm="6" xs="12">
                                        <h4>Pricing Model - Standard</h4>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="12">
                                        <Card>
                                          <CardBody>
                                            <TableContainer
                                              columns={proposalMultiColumnstep4}
                                              data={step4Data?.standardProducts || []}
                                              customPageSize={10}
                                              className="custom-header-css"
                                            />
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </>
                                }

                                {step4Data?.customProducts?.length > 0 &&
                                  <>
                                    <Row>
                                      <Col lg="6" md="6" sm="6" xs="12">
                                        <h4>Pricing Model - Custom </h4>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xs="12">
                                        <Card>
                                          <CardBody>
                                            <TableContainer
                                              columns={proposalMultiColumnstep4}
                                              data={step4Data?.customProducts || []}
                                              // isGlobalFilter={true}
                                              // isAddOpportunities={permissions.includes("system.orders.order.write") ? true : false}
                                              customPageSize={10}
                                              className="custom-header-css"
                                            // handlePageClick={handlePageClick}
                                            // customPageCount={pageCount}
                                            />
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </>
                                }
                                {fieldsStep4 && fieldsStep4.length > 0 && <Row>
                                  <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                                    <h4> Pricing Model - Flat Rate </h4>
                                    <br></br>
                                  </Col>
                                </Row>}

                                {fieldsStep4 && fieldsStep4.length > 0 && fieldsStep4.map((item, index) => (
                                  <div key={item.id}>
                                    <Row>

                                      <Col lg="6" md="6" sm="6" xs="6">
                                        <div className="float-start">
                                          <span style={{ fontSize: "15px" }}>   Pricing Model Name: &nbsp; {item.app_pricing_model_name}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>  Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>  Qualifying Value:&nbsp; {item.app_pricing_model_qualifying_value}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>  Discount Type: &nbsp;  {item.app_pricing_model_discount_type}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>  Discount Value: &nbsp;  {item.app_pricing_model_discount_value}</span><br></br>

                                          {/* <h5 > <b> Pricing Model Name: &nbsp; </b> {item.app_pricing_model_name}</h5>
                                          <h5 > <b> Qualifying Type: &nbsp; </b> {item.app_pricing_model_qualifying_type}</h5>
                                          <h5 > <b> Qualifying Value:&nbsp;  </b>{item.app_pricing_model_qualifying_value}</h5>
                                          <h5 > <b> Discount Type: &nbsp;  </b> {item.app_pricing_model_discount_type}</h5>
                                          <h5 > <b> Discount Value: &nbsp;  </b> {item.app_pricing_model_discount_value}</h5> */}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="12">
                                        <Card>
                                          <CardBody>
                                            <TableContainer
                                              columns={proposalMultiColumnstep4}
                                              data={item.flatRateList || []}
                                              customPageSize={10}
                                              className="custom-header-css"
                                            />
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}



                                {fieldsTierStep4 && fieldsTierStep4.length > 0 && <Row>
                                  <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                                    <h4> Pricing Model - Tier</h4>
                                    <br></br>
                                  </Col>
                                </Row>}

                                {fieldsTierStep4 && fieldsTierStep4.length > 0 && fieldsTierStep4.map((item, index) => (
                                  <div key={item.id}>
                                    <Row>
                                      <Col lg="6" md="6" sm="6" xs="6">
                                        <div className="float-start">
                                          <span style={{ fontSize: "15px" }}>Pricing Model Name: &nbsp;{item.app_pricing_model_name}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                          <span style={{ fontSize: "15px" }}>Discount Type: &nbsp; {item.app_pricing_model_discount_type}</span><br></br>
                                          {/* <h5><b> Pricing Model Name: &nbsp; </b>{item.app_pricing_model_name}</h5>
                                          <h5><b> Qualifying Type: &nbsp; </b>{item.app_pricing_model_qualifying_type}</h5>
                                          <h5><b> Discount Type: &nbsp; </b>{item.app_pricing_model_discount_type}</h5> */}
                                          {displayTierValues(item)}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="12">
                                        <Card>
                                          <CardBody>
                                            <TableContainer
                                              columns={proposalMultiColumnstep4}
                                              data={item.tierList || []}
                                              customPageSize={10}
                                              className="custom-header-css"
                                            />
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}

                                <Row className="mt-3">
                                  <Label> <h4>Terms and Conditions</h4> </Label>
                                  <br />
                                  <Col>
                                    <span dangerouslySetInnerHTML={{ __html: step4Data?.terms_and_condition }} />

                                  </Col>
                                </Row>

                                <Row className="mt-3">
                                  <Label> <h4>Payment Methods  </h4>   </Label>
                                  <br />
                                  <Col>
                                    <span dangerouslySetInnerHTML={{ __html: step4Data?.payment_method }} />

                                  </Col>
                                </Row>

                                <Row>
                                  <Col className="d-flex justify-content-end" xs="12">
                                    <h4>
                                      {"Grand Qty " + " : " + `${getGrandTotalQuantitystep3(step4Data?.standardProducts, step4Data?.customProducts, getValues("flatRateProducts"), getValues("tierProducts"),)}`}
                                    </h4>
                                    &nbsp; &nbsp; &nbsp;
                                    <h4 className="me-4">
                                      {"Grand Total " + " : " + " $ " + `${getGrandTotalstep3(step4Data?.standardProducts, step4Data?.customProducts, getValues("flatRateProducts"), getValues("tierProducts"))}`}
                                    </h4>
                                  </Col>
                                </Row>
                                <hr></hr>

                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end"> Required Date<span style={{ color: "red" }}>*</span></Label>
                                      <Col lg="7" md="6">
                                        <input type="date" readOnly={step4Data?.finalize_proposal_date ? true : false}
                                          style={step4Data?.finalize_proposal_date ? { backgroundColor: "#D2D4D1" } : {}}
                                          className={`form form-control`} value={step4Data?.finalize_proposal_date}
                                          {...register3('finalize_proposal_date', { required: true })} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Invoice No  </Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="" value={step4Data?.invoice_keyid} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>



                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end " >Payment File<span className='text-danger'>*</span></Label>
                                      <Col lg="7" md="6">
                                        {!(step4Data?.attachment_file?.length > 0) && <input className='form form-control'
                                          accept='application/pdf, image/png, image/jpeg' multiple type="file" onChange={handleChange}  ref={fileInputRef}/>}
                                        <div className="d-flex flex-wrap">
                                          {images?.map((image, i) => (
                                            <div key={i} className="thumbSpan thumbContainer"  >
                                              <img className='thumbImage ms-3' id="fileImg" src={image?.imgSrc} alt="pdf"
                                                onMouseEnter={() => setRemoveButton(true)}
                                                onClick={() => openImage(image)}
                                                onError={(e) => { e.target.src = pdfImage; }}
                                                style={{
                                                  border: "1px solid #ddd",
                                                  borderRadius: "4px",
                                                  padding: "5px",
                                                  width: "125px",
                                                  height: "125px",
                                                  margin: "15px"
                                                }}
                                              />
                                              {!(step4Data?.attachment_file?.length > 0) && removeButton &&
                                                <button type='button'
                                                  onClick={() => removeBtnClick(i)}
                                                  className='thumbClose btn btn-danger btn-sm'>
                                                  X
                                                </button>
                                              }
                                            </div>
                                          ))}
                                        </div>
                                        {errors.app_payment_file && <span style={{ color: "red" }}>This Field is required</span>}
                                      </Col>


                                    </FormGroup>
                                  </Col>

                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Payment Notes </Label>
                                      <Col lg="7" md="6">
                                        <input className={`form form-control`} placeholder="Payment Notes "
                                          readOnly={step4Data?.payment_notes ? true : false}
                                          style={step4Data?.payment_notes ? { backgroundColor: "#D2D4D1" } : {}}
                                          value={step4Data?.payment_notes}  {...register3('payment_notes', { required: true })} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end"> Approval Status</Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} value={step4Data?.invoice_approval_status != undefined ? step4Data?.invoice_approval_status : " "} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">Remarks</Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} value={step4Data?.finalize_proposal_remarks != undefined ? step4Data?.finalize_proposal_remarks : " "} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                {/* <Row>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end"> CSMS Order ID</Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} value={step4Data?.invoice_approval_status != undefined ? step4Data?.invoice_ : " "} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup row>
                                      <Label lg="5" md="6" className="d-flex justify-content-lg-end justify-content-md-end ">CSMS Order Status</Label>
                                      <Col lg="7" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} value={step4Data?.finalize_proposal_remarks != undefined ? step4Data?.finalize_ : " "} />
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row> */}

                                <div className="actions clearfix  d-flex justify-content-end ">
                                  {activeTab === 4 && (
                                    <h5 className="d-flex justify-content-end mt-3" style={{ marginRight: "30px" }}> </h5>
                                  )}
                                  <Button onClick={() => backButton()} color='danger' type='button' className='m-2'>
                                    Back to Listing
                                  </Button>
                                  <Button color='btn btn-info' type='button' className='m-2' onClick={() => history.push(`/viewOpportunities/${id}?tab=3`)}>
                                    Previous
                                  </Button>
                                </div>

                              </div>

                            </Form>
                          </div>
                        </TabPane>

                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container >
      </div >
    </React.Fragment >
  )
}

export default ViewOpportunities
