import PropTypes from 'prop-types'
import React from "react"
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Col, FormGroup, Label, Modal, ModalBody, Row } from "reactstrap"
import { postFinalizeProposal, updateInvoice, updateOpportunities } from 'store/actions'

const ApproveSubmitInvoiceModal = ({ show, onConfirmClick, acc, onCloseClick, data, onRejectClick, title }) => {

  const location = useLocation()
  const dispatch = useDispatch()

  const parseFloatSymbol = (cell) => {
    const floatValue = parseFloat(cell).toFixed(1);

    if (!isNaN(floatValue)) {
      const displayValue = floatValue >= 0 ? `+${floatValue}` : floatValue;
      return displayValue;
    }
  }

  if (!data || data.grandTotals === null) {
    return <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value="N/A" />;
  }
  
  const handleConfirm = () => {

    let confirmedVersionData = {
      ...data
    };
    dispatch(postFinalizeProposal(confirmedVersionData))

    const Data = {
      invoiceId: data.invoice_id,
      updatedData: {
        invoice_approval_status: "Pending",
        finalize_proposal_date: data.finalize_proposal_date,
        payment_notes: data.payment_notes,
        attachment_file: data.attachment_file
      }
    }

    dispatch(updateInvoice(Data))


    const Datas = {
      opportunitiesId: data.opportunity_id,
      updatedData: {
        status: "Pending Approval"
      }
    }
    
    dispatch(updateOpportunities(Datas))
    onCloseClick();

  }

  return (
    <Modal isOpen={show} backdrop="static" centered={true} size='lg'>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <p className='font-size-18'>Are You Want to Submit for Approval ?</p>

              <>
                {/* <Row>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Account Balance </Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + parseFloat(acc).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Grand Total </Label>
                        <Col lg="7" md="6">
      
                           <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + parseFloat(data.grandTotals).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row> */}

              </>
            </div>
          </Col>
        </Row>


        <Row className="mt-4">
          <FormGroup row>
            <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
            <Col lg="5" md="6">
              <Button color='danger' type='button' className='m-2' onClick={() => { onCloseClick(); }}>
              Close
              </Button>
              <Button color='success' id='createCreditBtn' type='submit' onClick={() => handleConfirm()} className='m-2' >
                Confirm
              </Button>
            </Col>
          </FormGroup>
        </Row>
        {/* <Row>
          <Col>
            <div className="text-center mt-3">

              <button
                type="button"
                className="btn btn-warning ms-2"
                onClick={onCloseClick}
              >
                Confirm
              </button>
            </div>
          </Col>
          <Col>
            <div className="text-center mt-3">

              <button
                type="button"
                className="btn btn-warning ms-2"
                onClick={onCloseClick}
              >
                Close
              </button>
            </div>
          </Col>
        </Row> */}
      </ModalBody>
    </Modal>
  )
}

ApproveSubmitInvoiceModal.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any
}

export default ApproveSubmitInvoiceModal