import {
  GET_APP_COUPON_GENERATE,
  GET_APP_COUPON_GENERATE_FAIL,
  GET_APP_COUPON_GENERATE_SUCCESS,

  POST_APP_COUPON_GENERATE,
  POST_APP_COUPON_GENERATE_FAIL,
  POST_APP_COUPON_GENERATE_SUCCESS,



} from "./actionTypes";


///////////////////////////////////////
//        Get Coupon   Generate     //
///////////////////////////////////////

export const getCouponGenerate = (payload) => ({
  type: GET_APP_COUPON_GENERATE,
  payload: payload
});

export const getCouponGenerateSuccess = couponGenerate => ({
  type: GET_APP_COUPON_GENERATE_SUCCESS,
  payload: couponGenerate
});

export const getCouponGenerateFail = error => ({
  type: GET_APP_COUPON_GENERATE_FAIL,
  payload: error,
});




////////////////////////////////
//    Post Coupon  Generate  ///
////////////////////////////////

export const postCouponGenerate = (payload) => ({
  type: POST_APP_COUPON_GENERATE,
  payload: payload
});

export const postCouponGenerateSuccess = couponGenerate => ({
  type: POST_APP_COUPON_GENERATE_SUCCESS,
  payload: couponGenerate,
});

export const postCouponGenerateFail = error => ({
  type: POST_APP_COUPON_GENERATE_FAIL,
  payload: error,
});




