import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';
import axios from "axios";
import getHeader from "config/header";
import expiredSessionHandler from "common/expiredSessionHandler";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getPricingmodels, getProposalVersion, updateProposalVersion } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Status } from "../../Finance/Invoices/InvoiceListCol";
import { Amount, ProductkeyID, Date, Version, Quantities, Unitprice } from "./OpportunitiesListCol"
import ApproveCustomProductModal from "components/Common/ApprovalModelComponent/ApproveCustomProductModal";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const CustomPricingModelList = props => {


    const query = useQuery();
    const history = useHistory()
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const dispatch = useDispatch();
    const queryMultistatus = query.get("queryMultistatus");
    const Busid = query.get("Busid");
    const [pageCount, setPageCount] = useState(1);


    const [permissions, setPermissions] = useState('')

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])



    const proposalVersionReducerState = useSelector(state => state.ProposalVersionReducer)
    const [confirmProposalList, setConfirmProposalList] = useState([]);
    const [customFilterData, setCustomFilterData] = useState("");

    useEffect(() => {
        handlePageClick()
    }, [page, pageLimit, queryMultistatus, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/custompricingModels?skip=${0}&&limit=${10}&&queryMultistatus=${"all"}`)
        }
    }, [customFilterData])


    //========================global filter=============================//

    const handlePageClick = (index) => {

        // dispatch(getProposalVersion({ filter: `{"confirm_proposal":${true}}`, limit: 0, skip: 0 }))
        let filter1 = `{"confirm_proposal":${false},"$or":[
            {"proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"proposal_version":{"$regex":"${customFilterData}","$options":"i"}}
          ]}`
        let filter2 = `{"approval_custom_pricingmodel_status":"${queryMultistatus}","$or":[
            {"proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"proposal_version":{"$regex":"${customFilterData}","$options":"i"}}
          ]}`

        let skips = 0;
        if (queryMultistatus === "all") {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getProposalVersion({ filter: filter1, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getProposalVersion({ filter: filter1, limit: pageLimit, skip: 0 }));
            }
        } else {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getProposalVersion({ filter: filter2, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getProposalVersion({ filter: filter2, limit: pageLimit, skip: 0 }));
            }
        }
    }


    useEffect(() => {
        if (proposalVersionReducerState.Proposalversions !== null) {

            setConfirmProposalList(proposalVersionReducerState.Proposalversions.result);
            setPageCount(Math.ceil(proposalVersionReducerState.Proposalversions.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(proposalVersionReducerState.Proposalversions.summary.filteredsize / pageLimit)
            );
        }
        if (proposalVersionReducerState.isUpdated) {
            handlePageClick()
        }
    }, [proposalVersionReducerState])

    const [customProductModal, setCustomProductModal] = useState(false);
    const [customDataTBA, setCustomDataTBA] = useState('')

    const onClickApproveCustomProduct = (customData) => {
        setCustomProductModal(true);
        setCustomDataTBA(customData);
    }

    // const handleApproveCustomProduct = () => {

    //     if (customDataTBA.proposal_id) {
    //         const newData = {
    //             proposal_status: "Confirmed",
    //             approval_custom_pricingmodel_status: "Approved",
    //             confirm_proposal: true
    //         }
    //         const updateDatas = {
    //             proposalId: customDataTBA.proposal_id,
    //             updatedData: newData
    //         }
    //         dispatch(updateProposalVersion(updateDatas))
    //         setCustomProductModal(false);
    //     }
    // };

    // const handleRejectCustomProduct = () => {

    //     const customValue = {
    //         proposal_status: "Rejected",
    //         approval_custom_pricingmodel_status: "Rejected",
    //         confirm_proposal: false
    //     }
    //     if (customDataTBA.proposal_id) {

    //         const updateDatas = {
    //             proposalId: customDataTBA.proposal_id,
    //             updatedData: customValue
    //         }
    //         dispatch(updateProposalVersion(updateDatas))
    //         setCustomProductModal(false);
    //     }
    // };



    const columns = useMemo(
        () => [
            {
                Header: 'Proposal ID',
                accessor: 'proposal_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductkeyID  {...cellProps} />;
                }
            },
            {
                Header: 'Opportunities ID',
                accessor: 'opportunity_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductkeyID  {...cellProps} />;
                }
            },
            {
                Header: 'Version',
                accessor: 'proposal_version',
                filterable: true,
                Cell: (cellProps) => {
                    return <Version statusdata={cellProps.row.original} {...cellProps} />;
                }
            },
            {
                Header: 'Date',
                accessor: 'proposal_date',
                filterable: true,
                Cell: (cellProps) => {
                    return <Date  {...cellProps} />;
                }
            },
            {
                Header: 'Quantities ',
                accessor: 'quantities',
                filterable: true,
                Cell: (cellProps) => {
                    return <Quantities {...cellProps} data={cellProps.row.original} />;
                }
            },
            {
                Header: 'Amount',
                accessor: 'grandTotals',
                filterable: true,
                Cell: (cellProps) => {
                    return <Amount {...cellProps} data={cellProps.row.original} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'approval_custom_pricingmodel_status',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {(permissionAction.includes("system.sales.custompricing.read")) &&
                                (permissionAction.includes("system.sales.custompricing.update")) ?
                                <>
                                    <button onClick={() => {
                                        const customData = cellProps.row.original;
                                        onClickApproveCustomProduct(customData);

                                    }} className="btn btn-success btn-sm">View</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                        </div>
                    );
                }
            },
        ],
        [confirmProposalList]
    );




    return (
        <React.Fragment>

            <ApproveCustomProductModal
                show={customProductModal}
                onCloseClick={() => setCustomProductModal(false)}
                // onApproveClick={handleApproveCustomProduct}
                // onRejectClick={handleRejectCustomProduct}
                data={customDataTBA}
                title="Custom PricingModel"
            />

            <div className="page-content">
                {permissions.includes("system.sales.custompricing.read") ? <Container fluid>
                    <Breadcrumbs title="Custom Pricing Models" breadcrumbItem="Products" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={confirmProposalList}
                                        isGlobalFilter={true}
                                        customPageSize={10}
                                        className="custom-header-css"
                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                        handlePageClick={handlePageClick}
                                        // isCompanyDropdownforInvoiceList={true}
                                        customPageCount={pageCount}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>}
            </div>
        </React.Fragment>
    );
};

export default CustomPricingModelList;
