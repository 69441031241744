export const GET_PASSWORD_POLICYS = "GET_PASS_WORD_POLICYS"
export const GET_PASSWORD_POLICYS_SUCCESS = "GET_PASS_WORD_POLICYS_SUCCESS"
export const GET_PASSWORD_POLICYS_FAIL = "GET_PASS_WORD_POLICYS_FAIL"

export const POST_PASSWORD_POLICY = "POST_PASS_WORD_POLICY"
export const POST_PASSWORD_POLICY_SUCCESS = "POST_PASS_WORD_POLICY_SUCCESS"
export const POST_PASSWORD_POLICY_FAIL = "POST_PASS_WORD_POLICY_FAIL"

export const UPDATE_PASSWORD_POLICY = "UPDATE_PASS_WORD_POLICY"
export const UPDATE_PASSWORD_POLICY_SUCCESS = "UPDATE_PASS_WORD_POLICY_SUCCESS"
export const UPDATE_PASSWORD_POLICY_FAIL = "UPDATE_PASS_WORD_POLICY_FAIL"