import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label } from "reactstrap"
import { getAddress, getBusinesses, getDepartments, postAddress, updateAddress, } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';



function createaddress() {

    const history = useHistory()
    const dispatch = useDispatch()
    const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()

    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const UpadatePermission = (permissionAction.includes("system.sales.address.update")) ? false : true
    const DeletePermission = (permissionAction.includes("system.sales.address.delete")) ? false : true

    const [businessList, setBusinessList] = useState([])
    const [departments, setDepartment] = useState([])
    const businessesState = useSelector(state => state.businessReducer)
    const departmentState = useSelector(state => state.departmentReducer)

    useEffect(() => {
        dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: "", skip: '' }))
    }, [])

    useEffect(() => {
        if (businessesState.businesses !== null) {
            const res = businessesState.businesses.result
            setBusinessList(res)
        }
    }, [businessesState])


    useEffect(() => {
        if (departmentState.departments !== null) {
            setDepartment(departmentState.departments.result)
        }
    }, [departmentState])

    const selectBusiness = (e) => {
        setDepartment([])
        let companyID = e.target.value
        dispatch(getDepartments({ companyID, filter: true, limit: 0, skip: 0 }))
    }


    const addressState = useSelector(state => state.addressReducer)

    useEffect(() => {
        if (addressState.isCreated) {
            history.goBack()
        }
    }, [addressState])



    const createAddress = (addressValue) => {
        if (addressValue.app_address_name && addressValue.app_billing_address &&  addressValue.app_delivery_address) {
            // console.log(addressValue);

            let statusValue
            if (addressValue.is_active === "active") {
                statusValue = true
            } else if (addressValue.is_active === "inactive") {
                statusValue = false
            }

            const value = {
                ...addressValue,
                is_active: statusValue,
                app_department_id: addressValue.app_department_id
            }

            const data = {
                companyID: addressValue.app_business_id,
                value
            }
            // console.log(data);
            dispatch(postAddress(data))

        }
    }

    const copyAddr = () => {
        const value = getValues('app_billing_address')
        setValue('app_delivery_address', value)
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Address" breadcrumbItem="Addresses" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createAddress)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select onInput={selectBusiness} className="form-select d-inline" aria-label="Default select example" {...register("app_business_id", { required: true })}>
                                                    <option value="">Company</option>
                                                    {businessList.map((business) => (
                                                        <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Site <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select className={`form-select d-inline `} aria-label="Default select example" {...register("app_department_id", { required: true })}>
                                                    <option hidden value="">Select the Site</option>
                                                    {departments.map((department) => (
                                                        <option key={department.app_department_id} value={department.app_department_id}>{department.app_department_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_department_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Address name <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input className={`form form-control `} placeholder="Enter Address Name" {...register(`app_address_name`, { required: true })} />
                                                {errors.app_address_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                                              Billing  Address <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6" className="position-relative">
                                                <textarea rows={"5"} className={`form form-control`} placeholder="Enter Address"  {...register(`app_billing_address`, { required: true })} />
                                                <i className="far fa-copy position-absolute fa-lg" onClick={() => copyAddr()}
                                                    style={{  right: "20px", top: "15%", transform: "translateY(-50%)", cursor: "pointer" }}  ></i>
                                                {errors.app_billing_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Delivery  Address <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <textarea rows={"5"} className={`form form-control`} placeholder="Enter Address" {...register(`app_delivery_address`, { required: true })} />
                                                {errors.app_delivery_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="5" md="6">
                                                <select disabled={DeletePermission} style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} className="form-select d-inline" {...register('is_active')}>
                                                    {/* <option hidden value="">Select Status</option> */}
                                                    <option value="inactive">INACTIVE</option>
                                                    <option value="active">ACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button id='updateProduct' color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default createaddress

