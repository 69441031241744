import React, { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, FormGroup, Label } from "reactstrap";
import { Filter, DefaultColumnFilter, SelectColumnFilter } from "./filters";
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
import CompanySelect from "./CompanySelect";
import CompanySelectCredit from "./CompanySelectCredit";
import SiteSelectCredit from "./SiteSelectCredit";
import CompanySelectInvoiceList from "./CompanySelectInvoiceList";
import { useDispatch, useSelector } from "react-redux";
import { getSynchronize } from "store/actions";
import { toast } from "react-toastify";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);
    return (
        <Col sm={3}>
            <div className="search-box mb-1 d-inline-block search-pad">
                <div className="position-relative">
                    <label htmlFor="search-bar-0" className="search-label">
                        <span id="search-bar-0-label" className="sr-only">
                            Search this table
                        </span>
                        <input
                            onChange={e => {
                                setValue(e.target.value);
                                onChange(e.target.value);
                            }}
                            id="search-bar-0"
                            type="text"
                            className="form-control"
                            placeholder={`${count} records...`}
                            value={value || ""}
                            size="50"
                        />
                    </label>
                    <i className="bx bx-search-alt search-icon"></i>
                </div>
            </div>
        </Col>
    );
}

let debounceTimer

const TableContainer = ({

    columns, data, isGlobalFilter, isthemeGlobalFilter, customPageSize, pageSizeTransfer, className, handleUserClick,
    handleCompanyClick, customPageSizeOptions, customGlobalFilterDataTransfer, customPageStatus, customPageCount,
    handlePageClick, customPageIndex, isActivityFilter, isStatusDropDown, handleProductClick, pageFilterDataTransfer,
    isCompanyDropdown, isCompanyDropdownforInvoiceList, isCompanyDropdownWithSite, isSiteDropdown, isAddTransaction,
    isTwoColumnTable, 

    handleOrderClicks, handleCustomerClick, handleAddContact, handleAddSite, handleAddAddress, handleAddUser,
    handleAddCustomer, accountID, handleAddtier, accountId,

    isAddUserList, isRolesList, isDepartmentList, isCustomerRoleManagementList, isContractsList, isBusinessUnitsList,
    isBusinessUnitDivision, isAddCustList, isAddCompList, isAddCustomerUserList, isAddContact, isAddSite, isViewModeBtn,
    isAddAddress, isLinkUser, isLinkCustomer, isProductsList, isAddAccountList, isAddPricingModels, isAddTier,
    isAddOrder, isAddOpportunities, isAddCredit, isAddCreditAccount, isAddOptions, handleAddCompList, isViewAllOrder,
    isAddCustomerSites, isAddSalesContact, isAddSalesAddress, isAddReturns, isApplicationTemplate


}) => {

    const {
        getTableProps, getTableBodyProps, headerGroups, page, prepareRow, canPreviousPage, canNextPage,
        pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state, preGlobalFilteredRows, setGlobalFilter,
        state: { pageIndex, pageSize, },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: customPageIndex ? customPageIndex : 0,
                pageSize: customPageSize ? customPageSize : 10,
                sortBy: [
                    // {
                    //   desc: true,
                    // },
                ],
            },
            manualPagination: true,
            pageCount: customPageCount ? customPageCount : null,
            // pageStatus:true
        },

        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    //===================================================================//
    const tableTotal = page.map(item => {
        const { values } = item;
        return { values };
    });

    let totalRow = 0;
    let totalQuantity = 0;
    let grandTotal = 0;
    let totalGrandquantities = 0;

    //==============================subtotal ===========================//


    // value={" $ " + parseFloat(opportunities.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}
    if (tableTotal.length > 0) {
        totalRow = tableTotal.reduce((acc, curr) => {

            if (isNaN(curr.values.rowTotal)) return acc;

            if (curr.values.rowTotal === undefined) return acc;

            return acc + parseFloat(curr.values.rowTotal);
        }, 0).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    } else {
        totalRow = "0"
    }

    if (tableTotal.length > 0) {
        totalQuantity = tableTotal.reduce((acc, curr) => {
            if (isNaN(curr.values.quantity)) return acc;
            if (curr.values.quantity === undefined) return acc;
            return acc + parseFloat(curr.values.quantity);

        }, 0).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    } else {
        totalQuantity = "0";
    }

    //=================================grand total ===================================//
    if (tableTotal.length > 0) {
        grandTotal = tableTotal.reduce((acc, curr) => {

            if (isNaN(curr.values.grandTotals)) return acc;

            if (curr.values.grandTotals === undefined) return acc;

            return acc + parseFloat(curr.values.grandTotals);
        }, 0).toFixed(1);
    } else {
        grandTotal = 0;
    }

    if (tableTotal.length > 0) {
        totalGrandquantities = tableTotal.reduce((acc, curr) => {
            if (isNaN(curr.values.quantities)) return acc;
            if (curr.values.quantities === undefined) return acc;
            return acc + parseFloat(curr.values.quantities);

        }, 0);
    } else {
        totalGrandquantities = 0;
    }


    const history = useHistory()
    const location = useLocation()

    const query = useQuery();
    const skip = query.get("skip")
    const limit = query.get("limit")
    const querystatus = query.get("status")
    const queryMultistatus = query.get("queryMultistatus")
    const Busid = query.get("Busid")
    const tab = query.get("tab")
    const SiteId = query.get("SiteId")
    const opportunityQueryStatus = query.get("opportunityStatus")
    const transactionQueryStatus = query.get("transactionStatus");

    // console.log("transactionQueryStatus",transactionQueryStatus);

    // useEffect(() => {
    //   console.log("pageIndex", pageIndex);
    //   handlePageClick(pageIndex);
    // }, [pageIndex])

    // const currentURL = window.location.href // returns the absolute URL of a page

    // const pathname = window.location.pathname



    const pathname = location.pathname

    const [pageStatus, setPageStatus] = useState(true)
    const [pageMultiStatus, setPageMultiStatus] = useState(queryMultistatus)
    const [opportunityPageStatus, setOpportunityPageStatus] = useState("all")
    const [transactionPageStatus, setTransactionPageStatus] = useState("all")

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
    };


    //======================= Active inActive ================
    const onChangeStatus = event => {
        // customPageStatus(event.target.value)
        setPageStatus(event.target.value)
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany" || pathname.substring(0, 12) === "/viewCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&status=${event.target.value}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}&&Busid=${Busid}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/addresses") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&status=${event.target.value}&&Busid=${Busid}`)
        }
        else {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&status=${event.target.value}`)
        }
    };


    //  Pending Approved Reject 
    const onChangeMultiStatus = (event) => {
        setPageMultiStatus(event.target.value)
        if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}&&Busid=${Busid}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&queryMultistatus=${event.target.value}&&Busid=${Busid}`)
        }
    }


    //  open proposal close 
    const onChangeOpportunityMultiStatus = (event) => {
        setOpportunityPageStatus(event.target.value)
        if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&opportunityStatus=${event.target.value}`)
        }
    }

    const onChangeTransactionMultiStatus = (event) => {
        setTransactionPageStatus(event.target.value)
        if (pathname === "/dashboard") {
            history.push(`${pathname}?skip=${0}&&limit=${limit}&&transactionStatus=${event.target.value}`)
        }
    }

    //   pagelimit 
    const onChangeInSelect = event => {
        // pageSizeTransfer(Number(event.target.value));
        setPageSize(Number(event.target.value));
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany" || pathname.substring(0, 12) === "/viewCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/dashboard") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${Number(event.target.value)}&&transactionStatus=${transactionQueryStatus}`)
        }
        else {
            history.push(`${pathname}?skip=${0}&&limit=${Number(event.target.value)}&&status=${pageStatus}`)
        }
    };

    //  page number inputbox 
    const onChangeInInput = event => {
        const inputValue = Number(event.target.value);
        const page = inputValue ? Math.min(inputValue - 1, pageOptions.length - 1) : 0;

        // const page = event.target.value ? Number(event.target.value) - 1 : 0;
        // gotoPage(page);
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany" || pathname.substring(0, 12) === "/viewCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${page}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${page}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${page}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${page}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/dashboard") {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&transactionStatus=${transactionQueryStatus}`)
        }
        else {
            history.push(`${pathname}?skip=${page}&&limit=${limit}&&status=${pageStatus}`)
        }
    };

    const prevPage = () => {
        // previousPage()
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany" || pathname.substring(0, 12) === "/viewCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) - 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/dashboard") {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&transactionStatus=${transactionQueryStatus}`)
        }

        else {
            history.push(`${pathname}?skip=${Number(skip) - 1}&&limit=${limit}&&status=${pageStatus}`)
        }
    }

    const nxtPage = () => {
        // nextPage()
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany" || pathname.substring(0, 12) === "/viewCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) + 1}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/dashboard") {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&transactionStatus=${transactionQueryStatus}`)
        }

        else {
            history.push(`${pathname}?skip=${Number(skip) + 1}&&limit=${limit}&&status=${pageStatus}`)
        }
    }

    const fistPage = () => {
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany" || pathname.substring(0, 12) === "/viewCompany") {
            history.push(`${pathname}?tab=${tab}&&skip=${(0)}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${(0)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${0}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${(0)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/dashboard") {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&transactionStatus=${transactionQueryStatus}`)
        }
        else {
            history.push(`${pathname}?skip=${(0)}&&limit=${limit}&&status=${pageStatus}`)
        }
    }

    const lastPage = () => {
        if (pathname === "/createCompany" || pathname.substring(0, 14) === "/updateCompany" || pathname.substring(0, 12) === "/viewCompany") {
            history.push(`${pathname}?tab=${tab}&&sskip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}`)
        } else if (pathname === "/credits") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname === "/customerSites" || pathname === "/customerUser" || pathname === "/accountlist") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/pricingModels") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/custompricingModels") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/contacts" || pathname === "/addresses") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}&&SiteId=${SiteId}`)
        } else if (pathname.substring(0, 12) === "/viewaccount") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&Busid=${Busid}`)
        } else if (pathname === "/invoicelist") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/opportunities") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&opportunityStatus=${opportunityPageStatus}`)
        } else if (pathname === "/approvals/opportunities") {
            history.push(`${pathname}?tab=${tab}&&skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}&&Busid=${Busid}`)
        } else if (pathname === "/approvals/pricingmodels") {
            history.push(`${pathname}?tab=${tab}&&skip=${(pageOptions.length - 1)}&&limit=${limit}&&queryMultistatus=${pageMultiStatus}`)
        } else if (pathname === "/approvals/companySites") {
            history.push(`${pathname}?tab=${tab}&&skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}&&Busid=${Busid}`)
        } else if (pathname === "/dashboard") {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&transactionStatus=${transactionQueryStatus}`)
        }
        else {
            history.push(`${pathname}?skip=${(pageOptions.length - 1)}&&limit=${limit}&&status=${pageStatus}`)
        }
    }

    const toCreate = () => {
        if (isAddCompList) {
            handleAddCompList()
        } else if (isRolesList) {
            history.push("/createRole")
        } else if (isAddUserList) {
            history.push("/createUser")
        } else if (isDepartmentList) {
            history.push("/createDepartment")
        } else if (isCustomerRoleManagementList) {
            history.push("/createCustomerRole")
        } else if (isAddCustomerUserList) {
            history.push('/createCustomerUser')
        } else if (isContractsList) {
            history.push('/createContract')
        } else if (isBusinessUnitsList) {
            history.push('/createBusinessUnit')
        } else if (isBusinessUnitDivision) {
            history.push('/createBusinessUnitDivision')
        } else if (isProductsList) {
            history.push('/createProducts')
        } else if (isAddAccountList) {
            history.push('/createAccount')
        } else if (isAddPricingModels) {
            history.push('/createPricingModels')
        } else if (isAddOrder) {
            history.push('/createOrders')
        } else if (isAddOpportunities) {
            history.push('/createOpportunities')
        } else if (isAddCredit) {
            history.push('/createAccounts')
        } else if (isAddCreditAccount) {
            history.push('/#')
        } else if (isAddCustomerSites) {
            history.push('/createCustomerSites')
        } else if (isAddSalesContact) {
            history.push('/createContact')
        } else if (isAddSalesAddress) {
            history.push('/createAddress')
        } else if (isViewAllOrder) {
            history.push('/orders')
        } else if (isAddReturns) {
            history.push('/createReturns')
        } else if (isAddTransaction) {
            history.push(`/createTransaction?AccId=${accountId.AccId}`)
        } if (isApplicationTemplate) {
            history.push('/createApplicationTemplate')
        }
    }

    const [activityFilter, setActivityFilter] = useState({
        app_activity_keyid: "", action: "", module: "", created_on: "", user_name: "", reference: ""
    })

    {
        isActivityFilter && useEffect(() => {
            pageFilterDataTransfer(activityFilter)
        }, [activityFilter])
    }

    const customsFilteredValue = (id, e) => {
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => {
            setActivityFilter(prevState => ({ ...prevState, [id]: e.target.value }))
        }, 1500);

        // pageFilterDataTransfer({ ...activityFilter, [id]: e.target.value })
    }


    const totalRows = page.length;
    const midpoint = Math.ceil(totalRows / 2);

    const dispatch = useDispatch();

    const handlegetSynchronize = () => {

        const today = new Date();

        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        const requestendDate = `${day}-${month}-${year}`;


        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const day2 = String(yesterday.getDate()).padStart(2, '0');
        const month2 = String(yesterday.getMonth() + 1).padStart(2, '0');
        const year2 = yesterday.getFullYear();
        const requeststartDate = `${day2}-${month2}-${year2}`;

        let data = {
            request_start_date: requeststartDate,
            request_end_date: requestendDate
        }

        console.log("data", data);

        dispatch(getSynchronize(data))
    }


    const appSynchronizeState = useSelector(state => state.AppSynchronize)

    useEffect(() => {
        if (appSynchronizeState.appSynchronize !== null) {
            // console.log("appSynchronizeState", appSynchronizeState.appSynchronize.result);
            if (appSynchronizeState.isGet) {
                toast.success(appSynchronizeState.appSynchronize.result)
                appSynchronizeState.isGet = false
            }
        }

    }, [appSynchronizeState])


    return (
        <Fragment>
            <Row className="mb-2 mt-2">
                {(location.pathname !== '/activity' &&
                    (location.pathname !== '/createOpportunities') &&
                    (location.pathname.substring(0, 18) !== '/viewOpportunities') &&
                    (location.pathname !== '/pricingModels') &&
                    (location.pathname !== '/createBusinessUnit') &&
                    (location.pathname !== '/createBusinessUnitDivision') &&
                    (location.pathname !== "/approvals/pricingmodels") &&
                    (location.pathname !== '/opportunities') &&
                    (location.pathname !== '/approvals/opportunities') &&
                    (location.pathname !== '/createPricingModels') &&
                    (location.pathname !== '/invoicelist') &&
                    (location.pathname !== '/custompricingModels') &&
                    (location.pathname !== '/dashboard') &&
                    (location.pathname.substring(0, 19) !== '/updateBusinessUnit') &&
                    (location.pathname.substring(0, 17) !== '/viewBusinessUnit') &&
                    (location.pathname.substring(0, 12) !== '/viewaccount') &&
                    (location.pathname.substring(0, 12) !== '/viewinvoice') &&
                    (location.pathname.substring(0, 20) !== "/updateOpportunities") &&
                    (location.pathname.substring(0, 18) !== "/viewPricingModels") &&
                    (location.pathname.substring(0, 20) !== "/updatePricingModels") &&
                    location.pathname !== '/credits' && isStatusDropDown !== false) &&
                    <Col sm="2">
                        <select onChange={onChangeStatus} value={querystatus} className="form-select d-inline mb-1" aria-label="Default select example" >
                            {/* <option hidden value="">Select Status</option> */}
                            <option value={true}>ACTIVE</option>
                            <option value={false}>INACTIVE</option>
                        </select>
                    </Col>}
                {(location.pathname === '/pricingModels' ||
                    location.pathname === "/approvals/pricingmodels" ||
                    location.pathname === "/invoicelist" ||
                    location.pathname === "/custompricingModels" ||
                    location.pathname.startsWith("/approvals/opportunities") && location.search !== "?tab=1") &&
                    <Col sm="2">
                        <select onChange={onChangeMultiStatus} value={queryMultistatus} className="form-select d-inline mb-1" aria-label="Default select example" >
                            <option value={"all"}>All</option>
                            <option value={"Pending"}>Pending</option>
                            <option value={"Rejected"}>Rejected</option>
                            <option value={"Approved"}>Approved</option>
                        </select>
                    </Col>}

                {(location.pathname === '/opportunities' ||
                    location.pathname.startsWith("/approvals/opportunities") && location.search === "?tab=2") && <Col sm="2">
                        <select onChange={onChangeOpportunityMultiStatus} value={opportunityQueryStatus} className="form-select d-inline mb-1" aria-label="Default select example" >
                            <option value={"all"}>All</option>
                            <option value={"Open"}>Open</option>
                            <option value={"Close"}>Close</option>
                            <option value={"Proposal"}>Proposal</option>
                            <option value={"Pending Approval"}>Pending Approval</option>
                            <option value={"Approved"}>Approved</option>
                            <option value={"Waiting for Custom Approval"}>Waiting for custom Approval</option>
                        </select>
                    </Col>}

                {(location.pathname === '/dashboard') && <Col sm="2">
                    <select onChange={onChangeTransactionMultiStatus} value={transactionQueryStatus} className="form-select d-inline mb-1" aria-label="Default select example" >
                        <option value={"all"}>All</option>
                        <option value={"DEBIT"}>Debit</option>
                        <option value={"DEPOSIT"}>Deposit</option>
                        <option value={"ADJUSTMENT_PLUS"}>Adjustment_plus</option>
                        <option value={"ADJUSTMENT_MINUS"}>Adjustment_minus</option>
                        <option value={"INVOICE"}>Invoice</option>
                    </select>
                </Col>}

                {isCompanyDropdownforInvoiceList && <Col className="mb-1" sm="2"> <CompanySelectInvoiceList /></Col>}
                {isCompanyDropdown && <Col className="mb-1" sm="2"> <CompanySelect /></Col>}
                {isCompanyDropdownWithSite && <Col className="mb-1" sm="2"> <CompanySelectCredit /></Col>}
                {isSiteDropdown && <Col className="mb-1" sm="2"> <SiteSelectCredit /></Col>}

                {((location.pathname !== '/createOrders') &&
                    location.pathname !== '/createPricingModels' &&
                    (location.pathname !== '/createOpportunities') &&
                    (location.pathname.substring(0, 18) !== '/viewOpportunities') &&
                    (location.pathname !== '/updateOrders') &&
                    (location.pathname !== '/activity')) &&
                    (location.pathname !== '/createBusinessUnit') &&
                    (location.pathname !== '/createBusinessUnitDivision') &&
                    (location.pathname.substring(0, 19) !== '/updateBusinessUnit') &&
                    (location.pathname.substring(0, 17) !== '/viewBusinessUnit') &&
                    (location.pathname.substring(0, 12) !== '/viewinvoice') &&
                    (location.pathname.substring(0, 20) !== "/updateOpportunities") &&
                    (location.pathname.substring(0, 20) !== "/updatePricingModels") &&
                    (location.pathname.substring(0, 18) !== "/viewPricingModels") && <Col sm="2">
                        {/* <label className="showLabel" htmlFor="">Show</label> */}
                        <select
                            className="form-select"
                            value={limit}
                            onChange={onChangeInSelect}
                        >
                            {[10, 20, 30, 40, 50, 100].map(pageSizes => (
                                <option key={pageSizes} value={pageSizes}>
                                    Show {pageSizes}
                                </option>
                            ))}
                        </select>
                    </Col>}

                {location.pathname !== '/createPricingModels' &&
                    // (location.pathname.substring(0, 20) !== "/updatePricingModels") &&
                    // (location.pathname.substring(0, 18) !== "/viewPricingModels") &&
                    isGlobalFilter && (
                        <>  {isthemeGlobalFilter && <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />}

                            {!(isthemeGlobalFilter) && <Col sm={3}>
                                <div className="search-box mb-1 d-inline-block search-pad">
                                    <div className="position-relative">
                                        <label htmlFor="search-bar-0" className="search-label">
                                            <span id="search-bar-0-label" className="sr-only">
                                                Search this table
                                            </span>
                                            <input
                                                onChange={e => { customGlobalFilterDataTransfer(e.target.value) }}
                                                id="search-bar-0"
                                                type="text"
                                                className="form-control"
                                                placeholder={`Search records...`}
                                                size="50"
                                            />
                                        </label>
                                        <i className="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </Col>}
                        </>
                    )}

                <>
                    {isAddTransaction && (
                        <Col sm="12">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus " />
                                    Add Transaction
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddSalesAddress && (
                        <Col sm="12">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus " />
                                    Add Address
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddSalesContact && (
                        <Col sm="12">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus " />
                                    Add Contact
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCustomerSites && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Customer Sites
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddTier && (
                        <Col sm="12">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleAddtier}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create Tier
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddPricingModels && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Pricing Models
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddOptions && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleOrderClicks}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Add New Order
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddUserList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Portal User
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddAccountList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Account
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isProductsList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    // onClick={toCreate}
                                    onClick={handlegetSynchronize}
                                >
                                    {/* <i className="mdi mdi-plus me-1" /> */}
                                    Sync Product
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCustomerUserList && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create New User
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCustList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleCustomerClick}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Customers
                                </Button>
                            </div>
                        </Col>
                    )} {isViewModeBtn && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="warning"
                                    className="btn-rounded mb-2 me-2"
                                >
                                    View Mode
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddContact && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddContact}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Contact
                                </Button>
                            </div>
                        </Col>
                    )}

                    {isAddSite && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddSite}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Site
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isRolesList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Roles
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isLinkCustomer && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddCustomer}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Link Customer
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isLinkUser && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddUser}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Link User
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isDepartmentList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                // onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Sites
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isCustomerRoleManagementList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Customer Role
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isContractsList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Contracts
                                </Button>
                            </div>
                        </Col>
                    )}   {isBusinessUnitsList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Business Unit
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isApplicationTemplate && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Template
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isBusinessUnitDivision && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Business Unit Division
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddAddress && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={handleAddAddress}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Address
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCompList && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Create New Customer
                                </Button>
                            </div>
                        </Col>
                    )}

                    {isViewAllOrder && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className=" mb-2 "
                                    onClick={toCreate}
                                >
                                    View All Orders List
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddOrder && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Order
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddOpportunities && (
                        <Col sm="5">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Opportunities
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCredit && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Transaction
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddReturns && (
                        <Col sm="3">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    New Returns
                                </Button>
                            </div>
                        </Col>
                    )}
                    {isAddCreditAccount && (
                        <Col sm="7">
                            <div className="text-sm-end">
                                <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={toCreate}
                                >
                                    <i className="mdi mdi-plus me-1" />
                                    Add Account
                                </Button>
                            </div>
                        </Col>
                    )}
                </>

            </Row>

            {!(isTwoColumnTable) && <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th key={column.id}>
                                        {isActivityFilter && <Row>
                                            <FormGroup row className="d-flex justify-content-around">
                                                <Col lg="12" md="12">
                                                    <Label className="d-flex justify-content-lg-start justify-content-md-start">
                                                        {column.render('Header')}
                                                    </Label>
                                                    <input
                                                        placeholder={`Search ...`}
                                                        type="text"
                                                        className="form form-control"
                                                        onChange={(e) => customsFilteredValue(column.id, e)}
                                                    // {...column.getFilterProps()} 
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Row>}
                                        {!isActivityFilter && <div className="" {...column.getSortByToggleProps()}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr>
                                        {row.cells.map(cell => {
                                            return (
                                                <td key={cell.id} {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </Fragment>
                            );
                        })}

                    </tbody>

                    {((location.pathname === '/createOpportunities' || location.pathname.substring(0, 18) === '/viewOpportunities') ||
                        (location.pathname.substring(0, 12) === '/viewinvoice') || (location.pathname.substring(0, 24) === '/custompricingModels')) && (
                            <tbody>
                                <tr>
                                    {totalQuantity != 0 && totalRow != 0 &&
                                        <>
                                            <td colSpan={headerGroups[0].headers.length - 2}
                                                style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>Sub Total</td>

                                            <td style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>
                                                <span>{totalQuantity}</span>
                                            </td>

                                            <td style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>
                                                <span>$ {totalRow}</span>
                                            </td>
                                        </>
                                    }

                                    {/* {grandTotal !== 0 && totalGrandquantities !== 0 &&
                                    <>
                                        <td colSpan={headerGroups[0].headers.length - 3}
                                            style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>Grand Total</td>

                                        <td style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>
                                            <span>{totalGrandquantities}</span>
                                        </td>

                                        <td style={{ textAlign: "right", fontWeight: "bold", fontSize: "15px" }}>
                                            <span>$ {grandTotal}</span>
                                        </td>

                                    </>
                                } */}

                                </tr>
                            </tbody>
                        )}
                </Table>
            </div>}


            {isTwoColumnTable && <div className="table-responsive react-table">
                <div className="d-flex">
                    <div className="flex-fill">
                        <Table bordered hover {...getTableProps()} className={className}>
                            <thead className="table-light table-nowrap">
                                {headerGroups.map(headerGroup => (
                                    <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th key={column.id}>
                                                {isActivityFilter && (
                                                    <Row>
                                                        <FormGroup row className="d-flex justify-content-around">
                                                            <Col lg="12" md="12">
                                                                <Label className="d-flex justify-content-lg-start justify-content-md-start">
                                                                    {column.render('Header')}
                                                                </Label>
                                                                <input
                                                                    placeholder={`Search ...`}
                                                                    type="text"
                                                                    className="form form-control"
                                                                    onChange={(e) => customsFilteredValue(column.id, e)}
                                                                // {...column.getFilterProps()} 
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Row>
                                                )}
                                                {!isActivityFilter && (
                                                    <div className="" {...column.getSortByToggleProps()}>
                                                        {column.render("Header")}
                                                        {generateSortingIndicator(column)}
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {page.slice(0, midpoint).map(row => {
                                    prepareRow(row);
                                    return (
                                        <Fragment key={row.getRowProps().key}>
                                            <tr>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td key={cell.id} {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>

                    {page.length > 1 && <div className="flex-fill">
                        <Table bordered hover {...getTableProps()} className={className}>
                            <thead className="table-light table-nowrap">
                                {headerGroups.map(headerGroup => (
                                    <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th key={column.id}>
                                                {isActivityFilter && (
                                                    <Row>
                                                        <FormGroup row className="d-flex justify-content-around">
                                                            <Col lg="12" md="12">
                                                                <Label className="d-flex justify-content-lg-start justify-content-md-start">
                                                                    {column.render('Header')}
                                                                </Label>
                                                                <input
                                                                    placeholder={`Search ...`}
                                                                    type="text"
                                                                    className="form form-control"
                                                                    onChange={(e) => customsFilteredValue(column.id, e)}
                                                                // {...column.getFilterProps()} 
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </Row>
                                                )}
                                                {!isActivityFilter && (
                                                    <div className="" {...column.getSortByToggleProps()}>
                                                        {column.render("Header")}
                                                        {generateSortingIndicator(column)}
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {page.slice(midpoint, totalRows).map(row => {
                                    prepareRow(row);
                                    return (
                                        <Fragment key={row.getRowProps().key}>
                                            <tr>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td key={cell.id} {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </div>}
                </div>

            </div>}

            {/* <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>

                    {isActivityFilter && <Filter column={column} />} 
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div> */}

            {(location.pathname !== '/createOpportunities') &&
                (location.pathname.substring(0, 18) !== '/viewOpportunities') &&
                (location.pathname !== '/createPricingModels') &&
                (location.pathname.substring(0, 20) !== "/updateOpportunities") &&
                (location.pathname.substring(0, 20) !== "/updatePricingModels") &&
                (location.pathname.substring(0, 18) !== "/viewPricingModels") &&
                (location.pathname.substring(0, 12) !== '/viewinvoice') &&



                (location.pathname !== '/createBusinessUnit') &&
                (location.pathname !== '/createBusinessUnitDivision') &&
                (location.pathname.substring(0, 19) !== '/updateBusinessUnit') &&
                (location.pathname.substring(0, 17) !== '/viewBusinessUnit') &&

                <Row className="justify-content-md-end justify-content-center mt-2 align-items-center">
                    <Col className="col-md-auto">
                        <div className="d-flex gap-1">
                            <Button
                                color="primary"
                                // onClick={() => gotoPage(0)}
                                onClick={fistPage}
                                disabled={skip <= 0 ? true : false}
                            >
                                {"<<"}
                            </Button>
                            <Button
                                color="primary"
                                // onClick={previousPage}
                                onClick={prevPage}
                                disabled={skip <= 0 ? true : false}
                            >
                                {"<"}
                            </Button>
                        </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                        Page{" "}
                        <strong>
                            {Number(skip) + 1} of {pageOptions.length}
                        </strong>
                    </Col>
                    <Col className="col-md-auto">
                        <input
                            className='form form-control'
                            type="number"
                            min={1}
                            style={{ width: 70, textAlign: "center" }}
                            // max={pageOptions.length}
                            max={"50"}
                            // defaultValue={pageIndex + 1  }
                            value={Number(skip) + 1}
                            onChange={onChangeInInput}
                        />
                    </Col>
                    <Col className="col-md-auto">
                        <div className="d-flex gap-1">
                            <Button color="primary"
                                //  onClick={nextPage}
                                onClick={nxtPage}
                                disabled={skip == (pageOptions.length - 1) ? true : false}>
                                {">"}
                            </Button>
                            <Button
                                color="primary"
                                // onClick={() => gotoPage(pageCount - 1)}
                                onClick={lastPage}
                                disabled={skip == (pageOptions.length - 1) ? true : false}
                            >
                                {">>"}
                            </Button>
                        </div>
                    </Col>
                </Row>}
        </Fragment>
    );
};

TableContainer.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
