/* EVENTS */

// Get business Unit
export const GET_FINALIZEPROPOSAL = "GET_FINALIZEPROPOSAL";
export const GET_FINALIZEPROPOSAL_SUCCESS = "GET_FINALIZEPROPOSAL_SUCCESS";
export const GET_FINALIZEPROPOSAL_FAIL = "GET_OPPORTUNITIES_FAIL"

// Get Business Unit by id
export const GET_FINALIZEPROPOSAL_DETAIL = "GET_FINALIZEPROPOSAL_DETAIL";
export const GET_FINALIZEPROPOSAL_DETAIL_SUCCESS = "GET_FINALIZEPROPOSAL_DETAIL_SUCCESS";
export const GET_FINALIZEPROPOSAL_DETAIL_FAIL = "GET_FINALIZEPROPOSAL_DETAIL_FAIL";

//  Business Unit
export const POST_FINALIZEPROPOSAL = "POST_FINALIZEPROPOSAL";
export const POST_FINALIZEPROPOSAL_SUCCESS = "POST_FINALIZEPROPOSAL_SUCCESS";
export const POST_FINALIZEPROPOSAL_FAIL = "POST_FINALIZEPROPOSAL_FAIL"

// Update confirm finalize proposal
export const CONFIRM_FINALIZEPROPOSAL = "CONFIRM_FINALIZEPROPOSAL";
export const CONFIRM_FINALIZEPROPOSAL_SUCCESS = "CONFIRM_FINALIZEPROPOSAL_SUCCESS";
export const CONFIRM_FINALIZEPROPOSAL_FAIL = "CONFIRM_FINALIZEPROPOSAL_FAIL"

