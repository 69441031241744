import { takeEvery, put, call } from "redux-saga/effects"
import {
  getSynchronizeSuccess,
  getSynchronizeFail
} from "./actions"

import {
    getSynchronize
} from "helpers/backendApi_helpers";

import {
    GET_APP_SYNCHRONIZE,
} from "./actionTypes";

function* fetchAppSynchronize({ payload: payload }) {
  try {
    const response = yield call(getSynchronize, payload);
    yield put(getSynchronizeSuccess(response))
  } catch (error) {
    yield put(getSynchronizeFail(error))
  }
}

function* AppSynchronizeSaga() {
  yield takeEvery(GET_APP_SYNCHRONIZE, fetchAppSynchronize);
}

export default AppSynchronizeSaga

