import { takeEvery, put, call } from "redux-saga/effects"
import {
postProposalCloneVersionSuccess,
postProposalCloneVersionFail
} from "./actions"

import {
  postProposalCloneVersion
}
  from "helpers/backendApi_helpers";

import {
POST_PROPOSAL_CLONE_VERSION,
} from "./actionTypes";


function* postAppProposalCloneVersion({ payload: payload }) {
  try {
    const response = yield call(postProposalCloneVersion, payload);
    yield put(postProposalCloneVersionSuccess(response))
  } catch (error) {
    yield put(postProposalCloneVersionFail(error))
  }
}
 
function* AppProposalCloneVersionSaga() {
  yield takeEvery(POST_PROPOSAL_CLONE_VERSION, postAppProposalCloneVersion)
}

export default AppProposalCloneVersionSaga





