export const GET_BUSINESS_UNIT_DIVISIONS = "GET_BUSINESS_UNIT_DIVISIONS"
export const GET_BUSINESS_UNIT_DIVISIONS_SUCCESS = "GET_BUSINESS_UNIT_DIVISIONS_SUCCESS"
export const GET_BUSINESS_UNIT_DIVISIONS_FAIL = "GET_BUSINESS_UNIT_DIVISIONS_FAIL"

export const POST_BUSINESS_UNIT_DIVISION = "POST_BUSINESS_UNIT_DIVISION"
export const POST_BUSINESS_UNIT_DIVISION_SUCCESS = "POST_BUSINESS_UNIT_DIVISION_SUCCESS"
export const POST_BUSINESS_UNIT_DIVISION_FAIL = "POST_BUSINESS_UNIT_DIVISION_FAIL"

export const DELETE_BUSINESS_UNIT_DIVISION = "DELETE_BUSINESS_UNIT_DIVISION"
export const DELETE_BUSINESS_UNIT_DIVISION_SUCCESS = "DELETE_BUSINESS_UNIT_DIVISION_SUCCESS"
export const DELETE_BUSINESS_UNIT_DIVISION_FAIL = "DELETE_BUSINESS_UNIT_DIVISION_FAIL"

export const GET_BUSINESS_UNIT_DIVISION = "GET_BUSINESS_UNIT_DIVISION"
export const GET_BUSINESS_UNIT_DIVISION_SUCCESS = "GET_BUSINESS_UNIT_DIVISION_SUCCESS"
export const GET_BUSINESS_UNIT_DIVISION_FAIL = "GET_BUSINESS_UNIT_DIVISION_FAIL"

export const UPDATE_BUSINESS_UNIT_DIVISION = "UPDATE_BUSINESS_UNIT_DIVISION"
export const UPDATE_BUSINESS_UNIT_DIVISION_SUCCESS = "UPDATE_BUSINESS_UNIT_DIVISION_SUCCESS"
export const UPDATE_BUSINESS_UNIT_DIVISION_FAIL = "UPDATE_BUSINESS_UNIT_DIVISION_FAIL"