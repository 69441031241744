import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { isEmpty, map } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import logo from "../../../assets/images/maxims/maxims.png";
import fontFile from './NotoSansSC-Regular.ttf';

Font.register({
  family: 'NotoSansSC',
  src: fontFile,
});

let grandTotals

const QuotationDownload = ({ quotationData }) => {
  const [standardProductList, setStandardProductList] = useState([]);
  const [customProductList, setCustomProductList] = useState([]);
  const [flatRateProductList, setFlatRateProductList] = useState([]);
  const [tierProductList, setTierProductList] = useState([]);


  useEffect(() => {
    if (quotationData.standardProducts !== null && quotationData.standardProducts.length > 0) {
      setStandardProductList(quotationData?.standardProducts);
    }

    if (quotationData.customProducts !== null && quotationData.customProducts.length > 0) {
      setCustomProductList(quotationData?.customProducts);
    }
    if (quotationData.flatRateProducts !== null && quotationData.flatRateProducts !== undefined) {
      setFlatRateProductList(quotationData?.flatRateProducts);
    }

    if (quotationData.tierProducts !== null && quotationData.tierProducts !== undefined) {
      setTierProductList(quotationData?.tierProducts);
    }
  }, [quotationData])


  const styles = StyleSheet.create({
    bold3: {
      fontWeight: "heavy",
      color: "black"
    },

    text: {
      fontFamily: 'NotoSansSC',
      fontSize: 12,
    },

    underline: {
      textDecoration: 'underline',
    },
    viewer: {
      paddingTop: 32,
      width: "100%",
      height: "80vh",
      border: "none",
    },
    page: {
      display: "flex",
      padding: "0.4in 0.4in",
      fontSize: 12,
      color: "#333",
      backgroundColor: "#fff",
    },

    invoiceText: {
      color: "#3aabf0",
    },
    invoiceId: {
      textAlign: "center",
    },
    dividerSM: {
      width: "100%",
      height: 1,
      marginTop: 12,
      marginBottom: 12,
      backgroundColor: "#e5e5e5",
    },
    dividerLG: {
      width: "100%",
      height: 1,
      marginTop: 20,
      marginBottom: 20,
      backgroundColor: "#e5e5e5",
    },
    table: {
      marginTop: 10,
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row",
      textAlign: "center",
      backgroundColor: "#EFF2F7"
    },
    tableHeaderItem: {
      paddingVertical: 8,
      border: "1px solid #e5e5e5",
      borderBottom: "none",
      textAlign: "start", // Added textAlign
      fontSize: 11,
      paddingLeft: 5,
      color: "black",
      fontWeight: "heavy"
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
    },
    tableCol: {
      paddingVertical: 8,
      paddingHorizontal: 4,
      border: "1px solid #e5e5e5",
      textAlign: "start", // Added textAlign
      fontSize: 10
    },
    totalContainer: {
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginTop: 16,
      textAlign: "right", // Added textAlign
    },
    footer: {
      borderTop: "1px solid #e5e5e5",
      paddingTop: 8,
      marginTop: "auto",
    },
    footerText: {
      color: "#787878",
      lineHeight: 1.5,
      textAlign: "center", // Adjusted textAlign
    },
    invoiceFormCompany: {
      paddingLeft: 17
    },
    invoiceTextNumberContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    invoiceForFromContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    invoiceFor: {
      flex: 1.5,
      flexDirection: 'row',
      justifyContent: 'flex-start'
    },
    invoiceForFromTitle: {
      flexDirection: 'row',
      justifyContent: "flex-end",
      width: '35%',
      marginBottom: 8
    },
    invoiceForFromTitle2: {
      flexDirection: 'row',
      justifyContent: "flex-end",
      width: '65%',
      marginBottom: 8
    },
    invoiceForFromText: {
      color: "#787878",
      lineHeight: 1.5,
      width: '65%',
      textAlign: "left" // Adjusted textAlign
    },
    paymentText: {
      color: "#787878",
      lineHeight: 1.5,
      width: '100%',
      textAlign: "left" // Adjusted textAlign
    },
    invoiceHeader: {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center", // Added textAlign
    },
    invoicePayment: {
      marginTop: 20,
      textAlign: "center", // Added textAlign
    },
    totalValue: {
      justifyContent: "flex-end",
      alignItems: "flex-end",
      textAlign: "right", // Added textAlign
    },
    invoiceFrom: {
      flex: 1,
    },
    textContent: {
      // paddingLeft:5,
      fontSize: 10
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    inputBox: {
      borderBottomWidth: 1,
      borderColor: '#000',
      marginBottom: 10
    }
  });


  const getSubTotal = (data) => {
    let total = 0
    data?.forEach(item => total += parseFloat(item.rowTotal ? item.rowTotal : 0))

    return parseFloat(total).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }

  const getQuantity = (data) => {
    let total = 0
    data?.forEach(item => total += parseFloat(item.quantity ? item.quantity : 0))
    return parseFloat(total).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }


  const getGrandTotal = (standardProductList, customProductList, flatRateProductList, tierProductList) => {
    let total1 = 0;
    standardProductList?.forEach(item => total1 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total2 = 0;
    customProductList?.forEach(item => total2 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total3 = 0;
    flatRateProductList?.forEach(item => total3 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total4 = 0;
    tierProductList?.forEach(item => total4 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let grandTotal = total1 + total2 + total3 + total4;

    grandTotals = parseFloat(grandTotal).toFixed(1)
    return parseFloat(grandTotals).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }

  const displayTierValues = (tierProductList) => {
    const tiers = tierProductList?.tiers;

    const tierValues = tiers?.map((tier, index) => {
      const startRange = index === 0 ? 1 : parseInt(tiers[index - 1]?.app_tier_qualifying_input || 0) + 1;
      const endRange = index === tiers.length - 1 ? "Infinity" : tier.app_tier_qualifying_input;
      return (
        <View key={tier.localID}>
          <View >
            <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]} >{"\n"} Qualifying Value: {startRange} - {endRange}: Discount: {tier.app_tier_discount_value} {"\n"}</Text>
          </View>
        </View>
      );
    });
    return tierValues;
  }


  // const parseRichText = (richText, listItemNumber = 1) => {
  //   const containsHtmlTags = /<[^>]+>/g.test(richText);

  //   if (!containsHtmlTags) {
  //     return <Text>{richText}</Text>;
  //   }

  //   const htmlParser = new DOMParser();
  //   const parsedHtml = htmlParser.parseFromString(richText, 'text/html');
  //   const elements = parsedHtml.body.childNodes;

  //   const renderElements = (nodes, listType, parentNode) => {
  //     return Array.from(nodes).map((node, index) => {
  //       if (node.nodeType === 1) {
  //         const tagName = node.tagName.toLowerCase();
  //         if (tagName === 'br') {
  //           return ' ';
  //         } else if (tagName === 'strong') {

  //           return (
  //             <Text key={index} style={styles.bold3}>
  //               {renderElements(node.childNodes, listType, node)}
  //             </Text>
  //           );
  //         } else if (tagName === 'i' || tagName === 'em') {
  //           return (
  //             <Text key={index} style={styles.italic}>
  //               {renderElements(node.childNodes, listType, parentNode)}
  //             </Text>
  //           );
  //         } else if (tagName === 'ol' || tagName === 'ul') {
  //           return (
  //             <View key={index} style={styles.listContainer}>
  //               {renderElements(node.childNodes, tagName, node)}
  //             </View>
  //           );
  //         } else if (tagName === 'li') {
  //           const hasStrongChild = Array.from(node.childNodes).some(child => child.tagName && child.tagName.toLowerCase() === 'strong');
  //           const listItem = (
  //             <Text key={index} style={hasStrongChild === true ? styles.bold3 : styles.listItem}>
  //               {listType === 'ol' ? `${listItemNumber++}. ` : '• '} {node.textContent.trim()}
  //             </Text>
  //           );

  //           return listItem;
  //         } else if (tagName === 'p') {
  //           return (
  //             <Text key={index} style={styles.paragraph}>
  //               {renderElements(node.childNodes, listType, parentNode)}
  //             </Text>
  //           );
  //         } else if (tagName === 'span') {
  //           const inlineStyle = node.getAttribute('style');
  //           const style = inlineStyle ? inlineStyle.split(';').reduce((acc, curr) => {
  //             const [property, value] = curr.split(':').map(prop => prop.trim());
  //             acc[property] = value;
  //             return acc;
  //           }, {}) : {};

  //           return (
  //             <Text key={index} style={{ ...styles.span, ...style }}>
  //               {node.textContent}
  //             </Text>
  //           );
  //         } else if (tagName === 'u') {
  //           return (
  //             <Text key={index} style={styles.underline}>
  //               {renderElements(node.childNodes, listType, parentNode)}
  //             </Text>
  //           );
  //         }
  //       } else if (node.nodeType === 3) {
  //         return node.textContent.trim();
  //       } else {
  //         return null;
  //       }
  //     });
  //   };

  //   return renderElements(elements);
  // };


  const parseRichText = (richText, listItemNumber = 1) => {
    const containsHtmlTags = /<[^>]+>/g.test(richText);

    if (!containsHtmlTags) {
      return <Text>{richText}</Text>;
    }

    const htmlParser = new DOMParser();
    const parsedHtml = htmlParser.parseFromString(richText, 'text/html');
    const elements = parsedHtml.body.childNodes;

    const renderElements = (nodes, listType, parentNode) => {
      return Array.from(nodes).map((node, index) => {
        if (node.nodeType === 1) {
          const tagName = node.tagName.toLowerCase();
          if (tagName === 'br') {
            return ' ';
          } else if (tagName === 'strong') {
            // Extract style from inline style attribute
            const inlineStyle = node.getAttribute('style');
            const style = {};
            if (inlineStyle) {
              const styleList = inlineStyle.split(';').map(prop => prop.trim());
              styleList.forEach(prop => {
                const [property, value] = prop.split(':').map(p => p.trim());
                style[property] = value;
              });
            }

            return (
              <Text key={index} style={[styles.bold3, style]}>
                {renderElements(node.childNodes, listType, node)}
              </Text>
            );
          } else if (tagName === 'i' || tagName === 'em') {
            return (
              <Text key={index} style={styles.italic}>
                {renderElements(node.childNodes, listType, parentNode)}
              </Text>
            );
          } else if (tagName === 'ol' || tagName === 'ul') {
            return (
              <View key={index} style={styles.listContainer}>
                {renderElements(node.childNodes, tagName, node)}
              </View>
            );
          } else if (tagName === 'li') {
            const hasStrongChild = Array.from(node.childNodes).some(child => child.tagName && child.tagName.toLowerCase() === 'strong');
            const listItem = (
              <Text key={index} style={hasStrongChild === true ? styles.bold3 : styles.listItem}>
                {listType === 'ol' ? `${listItemNumber++}. ` : '• '} {node.textContent.trim()}
              </Text>
            );

            return listItem;
          } else if (tagName === 'p') {
            return (
              <Text key={index} style={styles.paragraph}>
                {renderElements(node.childNodes, listType, parentNode)}
              </Text>
            );
          } else if (tagName === 'span') {
            // Extract style from inline style attribute
            const inlineStyle = node.getAttribute('style');
            const style = {};
            if (inlineStyle) {
              const styleList = inlineStyle.split(';').map(prop => prop.trim());
              styleList.forEach(prop => {
                const [property, value] = prop.split(':').map(p => p.trim());
                style[property] = value;
              });
            }

            return (
              <Text key={index} style={[styles.span, style]}>
                {node.textContent}
              </Text>
            );
          } else if (tagName === 'u') {
            return (
              <Text key={index} style={styles.underline}>
                {" "}
                {renderElements(node.childNodes, listType, parentNode)}
                {" "}
              </Text>
            );
          }
        } else if (node.nodeType === 3) {
          return node.textContent.trim();
        } else {
          return null;
        }
      });
    };

    return renderElements(elements);
  };
  const templateInformation = quotationData.template_information
  const termsCondition = quotationData.terms_and_condition
  const paymentMethod = quotationData.payment_method

  return (
    <Document>
      <Page style={styles.page} size="A4">

        <View style={styles.invoiceHeader}>
          <Image src={logo} style={{ width: "80", height: "30" }} />
          <Text style={{ marginTop: "5", fontSize: "15" }}>{`Quotation`}</Text>
        </View>

        <View style={styles.dividerLG} />


        <View style={styles.invoiceForFromContainer}>

          <View style={styles.invoiceFor}>
            <View style={styles.invoiceForFromTitle}>
              <Text>Company : </Text>
            </View>

            <View style={styles.invoiceForFromText}>
              <Text style={styles.textContent}>{quotationData.app_business_name} | {quotationData.app_department_name} </Text>
            </View>
          </View>
          <View style={styles.invoiceFor}>
            <View style={styles.invoiceForFromTitle2}>
              <Text>Quotation Id : </Text>
            </View>

            <View style={styles.invoiceForFromText}>
              <Text style={styles.textContent}>{quotationData.quotation_keyid}</Text>
            </View>
          </View>
        </View>


        <View style={styles.invoiceForFromContainer}>
          <View style={styles.invoiceFor}>
            <View style={styles.invoiceForFromTitle}>
              <Text>Billing Details : </Text>
            </View>

            <View style={styles.invoiceForFromText}>
              <Text style={styles.textContent}>{quotationData.app_billing_contact_name}</Text>
              <Text style={styles.textContent}>{quotationData.app_billing_address}</Text>
            </View>
          </View>

          <View style={styles.invoiceFor}>
            <View style={styles.invoiceForFromTitle2}>
              <Text>Date : </Text>
            </View>

            <View style={styles.invoiceForFromText}>
              <Text style={styles.textContent}>{quotationData.opportunity_date}</Text>
            </View>
          </View>
        </View>

        <View style={styles.invoiceForFromContainer}>
          <View style={styles.invoiceFor}>
            <View style={styles.invoiceForFromTitle}>
              <Text>Delivery Details : </Text>
            </View>

            <View style={styles.invoiceForFromText}>
              <Text style={styles.textContent}>{quotationData.app_delivery_contact_name}</Text>
              <Text style={styles.textContent}>{quotationData.app_delivery_address}</Text>
            </View>
          </View>

          <View style={styles.invoiceFor}>
            <View style={styles.invoiceForFromTitle2}>
              <Text>Reference : </Text>
            </View>

            <View style={styles.invoiceForFromText}>
              <Text style={styles.textContent}>Remarks Here</Text>
            </View>
          </View>
        </View>


        <View style={styles.dividerLG} />


        <View style={styles.invoiceForFromContainer}>
          <Text style={[styles.invoiceForFromText, { marginTop: "10" }, { color: "black" }, { fontWeight: "heavy" }]}>Template Information</Text>
        </View>

        {/* <View style={styles.invoiceForFromText2}>
          <Text style={styles.textContent}> {quotationData.template_information}</Text>
        </View> */}

        <View style={styles.textContent}>
          {parseRichText(templateInformation)}
        </View>

        {
          standardProductList.length > 0 ? (
            <>
              <View style={styles.invoiceForFromContainer}>
                <Text style={[styles.invoiceForFromText, { marginTop: "10" }, { color: "black" }, { fontWeight: "heavy" }]}>Pricing Model - Standard</Text>
              </View>

              <View style={styles.table}>
                <View style={styles.tableHeader}>
                  <Text style={[styles.tableHeaderItem, { width: "35%" }]}> Product</Text>
                  <Text style={[styles.tableHeaderItem, { width: "13%" }]}>Unit Price</Text>
                  <Text style={[styles.tableHeaderItem, { width: "13%" }]}>Discount</Text>
                  <Text style={[styles.tableHeaderItem, { width: "14%" }]}>Discounted Unit Price</Text>
                  <Text style={[styles.tableHeaderItem, { width: "9%" }]}>Qty</Text>
                  <Text style={[styles.tableHeaderItem, { width: "16%" }]}>Sub total</Text>
                </View>
                {standardProductList?.map((item) => {
                  return (
                    <>
                      <View key={item.id} style={styles.tableRow}>
                        <Text style={[styles.tableCol, styles.text, { width: "35%" }, { color: "#49535C" }]}>{item.app_product_local_name}</Text>
                        <Text style={[styles.tableCol, { width: "13%", textAlign: 'right' }, { color: "#49535C" }]}>{item.pack_unit}</Text>
                        <Text style={[styles.tableCol, { width: "13%", textAlign: 'right' }, { color: "#49535C" }]}>{item.discount}</Text>
                        <Text style={[styles.tableCol, { width: "14%", textAlign: 'right' }, { color: "#49535C" }]}>{"$" + " " + parseFloat(item.discounted_unit_price).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                        <Text style={[styles.tableCol, { width: "9%", textAlign: 'right' }, { color: "#49535C" }]}>{parseFloat(item.quantity).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                        <Text style={[styles.tableCol, { width: "16%", textAlign: 'right' }, { color: "#49535C" }]}> {" $" + " " + parseFloat(item.rowTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                      </View>
                    </>
                  );
                })}

                <View style={[styles.tableRow, { justifyContent: 'flex-end' }]}>
                  <Text style={[styles.tableCol, { width: '75%', textAlign: "right", alignItems: "flex-end", justifyContent: 'flex-end', fontWeight: "bold", fontSize: "13px" }]}>Sub Total</Text>
                  <Text style={[styles.tableCol, { width: '9%', textAlign: 'right', fontSize: "13", fontWeight: "bold" }]}>{getQuantity(standardProductList)}</Text>
                  <Text style={[styles.tableCol, { width: '16%', textAlign: 'right', fontSize: "13", fontWeight: "bold" }]}>{" $ " + " " + getSubTotal(standardProductList)}</Text>
                </View>
              </View>
            </>) : null
        }


        {
          customProductList.length > 0 ? (<>
            <View style={styles.invoiceForFromContainer}>
              <Text style={[styles.invoiceForFromText, { marginTop: "25" }, { marginBottom: "1" }, { color: "black" }, { fontWeight: "heavy" }]}>Pricing Model - Custom</Text>
            </View>

            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={[styles.tableHeaderItem, { width: "35%" }]}> Product</Text>
                <Text style={[styles.tableHeaderItem, { width: "13%" }]}>Unit Price</Text>
                <Text style={[styles.tableHeaderItem, { width: "13%" }]}>Discount</Text>
                <Text style={[styles.tableHeaderItem, { width: "14%" }]}>Discounted Unit Price</Text>
                <Text style={[styles.tableHeaderItem, { width: "9%" }]}>Qty</Text>
                <Text style={[styles.tableHeaderItem, { width: "16%" }]}>Sub total</Text>
              </View>
              {customProductList?.map((item) => {
                return (
                  <>
                    <View key={item.id} style={styles.tableRow}>
                      <Text style={[styles.tableCol, styles.text, { width: "35%" }, { color: "#49535C" }]}>{item.app_product_local_name}</Text>
                      <Text style={[styles.tableCol, { width: "13%", textAlign: 'right' }, { color: "#49535C" }]}>{item.pack_unit}</Text>
                      <Text style={[styles.tableCol, { width: "13%", textAlign: 'right' }, { color: "#49535C" }]}>{item.discount}</Text>
                      <Text style={[styles.tableCol, { width: "14%", textAlign: 'right' }, { color: "#49535C" }]}>{"$" + " " + parseFloat(item.discounted_unit_price).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                      <Text style={[styles.tableCol, { width: "9%", textAlign: 'right' }, { color: "#49535C" }]}>{parseFloat(item.quantity).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                      <Text style={[styles.tableCol, { width: "16%", textAlign: 'right' }, { color: "#49535C" }]}>{"$" + " " + parseFloat(item.rowTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                    </View>
                  </>
                );
              })}
              <View style={[styles.tableRow, { justifyContent: 'flex-end' }]}>
                <Text style={[styles.tableCol, { width: '100%', textAlign: "right", alignItems: "flex-end", justifyContent: 'flex-end', fontWeight: "bold", fontSize: "13px" }]}>Sub Total</Text>
                <Text style={[styles.tableCol, { width: '20%', textAlign: 'right', fontSize: "13", fontWeight: "bold" }]}>{getQuantity(customProductList)}</Text>
                <Text style={[styles.tableCol, { width: '20%', textAlign: 'right', fontSize: "13", fontWeight: "bold" }]}>{" $ " + " " + getSubTotal(customProductList)}</Text>
              </View>
            </View>
          </>) : null
        }
        {
          flatRateProductList.map((flatRateProduct, index) => (
            flatRateProduct.flatRateList.length > 0 ? (
              <View key={index}>
                <View style={styles.invoiceForFromContainer}>
                  <Text style={[styles.invoiceForFromText, { marginTop: 25 }, { marginBottom: 1 }, { color: "black" }, { fontWeight: "heavy" }]}>Pricing Model - Flat Rate</Text>
                </View>


                <View key={flatRateProduct.id}>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  Pricing Model Name : {flatRateProduct.app_pricing_model_name}</Text>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  Qualifying Type : {flatRateProduct.app_pricing_model_qualifying_type}</Text>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  Qualifying Value : {flatRateProduct.app_pricing_model_qualifying_value}</Text>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  Discount Type : {flatRateProduct.app_pricing_model_discount_type}</Text>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  Discount Value : {flatRateProduct.app_pricing_model_discount_value}</Text>
                </View>


                <View style={styles.table}>
                  <View style={styles.tableHeader}>
                    <Text style={[styles.tableHeaderItem, { width: "35%" }]}> Product</Text>
                    <Text style={[styles.tableHeaderItem, { width: "13%" }]}>Unit Price</Text>
                    <Text style={[styles.tableHeaderItem, { width: "13%" }]}>Discount</Text>
                    <Text style={[styles.tableHeaderItem, { width: "14%" }]}>Discounted Unit Price</Text>
                    <Text style={[styles.tableHeaderItem, { width: "9%" }]}>Qty</Text>
                    <Text style={[styles.tableHeaderItem, { width: "16%" }]}>Sub total</Text>
                  </View>

                  {flatRateProduct.flatRateList.map((item, itemIndex) => (
                    <View key={itemIndex} style={styles.tableRow}>
                      <Text style={[styles.tableCol, styles.text, { width: "35%" }, { color: "#49535C" }]}>{item.app_product_local_name}</Text>
                      <Text style={[styles.tableCol, { width: "13%", textAlign: 'right' }, { color: "#49535C" }]}>{item.pack_unit}</Text>
                      <Text style={[styles.tableCol, { width: "13%", textAlign: 'right' }, { color: "#49535C" }]}>{item.discount}</Text>
                      <Text style={[styles.tableCol, { width: "14%", textAlign: 'right' }, { color: "#49535C" }]}>{"$" + " " + parseFloat(item.discounted_unit_price).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                      <Text style={[styles.tableCol, { width: "9%", textAlign: 'right' }, { color: "#49535C" }]}>{parseFloat(item.quantity).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                      <Text style={[styles.tableCol, { width: "16%", textAlign: 'right' }, { color: "#49535C" }]}> {" $ " + " " + parseFloat(item.rowTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                    </View>
                  ))}

                  <View style={[styles.tableRow, { justifyContent: 'flex-end' }]}>
                    <Text style={[styles.tableCol, { width: '75%', textAlign: "right", alignItems: "flex-end", justifyContent: 'flex-end', fontWeight: "bold", fontSize: 13 }]}>Sub Total</Text>
                    <Text style={[styles.tableCol, { width: '9%', textAlign: 'right', fontSize: 13, fontWeight: "bold" }]}>{getQuantity(flatRateProduct.flatRateList)}</Text>
                    <Text style={[styles.tableCol, { width: '16%', textAlign: 'right', fontSize: 13, fontWeight: "bold" }]}>{" $ " + " " + getSubTotal(flatRateProduct.flatRateList)}</Text>
                  </View>
                </View>
              </View>
            ) : null
          ))
        }


        {
          tierProductList.map((tierProduct, index) => (
            tierProduct.tierList.length > 0 ? (
              <View key={index}>
                <View style={styles.invoiceForFromContainer}>
                  <Text style={[styles.invoiceForFromText, { marginTop: "25" }, { marginBottom: "1" }, { color: "black" }, { fontWeight: "heavy" }]}>Pricing Model - Tiers</Text>
                </View>


                <View key={tierProduct.id}>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  Pricing Model Name : {tierProduct.app_pricing_model_name}</Text>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  Qualifying Type : {tierProduct.app_pricing_model_qualifying_type}</Text>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  Discount Type : {tierProduct.app_pricing_model_discount_type}</Text>
                  <Text style={[{ marginBottom: "2" }, { color: "#49535C" }]}>  {displayTierValues(tierProduct)}</Text>
                </View>

                <View style={styles.table}>
                  <View style={styles.tableHeader}>
                    <Text style={[styles.tableHeaderItem, { width: "35%" }]}> Product</Text>
                    <Text style={[styles.tableHeaderItem, { width: "13%" }]}>Unit Price</Text>
                    <Text style={[styles.tableHeaderItem, { width: "13%" }]}>Discount</Text>
                    <Text style={[styles.tableHeaderItem, { width: "14%" }]}>Discounted Unit Price</Text>
                    <Text style={[styles.tableHeaderItem, { width: "9%" }]}>Qty</Text>
                    <Text style={[styles.tableHeaderItem, { width: "16%" }]}>Sub total</Text>
                  </View>

                  {tierProduct.tierList.map((item, itemIndex) => (
                    <View key={itemIndex} style={styles.tableRow}>
                      <Text style={[styles.tableCol, styles.text, { width: "35%" }, { color: "#49535C" }]}>{item.app_product_local_name}</Text>
                      <Text style={[styles.tableCol, { width: "13%", textAlign: 'right' }, { color: "#49535C" }]}>{item.pack_unit}</Text>
                      <Text style={[styles.tableCol, { width: "13%", textAlign: 'right' }, { color: "#49535C" }]}>{item.discount}</Text>
                      <Text style={[styles.tableCol, { width: "14%", textAlign: 'right' }, { color: "#49535C" }]}>{"$" + " " + parseFloat(item.discounted_unit_price).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                      <Text style={[styles.tableCol, { width: "9%", textAlign: 'right' }, { color: "#49535C" }]}>{parseFloat(item.quantity).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                      <Text style={[styles.tableCol, { width: "16%", textAlign: 'right' }, { color: "#49535C" }]}> {" $ " + " " + parseFloat(item.rowTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}</Text>
                    </View>
                  ))}

                  <View style={[styles.tableRow, { justifyContent: 'flex-end' }]}>
                    <Text style={[styles.tableCol, { width: '75%', textAlign: "right", alignItems: "flex-end", justifyContent: 'flex-end', fontWeight: "bold", fontSize: 13 }]}>Sub Total</Text>
                    <Text style={[styles.tableCol, { width: '9%', textAlign: 'right', fontSize: 13, fontWeight: "bold" }]}>{getQuantity(tierProduct.tierList)}</Text>
                    <Text style={[styles.tableCol, { width: '16%', textAlign: 'right', fontSize: 13, fontWeight: "bold" }]}>{" $ " + " " + getSubTotal(tierProduct.tierList)}</Text>
                  </View>
                </View>
              </View>
            ) : null
          ))
        }

        <View style={styles.invoiceForFromContainer}>
          <Text style={[styles.invoiceForFromText, { marginTop: "10" }, { color: "black" }, { fontWeight: "heavy" }]}>Terms And Condition</Text>
        </View>

        <View style={styles.invoiceForFromText2}>
          {parseRichText(termsCondition)}
        </View>

        {/* <View style={styles.invoiceForFromText2}>
          <Text style={styles.textContent}> {quotationData.terms_and_condition}</Text>
        </View> */}



        {/* <View style={styles.invoiceFor}>
          <View style={[styles.invoiceForFromText, { marginTop: "10" }, { color: "black" }, { fontWeight: "heavy" }]}>
            <Text>Payment Method : {quotationData.payment_method} </Text>
          </View>
        </View> */}

        <View style={styles.invoiceFor}>
          <View style={[styles.paymentText, { marginTop: "10" }]}>
            <Text style={[{ color: "black", fontWeight: "heavy" }]}>  Payment Method : </Text>
              {parseRichText(paymentMethod)} 
          </View>
        </View>


        {/* <View style={styles.textContent}>
          {parseRichText(paymentMethod)}
        </View> */}



        <View style={styles.totalContainer}>
          <Text style={styles.totalValue}>Total :  ${getGrandTotal(standardProductList, customProductList, flatRateProductList, tierProductList)}</Text>
        </View>


        <View style={styles.footer}>
          <Text style={[styles.footerText, { color: "#49535C" }]}>{"2024 © Maxims B2B Portal."}</Text>
        </View>
      </Page>
    </Document>
  );
};
export default QuotationDownload;



