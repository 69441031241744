import {
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAIL,

  POST_INVOICE,
  POST_INVOICE_SUCCESS,
  POST_INVOICE_FAIL,

  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAIL,

  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICE_DETAIL,

  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAIL,
  UPDATE_INVOICE,

  GET_INVOICE_CONFIRM,
  GET_INVOICE_CONFIRM_SUCCESS,
  GET_INVOICE_CONFIRM_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  invoices: null,
  invoice: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const AppInvoiceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICE:
      return {
        ...state,
        loading: true,
        invoices: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_INVOICE:
      return {
        ...state,
        loading: true,
        invoice: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.payload,
        isCreated: true,
        isDeleted: false,
        isUpdated: false
      };
    case POST_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case DELETE_INVOICE:
      return {
        ...state,
        loading: true,
        invoice: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: null,
        isCreated: false,
        isDeleted: true,
        isUpdated: false
      };
    case DELETE_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_INVOICE_DETAIL:
      return {
        ...state,
        loading: true,
        invoice: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case UPDATE_INVOICE:
      return {
        ...state,
        loading: true,
        invoices: null,
        invoice: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: true
      };
    case UPDATE_INVOICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_INVOICE_CONFIRM:
      return {
        ...state,
        loading: true,
        invoice: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_INVOICE_CONFIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        invoice: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_INVOICE_CONFIRM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default AppInvoiceReducer;


