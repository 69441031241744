import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import moment from 'moment';
import axios from 'axios';
import getHeader from 'config/header';
import { useEffect } from 'react';
import * as url from "./../../../helpers/url_helper";

const ID = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};
const UserID = (cell) => {
    return cell.value ? cell.value : '';
};
const Name = (cell) => {
    return cell.value ? cell.value : '';
};

const LoginEmail = (cell) => {
    return cell.value ? cell.value : '';
};

const Password = (cell) => {
    return cell.value ? cell.value : '';
};

const UserRoleID = (cell) => {
    return cell.value ? cell.value : '';
};

const UserRoleName = (cell) => {
    const [roleName, setRoleName] = useState('')

    useEffect(() => {
        if (cell.value) {
            const token = sessionStorage.getItem('authUser')
            axios.get(url.API_BASE_URL+url.APP_ROLE_URL+"/"+cell.value, getHeader(token))
                .then(res => {
                    setRoleName(res.data.app_role_name)
                }).catch(err => {
                    console.log(err);
                })
        }
    }, [])
    return roleName ? roleName : ''
};

const CompanyName = (cell) => {
    const [companyName, setCompanyName] = useState('')

    useEffect(() => {
        if(cell.value){
            const token = sessionStorage.getItem("authUser")
        axios.get(url.API_BASE_URL+url.BUSINESS_URL+"/"+cell.value, getHeader(token))
            .then(res => {
                setCompanyName(res.data.app_business_name);
            }).catch(err => {
                console.log(err);
            })
        }
        
    }, [])
    return companyName ? companyName : ""
};

const Status = (cell) => {
    return (
        <Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
            {cell.value ? 'ACTIVE' : 'INACTIVE'}
        </Badge>
    )
}

const LockStatus = (cell) => {
    return (
        <Badge className={"font-size-12 badge-soft-" + (cell.value ? "danger" : "success")}>
            {cell.value ? 'Locked' : 'Normal'}
        </Badge>
    )
};


const CreatedDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';
};

const LastUpdateDate = (cell) => {
    return cell.value ? cell.value : '';
};

const CreatedByUser = (cell) => {
    return cell.value ? cell.value : '';
};

const LastUpdateByUser = (cell) => {
    return cell.value ? cell.value : '';
};



export {
    UserID,
    Name,
    ID,
    LoginEmail,
    Password,
    UserRoleID,
    UserRoleName,
    CompanyName,
    LockStatus,
    Status,
    CreatedDate,
    LastUpdateByUser,
    LastUpdateDate,
    CreatedByUser
};