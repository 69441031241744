//Get by:LIST transaction
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";

//Get by:ID transaction
export const GET_TRANSACTION_DETAIL = "GET_TRANSACTION_DETAIL";
export const GET_TRANSACTION_DETAIL_SUCCESS = "GET_TRANSACTION_DETAIL_SUCCESS";
export const GET_TRANSACTION_DETAIL_FAIL = "GET_TRANSACTION_DETAIL_FAIL";

//post Transaction 
export const POST_TRANSACTION = "POST_TRANSACTION";
export const POST_TRANSACTION_SUCCESS = "POST_TRANSACTION_SUCCESS";
export const POST_TRANSACTION_FAIL = "POST_TRANSACTION_FAIL"

// Delete Transaction
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL"


// Update Transaction
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL";