import {

    GET_PRICING_MODELS_SUCCESS,
    GET_PRICING_MODELS_FAIL,
    GET_PRICING_MODELS,

    GET_PRICING_MODEL,
    GET_PRICING_MODEL_SUCCESS,
    GET_PRICING_MODEL_FAIL,

    POST_PRICING_MODEL,
    POST_PRICING_MODEL_SUCCESS,
    POST_PRICING_MODEL_FAIL,

    DELETE_PRICING_MODEL,
    DELETE_PRICING_MODEL_SUCCESS,
    DELETE_PRICING_MODEL_FAIL,

    UPDATE_PRICING_MODEL_SUCCESS,
    UPDATE_PRICING_MODEL_FAIL,
    UPDATE_PRICING_MODEL,
} from "./actionTypes";


const INIT_STATE = {
    PricingModels: null,
    pricingmodel: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const PricingModelReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_PRICING_MODELS:
            return {
                ...state,
                loading: true,
                PricingModels: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_PRICING_MODELS_SUCCESS:
            return {
                ...state,
                loading: false,
                PricingModels: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_PRICING_MODELS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };

        case GET_PRICING_MODEL:
            return {
                ...state,
                loading: true,
                pricingmodel: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_PRICING_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
                pricingmodel: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_PRICING_MODEL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_PRICING_MODEL:
            return {
                ...state,
                loading: true,
                pricingmodel: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_PRICING_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
                pricingmodel: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_PRICING_MODEL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_PRICING_MODEL:
            return {
                ...state,
                loading: true,
                pricingmodel: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_PRICING_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
                pricingmodel: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_PRICING_MODEL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };

        case UPDATE_PRICING_MODEL:
            return {
                ...state,
                loading: true,
                pricingmodel: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_PRICING_MODEL_SUCCESS:
            return {
                ...state,
                loading: false,
                pricingmodel: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_PRICING_MODEL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default PricingModelReducer;


