import React, { useState, useMemo, useEffect } from "react"
import { useHistory, useParams } from 'react-router-dom'
import { set, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import Select from "react-select";
import 'react-phone-number-input/style.css'
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  Button,
  UncontrolledTooltip,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal";
import axios from "axios"
import getHeader from "config/header"
import TableContainer from "components/Common/TableContainer"

// Import SiteCol
import { SiteID, SiteName, BusinessUnits, SiteDescription, SiteStatus, Business } from "./sites/SiteCol"
import { ContactName, Position, Department, Email } from "./contact/ContactCol"
import { UserID, UserName, BusinessUnit, Status } from "./users/UserCol"
import { CompanySite, OrderID, OrderDate, OrderAmount, ApprovalStatus } from "./Orders/OrdersListCol"
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress, deleteContact, deleteDepartment, getAddress, getAddresses, getBusinesses, getContact, getContactss, getDepartment, getDepartments, postAddress, postAppGenericIndustry, postContact, postDepartment, updateAddress, updateContacts, updateDepartment } from "store/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Creatable, { useCreatable } from 'react-select/creatable';
import { API_BASE_URL, APP_BUSINESS_UNITS_URL, BUSINESS_URL, DEPARTMENT_URL, INDUSTRY, SYS_USER_URL } from "helpers/url_helper";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}


const UpdateCompany = () => {

  const { id } = useParams()
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState('')
  const [currentIndustry, setCurrentIndustry] = useState('')

  const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
  const UpadatePermission = (permissionAction.includes("system.customers.business.update")) ? false : true
  const DeletePermission = (permissionAction.includes("system.customers.business.delete")) ? false : true

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#D2D4D1", // Change this to your desired background color
    }),
  };


  const [companyID, setCompanyID] = useState(id)


  useEffect(() => {
    if (id) {
      const token = sessionStorage.getItem("authUser")
      axios.get(API_BASE_URL + INDUSTRY, getHeader(token))
        .then(res => {
          var results_data = []
          for (var i in res.data.result) {
            var _data = {
              value: res.data.result[i].industry_id,
              label: res.data.result[i].industry_name
            }
            results_data.push(_data)
          }
          setSalesIndustries(results_data)
          register("app_company.app_sales_industry")
          axios.get(API_BASE_URL + BUSINESS_URL + "/" + id, getHeader(token))
            .then(res => {
              // console.log(res.data);
              let busData = res.data
              setCompanyName(busData.app_business_name)
              setValue("app_company.app_business_keyid", busData.app_business_keyid)
              setValue("app_company.app_business_name", busData.app_business_name)
              if (busData.is_active === true) {
                setValue("app_company.is_active", "active")
              } else if (busData.is_active === false) {
                setValue("app_company.is_active", "inactive")
              }
              // setValue("app_company.app_company_address", busData.app_company_address)
              // setValue("app_company.app_sales_industry", busData.app_sales_industry)
              // setValue("app_company.app_oracle_supplier_id", busData.app_oracle_supplier_id)
              // setValue("app_company.app_oracle_supplier_site", busData.app_oracle_supplier_site)
              // setValue("app_company.app_oracle_supplier_ou", busData.app_oracle_supplier_ou)
              // setValue("app_company.app_business_status", busData.app_business_status)
              setCompanyID(busData.app_business_id)
              axios.get(API_BASE_URL + INDUSTRY + "/" + busData.app_sales_industry, getHeader(token))
                .then(res => {
                  const industry = {
                    value: res.data.industry_id,
                    label: res.data.industry_name
                  }
                  setCurrentIndustry(industry)
                  setValue("app_company.app_sales_industry", industry.value)

                })
                .catch(err => {
                  console.log(err);
                })

            }).catch(err => {
              console.log(err);
            })
        })
        .catch(err => {
          console.log(err);
        })
    }
  }, [id])


  //  creatable Select 
  const [creatableSelect, setCreatableSelect] = useState()

  useEffect(() => {
    if (creatableSelect !== undefined) {
      let data = {
        industry_id: ((creatableSelect.value).replace(/\s/g, "").toLowerCase()),
        industry_name: creatableSelect.label
      }
      dispatch(postAppGenericIndustry(data))
    }
  }, [creatableSelect])

  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)

  //meta title
  document.title = "Companies | Maxims B2B Portal";

  const [activeTab, setactiveTab] = useState("1")

  const { formState: { errors }, register, reset, unregister, setValue, getValues } = useForm()




  // Watching Oracle Supplier ID
  const oracleIDInput = (e) => {
    const oracleID = e.target.value;
    unregister("app_oracleSuplierSite")
    unregister("app_oracleSupplierOU")
    if (oracleID.trim() !== '' && oracleID.trim() !== null) {
      register("app_oracleSuplierSite", { required: true })
      register("app_oracleSupplierOU", { required: true })
    } else {
      register("app_oracleSuplierSite")
      register("app_oracleSupplierOU")
    }
  }

  // CUSTOM VALIDATION
  const [custErrors, setCustErrors] = useState({
    app_business_name: false,
    app_business_keyid: false,
    app_company_address: false,
    app_businessunit_id: false,
    app_business_id: false,
    is_active: false,
    // app_business_owner: false,
    app_sales_industry: false,
    app_department_name: false,
    app_department_id: false,
    app_department_keyid: false,
    app_oracle_site: false,
    app_oracle_ou: false,
    app_account_keyid: false,
    credit_limit: false,
    account_balance: false,
    oracle_credit_status: false,
    account_status: false,
    department: false,
    position: false,
    mobile_phone: false,
    office_phone: false,
    app_contact_name: false,
    app_contact_email: false,
    sys_user: false,
    oracle_site_ID: false,
    app_address_name: false,
    app_billing_address: false,
    app_delivery_address: false
  })

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  const [ownerList, setOwnerList] = useState([])
  useEffect(() => {
    const token = sessionStorage.getItem('authUser')
    axios.get(API_BASE_URL + SYS_USER_URL, getHeader(token))
      .then(userRes => {
        setOwnerList(userRes.data.result)
      }).catch(userErr => {
        console.log(userErr);
      })
  }, [])


  const [businessUnitList, setBusinessUnitList] = useState([])
  useEffect(() => {
    const token = sessionStorage.getItem("authUser")
    axios.get(API_BASE_URL + APP_BUSINESS_UNITS_URL, getHeader(token))
      .then(businessRes => {
        setBusinessUnitList(businessRes.data.result)
      }).catch(businessErr => {
        console.log(businessErr);
      })
  }, [])

  //COMPANY
  const [watchCompanyState, setWatchCompanyState] = useState(false)
  const [salesIndustries, setSalesIndustries] = useState([])
  const [buttonState, setButtonState] = useState(true)

  const watchCompanyFields = (e) => {
    if (watchCompanyState) {
      const inputID = e.target.id
      const inputValue = e.target.value
      if (inputValue) {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: true }))
      }
      // if (inputID === "app_business_email") {
      //   const result = validateEmail(inputValue)
      //   if (result) {
      //     setCustErrors((prevState) => ({ ...prevState, app_business_email: false }))
      //   } else {
      //     setCustErrors((prevState) => ({ ...prevState, app_business_email: true }))
      //     return
      //   }
      // }
    }
  }

  const watchSalesFields = (e) => {
    if (watchCompanyState) {
      if (e.value) {
        setCustErrors((prevState) => ({ ...prevState, app_sales_industry: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, app_sales_industry: true }))
      }
    }
  }

  const updateCompany = () => {
    setWatchCompanyState(true)
    setButtonState(false)
    $('#createCompanyBtn').attr("disabled", true)
    const companyValues = getValues("app_company")
    console.log(companyValues);
    if (!companyValues.app_business_name) {
      setCustErrors((prevState) => ({ ...prevState, app_business_name: true }))
    }
    if (!companyValues.app_business_keyid) {
      setCustErrors((prevState) => ({ ...prevState, app_business_keyid: true }))
    }
    if (!companyValues.app_sales_industry) {
      setCustErrors((prevState) => ({ ...prevState, app_sales_industry: true }))
    }
    if (companyValues.app_business_name && companyValues.app_business_keyid && companyValues.app_sales_industry) {

      let statusValue
      if (companyValues.is_active === "active") {
        statusValue = true
      } else if (companyValues.is_active === "inactive") {
        statusValue = false
      }

      const companyValue = {
        ...companyValues,
        is_active: statusValue,
      }
      console.log(companyValue);
      const token = sessionStorage.getItem('authUser')
      axios.put(API_BASE_URL + BUSINESS_URL + "/" + id, companyValue, getHeader(token))
        .then(res => {
          setCompanyID(res.data.app_business_id)
          setButtonState(true)
          toast.success("Company Updated")
          $('#createCompanyBtn').attr("disabled", false)
        }).catch(err => {
          console.log(err);
          setButtonState(true)
          toast.error("Failed to Update")
          $('#createCompanyBtn').attr("disabled", false)
        })
    } else {
      setButtonState(true)
      $('#createCompanyBtn').attr("disabled", false)
    }
  }

  const [businessList, setBusinessList] = useState([])
  const businessesState = useSelector(state => state.businessReducer)

  useEffect(() => {
    dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: "", skip: '' }))
  }, [])

  useEffect(() => {
    if (businessesState.businesses !== null) {
      const res = businessesState.businesses.result
      setBusinessList(res)
    }
  }, [businessesState])


  // SITE
  const [sites, setSites] = useState([])
  const [siteTableState, setSiteTableState] = useState(true)
  const [siteEditState, setSiteEditState] = useState(false)
  const [watchSiteState, setWatchSiteState] = useState(false)
  const [editDepartmentID, setEditDepartmentID] = useState('')


  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const pageStatus = query.get("status")
  const tab = query.get("tab")


  // useEffect(() => {
  //   setactiveTab(tab);
  // },[tab])

  // const [sitepageStatus, setSitePageStatus] = useState(true)
  const [sitepageCount, setSitePageCount] = useState(0);
  // const [sitepage, setSitePage] = useState(0);
  // const [sitepageLimit, setSitePageLimit] = useState(10);

  const departmentState = useSelector(state => state.departmentReducer)

  const [customFilterData, setCustomFilterData] = useState("")




  useEffect(() => {
    if (siteTableState) {
      if (departmentState.departments !== null) {
        setSites(departmentState.departments.result);
        setSitePageCount(Math.ceil(departmentState.departments.summary.filteredsize / pageLimit) == 0 ? 1 : Math.ceil(departmentState.departments.summary.filteredsize / pageLimit) );
      }
      if (departmentState.isDeleted) {
        setDeleteModal(false);
        sitehandlePageClick(page);
      }
      if (departmentState.error) {
        setDeleteModal(false);
      }
    }

  }, [departmentState])

  useEffect(() => {
    if (companyID && siteTableState) {
      // setactiveTab("2");
      setactiveTab(tab);
      // dispatch(getDepartments({ companyID, filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
      sitehandlePageClick()
    }
  }, [pageStatus, companyID, siteTableState, pageLimit, page, customFilterData])

  useEffect(() => {
    if (customFilterData.length > 0) {
      history.push(`/updateCompany/${id}?tab=${2}&&skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
    }
  }, [customFilterData])

  const sitehandlePageClick = (index) => {
    let filter = `{"is_active":${pageStatus},"$or":[
      {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_department_keyid":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
      {"department_description":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_business_unit_name":{"$regex":"${customFilterData}","$options":"i"}},
  ]}`
    let skips = 0;
    // setSitePage(index);
    if (page > 0) {
      skips = page * pageLimit;
      // dispatch(getDepartments({ companyID, filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: skips }));
      dispatch(getDepartments({ companyID, filter: filter, limit: pageLimit, skip: skips }));

    }
    else {
      dispatch(getDepartments({ companyID, filter: filter, limit: pageLimit, skip: 0 }));

      // dispatch(getDepartments({ companyID, filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }
  }

  const siteColumns = useMemo(
    () => [
      {
        Header: 'Company',
        accessor: 'app_business_name',
        filterable: true,
        Cell: (cellProps) => {
          return <Business {...cellProps} />
        }
      },
      {
        Header: 'Site ID',
        accessor: 'app_department_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <SiteID {...cellProps} />
        }
      },
      {
        Header: 'Site Name',
        accessor: 'app_department_name',
        filterable: true,
        Cell: (cellProps) => {
          return <SiteName {...cellProps} />
        }
      },
      {
        Header: ' Site Description',
        accessor: 'department_description',
        filterable: true,
        Cell: (cellProps) => {
          return <SiteDescription {...cellProps} />
        }
      },
      {
        Header: 'Business Unit',
        accessor: 'app_business_unit_name',
        filterable: true,
        Cell: (cellProps) => {
          return <BusinessUnits {...cellProps} />
        }
      },
      {
        Header: 'Sub Menu',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Button id="createCoBtn" color="info" onClick={() => handleMoveContactPage(cellProps.row.original)} size="sm" type="button" >Contacts</Button>
              <Button id="createCBtn" color="info" onClick={() => handleMoveAddressPage(cellProps.row.original)} size="sm" type="button" >Addresses</Button>
              <Button id="creatBtn" color="info" onClick={() => handleMoveOrdersPage(cellProps.row.original)} size="sm" type="button" >Opportunities</Button>

              {/* <Button id="creatBtn" color="info" size="sm" type="button" >Orders</Button> */}
              {/* <a
               className="text-success"
               onClick={() => {  
                 const siteData = cellProps.row.original;
                 handleMoveContactPage(siteData)
               }}
             >
               <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
               <UncontrolledTooltip placement="top" target="edittooltip">
                 Edit
               </UncontrolledTooltip>
             </a>
             <a
               // to="#"
               className="text-info"
             // onClick={() => {
             //   const siteData = cellProps.row.original;
             //   onClickDelete(siteData);
             // }}
             >
               <i className="mdi mdi-eye font-size-18" id="deletetooltip" />
               <UncontrolledTooltip placement="top" target="deletetooltip">
                 view
               </UncontrolledTooltip>
             </a> */}
            </div>
          );
        }
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <SiteStatus {...cellProps} />
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">

              <Button color="success" onClick={() => handleEditSite(cellProps.row.original)} size="sm" type="button" > Edit </Button>

              {/* <a
                className="text-success"
                onClick={() => {
                  const siteData = cellProps.row.original;
                  handleEditSite(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </a>
              <a
                // to="#"
                className="text-info"
                onClick={() => {
                  handleViewSite(cellProps.row.original)
                }}
              // onClick={() => {
              //   const siteData = cellProps.row.original;
              //   onClickDelete(siteData);
              // }}
              >
                <i className="mdi mdi-eye font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  view
                </UncontrolledTooltip>
              </a> */}
            </div>
          );
        }
      },
    ], [sites]);

  const [siteID, setSiteID] = useState("")
  const [siteName, setSiteName] = useState("")

  const handleMoveContactPage = (value) => {
    if (value.app_department_id) {
      setSiteID(value.app_department_id)
      setSiteName(value.app_department_name)
      setContactTableState(true)
      setactiveTab("3");
      setSiteEditState(false);
      // setSiteTableState(true);
      history.push(`/updateCompany/${id}?tab=${3}&&skip=${0}&&limit=${10}&&status=${true}`)
    }
  }

  const handleMoveAddressPage = (value) => {
    if (value.app_department_id) {
      setSiteID(value.app_department_id)
      setSiteName(value.app_department_name)
      setAddressTableState(true)
      setactiveTab("4");
      setSiteEditState(false);
      // setSiteTableState(true);
      history.push(`/updateCompany/${id}?tab=${4}&&skip=${0}&&limit=${10}&&status=${true}`)
    }
  }

  const handleMoveOrdersPage = (value) => {
    // console.log(value);
    // if (value.app_department_id) {
    //   setSiteID(value.app_department_id)
    //   setSiteName(value.app_department_name)
    //   setOrderTableState(true)
    //   setactiveTab("5");
    //   setSiteEditState(false);
    //   // setSiteTableState(true);
    //   history.push(`/updateCompany/${id}?tab=${5}&&skip=${0}&&limit=${10}&&status=${true}`)
    //   console.log("aaaaaaa", value.app_department_id);
    // }
  }

  const handleAddSite = () => {
    setSiteTableState(false)
  }

  const watchSiteFields = (e) => {
    if (watchSiteState) {
      const inputID = e.target.id
      const inputValue = e.target.value
      if (inputValue) {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: true }))
      }
    }
  }


  useEffect(() => {
    if (departmentState.isCreated) {
      setValue("app_site", "")
      setCustErrors((prevState) => ({ ...prevState, app_department_name: false }))
      setSiteTableState(true)
      dispatch(getDepartments({ companyID, filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }

    if (departmentState.isUpdated) {
      setSiteTableState(true)
      setSiteEditState(false)
      setEditDepartmentID("")
      dispatch(getDepartments({ companyID, filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }

    if (departmentState.error) {
      // console.log(departmentState.error);
    }

    if (departmentState.isDeleted) {
      setDeleteModal(false);
      dispatch(getDepartments({ companyID, filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }


  }, [departmentState,])

  const addSite = () => {
    setWatchSiteState(true)
    const siteValue = getValues("app_site")
    if (!siteValue.app_department_name) {
      setCustErrors((prevState) => ({ ...prevState, app_department_name: true }))
    }
    if (!siteValue.app_businessunit_id) {
      setCustErrors((prevState) => ({ ...prevState, app_businessunit_id: true }))
    }
    // if (!siteValue.oracle_site_ID) {
    //   setCustErrors((prevState) => ({ ...prevState, oracle_site_ID: true }))
    // }
    // if (!siteValue.app_oracle_ou) {
    //   setCustErrors((prevState) => ({ ...prevState, app_oracle_ou: true }))
    // }
    // if (!siteValue.credit_limit) {
    //   setCustErrors((prevState) => ({ ...prevState, credit_limit: true }))
    // }
    // if (!siteValue.app_account_keyid) {
    //   setCustErrors((prevState) => ({ ...prevState, app_account_keyid: true }))
    // }
    // if (!siteValue.account_balance) {
    //   setCustErrors((prevState) => ({ ...prevState, account_balance: true }))
    // }
    // if (!siteValue.oracle_credit_status) {
    //   setCustErrors((prevState) => ({ ...prevState, oracle_credit_status: true }))
    // }
    // if (!siteValue.oracle_credit_status) {
    //   setCustErrors((prevState) => ({ ...prevState, oracle_credit_status: true }))
    // }
    $('#createRoleBtn').attr('disabled', true)

    let value
    if (siteValue.is_active === "active") {
      value = true
    } else if (siteValue.is_active === "inactive") {
      value = false
    }

    // let account_status
    // if (siteValue.account_status === "active") {
    //   account_status = true
    // } else if (siteValue.account_status === "inactive") {
    //   account_status = false
    // }

    // let oracle_credit_status
    // if (siteValue.oracle_credit_status === "active") {
    //   oracle_credit_status = true
    // } else if (siteValue.oracle_credit_status === "inactive") {
    //   oracle_credit_status = false
    // }

    const siteItems = {
      ...siteValue,
      is_active: value,
      // account_status: account_status,
      // oracle_credit_status: oracle_credit_status
    }

    if (siteItems.app_department_name && siteItems.app_businessunit_id) {
      const data = {
        companyID,
        siteItems
      }
      //  console.log("data", data);
      dispatch(postDepartment(data))
    }
  }

  const handleDeleteSite = (value) => {
    if (value.app_department_id) {
      let DepartmentID = value.app_department_id

      if (companyID && DepartmentID) {
        const data = {
          companyID,
          DepartmentID
        };
        dispatch(deleteDepartment(data));
      }
    }
  }

  // get by id department
  useEffect(() => {
    if (departmentState.singledepartment !== null) {
      // console.log(departmentState.singledepartment);
      const siteData = departmentState.singledepartment
      setValue("edit_site.app_department_name", siteData.app_department_name)
      setValue("edit_site.app_department_keyid", siteData.app_department_keyid)
      setValue("edit_site.department_description", siteData.department_description)
      setValue("edit_site.app_businessunit_id", siteData.app_businessunit_id)
      setValue("edit_site.app_business_id", siteData.app_business_id)
      setValue("edit_site.app_account_keyid", siteData.app_account_keyid)
      setValue("edit_site.credit_limit", siteData.credit_limit)
      setValue("edit_site.account_balance", siteData.account_balance)
      setValue("edit_site.oracle_site_ID", siteData.oracle_site_ID)
      // setValue("edit_site.app_oracle_site", siteData.app_oracle_site)
      // setValue("edit_site.app_oracle_ou", siteData.app_oracle_ou)

      if (siteData.oracle_credit_status === true) {
        setValue("edit_site.oracle_credit_status", "active")
      } else if (siteData.oracle_credit_status === false) {
        setValue("edit_site.oracle_credit_status", "inactive")
      }
      if (siteData.account_status === true) {
        setValue("edit_site.account_status", "active")
      } else if (siteData.account_status === false) {
        setValue("edit_site.account_status", "inactive")
      }
      if (siteData.is_active === true) {
        setValue("edit_site.is_active", "active")
      } else if (siteData.is_active === false) {
        setValue("edit_site.is_active", "inactive")
      }
      setSiteTableState(false)
      // statusChange()
      setSiteEditState(true)
      // setEditDepartmentID("")
    }
  }, [departmentState.singledepartment])

  const handleEditSite = (value) => {
    if (value.app_department_id) {
      setEditDepartmentID(value.app_department_id)
      let departmentID = value.app_department_id
      let businessID = value.app_business_id
      const data = {
        departmentID,
        businessID
      }
      // console.log("data", data);
      dispatch(getDepartment(data))
    }
  }

  const [siteReadOnly, setSiteReadOnly] = useState(false)
  const handleViewSite = (value) => {
    if (value.app_department_id) {
      setSiteReadOnly(true)
      setEditDepartmentID(value.app_department_id)
      let departmentID = value.app_department_id
      let businessID = value.app_business_id
      const data = {
        departmentID,
        businessID
      }
      // console.log("data", data);
      dispatch(getDepartment(data))
    }
  }

  const updateSite = () => {

    setWatchSiteState(true)
    const dataTBU = getValues('edit_site')
    if (!dataTBU.app_department_name) {
      setCustErrors((prevState) => ({ ...prevState, app_department_name: true }))
    }
    if (!dataTBU.app_businessunit_id) {
      setCustErrors((prevState) => ({ ...prevState, app_businessunit_id: true }))
    }

    let value
    if (dataTBU.is_active === "active") {
      value = true
    } else if (dataTBU.is_active === "inactive") {
      value = false
    }

    // let oracle_credit_status
    // if (dataTBU.oracle_credit_status === "active") {
    //   oracle_credit_status = true
    // } else if (dataTBU.oracle_credit_status === "inactive") {
    //   oracle_credit_status = false
    // }

    // let account_status
    // if (dataTBU.account_status === "active") {
    //   account_status = true
    // } else if (dataTBU.account_status === "inactive") {
    //   account_status = false
    // }

    const UpdateSiteData = {
      // ...dataTBU,
      is_active: value,
      app_business_id: dataTBU.app_business_id,
      app_businessunit_id: dataTBU.app_businessunit_id,
      app_department_keyid: dataTBU.app_department_keyid,
      app_department_name: dataTBU.app_department_name,
      department_description: dataTBU.department_description
      // account_status: account_status,
      // oracle_credit_status: oracle_credit_status
    }

    if (UpdateSiteData.app_department_name && UpdateSiteData.app_businessunit_id) {
      const data = {
        companyID,
        editDepartmentID,
        UpdateSiteData
      }
      dispatch(updateDepartment(data))
    }
  }

  // COMMON DELETE HANDLERS
  const [dataTBD, setDataTBD] = useState('')
  const [dataModuleTBD, setDataModuleTBD] = useState('')

  const onClickDelete = (data) => {
    if (data.hasOwnProperty("app_department_id")) {
      setDataModuleTBD("SITE")
      setDataTBD(data)
      setDeleteModal(true);
    } else if (data.hasOwnProperty("app_contact_id")) {
      setDataModuleTBD("CONTACT")
      setDataTBD(data)
      setDeleteModal(true);
    } else if (data.hasOwnProperty("app_address_id")) {
      setDataModuleTBD("ADDRESS")
      setDataTBD(data)
      setDeleteModal(true);
    }
  };

  const handleDelete = () => {
    if (dataModuleTBD === "SITE") {
      handleDeleteSite(dataTBD)
      setDataModuleTBD("")
      setDataTBD("")
    } else if (dataModuleTBD === "CONTACT") {
      handleDeleteContact(dataTBD)
      setDataModuleTBD("")
      setDataTBD("")
    } else if (dataModuleTBD === "ADDRESS") {
      handleDeleteAddress(dataTBD)
      setDataModuleTBD("")
      setDataTBD("")
    }
  }


  // CONTACT
  const [contacts, setContacts] = useState([])
  const [contactTableState, setContactTableState] = useState(true)
  const [contactEditState, setContactEditState] = useState(false)
  const [watchContactState, setWatchContactState] = useState(false)
  const [editContactID, setEditContactID] = useState('')

  const [departments, setDepartment] = useState([])
  useEffect(() => {
    if (companyID && contactTableState) {
      const token = sessionStorage.getItem('authUser')
      axios.get(API_BASE_URL + DEPARTMENT_URL + "/" + companyID, getHeader(token))
        .then(res => {
          setDepartment(res.data.result)
        }).catch(err => {
          console.log(err);
        })
    }
  }, [])

  const contactState = useSelector(state => state.contactsReducer)

  // const [contactpageStatus, setContactPageStatus] = useState(true)
  const [contactpageCount, setContactPageCount] = useState(0);
  // const [contactpage, setContactPage] = useState(0);
  // const [contactpageLimit, setContactPageLimit] = useState(10);



  useEffect(() => {
    if (contactTableState) {
      if (contactState.contacts !== null) {
        setContacts(contactState.contacts.result);
        setContactPageCount(Math.ceil(contactState.contacts.summary.filteredsize / pageLimit) == 0 ? 1 : Math.ceil(contactState.contacts.summary.filteredsize / pageLimit) );
      }
      if (contactState.isDeleted) {
        setDeleteModal(false);
        contacthandlePageClick(page);
      }
      if (contactState.error) {
        setDeleteModal(false);
      }
    }

  }, [contactState])


  useEffect(() => {
    if (companyID && siteID && contactTableState) {
      // setactiveTab("2");
      const data = { companyID }
      // dispatch(getContactss({ companyID, limit: contactpageLimit, skip: 0 }));
      contacthandlePageClick()
    }
  }, [pageStatus, companyID, siteID, contactTableState, pageLimit, page, customFilterData])

  const contacthandlePageClick = (index) => {
    let filter = `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}, "$or":[
      {"app_contact_name":{"$regex":"${customFilterData}","$options":"i"}},
      {"position":{"$regex":"${customFilterData}","$options":"i"}},
      {"department":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_contact_email":{"$regex":"${customFilterData}","$options":"i"}}
  ]}`
    let skips = 0;
    // setContactPage(index);
    const data = { companyID }
    if (page > 0) {
      skips = page * pageLimit;
      dispatch(getContactss({ companyID, filter: filter, limit: pageLimit, skip: skips }));

      // dispatch(getContactss({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: skips }));
    }
    else {

      dispatch(getContactss({ companyID, filter: filter, limit: pageLimit, skip: 0 }));

      // dispatch(getContactss({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }
  }

  const contactColumns = useMemo(
    () => [
      {
        Header: ' Name',
        accessor: 'app_contact_name',
        filterable: true,
        Cell: (cellProps) => {
          let title = cellProps.row.original.title
          return <ContactName title={title} {...cellProps} />
        }
      },
      {
        Header: 'Position',
        accessor: 'position',
        filterable: true,
        Cell: (cellProps) => {
          return <Position {...cellProps} />
        }
      },
      {
        Header: 'Department',
        accessor: 'department',
        filterable: true,
        Cell: (cellProps) => {
          return <Department {...cellProps} />
        }
      },
      {
        Header: 'Email',
        accessor: 'app_contact_email',
        filterable: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Button color="success" onClick={() => handleEditContact(cellProps.row.original)} size="sm" type="button" > Edit </Button>
              {/* <a
                className="text-success"
                onClick={() => {
                  const contactData = cellProps.row.original;
                  handleEditContact(contactData)
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </a>
              <a
                // to="#"
                className="text-info"
                // onClick={() => {
                //   const contactData = cellProps.row.original;
                //   onClickDelete(contactData);
                // }}
                onClick={() => {
                  handleViewContact(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  View
                </UncontrolledTooltip>
              </a> */}
            </div>
          );
        }
      },
    ], [contacts]);

  const handleAddContact = () => {
    setContactTableState(false)
  }

  useEffect(() => {
    if (contactState.isCreated) {
      setContactTableState(true)
      reset()
      setOfficePhone('')
      setMobilePhone('')
      setCountryCode('')
      setCountryCode2('')
      dispatch(getContactss({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }

    if (contactState.error) {
    }

    if (contactState.isUpdated) {
      setContactTableState(true)
      setContactEditState(false)
      setEditContactID("")
      reset()
      setOfficePhone('')
      setMobilePhone('')
      setCountryCode('')
      setCountryCode2('')
      dispatch(getContactss({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }

    if (contactState.isDeleted) {
      setDeleteModal(false);
      dispatch(getContactss({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }
  }, [contactState])

  const watchContactFields = (e) => {
    if (watchContactState) {
      const inputID = e.target.id
      const inputValue = e.target.value
      if (inputValue) {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: true }))
      }
      if (inputID === "app_contact_email") {
        const result = validateEmail(inputValue)
        if (result) {
          setCustErrors((prevState) => ({ ...prevState, app_contact_email: false }))
        } else {
          setCustErrors((prevState) => ({ ...prevState, app_contact_email: true }))
          return
        }
      }
    }
  }

  const addContact = () => {
    setWatchContactState(true)
    const contactValue = getValues("app_contact")
    if (!contactValue.app_contact_name) {
      setCustErrors((prevState) => ({ ...prevState, app_contact_name: true }))
    }
    if (!contactValue.position) {
      setCustErrors((prevState) => ({ ...prevState, position: true }))
    }
    if (!contactValue.department) {
      setCustErrors((prevState) => ({ ...prevState, department: true }))
    }
    if (!contactValue.app_contact_email) {
      setCustErrors((prevState) => ({ ...prevState, app_contact_email: true }))
    }
    if (contactValue.app_contact_email) {
      const result = validateEmail(contactValue.app_contact_email)
      if (result) {
        setCustErrors((prevState) => ({ ...prevState, app_contact_email: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, app_contact_email: true }))
        return
      }
    }
    if (!contactValue.office_phone) {
      setCustErrors((prevState) => ({ ...prevState, office_phone: true }))
    }
    if (!contactValue.mobile_phone) {
      setCustErrors((prevState) => ({ ...prevState, mobile_phone: true }))
    }
    if (contactValue.app_contact_name && contactValue.department && contactValue.position && contactValue.app_contact_email && contactValue.office_phone && contactValue.mobile_phone) {


      let statusValue
      if (contactValue.is_active === "active") {
        statusValue = true
      } else if (contactValue.is_active === "inactive") {
        statusValue = false
      }

      // console.log(contactValue);
      const contactValues = {
        ...contactValue,
        is_active: statusValue,
        app_contact_email: (contactValue.app_contact_email).toLowerCase(),
        app_department_id: siteID,
        app_business_id: companyID
      }
      const data = {
        companyID,
        contactValues
      }
      dispatch(postContact(data))

    }
  }

  const handleDeleteContact = (value) => {
    if (value.app_contact_id) {
      let ContactID = value.app_contact_id
      if (companyID && ContactID) {
        const data = {
          companyID,
          ContactID
        };
        dispatch(deleteContact(data));
      }

    }
  }

  // get by id contact
  useEffect(() => {
    if (contactState.singlecontact !== null) {
      const contactData = contactState.singlecontact
      setValue("edit_contact.app_contact_name", contactData.app_contact_name)
      setValue("edit_contact.app_department_id", contactData.app_department_id)
      setValue("edit_contact.position", contactData.position)
      setValue("edit_contact.title", contactData.title)
      setValue("edit_contact.department", contactData.department)
      setValue("edit_contact.office_phone", contactData.office_phone)
      setValue("edit_contact.mobile_phone", contactData.mobile_phone)
      setEditOfficePhone(contactData.office_phone)
      setEditMobilePhone(contactData.mobile_phone)
      setValue("edit_contact.app_contact_email", contactData.app_contact_email)
      setValue("edit_contact.remarks", contactData.remarks)
      if (contactData.is_active === true) {
        setValue("edit_contact.is_active", "active")
      } else if (contactData.is_active === false) {
        setValue("edit_contact.is_active", "inactive")
      }
      setContactTableState(false)
      setContactEditState(true)
    }
  }, [contactState.singlecontact])


  const handleEditContact = (value) => {
    if (value.app_contact_id) {
      setEditContactID(value.app_contact_id)
      let contactID = value.app_contact_id
      let businessID = value.app_business_id

      const data = {
        contactID,
        businessID
      }
      dispatch(getContact(data))

    }
  }

  const [contactReadOnly, setContactReadOnly] = useState(false)
  const handleViewContact = (value) => {
    if (value.app_contact_id) {
      setContactReadOnly(true)
      setEditContactID(value.app_contact_id)
      let contactID = value.app_contact_id
      let businessID = value.app_business_id

      const data = {
        contactID,
        businessID
      }
      dispatch(getContact(data))
    }
  }

  const updateContact = () => {
    // const dataTBU = getValues('edit_contact')

    const contactValue = getValues("edit_contact")

    setWatchContactState(true)

    if (!contactValue.app_contact_name) {
      setCustErrors((prevState) => ({ ...prevState, app_contact_name: true }))
    }
    if (!contactValue.position) {
      setCustErrors((prevState) => ({ ...prevState, position: true }))
    }
    if (!contactValue.department) {
      setCustErrors((prevState) => ({ ...prevState, department: true }))
    }
    if (!contactValue.app_contact_email) {
      setCustErrors((prevState) => ({ ...prevState, app_contact_email: true }))
    }
    if (contactValue.app_contact_email) {
      const result = validateEmail(contactValue.app_contact_email)
      if (result) {
        setCustErrors((prevState) => ({ ...prevState, app_contact_email: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, app_contact_email: true }))
        return
      }
    }
    if (!contactValue.office_phone) {
      setCustErrors((prevState) => ({ ...prevState, office_phone: true }))
    }
    if (!contactValue.mobile_phone) {
      setCustErrors((prevState) => ({ ...prevState, mobile_phone: true }))
    }


    if (contactValue.app_contact_name && contactValue.department && contactValue.position && contactValue.app_contact_email && contactValue.office_phone && contactValue.mobile_phone) {

      let statusValue
      if (contactValue.is_active === "active") {
        statusValue = true
      } else if (contactValue.is_active === "inactive") {
        statusValue = false
      }

      const value = {
        ...contactValue,
        is_active: statusValue,
        app_contact_email: (contactValue.app_contact_email).toLowerCase()
        // app_department_id: siteID
      }

      const data = {
        companyID,
        editContactID,
        value
      }
      console.log(data);
      dispatch(updateContacts(data))
    }
  }


  const [officePhone, setOfficePhone] = useState()
  const [countryCode, setCountryCode] = useState("")
  register("app_contact.office_phone")

  useEffect(() => {
    if (officePhone) {
      setValue("app_contact.office_phone", officePhone)
    } else {
      setValue("app_contact.office_phone", "")
    }
  }, [officePhone])

  const changeCountry = (e) => {
    if (e !== undefined) {
      let value = getCountryCallingCode(e)
      setCountryCode(value);
    } else {
      setCountryCode()
    }
  }

  const isValidOfficePhone = () => {
    if (!officePhone) {
      return false;
    }
    const phoneNumber = `${officePhone}`;
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  };


  const [mobilePhone, setMobilePhone] = useState()
  const [countryCode2, setCountryCode2] = useState("")
  register("app_contact.mobile_phone")

  useEffect(() => {
    if (mobilePhone) {
      setValue("app_contact.mobile_phone", mobilePhone)
    } else {
      setValue("app_contact.mobile_phone", "")
    }
  }, [mobilePhone])

  const changeCountry2 = (e) => {
    if (e !== undefined) {
      let value = getCountryCallingCode(e)
      setCountryCode2(value);
    } else {
      setCountryCode2()
    }
  }

  const isValidMobilePhone = () => {
    if (!mobilePhone) {
      return false;
    }
    const phoneNumber = `${mobilePhone}`;
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  };


  const [editOfficePhone, setEditOfficePhone] = useState()
  const [countryCode3, setCountryCode3] = useState("")
  register("edit_contact.office_phone")

  useEffect(() => {
    if (editOfficePhone) {
      setValue("edit_contact.office_phone", editOfficePhone)
    } else {
      setValue("edit_contact.office_phone", "")
    }
  }, [editOfficePhone])

  const changeCountry3 = (e) => {
    if (e !== undefined) {
      let value = getCountryCallingCode(e)
      setCountryCode3(value);
    } else {
      setCountryCode3()
    }
  }

  const isValidEditOfficePhone = () => {
    if (!editOfficePhone) {
      return false;
    }
    const phoneNumber = `${editOfficePhone}`;
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  };

  const [editMobilePhone, setEditMobilePhone] = useState()
  const [countryCode4, setCountryCode4] = useState("")
  register("edit_contact.mobile_phone")

  useEffect(() => {
    if (editMobilePhone) {
      setValue("edit_contact.mobile_phone", editMobilePhone)
    } else {
      setValue("edit_contact.mobile_phone", "")
    }
  }, [editMobilePhone])

  const changeCountry4 = (e) => {
    if (e !== undefined) {
      let value = getCountryCallingCode(e)
      setCountryCode4(value);
    } else {
      setCountryCode4()
    }
  }

  const isValidEditMobilePhone = () => {
    if (!editMobilePhone) {
      return false;
    }
    const phoneNumber = `${editMobilePhone}`;
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

    return parsedPhoneNumber && parsedPhoneNumber.isValid();
  };


  // ADDRESS
  const [address, setAddress] = useState([])
  const [addressTableState, setAddressTableState] = useState(true)
  const [addressEditState, setAddressEditState] = useState(false)
  const [watchAddressState, setWatchAddressState] = useState(false)
  const [editAddressID, setEditAddressID] = useState('')


  const addressState = useSelector(state => state.addressReducer)

  // const [addresspageStatus, setAddressPageStatus] = useState(true)
  const [addresspageCount, setAddressPageCount] = useState(0);
  // const [addresspage, setAddressPage] = useState(0);
  // const [addresspageLimit, setAddressPageLimit] = useState(10);



  useEffect(() => {
    if (addressTableState) {
      if (addressState.addresses !== null) {
        // console.log(addressState.addresses);
        setAddress(addressState.addresses.result);
        setAddressPageCount(Math.ceil(addressState.addresses.summary.filteredsize / pageLimit) == 0 ? 1 : Math.ceil(addressState.addresses.summary.filteredsize / pageLimit));
      }
      if (addressState.isDeleted) {
        setDeleteModal(false);
        addresshandlePageClick(addresspage);
      }
      if (addressState.error) {
        setDeleteModal(false);
      }
    }

  }, [addressState])

  useEffect(() => {
    if (companyID && addressTableState) {
      // dispatch(getAddresses({ companyID, filter: `{"is_active":${addresspageStatus}}`, limit: addresspageLimit, skip: 0 }));
      addresshandlePageClick()
    }
  }, [pageStatus, companyID, addressTableState, pageLimit, page, siteID, customFilterData])


  const addresshandlePageClick = (index) => {
    let filter = `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}, "$or":[
      {"app_address_name":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_address":{"$regex":"${customFilterData}","$options":"i"}}
  ]}`
    let skips = 0;
    // setAddressPage(index);
    if (page > 0) {
      skips = page * pageLimit;
      dispatch(getAddresses({ companyID, filter: filter, limit: pageLimit, skip: skips }));

      // dispatch(getAddresses({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: skips }));
    }
    else {
      dispatch(getAddresses({ companyID, filter: filter, limit: pageLimit, skip: 0 }));

      // dispatch(getAddresses({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }
  }

  const addressColumns = useMemo(
    () => [
      {
        Header: ' Address Name',
        accessor: 'app_address_name',
        filterable: true,
        Cell: (cellProps) => {
          return <Position  {...cellProps} />
        }
      },
      {
        Header: 'Billing Address',
        accessor: 'app_billing_address',
        filterable: true,
        Cell: (cellProps) => {
          return <Position {...cellProps} />
        }
      },
      {
        Header: 'Delivery Adress',
        accessor: 'app_delivery_address',
        filterable: true,
        Cell: (cellProps) => {
          return <Position {...cellProps} />
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Button color="success" onClick={() => handleEditAddress(cellProps.row.original)} size="sm" type="button" > Edit </Button>
              {/* <a
                className="text-success"
                onClick={() => {
                  const addressData = cellProps.row.original;
                  handleEditAddress(addressData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </a>
              <a
                // to="#"
                className="text-info"
                onClick={() => {
                  handleViewAddress(cellProps.row.original)
                }}
              // onClick={() => {
              //   const addressData = cellProps.row.original;
              //   onClickDelete(addressData);
              // }}
              >
                <i className="mdi mdi-eye font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  view
                </UncontrolledTooltip>
              </a> */}
            </div>
          );
        }
      },
    ], [address]);

  const handleAddAddress = () => {
    setAddressTableState(false)
  }

  useEffect(() => {
    if (addressState.isCreated) {
      setAddressTableState(true)
      reset()
      dispatch(getAddresses({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }

    if (addressState.error) {

    }
    if (addressState.isUpdated) {
      setAddressTableState(true)
      setAddressEditState(false)
      setEditAddressID("")
      dispatch(getAddresses({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }

    if (addressState.isDeleted) {
      setDeleteModal(false);
      dispatch(getAddresses({ companyID, filter: `{"app_department_id":${JSON.stringify(siteID)},"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }
  }, [addressState])

  const watchAddressFields = (e) => {
    if (watchAddressState) {
      const inputID = e.target.id
      const inputValue = e.target.value
      if (inputValue) {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: true }))
      }
    }
  }

  const addAddress = () => {
    setWatchAddressState(true)
    const addressValue = getValues("app_address")
    if (!addressValue.app_address_name) {
      setCustErrors((prevState) => ({ ...prevState, app_address_name: true }))
    }

    if (!addressValue.app_delivery_address) {
      setCustErrors((prevState) => ({ ...prevState, app_delivery_address: true }))
    }

    if (!addressValue.app_billing_address) {
      setCustErrors((prevState) => ({ ...prevState, app_billing_address: true }))
    }


    if (addressValue.app_address_name && addressValue.app_delivery_address && addressValue.app_billing_address) {
      const token = sessionStorage.getItem('authUser')
      // console.log(addressValue);

      let statusValue
      if (addressValue.is_active === "active") {
        statusValue = true
      } else if (addressValue.is_active === "inactive") {
        statusValue = false
      }

      const value = {
        ...addressValue,
        is_active: statusValue,
        app_department_id: siteID,
        app_business_id: companyID
      }

      const data = {
        companyID,
        value
      }
      // console.log(data);
      dispatch(postAddress(data))

    }
  }

  const handleDeleteAddress = (value) => {
    if (value.app_address_id) {
      let AddressID = value.app_address_id
      if (companyID && AddressID) {
        const data = {
          companyID,
          AddressID
        };
        dispatch(deleteAddress(data));
      }
    }
  }

  // get by id Address
  useEffect(() => {
    if (addressState.singleAddress !== null) {
      const addressData = addressState.singleAddress
      setValue("edit_address.app_address_name", addressData.app_address_name)
      setValue("edit_address.app_billing_address", addressData.app_billing_address)
      setValue("edit_address.app_delivery_address", addressData.app_delivery_address)

      if (addressData.is_active === true) {
        setValue("edit_address.is_active", "active")
      } else if (addressData.is_active === false) {
        setValue("edit_address.is_active", "inactive")
      }
      setAddressTableState(false)
      setAddressEditState(true)
    }
  }, [addressState.singleAddress])


  const handleEditAddress = (value) => {
    if (value.app_address_id) {
      setEditAddressID(value.app_address_id)
      let addressID = value.app_address_id
      let businessID = value.app_business_id

      const data = {
        addressID,
        businessID
      }
      dispatch(getAddress(data))

    }
  }


  const [addressReadOnly, setAddressReadOnly] = useState(false)

  const handleViewAddress = (value) => {
    if (value.app_address_id) {
      setAddressReadOnly(true)
      setEditAddressID(value.app_address_id)
      let addressID = value.app_address_id
      let businessID = value.app_business_id

      const data = {
        addressID,
        businessID
      }
      dispatch(getAddress(data))

    }
  }

  const updateAddresses = () => {

    const addressValue = getValues("edit_address")

    setWatchAddressState(true)
    if (!addressValue.app_address_name) {
      setCustErrors((prevState) => ({ ...prevState, app_address_name: true }))
    }
    if (!addressValue.app_delivery_address) {
      setCustErrors((prevState) => ({ ...prevState, app_delivery_address: true }))
    }
    if (!addressValue.app_billing_address) {
      setCustErrors((prevState) => ({ ...prevState, app_billing_address: true }))
    }

    if (addressValue.app_address_name && addressValue.app_delivery_address && addressValue.app_billing_address) {

      let statusValue
      if (addressValue.is_active === "active") {
        statusValue = true
      } else if (addressValue.is_active === "inactive") {
        statusValue = false
      }

      const value = {
        ...addressValue,
        is_active: statusValue,
        app_department_id: siteID,
      }

      const data = {
        companyID,
        editAddressID,
        value
      }
      // console.log(data);
      dispatch(updateAddress(data))
    }

  }



  // orders
  // const [sites, setSites] = useState([])
  const [orderTableState, setOrderTableState] = useState(true)
  const [watchOrderState, setWatchOrderState] = useState(false)


  //dummy need to implement
  const orderhandlePageClick = () => {

  }

  const orderColumns = useMemo(
    () => [
      {
        Header: 'Order ID',
        // accessor: 'app_department_name',
        filterable: true,
        Cell: (cellProps) => {
          return <OrderID {...cellProps} />
        }
      },
      {
        Header: 'Order Date',
        // accessor: 'department_description',
        filterable: true,
        Cell: (cellProps) => {
          return <OrderDate {...cellProps} />
        }
      },
      {
        Header: 'Order Amount',
        // accessor: 'department_description',
        filterable: true,
        Cell: (cellProps) => {
          return <OrderAmount {...cellProps} />
        }
      },
      {
        Header: 'Status',
        // accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <ApprovalStatus {...cellProps} />
        }
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">

              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const siteData = cellProps.row.original;
                  handleEditSite(siteData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const siteData = cellProps.row.original;
                  onClickDelete(siteData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link> */}
            </div>
          );
        }
      },
    ], []);


  const [statusNewOrder, setStatusNewOrder] = useState({
    state: false,
    name: "Inactive"
  })

  const statusChangeNewOrder = () => {
    const value = getValues('app_site.is_active')
    if (value) {
      setStatusNewOrder({
        state: true,
        name: "Active"
      })

    }
    else {
      setStatusNewOrder({
        state: false,
        name: "Inactive"
      })

    }
  }

  const handleAddOrder = () => {
    // setOrderTableState(false)
  }

  const watchOrderFields = (e) => {
    if (watchOrderState) {
      const inputID = e.target.id
      const inputValue = e.target.value
      if (inputValue) {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: true }))
      }
    }
  }

  const addOrder = () => {
    setWatchOrderState(true)
    const siteValue = getValues("app_site")
    if (!siteValue.app_department_name) {
      setCustErrors((prevState) => ({ ...prevState, app_department_name: true }))
    } if (!siteValue.department_keyid) {
      setCustErrors((prevState) => ({ ...prevState, department_keyid: true }))
    }
    if (siteValue.app_department_name && siteValue.department_keyid) {
      const token = sessionStorage.getItem('authUser')
      axios.post(API_BASE_URL + DEPARTMENT_URL + "/" + companyID, siteValue, getHeader(token))
        .then(res => {
          setValue("app_site", "")
          setCustErrors((prevState) => ({ ...prevState, app_department_name: false }))
          setCustErrors((prevState) => ({ ...prevState, department_keyid: false }))
          setOrderTableState(true)
        }).catch(err => {
          console.log(err);
        })
    }
  }

  const getBackPath = () => {
    const { skip, limit, status } = JSON.parse(sessionStorage.getItem("companyListQuery"))
    return `/companyList?skip=${skip}&&limit=${limit}&&status=${status}`
  }

  const copyAddr_app_address = () => {
    const value = getValues('app_address.app_billing_address')
    setValue('app_address.app_delivery_address', value)
  }

  const copyAddr_edit_address = () => {
    const value = getValues('edit_address.app_billing_address')
    setValue('edit_address.app_delivery_address', value)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={companyName} breadcrumbItem="Companies" />

          <div className="checkout-tabs mb-4">
            <Row>
              <Col xl="2" md="3" sm="3">
                <Nav className="input-navbar" pills>
                  <NavItem className="me-3">
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setSiteEditState(false); setSiteTableState(false); setactiveTab("1"); history.push(`/updateCompany/${id}?tab=${1}&&skip=${0}&&limit=${10}&&status=${true}`)
                      }}
                    >

                      <i className="bx bx-buildings d-block check-nav-icon mt-2 mb-2" />
                      <p className="text-content font-weight-bold mb-0">Company</p>
                      <p className="text-content font-weight-bold mb-2">Details</p>

                    </NavLink>
                  </NavItem>
                  <NavItem className="me-3">
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setSiteEditState(false); setSiteTableState(true); setactiveTab("2"); history.push(`/updateCompany/${id}?tab=${2}&&skip=${0}&&limit=${10}&&status=${true}`)
                      }}
                    >
                      <i className="bx bx-buildings d-block check-nav-icon mt-2 mb-2" />
                      <p className="text-content font-weight-bold mb-0">{"Site"}</p>
                      <p className="text-content font-weight-bold mb-2">&nbsp;{""}</p>
                    </NavLink>
                  </NavItem >
                  {(tab === "3") && <NavItem className="me-2">
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                    // onClick={() => {
                    //   setactiveTab("3")
                    // }}
                    >
                      <i className="bx bxs-contact d-block check-nav-icon mt-2 mb-2" />
                      <p className="text-content font-weight-bold mb-0">Contact</p>
                      <p className="text-content font-weight-bold mb-2">Details</p>

                    </NavLink>
                  </NavItem>}
                  {(tab === "4") && <NavItem className="me-2">
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                    // onClick={() => {
                    //   setactiveTab("4")
                    // }}
                    >
                      <i className="bx bxs-book-reader d-block check-nav-icon mt-2 mb-2" />
                      <p className="text-content font-weight-bold mb-0">Address</p>
                      <p className="text-content font-weight-bold mb-2">Book</p>

                    </NavLink>
                  </NavItem>}
                  {(tab === "5") && <NavItem className=" me-2">
                    <NavLink
                      className={classnames({ active: activeTab === "5" })}
                    // onClick={() => {
                    //   setactiveTab("5")
                    // }}
                    >
                      <i className="bx bxs-cart d-block check-nav-icon mt-2 mb-2" />
                      <p className="text-content  font-weight-bold mb-0">Orders</p>
                      <p className="text-content font-weight-bold mb-2">Details</p>
                    </NavLink>
                  </NavItem>}
                </Nav>
              </Col>
              <Col xl="10" md="9" sm="9">
                <Card>
                  <CardBody>
                    <Form>
                      <TabContent activeTab={activeTab}>

                        {/* Company */}

                        <TabPane
                          tabId="1">
                          <Container fluid>
                            <Row>
                              <Col lg="12">
                                {/* <h5 className="text-decoration-underline">Company Details</h5><br /> */}
                                <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Customer ID <span style={{ color: "red" }}>*</span> </Label>
                                    <Col lg="5" md="6" >
                                      <input style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchCompanyFields} id="app_business_keyid" className={`form form-control ${custErrors.app_business_keyid && 'is-invalid'}`} type="email" placeholder="Enter Company ID" {...register("app_company.app_business_keyid", { required: true })} />
                                      {custErrors.app_business_keyid && <p style={{ color: 'red' }}>The Field is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Customer Name <span style={{ color: "red" }}>*</span></Label>
                                    <Col lg="5" md="6">
                                      <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} onInput={watchCompanyFields} id="app_business_name" className={`form form-control ${custErrors.app_business_name && 'is-invalid'}`} type="text" placeholder="Enter Company Name" {...register("app_company.app_business_name", { required: true })} />
                                      {custErrors.app_business_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row>
                                {/* <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Company Address <span style={{ color: "red" }}>*</span> </Label>
                                    <Col lg="5" md="6">
                                      <textarea onInput={watchCompanyFields} id="app_company_address" className={`form form-control ${custErrors.app_company_address && 'is-invalid'}`} type="textarea" placeholder="Enter Company Address" {...register("app_company.app_company_address", { required: true })} />
                                      {custErrors.app_company_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row> */}
                                <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Industry <span style={{ color: "red" }}>*</span></Label>
                                    <Col lg="5" md="6">
                                      {UpadatePermission ?
                                        <Creatable
                                          isDisabled
                                          styles={customStyles}
                                          onChange={(e) => {
                                            const salesID = ((e.value).replace(/\s/g, "").toLowerCase())
                                            setCreatableSelect(e)
                                            setCurrentIndustry(e)
                                            setValue("app_company.app_sales_industry", salesID)
                                            watchSalesFields(e)
                                          }}
                                          // className={custErrors.app_sales_industry && 'is-invalid'}
                                          aria-label="Default select example"
                                          options={salesIndustries}
                                          value={currentIndustry}
                                          maxMenuHeight={185}
                                        /> :
                                        <Creatable
                                          onChange={(e) => {
                                            const salesID = ((e.value).replace(/\s/g, "").toLowerCase())
                                            // console.log("e", e);
                                            setCreatableSelect(e)
                                            setCurrentIndustry(e)
                                            setValue("app_company.app_sales_industry", salesID)
                                            watchSalesFields(e)
                                          }}
                                          className={custErrors.app_sales_industry && 'is-invalid'}
                                          aria-label="Default select example"
                                          options={salesIndustries}
                                          value={currentIndustry}
                                          maxMenuHeight={185}
                                        />
                                      }
                                      {custErrors.app_sales_industry && <p style={{ color: 'red' }}>This Field is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                    <Col lg="5" md="6">
                                      <select style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={DeletePermission} className="form-select d-inline" {...register('app_company.is_active')}>
                                        <option hidden value="">Select Status</option>
                                        <option value="active">ACTIVE</option>
                                        <option value="inactive">INACTIVE</option>
                                      </select>
                                    </Col>
                                  </FormGroup>
                                </Row>
                              </Col>
                            </Row>
                          </Container>
                        </TabPane>

                        {/* Sites */}

                        <TabPane
                          tabId="2"
                          id="v-pills-payment"
                          role="tabpanel"
                          aria-labelledby="v-pills-payment-tab">
                          <Container fluid>
                            {siteTableState ?
                              <>
                                <TableContainer
                                  columns={siteColumns}
                                  data={sites}
                                  isGlobalFilter={true}
                                  isAddSite={true}
                                  // isAddSite={false}
                                  // customPageSize={10}
                                  handleAddSite={handleAddSite}
                                  className="custom-header-css"
                                  // customPageStatus={(data) => setSitePageStatus(data)}
                                  // pageSizeTransfer={(data) => setSitePageLimit(data)}
                                  // handlePageClick={sitehandlePageClick}
                                  customPageCount={sitepageCount}
                                  customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                                // customPageIndex={sitepage}
                                />
                              </>

                              : (siteEditState ?
                                <div>
                                  {/* {UpadatePermission ? <h4 style={{ textDecoration: "underline" }}>View Site</h4> : <h4 style={{ textDecoration: "underline" }}>Edit Site</h4>} */}
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Site ID <span style={{ color: "red" }}>*</span> </Label>
                                      <Col lg="8" md="6">
                                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} onInput={watchSiteFields} id="app_department_keyid" className={`form form-control ${custErrors.app_department_keyid && 'is-invalid'}`} placeholder="Enter Site ID" {...register(`edit_site.app_department_keyid`)} />
                                        {custErrors.app_department_keyid && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Site Name <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="8" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} onInput={watchSiteFields} id="app_department_name" className={`form form-control ${custErrors.app_department_name && 'is-invalid'}`} placeholder="Enter Site Name" {...register(`edit_site.app_department_name`)} />
                                        {custErrors.app_department_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Site Description
                                      </Label>
                                      <Col lg="8" md="6">
                                        <textarea readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} className="form form-control" type="textarea" placeholder="Enter Site Description"{...register(`edit_site.department_description`)} />
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Business Unit <span style={{ color: "red" }}>*</span> </Label>
                                      <Col lg="8" md="6">
                                        <select disabled={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} defaultValue="" onInput={watchSiteFields} id="app_businessunit_id" className={`form-select d-inline ${custErrors.app_businessunit_id && 'is-invalid'}`} aria-label="Default select example" {...register("edit_site.app_businessunit_id", { required: true })}>
                                          <option disabled hidden value="">Select the Business Unit</option>
                                          {businessUnitList.map((businessunitlist) => (
                                            <option key={businessunitlist.app_businessunit_id} value={businessunitlist.app_businessunit_id}>{businessunitlist.app_business_unit_name}</option>
                                          ))}
                                        </select>
                                        {custErrors.app_businessunit_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                      <Col lg="8" md="6">
                                        <select disabled style={{ backgroundColor: "#D2D4D1" }} onInput={watchSiteFields} className={`form-select d-inline ${custErrors.app_business_id && 'is-invalid'}`} aria-label="Default select example" id="app_business_id" {...register("edit_site.app_business_id", { required: true })}>
                                          <option value="">Company</option>
                                          {businessList.map((business) => (
                                            <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                          ))}
                                        </select>
                                        {custErrors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <hr />
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Account ID </Label>
                                      <Col lg="8" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchSiteFields} id="app_account_keyid" className={`form form-control ${custErrors.app_account_keyid && 'is-invalid'}`} placeholder=" " {...register("edit_site.app_account_keyid")} />
                                        {custErrors.app_account_keyid && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Credit Limit  </Label>
                                      <Col lg="8" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchSiteFields} id="credit_limit" className={`form form-control ${custErrors.credit_limit && 'is-invalid'}`} placeholder=" " {...register("edit_site.credit_limit")} />
                                        {custErrors.credit_limit && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  {/* <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Account Balance </Label>
                                      <Col lg="8" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchSiteFields} id="account_balance" className={`form form-control ${custErrors.account_balance && 'is-invalid'}`} placeholder=" " {...register("edit_site.account_balance")} />
                                        {custErrors.account_balance && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row> */}

                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Oracle Site ID </Label>
                                      <Col lg="8" md="6">
                                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchSiteFields} id="oracle_site_ID" className={`form form-control ${custErrors.oracle_site_ID && 'is-invalid'}`} placeholder="Enter Oracle Site" {...register("edit_site.oracle_site_ID")} />
                                        {custErrors.oracle_site_ID && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Oracle Credit Status  </Label>
                                      <Col lg="8" md="6">
                                        <select defaultValue={"inactive"} id="oracle_credit_status" style={{ backgroundColor: "#D2D4D1" }} disabled className="form-select d-inline" {...register('edit_site.oracle_credit_status')}>
                                          {/* <option hidden value="">Select Status</option> */}
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                        {/* {custErrors.oracle_credit_status && <p style={{ color: 'red' }}>This Field is required</p>} */}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Account Status  </Label>
                                      <Col lg="8" md="6">
                                        <select disabled style={{ backgroundColor: "#D2D4D1" }} className="form-select d-inline" {...register('edit_site.account_status')}>
                                          {/* <option hidden value="">Select Status</option> */}
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                        {custErrors.account_status && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <hr />
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                      <Col lg="8" md="6">
                                        <select disabled={DeletePermission} style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} className="form-select d-inline" {...register('edit_site.is_active')}>
                                          {/* <option hidden value="">Select Status</option> */}
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row className="mt-4">
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                      <Col lg="5" md="6">
                                        <Button color="secondary" onClick={() => { setSiteTableState(true); setSiteEditState(false); setSiteReadOnly(false) }}>Back</Button>
                                        <Button className="ms-2" color="success" onClick={updateSite}>Update</Button>
                                      </Col>
                                    </FormGroup>
                                  </Row>

                                </div>
                                :
                                <div>
                                  {/* <h4 style={{ textDecoration: "underline" }}>Add Site</h4> */}
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Site Name <span style={{ color: "red" }}>*</span> </Label>
                                      <Col lg="8" md="6">
                                        <input onInput={watchSiteFields} id="app_department_name" className={`form form-control ${custErrors.app_department_name && 'is-invalid'}`} placeholder="Enter Site name" {...register(`app_site.app_department_name`)} />
                                        {custErrors.app_department_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Site Description</Label>
                                      <Col lg="8" md="6">
                                        <textarea className="form form-control" type="textarea" placeholder="Enter Site Description"{...register(`app_site.department_description`)} />
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Business Unit <span style={{ color: "red" }}>*</span> </Label>
                                      <Col lg="8" md="6">
                                        <select defaultValue="" onInput={watchSiteFields} id="app_businessunit_id" className={`form-select d-inline ${custErrors.app_businessunit_id && 'is-invalid'}`} aria-label="Default select example" {...register("app_site.app_businessunit_id", { required: true })}>
                                          <option disabled hidden value="">Select the Business Unit</option>
                                          {businessUnitList.map((businessunitlist) => (
                                            <option key={businessunitlist.app_businessunit_id} value={businessunitlist.app_businessunit_id}>{businessunitlist.app_business_unit_name}</option>
                                          ))}
                                        </select>
                                        {custErrors.app_businessunit_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  {/* <hr />
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Account ID </Label>
                                      <Col lg="8" md="6">
                                        <input defaultValue={"Account id from CSMS"} style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchSiteFields} id="app_account_keyid" className={`form form-control ${custErrors.app_account_keyid && 'is-invalid'}`} placeholder=" " {...register("app_site.app_account_keyid")} />
                                        {custErrors.app_account_keyid && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Credit Limit  </Label>
                                      <Col lg="8" md="6">
                                        <input defaultValue={"Credit limit from CSMS"} style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchSiteFields} id="credit_limit" className={`form form-control ${custErrors.credit_limit && 'is-invalid'}`} placeholder=" " {...register("app_site.credit_limit")} />
                                        {custErrors.credit_limit && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Account Balance  </Label>
                                      <Col lg="8" md="6">
                                        <input defaultValue={"Account Balance From CSMS"} style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchSiteFields} id="account_balance" className={`form form-control ${custErrors.account_balance && 'is-invalid'}`} placeholder=" " {...register("app_site.account_balance")} />
                                        {custErrors.account_balance && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Oracle Site ID  </Label>
                                      <Col lg="8" md="6">
                                        <input defaultValue={"Oracle Site Id from CSMS"} style={{ backgroundColor: "#D2D4D1" }} readOnly onInput={watchSiteFields} id="oracle_site_ID" className={`form form-control ${custErrors.oracle_site_ID && 'is-invalid'}`} placeholder="Enter Oracle Site" {...register("app_site.oracle_site_ID")} />
                                        {custErrors.oracle_site_ID && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Oracle Credit Status </Label>
                                      <Col lg="8" md="6">
                                        <select defaultValue={"inactive"} id="oracle_credit_status" style={{ backgroundColor: "#D2D4D1" }} disabled className="form-select d-inline" {...register('app_site.oracle_credit_status')}>
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Account Status  </Label>
                                      <Col lg="8" md="6">
                                        <select defaultValue={"inactive"} className="form-select d-inline" {...register('app_site.account_status')}>
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                        {custErrors.account_status && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <hr /> */}
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                      <Col lg="8" md="6">
                                        <select defaultValue={"active"} className="form-select d-inline" {...register('app_site.is_active')}>
                                          {/* <option hidden value="">Select Status</option> */}
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  {/* <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label><br />
                                      <Col lg="5" md="6">
                                        <div className="form-check form-switch form-switch-lg mb-3">
                                          <input type="checkbox" className="form-check-input" id="roleStatus" onInput={statusChangeNew} defaultChecked={false} {...register('app_site.is_active')} />
                                          <label className="form-check-label" htmlFor="roleStatus">
                                            {statusNew.name}
                                          </label>
                                        </div>
                                      </Col>
                                    </FormGroup>
                                  </Row> */}
                                  <Row className="mt-4">
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                      <Col lg="5" md="6">
                                        <Button className="m-2" color="secondary" onClick={() => { reset(); setSiteTableState(true) }}>Back</Button>
                                        <Button color="success" onClick={addSite}>Add</Button>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                </div>)
                            }
                          </Container>
                        </TabPane>

                        {/* Contacts */}

                        <TabPane
                          tabId="3"
                          id="v-pills-payment"
                          role="tabpanel"
                          aria-labelledby="v-pills-payment-tab">
                          <Container fluid>
                            {contactTableState ?
                              <>
                                <Row >
                                  <Col xs="8">
                                    <>
                                      {/* <div className="d-inline p-2 bg-primary text-white">d-inline</div> */}
                                      {/* <div style={{ fontSize: "18px" }} className="d-inline p-2">Site Name : </div>
                                      <div style={{ textTransform: "uppercase", fontSize: "15px", }} className="d-inline ">{siteName}</div> */}
                                    </>
                                  </Col>
                                  {/* <Col xs="4" className="d-flex justify-content-end mb-2">
                                    <Button style={{ marginRight: "4px", }} color="info" type="button" onClick={() => {
                                      setactiveTab("2"); setContactEditState(false); setContactTableState(true); history.push(`/updateCompany/${id}?tab=${2}&&skip=${0}&&limit=${10}&&status=${true}`)
                                    }}>Back</Button>
                                  </Col> */}
                                </Row>
                                <TableContainer
                                  columns={contactColumns}
                                  data={contacts}
                                  isGlobalFilter={true}
                                  isAddContact={true}
                                  handleAddContact={handleAddContact}
                                  customPageCount={contactpageCount}
                                  customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                                  // customPageIndex={contactpage}
                                  // isStatusDropDown={false}
                                  // pageSizeTransfer={(data) => setContactPageLimit(data)}
                                  // customPageStatus={(data) => setContactPageStatus(data)}
                                  // handlePageClick={contacthandlePageClick}
                                  // customPageSize={10}
                                  className="custom-header-css" />
                              </>

                              : (contactEditState ?

                                <div>
                                  {/* {UpadatePermission ? <h4 style={{ textDecoration: "underline" }}>View Contact</h4> : <h4 style={{ textDecoration: "underline" }}>Edit Contact</h4>} */}
                                  {/* <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Site <span style={{ color: "red" }}>*</span> </Label>
                                      <Col lg="5" md="6">
                                        <select disabled style={{ backgroundColor: "#D2D4D1" }} onInput={watchContactFields} id="app_department_id" className={`form-select d-inline ${custErrors.app_department_id && 'is-invalid'}`} aria-label="Default select example" {...register("edit_contact.app_department_id", { required: true })}>
                                          <option disabled hidden value="">Select the Site</option>
                                          {departments.map((department) => (
                                            <option key={department.app_department_id} value={department.app_department_id}>{department.app_department_name}</option>
                                          ))}
                                        </select>
                                        {custErrors.app_department_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row> */}
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Name <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} onInput={watchContactFields} id="app_contact_name" className={`form form-control ${custErrors.app_contact_name && 'is-invalid'}`} placeholder="Enter Contact Person Name" {...register(`edit_contact.app_contact_name`)} />
                                        {custErrors.app_contact_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>

                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Position <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} onInput={watchContactFields} id="position" className={`form form-control ${custErrors.position && 'is-invalid'}`} placeholder="Enter Position" {...register(`edit_contact.position`)} />
                                        {custErrors.position && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Department <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} onInput={watchContactFields} id="department" className={`form form-control ${custErrors.department && 'is-invalid'}`} placeholder="Enter Department" {...register(`edit_contact.department`)} />
                                        {custErrors.department && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row >
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Email <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} onInput={watchContactFields} type="email" id="app_contact_email" className={`form form-control ${custErrors.app_contact_email && 'is-invalid'}`} placeholder="Enter Email" {...register(`edit_contact.app_contact_email`)} />
                                        {custErrors.app_contact_email && <p style={{ color: "red" }}>Valid Email is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  {/* <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Office <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} maxLength={"20"} onInput={watchContactFields} id="office_phone" className={`form form-control ${custErrors.office_phone && 'is-invalid'}`} placeholder="Enter office phone" {...register(`edit_contact.office_phone`)} />
                                        {custErrors.office_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Mobile <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} maxLength={"20"} onInput={watchContactFields} id="mobile_phone" className={`form form-control ${custErrors.mobile_phone && 'is-invalid'}`} placeholder="Enter mobile phone" {...register(`edit_contact.mobile_phone`)} />
                                        {custErrors.mobile_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row> */}
                                  <Row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                      Office <span style={{ color: "red" }}>*</span> {countryCode3 && <span style={{ color: "steelblue" }}>{`(+${countryCode3})`}</span>}
                                    </Label>
                                    <Col lg="5" md="6">
                                      <FormGroup row
                                        className="form form-control form-phone" >
                                        <PhoneInput
                                          disabled={UpadatePermission}
                                          style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}}
                                          onCountryChange={e => changeCountry3(e)}
                                          placeholder="Enter Office Phone Number"
                                          value={editOfficePhone}
                                          onChange={setEditOfficePhone} />
                                      </FormGroup>
                                      {isValidEditOfficePhone() ? (
                                        ""
                                      ) : (
                                        <p style={{ color: 'red' }}>Invalid Office number</p>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                      Mobile <span style={{ color: "red" }}>*</span> {countryCode4 && <span style={{ color: "steelblue" }}>{`(+${countryCode4})`}</span>}
                                    </Label>
                                    <Col lg="5" md="6">
                                      <FormGroup row
                                        className="form form-control form-phone" >
                                        <PhoneInput
                                          // readOnly
                                          disabled={UpadatePermission}
                                          style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}}
                                          onCountryChange={e => changeCountry4(e)}
                                          placeholder="Enter Mobile Phone Number"
                                          value={editMobilePhone}
                                          onChange={setEditMobilePhone} />
                                      </FormGroup>
                                      {isValidEditMobilePhone() ? (
                                        ""
                                      ) : (
                                        <p style={{ color: 'red' }}>Invalid Mobile number</p>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Remarks
                                      </Label><Col lg="5" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} className="form form-control" placeholder="Enter Remarks" {...register(`edit_contact.remarks`)} />
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <hr />

                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                      <Col lg="5" md="6">
                                        <select disabled={DeletePermission} style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} className="form-select d-inline" {...register('edit_contact.is_active')}>
                                          {/* <option hidden value="">Select Status</option> */}
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  {!UpadatePermission || !DeletePermission ?
                                    <Row className="mt-4">
                                      <FormGroup row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                        <Col lg="5" md="6">
                                          <Button color="secondary" onClick={() => { setContactTableState(true); setContactEditState(false); setContactReadOnly(false); }}>Back</Button>
                                          <Button className="ms-2" color="success" onClick={updateContact}>Update</Button>
                                        </Col>
                                      </FormGroup>
                                    </Row>
                                    :
                                    <Row className="mt-4">
                                      <FormGroup row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                        <Col lg="5" md="6">
                                          <Button color='secondary' type='button' onClick={() => { setContactTableState(true); setContactEditState(false); setContactReadOnly(false); }}>
                                            Back
                                          </Button>
                                        </Col>
                                      </FormGroup>
                                    </Row>
                                  }
                                </div>
                                :
                                <div>
                                  {/* <h4 style={{ textDecoration: "underline" }}>Add Contact</h4> */}
                                  <Row className="mt-2">
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Name <span style={{ color: "red" }}>*</span></Label>
                                      <Col lg="5" md="6">
                                        <input onInput={watchContactFields} id="app_contact_name" className={`form form-control ${custErrors.app_contact_name && 'is-invalid'}`} placeholder="Enter Contact Person Name" {...register(`app_contact.app_contact_name`)} />
                                        {custErrors.app_contact_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Position <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input onInput={watchContactFields} id="position" className={`form form-control ${custErrors.position && 'is-invalid'}`} placeholder="Enter Position" {...register(`app_contact.position`)} />
                                        {custErrors.position && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Department <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input onInput={watchContactFields} id="department" className={`form form-control ${custErrors.department && 'is-invalid'}`} placeholder="Enter Department" {...register(`app_contact.department`)} />
                                        {custErrors.department && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Email <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input onInput={watchContactFields} type="email" id="app_contact_email" className={`form form-control ${custErrors.app_contact_email && 'is-invalid'}`} placeholder="Enter Email" {...register(`app_contact.app_contact_email`)} />
                                        {custErrors.app_contact_email && <p style={{ color: "red" }}>Valid Email is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                      Office <span style={{ color: "red" }}>*</span>  {countryCode && <span style={{ color: "steelblue" }}>{`(+${countryCode})`}</span>}
                                    </Label>
                                    <Col lg="5" md="6">
                                      <FormGroup row
                                        className="form form-control form-phone" >
                                        <PhoneInput
                                          onCountryChange={e => changeCountry(e)}
                                          placeholder="Enter Office Phone Number"
                                          value={officePhone}
                                          onChange={setOfficePhone} />
                                      </FormGroup>
                                      {officePhone && (isValidOfficePhone() ? "" : <p style={{ color: 'red' }}>Invalid Office number</p>)}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                      Mobile <span style={{ color: "red" }}>*</span> {countryCode2 && <span style={{ color: "steelblue" }}>{`(+${countryCode2})`}</span>}
                                    </Label>
                                    <Col lg="5" md="6">
                                      <FormGroup row className="form form-control form-phone" >
                                        <PhoneInput
                                          onCountryChange={e => changeCountry2(e)}
                                          placeholder="Enter Mobile Phone Number"
                                          value={mobilePhone}
                                          onChange={setMobilePhone} />
                                      </FormGroup>
                                      {mobilePhone && (isValidMobilePhone() ? "" : <p style={{ color: 'red' }}>Invalid Mobile number</p>)}
                                    </Col>
                                  </Row>
                                  {/* <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Office <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input maxLength={"20"} onInput={watchContactFields} id="office_phone" className={`form form-control ${custErrors.office_phone && 'is-invalid'}`} placeholder="Enter office phone" {...register(`app_contact.office_phone`)} />
                                        {custErrors.office_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Mobile <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input maxLength={"20"} onInput={watchContactFields} id="mobile_phone" className={`form form-control ${custErrors.mobile_phone && 'is-invalid'}`} placeholder="Enter mobile phone" {...register(`app_contact.mobile_phone`)} />
                                        {custErrors.mobile_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row> */}
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Remarks
                                      </Label><Col lg="5" md="6">
                                        <input className="form form-control" placeholder="Enter Remarks" {...register(`app_contact.remarks`)} />
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <hr />
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                      <Col lg="5" md="6">
                                        <select defaultValue="active" className="form-select d-inline" {...register('app_contact.is_active')}>
                                          {/* <option hidden value="">Select Status</option> */}
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row className="mt-4">
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                      <Col lg="5" md="6">
                                        <Button className="m-2" color="secondary" onClick={() => {
                                          reset(); setContactTableState(true); setOfficePhone(''); setMobilePhone(''); setCountryCode(''); setCountryCode2('');
                                        }}>Back</Button>
                                        <Button color="success" onClick={addContact}>Add</Button>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                </div>)
                            }
                          </Container>
                        </TabPane>
                        {/* Address */}

                        <TabPane
                          tabId="4"
                          id="v-pills-payment"
                          role="tabpanel"
                          aria-labelledby="v-pills-payment-tab">
                          <Container fluid>
                            {addressTableState ?
                              <>
                                <Row >
                                  <Col xs="8"  >
                                    <>
                                      {/* <div className="d-inline p-2 bg-primary text-white">d-inline</div> */}
                                      {/* <div style={{ fontSize: "18px" }} className="d-inline p-2">Site Name : </div>
                                      <div style={{ textTransform: "uppercase", fontSize: "15px", }} className="d-inline ">{siteName}</div> */}
                                    </>
                                  </Col>
                                  {/* <Col xs="4" className="d-flex justify-content-end mb-2">
                                    <Button style={{ marginRight: "4px", }} color="info" type="button" onClick={() => {
                                      setactiveTab("2"); setAddressEditState(false); history.push(`/updateCompany/${id}?tab=${2}&&skip=${0}&&limit=${10}&&status=${true}`)
                                    }}>Back</Button>
                                  </Col> */}
                                </Row>
                                <TableContainer
                                  columns={addressColumns}
                                  data={address}
                                  isGlobalFilter={true}
                                  isAddAddress={true}
                                  // customPageSize={10}
                                  handleAddAddress={handleAddAddress}
                                  // customPageStatus={(data) => setAddressPageStatus(data)}
                                  // handlePageClick={addresshandlePageClick}
                                  customPageCount={addresspageCount}
                                  customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                                  // customPageIndex={addresspage}
                                  // isStatusDropDown={false}
                                  // pageSizeTransfer={(data) => setAddressPageLimit(data)}
                                  className="custom-header-css" />
                              </>
                              : (addressEditState ?
                                <div>
                                  {/* {UpadatePermission ? <h4 style={{ textDecoration: "underline" }}>View Address</h4> : <h4 style={{ textDecoration: "underline" }}>Edit Address</h4>} */}
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Address Name <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <input readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} onInput={watchAddressFields} id="app_address_name" className={`form form-control ${custErrors.app_address_name && 'is-invalid'}`} placeholder="Enter Address Name" {...register(`edit_address.app_address_name`)} />
                                        {custErrors.app_address_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                                        Billing  Address <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <textarea readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} rows="5" onInput={watchAddressFields} className={`form form-control ${custErrors.app_billing_address && 'is-invalid'}`} placeholder="Enter Address"  {...register(`edit_address.app_billing_address`, { required: true })} />
                                        <i className="far fa-copy position-absolute fa-lg" onClick={() => copyAddr_edit_address()}
                                          style={{ right: "20px", top: "15%", transform: "translateY(-50%)", cursor: "pointer" }}  ></i>
                                        {errors.app_billing_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  {/* <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Delivery  Address <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <textarea readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} rows="5" onInput={watchAddressFields} className={`form form-control${custErrors.app_delivery_address && 'is-invalid'}`} placeholder="Enter Address" {...register(`edit_address.app_delivery_address`, { required: true })} />
                                        {errors.app_delivery_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row> */}
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Delivery  Address <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <textarea readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} rows="5" onInput={watchAddressFields} className={`form form-control ${custErrors.app_delivery_address && 'is-invalid'}`} placeholder="Enter Address"  {...register(`edit_address.app_delivery_address`, { required: true })} />
                                        {errors.app_delivery_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                      <Col lg="5" md="6">
                                        <select disabled={DeletePermission} style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} className="form-select d-inline" {...register('edit_address.is_active')}>
                                          {/* <option hidden value="">Select Status</option> */}
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  {(!UpadatePermission || !DeletePermission) ?
                                    <Row className="mt-4">
                                      <FormGroup row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                        <Col lg="5" md="6">
                                          <Button color="secondary" onClick={() => { setAddressTableState(true); setAddressEditState(false); setAddressReadOnly(false); }}>Back</Button>
                                          <Button className="ms-2" color="success" onClick={updateAddresses}>Update</Button>
                                        </Col>
                                      </FormGroup>
                                    </Row>
                                    :
                                    <Row className="mt-4">
                                      <FormGroup row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                        <Col lg="5" md="6">
                                          <Button color='secondary' type='button' onClick={() => { setAddressTableState(true); setAddressEditState(false); setAddressReadOnly(false); }}>
                                            Back
                                          </Button>
                                        </Col>
                                      </FormGroup>
                                    </Row>
                                  }
                                </div>
                                :
                                <div>
                                  {/* <h4 style={{ textDecoration: "underline" }}>Add Address</h4> */}

                                  <Row className="mt-2">
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Address Name <span style={{ color: "red" }}>*</span></Label>
                                      <Col lg="5" md="6">
                                        <input onInput={watchAddressFields} id="app_address_name" className={`form form-control ${custErrors.app_address_name && 'is-invalid'}`} placeholder="Enter Address Name" {...register(`app_address.app_address_name`)} />
                                        {custErrors.app_address_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  {/* <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Address <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <textarea rows="5" onInput={watchAddressFields} id="app_address" className={`form form-control ${custErrors.app_address && 'is-invalid'}`} placeholder="Enter Address" {...register(`app_address.app_address`)} />
                                        {custErrors.app_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row> */}
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                                        Billing  Address <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <textarea readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} rows="5" onInput={watchAddressFields} className={`form form-control ${custErrors.app_billing_address && 'is-invalid'}`} placeholder="Enter Billing Address"  {...register(`app_address.app_billing_address`, { required: true })} />
                                        <i className="far fa-copy position-absolute fa-lg" onClick={() => copyAddr_app_address()}
                                          style={{ right: "20px", top: "15%", transform: "translateY(-50%)", cursor: "pointer" }}  ></i>
                                        {errors.app_billing_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                        Delivery  Address <span style={{ color: "red" }}>*</span>
                                      </Label>
                                      <Col lg="5" md="6">
                                        <textarea readOnly={UpadatePermission} style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} rows="5" onInput={watchAddressFields} className={`form form-control ${custErrors.app_delivery_address && 'is-invalid'}`} placeholder="Enter Delivery Address"  {...register(`app_address.app_delivery_address`, { required: true })} />
                                        {errors.app_delivery_address && <p style={{ color: 'red' }}>This Field is required</p>}
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row>
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                      <Col lg="5" md="6">
                                        <select defaultValue="active" className="form-select d-inline" {...register('app_address.is_active')}>
                                          {/* <option hidden value="">Select Status</option> */}
                                          <option value="inactive">INACTIVE</option>
                                          <option value="active">ACTIVE</option>
                                        </select>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                  <Row className="mt-4">
                                    <FormGroup row>
                                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                      <Col lg="5" md="6">
                                        <Button className="m-2" color="secondary" onClick={() => { reset(); setAddressTableState(true); }}>Back</Button>
                                        <Button color="success" onClick={addAddress}>Add</Button>
                                      </Col>
                                    </FormGroup>
                                  </Row>
                                </div>)
                            }
                          </Container>
                        </TabPane>


                        {/* orders */}

                        <TabPane
                          tabId="5"
                          id="v-pills-payment"
                          role="tabpanel"
                          aria-labelledby="v-pills-payment-tab">
                          <Container fluid>
                            {orderTableState ?
                              <>
                                <Row >
                                  <Col xs="8">
                                    <>
                                      {/* <div className="d-inline p-2 bg-primary text-white">d-inline</div> */}
                                      <div style={{ fontSize: "18px" }} className="d-inline p-2">Site Name : </div>
                                      <div style={{ textTransform: "uppercase", fontSize: "15px", }} className="d-inline ">{siteName}</div>
                                    </>
                                  </Col>
                                  <Col xs="4" className="d-flex justify-content-end mb-2">
                                    {/* <Button style={{ marginRight: "4px", }} color="info" type="button" onClick={() => {
                                      setactiveTab("2"); setContactEditState(false); setContactTableState(true); history.push(`/updateCompany/${id}?tab=${2}&&skip=${0}&&limit=${10}&&status=${true}`)
                                    }}>Back</Button> */}
                                  </Col>
                                </Row>
                                <TableContainer
                                  columns={orderColumns}
                                  data={sites}
                                  isGlobalFilter={true}
                                  isAddOrder={true}
                                  customPageSize={10}
                                  handleAddOrder={handleAddOrder}
                                  handlePageClick={orderhandlePageClick}
                                  className="custom-header-css" />
                              </>
                              :
                              <div>
                                <h4 style={{ textDecoration: "underline" }}>Add Orders</h4>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Site ID <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col lg="5" md="6">
                                      <input onInput={watchSiteFields} id="department_keyid" className={`form form-control ${custErrors.department_keyid && 'is-invalid'}`} placeholder="Enter Site ID" {...register(`app_site.department_keyid`)} />
                                      {custErrors.department_keyid && <p style={{ color: 'red' }}>This Field is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                      Site Name <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col lg="5" md="6">
                                      <input onInput={watchSiteFields} id="app_department_name" className={`form form-control ${custErrors.app_department_name && 'is-invalid'}`} placeholder="Enter Site name" {...register(`app_site.app_department_name`)} />
                                      {custErrors.app_department_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                      Site Description
                                    </Label>
                                    <Col lg="5" md="6">
                                      <input className="form form-control" placeholder="Enter Site Description" {...register(`app_site.department_description`)} />
                                    </Col>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label><br />
                                    <Col lg="5" md="6">
                                      <div className="form-check form-switch form-switch-lg mb-3">
                                        <input type="checkbox" className="form-check-input" id="roleStatus" onInput={statusChangeNewOrder} defaultChecked={false} {...register('app_site.is_active')} />
                                        <label className="form-check-label" htmlFor="roleStatus">
                                          {statusNewOrder.name}
                                        </label>
                                      </div>
                                    </Col>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <div className="text-end">
                                    <Button className="m-2" color="secondary" >Back</Button>
                                    <Button color="success" onClick={addOrder}>Add</Button>
                                  </div>
                                </Row>
                              </div>
                            }
                          </Container>
                        </TabPane>


                      </TabContent>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-4">
                  {(activeTab === "2" || activeTab === "1") &&
                    <Col xs="6">
                      <Link
                        to={getBackPath()}
                        className="btn text-muted d-xs-inline-block btn-link"
                      >
                        <i className="mdi mdi-arrow-left me-1 text-danger" /> <span className="text-danger">Back to Listings</span>
                      </Link>
                    </Col>
                  }

                  <Col sm="12">
                    <div className="text-sm-end">
                      {/* {activeTab === "3" && <Button style={{ marginRight: "4px" }} color="info" type="button" onClick={() => { setactiveTab("2") }}>Back</Button>}
                      {activeTab === "2" && <Button style={{ marginRight: "4px" }} color="info" type="button" onClick={() => { setactiveTab("1") }}>Back</Button>}
                      {activeTab === "4" && <Button style={{ marginRight: "4px" }} color="info" type="button" onClick={() => { setactiveTab("3") }}>Back</Button>}
                      {activeTab === "5" && <Button style={{ marginRight: "3px" }} color="info" type="button" onClick={() => { setactiveTab("4") }}>Back</Button>} */}

                      {activeTab === "1" &&
                        <>
                          <Button id="createCompanyBtn" color="info" type="button"
                            onClick={updateCompany}>{buttonState ? "Update" : <>Please Wait <i className='bx bx-loader-alt bx-spin'></i></>}</Button>
                        </>
                      }
                      {activeTab === "3" &&
                        <Button style={{ marginRight: "4px", }} color="info" type="button" onClick={() => {
                          setactiveTab("2"); setContactEditState(false); setSiteTableState(true); history.push(`/updateCompany/${id}?tab=${2}&&skip=${0}&&limit=${10}&&status=${true}`)
                        }}>Back to Site List</Button>
                      }
                      {activeTab === "4" &&
                        <Button style={{ marginRight: "4px", }} color="info" type="button" onClick={() => {
                          setactiveTab("2"); setContactEditState(false); setSiteTableState(true); history.push(`/updateCompany/${id}?tab=${2}&&skip=${0}&&limit=${10}&&status=${true}`)
                        }}>Back to Site List</Button>
                      }
                      {activeTab === "5" &&
                        <Button style={{ marginRight: "4px", }} color="info" type="button" onClick={() => {
                          setactiveTab("2"); setContactEditState(false); setSiteTableState(true); history.push(`/updateCompany/${id}?tab=${2}&&skip=${0}&&limit=${10}&&status=${true}`)
                        }}>Back to Site List</Button>
                      }

                      {/* {activeTab === "2" &&
                        <Button color="info" type="button" onClick={() => { setactiveTab("3") }}>Next</Button>
                      }
                      {activeTab === "3" &&
                        <Button color="info" type="button" onClick={() => { setactiveTab("4") }}>Next</Button>
                      }
                      {activeTab === "4" &&
                        <Button color="info" type="button" onClick={() => { setactiveTab("5") }}>Next</Button>
                      } */}

                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpdateCompany
