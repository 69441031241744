import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap'



const CompanySite = (cell) => {
    return cell.value ? cell.value : '';
};

const OrderID = (cell) => {
    return cell.value ? cell.value : '';
};

const OrderDate = (cell) => {
    return cell.value ? cell.value : '';
};
const OrderAmount = (cell) => {
    return cell.value ? cell.value : '';
};

const ApprovalStatus = (cell) => {
    return cell.value ? cell.value : '';
};




export {
    CompanySite,
    OrderID,
    OrderDate,
    OrderAmount,
    ApprovalStatus,
    
};