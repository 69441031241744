import React from "react";
import moment from "moment";

const Heartbeat = () => {

    const status = "Active";
    const version = "V2.0.164";
    const releaseTime = "06-Jul-2024"
    const queryTime = moment().format('MMMM Do YYYY, h:mm:ss a');

    return (
        <>
            <div className="text-center" style={{ marginTop: "300px" }}>
                <h1>Maxims Web Application</h1>
                <p>Status: {status} &nbsp; Version: {version} &nbsp; Release Time: {releaseTime} &nbsp; Query Time: {queryTime}</p>
            </div>
        </>
    )
}

export default Heartbeat