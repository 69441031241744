import { takeEvery, put, call } from "redux-saga/effects"
import { getActivitiesSuccess, getActivitiesFail } from "./actions"
import { getActivities} from "helpers/backendApi_helpers";
import { GET_ACTIVITIES } from "./actionTypes";



function* fetchActivities({payload: payload}) {
  try {
    const response = yield call(getActivities, payload);
    yield put(getActivitiesSuccess(response))
  } catch (error) {
    yield put(getActivitiesFail(error))
  }
}

function* ActivitiesSaga() {
  yield takeEvery(GET_ACTIVITIES, fetchActivities)
}

 export default ActivitiesSaga






// export {AppBusinessUnitsSaga, PostAppBusinessUnitsSaga} 




