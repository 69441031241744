import axios from "axios";
import expiredSessionHandler from "common/expiredSessionHandler";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";

//pass new generated access token here

//apply base url for axios
const API_URL = "/api/v2";

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

axiosApi.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem('authUser');
    if (token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      };
    }
    return config;
  },
  error => {
    console.log("request error", error);
    if (error.response && error.response.data.ErrorCode === "01030901") {
      sessionStorage.removeItem("authUser");
      sessionStorage.removeItem("userCred");
      sessionStorage.removeItem("userName");
      window.location.href = '/login';
      // toast.info("Session Expired. Please login again !")
    }
    return Promise.reject(error.response ? error.response.data : error)
  }
);

axiosApi.interceptors.response.use(
  response =>  response,
  error => {
    console.log("response error", error);
    if (error.response && error.response.data.ErrorCode === "01030901") {
      sessionStorage.removeItem("authUser");
      sessionStorage.removeItem("userCred");
      sessionStorage.removeItem("userName");
      window.location.href = '/login';
      // toast.info("Session Expired. Please login again !")
    }
    return Promise.reject(error.response ? error.response.data : error)
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response);
}
