import moment from 'moment';
import React from 'react';

import { Badge } from 'reactstrap';

const CustomerRoleID = (cell) => {
    return cell.value ? cell.value : '';
};

const UserRoleName = (cell) => {
    return cell.value ? cell.value : '';
};

const CustomerRoleDesc = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};



const CreatedDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';

};

const LastUpdateDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';

}

export {
    CustomerRoleID,
    UserRoleName,
    Status,
    CreatedDate,
    LastUpdateDate,
    CustomerRoleDesc
};