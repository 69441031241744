/* EVENTS */

// Get Account
export const GET_APP_ACCOUNT = "GET_APP_ACCOUNT";
export const GET_APP_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_APP_ACCOUNT_FAIL = "GET_APP_ACCOUNT_FAIL";


// Post  Account
export const POST_APP_ACCOUNT = "POST_APP_ACCOUNT";
export const POST_APP_ACCOUNT_SUCCESS = "POST_APP_ACCOUNT_SUCCESS";
export const POST_APP_ACCOUNT_FAIL = "POST_APP_ACCOUNT_FAIL"

// Delete  Account
export const DELETE_APP_ACCOUNT = "DELETE_APP_ACCOUNT";
export const DELETE_APP_ACCOUNT_SUCCESS = "DELETE_APP_ACCOUNT_SUCCESS";
export const DELETE_APP_ACCOUNT_FAIL = "DELETE_APP_ACCOUNT_FAIL"

// Get Account by id
export const GET_APP_ACCOUNT_DETAIL = "GET_APP_ACCOUNT_DETAIL";
export const GET_APP_ACCOUNT_DETAIL_SUCCESS = "GET_APP_ACCOUNT_DETAIL_SUCCESS";
export const GET_APP_ACCOUNT_DETAIL_FAIL = "GET_APP_ACCOUNT_DETAIL_FAIL";

// Update  Account
export const UPDATE_APP_ACCOUNT = "UPDATE_APP_ACCOUNT";
export const UPDATE_APP_ACCOUNT_SUCCESS = "UPDATE_APP_ACCOUNT_SUCCESS";
export const UPDATE_APP_ACCOUNT_FAIL = "UPDATE_APP_ACCOUNT_FAIL";