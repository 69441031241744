import axios from 'axios';
import getHeader from 'config/header';
import { API_BASE_URL, BUSINESS_URL, DEPARTMENT_URL } from 'helpers/url_helper';
import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';

const Company = (cell) => {
    // const [business, setBusiness] = useState()
    // useEffect(() => {
    //     if (cell.value) {
    //         const token = sessionStorage.getItem('authUser')
    //         axios.get(API_BASE_URL + BUSINESS_URL + "/" + cell.value, getHeader(token))
    //             .then(res => {
    //                 // console.log(res.data);
    //                 setBusiness(res.data.app_business_name)
    //             }).catch(err => {
    //                 console.log(err);
    //             })
    //     }
    // }, [])
    // return business ? business : '';
    return cell.value ? cell.value : '';
};

const Site = (cell) => {
    // const [site, setSite] = useState()
    // useEffect(() => {
    //     if (cell.value) {
    //         const token = sessionStorage.getItem('authUser')
    //         axios.get(API_BASE_URL + DEPARTMENT_URL + "/" + cell.BusId + '/' + cell.value, getHeader(token))
    //             .then(res => {
    //                 setSite(res.data.app_department_name)
    //             }).catch(err => {
    //                 console.log(err);
    //             })
    //     }
    // }, [])
    // return site ? site : '';
    return cell.value ? cell.value : '';
};

const AddressesName = (cell) => {
    return cell.value ? cell.value : '';
};
const Address = (cell) => {
    return cell.value ? cell.value : '';
};

const Department = (cell) => {
    return cell.value ? cell.value : '';
};

const Email = (cell) => {
    return cell.value ? cell.value : '';
};
const Status = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

export {
    Company,
    Site,
    AddressesName,
    Address,
    Department,
    Email,
    Status
};