import {

    GET_LOGIN_RETRY,
    GET_LOGIN_RETRY_SUCCESS,
    GET_LOGIN_RETRY_FAIL,

    POST_LOGIN_RETRY,
    POST_LOGIN_RETRY_SUCCESS,
    POST_LOGIN_RETRY_FAIL,

    UPDATE_LOGIN_RETRY,
    UPDATE_LOGIN_RETRY_SUCCESS,
    UPDATE_LOGIN_RETRY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    loginRetry: null,
    loading: false,
    isCreated: false,
    isUpdated: false,
    error: {},
};

const LoginRetry = (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_LOGIN_RETRY:
            return {
                ...state,
                loading: true,
                loginRetry: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_LOGIN_RETRY_SUCCESS:
            return {
                ...state,
                loading: false,
                loginRetry: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_LOGIN_RETRY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_LOGIN_RETRY:
            return {
                ...state,
                loading: true,
                loginRetry: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_LOGIN_RETRY_SUCCESS:
            return {
                ...state,
                loading: false,
                loginRetry: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_LOGIN_RETRY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_LOGIN_RETRY:
            return {
                ...state,
                loading: true,
                loginRetry: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_LOGIN_RETRY_SUCCESS:
            return {
                ...state,
                loading: false,
                loginRetry: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_LOGIN_RETRY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default LoginRetry;
