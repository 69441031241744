import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label } from "reactstrap"
import axios from 'axios';
import getHeader from 'config/header';
import Select from 'react-select'
import StatusModal from 'components/Common/StatusModal';


// import { render } from 'react-dom';
// import { COUNTRIES } from './countries';
// import './style.css';
import { WithContext as ReactTags } from 'react-tag-input';
import { getAppBusinessUnits, getBusinessProductDetail, getBusinessUnitDivisions, updateBusinessProduct } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL, APP_BUSINESS_UNIT_LINK_URL } from 'helpers/url_helper';

// const suggestions = COUNTRIES.map((country) => {
//   return {
//     id: country,
//     text: country,
//   };
// });

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];


function UpdateProducts() {
    const { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()


    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const UpadatePermission = (permissionAction.includes("system.products.product.update")) ? false : true
    const DeletePermission = (permissionAction.includes("system.products.product.delete")) ? false : true

    const [tags, setTags] = useState([]);

    const businessProductState = useSelector(state => state.BusinessProducts);
    const [businessUnits, setBusinessUnit] = useState([])
    const businessUnitState = useSelector(state => state.AppBusinessUnits)
    const [busDiv, setBusDiv] = useState([])
    const divisionState = useSelector(state => state.BUDivisionReducer)

    useEffect(() => {
        setOptionGroup('');
        setselectedMulti([]);
        dispatch(getAppBusinessUnits({ filter: '{"is_active":true}', limit: 0, skip: 0 }));

        setBusDivOptionGroup('');
        setselectedBusDivMulti([]);
        dispatch(getBusinessUnitDivisions({ filter: '{"is_active":true}', limit: 0, skip: 0 }));
    }, [])


  

    useEffect(() => {
        if (businessUnitState.appBusinessUnits !== null) {
            setBusinessUnit(businessUnitState.appBusinessUnits.result);
        }
    }, [businessUnitState]);

    useEffect(() => {
        if (divisionState.BUDivisions !== null) {
            setBusDiv(divisionState.BUDivisions.result);
        }
    }, [divisionState]);

    useEffect(() => {
        if (id) {
            const data = { businessProductId: id };
            dispatch(getBusinessProductDetail(data));
        }
    }, [id]);

    useEffect(() => {
        if (businessProductState.product !== null && businessProductState.product !== undefined) {
            let productData = businessProductState.product
            // console.log("productData", productData);
            setValue("app_product_name", productData.app_product_name)
            if (productData.app_product_local_name === "") {
                setValue("app_product_local_name", productData.app_product_name)
            } else {
                setValue("app_product_local_name", productData.app_product_local_name)
            }
            setValue("app_product_payment_code", productData?.app_product_payment_code)
            setValue("app_product_tags", productData?.app_product_tags)
            setValue("app_product_keyid", productData?.app_product_keyid)
            setValue("app_product_pos_item_code", productData?.app_product_pos_item_code)
            // if (productData.product_type === "D") {
            //     setValue("product_type", "Digital")
            // } else {
            //     setValue("product_type", "Print")
            // }
            setValue("product_type", productData?.mxm_source?.coupon_type)
            setValue("pack_unit", productData.pack_unit)
            setValue("selling_price", parseFloat(productData?.selling_price).toFixed(1))
            setValue("unit_price", parseFloat(productData?.unit_price).toFixed(1))
            setValue("app_product_unit_price", productData?.app_product_unit_price)
            // setValue("is_active", productData.is_active)
            if (productData.is_active === true) {
                setValue("is_active", "active")
            } else if (productData.is_active === false) {
                setValue("is_active", "inactive")
            }
            // if ((productData.app_product_tags ? productData.app_product_tags : []).length > 0) {
            //     console.log("tagsssss", productData);
            //     setTags(productData?.app_product_tags ? productData.app_product_tags : [])
            // }
            if (productData?.app_product_tags?.length >= 0) {
                setTags(productData?.app_product_tags ? productData.app_product_tags : [])
            }
            appBusinessUnit(productData?.app_business_unit)
            appBusinessUnitDiv(productData?.app_business_unit_division)


        }

        if (businessProductState.isUpdated) {
            // toast.info("Updated Successfully")
            setTags([])
            history.goBack()
        }

    }, [businessProductState, businessUnits, busDiv])


    const [statusModal, setStatusModal] = useState(false)
    const [statusTBC, setStatusTBC] = useState()

    const [confirmStatus, setConfirmStatus] = useState()
    const statusChange = (e) => {
        setConfirmStatus(e.target.value)
        if (e.target.value === "active") {
            setStatusTBC(true)
            setStatusModal(true)
        } else if (e.target.value === "inactive") {
            setStatusTBC(false);
            setStatusModal(true)
        }
    }

    const handleStatus = () => {
        setValue("is_active", confirmStatus)
        setStatusModal(false)
    }

    const closeClick = () => {
        const value = getValues("is_active");
        if (value === "inactive") {
            let dta = "active"
            setValue("is_active", dta)
            setStatusModal(false)
            // console.log("singleValue", dta);
        } else if (value === "active") {
            let dta = "inactive"
            setValue("is_active", dta)
            setStatusModal(false)
            // console.log("singleValue", dta);
        }

    }




    // Manage Business Unit's MultiSelect
    const [selectedMulti, setselectedMulti] = useState([]);
    const [optionGroup, setOptionGroup] = useState([{ options: [] }])
    register('app_business_unit');


    useEffect(() => {
        if (businessUnits) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in businessUnits) {
                let data = {
                    label: businessUnits[i].app_business_unit_name,
                    value: businessUnits[i].app_businessunit_id
                }
                options.push(data)
            }
            setOptionGroup(options)
        }
    }, [businessUnits])

    const appBusinessUnit = (Items) => {
        if (Items?.length > 0) {
            let Data = Items.map((item) => {
                let businessunits = businessUnits.find(businessunits => businessunits.app_businessunit_id === item)
                if (businessunits) {
                    return {
                        label: businessunits.app_business_unit_name,
                        value: businessunits.app_businessunit_id
                    }
                }
            }).filter(Boolean)
            setselectedMulti(Data)
            setValue("app_business_unit", Data)
        }
    }

    const handlePostChange = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values
        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroup.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_business_unit', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_business_unit', postArray)
                }
            }
        });
        setselectedMulti(postArray);
        setValue('app_business_unit', postArray)
    };


    // Manage Business unit division MultiSelect
    const [selectedBusDivMulti, setselectedBusDivMulti] = useState([]);
    const [busDivoptionGroup, setBusDivOptionGroup] = useState([{ options: [] }])
    register('app_business_unit_division');


    useEffect(() => {
        if (busDiv) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in busDiv) {
                let data = {
                    label: busDiv[i].app_business_unit_division_name,
                    value: busDiv[i].app_division_id
                }
                options.push(data)
            }
            setBusDivOptionGroup(options)
        }
    }, [busDiv])

    const appBusinessUnitDiv = (Items) => {
        if (Items?.length > 0) {
            let Data = Items.map((item) => {
                let businessunitsDiv = busDiv.find(busDiv => busDiv.app_division_id === item)
                if (businessunitsDiv) {
                    return {
                        label: businessunitsDiv.app_business_unit_division_name,
                        value: businessunitsDiv.app_division_id
                    }
                }
            }).filter(Boolean)
            setselectedBusDivMulti(Data)
            setValue("app_business_unit_division", Data)
        }
    }

    const handlePostChangeBusDiv = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = busDivoptionGroup.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_business_unit_division', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_business_unit_division', postArray)
                }
            }
        });
        setselectedBusDivMulti(postArray);
        setValue('app_business_unit_division', postArray)
    };


    // tags
    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setTags([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
    };

    const handleTagClick = (index) => {
        // console.log('The tag at index ' + index + ' was clicked');
    };

    const customStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: "#D2D4D1", // Change this to your desired background color
        }),
    };

    const updateProduct = async (data) => {
        // console.log("data", data);

        let status
        if (data.is_active === "active") {
            status = true
        } else if (data.is_active === "inactive") {
            status = false
        }

        let items = data.app_business_unit
        let Dta = []
        for (let i in items) {
            Dta.push(items[i].value)
        }

        let value = data.app_business_unit_division
        let Data = []
        for (let i in value) {
            Data.push(value[i].value)
        }

        let Tag = tags
        let Tags = []
        for (let i in Tag) {
            Tags.push(Tag[i].text)
        }

        let product_type
        if (data.product_type === "Digital") {
            product_type = "Digital"
        } else {
            product_type = "Physical"
        }

        const busunitProductData = {
            ...data,
            is_active: status,
            app_business_unit: Dta,
            app_business_unit_division: Data,
            app_product_tags: tags,
            app_product_tags_names: Tags,
            product_type: product_type
        }

        // console.log(busunitProductData);

        const updateData = {
            productId: id,
            updateData: busunitProductData
        }

        // console.log("updateData", updateData);

        $('#updateProduct').attr("disabled", true)
        dispatch(updateBusinessProduct(updateData))

    }


    return (
        <React.Fragment>
            <StatusModal
                show={statusModal}
                onDeleteClick={handleStatus}
                onCloseClick={closeClick}
                data={statusTBC}
            />
            <div className="page-content">
                <Breadcrumbs title="Update Product" breadcrumbItem="Product List" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(updateProduct)}>
                                    <h5><b>CSMS</b></h5>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Product ID <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('app_product_keyid', { required: true })} />
                                                {errors.app_product_keyid && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Product Name <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('app_product_name', { required: true })} />
                                                {errors.app_product_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">POS Item Code <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('app_product_pos_item_code', { required: true })} />
                                                {errors.app_product_pos_item_code && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Payment Code <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control'{...register('app_product_payment_code', { required: true })} />
                                                {errors.app_product_payment_code && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Product Type  <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('product_type', { required: true })} />
                                                {errors.product_type && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Pack Unit <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('pack_unit', { required: true })} />
                                                {errors.pack_unit && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Selling Price <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('selling_price', { required: true })} />
                                                {errors.selling_price && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Unit Price <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('unit_price', { required: true })} />
                                                {errors.unit_price && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="5" md="6">
                                                <select style={{ backgroundColor: "#D2D4D1" }} disabled onInput={(e) => { statusChange(e) }} className="form-select d-inline" {...register('is_active')}>
                                                    <option hidden value="">Select Status</option>
                                                    <option value="active">ACTIVE</option>
                                                    <option value="inactive">INACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <hr />
                                    <h4><b>Local</b></h4>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Local Product Name</Label>
                                            <Col lg="5" md="6">
                                                <input type="text" style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} className='form form-control' {...register('app_product_local_name', { required: true })} />
                                                {errors.app_product_local_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Product Tags </Label>
                                            <Col lg="5" md="6">
                                                {UpadatePermission ? <ReactTags
                                                    inputProps={{
                                                        disabled: true
                                                    }}
                                                    tags={tags ? tags : []}
                                                    // suggestions={suggestions}
                                                    delimiters={delimiters}
                                                    // handleDelete={handleDelete}
                                                    handleAddition={handleAddition}
                                                    // handleDrag={handleDrag}
                                                    // handleTagClick={handleTagClick}
                                                    inputFieldPosition="bottom"
                                                    autocomplete
                                                // editable
                                                /> :
                                                    <ReactTags
                                                        tags={tags ? tags : []}
                                                        // suggestions={suggestions}
                                                        delimiters={delimiters}
                                                        handleDelete={handleDelete}
                                                        handleAddition={handleAddition}
                                                        handleDrag={handleDrag}
                                                        handleTagClick={handleTagClick}
                                                        inputFieldPosition="bottom"
                                                        autocomplete
                                                        // editable
                                                        style={{ width: "200%" }}
                                                    />}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Business Unit Division</Label>
                                            <Col lg="5" md="6">
                                                {UpadatePermission ?
                                                    <Select
                                                        isDisabled
                                                        styles={customStyles}
                                                        value={selectedBusDivMulti.length > 0 ? selectedBusDivMulti : []}
                                                        isMulti={true}
                                                        onChange={handlePostChangeBusDiv}
                                                        options={busDivoptionGroup}
                                                        name={"app_division_id"}
                                                        className="modal__input"
                                                    /> :
                                                    <Select
                                                        value={selectedBusDivMulti.length > 0 ? selectedBusDivMulti : []}
                                                        isMulti={true}
                                                        onChange={handlePostChangeBusDiv}
                                                        options={busDivoptionGroup}
                                                        name={"app_division_id"}
                                                        className="modal__input"
                                                    />}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end "> Business Units </Label>
                                            <Col lg="5" md="6">
                                                {UpadatePermission ?

                                                    <Select
                                                        isDisabled
                                                        styles={customStyles}
                                                        value={selectedMulti.length > 0 ? selectedMulti : []}
                                                        isMulti={true}
                                                        onChange={handlePostChange}
                                                        options={optionGroup}
                                                        name={"app_businessunit_id"}
                                                        className="modal__input"
                                                    /> :
                                                    <Select
                                                        value={selectedMulti.length > 0 ? selectedMulti : []}
                                                        isMulti={true}
                                                        onChange={handlePostChange}
                                                        options={optionGroup}
                                                        name={"app_businessunit_id"}
                                                        className="modal__input"
                                                    />}
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => { setTags([]); history.goBack() }}>
                                                    Back
                                                </Button>
                                                {!UpadatePermission && <Button id='updateProduct' color='success' type='submit' className='m-2'>
                                                    Update
                                                </Button>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UpdateProducts