import React, { useState } from "react"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CreateCompanySiteApprovalList from "./modules/CreateApprovalCompanySite";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const CompanySiteApprovalList = () => {

    document.title = "Company Site Approvals | Maxims B2B Portal";

    const query = useQuery();
    const history = useHistory()
    const tab = query.get("tab")
    const [activeTab, setactiveTab] = useState(1)

    const permissions = JSON.parse([sessionStorage.getItem("userCred")])

    return (
        <React.Fragment>
            {/* <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Company Site Approvals" breadcrumbItem="Approvals" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <CreateCompanySiteApprovalList />
                                    <div className="vertical-wizard wizard clearfix vertical">
                                        <div className="steps clearfix">
                                            <ul>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTab === 1,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === 1,
                                                        })}
                                                        onClick={() => {
                                                            setactiveTab(1)
                                                            history.push("companySites?tab=1&&skip=0&&limit=10&&status=true")
                                                        }}
                                                    >
                                                        <span className="number">1.</span>Create Company Site
                                                    </NavLink>
                                                </NavItem>

                                            </ul>
                                        </div>
                                        <div className="content clearfix">
                                            <TabContent
                                                activeTab={activeTab}
                                                className="body"
                                            >
                                                <TabPane tabId={1}>
                                                    <CreateCompanySiteApprovalList />
                                                    
                                                </TabPane>

                                                <TabPane tabId={2}>

                                                     <CompanySite />
                                                    
                                                </TabPane>

                                                <TabPane tabId={3}>
                                                    <div>
                                                        <Form>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-namecard-input112">
                                                                            Name on Card
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-namecard-input112"
                                                                            placeholder="Enter Your Name on Card"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label>Credit Card Type</Label>
                                                                        <select className="form-select">
                                                                            <option>Select Card Type</option>
                                                                            <option>American Express</option>
                                                                            <option>Visa</option>
                                                                            <option>MasterCard</option>
                                                                            <option>Discover</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-cardno-input122">
                                                                            Credit Card Number
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-cardno-input122"
                                                                            placeholder="Enter Your Card Number"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-card-verification-input">
                                                                            Card Verification Number
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-card-verification-input"
                                                                            placeholder="Card Verification Number"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-expiration-input132">
                                                                            Expiration Date
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-expiration-input132"
                                                                            placeholder="Card Expiration Date"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <div className="row justify-content-center">
                                                        <Col lg="6">
                                                            <div className="text-center">
                                                                <div className="mb-4">
                                                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                </div>
                                                                <div>
                                                                    <h5>Confirm Detail</h5>
                                                                    <p className="text-muted">
                                                                        If several languages coalesce, the grammar
                                                                        of the resulting
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                       
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div> */}


            <div className="page-content">
                {permissions.includes("system.settings.approval.read") ? <Container fluid>
                    <Breadcrumbs title="Site Approvals" breadcrumbItem="Approvals" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <CreateCompanySiteApprovalList />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
}

export default CompanySiteApprovalList;



