import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,


    GET_SYS_USERS_PROFILE,
    GET_SYS_USERS_PROFILE_SUCCESS,
    GET_SYS_USERS_PROFILE_FAIL,

    GET_NOTIFICATION_APPROVAL_STATUS,
    GET_NOTIFICATION_APPROVAL_STATUS_SUCCESS,
    GET_NOTIFICATION_APPROVAL_STATUS_FAIL,

    GET_NOTIFICATION_PENDING_STATUS,
    GET_NOTIFICATION_PENDING_STATUS_SUCCESS,
    GET_NOTIFICATION_PENDING_STATUS_FAIL


} from "./actionTypes";

const INIT_STATE = {
    chartsData: [],
    sysUserProfile: null,
    notificationApprovalStatus: null,
    notificationPendingStatus: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SYS_USERS_PROFILE:
            return {
                ...state,
                loading: true,
                sysUserProfile: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_USERS_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                sysUserProfile: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_USERS_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };

        case GET_NOTIFICATION_APPROVAL_STATUS:
            return {
                ...state,
                loading: true,
                notificationApprovalStatus: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_NOTIFICATION_APPROVAL_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationApprovalStatus: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_NOTIFICATION_APPROVAL_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_NOTIFICATION_PENDING_STATUS:
            return {
                ...state,
                loading: true,
                notificationPendingStatus: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_NOTIFICATION_PENDING_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationPendingStatus: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_NOTIFICATION_PENDING_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsData: action.payload.data
                    };
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsDataError: action.payload.error
                    };
                default:
                    return state;
            }
        default:
            return state;
    }
}


export default Dashboard;