import {
    GET_BUSINESS_UNIT_DIVISIONS,
    GET_BUSINESS_UNIT_DIVISIONS_SUCCESS,
    GET_BUSINESS_UNIT_DIVISIONS_FAIL,

    GET_BUSINESS_UNIT_DIVISION,
    GET_BUSINESS_UNIT_DIVISION_SUCCESS,
    GET_BUSINESS_UNIT_DIVISION_FAIL,

    POST_BUSINESS_UNIT_DIVISION,
    POST_BUSINESS_UNIT_DIVISION_SUCCESS,
    POST_BUSINESS_UNIT_DIVISION_FAIL,

    DELETE_BUSINESS_UNIT_DIVISION,
    DELETE_BUSINESS_UNIT_DIVISION_SUCCESS,
    DELETE_BUSINESS_UNIT_DIVISION_FAIL,

    UPDATE_BUSINESS_UNIT_DIVISION,
    UPDATE_BUSINESS_UNIT_DIVISION_SUCCESS,
    UPDATE_BUSINESS_UNIT_DIVISION_FAIL,
} from "./actionTypes";

/////////////////////////////////
//  Get  BusinessUnitDivision  //
/////////////////////////////////


export const getBusinessUnitDivisions = (payload) => ({
    type: GET_BUSINESS_UNIT_DIVISIONS,
    payload: payload
})

export const getBusinessUnitDivisionsSuccess = BUDivision => ({
    type: GET_BUSINESS_UNIT_DIVISIONS_SUCCESS,
    payload: BUDivision
})

export const getBusinessUnitDivisionsFail = error => ({
    type: GET_BUSINESS_UNIT_DIVISIONS_FAIL,
    payload: error
})

//////////////////////////////
// Get By id BusinessUnitDivision //
//////////////////////////////

export const getBusinessUnitDivision = (payload) => ({
    type: GET_BUSINESS_UNIT_DIVISION,
    payload: payload
});

export const getBusinessUnitDivisionSuccess = BUDivision => ({
    type: GET_BUSINESS_UNIT_DIVISION_SUCCESS,
    payload: BUDivision,
});

export const getBusinessUnitDivisionFail = error => ({
    type: GET_BUSINESS_UNIT_DIVISION_FAIL,
    payload: error,
});

////////////////////////
// Post BusinessUnitDivision     ///
////////////////////////

export const postBusinessUnitDivision = (payload) => ({
    type: POST_BUSINESS_UNIT_DIVISION,
    payload: payload
});

export const postBusinessUnitDivisionSuccess = BUDivision => ({
    type: POST_BUSINESS_UNIT_DIVISION_SUCCESS,
    payload: BUDivision,
});

export const postBusinessUnitDivisionFail = error => ({
    type: POST_BUSINESS_UNIT_DIVISION_FAIL,
    payload: error,
});

///////////////////////////
// Delete  BusinessUnitDivision //
//////////////////////////

export const deleteBusinessUnitDivision= (payload) => ({
    type: DELETE_BUSINESS_UNIT_DIVISION,
    payload: payload
});

export const deleteBusinessUnitDivisionSuccess = () => ({
    type: DELETE_BUSINESS_UNIT_DIVISION_SUCCESS,
});

export const deleteBusinessUnitDivisionFail = error => ({
    type: DELETE_BUSINESS_UNIT_DIVISION_FAIL,
    payload: error,
});

///////////////////////////
// Update  BusinessUnitDivision  //
///////////////////////////

export const updateBusinessUnitDivision = (payload) => ({
    type: UPDATE_BUSINESS_UNIT_DIVISION,
    payload: payload
});

export const updateBusinessUnitDivisionSuccess = payload => ({
    type: UPDATE_BUSINESS_UNIT_DIVISION_SUCCESS,
    payload: payload
});

export const updateBusinessUnitDivisionFail = error => ({
    type: UPDATE_BUSINESS_UNIT_DIVISION_FAIL,
    payload: error,
});


