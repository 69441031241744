// import React from "react"
// import { Card, Container } from "reactstrap"

// function ComingSoon() {
//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Card>
//             <div style={{height: "500px"}} className="d-flex justify-content-center align-items-center">
//               <h1>Coming Soon ...</h1>
//             </div>
//           </Card>
//         </Container>
//       </div>
//     </React.Fragment>
//   )
// }
// export default ComingSoon







import React, { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import CryptoJS from 'crypto-js';
import { Card, Container } from 'reactstrap';

const App = () => {


  const [couponList, setCouponList] = useState([
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CMDFAG356HGF"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CLKJHGRS5SJ"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CERT3453292CN"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CSYER3478HJC"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CLSHD6BE98D"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CKJDSHRE6EJW7"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CSDKJDS7632J"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "C37443H87WLK"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "C9H4D9D6TDH"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CJD63NJE7R9F"
    },
    {
      "app_product_keyid": "PU5BC3536B",
      "app_product_name": "CorpDrink",
      "app_product_pos_item_code": "0800032446",
      "product_type": "Print",
      "coupon_no": "CMNR58RE3SE"
    },

  ])

  const exportCSV = () => {
    const csvData = getCsvData();
    const zip = new JSZip();
    zip.file('coupons.csv', csvData, { password: 'yourpassword' });
    zip.generateAsync({ type: 'blob', compression: 'DEFLATE', })
      .then(function (content) {
        saveAs(content, 'coupons.zip');
      });
  };

  const getCsvData = () => {
    const headers = Object.keys(couponList[0]).join(',');
    const rows = couponList.map(coupon => Object.values(coupon).join(','));
    return headers + '\n' + rows.join('\n');
  };





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <div>
              <button type='button' onClick={exportCSV}>Generate Password Protected ZIP</button>
            </div>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
};

export default App;
