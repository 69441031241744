import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';
import { UserRoleName, Status, CustomerRoleID, CustomerRoleDesc } from './CustomerRoleListCol';
import axios from "axios";
import getHeader from "config/header";
import { toast } from "react-toastify";
import expiredSessionHandler from "common/expiredSessionHandler";
import { useDispatch, useSelector } from "react-redux";
import { deleteAppRoles, getAppRoles } from "store/administration/customerRole/action";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const CustomerRoleManagement = props => {

  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const pageStatus = query.get("status")


  const history = useHistory()
  const dispatch = useDispatch();
  document.title = "Customer Roles Management | Maxims B2B Portal";

  const [permissions, setPermissions] = useState('')
  // const [pageStatus, setPageStatus] = useState(true)
  const [roles, setRoles] = useState([])
  const [pageCount, setPageCount] = useState(1);
  // const [page, setPage] = useState(0);
  // const [pageLimit, setPageLimit] = useState(10);


  const appRolesState = useSelector(state => state.AppRoles)

  useEffect(() => {
    setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
  }, [])


  //meta title

  useEffect(() => {
    if (appRolesState.appRoles !== null) {
      setRoles(appRolesState.appRoles.result);
      setPageCount(Math.ceil(appRolesState.appRoles.summary.filteredsize / pageLimit) == 0 ?  1
      :
      Math.ceil(appRolesState.appRoles.summary.filteredsize / pageLimit));
    }
    if (appRolesState.isDeleted) {
      setDeleteModal(false);
      handlePageClick(page);
    }
    if (appRolesState.error) {
      setDeleteModal(false);
    }
  }, [appRolesState])


  const [customFilterData, setCustomFilterData] = useState("")

  useEffect(() => {
    handlePageClick()
  }, [pageStatus, pageLimit, page,customFilterData])

  
  useEffect(()=>{
    if(customFilterData.length > 0){
      history.push(`/customerRoleManagement?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
    }
  },[customFilterData])

  const handlePageClick = (index) => {
    let filter = `{"is_active":${pageStatus},"$or":[
      {"app_role_keyid":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_role_name":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`
    let skips = 0;
    // setPage(index);
    if (page > 0) {
      skips = page * pageLimit;
      dispatch(getAppRoles({ filter: filter, limit: pageLimit, skip: skips }));

      // dispatch(getAppRoles({ filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: skips }));
    }
    else {
      dispatch(getAppRoles({ filter: filter, limit: pageLimit, skip: 0 }));

      // dispatch(getAppRoles({ filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }
  }


  // Company Column
  const columns = useMemo(
    () => [
      {
        Header: 'Role ID',
        accessor: 'app_role_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <CustomerRoleID {...cellProps} />;
        }
      },
      {
        Header: 'Role Name',
        accessor: 'app_role_name',
        filterable: true,
        Cell: (cellProps) => {
          return <UserRoleName {...cellProps} />;
        }
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
          return (

          //   <div className="d-flex gap-3">
          //   {(permissionAction.includes("system.administration.customerroles.read")) ?
          //     <>
          //       <button
          //         onClick={() => {
          //           history.push(`/updateCustomerRole/${cellProps.row.original.app_role_id}`)
          //         }} className="btn btn-success btn-sm">View</button>
          //     </> : <div className="text-muted">No Access</div>
          //   }
          // </div>
          <div className="d-flex gap-3">
          {(permissionAction.includes("system.administration.customerroles.read")) ?
              <>
                  <button
                      onClick={() => {
                          history.push(`/viewCustomerRole/${cellProps.row.original.app_role_id}`)
                      }} className="btn btn-success btn-sm">View</button>
              </> : <div className="text-muted">No Access</div>
          }
          {(permissionAction.includes("system.administration.customerroles.read")) && (permissionAction.includes("system.administration.customerroles.update")) ||
              (permissionAction.includes("system.administration.customerroles.read")) && (permissionAction.includes("system.administration.customerroles.delete"))
              ?
              <>
                  <button
                      onClick={() => {
                          history.push(`/updateCustomerRole/${cellProps.row.original.app_role_id}`)
                      }} className="btn btn-info btn-sm">Edit</button>
              </> : <div className="text-muted">No Access</div>
          }

      </div>
          );
        }
      },
    ],
    [roles]
  );


  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [roleTBD, setRoleTBD] = useState('')

  const onClickDelete = (appRole) => {
    setRoleTBD(appRole)
    setDeleteModal(true)
  }

  const handleDeleteCustomer = () => {
    if (roleTBD.app_role_id) {
      const data = {
        businessUnitId: roleTBD.app_role_id
      };

       dispatch(deleteAppRoles(data));
    }
  }



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {permissions.includes("system.administration.customerroles.read") ? <Container fluid>
          <Breadcrumbs title="Roles Management" breadcrumbItem="Customer" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={roles}
                    isGlobalFilter={true}
                    className="custom-header-css"
                    customPageCount={pageCount}
                    isCustomerRoleManagementList={permissions.includes("system.administration.customerroles.write") ? true : false}
                    customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                    // handleCompanyClick={handleCompanyClick}
                    // customPageSize={10}
                    // customPageStatus={(data) => setPageStatus(data)}
                    // handlePageClick={handlePageClick}
                    // customPageIndex={page}
                    // pageSizeTransfer={(data) => setPageLimit(data)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
          :
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                      <div>
                        <h1><i className='bx bxs-lock'></i></h1>
                        <h3>Access Denied</h3>
                        <div className="text-muted">You don't have permission to access this module</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
    </React.Fragment>
  );
};

export default CustomerRoleManagement;


