import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';
import { SiteID, SiteName, SiteDescription, SiteStatus, BusinessUnits, Business } from './CustomerSitesListCol';
import { getAllDepartment, getDepartments } from "store/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const CustomerSites = props => {

    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const Busid = query.get("Busid")
    const SiteId = query.get("SiteId")
    const pageStatus = query.get("status")

    const history = useHistory()
    const [permissions, setPermissions] = useState('')
    const [sites, setSites] = useState([])
    const dispatch = useDispatch()

    const [pageCount, setPageCount] = useState(1);
    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])
    // const [page, setPage] = useState(0);
    // const [pageLimit, setPageLimit] = useState(10);

    const departmentState = useSelector(state => state.departmentReducer)


    // Company Column
    const columns = useMemo(
        () => [
            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Business {...cellProps} />
                }
            },
            {
                Header: 'Site Name',
                accessor: 'app_department_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteName {...cellProps} />
                }
            },
            {
                Header: 'Site ID',
                accessor: 'app_department_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteID {...cellProps} />
                }
            },
            {
                Header: 'Business Unit',
                accessor: 'app_business_unit_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <BusinessUnits {...cellProps} />
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteStatus {...cellProps} />
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (

                        <div className="d-flex gap-3">
                            {(permissionAction.includes("system.sales.customersites.read")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/viewCustomerSites/${cellProps.row.original.app_department_id}?BusId=${cellProps.row.original.app_business_id}`)
                                        }} className="btn btn-success btn-sm">View</button>
                                </> : ""
                            }
                            {(permissionAction.includes("system.sales.customersites.read")) && (permissionAction.includes("system.sales.customersites.updates")) ||
                                (permissionAction.includes("system.sales.customersites.read")) && (permissionAction.includes("system.sales.customersites.delete")) ?
                                <>
                                    <button
                                        onClick={() => {
                                            history.push(`/updateCustomerSites/${cellProps.row.original.app_department_id}?BusId=${cellProps.row.original.app_business_id}`)
                                        }} className="btn btn-info btn-sm">Edit</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                        </div>


                    );
                }
            },
        ], [sites]
    );

    useEffect(() => {
        if (departmentState.departments !== null) {
            setSites(departmentState.departments.result)
            setPageCount(Math.ceil(departmentState.departments.summary.filteredsize / pageLimit) == 0 ? 1
                : Math.ceil(departmentState.departments.summary.filteredsize / pageLimit)
            );
        }
        if (departmentState.error) {
            // console.log("err", creditsState.error);
        }
    }, [departmentState])


    const [customFilterData, setCustomFilterData] = useState("")
    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, Busid, SiteId, pageStatus, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/customerSites?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}&&Busid=${Busid}`)
        }
    }, [customFilterData])

    const handlePageClick = (index) => {
        let filter = `{"is_active":${pageStatus},"$or":[
           {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
           {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
           {"app_department_keyid":{"$regex":"${customFilterData}","$options":"i"}},
           {"app_business_unit_name":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`
        const companyID = Busid
        if (Busid !== "null") {
            let skips = 0
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getDepartments({ companyID, filter: filter, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getDepartments({ companyID, filter: filter, limit: pageLimit, skip: 0 }));
            }
        } else {
            let skips = 0
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getAllDepartment({ filter: filter, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getAllDepartment({ filter: filter, limit: pageLimit, skip: 0 }));
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {permissions.includes("system.sales.customersites.read") ? <Container fluid>
                    <Breadcrumbs title="Customer Sites " breadcrumbItem="Sales" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={sites}
                                        isGlobalFilter={true}
                                        className="custom-header-css"
                                        customPageCount={pageCount}
                                        isCompanyDropdown={true}
                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                        isAddCustomerSites={permissions.includes("system.sales.customersites.write") ? true : false}
                                    //  isDepartmentList={permissions.includes("system.customers.credit.write") ? true : false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    );
};

export default CustomerSites;