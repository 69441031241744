import {
  GET_FINALIZEPROPOSAL,
  GET_FINALIZEPROPOSAL_SUCCESS,
  GET_FINALIZEPROPOSAL_FAIL,

  GET_FINALIZEPROPOSAL_DETAIL_SUCCESS,
  GET_FINALIZEPROPOSAL_DETAIL_FAIL,
  GET_FINALIZEPROPOSAL_DETAIL,

  POST_FINALIZEPROPOSAL,
  POST_FINALIZEPROPOSAL_SUCCESS,
  POST_FINALIZEPROPOSAL_FAIL,

  CONFIRM_FINALIZEPROPOSAL,
  CONFIRM_FINALIZEPROPOSAL_SUCCESS,
  CONFIRM_FINALIZEPROPOSAL_FAIL,

} from "./actionTypes";

const INIT_STATE = {
  finalizeproposals: null,
  finalizeproposal: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const AppFinalizeProposalReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FINALIZEPROPOSAL:
      return {
        ...state,
        loading: true,
        finalizeproposals: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_FINALIZEPROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        finalizeproposals: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_FINALIZEPROPOSAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_FINALIZEPROPOSAL_DETAIL:
      return {
        ...state,
        loading: true,
        finalizeproposal: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_FINALIZEPROPOSAL_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        finalizeproposal: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_FINALIZEPROPOSAL_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_FINALIZEPROPOSAL:
      return {
        ...state,
        loading: true,
        finalizeproposal: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_FINALIZEPROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        finalizeproposal: action.payload,
        isCreated: true,
        isDeleted: false,
        isUpdated: false
      };
    case POST_FINALIZEPROPOSAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case CONFIRM_FINALIZEPROPOSAL:
      return {
        ...state,
        loading: true,
        finalizeproposals: null,
        finalizeproposal: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case CONFIRM_FINALIZEPROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        finalizeproposal: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: true
      };
    case CONFIRM_FINALIZEPROPOSAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default AppFinalizeProposalReducer;


