import {
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAIL,

  POST_INVOICE,
  POST_INVOICE_SUCCESS,
  POST_INVOICE_FAIL,

  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAIL,

  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICE_DETAIL,
  
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAIL,
  UPDATE_INVOICE,

  GET_INVOICE_CONFIRM,
  GET_INVOICE_CONFIRM_SUCCESS,
  GET_INVOICE_CONFIRM_FAIL,

} from "./actionTypes";

/////////////////////////
//  Get INVOICE  //
/////////////////////////

export const getInvoices = (payload) => ({
  type: GET_INVOICE,
  payload: payload
});

export const getInvoicesSuccess = Invoices => ({
  type: GET_INVOICE_SUCCESS,
  payload: Invoices,
});

export const getInvoicesFail = error => ({
  type: GET_INVOICE_FAIL,
  payload: error,
});

//////////////////////////////
// Get By id INVOICE  //
//////////////////////////////

export const getInvoice = (payload) => ({
  type: GET_INVOICE_DETAIL,
  payload: payload
});

export const getInvoiceDetailSuccess = Invoice => ({
  type: GET_INVOICE_DETAIL_SUCCESS,
  payload: Invoice,
});

export const getInvoiceDetailFail = error => ({
  type: GET_INVOICE_DETAIL_FAIL,
  payload: error,
});

////////////////////////
// Post INVOICE///
////////////////////////

export const postInvoice = (payload) => ({
  type: POST_INVOICE,
  payload: payload
});

export const postInvoiceSuccess = Invoice => ({
  type: POST_INVOICE_SUCCESS,
  payload: Invoice,
});

export const postInvoiceFail = error => ({
  type: POST_INVOICE_FAIL,
  payload: error,
});

///////////////////////////
// Delete INVOICE //
//////////////////////////

export const deleteInvoice = (payload) => ({
  type: DELETE_INVOICE,
  payload: payload
});

export const deleteInvoiceSuccess = () => ({
  type: DELETE_INVOICE_SUCCESS,
});

export const deleteInvoiceFail = error => ({
  type: DELETE_INVOICE_FAIL,
  payload: error,
});

///////////////////////////
// Update INVOICE  //
///////////////////////////

export const updateInvoice = (payload) => ({
  type: UPDATE_INVOICE,
  payload: payload
});

export const updateInvoiceSuccess = payload => ({
  type: UPDATE_INVOICE_SUCCESS,
  payload: payload
});

export const updateInvoiceFail = error => ({
  type: UPDATE_INVOICE_FAIL,
  payload: error,
});



export const getInvoiceConfirm = (payload) => ({
  type: GET_INVOICE_CONFIRM,
  payload: payload
});

export const getInvoiceConfirmSuccess = Invoices => ({
  type: GET_INVOICE_CONFIRM_SUCCESS,
  payload: Invoices,
});

export const getInvoiceConfirmFail = error => ({
  type: GET_INVOICE_CONFIRM_FAIL,
  payload: error,
});