import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"

import axios from 'axios';
import getHeader from 'config/header';
import { useDispatch, useSelector } from 'react-redux'
import { getBusinesses, getDepartments } from 'store/actions'
import { getAppRoles } from 'store/administration/customerRole/action'
import * as url from "./../../../helpers/url_helper";

function CreateCustomerUser() {

    const history = useHistory()
    const dispatch = useDispatch()

    const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()

    // Get Company
    const [businessList, setBusinessList] = useState([])
    const [siteList, setSiteList] = useState([])
    const [optionGroup, setOptionGroup] = useState([{ options: [] }])

    const businessesState = useSelector(state => state.businessReducer)
    const departmentState = useSelector(state => state.departmentReducer)
    const appRolesState = useSelector(state => state.AppRoles.appRoles)

    useEffect(() => {
        dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: "", skip: '' }))

        dispatch(getAppRoles({ filter: `{"is_active":${true}}`, limit: 0, skip: 0 }))
    }, [])

    useEffect(() => {
        if (businessesState.businesses !== null) {
            const res = businessesState.businesses.result
            setBusinessList(res)
        }
        if (departmentState.departments !== null) {
            setSiteList(departmentState.departments.result)
        }
    }, [businessesState,departmentState])

    useEffect(() => {
        setOptionGroupRole('')
        setselectedMultiRole([])
        if (appRolesState !== null) {
            setRoleList(appRolesState.result)
        }
    }, [appRolesState])

    const selectBusiness = (e) => {
        setOptionGroup('')
        setselectedMulti([])
        let companyID = e.target.value
        dispatch(getDepartments({ companyID, filter: true, limit: 0, skip: 0 }))
    }

    // Site Multi select
    const [selectedMulti, setselectedMulti] = useState([]);
    register('app_user_department', { required: true });

    // Manage Role MultiSelect
    const [selectedMultiRole, setselectedMultiRole] = useState([]);
    const [optionGroupRole, setOptionGroupRole] = useState([{ options: [] }])
    register('app_user_roles', { required: true });
    const [roleList, setRoleList] = useState([])

    useEffect(() => {
        if (siteList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in siteList) {
                let data = {
                    label: siteList[i].app_department_name,
                    value: siteList[i].app_department_id
                }
                options.push(data)
            }
            setOptionGroup(options)
        }
        if (roleList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in roleList) {
                let data = {
                    label: roleList[i].app_role_name,
                    value: roleList[i].app_role_id
                }
                options.push(data)
            }
            setOptionGroupRole(options)
        }
    }, [siteList, roleList])


    const handlePostChangeSite = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroup.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_user_department', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_user_department', postArray)
                }
            }
        });
        setselectedMulti({
            app_department_id: postArray,
        });
    };

    const handlePostChange = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupRole.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_user_roles', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_user_roles', postArray)
                }
            }
        });
        setselectedMultiRole({
            app_role_id: postArray,
        });
    };

    const createUser = (data) => {
        let roleID = data.app_user_roles
        let Dta = []
        for (let i in roleID) {
            Dta.push(roleID[i].value)
        }

        // Active inactive
        let value
        if (data.is_active === "active") {
            value = true
        } else if (data.is_active === "inactive") {
            value = false
        }

        const values = {
            ...data,
            app_user_roles: Dta,
            is_active: value,
            is_lock_app_user: false
        }

        // create pwd failed attempt with 0   &&&   
        const attemptData = {
            app_user_emailid: values.app_user_email,
            failed_attempt: 0,
            otp_failed_attempt: 0,
            lock_upto: new Date(),
            otp_lock_upto: new Date()
        }
        const token = sessionStorage.getItem('authUser')
        axios.post(url.API_BASE_URL+url.LOGIN_ATTEMPT, attemptData, getHeader(token))
            .then((res) => {
            }).catch((err) => {
                console.log(err);
            })
        $('#createCustomerUserBtn').attr('disabled', true)
        axios.post(url.API_BASE_URL+url.APP_USER_URL, values, getHeader(token))
            .then(res => {
                history.goBack()
                $('#createCustomerUserBtn').attr('disabled', false)
            }).catch(err => {
                console.log(err);
            })
    }

    
    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create User" breadcrumbItem="Customer User" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createUser)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User Name <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className='form form-control' {...register('app_user_name', { required: true })} />
                                                {errors.app_user_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User Email <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className='form form-control' {...register('app_user_email', { required: true })} />
                                                {errors.app_user_email && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Role <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <Select
                                                    value={selectedMultiRole.app_role_id}
                                                    isMulti={true}
                                                    onChange={handlePostChange}
                                                    options={optionGroupRole}
                                                    name={"app_role_id"}
                                                    className="modal__input"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select onInput={selectBusiness} className="form-select d-inline" aria-label="Default select example" {...register("app_business_id", { required: true })}>
                                                    <option value="">Company</option>
                                                    {businessList.map((business) => (
                                                        <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Sites <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <Select
                                                    value={selectedMulti.app_department_id !== undefined && selectedMulti.app_department_id}
                                                    isMulti={true}
                                                    onChange={handlePostChangeSite}
                                                    options={optionGroup}
                                                    name={"app_department_id"}
                                                    className="modal__input"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="5" md="6">
                                                <select className="form-select d-inline" {...register('is_active')}>
                                                    {/* <option hidden value="">Select Status</option> */}
                                                    <option value="inactive">INACTIVE</option>
                                                    <option value="active">ACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className='mt-4'>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "> </Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                                                    Back
                                                </Button>
                                                <Button id='createCustomerUserBtn' color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateCustomerUser