import PropTypes from 'prop-types'
import React from "react"
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"

const ActivityModal = ({ show, onCloseClick, data }) => {
    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true} size='lg' backdrop="static">
            <ModalHeader toggle={onCloseClick}>
                <div className="event-timeline-dot">
                    <i className={`${data.color_code === "warning" ? 'bx bx-error bx-flashing' : 'bx bx-right-arrow-circle'} font-size-18 ${'text-' + data.color_code}`} />
                    <span style={{ marginLeft: "10px" }}><b>{data.action}</b></span>

                </div>
            </ModalHeader>
            <ModalBody className="py-3 px-5">
                <Row>
                    <Col lg={12} style={{ maxWidth: '700px', width: '100%' }}>
                        <div className="text-center">
                            <b>{"Under Implementation"}</b>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Row>
                    <Col>
                        <div className="text-center mt-3">
                            <button type="button" className="btn btn-danger ms-2" onClick={onCloseClick}>Close</button>
                        </div>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    )
}

ActivityModal.propTypes = {
    onCloseClick: PropTypes.func,
    show: PropTypes.bool,
    data: PropTypes.any
}

export default ActivityModal