import PropTypes from 'prop-types'
import React, { useMemo, useState,useEffect} from "react"
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Card, CardBody, Col, FormGroup, Label, Modal, ModalBody, Row, Form, Button } from "reactstrap"
import TableContainer from '../TableContainer'
import { ProductID, ProductName, PosItemCode, ProductType, OriginalPrice, CustomUnitPrice, CustomQuantity, RowTotal } from "../ApprovalModelComponent/CustomOpportunityListCol"
import TableContainer2 from '../TableContainer2'
import { useForm } from 'react-hook-form'
import { updateOpportunities, updateProposalVersion } from 'store/actions'
import { useDispatch } from 'react-redux'


const ApproveCustomProductModal = ({ show, onApproveClick, onCloseClick, onRejectClick, data, title, updatedData }) => {


  const { setValue, register, reset, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()

  // setValue("custompricingModels_remarks", data.custompricingModels_remarks)

  const dispatch = useDispatch();

  const [permissionAction, setPermissions] = useState('');

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    },[])


  const UpdatePermission = (permissionAction.includes("system.sales.custompricing.update")) ? false : true

  // pricing model product listing
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: (row, index) => index + 1,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Product ID',
        accessor: 'app_product_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductID datas={cellProps.row.original} {...cellProps} />;
        }
      },
      {
        Header: 'Product Name',
        accessor: 'app_product_local_name',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'POS Item Code',
        accessor: 'app_product_pos_item_code',
        filterable: true,
        Cell: (cellProps) => {
          return <PosItemCode  {...cellProps} />;
        }
      },

      {
        Header: 'Product Type',
        accessor: 'product_type',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductType  {...cellProps} />;
        }
      },
      {
        Header: 'Original price',
        accessor: 'selling_price',
        filterable: true,
        Cell: (cellProps) => {
          return <OriginalPrice  {...cellProps} />;
        }
      },
      {
        Header: 'Unit Price',
        accessor: 'unit_price',
        filterable: true,
        Cell: (cellProps) => {
          return <CustomUnitPrice  {...cellProps} />;
        }
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        filterable: true,
        Cell: (cellProps) => {
          return <CustomQuantity  {...cellProps} />;
        }
      },
      {
        Header: ' Row Total ',
        accessor: 'rowTotal',
        filterable: true,
        Cell: (cellProps) => {
          return <RowTotal  {...cellProps} />;
        }
      },
    ],
    [data?.customProducts]
  );



  const updateApproval = (datas, type) => {
    if (type === "reject") {
      const customValue = {
        proposal_status: "Rejected",
        approval_custom_pricingmodel_status: "Rejected",
        confirm_proposal: false,
        custompricingModels_remarks: datas.custompricingModels_remarks
      }
      if (data.proposal_id) {

        const updateDatas = {
          proposalId: data.proposal_id,
          updatedData: customValue
        }


        console.log("rej", updateDatas);
        dispatch(updateProposalVersion(updateDatas))

        const Datas = {
          opportunitiesId: data.opportunity_id,
          updatedData: {
            "step": 2,
            "status": "Custom pricing model Rejected"
          }
        }
        dispatch(updateOpportunities(Datas))
        
        reset()
        onCloseClick()
      }
    } else if (type === "approve") {

      if (data.proposal_id) {
        const newData = {
          proposal_status: "Confirmed",
          approval_custom_pricingmodel_status: "Approved",
          confirm_proposal: true,
          custompricingModels_remarks: datas.custompricingModels_remarks
        }
        const updateDatas = {
          proposalId: data.proposal_id,
          updatedData: newData
        }

        // console.log("app", updateDatas);
        // console.log("data", data);
        dispatch(updateProposalVersion(updateDatas))


        const Datas = {
          opportunitiesId: data.opportunity_id,
          updatedData: {
            "step": 2,
            "status": "Custom pricing model Approved"
          }
        }
        dispatch(updateOpportunities(Datas))
        reset()
        onCloseClick()
      }
    }
  }


  return (
    <Modal isOpen={show} backdrop="static" centered={true} size='xl'>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <p className='font-size-18'>Do you want to Approve this {title} </p>
              <>
                <Form >
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Proposal Id</Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.proposal_keyid} />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Opportunity Id</Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.opportunity_keyid} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Company Name </Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.app_business_name} />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Site Name </Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.app_department_name} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <TableContainer2
                            columns={columns}
                            data={data?.customProducts}
                            className="custom-header-css"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Remarks <span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        <input type="text" className='form form-control'  {...register('custompricingModels_remarks', { required: true })} />
                      </Col>
                    </FormGroup>
                  </Row>
                </Form>
              </>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              {/* <button type="button" className="btn btn-success ms-2" onClick={onApproveClick}  >
                Approve
              </button>

              <button type="button" className="btn btn-danger ms-2" onClick={onRejectClick}  >
                Reject
              </button> */}

              {/* <button type="button" className="btn btn-warning ms-2" onClick={() => { onCloseClick();  }} >
                Close
              </button> */}

              {
                !UpdatePermission && (
                  <>
                    <Button
                      id="confirmProposalBtn"
                      color='info'
                      onClick={handleSubmit(dta => updateApproval(dta, "approve"))}
                      type='button'
                      className='m-2 '
                    >
                      Approve
                    </Button>
                    <Button
                      id="draftBtn"
                      color='secondary'
                      onClick={handleSubmit(dta => updateApproval(dta, "reject"))}
                      type='button'
                      className='m-2'
                    >
                      Reject
                    </Button>
                  </>
                )
              }

              {/* <Button id="confirmProposalBtn" color='info' onClick={handleSubmit(dta => updateApproval(dta, "approve"))} type='button' className='m-2 me-4'  >
               Approve
              </Button>
              <Button id="draftBtn" color='secondary' onClick={handleSubmit(dta => updateApproval(dta, "reject"))} type='button' className='m-2'>
                Reject
              </Button> */}
              <Button color='danger' type='button' className='m-2' onClick={() => { onCloseClick(); }}>
                Back
              </Button>

            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ApproveCustomProductModal.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any
}

export default ApproveCustomProductModal