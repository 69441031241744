import PropTypes from 'prop-types'
import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"

const RequestModal = ({ show, onDeleteClick, onCloseClick }) => {
    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="py-3 px-5">
                <Row className='mt-1 mb-2'>
                    <Col lg={12}>
                        <div className="text-center">
                            {/* <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              /> */}
                            <h2>Request to Access Customer</h2>
                            {/* <h4>{"You won't be able to revert this!"}</h4> */}
                        </div>
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col lg={4}>
                        <input defaultValue="Requestor" className='form form-control' type="text" />
                    </Col>
                    <Col lg={8}>
                        <input defaultValue="UserName" className='form form-control' type="text" />
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col lg={4}>
                        <input defaultValue="Message" className='form form-control' type="text" />
                    </Col>
                    <Col lg={8}>
                        <textarea defaultValue="Text" className='form form-control'  />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-center mt-3">
                            <button
                                type="button"
                                className="btn btn-success btn-lg ms-2"
                                onClick={onDeleteClick}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger btn-lg ms-2"
                                onClick={onCloseClick}
                            >
                                Close
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

RequestModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any
}

export default RequestModal
