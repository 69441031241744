import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, CardBody, UncontrolledTooltip, Label } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import axios from "axios"
// import { CustKeyId, CompanyName, Industry, BusinessUnitOwner, Status } from "pages/Customers/companies/CompanyListCol"
import getHeader from "config/header"
import Approval from "../../../../components/Common/ApproveModal";
import { useHistory, Link } from "react-router-dom"
import { API_BASE_URL, BUSINESS_URL } from "helpers/url_helper"
import { useDispatch, useSelector } from "react-redux"
import { getAppBusiness, getPricingmodels, updatePricingmodel } from "store/actions"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { PricingModelType, PricingModelCode, PricingModelName, Status, ValidPeriod, ApprovalStatus } from '../../../Products/PricingModels/PricingModelsListCol';
import ApproveCreatePricingModal from "components/Common/ApprovalModelComponent/ApproveCreatePricingModal"

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

function CreatePricingModelsApprovalList() {
    const query = useQuery();
    const history = useHistory()
    const [pricingModelList, setPricingModelList] = useState([])
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const pageStatus = query.get("status")
    const dispatch = useDispatch();
    const queryMultistatus = query.get("queryMultistatus")
    const [pageCount, setPageCount] = useState(1);
    //============global search=====================================//
    const [customFilterData, setCustomFilterData] = useState("")
    const pricingModelState = useSelector(state => state.PricingModelReducer)

    useEffect(() => {
        handlePageClick()
    }, [page, pageLimit, queryMultistatus, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/approvals/pricingmodels?tab=${1}&&skip=${0}&&limit=${pageLimit}&&queryMultistatus=${"pending"}`)
        }
    }, [customFilterData])

    const handlePageClick = () => {
        let filter1 = `{"approval_status":"Pending","$or":[
            {"pricingmodel_keyid":{"$regex": "${customFilterData}", "$options": "i"}},
            {"app_pricing_model_name": {"$regex": "${customFilterData}", "$options": "i"}}
          ]}`
        let filter2 = `{"approval_status":"${queryMultistatus}","$or":[
            {"pricingmodel_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_pricing_model_name":{"$regex":"${customFilterData}","$options":"i"}},
            {"app_pricing_model_type":{"$regex":"${customFilterData}","$options":"i"}}
          ]}`

        let skips = 0;
        if (queryMultistatus === "pending") {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getPricingmodels({ filter: filter1, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getPricingmodels({ filter: filter1, limit: pageLimit, skip: 0 }));
            }
        } else {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getPricingmodels({ filter: filter2, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getPricingmodels({ filter: filter2, limit: pageLimit, skip: 0 }));
            }

        }
    }

    useEffect(() => {
        if (pricingModelState.PricingModels !== null) {
            setPricingModelList(pricingModelState.PricingModels.result);
            setPageCount(Math.ceil(pricingModelState.PricingModels.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(pricingModelState.PricingModels.summary.filteredsize / pageLimit)
            );
        }
    }, [pricingModelState])

    //======================================================================================//
    const [approvePricingModal, setApprovePricingModal] = useState(false);
    const [pricingmodelTBA, setPricingmodelTBA] = useState('')

    const onClickApprove = (company) => {
        setPricingmodelTBA(company);
        setApprovePricingModal(true);
    };

    useEffect(() => {
        if (pricingModelState.isUpdated) {
            setApprovePricingModal(false);
            handlePageClick()
        }
    }, [pricingModelState])

    const handleApprovePricingModel = () => {
        const pricingmodelValue = {
            is_active: true,
            is_approve: true,
            approval_status: "Approved"
        }
        console.log("pricingmodelTBA", pricingmodelTBA);
        if (pricingmodelTBA.pricingmodel_id) {
            const updateData = {
                pricingModelId: pricingmodelTBA.pricingmodel_id,
                updateData: pricingmodelValue
            }
            dispatch(updatePricingmodel(updateData))

            // const token = sessionStorage.getItem('authUser')
            // axios.put(API_BASE_URL + BUSINESS_URL + "/" + ID, pricingmodelValue, getHeader(token))
            //     .then(res => {
            //         setApproveModal(false);
            //         getCompanyData()
            //     }).catch(err => {
            //         console.log(err);
            //         setApproveModal(false);
            //     })
        }
    };

    const handleRejectPricingModel = () => {
        screenTop
        const pricingmodelValue = {
            is_active: false,
            is_approve: false,
            approval_status: "Rejected"
        }
        if (pricingmodelTBA.pricingmodel_id) {
            const updateData = {
                pricingModelId: pricingmodelTBA.pricingmodel_id,
                updateData: pricingmodelValue
            }
            dispatch(updatePricingmodel(updateData))
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Pricing Model ID',
                accessor: 'pricingmodel_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <PricingModelCode {...cellProps} />;
                }
            },
            {
                Header: 'Pricing Model Name',
                accessor: 'app_pricing_model_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <PricingModelName {...cellProps} />;
                }
            },
            {
                Header: 'Pricing Model Type',
                accessor: 'app_pricing_model_type',
                filterable: true,
                Cell: (cellProps) => {
                    return <PricingModelType {...cellProps} />;
                }
            },
            {
                Header: 'Valid Period',
                accessor: 'app_pricing_model_start_date',
                filterable: true,
                Cell: (cellProps) => {
                    return <ValidPeriod EndDate={cellProps.row.original.app_pricing_model_end_date} {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Approval Status',
                accessor: 'approval_status',
                filterable: true,
                Cell: (cellProps) => {
                    return <ApprovalStatus {...cellProps} />;
                }
            },
            // {
            //     Header: 'Action',
            //     Cell: (cellProps) => {
            //         return (
            //             <div className="d-flex gap-3">
            //                 {<href
            //                     className="text-info"
            //                     onClick={() => {
            //                         const companyData = cellProps.row.original;
            //                         onClickApprove(companyData);
            //                     }}
            //                 >
            //                     <i className='mdi mdi-checkbox-marked-outline font-size-18' id={"approveTooltip" + cellProps.row.original.pricingmodel_id}></i>
            //                     <UncontrolledTooltip placement="top" target={"approveTooltip" + cellProps.row.original.pricingmodel_id}>
            //                         Approve
            //                     </UncontrolledTooltip>
            //                 </href>}
            //             </div>
            //         );
            //     }
            // },


            {
                Header: 'Actions',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {((permissionAction.includes("system.settings.approval.read")) &&
                                (permissionAction.includes("system.settings.approval.update"))) ?
                                <>
                                    <button
                                        onClick={() => {
                                            const companyData = cellProps.row.original;
                                            onClickApprove(companyData);
                                        }} className="btn btn-success btn-sm">View</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                        </div>
                    );
                }
            },
        ], [pricingModelList]
    );

    return (
        <React.Fragment>
            <ApproveCreatePricingModal
                show={approvePricingModal}
                onApproveClick={handleApprovePricingModel}
                onRejectClick={handleRejectPricingModel}
                onCloseClick={() => setApprovePricingModal(false)}
                data={pricingmodelTBA}
                title="Pricing Model"
            />
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns}
                                data={pricingModelList}
                                isGlobalFilter={true}
                                customPageSize={10}
                                className="custom-header-css"
                                handlePageClick={handlePageClick}
                                customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                customPageCount={pageCount}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default CreatePricingModelsApprovalList



