import PropTypes from "prop-types";
import React from "react";
import qs from 'qs'
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

import { withRouter, Link, useLocation } from "react-router-dom";
import * as url from "./../../helpers/url_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "assets/images/maxims/Maxims-logo.png";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import getHeader from "config/header";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const SetupPassword = props => {

  document.title = "Password Setup |  Maxims B2B Portal";

  const query = useQuery();
  const userID = query.get("userid")

  const [commonToken, setCommonToken] = useState("")

  // Get Common Token 
  useEffect(() => {
    if (userID) {
      const headerData = {
        grant_type: "client_credentials",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      }
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(headerData),
        url: url.API_BASE_URL+url.AUTH_TOKEN
      }
      axios(options)
        .then(res => {
          setCommonToken(res.data.access_token)
          console.log(res.data);
        }).catch(err => {
          console.log(err);
        })
    }
  }, [userID])

  const [Regex, setRegex] = useState()
  const [pwdlength, setPwdLength] = useState({})

  useEffect(() => {
    if (commonToken) {
      axios.get(url.API_BASE_URL+PASSWORD_POLICY_URL, getHeader(commonToken))
        .then(res => {
          setPwdLength(res.data)
          let a = res.data.app_pwd_lowercase
          let b = res.data.app_pwd_uppercase
          let c = res.data.app_pwd_numeric
          let d = res.data.app_pwd_special
          let e = res.data.app_pwd_min_length
          let f = res.data.app_pwd_max_length

          let regex = new RegExp(`^(?=(.*[a-z]){${a}})(?=(.*[A-Z]){${b}})(?=(.*\\d){${c}})(?=(.*[@$!%*?&]){${d}})[A-Za-z\\d@$!%*?&]{${e},${f}}$`);
          setRegex(regex)
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [commonToken])


  // Set Password for customer user  = user create = mail to otp = set password 
  
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: ''
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .required("Please enter your password")
        .matches(
          Regex,
          "Password should be Lowercase, Uppercase, Numbers and Special Characters "
        ),
      confirmPassword: Yup.string()
        .required("Please enter your confirm password")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
    }),
    onSubmit: (values) => {
      if (values.password !== values.confirmPassword) {
        toast.error("Passwords should match")
      } else {
        const data = {
          app_user_password: values.password
        }
        $('#setPasswordBtn').attr("disabled", true)
        axios.put(url.API_BASE_URL+url.APP_USER_URL+"/"+ userID, data, getHeader(commonToken))
          .then(res => {
            window.open("http://mxappdev.mezzofy.com/login", "_self")
          }).catch(err => {
            console.log(err);
            toast.error("Failed!!")
            $('#setPasswordBtn').attr("disabled", false)
          })
      }
    }
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div style={{ color: "white" }} className="text p-4">
                        <h5 style={{ color: "white" }} className="text">Setup Password</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title bg-transparent">
                          <img
                            src={logo}
                            alt=""
                            height="54"
                            style={{ paddingLeft: "45px", marginTop: "-20px" }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">


                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          minLength={pwdlength.app_pwd_min_length}
                          maxLength={pwdlength.app_pwd_max_length}
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          minLength={pwdlength.app_pwd_min_length}
                          maxLength={pwdlength.app_pwd_max_length}
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                          }
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                            disabled={(userID && commonToken) ? false : true}
                            id="setPasswordBtn"
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  {/* © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand */}
                  © {new Date().getFullYear()} Maxims B2B Portal.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SetupPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SetupPassword);
