import { takeEvery, put, call } from "redux-saga/effects"
import {
    getCreditsSuccess, getCreditsFail,
    getCreditSuccess, getCreditFail,
    getAllCreditSuccess, getAllCreditFail,
    postCreditSuccess, postCreditFail,
    deleteCreditSuccess, deleteCreditFail,
    updateCreditSuccess, updateCreditFail,
} from "./actions"

// Api Handler 
import {
    getCredits,
    getCredit,
    getAllCredit,
    postCredit,
    updateCredit,
    deleteCredit,

} from "helpers/backendApi_helpers";


import {
    GET_CREDITS,
    GET_CREDIT,
    GET_ALL_CREDIT,
    POST_CREDIT,
    DELETE_CREDIT,
    UPDATE_CREDIT
} from "./actionsTypes"




function* fetchCredits({ payload: payload }) {
    try {
        const response = yield call(getCredits, payload);
        yield put(getCreditsSuccess(response))
    } catch (error) {
        yield put(getCreditsFail(error))
    }
}

function* postAppCredit({ payload: payload }) {
    try {
        const response = yield call(postCredit, payload);
        yield put(postCreditSuccess(response))
    } catch (error) {
        yield put(postCreditFail(error))
    }
}

function* deleteAppCredit({ payload: payload }) {
    try {
        const response = yield call(deleteCredit, payload);
        yield put(deleteCreditSuccess(response))
    } catch (error) {
        yield put(deleteCreditFail(error))
    }
}

function* getAppCredit({ payload: payload }) {
    try {
        const response = yield call(getCredit, payload);
        yield put(getCreditSuccess(response))
    } catch (error) {
        yield put(getCreditFail(error))
    }
}

function* getAppAllCredit({ payload: payload }) {
    try {
        const response = yield call(getAllCredit, payload);
        yield put(getAllCreditSuccess(response))
    } catch (error) {
        yield put(getAllCreditFail(error))
    }
}

function* updateAppCredit({ payload: payload }) {
    try {
        const response = yield call(updateCredit, payload);
        yield put(updateCreditSuccess(response))
    } catch (error) {
        yield put(updateCreditFail(error))
    }
}

function* CreditsSaga() {
    
    yield takeEvery(GET_CREDITS, fetchCredits)
    yield takeEvery(POST_CREDIT, postAppCredit)
    yield takeEvery(DELETE_CREDIT, deleteAppCredit)
    yield takeEvery(GET_CREDIT, getAppCredit)
    yield takeEvery(GET_ALL_CREDIT, getAppAllCredit)
    yield takeEvery(UPDATE_CREDIT, updateAppCredit)
}

export default CreditsSaga

