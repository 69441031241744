import React, { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Col, FormGroup, Label, Modal, ModalBody, Row, Card, CardBody } from "reactstrap"
import TableContainer from "../TableContainer";
import { getCouponGenerate, getDownloadLog, postDownloadLog } from "store/actions";
import { useDispatch, useSelector } from "react-redux";


const LogModal = ({ show, onCloseClick, data }) => {


    const dispatch = useDispatch()
    const [downloadLogList, setDownloadLogList] = useState([]);
    const downloadlogstate = useSelector(state => state.DownloadLogReducer);

    const [passwordState, setPasswordState] = useState(true)

    const xyz = () => {
        setPasswordState(abc => !abc)
    }


    // useEffect(() => {
    //     if (show) {
    //         let value = {
    //             "requestId": data
    //             // "requestId": "XTTRGMINN6NF3BJ"
    //         }
    //         dispatch(getDownloadLog(value))
    //     }
    // }, [data])



    // useEffect(() => {
    //     if (show) {
    //         if (downloadlogstate.isCreated) {
    //             let value = {
    //                 "requestId": data
    //                 // "requestId": "XTTRGMINN6NF3BJ"
    //             }
    //             dispatch(getDownloadLog(value))
    //         }
    //     }

    // }, [downloadlogstate])


    useEffect(() => {
        if (show) {
        let filter = JSON.stringify({"request_id": data});
        dispatch(getDownloadLog({ filter: filter }))
        }
       
    }, [data])

    useEffect(() => {
        if (show) {
              if (downloadlogstate.isCreated) {
        let filter = JSON.stringify({"request_id": data});
        dispatch(getDownloadLog({ filter: filter}))
        }
        }
      
    }, [downloadlogstate])

    useEffect(() => {
        if (downloadlogstate.downloadLogs !== null) {
            setDownloadLogList(downloadlogstate?.downloadLogs?.result)
        }
    }, [downloadlogstate])


    const logColumns = useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: (row, index) => index + 1,
                filterable: true,
                Cell: ({ value }) => <span>{value}</span>,
            },
            {
                Header: 'User ID / User Name',
                accessor: 'sys_user_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <span > {cellProps?.row?.original?.sys_user_keyid}  |   {cellProps?.row?.original?.sys_user_name}</span>;
                }
            },
            {
                Header: 'Date/Time',
                accessor: 'created_on',
                filterable: true,
                Cell: ({ value }) => <span>{value}</span>,
            },
            {
                Header: 'Action',
                accessor: 'action',
                filterable: true,
                Cell: ({ value }) => <span>{value}</span>,
            },
        ], [downloadLogList]
    );

    const couponDownloadLog = () => {
        let value = {
            "action": "Download",
            "request_id": data,
            // "requestId": "XTTRGMINN6NF3BJ"
        }
        dispatch(postDownloadLog(value))
    }

    const couponGenerateState = useSelector(state => state.CouponGenerateReducer)
    const [couponGenerate, setCouponGenerate] = useState('')

    useEffect(() => {
        if (show && data) {
            let value = {
                "requestId": data  
                // "requestId": "XTTRGMINN6NF3BJ"
            }
            dispatch(getCouponGenerate(value))
        }

    }, [show, data])

    useEffect(() => {
        if (couponGenerateState?.getcouponGenerate !== null) {
             setCouponGenerate(couponGenerateState?.getcouponGenerate)
        }
    }, [couponGenerateState])

    // useEffect(() => {
    //     console.log("couponGenerate", couponGenerate);
    // }, [couponGenerate])

    return (
        <Modal isOpen={show} backdrop="static" centered={true} size='xl'>

            <ModalBody className="py-3 px-5">
                <Row>
                    <Col lg={12}>
                        <div className="text-center">
                            <p className='font-size-18'>Log Details </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="text-center input-group">
                            <strong className="font-size-16 mt-2  me-2">Password : </strong>
                            <input defaultValue={couponGenerate?.password} disabled type={passwordState ? 'password' : 'text'} id="passwordField" autoComplete='off' className='form form-control' />
                            <div onClick={xyz} style={{ cursor: "pointer" }} className="input-group-append">
                                <span className="btn btn-info">{passwordState ? <i className='bx bxs-hide' ></i> : <i className='bx bxs-show'></i>}</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={7}>
                        <div className="text-center">
                            <button
                                type="button"
                                onClick={() => {
                                    // window.location.href = "https://images.platform.mezzofy.com/v3coupon/shlx/outlet/20240619T15:44:29.177Z.zip";
                                    window.location.href = couponGenerate?.download_link;
                                    couponDownloadLog()
                                }
                                }
                                className="btn btn-danger ms-2"

                            >
                                Download
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row style={{ maxHeight: "300px", overflowY: "auto" }}>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <TableContainer
                                    columns={logColumns}
                                    data={downloadLogList}
                                    isGlobalFilter={false}
                                    className="custom-header-css"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-center mt-3">
                            <button
                                type="button"
                                className="btn btn-danger ms-2"
                                onClick={onCloseClick}
                            >
                                Close
                            </button>
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

LogModal.propTypes = {
    onCloseClick: PropTypes.func,
    onApproveClick: PropTypes.func,
    show: PropTypes.any
}

export default LogModal