import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Table, Collapse, Nav, NavItem, NavLink, TabContent, TabPane, CardBody } from "reactstrap"

import classnames from 'classnames';
import axios from 'axios';
import getHeader from 'config/header';
import { useDispatch, useSelector } from 'react-redux'
import { postAppBusinessUnits, postAppBusinessUnitsSuccess, postAppBusinessUnitsFail } from "store/administration/businessUnit/actions"
import { getBusinessProducts, getBusinessUnitDivisions, getSysUsers } from 'store/actions'
import { getAppBusinessUnit } from 'helpers/backendApi_helpers'
import * as url from "./../../../helpers/url_helper";
import TableContainer from 'components/Common/TableContainer'


function CreateBusinessUnit() {

    const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()

    const dispatch = useDispatch();
    const history = useHistory()

    const [sysUserList, setSysUserList] = useState([])
    const [buDivisionList, setBuDivisionList] = useState([])
    const [productList, setProductList] = useState([])
    const sysUserState = useSelector(state => state.sysUsersReducer)
    const divisionState = useSelector(state => state.BUDivisionReducer)
    const businessProductState = useSelector(state => state.BusinessProducts);
    const businessUnit = useSelector(state => state.AppBusinessUnits);

    useEffect(() => {
        SysUser()
        BusinessUnitsDivisions()
        Poducts()
    }, [])


    const SysUser = () => {
        setOptionGroupBusUnitUser()
        setselectedMultiBusUser([])
        dispatch(getSysUsers({ filter: `{"is_active":true}`, limit: 0, skip: 0 }))
    }

    const BusinessUnitsDivisions = () => {
        setOptionGroupBuDivision()
        setselectedMultiBuDivision([])
        dispatch(getBusinessUnitDivisions({ filter: '{"is_active":true}', limit: 0, skip: 0 }));
    }

    const Poducts = () => {
        setOptionGroupProduct()
        setselectedMultiProduct([])
        dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));
    }

    useEffect(() => {
        if (sysUserState.sysUsers !== null) {
            setSysUserList(sysUserState.sysUsers.result);
        }
        if (divisionState.BUDivisions !== null) {
            setBuDivisionList(divisionState.BUDivisions.result);
        }
        if (businessProductState.products !== null) {
            setProductList(businessProductState.products.result);
        }

    }, [sysUserState, divisionState, businessProductState])

    useEffect(() => {
        if (businessUnit.isCreated) {
            history.goBack()
        }
    }, [businessUnit])

    // Manage Business Unit User MultiSelect
    const [selectedMultiBusUser, setselectedMultiBusUser] = useState([]);
    const [optionGroupBusUnitUser, setOptionGroupBusUnitUser] = useState([{ options: [] }])
    register('app_business_unit_user');

    // manage bu division multiselect
    const [selectedMultiBuDivision, setselectedMultiBuDivision] = useState([]);
    const [optionGroupBuDivision, setOptionGroupBuDivision] = useState([{ options: [] }])
    register('app_business_unit_division');

    //  manage product multi select
    const [selectedMultiProduct, setselectedMultiProduct] = useState([]);
    const [optionGroupProduct, setOptionGroupProduct] = useState([{ options: [] }])
    register('app_business_unit_product');


    useEffect(() => {
        if (sysUserList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in sysUserList) {
                // if (sysUserList[i].sys_user_role_name !== undefined) {
                //     for (let j in sysUserList[i].sys_user_role_name) {
                //         let data = {
                //             label: sysUserList[i].sys_user_keyid + " | " + sysUserList[i].sys_user_name + " | " + sysUserList[i].sys_user_role_name[j],
                //             value: sysUserList[i].sys_user_id
                //         }
                //         options.push(data)
                //     }
                // }
                let data = {
                    label: sysUserList[i].sys_user_keyid + " | " + " " + sysUserList[i].sys_user_name,
                    value: sysUserList[i].sys_user_id
                }
                options.push(data)
            }
            setOptionGroupBusUnitUser(options)
        }

        if (buDivisionList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in buDivisionList) {
                let data = {
                    label: buDivisionList[i].app_business_unit_division_name,
                    value: buDivisionList[i].app_division_id
                }
                options.push(data)
            }
            setOptionGroupBuDivision(options)
        }

        if (productList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in productList) {
                let data = {
                    label: productList[i].app_product_pos_item_code + " | " + productList[i].app_product_payment_code + " | " + productList[i].app_product_local_name,
                    value: productList[i].app_product_id
                }
                options.push(data)
            }
            setOptionGroupProduct(options)
        }
    }, [sysUserList, buDivisionList, productList])

    const handlePostChange = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupBusUnitUser.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_business_unit_user', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_business_unit_user', postArray)
                }
            }
        });
        // setBusUnitUserList(postArray)
        setselectedMultiBusUser({
            sys_user_id: postArray,
        });
    };

    const handleChangeDivision = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupBuDivision.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_business_unit_division', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_business_unit_division', postArray)
                }
            }
        });
        //   setBusUnitUserList(postArray)
        setselectedMultiBuDivision({
            app_division_id: postArray,
        });
    };

    const handleChangeProduct = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values
        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupProduct.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_business_unit_product', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_business_unit_product', postArray)
                }
            }
        });
        //   setBusUnitUserList(postArray)
        setselectedMultiProduct( postArray);
    };

    const CreateBusinessUnit = (data) => {
        // $('#createbusinessunits').attr("disabled", true)
        let value
        if (data.is_active === "active") {
            value = true
        } else if (data.is_active === "inactive") {
            value = false
        }


        let BusUnituser = data.app_business_unit_user
        let Dta = []
        for (let i in BusUnituser) {
            Dta.push(BusUnituser[i].value)
        }

        const newBusinessUnit = {
            ...data,
            // app_business_unit_user: Dta,
            is_active: value,
        }
        dispatch(postAppBusinessUnits(newBusinessUnit));

    }


    const removeSelectedProduct = (indexToRemove) => {
        const newData = selectedMultiProduct.filter((_, index) => index !== indexToRemove);
        setselectedMultiProduct(newData);
    }

    const columns2 = useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: (row, index) => index + 1,
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Product Name',
                accessor: 'label',
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Action',
                accessor: (row, index) => index,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <button type='button' className="btn btn-danger btn-sm" onClick={() => { removeSelectedProduct(cellProps.value) }}>X</button>
                        </div>
                    );
                }
            },

        ],
        [selectedMultiProduct]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Business Unit" breadcrumbItem="Business Unit" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(CreateBusinessUnit)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Business Unit Name <span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input type="text" className='form form-control' {...register('app_business_unit_name', { required: true })} />
                                                {errors.app_business_unit_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >CSMS Branch Code<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input type="text" className='form form-control'   {...register('app_csms_branch_code', { required: true })} />
                                                {errors.app_csms_branch_code && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                BU Division 
                                            </Label>
                                            <Col lg="4" md="6">
                                                <Select
                                                    value={selectedMultiBuDivision.app_division_id}
                                                    isMulti={true}
                                                    onChange={handleChangeDivision}
                                                    options={optionGroupBuDivision}
                                                    name={"app_division_id"}
                                                    className="modal__input"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Products 
                                            </Label>
                                            <Col lg="4" md="6">
                                                <Select
                                                    value={selectedMultiProduct}
                                                    isMulti={true}
                                                    onChange={handleChangeProduct}
                                                    options={optionGroupProduct}
                                                    name={"app_product_id"}
                                                    className="modal__input"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    {selectedMultiProduct.length > 0 ? <Row>
                                        <Col xs="12">
                                            <Card>
                                                <CardBody>
                                                    <TableContainer
                                                        columns={columns2}
                                                        data={selectedMultiProduct}
                                                        isGlobalFilter={true}
                                                        isTwoColumnTable={true}
                                                        isthemeGlobalFilter={true}
                                                        className="custom-header-css"
                                                        // customPageCount={pageCount}
                                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row> : ""}
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Users 
                                            </Label>
                                            <Col lg="4" md="6">
                                                <Select
                                                    value={selectedMultiBusUser.sys_user_id}
                                                    isMulti={true}
                                                    onChange={handlePostChange}
                                                    options={optionGroupBusUnitUser}
                                                    name={"sys_user_id"}
                                                    className="modal__input"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="4" md="6">
                                                <select className="form-select d-inline" {...register('is_active')}>
                                                    <option value="inactive">INACTIVE</option>
                                                    <option value="active">ACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                                                    Back
                                                </Button>
                                                <Button id="createbusinessunits" color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateBusinessUnit
