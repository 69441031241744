/* EVENTS */

// Get list proposal version
export const GET_PROPOSAL_VERSION = "GET_PROPOSAL_VERSION";
export const GET_PROPOSAL_VERSION_SUCCESS = "GET_PROPOSAL_VERSION_SUCCESS";
export const GET_PROPOSAL_VERSION_FAIL = "GET_PROPOSAL_VERSION_FAIL"

// Post proposal version
export const POST_PROPOSAL_VERSION = "POST_PROPOSAL_VERSION";
export const POST_PROPOSAL_VERSION_SUCCESS = "POST_PROPOSAL_VERSION_SUCCESS";
export const POST_PROPOSAL_VERSION_FAIL = "POST_PROPOSAL_VERSION_FAIL"

// Delete proposal version
export const DELETE_PROPOSAL_VERSION = "DELETE_PROPOSAL_VERSION";
export const DELETE_PROPOSAL_VERSION_SUCCESS = "DELETE_PROPOSAL_VERSION_SUCCESS";
export const DELETE_PROPOSAL_VERSION_FAIL = "DELETE_PROPOSAL_VERSION_FAIL"

// Get id proposal version
export const GET_PROPOSAL_VERSION_DETAIL = "GET_PROPOSAL_VERSION_DETAIL";
export const GET_PROPOSAL_VERSION_DETAIL_SUCCESS = 'GET_PROPOSAL_VERSION_DETAIL_SUCCESS';
export const GET_PROPOSAL_VERSION_DETAIL_FAIL = "GET_PROPOSAL_VERSION_DETAIL_FAIL";

// Update proposal version
export const UPDATE_PROPOSAL_VERSION = "UPDATE_PROPOSAL_VERSION";
export const UPDATE_PROPOSAL_VERSION_SUCCESS = "UPDATE_PROPOSAL_VERSION_SUCCESS";
export const UPDATE_PROPOSAL_VERSION_FAIL = "UPDATE_PROPOSAL_VERSION_FAIL";

//get proposal by oppportunity
export const GET_PROPOSAL_BY_OPPORTUNITY_ID = "GET_PROPOSAL_BY_OPPORTUNITY_ID";
export const GET_PROPOSAL_BY_OPPORTUNITY_ID_SUCCESS = "GET_PROPOSAL_BY_OPPORTUNITY_ID_SUCCESS";
export const GET_PROPOSAL_BY_OPPORTUNITY_ID_FAIL = "GET_PROPOSAL_BY_OPPORTUNITY_ID_FAIL";