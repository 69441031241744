import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';
import { BusinessUnitDivisionName,  Status, BUDID } from './BusinessUnitDivisionListCol';
import axios from "axios";
import getHeader from "config/header";
import { getBusinessUnitDivisions } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const BusinessUnitDivisions = props => {

  const history = useHistory()
  const dispatch = useDispatch()

  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const pageStatus = query.get("status")

  const [businessUnitDivisions, setBusinessUnitDivisions] = useState([])
  const [permissions, setPermissions] = useState('')
  const [pageCount, setPageCount] = useState(1);
  // const [pageStatus, setPageStatus] = useState(true)
  // const [page, setPage] = useState(0);
  // const [pageLimit, setPageLimit] = useState(10);

  //meta title
  document.title = "Business Unit Division | Maxims B2B Portal";

  const businessUnitDivisionState = useSelector(state => state.BUDivisionReducer)

  useEffect(() => {
    setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
  }, [])

  useEffect(() => {
    if (businessUnitDivisionState.BUDivisions !== null) {
      setBusinessUnitDivisions(businessUnitDivisionState.BUDivisions.result);
      setPageCount(Math.ceil(businessUnitDivisionState.BUDivisions.summary.filteredsize / pageLimit) == 0 ?  1
      :
      Math.ceil(businessUnitDivisionState.BUDivisions.summary.filteredsize / pageLimit));
    }
    if(businessUnitDivisionState.isDeleted) {
      handlePageClick(page);
    }
  }, [businessUnitDivisionState])


  const [customFilterData, setCustomFilterData] = useState("")

  useEffect(() => {
    handlePageClick()
  }, [pageStatus, pageLimit, page,customFilterData])

  useEffect(()=>{
    if(customFilterData.length > 0){
      history.push(`/businessUnitDivision?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
    }
  },[customFilterData])


  const handlePageClick = (index) => {

    let filter = `{"is_active":${pageStatus},"$or":[
      {"app_business_unit_division_keyid":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_business_unit_division_name":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`

    let skips = 0;
    if (page > 0) {
      skips = page * pageLimit;
      dispatch(getBusinessUnitDivisions({ filter: filter, limit: pageLimit, skip: skips }));
    }
    else {
      dispatch(getBusinessUnitDivisions({ filter: filter, limit: pageLimit, skip: 0 }));
    }
  }

  // Company Column
  const columns = useMemo(
    () => [
      {
        Header: 'Business Unit Division ID',
        accessor: 'app_business_unit_division_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <BUDID {...cellProps} />;
        }
      },
      {
        Header: 'Business Unit Division Name',
        accessor: 'app_business_unit_division_name',
        filterable: true,
        Cell: (cellProps) => {
          return <BusinessUnitDivisionName {...cellProps} />;
        }
      },
      {
        Header: ' Status',
        accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
     
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
          return (
            <div className="d-flex gap-3">
            {(permissionAction.includes("system.administration.businessunitsdivisions.read")) ?
              <>
                <button
                  onClick={() => {
                    history.push(`/viewBusinessUnitDivision/${cellProps.row.original.app_division_id}`)
                  }} className="btn btn-success btn-sm">View</button>
              </> : <div className="text-muted">No Access</div>
            }
            {(permissionAction.includes("system.administration.businessunitsdivisions.read")) && (permissionAction.includes("system.administration.businessunitsdivisions.update")) ||
              (permissionAction.includes("system.administration.businessunitsdivisions.read")) && (permissionAction.includes("system.administration.businessunitsdivisions.delete"))
              ?
              <>
                <button
                  onClick={() => {
                    history.push(`/updateBusinessUnitDivision/${cellProps.row.original.app_division_id}`)
                  }} className="btn btn-info btn-sm">Edit</button>
              </> : <div className="text-muted">No Access</div>
            }

      </div>
          //   <div className="d-flex gap-3">
          //   {(permissionAction.includes("system.administration.businessunitsdivisions.read")) ?
          //     <>
          //       <button
          //         onClick={() => {
          //           history.push(`/updateBusinessUnitDivision/${cellProps.row.original.app_division_id}`)
          //         }} className="btn btn-success btn-sm">View</button>
          //     </> : <div className="text-muted">No Access</div>
          //   }
          // </div>
          );
        }
      },
    ], [businessUnitDivisions]
  );


  return (
    <React.Fragment>
      <div className="page-content">
      {permissions.includes("system.administration.businessunitsdivisions.read") ? <Container fluid>
          <Breadcrumbs title="Business Unit Division" breadcrumbItem="Administration" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={businessUnitDivisions}
                    isGlobalFilter={true}
                    customPageCount={pageCount}
                    className="custom-header-css"
                    isBusinessUnitDivision={permissions.includes("system.administration.businessunitsdivisions.write") ? true : false}
                    customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                    // customPageSize={10}
                    // customPageStatus={(data) => setPageStatus(data)}
                    // pageSizeTransfer={(data) => setPageLimit(data)}
                    // handlePageClick={handlePageClick}
                    // customPageIndex={page}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
         :
         <Container fluid>
           <Row>
             <Col xs="12">
               <Card>
                 <CardBody>
                   <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                     <div>
                       <h1><i className='bx bxs-lock'></i></h1>
                       <h3>Access Denied</h3>
                       <div className="text-muted">You don't have permission to access this module</div>
                     </div>
                   </div>
                 </CardBody>
               </Card>
             </Col>
           </Row>
         </Container>
       }
      </div>
    </React.Fragment>
  );
};

BusinessUnitDivisions.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
};

export default BusinessUnitDivisions;


