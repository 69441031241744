import { takeEvery, put, call } from "redux-saga/effects"
import {
    getCouponGenerateSuccess,
    getCouponGenerateFail,
    postCouponGenerateSuccess,
    postCouponGenerateFail,
} from "./actions"

import {
    postAppCouponGenerate,
    getAppCouponGenerate,
}
    from "helpers/backendApi_helpers";

import {
    GET_APP_COUPON_GENERATE,
    POST_APP_COUPON_GENERATE,

} from "./actionTypes";

function* fetchgetCouponGenerate({ payload: payload }) {
    try {
        const response = yield call(getAppCouponGenerate, payload);
        yield put(getCouponGenerateSuccess(response))
    } catch (error) {
        yield put(getCouponGenerateFail(error))
    }
}

function* postCouponGenerate({ payload: payload }) {
    try {
        const response = yield call(postAppCouponGenerate, payload);
        yield put(postCouponGenerateSuccess(response))
    } catch (error) {
        yield put(postCouponGenerateFail(error))
    }
}

function* AppCouponGenerate() {
    yield takeEvery(GET_APP_COUPON_GENERATE, fetchgetCouponGenerate)
    yield takeEvery(POST_APP_COUPON_GENERATE, postCouponGenerate)
}

export default AppCouponGenerate





