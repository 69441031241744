import { takeEvery, put, call } from "redux-saga/effects"
import {
    getDownloadLogSuccess,
    getDownloadLogFail,
    postDownloadLogSuccess,
    postDownloadLogFail,
    deleteDownloadLogSuccess,
    deleteDownloadLogFail,
    getDownloadLogDetailSuccess,
    getDownloadLogDetailFail,
} from "./actions"

import {
    getDownloadlog,
    postDownloadlog,
    deleteDownloadlog,
    getDownloadlogDetail,
}
    from "helpers/backendApi_helpers";

import {
    DELETE_DOWNLOAD_LOG,
    GET_DOWNLOAD_LOG_DETAIL,
    GET_DOWNLOAD_LOG,
    POST_DOWNLOAD_LOG,
} from "./actionTypes";

function* fetchAppDownload({ payload: payload }) {
  try {
    const response = yield call(getDownloadlog, payload);
    yield put(getDownloadLogSuccess(response))
  } catch (error) {
    yield put(getDownloadLogFail(error))
  }
}

function* postAppDownload({ payload: payload }) {
  try {
    const response = yield call(postDownloadlog, payload);
    yield put(postDownloadLogSuccess(response))
  } catch (error) {
    yield put(postDownloadLogFail(error))
  }
}

function* deleteAppDownload({ payload: payload }) {
  try {
    const response = yield call(deleteDownloadlog, payload);
    yield put(deleteDownloadLogSuccess(response))
  } catch (error) {
    yield put(deleteDownloadLogFail(error))
  }
}

function* getAppDownloadDetail({ payload: payload }) {
  try {
    const response = yield call(getDownloadlogDetail, payload);
    yield put(getDownloadLogDetailSuccess(response))
  } catch (error) {
    yield put(getDownloadLogDetailFail(error))
  }
}

function* AppDownloadLogSaga() {
  yield takeEvery(GET_DOWNLOAD_LOG, fetchAppDownload)
  yield takeEvery(POST_DOWNLOAD_LOG, postAppDownload)
  yield takeEvery(DELETE_DOWNLOAD_LOG, deleteAppDownload)
  yield takeEvery(GET_DOWNLOAD_LOG_DETAIL, getAppDownloadDetail)
}

export default AppDownloadLogSaga

