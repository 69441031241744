import React from 'react';
import { Badge } from 'reactstrap';


const BusinessName = (cell) => {
    return cell.value ? cell.value : '';
};

const BusinessStatus = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

const BusinessUnitID = (cell) => {
    return cell.value ? cell.value : '';
};



export {
    BusinessName,
    BusinessStatus,
    BusinessUnitID,
};