import React from 'react';
import { Badge } from 'reactstrap';


const BusinessUnitDivisionName = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

const BUDID = (cell) => {
    return cell.value ? cell.value : '';
};



export {
    BusinessUnitDivisionName,
    Status,
    BUDID,
};