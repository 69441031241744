import { takeEvery, put, call } from "redux-saga/effects"
import {
  getBusinessProductsFail, getBusinessProductsSuccess,
  getBusinessProductDetailSuccess, getBusinessProductDetailFail,
  updateBusinessProductSuccess, updateBusinessProductFail
} from "./actions"
import { getBusinessProducts, getBusinessProduct, updateBusinessProduct } from "helpers/backendApi_helpers";
import {
  GET_BUSINESS_PRODUCTS,
  GET_BUSINESS_PRODUCTS_DETAIL,
  UPDATE_BUSINESS_PRODUCTS
} from "./actionTypes";


function* fetchBusinessProducts({ payload: payload }) {
  try {
    const response = yield call(getBusinessProducts, payload);
    yield put(getBusinessProductsSuccess(response))
  } catch (error) {
    yield put(getBusinessProductsFail(error))
  }
}

function* getAppBusinessProducts({ payload: payload }) {
  try {
    const response = yield call(getBusinessProduct, payload);
    yield put(getBusinessProductDetailSuccess(response))
  } catch (error) {
    yield put(getBusinessProductDetailFail(error))
  }
}

function* updateAppBusinessProducts({ payload: payload }) {
  try {
    const response = yield call(updateBusinessProduct, payload);
    yield put(updateBusinessProductSuccess(response))
  } catch (error) {
    yield put(updateBusinessProductFail(error))
  }
}

function* BusinessProductsSaga() {
  yield takeEvery(GET_BUSINESS_PRODUCTS, fetchBusinessProducts)
  yield takeEvery(GET_BUSINESS_PRODUCTS_DETAIL, getAppBusinessProducts)
  yield takeEvery(UPDATE_BUSINESS_PRODUCTS, updateAppBusinessProducts)
}


export default BusinessProductsSaga
