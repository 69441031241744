import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, useFieldArray, Controller } from "react-hook-form"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Table, Label, CardBody } from "reactstrap"
import { useState } from 'react';
import getHeader from 'config/header';
import axios from 'axios';
import { toast } from 'react-toastify';
import expiredSessionHandler from 'common/expiredSessionHandler';
import { getLoginRetry, getOtpTimeout, getPassWordPolicys, postLoginRetry, postOtpTimeout, postPassWordPolicy, updateLoginRetry, updateOtpTimeout, updatePassWordPolicy } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';

const Securities = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { register: registerOTP, setValue: setValueOTP, handleSubmit: handleSubmitOTP, control: controlOTP, reset: resetOTP } = useForm(
        {
            defaultValues: {
                otp_retry: [
                    {
                        otp_retry_time_1: ''
                    }
                ]
            }
        }
    )
    const { fields: fieldsOTP, append: appendOTP, remove: removeOTP } = useFieldArray({
        control: controlOTP,
        name: 'otp_retry'
    });

    const { register, control, handleSubmit, setValue } = useForm({
        defaultValues: {
            login_retry: [
                {
                    retry_time_1: ''
                }
            ]
        }
    });

    const { fields: fieldsLogin, append: appendLogin, remove: removeLogin } = useFieldArray({
        control,
        name: 'login_retry'
    });

    const { register: registerPwdPolicy, setValue: setValuePwdPolicy, handleSubmit: handleSubmitPwdPolicy } = useForm()


    const [permissions, setPermissions] = useState('')
    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    const LoginRetryState = useSelector(state => state.LoginRetry)
    const [editState, setEditState] = useState(false)
    const [createState, setCreateState] = useState(true)
    const [triggeredCancel, setTriggeredCancel] = useState(false)

    useEffect(() => {
        dispatch(getLoginRetry())
    }, [triggeredCancel])


    useEffect(() => {
        if (LoginRetryState.loginRetry !== null) {
            // const loginRetryArray = LoginRetryState?.loginRetry?.app_login_retry?.login_retry;
            const loginRetryArray = LoginRetryState?.loginRetry?.login_retry;
            const updatedLoginRetryArray = loginRetryArray.slice(0, loginRetryArray.length - 1);
            setValue('login_retry', updatedLoginRetryArray);
            setCreateState(false)
        }
        if (LoginRetryState.isCreated) {
            toast.success("Login Retry Saved")
            setEditState(!editState)
            setCreateState(false)
            dispatch(getLoginRetry())
        }
        else if (LoginRetryState.isUpdated) {
            toast.success("Login Retry Updated")
            dispatch(getLoginRetry())
            setEditState(!editState)
        }
    }, [LoginRetryState])

    const handleEditState = () => {
        setEditState(!editState)
    }

    const handleCancelEditState = () => {
        setTriggeredCancel(!triggeredCancel)
        setEditState(!editState)
    }

    const createLoginRetry = (data) => {
        if (createState) {
            const app_login_retry = { ...data }
            let value = { app_login_retry }
            let Dta = value.app_login_retry.login_retry
            let lengthNumber = Dta.length;
            let newDa = `retry_time_${lengthNumber + 1}`;
            let newData = { [newDa]: "99999" };
            Dta.push(newData)
            const newData2 = {
                login_retry: Dta
            }
            dispatch(postLoginRetry({ newData2 }))
        } else {
            const app_login_retry = { ...data }
            let value = { app_login_retry }
            let Dta = value.app_login_retry.login_retry
            let lengthNumber = Dta.length;
            let newDa = `retry_time_${lengthNumber + 1}`;
            let newData = { [newDa]: "99999" };
            Dta.push(newData)
            const newData2 = {
                login_retry: Dta
            }
            dispatch(updateLoginRetry({ newData2 }))
        }
    }


    

    // OTP time out 
    const [editStateOTP, setEditStateOTP] = useState(false)
    const [createStateOTP, setCreateStateOTP] = useState(true)
    const [triggeredCancelOTP, setTriggeredCancelOTP] = useState(false)

    const OtpRetryState = useSelector(state => state.OtpTimeouts)

    const handleEditStateOTP = () => {
        setEditStateOTP(!editStateOTP)
    }

    const handleCancelEditStateOTP = () => {
        setTriggeredCancelOTP(!triggeredCancelOTP)
        setEditStateOTP(!editStateOTP)
    }

    useEffect(() => {
        dispatch(getOtpTimeout())
    }, [triggeredCancelOTP])

    useEffect(() => {
        if (OtpRetryState.otpTimeout !== null) {
            setValueOTP('app_otp_timeout', OtpRetryState?.otpTimeout?.app_otp_timeout)
            setValueOTP('app_otp_max_attempt', OtpRetryState?.otpTimeout?.app_otp_max_attempt)
            const OTPRetryArray = OtpRetryState.otpTimeout.otp_retry;
            const updatedOTPRetryArray = OTPRetryArray.slice(0, OTPRetryArray.length - 1);
            setValueOTP('otp_retry', updatedOTPRetryArray);
            setCreateStateOTP(false)
        }
        if (OtpRetryState.isCreated) {
            toast.success("Otp Attempt Saved")
            setEditStateOTP(!editStateOTP)
            setCreateStateOTP(false)
            dispatch(getOtpTimeout())
        }
        else if (OtpRetryState.isUpdated) {
            toast.success("Otp Attempt Updated")
            setEditStateOTP(!editStateOTP)
            dispatch(getOtpTimeout())
        }
    }, [OtpRetryState])


    const createOtpLogin = (data) => {
        if (createStateOTP) {
            let Dta = data.otp_retry
            let newKey = `otp_retry_time_${Dta.length + 1}`;
            let newData = { [newKey]: "99999" };
            Dta.push(newData)
            let value = {
                otp_retry: Dta,
                app_otp_timeout: data.app_otp_timeout,
            }
            dispatch(postOtpTimeout({ value }))
        } else {
            let Dta = data.otp_retry
            let newKey = `otp_retry_time_${Dta.length + 1}`;
            let newData = { [newKey]: "99999" };
            Dta.push(newData)
            let value = {
                otp_retry: Dta,
                app_otp_timeout: data.app_otp_timeout,
            }
            dispatch(updateOtpTimeout({ value }))
        }
    }

    // password policy
    const [editStatePwd, setEditStatePwd] = useState(false)
    const [createStatePwd, setCreateStatePwd] = useState(true)
    const [triggeredCancelPwd, setTriggeredCancelPwd] = useState(false)

    const PasswordPolicyState = useSelector(state => state.PassWordPolicysReducer)

    const handleEditStatePwd = () => {
        setEditStatePwd(!editStatePwd)
    }

    const handleCancelEditStatePwd = () => {
        setTriggeredCancelPwd(!triggeredCancelPwd)
        setEditStatePwd(!editStatePwd)
    }

    useEffect(() => {
        dispatch(getPassWordPolicys())
    }, [triggeredCancelPwd])


    useEffect(() => {
        if (PasswordPolicyState.passwordPolicys !== null) {
            setValuePwdPolicy('app_pwd_min_length', PasswordPolicyState.passwordPolicys.app_pwd_min_length)
            setValuePwdPolicy('app_pwd_max_length', PasswordPolicyState.passwordPolicys.app_pwd_max_length)
            setValuePwdPolicy('app_pwd_uppercase', PasswordPolicyState.passwordPolicys.app_pwd_uppercase)
            setValuePwdPolicy('app_pwd_lowercase', PasswordPolicyState.passwordPolicys.app_pwd_lowercase)
            setValuePwdPolicy('app_pwd_numeric', PasswordPolicyState.passwordPolicys.app_pwd_numeric)
            setValuePwdPolicy('app_pwd_special', PasswordPolicyState.passwordPolicys.app_pwd_special)
            setCreateStatePwd(false)
        }
        if (PasswordPolicyState.isCreated) {
            toast.success("Password Saved")
            setEditStatePwd(!editStatePwd)
            setCreateStatePwd(false)
            dispatch(getPassWordPolicys())
        }
        else if (PasswordPolicyState.isUpdated) {
            toast.success("Password Updated")
            setEditStatePwd(!editStatePwd)
            dispatch(getPassWordPolicys())
        }
    }, [PasswordPolicyState])


    const createPwdPolicy = (data) => {
        if (createStatePwd) {
            dispatch(postPassWordPolicy({ data }))
        } else {
            dispatch(updatePassWordPolicy({ data }))
        }
    }

    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const CreatePermission = (permissionAction.includes("system.settings.security.write")) ? true : false
    const UpdatePermission = (permissionAction.includes("system.settings.security.write")) ? true : false
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Securities" />
                {permissions.includes("system.settings.security.read") ? <Container fluid>
                    <Row>
                        <Col lg="6" md="6" sm="12" xs="12" >
                            <Card>
                                <Form onSubmit={handleSubmit(createLoginRetry)}>
                                    <Row className="m-2 mb-4">
                                        <Col className="text" lg="8" md="8" sm="8" xs="10">
                                            <h4>Password Retry Attempt</h4>
                                        </Col>
                                        <Col className="text-end" lg="4" md="4" sm="4" xs="2">


                                            {/* {CreatePermission && (!editState ?
                                                <Button type="button" size="sm" onClick={handleEditState} color="info">Edit </Button>
                                                :
                                                <Button type="button" size="sm" onClick={handleCancelEditState} color="danger">Close</Button>)}

                                            {UpdatePermission && (!editState ?
                                                <Button type="button" size="sm" onClick={handleEditState} color="info">Edit </Button>
                                                :
                                                <Button type="button" size="sm" onClick={handleCancelEditState} color="danger">Close</Button>)}  */}



                                            {(CreatePermission && UpdatePermission) &&
                                                <Button type="button" size="sm" onClick={!editState ? handleEditState : handleCancelEditState} color={!editState ? "info" : "danger"}>
                                                    {!editState ? "Edit" : "Close"}
                                                </Button>
                                            }

                                            {UpdatePermission && (CreatePermission && !UpdatePermission &&
                                                <Button type="button" size="sm" onClick={!editState ? handleEditState : handleCancelEditState} color={!editState ? "info" : "danger"}>
                                                    {!editState ? "Edit" : "Close"}
                                                </Button>
                                            )}

                                            {CreatePermission && (UpdatePermission && !CreatePermission &&
                                                <Button type="button" size="sm" onClick={!editState ? handleEditState : handleCancelEditState} color={!editState ? "info" : "danger"}>
                                                    {!editState ? "Edit" : "Close"}
                                                </Button>
                                            )}




                                            {/* {(CreatePermission && UpdatePermission) ? "" :
                                                location.pathname === '/securities' && CreatePermission && createState && (!editState ?
                                                    <Button type="button" size="sm" onClick={handleEditState} color="info">Edits</Button>
                                                    :
                                                    <Button type="button" size="sm" onClick={handleCancelEditState} color="danger">Cancel</Button>
                                                )}

                                            {(CreatePermission && UpdatePermission) ? "" :
                                                location.pathname === '/securities' && UpdatePermission && (!editState ?
                                                    <Button type="button" size="sm" onClick={handleEditState} color="info">Edit</Button>
                                                    :
                                                    <Button type="button" size="sm" onClick={handleCancelEditState} color="danger">Cancel</Button>
                                                )}

                                            {location.pathname === '/securities' && (CreatePermission && UpdatePermission) && (!editState ?
                                                <Button type="button" size="sm" onClick={handleEditState} color="info">Edit</Button>
                                                :
                                                <Button type="button" size="sm" onClick={handleCancelEditState} color="danger">Cancel</Button>
                                            )}  */}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="text-lg-end text-md-end text-sm-end text-end pe-3" lg="4" md="4" sm="4" xs="4">
                                            <h5>Attempt</h5>
                                        </Col>
                                        <Col className="text-lg-between text-md-between text-between text-between" lg="4" md='4' sm="4" xs="8">
                                            <h5>Timing (Minutes)</h5>
                                        </Col>
                                    </Row>
                                    {fieldsLogin.map((item, index) => (
                                        <Row key={item.id} className="mb-2">
                                            <Col className="text-lg-end text-md-end text-sm-end text-end" lg="4" md="4" sm="4" xs="4">
                                                <span>{`Attempt ${index + 1} :`}</span>
                                            </Col>
                                            <Col className="text-center" lg="2" md="3" sm="2" xs="3">
                                                <input
                                                    size="1"
                                                    maxLength="4"
                                                    readOnly={!editState}
                                                    style={!editState ? { backgroundColor: "#D2D4D1" } : {}}
                                                    className="form form-control"
                                                    {...register(`login_retry.${index}.retry_time_${index + 1}`)}
                                                />
                                            </Col>
                                            {fieldsLogin.length > 3 && editState && (
                                                <Col className="text-center" lg="1" md="2" sm="1" xs="2">
                                                    <button className="btn btn-danger" type="button" onClick={() => removeLogin(index)}>
                                                        <i className='bx bxs-trash-alt'></i>
                                                    </button>
                                                </Col>
                                            )}
                                            {editState && index === fieldsLogin.length - 1 && (
                                                <Col className="text-center" lg="1" md="2" sm="2" xs="2">
                                                    <button
                                                        disabled={!editState}
                                                        type="button"
                                                        className="btn btn-success"
                                                        onClick={() => appendLogin()}
                                                    >
                                                        +
                                                    </button>
                                                </Col>)}
                                        </Row>
                                    ))}
                                    <Row className="mt-3">
                                        <Col lg="4" md="4" sm="4" xs="4">
                                        </Col>
                                        <Col className="d-flex justify-content-start" lg="2" md="2" sm="2" xs="2">
                                            <FormGroup>
                                                {editState &&
                                                    <Button color="success" type="submit">{createState ? "Create" : "Update"}</Button>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                            <Card>
                                <Form onSubmit={handleSubmitPwdPolicy(createPwdPolicy)}>
                                    <Row className="ms-3 mt-3 me-3">
                                        <Col className="text" lg="8" md="8" sm="8" xs="10">
                                            <h4>Password Policy</h4>
                                        </Col>
                                        <Col className="text-end" lg="4" md="4" sm="4" xs="2">

                                        {(CreatePermission && UpdatePermission) &&
                                                <Button type="button" size="sm" onClick={!editStatePwd ? handleEditStatePwd : handleCancelEditStatePwd} color={!editStatePwd ? "info" : "danger"}>
                                                    {!editStatePwd ? "Edit" : "Close"}
                                                </Button>
                                            }

                                            {UpdatePermission && (CreatePermission && !UpdatePermission &&
                                                <Button type="button" size="sm" onClick={!editStatePwd ? handleEditStatePwd : handleCancelEditStatePwd} color={!editStatePwd ? "info" : "danger"}>
                                                    {!editStatePwd ? "Edit" : "Close"}
                                                </Button>
                                            )}

                                            {CreatePermission && (UpdatePermission && !CreatePermission &&
                                                <Button type="button" size="sm" onClick={!editStatePwd ? handleEditStatePwd : handleCancelEditStatePwd} color={!editStatePwd ? "info" : "danger"}>
                                                    {!editStatePwd ? "Edit" : "Close"}
                                                </Button>
                                            )}

                                            {/* {!editStatePwd ?
                                                <Button type="button" size="sm" onClick={handleEditStatePwd} color="info">Edit</Button>
                                                :
                                                <Button type="button" size="sm" onClick={handleCancelEditStatePwd} color="danger">Close</Button>} */}

                                            {/* {(CreatePermission && UpdatePermission) ? "" :
                                                location.pathname === '/securities' && CreatePermission && createStatePwd && (!editStatePwd ?
                                                    <Button type="button" size="sm" onClick={handleEditStatePwd} color="info">Edits</Button>
                                                    :
                                                    <Button type="button" size="sm" onClick={handleCancelEditStatePwd} color="danger">Cancel</Button>
                                                )}

                                            {(CreatePermission && UpdatePermission) ? "" :
                                                location.pathname === '/securities' && UpdatePermission && (!editStatePwd ?
                                                    <Button type="button" size="sm" onClick={handleEditStatePwd} color="info">Edit</Button>
                                                    :
                                                    <Button type="button" size="sm" onClick={handleCancelEditStatePwd} color="danger">Cancel</Button>
                                                )}

                                            {location.pathname === '/securities' && (CreatePermission && UpdatePermission) && (!editStatePwd ?
                                                <Button type="button" size="sm" onClick={handleEditStatePwd} color="info">Edit</Button>
                                                :
                                                <Button type="button" size="sm" onClick={handleCancelEditStatePwd} color="danger">Cancel</Button>
                                            )} */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row className="align-items-end">
                                                <Label lg="4" md="7" sm="7" xs="7" className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end   justify-content-end">Password Min length : </Label>
                                                <Col lg="2" md="3" sm="3" xs="4">
                                                    <input readOnly={!editStatePwd} style={!editStatePwd ? { backgroundColor: "#D2D4D1" } : {}} type="number" className='form form-control mt-1' {...registerPwdPolicy(`app_pwd_min_length`)} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row className="align-items-end">
                                                <Label lg="4" md="7" sm="7" xs="7" className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end  justify-content-end">Password Max length : </Label>
                                                <Col lg="2" md="3" sm="3" xs="4">
                                                    <input readOnly={!editStatePwd} style={!editStatePwd ? { backgroundColor: "#D2D4D1" } : {}} type="number" className='form form-control mt-1' {...registerPwdPolicy(`app_pwd_max_length`)} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row className="align-items-end">
                                                <Label lg="4" md="7" sm="7" xs="7" className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-end"> Uppercase  : </Label>
                                                <Col lg="2" md="3" sm="3" xs="4">
                                                    <input readOnly={!editStatePwd} style={!editStatePwd ? { backgroundColor: "#D2D4D1" } : {}} type="number" className='form form-control mt-1' {...registerPwdPolicy(`app_pwd_uppercase`)} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row className="align-items-end">
                                                <Label lg="4" md="7" sm="7" xs="7" className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-end"> Lowercase  : </Label>
                                                <Col lg="2" md="3" sm="3" xs="4">
                                                    <input readOnly={!editStatePwd} style={!editStatePwd ? { backgroundColor: "#D2D4D1" } : {}} type="number" className='form form-control mt-1' {...registerPwdPolicy(`app_pwd_lowercase`)} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row className="align-items-end">
                                                <Label lg="4" md="7" sm="7" xs="7" className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-end"> Numeric  : </Label>
                                                <Col lg="2" md="3" sm="3" xs="4">
                                                    <input readOnly={!editStatePwd} style={!editStatePwd ? { backgroundColor: "#D2D4D1" } : {}} type="number" className='form form-control mt-1' {...registerPwdPolicy(`app_pwd_numeric`)} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row className="align-items-end">
                                                <Label lg="4" md="7" sm="7" xs="7" className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-end"> Special  : </Label>
                                                <Col lg="2" md="3" sm="3" xs="4">
                                                    <input readOnly={!editStatePwd} style={!editStatePwd ? { backgroundColor: "#D2D4D1" } : {}} type="number" className='form form-control mt-1' {...registerPwdPolicy(`app_pwd_special`)} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col lg="8" md="8" sm="8" xs="8" className='d-flex justify-content-center ms-5'>
                                            <FormGroup>
                                                {editStatePwd &&
                                                    <Button color="success" type="submit">{createStatePwd ? "Create" : "Update"}</Button>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <Card>
                                <Form onSubmit={handleSubmitOTP(createOtpLogin)}>
                                    <Row className="m-2 mb-4">
                                        <Col className="text" lg="8" md="8" sm="8" xs="8">
                                            <h4>OTP Retry Attempt & OTP Expiry Time</h4>
                                        </Col>
                                        <Col className="text-end" lg="4" md="4" sm="4" xs="4">

                                        {(CreatePermission && UpdatePermission) &&
                                                <Button type="button" size="sm" onClick={!editStateOTP ? handleEditStateOTP : handleCancelEditStateOTP} color={!editStateOTP ? "info" : "danger"}>
                                                    {!editStateOTP ? "Edit" : "Close"}
                                                </Button>
                                            }

                                            {UpdatePermission && (CreatePermission && !UpdatePermission &&
                                                <Button type="button" size="sm" onClick={!editStateOTP ? handleEditStateOTP : handleCancelEditStateOTP} color={!editStateOTP ? "info" : "danger"}>
                                                    {!editStateOTP ? "Edit" : "Close"}
                                                </Button>
                                            )}

                                            {CreatePermission && (UpdatePermission && !CreatePermission &&
                                                <Button type="button" size="sm" onClick={!editStateOTP ? handleEditStateOTP : handleCancelEditStateOTP} color={!editStateOTP ? "info" : "danger"}>
                                                    {!editStateOTP ? "Edit" : "Close"}
                                                </Button>
                                            )}

                                            {/* {!editStateOTP ?
                                                <Button type="button" size="sm" onClick={handleEditStateOTP} color="info">Edit</Button>
                                                :
                                                <Button type="button" size="sm" onClick={handleCancelEditStateOTP} color="danger">Close</Button>} */}

                                            {/* {(CreatePermission && UpdatePermission) ? "" :
                                                location.pathname === '/securities' && CreatePermission && createStateOTP && (!editStateOTP ?
                                                    <Button type="button" size="sm" onClick={handleEditStateOTP} color="info">Edits</Button>
                                                    :
                                                    <Button type="button" size="sm" onClick={handleCancelEditStateOTP} color="danger">Cancel</Button>
                                                )}

                                            {(CreatePermission && UpdatePermission) ? "" :
                                                location.pathname === '/securities' && UpdatePermission && (!editStateOTP ?
                                                    <Button type="button" size="sm" onClick={handleEditStateOTP} color="info">Edit</Button>
                                                    :
                                                    <Button type="button" size="sm" onClick={handleCancelEditStateOTP} color="danger">Cancel</Button>
                                                )}

                                            {location.pathname === '/securities' && (CreatePermission && UpdatePermission) && (!editStateOTP ?
                                                <Button type="button" size="sm" onClick={handleEditStateOTP} color="info">Edit</Button>
                                                :
                                                <Button type="button" size="sm" onClick={handleCancelEditStateOTP} color="danger">Cancel</Button>
                                            )} */}
                                        </Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col>
                                            <FormGroup row className="align-items-center">
                                                <Label lg="4" md="4" sm="4" xs="4" className=" ms-4 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-end">OTP Expiry Time (Mins) :</Label>
                                                <Col lg="2" md="2" sm="3" xs="3">
                                                    <input readOnly={!editStateOTP} style={!editStateOTP ? { backgroundColor: "#D2D4D1" } : {}} type="number" className='form form-control mt-1' {...registerOTP(`app_otp_timeout`)} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="text-lg-end text-md-end text-sm-end text-end pe-3" lg="4" md="4" sm="4" xs="4" >
                                            <h5>Attempt</h5>
                                        </Col>
                                        <Col className="text-lg-between text-md-between text-sm-between text-between" lg="4" md='4' sm="4" xs="8">
                                            <h5>Timing (Minutes)</h5>
                                        </Col>
                                    </Row>
                                    {fieldsOTP.map((item, index) => (
                                        <Row key={item.id} className="mb-2">
                                            <Col className="text-lg-end text-md-end text-sm-end text-end" lg="4" md="4" sm="4" xs="4">
                                                <span>{`Attempt ${index + 1} :`}</span>
                                            </Col>
                                            <Col className="text-center" lg="2" md="3" sm="2" xs="3">
                                                <input
                                                    size="1"
                                                    maxLength="2"
                                                    readOnly={!editStateOTP}
                                                    style={!editStateOTP ? { backgroundColor: "#D2D4D1" } : {}}
                                                    className="form form-control"
                                                    {...registerOTP(`otp_retry.${index}.otp_retry_time_${index + 1}`)}
                                                />
                                            </Col>
                                            {fieldsOTP.length > 3 && editStateOTP && (
                                                <Col className="text-center" lg="1" md="2" sm="1" xs="2">
                                                    <button className="btn btn-danger" type="button" onClick={() => removeOTP(index)}>
                                                        <i className='bx bxs-trash-alt'></i>
                                                    </button>
                                                </Col>
                                            )}
                                            {editStateOTP && index === fieldsOTP.length - 1 && (
                                                <Col className="text-center" lg="1" md="2" sm="2" xs="2">
                                                    <button
                                                        disabled={!editStateOTP}
                                                        type="button"
                                                        className="btn btn-success"
                                                        onClick={() => appendOTP()}
                                                    >
                                                        +
                                                    </button>
                                                </Col>)}
                                        </Row>
                                    ))}
                                    <Row >
                                        <Col lg="8" md="8" sm="8" xs="8" className='d-flex justify-content-center ms-5'>
                                            <FormGroup>
                                                {editStateOTP &&
                                                    <Button color="success" type="submit">{createStateOTP ? "Create" : "Update"}</Button>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>}
            </div >
        </React.Fragment >
    )
}

export default Securities




