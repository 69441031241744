import { takeEvery, put, call } from "redux-saga/effects"
import {
    getOwnersSuccess, getOwnersFail,
    getOwnerSuccess, getOwnerFail,
    postOwnerSuccess, postOwnerFail,
    deleteOwnerSuccess, deleteOwnerFail,
    updateOwnerSuccess, updateOwnerFail,
} from "./actions"

// Api Handler 
import {
    getOwners,
    getOwner,
    postOwner,
    updateOwner,
    deleteOwner,

} from "helpers/backendApi_helpers";

import {
    GET_OWNERS,
    GET_OWNER,
    POST_OWNER,
    DELETE_OWNER,
    UPDATE_OWNER
} from "./actionTypes";

function* fetchOwners({ payload: payload }) {
    try {
        const response = yield call(getOwners, payload);
        yield put(getOwnersSuccess(response))
    } catch (error) {
        yield put(getOwnersFail(error))
    }
}

function* postAppOwner({ payload: payload }) {
    try {
        const response = yield call(postOwner, payload);
        yield put(postOwnerSuccess(response))
    } catch (error) {
        yield put(postOwnerFail(error))
    }
}

function* deleteAppOwner({ payload: payload }) {
    try {
        const response = yield call(deleteOwner, payload);
        yield put(deleteOwnerSuccess(response))
    } catch (error) {
        yield put(deleteOwnerFail(error))
    }
}

function* getAppOwner({ payload: payload }) {
    try {
        const response = yield call(getOwner, payload);
        yield put(getOwnerSuccess(response))
    } catch (error) {
        yield put(getOwnerFail(error))
    }
}

function* updateAppOwner({ payload: payload }) {
    try {
        const response = yield call(updateOwner, payload);
        yield put(updateOwnerSuccess(response))
    } catch (error) {
        yield put(updateOwnerFail(error))
    }
}

function* OwnerSaga() {
    yield takeEvery(GET_OWNERS, fetchOwners)
    yield takeEvery(POST_OWNER, postAppOwner)
    yield takeEvery(DELETE_OWNER, deleteAppOwner)
    yield takeEvery(GET_OWNER, getAppOwner)
    yield takeEvery(UPDATE_OWNER, updateAppOwner)
}

export default OwnerSaga

