import React, { useEffect, useMemo, useState } from "react"
import { Col, Card, CardBody, Nav, NavItem, NavLink, Table, Input, Label, TabContent, TabPane, Badge, Row } from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"

//Simple bar
import SimpleBar from "simplebar-react"
import { useDispatch, useSelector } from "react-redux"
import { getNotificationApprovalStatus, getNotificationPendingStatus, getOpportunitieses } from "store/actions"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TableContainer from "../TableContainer"
import TableContainer2 from "../TableContainer2"

const NotificationComp = props => {

    const [activeTab, setActiveTab] = useState("1");
    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const dispatch = useDispatch();
    const notificationStatusState = useSelector(state => state.Dashboard);

    const [approvalStatus, setApprovalStatus] = useState([])
    const [pendingStatus, setPendingStatus] = useState([])

    useEffect(() => {
        if (activeTab == "1") {
            dispatch(getNotificationPendingStatus());
        } else if (activeTab == "2") {
            dispatch(getNotificationApprovalStatus());
        }
    }, [activeTab])


    useEffect(() => {
        if (notificationStatusState?.notificationPendingStatus !== null) {
            setPendingStatus(notificationStatusState?.notificationPendingStatus?.result);
        }

        if (notificationStatusState?.notificationApprovalStatus !== null) {
            setApprovalStatus(notificationStatusState?.notificationApprovalStatus?.result);
        }
    }, [notificationStatusState])


    const columns = useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: (row, index) => index + 1,
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: ' ID',
                accessor: '',
                filterable: true,
                Cell: (cellProps) => {
                    return <span >

                        <Link to={`/viewinvoice/${cellProps?.row?.original?.finalize_proposal_id}`} className="text-dark text-decoration-underline">
                            {cellProps?.row?.original?.finalize_proposal_keyid}
                        </Link>
                        <Link to={`/custompricingModels?skip=${0}&&limit=${10}&&queryMultistatus=${"Pending"}`} className="text-dark text-decoration-underline">
                            {cellProps?.row?.original?.proposal_keyid}
                        </Link>

                    </span>;
                }
            },
            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Site',
                accessor: 'app_department_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Status',
                accessor: 'app_department_nasme',
                filterable: true,
                Cell: (cellProps) => {
                    return <span>  
                         { activeTab == "1" &&  <Badge className={"font-size-12 badge-soft-warning"} pill>
                        {cellProps?.row?.original?.invoice_approval_status ? cellProps?.row?.original?.invoice_approval_status : ""}
                        {cellProps?.row?.original?.approval_custom_pricingmodel_status ? cellProps?.row?.original?.approval_custom_pricingmodel_status : ""}
                        </Badge>}

                        { activeTab == "2" && <Badge className={"font-size-12 badge-soft-success"} pill>
                        {cellProps?.row?.original?.invoice_approval_status ? cellProps?.row?.original?.invoice_approval_status : ""}
                        {cellProps?.row?.original?.approval_custom_pricingmodel_status ? cellProps?.row?.original?.approval_custom_pricingmodel_status : ""}
                        </Badge>}
                    
                    </span>;
                }
            },

        ],
        [activeTab,pendingStatus, approvalStatus]
    );



    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Notifications</h4>

                    <Nav pills className="bg-light rounded">
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "1",
                                })}
                                onClick={() => {
                                    toggleTab("1")
                                }}
                            >
                                Pending
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({
                                    active: activeTab === "2",
                                })}
                                onClick={() => {
                                    toggleTab("2")
                                }}
                            >
                                Approved
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <div className="mt-4">
                        <SimpleBar style={{ maxHeight: "350px" }}>

                           {activeTab == "1" && <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer2
                                                columns={columns}
                                                data={pendingStatus}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>}
                            {activeTab == "2" && <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer2
                                                columns={columns}
                                                data={approvalStatus}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>}
                            {/* <div className="table-responsive">
                                <Table className="table table-nowrap align-middle table-hover mb-0">
                                    <tbody>
                                        <tr>
                                            <th className="text-start" style={{ width: "50px", borderRight: "1px solid #dee2e6" }}>S.No</th>
                                            <th className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>ID</th>
                                            <th className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>Company</th>
                                            <th className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>Site</th>
                                            <th className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>Status</th>
                                        </tr>
                                        {activeTab == "1" && pendingStatus.map((items, index) => (
                                            <tr key={index}>
                                                <td className="text-start" style={{ width: "50px", borderRight: "1px solid #dee2e6" }}>
                                                    <div>
                                                        <span>{index + 1}</span>
                                                    </div>
                                                </td>
                                                <td className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>
                                                    <h5 className="text-truncate font-size-14 mb-1">
                                                        <Link to={`/viewinvoice/${items?.finalize_proposal_id}`} className="text-dark text-decoration-underline">
                                                            {items?.finalize_proposal_keyid}
                                                        </Link>
                                                        <Link to={`/custompricingModels?skip=${0}&&limit=${10}&&queryMultistatus=${"Pending"}`} className="text-dark text-decoration-underline">
                                                            {items?.proposal_keyid}
                                                        </Link>
                                                    </h5>
                                                </td>
                                                <td style={{ borderRight: "1px solid #dee2e6" }}>
                                                    {items.app_business_name ? items.app_business_name : ""}
                                                </td>
                                                <td className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>
                                                    {items.app_department_name ? items.app_department_name : ""}
                                                </td>
                                                <td className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>
                                                    <Badge className={"font-size-12 badge-soft-warning"} pill>
                                                        {items.invoice_approval_status ? items.invoice_approval_status : ""}
                                                        {items.approval_custom_pricingmodel_status ? items.approval_custom_pricingmodel_status : ""}
                                                    </Badge>
                                                </td>
                                            </tr>
                                        ))}
                                        {activeTab == "2" && approvalStatus.map((items, index) => (
                                            <tr key={index}>
                                                <td className="text-start" style={{ width: "50px", borderRight: "1px solid #dee2e6" }}>
                                                    <div>
                                                        <span>{index + 1}</span>
                                                    </div>
                                                </td>
                                                <td className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>
                                                    <h5 className="text-truncate font-size-14 mb-1">
                                                        <Link to={`/viewinvoice/${items?.finalize_proposal_id}`} className="text-dark text-decoration-underline">
                                                            {items?.finalize_proposal_keyid}
                                                        </Link>
                                                        <Link to={`/custompricingModels?skip=${0}&&limit=${10}&&queryMultistatus=${"Approved"}`} className="text-dark text-decoration-underline">
                                                            {items?.proposal_keyid}
                                                        </Link>
                                                    </h5>
                                                </td>
                                                <td className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>
                                                    {items.app_business_name ? items.app_business_name : ""}
                                                </td>
                                                <td className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>
                                                    {items.app_department_name ? items.app_department_name : ""}
                                                </td>
                                                <td className="text-start" style={{ borderRight: "1px solid #dee2e6" }}>
                                                    <Badge className={"font-size-12 badge-soft-success"} pill>
                                                        {items.invoice_approval_status ? items.invoice_approval_status : ""}
                                                        {items.approval_custom_pricingmodel_status ? items?.approval_custom_pricingmodel_status : ""}
                                                    </Badge>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </div> */}
                        </SimpleBar>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default NotificationComp







