import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Badge } from 'reactstrap'
import axios from 'axios';
import getHeader from 'config/header';
import { useState } from 'react';
import { useEffect } from 'react';

const ID = (cell) => {
    return cell.value ? cell.value : '';
};

const Name = (cell) => {
    return cell.value ? cell.value : '';
};

const LoginEmail = (cell) => {
    return cell.value ? cell.value : '';
};

const Password = (cell) => {
    return cell.value ? cell.value : '';
};


const UserRoleName = (cell) => {
    let Data =  cell?.value
    let Dta = Data?.map((item) => item?.sys_role_name)
    return Dta ? Dta?.join(" , ") : ''
};

const Status = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

const CreatedDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';
};

const LastUpdateDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';
};

const CreatedByUser = (cell) => {
    return cell.value ? cell.value : '';
};

const LastUpdateByUser = (cell) => {
    return cell.value ? cell.value : '';
};


export {
    Name,
    ID,
    LoginEmail,
    Password,
    UserRoleName,
    Status,
    CreatedDate,
    LastUpdateByUser,
    LastUpdateDate,
    CreatedByUser
};