import axios from 'axios';
import getHeader from 'config/header';
import { API_BASE_URL, BUSINESS_URL, DEPARTMENT_URL } from 'helpers/url_helper';
import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';


const CreatedDate = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    
    if (cell.value === "Pending Approval") {
        return (
            <Badge
                className={"font-size-12 badge-soft-" +
                    (cell.value === "Pending Approval" ? "warning" : "")}
                pill
            >
                {cell.value}
            </Badge>
        );
    }
    else if (cell.value === "Approved") {
        return (
            <Badge
                className={"font-size-12 badge-soft-" +
                    (cell.value === "Approved" ? "success" : "")}
                pill
            >
                {cell.value}
            </Badge>
        );
    }
    else if (cell.value === "Cancel" ) {
        return (
            <Badge
                className={"font-size-12 badge-soft-" +
                    (cell.value === "Cancel" ? "danger" : "")}
                pill
            >
                {cell.value}
            </Badge>
        );
    }
    else if (cell.value === "Rejected" ) {
        return (
            <Badge
                className={"font-size-12 badge-soft-" +
                    (cell.value === "Rejected" ? "danger" : "")}
                pill
            >
                {cell.value}
            </Badge>
        );
    } {
        return cell.value ? cell.value : '';
    }

};

const OrderID = (cell) => {


    if (cell?.Opportunitiesdata?.cloned_opportunity) {
        return cell.value ? (
            <span>
                {cell.value} | {' '} <strong> Cloned From [ {cell?.Opportunitiesdata?.source_opportunity_keyid} ] </strong>
            </span>
        ) : '';
    } else {
        return cell.value ? cell.value : '';
    }

};

const Company = (cell) => {
    // const [business, setBusiness] = useState()
    // useEffect(() => {
    //     if (cell.value) {
    //         const token = sessionStorage.getItem('authUser')
    //         axios.get(API_BASE_URL + BUSINESS_URL + "/" + cell.value, getHeader(token))
    //             .then(res => {
    //                 // console.log(res.data);
    //                 setBusiness(res.data.app_business_name)
    //             }).catch(err => {
    //                 console.log(err);
    //             })
    //     }
    // }, [])
    // return business ? business : '';

    return cell.value ? cell.value : '';
};

const Site = (cell) => {
    // const [site, setSite] = useState()
    // useEffect(() => {
    //     if (cell.value) {
    //         const token = sessionStorage.getItem('authUser')
    //         axios.get(API_BASE_URL + DEPARTMENT_URL + "/" + cell.BusId + '/' + cell.value, getHeader(token))
    //             .then(res => {
    //                 setSite(res.data.app_department_name)
    //             }).catch(err => {
    //                 console.log(err);
    //             })
    //     }
    // }, [])
    // return site ? site : '';

    return cell.value ? cell.value : '';
};

const POAmount = (cell) => {
    let floatValue = parseFloat(cell.value);
    if (isNaN(floatValue)) return null;
    floatValue = floatValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (
        <span className='ListPageFloatRight'> {"$" +  " " + floatValue} </span>
    );
};

const NO = (cell) => {
    return cell.value ? cell.value : '';
};

const BusinessUnit = (cell) => {
    return cell.value ? cell.value : '';
};

const Product = (cell) => {
    return cell.value ? cell.value : '';
};

const OriginalPrice = (cell) => {
    return cell.value ? cell.value : '';
};

const UnitPrice = (cell) => {
    return cell.value ? cell.value : '';
};

const Qty = (cell) => {
    return cell.value ? cell.value : '';
};

const DiscountCode = (cell) => {
    return cell.value ? cell.value : '';
};

const DiscountValue = (cell) => {
    return cell.value ? cell.value : '';
};

const SubTotal = (cell) => {
    return cell.value ? cell.value : '';
};

//close
const ProductkeyID = (cell) => {
    return cell.value ? cell.value : "";
    // return cell.value ? cell.value + " | " + cell.datas.app_product_payment_code + "  | " + cell.datas.app_product_name : '';
};

const Version = (cell) => {
    if (cell.statusdata.invoice_status === "Confirmed") {
        return cell.value ? (
            <span>
                {cell.value} ({cell.statusdata.proposal_status}) |{' '}
                <strong>{cell.statusdata.invoice_status} Invoice </strong>
            </span>
        ) : '';
    } else {
        return cell.value ? cell.value + " " + "(" + cell.statusdata.proposal_status + ")" : '';
    }


    // return cell.value ? cell.value + " | " + cell.datas.app_product_payment_code + "  | " + cell.datas.app_product_name : '';
};

const Date = (cell) => {
    return cell.value ? cell.value : '';
};

const CloseQuantity = (cell) => {
    return cell.value ? cell.value : '';
};

const Amount = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (<span className='ListPageFloatRight'> {floatValue ? "$" + " " + floatValue : ''} </span>);
    //  return (<span className='ListPageFloatRight'> {cell.value ?  cell.value : ''} </span>)
};

const Remarks = (cell) => {
    return cell.value ? cell.value : '';
};

const Quantities = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (<span className='ListPageFloatRight'>{floatValue} </span>);
}

const ApprovalStatus = (cell) => {
    return (
        <Badge
            className={"font-size-12 badge-soft-" +
                (cell.value === "Approved" ? "success" : "danger" && cell.value === "Pending" ? "warning" : "danger")}
            pill
        >
            {cell.value}
        </Badge>
    )
};

export {
    CreatedDate, OrderID, Company, Site, POAmount, Status,
    NO, BusinessUnit, Product, OriginalPrice, UnitPrice, Qty, DiscountCode, DiscountValue, SubTotal,
    Version, Date, CloseQuantity, Amount, Remarks, Quantities, ProductkeyID, ApprovalStatus
};

// const SellingPrice = (cell) => {
//     const floatValue = parseFloat(cell.value).toFixed(1);
//     return (<span className='ListPageFloatRight'> {floatValue ? " $ " + floatValue : ''} </span>);
// };