import React, { useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Table, CardBody } from "reactstrap"

import axios from 'axios';
import getHeader from 'config/header';
import StatusModal from 'components/Common/StatusModal'
import { useDispatch, useSelector } from 'react-redux'
import { getAppBusinessUnit, getBusinessProducts, getBusinessUnitDivisions, getSysUsers, updateAppBusinessUnits } from 'store/actions'
import { toast } from 'react-toastify'
import expiredSessionHandler from 'common/expiredSessionHandler'
import SpinnerModal from 'components/Common/SpinnerModal'
import * as url from "./../../../helpers/url_helper";
import TableContainer from 'components/Common/TableContainer'

let removedID = []
let currentDivisions

function ViewBusinessUnit() {

  let { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState(false)

  const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()

  const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
  const UpadatePermission = (permissionAction.includes("system.administration.businessunits.update")) ? false : true
  const DeletePermission = (permissionAction.includes("system.administration.businessunits.delete")) ? false : true
  const businessUnitSate = useSelector(state => state.AppBusinessUnits);

  const [businessUnitList, setBusinessUnitList] = useState([])
  useEffect(() => {
    if (!(businessUnitSate && businessUnitSate.appBusinessUnit)) return
    setBusinessUnitList(businessUnitSate)
  }, [businessUnitSate])

  const [sysUserList, setSysUserList] = useState([])
  const [buDivisionList, setBuDivisionList] = useState([])
  const [productList, setProductList] = useState([])
  const sysUserState = useSelector(state => state.sysUsersReducer)
  const divisionState = useSelector(state => state.BUDivisionReducer)
  const businessProductState = useSelector(state => state.BusinessProducts);

  useEffect(() => {
    dispatch(getSysUsers({ filter: `{"is_active":true}`, limit: 0, skip: 0 }))
    dispatch(getBusinessUnitDivisions({ filter: '{"is_active":true}', limit: 0, skip: 0 }));
    dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));
  }, [])

  useEffect(() => {
    if (sysUserState.sysUsers !== null) {
      setOptionGroupBusUnitUser()
      setselectedMultiBusUser([])
      setSysUserList(sysUserState.sysUsers.result);
    }
    if (divisionState.BUDivisions !== null) {
      setOptionGroupBuDivision()
      setselectedMultiBuDivision([])
      setBuDivisionList(divisionState.BUDivisions.result);
    }
    if (businessProductState.products !== null) {
      setOptionGroupProduct()
      setselectedMultiProduct([])
      setProductList(businessProductState.products.result);
    }
  }, [sysUserState, divisionState, businessProductState,])

  useEffect(() => {
    if (id) {
      const data = { businessUnitId: id }
      dispatch(getAppBusinessUnit(data))
    }
  }, [id])


  const [getDiv, setGetDiv] = useState([])
  const [getProd, setGetProd] = useState([])

  useEffect(() => {
    if (businessUnitSate.appBusinessUnit !== null && sysUserState.sysUsers !== null && divisionState.BUDivisions !== null && businessProductState.products !== null) {
      const res = businessUnitSate.appBusinessUnit;
      setValue("app_business_unit_name", res.app_business_unit_name)
      setValue("app_business_unit_keyid", res.app_business_unit_keyid)
      setValue("app_csms_branch_code", res.app_csms_branch_code)
      appBusUnitUserID(res.app_business_unit_user ? res.app_business_unit_user : [])
      appBuDivision(res.app_business_unit_division ? res.app_business_unit_division : [])
      appBuProduct(res.app_business_unit_product ? res.app_business_unit_product : [])
      if (res.is_active === true) {
        setValue("is_active", "active")
      } else if (res.is_active === false) {
        setValue("is_active", "inactive")
      }
    }

    if (businessUnitSate.isUpdated) {
      history.goBack()
    }

  }, [businessUnitSate, buDivisionList, productList, sysUserList])


  // Manage User MultiSelect
  const [selectedMultiBusUser, setselectedMultiBusUser] = useState([]);
  const [optionGroupBusUnitUser, setOptionGroupBusUnitUser] = useState([{ options: [] }])
  register('app_business_unit_user');
  const [busUnitUserList, setBusUnitUserList] = useState([])

  // manage bu division multiselect
  const [selectedMultiBuDivision, setselectedMultiBuDivision] = useState([]);
  const [optionGroupBuDivision, setOptionGroupBuDivision] = useState([{ options: [] }])
  register('app_business_unit_division');

  //  manage product multi select
  const [selectedMultiProduct, setselectedMultiProduct] = useState([]);
  const [optionGroupProduct, setOptionGroupProduct] = useState([{ options: [] }])
  register('app_business_unit_product');
  // const [productList, setProductList] = useState([])

  useEffect(() => {
    if (sysUserList) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in sysUserList) {
        // if (sysUserList[i].sys_user_role_name !== undefined) {
        //     for (let j in sysUserList[i].sys_user_role_name) {
        //         let data = {
        //             label: sysUserList[i].sys_user_keyid + " | " + sysUserList[i].sys_user_name + " | " + sysUserList[i].sys_user_role_name[j],
        //             value: sysUserList[i].sys_user_id
        //         }
        //         options.push(data)
        //     }
        // }
        let data = {
          label: sysUserList[i].sys_user_keyid + " | " + sysUserList[i].sys_user_name,
          value: sysUserList[i].sys_user_id
        }
        options.push(data)
      }

      setOptionGroupBusUnitUser(options)
    }

    if (buDivisionList) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in buDivisionList) {
        let data = {
          label: buDivisionList[i].app_business_unit_division_name,
          value: buDivisionList[i].app_division_id
        }
        options.push(data)
      }
      setOptionGroupBuDivision(options)
    }

    if (productList) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in productList) {
        let data = {
          label: productList[i].app_product_pos_item_code + " | " + productList[i].app_product_payment_code + " | " + productList[i].app_product_local_name,
          value: productList[i].app_product_id
        }
        options.push(data)
      }
      setOptionGroupProduct(options)
    }
  }, [sysUserList, buDivisionList, productList])

  const appBusUnitUserID = (Items) => {
    // console.log(Items);
    if (Items.length > 0) {
      let Data = Items.map((item) => {
        let sysuserlist = sysUserList.find(sysuserlist => sysuserlist.sys_user_id === item.value)
        if (sysuserlist) {
          return {
            label: sysuserlist.sys_user_name,
            value: sysuserlist.sys_user_id
          }
        }
      }).filter(Boolean)
      setselectedMultiBusUser(Data)
      setBusUnitUserList(Data)
      setValue("app_business_unit_user", Data)
    }
  }

  const appBuDivision = (Items) => {
    if (Items.length > 0 && buDivisionList) {
      let Data = Items.map((item) => {
        let buDivisionlist = buDivisionList.find(buDivisionlist => buDivisionlist.app_division_id === item.value)
        if (buDivisionlist) {
          return {
            label: buDivisionlist.app_business_unit_division_name,
            value: buDivisionlist.app_division_id
          }
        }
      }).filter(Boolean)
      setselectedMultiBuDivision(Data)
      currentDivisions = Data
      setValue("app_business_unit_division", Data)
    }
  }

  const appBuProduct = (Items) => {
    if (Items.length > 0 && productList) {
      let Data = Items.map((item) => {
        let productlist = productList.find(productlist => productlist.app_product_id === item.value)
        if (productlist) {
          return {
            label: productlist.app_product_pos_item_code + " | " + productlist.app_product_payment_code + " | " + productlist.app_product_local_name,
            value: productlist.app_product_id
          }
        }
      }).filter(Boolean)
      setselectedMultiProduct(Data)
      // setProductList(Data)
      setValue("app_business_unit_product", Data)
    }
  }

  const handlePostChange = (Data) => {
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values
    console.log("Data", Data);
    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroupBusUnitUser.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            setValue('app_business_unit_user', postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue('app_business_unit_user', postArray)
        }
      }
    });
    // managePostChangeData(busUnitUserList, postArray)
    setBusUnitUserList(postArray)
    setselectedMultiBusUser({
      sys_user_id: postArray,
    });
  };

  const handleChangeDivision = (Data) => {
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values
    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroupBuDivision.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            setValue('app_business_unit_division', postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue('app_business_unit_division', postArray)
        }
      }
    });
    // setBuDivisionList(postArray)
    setselectedMultiBuDivision({
      app_division_id: postArray,
    });
    // manageCurrentDivision(postArray)
  };

  // const manageCurrentDivision = (newDivisions) => {
  //   console.log("curr", currentDivisions);
  //   console.log("new", newDivisions);
  //   if(currentDivisions.length > newDivisions.length){
  //     for(let x of currentDivisions){
  //       if(!newDivisions.some(item => item.value === x.value)) removedID.push(x.value)
  //     }
  //   }
  //   console.log("RemID", removedID);
  // }

  const handleChangeProduct = (Data) => {
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values
    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroupProduct.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            console.log(postArray);
            setValue('app_business_unit_product', postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue('app_business_unit_product', postArray)
        }
      }
    });
    // setProductList(postArray)
    setselectedMultiProduct(postArray);
  };

  const [statusModal, setStatusModal] = useState(false)
  const [statusTBC, setStatusTBC] = useState()

  const [confirmStatus, setConfirmStatus] = useState()
  const statusChange = (e) => {
    setConfirmStatus(e.target.value)
    if (e.target.value === "active") {
      setStatusTBC(true)
      setStatusModal(true)
    } else if (e.target.value === "inactive") {
      setStatusTBC(false);
      setStatusModal(true)
    }
  }

  const handleStatus = () => {
    setValue("is_active", confirmStatus)
    setStatusModal(false)
  }

  const closeClick = () => {
    const value = getValues("is_active");
    if (value === "inactive") {
      let dta = "active"
      setValue("is_active", dta)
      setStatusModal(false)
      console.log("singleValue", dta);
    } else if (value === "active") {
      let dta = "inactive"
      setValue("is_active", dta)
      setStatusModal(false)
      console.log("singleValue", dta);
    }
  }

  const updatebusinessunits = (data) => {
    let value
    if (data.is_active === "active") {
      value = true
    } else if (data.is_active === "inactive") {
      value = false
    }


    let BusUnituser = data.app_business_unit_user
    let Dta = []
    for (let i in BusUnituser) {
      Dta.push(BusUnituser[i].value)
    }
    const newBusinessUnitData = {
      businessUnitId: id,
      ...data,
      // app_business_unit_user: Dta,
      is_active: value,
    }

    const updateData = {
      businessUnitId: id,
      updateData: newBusinessUnitData
    }

    dispatch(updateAppBusinessUnits(updateData));
    $('#createbusinessunits').attr("disabled", true);
  }

  const managePostChangeData = (oldData, newData) => {
    let newIDs = []
    for (let x of newData) {
      if (oldData.some(item => item.value === x.value)) {
        continue
      } else {
        newIDs.push(x.value)
      }
    }
    let newDataIDsTBP = []
    for (let x of newIDs) {
      newDataIDsTBP.push({
        app_business_unit_user_id: x,
        is_read: false,
        is_write: false
      })
    }
    let newBusinessUnitList = { ...businessUnitList }
    let userDataArray = newBusinessUnitList.appBusinessUnit.app_business_unit_user
    userDataArray = [...userDataArray, ...newDataIDsTBP]
    newBusinessUnitList.appBusinessUnit.app_business_unit_user = userDataArray
    setBusinessUnitList(newBusinessUnitList)
  }

  // const verifyChecked = (id, type) => {
  //   let checked = false
  //   if (!(businessUnitList && businessUnitList.appBusinessUnit && businessUnitList.appBusinessUnit.app_business_unit_user)) return
  //   let userRoleData = businessUnitList.appBusinessUnit.app_business_unit_user
  //   let [data] = userRoleData.filter(item => item.app_business_unit_user_id === id)
  //   if (data) {
  //     checked = data[type]
  //   }
  //   return checked
  // }

  // const handleChange = (id, type) => {
  //   let userRoleData = { ...businessUnitList }
  //   let index = userRoleData.appBusinessUnit.app_business_unit_user.findIndex(item => item.app_business_unit_user_id === id)
  //   if (index >= 0) {
  //     userRoleData.appBusinessUnit.app_business_unit_user[index][type] = !userRoleData.appBusinessUnit.app_business_unit_user[index][type]
  //     setBusinessUnitList(userRoleData)
  //   }
  // }

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#D2D4D1", // Change this to your desired background color
    }),
  };


  const removeSelectedProduct = (indexToRemove) => {
    const newData = selectedMultiProduct.filter((_, index) => index !== indexToRemove);
    setselectedMultiProduct(newData);
}

const columns2 = useMemo(
    () => [
        {
            Header: 'S.No',
            accessor: (row, index) => index + 1,
            filterable: true,
            Cell: (cellProps) => {
                return <span>{cellProps.value}</span>;
            }
        },
        {
            Header: 'Product Name',
            accessor: 'label',
            filterable: true,
            Cell: (cellProps) => {
                return <span>{cellProps.value}</span>;
            }
        },
       

    ],
    [selectedMultiProduct]
);

  return (
    <React.Fragment>
      <StatusModal
        show={statusModal}
        onDeleteClick={handleStatus}
        onCloseClick={closeClick}
        data={statusTBC}
      />
      <SpinnerModal
        show={spinner}
      />
      <div className="page-content">
        <Breadcrumbs title="View Business Unit" breadcrumbItem="Business Unit" />
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card body>
                <Form onSubmit={handleSubmit(updatebusinessunits)}>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Business Unit ID <span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('app_business_unit_keyid', { required: true })} />
                        {errors.app_business_unit_keyid && <p style={{ color: "red" }}>This Field is required</p>}
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Business Unit Name<span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('app_business_unit_name', { required: true })} />
                        {errors.app_business_unit_name && <p style={{ color: "red" }}>This Field is required</p>}
                      </Col>
                    </FormGroup>
                  </Row>

                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >CSMS Branch Code<span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control'  {...register('app_csms_branch_code', { required: true })} />
                        {errors.app_csms_branch_code && <p style={{ color: "red" }}>This Field is required</p>}
                      </Col>
                    </FormGroup>
                  </Row>

                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                        BU Division
                      </Label>
                      <Col lg="4" md="6">

                        <Select
                          isDisabled
                          styles={customStyles}
                          value={selectedMultiBuDivision.length > 0 ? selectedMultiBuDivision : selectedMultiBuDivision.app_division_id}
                          isMulti={true}
                          onChange={handleChangeDivision}
                          options={optionGroupBuDivision}
                          name={"app_division_id"}
                          className="modal__input"
                        />


                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                        Products
                      </Label>
                      <Col lg="4" md="6">

                        <Select
                          isDisabled
                          styles={customStyles}
                          value={selectedMultiProduct.length > 0 ? selectedMultiProduct : selectedMultiProduct}
                          isMulti={true}
                          onChange={handleChangeProduct}
                          options={optionGroupProduct}
                          name={"app_product_id"}
                          className="modal__input"
                        />

                      </Col>
                    </FormGroup>
                  </Row>
                  {selectedMultiProduct.length > 0 ? <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <TableContainer
                            columns={columns2}
                            data={selectedMultiProduct}
                            isGlobalFilter={true}
                            isTwoColumnTable={true}
                            isthemeGlobalFilter={true}
                            className="custom-header-css"
                            // customPageCount={pageCount}
                            customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row> : ""}
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                        Users
                      </Label>
                      <Col lg="4" md="6">

                        <Select
                          isDisabled
                          styles={customStyles}
                          value={selectedMultiBusUser.length > 0 ? selectedMultiBusUser : selectedMultiBusUser.sys_user_id}
                          isMulti={true}
                          onChange={handlePostChange}
                          options={optionGroupBusUnitUser}
                          name={"sys_user_id"}
                          className="modal__input"
                        />

                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                      <Col lg="4" md="6">
                        <select style={{ backgroundColor: "#D2D4D1" }} disabled onInput={(e) => { statusChange(e) }} className="form-select d-inline" {...register('is_active')}>
                          <option hidden value="">Select Status</option>
                          <option value="active">ACTIVE</option>
                          <option value="inactive">INACTIVE</option>
                        </select>
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                      <Col lg="5" md="6">
                        <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                          Back
                        </Button>
                      </Col>
                    </FormGroup>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewBusinessUnit
