import {
  GET_OPPORTUNITIES,
  GET_OPPORTUNITIES_SUCCESS,
  GET_OPPORTUNITIES_FAIL,

  POST_OPPORTUNITIES,
  POST_OPPORTUNITIES_SUCCESS,
  POST_OPPORTUNITIES_FAIL,

  DELETE_OPPORTUNITIES,
  DELETE_OPPORTUNITIES_SUCCESS,
  DELETE_OPPORTUNITIES_FAIL,

  GET_OPPORTUNITIES_DETAIL_SUCCESS,
  GET_OPPORTUNITIES_DETAIL_FAIL,
  GET_OPPORTUNITIES_DETAIL,
  
  UPDATE_OPPORTUNITIES_SUCCESS,
  UPDATE_OPPORTUNITIES_FAIL,
  UPDATE_OPPORTUNITIES,
} from "./actionTypes";

const INIT_STATE = {
  opportunitieses: null,
  opportunities: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const AppOpportunitiesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OPPORTUNITIES:
      return {
        ...state,
        loading: true,
        opportunitieses: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        opportunitieses: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_OPPORTUNITIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
      case POST_OPPORTUNITIES:
        return {
          ...state,
          loading: true,
          opportunities: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case POST_OPPORTUNITIES_SUCCESS:
        return {
          ...state,
          loading: false,
          opportunities: action.payload,
          isCreated: true,
          isDeleted: false,
          isUpdated: false
        };
      case POST_OPPORTUNITIES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case DELETE_OPPORTUNITIES:
        return {
          ...state,
          loading: true,
          opportunities: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case DELETE_OPPORTUNITIES_SUCCESS:
        return {
          ...state,
          loading: false,
          opportunities: null,
          isCreated: false,
          isDeleted: true,
          isUpdated: false
        };
      case DELETE_OPPORTUNITIES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_OPPORTUNITIES_DETAIL:
        return {
          ...state,
          loading: true,
          opportunities: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_OPPORTUNITIES_DETAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          opportunities: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_OPPORTUNITIES_DETAIL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case UPDATE_OPPORTUNITIES:
          return {
            ...state,
            loading: true,
            opportunitieses: null,
            opportunities: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
      case UPDATE_OPPORTUNITIES_SUCCESS:
          return {
            ...state,
            loading: false,
            opportunities: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: true
          };
      case UPDATE_OPPORTUNITIES_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
    default:
      return state;
  }
};

export default AppOpportunitiesReducer;


