
import getHeader from 'config/header';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Badge } from 'reactstrap';
import axios from 'axios';
import { useState } from 'react';

const UserRoleId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const ContractsName = (cell) => {
    return cell.value ? cell.value : '';
};

const ContractsStatus = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

const ContractBusiness = (cell) => {
    const [companyName, setCompanyName] = useState()
    useEffect(() => {
        const token = sessionStorage.getItem('authUser')
      
    }, [])
    return companyName ? companyName : "" ;
}
const ContractsSite = (cell) => {
    const [site, setSite] = useState()
    useEffect(() => {
        const token = sessionStorage.getItem('authUser')
     
    }, [])


    return site ? site : "";
}
const ContractDescription = (cell) => {
    return cell.value ? cell.value : "";
}

const CreatedDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';

};



export {
    UserRoleId,
    ContractsName,
    ContractsStatus,
    ContractDescription,
    CreatedDate,
    ContractBusiness,
    ContractsSite

};