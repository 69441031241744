/* EVENTS */

// Get Industry
export const GET_GENERIC_INDUSTRY = "GET_GENERIC_INDUSTRY";
export const GET_GENERIC_INDUSTRY_SUCCESS = "GET_GENERIC_INDUSTRY_SUCCESS";
export const GET_GENERIC_INDUSTRY_FAIL = "GET_GENERIC_INDUSTRY_FAIL"

export const GET_GENERIC_INDUSTRY_ID = "GET_GENERIC_INDUSTRY_ID";
export const GET_GENERIC_INDUSTRY_ID_SUCCESS = "GET_GENERIC_INDUSTRY_ID_SUCCESS";
export const GET_GENERIC_INDUSTRY_ID_FAIL = "GET_GENERIC_INDUSTRY_ID_FAIL"

export const POST_GENERIC_INDUSTRY = "POST_GENERIC_INDUSTRY";
export const POST_GENERIC_INDUSTRY_SUCCESS = "POST_GENERIC_INDUSTRY_SUCCESS";
export const POST_GENERIC_INDUSTRY_FAIL = "POST_GENERIC_INDUSTRY_FAIL"
