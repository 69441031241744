import { toast } from "react-toastify";

const expiredSessionHandler = (history) => {
    toast.info("Session Expired. Please login again !")
    sessionStorage.removeItem("authUser");
    sessionStorage.removeItem("userCred");
    sessionStorage.removeItem("userName");
    // clearCacheData()
    history.push("/login");
}

const clearCacheData = () => {
    caches.keys()
        .then(names => names.forEach(name => caches.delete(name)))
}

export default expiredSessionHandler