import axios from 'axios';
import getHeader from 'config/header';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import login from 'store/auth/login/reducer';

const AccountID = (cell) => {
    return cell.value ? cell.value : '';
};
const Company = (cell) => {
    // let data = cell.value;
    // let companyname = data.map((item) => item.app_business_name);
    // return companyname ? companyname : "";

    return cell.value ? cell.value : '';
};

const SiteName = (cell) => {
    return cell.value ? cell.value : '';
};

const CreditLimit = (cell) => {
    let floatValue = parseFloat(cell.value);
    if (isNaN(floatValue)) return null;
    floatValue = floatValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (
        <span className='ListPageFloatRight'> {"$" + " " + floatValue} </span>
    );
    // const floatValue = parseFloat(cell.value).toFixed(1);
    // return (<span className='ListPageFloatRight'> {floatValue ? " $ " + floatValue : ''} </span>);
};

const AccountBalance = (cell) => {
    let floatValue = parseFloat(cell.value);
    if (isNaN(floatValue)) return null;
    floatValue = floatValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (
        <span className='ListPageFloatRight'> {"$" + " " +floatValue} </span>
    );
};

const OracleStatus = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

const Status = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

//Transaction list table
const TransactionId = (cell) => {
    return cell.value ? cell.value : '';
};

const TransactionDate = (cell) => {
    return cell.value ? cell.value : '';
};

const TransactionType = (cell) => {
    return cell.value ? cell.value : '';

};

const TransactionValue = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " +  " " + floatValue : ' '} </span>);
};

export {
    AccountID,
    Company,
    SiteName,
    CreditLimit,
    AccountBalance,
    OracleStatus,
    Status,
    TransactionId,
    TransactionDate,
    TransactionType,
    TransactionValue
};
