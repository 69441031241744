import {
    GET_PLATFORM_ACTIONS,
    GET_PLATFORM_ACTIONS_SUCCESS,
    GET_PLATFORM_ACTIONS_FAIL,
  } from "./actionTypes";
  
  /////////////////////////
  //  Get                //
  /////////////////////////
  
  export const getPlatformActions = (payload) => ({
    type: GET_PLATFORM_ACTIONS,
    payload: payload
  });
  
  export const getPlatformActionsSuccess = actions => ({
    type: GET_PLATFORM_ACTIONS_SUCCESS,
    payload: actions,
  });
  
  export const getPlatformActionsFail = error => ({
    type: GET_PLATFORM_ACTIONS_FAIL,
    payload: error,
  });
  