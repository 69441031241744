import { takeEvery, put, call } from "redux-saga/effects"
import {
    getPassWordPolicysSuccess, getPassWordPolicysFail,
    postPassWordPolicySuccess, postPassWordPolicyFail,
    updatePassWordPolicySuccess, updatePassWordPolicyFail,
} from "./actions"

// Api Handler 
import {
    getPassWordPolicys,
    postPassWordPolicy,
    updatePassWordPolicy,

} from "helpers/backendApi_helpers";


import {
    GET_PASSWORD_POLICYS,
    POST_PASSWORD_POLICY,
    UPDATE_PASSWORD_POLICY
} from "./actionTypes";




function* fetchPassWordPolicy({ payload: payload }) {
    try {
        const response = yield call(getPassWordPolicys, payload);
        yield put(getPassWordPolicysSuccess(response))
    } catch (error) {
        yield put(getPassWordPolicysFail(error))
    }
}

function* postPasswordPolicy({ payload: payload }) {
    try {
        const response = yield call(postPassWordPolicy, payload);
        yield put(postPassWordPolicySuccess(response))
    } catch (error) {
        yield put(postPassWordPolicyFail(error))
    }
}

function* updatePasswordPolicy({ payload: payload }) {
    try {
        const response = yield call(updatePassWordPolicy, payload);
        yield put(updatePassWordPolicySuccess(response))
    } catch (error) {
        yield put(updatePassWordPolicyFail(error))
    }
}

function* PasswordPolicysSaga() {
    yield takeEvery(GET_PASSWORD_POLICYS, fetchPassWordPolicy)
    yield takeEvery(POST_PASSWORD_POLICY, postPasswordPolicy)
    yield takeEvery(UPDATE_PASSWORD_POLICY, updatePasswordPolicy)
}

export default PasswordPolicysSaga


    