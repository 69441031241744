import {
  GET_APP_COUPON,
  GET_APP_COUPON_FAIL,
  GET_APP_COUPON_SUCCESS,

  GET_APP_COUPON_STATUS,
  GET_APP_COUPON_STATUS_SUCCESS,
  GET_APP_COUPON_STATUS_FAIL,

  GET_APP_COUPON_STATUS_BY_INVOICE_ID,
  GET_APP_COUPON_STATUS_BY_INVOICE_ID_SUCCESS,
  GET_APP_COUPON_STATUS_BY_INVOICE_ID_FAIL,


  GET_APP_REQUEST_COUPON_STATUS,
  GET_APP_REQUEST_COUPON_STATUS_SUCCESS,
  GET_APP_REQUEST_COUPON_STATUS_FAIL,

  POST_APP_COUPON,
  POST_APP_COUPON_FAIL,
  POST_APP_COUPON_SUCCESS,



} from "./actionTypes";


//////////////////////////////
//        Get Coupon        //
//////////////////////////////

export const getCoupon = (payload) => ({
  type: GET_APP_COUPON,
  payload: payload
});

export const getCouponSuccess = coupondata => ({
  type: GET_APP_COUPON_SUCCESS,
  payload: coupondata,
});

export const getCouponFail = error => ({
  type: GET_APP_COUPON_FAIL,
  payload: error,
});



//////////////////////////////
//    Get Coupon Status    //
//////////////////////////////

export const getCouponStatus = (payload) => ({
  type: GET_APP_COUPON_STATUS,
  payload: payload
});

export const getCouponStatusSuccess = coupondata => ({
  type: GET_APP_COUPON_STATUS_SUCCESS,
  payload: coupondata,
});

export const getCouponStatusFail = error => ({
  type: GET_APP_COUPON_STATUS_FAIL,
  payload: error,
});



export const getCouponStatusByInvoiceId = (payload) => ({
  type: GET_APP_COUPON_STATUS_BY_INVOICE_ID,
  payload: payload
});

export const getCouponStatusByInvoiceIdSuccess = coupondata => ({
  type: GET_APP_COUPON_STATUS_BY_INVOICE_ID_SUCCESS,
  payload: coupondata,
});

export const getCouponStatusByInvoiceIdFail = error => ({
  type: GET_APP_COUPON_STATUS_BY_INVOICE_ID_FAIL,
  payload: error,
});


//////////////////////////////
//    Get Coupon Request Status    //
//////////////////////////////

export const getRequestCouponStatus = (payload) => ({
  type: GET_APP_REQUEST_COUPON_STATUS,
  payload: payload
});

export const getRequestCouponStatusSuccess = requestcoupondata => ({
  type: GET_APP_REQUEST_COUPON_STATUS_SUCCESS,
  payload: requestcoupondata,
});

export const getRequestCouponStatusFail = error => ({
  type: GET_APP_REQUEST_COUPON_STATUS_FAIL,
  payload: error,
});


////////////////////////
//    Post Coupon    ///
////////////////////////

export const postCoupon = (payload) => ({
  type: POST_APP_COUPON,
  payload: payload
});

export const postCouponSuccess = coupondata => ({
  type: POST_APP_COUPON_SUCCESS,
  payload: coupondata,
});

export const postCouponFail = error => ({
  type: POST_APP_COUPON_FAIL,
  payload: error,
});




