import React, { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Col, FormGroup, Label, Modal, ModalBody, Row, Card, CardBody } from "reactstrap"
import TableContainer from "../TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getCoupon, getCouponStatus } from "store/actions";
import { ProductID, ProductName, PosItemCode, ProductType, OriginalPrice, UnitPrice, DiscountUnitPrice, FlatQuantity, RowTotal } from "../../Common/PricingModelComponent/CustomOpportunityListCol"



const CouponStatusModal = ({ data, show, onApproveClick, onCloseClick, name, title }) => {

  const location = useLocation()
  const dispatch = useDispatch()
  // const [requestData, setRequestData] = useState(data)
  const couponStatusState = useSelector(state => state.CouponReducer)


  // useEffect(() => {
  //   if (show) {
  //         let value = {
  //     "requestId": `${data}`
  //   }
  //   dispatch(getCouponStatus(value))
  //   }
  // }, [show])


  useEffect(() => {
    if (show) {
      let value = {
        "requestId": 'J56OS5UGZQDNNWX'
      }
      dispatch(getCouponStatus(value))
    }
  }, [show])


  const [couponStatus, setCouponStatus] = useState([])
  useEffect(() => {

    // console.log(couponStatusState);
    if (couponStatusState?.getcouponstatus !== null) {
      setCouponStatus(couponStatusState?.getcouponstatus
      )
    }
  }, [couponStatusState])



  const [downloadStatus, setDownloadStatus] = useState([
    {
      "item_code": "CHCN0000005",
      "payment_code": "87654321",
      "coupon_name": "测试券 20240206",
      "coupon_number": "202400001",
      "coupon_type": "Digital",
      "coupon_status": "XXXXX",
      "coupon_face_value": "ZZZZ",
      "last_update_date": "2024-02-06",
      "sold_date": "2024-02-01",
      "expiry_date": "2024-12-31",
    }, {
      "item_code": "CHCN0000005",
      "payment_code": "87654321",
      "coupon_name": "测试券 20240206",
      "coupon_number": "202400002",
      "coupon_type": "Digital",
      "coupon_status": "XXXXX",
      "coupon_face_value": "ZZZZZ",
      "last_update_date": "2024-02-06",
      "sold_date": "2024-02-01",
      "expiry_date": "2024-12-31",
    }, {
      "item_code": "CHCN0000005",
      "payment_code": "87654321",
      "coupon_name": "测试券 20240206",
      "coupon_number": "202400003",
      "coupon_type": "Digital",
      "coupon_status": "XXXXX",
      "coupon_face_value": "ZZZZZ",
      "last_update_date": "2024-02-06",
      "sold_date": "2024-02-01",
      "expiry_date": "2024-12-31",
    },
    // {
    //   "item_code": "CN0000098",
    //   "payment_code": "1000000646",
    //   "coupon_name": "CNY free tall size beverage voucher",
    //   "coupon_type": "Physical",
    //   "coupon_status": "YYYYY",
    //   "coupon_face_value": "0",
    //   "last_update_date": "2017-06-29",
    //   "sold_date": "2046-12-31",
    //   "expiry_date": "2046-12-31"
    // },
    // {
    //   "item_code": "CHCN0000004",
    //   "payment_code": "QQSJJSJ889",
    //   "coupon_name": "20230124测试用COUPON（update）",
    //   "coupon_type": "Digital",
    //   "coupon_status": "ZZZZZ",
    //   "coupon_face_value": "0",
    //   "last_update_date": "2024-02-06",
    //   "sold_date": "2024-01-24",
    //   "expiry_date": "2025-01-31"
    // }
  ])



  const dowmloadListColumns = useMemo(
    () => [
      {
        Header: 'S.No',
        accessor: (row, index) => index + 1,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Coupon Name',
        accessor: 'coupon_name',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'Item Code',
        accessor: 'item_code',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'Coupon Number',
        accessor: 'coupon_id',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'Coupon Type',
        accessor: 'coupon_type',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName {...cellProps} />;
        }
      },
      {
        Header: 'Coupon Status',
        accessor: 'coupon_status',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName {...cellProps} />;
        }
      },
      {
        Header: 'CouponFaceValue',
        accessor: 'coupon_face_value',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'Sold Date/Days',
        accessor: 'sold_date',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'Expiry Date/Days',
        accessor: 'expiry_date',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'Last Update Date',
        accessor: 'last_update_date',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },

    ], []
  );


  return (
    <Modal isOpen={show} backdrop="static" centered={true} style={{ maxWidth: '90%' }}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              {/* <h3 style={{ color: "#556EE6" }}> <span style={{ textTransform: "capitalize" }}>{name}</span></h3> */}
              <p className='font-size-18'>Coupon Status (CSMS) </p>
              <>

                <Row style={{ maxHeight: "500px", overflowY: "auto" }}>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <TableContainer
                          columns={dowmloadListColumns}
                          data={couponStatus}
                          isGlobalFilter={false}
                          className="custom-header-css"
                        // accountId={accountId}
                        //customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                        // isAddTransaction={true}
                        // customPageCount={pageCount}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              {/* <button
                type="button"
                className="btn btn-success ms-2"
                onClick={onApproveClick}
              >
                Approve
              </button> */}

              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

CouponStatusModal.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any
}

export default CouponStatusModal