import { takeEvery, put, call } from "redux-saga/effects"
import {
    getOtpTimeoutSuccess, getOtpTimeoutFail,
    postOtpTimeoutSuccess, postOtpTimeoutFail,
    updateOtpTimeoutSuccess, updateOtpTimeoutFail,
} from "./actions"

// Api Handler 
import {
    getOtpTimeout,
    postOtpTimeout,
    updateOtpTimeout,

} from "helpers/backendApi_helpers";


import {
    GET_OTP_TIMEOUT,
    POST_OTP_TIMEOUT,
    UPDATE_OTP_TIMEOUT
} from "./actionTypes";




function* postOtpTimeOut({ payload: payload }) {
    try {
        const response = yield call(postOtpTimeout, payload);
        yield put(postOtpTimeoutSuccess(response))
    } catch (error) {
        yield put(postOtpTimeoutFail(error))
    }
}

function* getOtpTimeOut({ payload: payload }) {
    try {
        const response = yield call(getOtpTimeout, payload);
        yield put(getOtpTimeoutSuccess(response))
    } catch (error) {
        yield put(getOtpTimeoutFail(error))
    }
}

function* updateOtpTimeOut({ payload: payload }) {
    try {
        const response = yield call(updateOtpTimeout, payload);
        yield put(updateOtpTimeoutSuccess(response))
    } catch (error) {
        yield put(updateOtpTimeoutFail(error))
    }
}

function* OtpTimeOutsSaga() {
    yield takeEvery(POST_OTP_TIMEOUT, postOtpTimeOut)
    yield takeEvery(GET_OTP_TIMEOUT, getOtpTimeOut)
    yield takeEvery(UPDATE_OTP_TIMEOUT, updateOtpTimeOut)
}

export default OtpTimeOutsSaga


    