import React, { useState } from "react"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import CreatePricingModelsApprovalList from "./modules/CreateApprovalPricingModel"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const PricingModelApprovals = () => {
    //meta title
    document.title = "PricingModels Approvals | Maxims B2B Portal";

    const query = useQuery();
    const history = useHistory()
    const tab = query.get("tab")
    const [activeTab, setactiveTab] = useState(1)

    const permissions = JSON.parse([sessionStorage.getItem("userCred")])


    return (
        <React.Fragment>
            {/* <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Pricing models Approvals" breadcrumbItem="Approvals" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="vertical-wizard wizard clearfix vertical">
                                        <div className="steps clearfix">
                                            <ul>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTab === 1,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === 1,
                                                        })}
                                                        onClick={() => {
                                                            setactiveTab(1)
                                                            history.push("pricingmodels?tab=1&&skip=0&&limit=10&&queryMultistatus=pending")
                                                        }}
                                                    >
                                                        <span className="number">1.</span>Create Pricing Models
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTab === 2,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab === 2,
                                                        })}
                                                        onClick={() => {
                                                            setactiveTab(2)
                                                            history.push("pricingmodels?tab=2&&skip=0&&limit=10&&queryMultistatus=pending")
                                                        }}
                                                    >
                                                        <span className="number">2.</span>{" "}
                                                        <span>Update PricingModels</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTab === 3,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={
                                                            (classnames({
                                                                active: activeTab === 3,
                                                            }),
                                                                "done")
                                                        }
                                                        onClick={() => {
                                                            setactiveTab(3)
                                                        }}
                                                    >
                                                        <span className="number">3.</span>InActive-PricingModels
                                                    </NavLink>
                                                </NavItem>

                                            </ul>
                                        </div>
                                        <div className="content clearfix">
                                            <TabContent
                                                activeTab={activeTab}
                                                className="body"
                                            >
                                                <TabPane tabId={1}>
                                                    <CreatePricingModelsApprovalList />
                                                </TabPane>

                                                 <TabPane tabId={2}>
                                                     <CompanySite />
                                                    
                                                </TabPane> 
                                                
                                                <TabPane tabId={3}>
                                                    <div>
                                                        <Form>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-namecard-input112">
                                                                            Name on Card
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-namecard-input112"
                                                                            placeholder="Enter Your Name on Card"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label>Credit Card Type</Label>
                                                                        <select className="form-select">
                                                                            <option>Select Card Type</option>
                                                                            <option>American Express</option>
                                                                            <option>Visa</option>
                                                                            <option>MasterCard</option>
                                                                            <option>Discover</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-cardno-input122">
                                                                            Credit Card Number
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-cardno-input122"
                                                                            placeholder="Enter Your Card Number"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-card-verification-input">
                                                                            Card Verification Number
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-card-verification-input"
                                                                            placeholder="Card Verification Number"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-expiration-input132">
                                                                            Expiration Date
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="basicpill-expiration-input132"
                                                                            placeholder="Card Expiration Date"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <div className="row justify-content-center">
                                                        <Col lg="6">
                                                            <div className="text-center">
                                                                <div className="mb-4">
                                                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                </div>
                                                                <div>
                                                                    <h5>Confirm Detail</h5>
                                                                    <p className="text-muted">
                                                                        If several languages coalesce, the grammar
                                                                        of the resulting
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </div>  
                                    <CreatePricingModelsApprovalList />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div> */}


            <div className="page-content">
                {permissions.includes("system.settings.approval.read") ? <Container fluid>
                    <Breadcrumbs title="Pricing models Approvals" breadcrumbItem="Approvals" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <CreatePricingModelsApprovalList />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
}

export default PricingModelApprovals



// import Breadcrumbs from "components/Common/Breadcrumb"
// import { Container, Row, Col, Card, CardBody } from "reactstrap"
// import Company from "./modules/Company"
// import React, { useState } from "react"
// import SysUser from "./modules/SysUser"
// import PricingModal from "./modules/PricingModal"

// function Approvals() {

//     const permissions = JSON.parse([sessionStorage.getItem("userCred")])


//     return (
//         <React.Fragment>
//             <div className="page-content">
//                 {permissions.includes("system.customers.business.approval" ) ?
//                     <Container fluid>
//                         <Breadcrumbs title="Approvals" />
//                        {/* {permissions.includes("system.customers.business.approval") && <Company />} */}
//                        <PricingModal/>
//                        {/* {permissions.includes("system.customers.business.approval") && <PricingModel/>} */}

//                         {/* <SysUser /> */}
//                     </Container>
//                     :
//                     <Container fluid>
//                         <Row>
//                             <Col xs="12">
//                                 <Card>
//                                     <CardBody>
//                                         <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
//                                             <div>
//                                                 <h1><i className='bx bxs-lock'></i></h1>
//                                                 <h3>Access Denied</h3>
//                                                 <div className="text-muted">You don't have permission to access this module</div>
//                                             </div>
//                                         </div>
//                                     </CardBody>
//                                 </Card>
//                             </Col>
//                         </Row>
//                     </Container>}
//             </div>
//         </React.Fragment>
//     )
// }
// export default Approvals
