import axios from 'axios';
import getHeader from 'config/header';
import moment from 'moment';
import { useEffect, useState } from 'react';

const ActivityID = (cell) => {
    return cell.value ? cell.value : '';
};

const ActivityType = (cell) => {
    return cell.value ? cell.value : '';
};

const Module = (cell) => {
    return cell.value ? cell.value : '';
};

const Reference = (cell) => {
    return cell.value ? cell.value : '';
};Reference

const UpdatedDate = (cell) => {
    // return cell.value ? moment(cell.value).format('DD MMM YYYY, HH:mm:ss') : '';
    return cell.value ? cell.value : '';
};



const UpdatedBy = (cell) => {
    return cell.value ? cell.value : '';
}


const CreatedDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';
};

const LastUpdateDate = (cell) => {
    return cell.value ? moment(cell.value).format('l') : '';
}

export {
    ActivityID,
    ActivityType,
    Module,
    CreatedDate,
    LastUpdateDate,
    UpdatedDate,
    UpdatedBy,
    Reference
};