import {
    GET_PROPOSAL_VERSION ,
    GET_PROPOSAL_VERSION_FAIL,
    GET_PROPOSAL_VERSION_SUCCESS,

    POST_PROPOSAL_VERSION,
    POST_PROPOSAL_VERSION_FAIL,
    POST_PROPOSAL_VERSION_SUCCESS,

    GET_PROPOSAL_VERSION_DETAIL,
    GET_PROPOSAL_VERSION_DETAIL_FAIL,
    GET_PROPOSAL_VERSION_DETAIL_SUCCESS,

    UPDATE_PROPOSAL_VERSION,
    UPDATE_PROPOSAL_VERSION_FAIL,
    UPDATE_PROPOSAL_VERSION_SUCCESS,

    DELETE_PROPOSAL_VERSION,
    DELETE_PROPOSAL_VERSION_FAIL,
    DELETE_PROPOSAL_VERSION_SUCCESS,

    GET_PROPOSAL_BY_OPPORTUNITY_ID,
    GET_PROPOSAL_BY_OPPORTUNITY_ID_SUCCESS,
    GET_PROPOSAL_BY_OPPORTUNITY_ID_FAIL
  } from "./actionTypes";
  
  /////////////////////////
  //  Get LIST ProposalVersion  //
  /////////////////////////
  
  export const getProposalVersion = (payload) => ({
    type: GET_PROPOSAL_VERSION,
    payload: payload
  });
  
  export const getProposalVersionSuccess = Proposalversion => ({
    type: GET_PROPOSAL_VERSION_SUCCESS,
    payload: Proposalversion,
  });

  export const getProposalVersionFail = error => ({
    type: GET_PROPOSAL_VERSION_FAIL,
    payload: error,
  });
  
  //////////////////////////////
  // Get ID ProposalVersion  //
  //////////////////////////////
  
  export const getProposalVersionDetail = (payload) => ({
    type: GET_PROPOSAL_VERSION_DETAIL,
    payload: payload
  });
  
  export const getProposalVersionDetailSuccess = Proposalversions => ({
    type: GET_PROPOSAL_VERSION_DETAIL_SUCCESS,
    payload: Proposalversions,
  });
  
  export const getProposalVersionDetailFail = error => ({
    type: GET_PROPOSAL_VERSION_DETAIL_FAIL,
    payload: error,
  });
  
  ////////////////////////
  // Post ProposalVersion///
  ////////////////////////
  
  export const postProposalVersion = (payload) => ({
    type: POST_PROPOSAL_VERSION,
    payload: payload
  });
  
  export const postProposalVersionSuccess = Proposalversion => ({
    type: POST_PROPOSAL_VERSION_SUCCESS,
    payload: Proposalversion,
  });
  
  export const postProposalVersionFail = error => ({
    type: POST_PROPOSAL_VERSION_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Delete ProposalVersion //
  //////////////////////////
  
  export const deleteProposalVersion = (payload) => ({
    type: DELETE_PROPOSAL_VERSION,
    payload: payload
  });
  
  export const deleteProposalVersionSuccess = () => ({
    type: DELETE_PROPOSAL_VERSION_SUCCESS,
  });
  
  export const deleteProposalVersionFail = error => ({
    type: DELETE_PROPOSAL_VERSION_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Update ProposalVersion  //
  ///////////////////////////
  
  export const updateProposalVersion= (payload) => ({
    type: UPDATE_PROPOSAL_VERSION,
    payload: payload
  });
  
  export const updateProposalVersionSuccess = payload => ({
    type: UPDATE_PROPOSAL_VERSION_SUCCESS,
    payload: payload
  });
  
  export const updateProposalVersionFail = error => ({
    type: UPDATE_PROPOSAL_VERSION_FAIL,
    payload: error,
  });
  

    ///////////////////////////
  // Get proposal by opportunity id  //
  ///////////////////////////
  
  export const getProposalbyOpportunityId = (payload) => ({
    type: GET_PROPOSAL_BY_OPPORTUNITY_ID,
    payload: payload
  });
  
  export const getProposalbyOpportunityIdSuccess = payload => ({
    type: GET_PROPOSAL_BY_OPPORTUNITY_ID_SUCCESS,
    payload: payload
  });
  
  export const getProposalbyOpportunityIdFail = error => ({
    type: GET_PROPOSAL_BY_OPPORTUNITY_ID_FAIL,
    payload: error,
  });


  
  
  