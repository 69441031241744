import { takeEvery, put, call } from "redux-saga/effects"
import { getSysUserProductSuccess, getSysUserProductFail, } from "./actions"
import { getSysUserProduct } from "helpers/backendApi_helpers";
import { GET_SYS_USER_PRODUCT } from "./actionTypes";

function* fetchSysUserProduct({ payload: payload }) {
  try {
    const response = yield call(getSysUserProduct, payload);
    yield put(getSysUserProductSuccess(response))
  } catch (error) {
    yield put(getSysUserProductFail(error))
  }
}

function* AppSysUserProductsaga() {
  yield takeEvery(GET_SYS_USER_PRODUCT, fetchSysUserProduct)
}

export default AppSysUserProductsaga

