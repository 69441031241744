import {
    GET_SMTP,
    GET_SMTP_SUCCESS,
    GET_SMTP_FAIL,
    POST_SMTP,
    POST_SMTP_SUCCESS,
    POST_SMTP_FAIL,
    UPDATE_SMTP,
    UPDATE_SMTP_SUCCESS,
    UPDATE_SMTP_FAIL
} from "./actionTypes"

//Get smtp
export const getSmtp = (payload) => ({
    type:GET_SMTP,
    payload:payload
})
export const getSmtpSuccess = value => ({
    type:GET_SMTP_SUCCESS,
    payload:value
})
export const getSmtpFail = error => ({
    type:GET_SMTP_FAIL,
    payload:error
})

//post smtp
export const postSmtp = (payload) => ({
    type:POST_SMTP,
    payload:payload
})
export const postSmtpSuccess = value => ({
    type:POST_SMTP_SUCCESS,
    payload:value
})
export const postSmtpFail = error => ({
    type:POST_SMTP_FAIL,
    payload:error
})

//update smtp
export const updateSmtp = (payload) => ({
    type:UPDATE_SMTP,
    payload:payload
})
export const updateSmtpSuccess = payload => ({
    type:UPDATE_SMTP_SUCCESS,
    payload:payload
})
export const updateSmtpFail = error => ({
    type:UPDATE_SMTP_FAIL,
    payload:error
})

