import {
    GET_APP_ACCOUNT,
    GET_APP_ACCOUNT_SUCCESS,
    GET_APP_ACCOUNT_FAIL,
  
    POST_APP_ACCOUNT,
    POST_APP_ACCOUNT_SUCCESS,
    POST_APP_ACCOUNT_FAIL,
  
    DELETE_APP_ACCOUNT,
    DELETE_APP_ACCOUNT_SUCCESS,
    DELETE_APP_ACCOUNT_FAIL,
  
    GET_APP_ACCOUNT_DETAIL_SUCCESS,
    GET_APP_ACCOUNT_DETAIL_FAIL,
    GET_APP_ACCOUNT_DETAIL,
    
    UPDATE_APP_ACCOUNT_SUCCESS,
    UPDATE_APP_ACCOUNT_FAIL,
    UPDATE_APP_ACCOUNT,
  } from "./actionTypes";
  
  /////////////////////////
  //  Get Account Unit  //
  /////////////////////////
  
  export const getAccount = (payload) => ({
    type: GET_APP_ACCOUNT,
    payload: payload
  });
  
  export const getAccountSuccess = accounts => ({
    type: GET_APP_ACCOUNT_SUCCESS,
    payload: accounts,
  });
  
  export const getAccountFail = error => ({
    type: GET_APP_ACCOUNT_FAIL,
    payload: error,
  });
  
  //////////////////////////////
  // Get By id Account  //
  //////////////////////////////
  
  export const geAccountDetail = (payload) => ({
    type: GET_APP_ACCOUNT_DETAIL,
    payload: payload
  });
  
  export const getAccountDetailSuccess = accounts => ({
    type: GET_APP_ACCOUNT_DETAIL_SUCCESS,
    payload: accounts,
  });
  
  export const getAccountDetailFail = error => ({
    type: GET_APP_ACCOUNT_DETAIL_FAIL,
    payload: error,
  });
  
  ////////////////////////
  // Post Account ///
  ////////////////////////
  
  export const postAccount = (payload) => ({
    type: POST_APP_ACCOUNT,
    payload: payload
  });
  
  export const postAccountSuccess = products => ({
    type: POST_APP_ACCOUNT_SUCCESS,
    payload: products,
  });
  
  export const postAccountFail = error => ({
    type: POST_APP_ACCOUNT_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Delete Account //
  //////////////////////////
  
  export const deleteAccount = (payload) => ({
    type: DELETE_APP_ACCOUNT,
    payload: payload
  });
  
  export const deleteAccountSuccess = () => ({
    type: DELETE_APP_ACCOUNT_SUCCESS,
  });
  
  export const deleteAccountFail = error => ({
    type: DELETE_APP_ACCOUNT_FAIL,
    paACCOUNT
  });
  
  ///////////////////////////
  // Update Account  //
  ///////////////////////////
  
  export const updateAccount = (payload) => ({
    type: UPDATE_APP_ACCOUNT,
    payload: payload
  });
  
  export const updateAccountSuccess = payload => ({
    type: UPDATE_APP_ACCOUNT_SUCCESS,
    payload: payload
  });
  
  export const updateAccountFail = error => ({
    type: UPDATE_APP_ACCOUNT_FAIL,
    payload: error,
  });
  
  
  
  