export const GET_APP_ROLES ="GET_APP_ROLES"
export const GET_APP_ROLES_SUCCESS = "GET_APP_ROLES_SUCCESS"
export const GET_APP_ROLES_FAIL = "GET_APP_ROLES_FAIL"

export const POST_APP_ROLES ="POST_APP_ROLES"
export const POST_APP_ROLES_SUCCESS = "POST_APP_ROLES_SUCCESS"
export const POST_APP_ROLES_FAIL = "POST_APP_ROLES_FAIL"

export const DELETE_APP_ROLES ="DELETE_APP_ROLES"
export const DELETE_APP_ROLES_SUCCESS = "DELETE_APP_ROLES_SUCCESS"
export const DELETE_APP_ROLES_FAIL = "DELETE_APP_ROLES_FAIL"

export const GET_APP_ROLE ="GET_APP_ROLE"
export const GET_APP_ROLE_SUCCESS = "GET_APP_ROLE_SUCCESS"
export const GET_APP_ROLE_FAIL = "GET_APP_ROLE_FAIL"

export const UPDATE_APP_ROLES ="UPDATE_APP_ROLES"
export const UPDATE_APP_ROLES_SUCCESS = "UPDATE_APP_ROLES_SUCCESS"
export const UPDATE_APP_ROLES_FAIL = "UPDATE_APP_ROLES_FAIL"