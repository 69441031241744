import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'
import classnames from "classnames"

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Table, Collapse, TabContent, TabPane, CardBody } from "reactstrap"

import axios from 'axios';
import getHeader from 'config/header';
import StatusModal from 'components/Common/StatusModal'
import { useDispatch, useSelector } from 'react-redux'
import { getAppBusinessUnits, getBusinessProducts, getBusinessUnitDivision, getSysUsers, updateBusinessUnitDivision } from 'store/actions'
import SpinnerModal from 'components/Common/SpinnerModal'
import * as url from "./../../../helpers/url_helper";
import TableContainer from 'components/Common/TableContainer'

function UpdateBusinessUnitDivision() {
    let { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()

    const businessUnitDivisionState = useSelector(state => state.BUDivisionReducer);
    const businessUnitSate = useSelector(state => state.AppBusinessUnits);
    const businessProductState = useSelector(state => state.BusinessProducts);
    const sysUserState = useSelector(state => state.sysUsersReducer)

    const [businessUnits, setBusinessUnit] = useState([])
    const [productList, setProductList] = useState([])
    const [sysUserList, setSysUserList] = useState([])
    const [businessUnitList, setBusinessUnitList] = useState([])

    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const UpadatePermission = (permissionAction.includes("system.administration.businessunitsdivisions.update")) ? false : true
    const DeletePermission = (permissionAction.includes("system.administration.businessunitsdivisions.delete")) ? false : true

    const [spinner, setSpinner] = useState(false)

    useEffect(() => {
        dispatch(getAppBusinessUnits({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));

        dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));

        dispatch(getSysUsers({ filter: `{"is_active":true}`, limit: 0, skip: 0 }))
    }, [])

    useEffect(() => {
        if (businessUnitSate.appBusinessUnits !== null) {
            setOptionGroup('')
            setselectedMulti([])
            setBusinessUnit(businessUnitSate.appBusinessUnits.result);
        }
        if (businessProductState.products !== null) {
            setOptionGroupProduct('')
            setselectedMultiProduct([])
            setProductList(businessProductState.products.result);
        }
        if (sysUserState.sysUsers !== null) {
            setOptionGroupBusUnitUser('')
            setselectedMultiBusUser([])
            setSysUserList(sysUserState.sysUsers.result);
        }
    }, [businessUnitSate, businessProductState, sysUserState,])

    useEffect(() => {
        if (id) {
            const data = { businessUniDivisiontId: id }
            dispatch(getBusinessUnitDivision(data))
        }
    }, [id])

    // useEffect(() => {
    //     if (!(businessUnitSate && businessUnitSate.appBusinessUnit)) return
    //     setBusinessUnitList(businessUnitSate)
    // }, [businessUnitSate])

    const [getBusUnit, setGetBusUnit] = useState([])
    const [getProd, setGetProd] = useState([])

    useEffect(() => {
        if (businessUnitDivisionState.singleBUDivision !== null && businessUnitSate.appBusinessUnits !== null &&
            businessProductState.products !== null && sysUserState.sysUsers !== null) {

            const res = businessUnitDivisionState.singleBUDivision
            setValue("app_business_unit_division_name", res.app_business_unit_division_name)
            setValue("app_business_unit_division_keyid", res.app_business_unit_division_keyid)
            appBusinessUnit(res?.app_business_unit ? res.app_business_unit : [])
            appBuProduct(res?.app_business_unit_product ? res.app_business_unit_product : [])
            //  statusChange()
            appBusUnitUserID(res.app_business_unit_user ? res.app_business_unit_user : [])
            if (res.is_active === true) {
                setValue("is_active", "active")
            } else if (res.is_active === false) {
                setValue("is_active", "inactive")
            }
        }

        if (businessUnitDivisionState.isUpdated) {
            history.goBack()
        }

    }, [businessUnits, productList, sysUserList, businessUnitDivisionState])

    // Manage business Unit MultiSelect
    const [selectedMulti, setselectedMulti] = useState([]);
    const [optionGroup, setOptionGroup] = useState([{ options: [] }])
    register('app_business_unit');

    //  manage product multi select
    const [selectedMultiProduct, setselectedMultiProduct] = useState([]);
    const [optionGroupProduct, setOptionGroupProduct] = useState([{ options: [] }])
    register('app_business_unit_product')

    // Manage sys User MultiSelect
    const [selectedMultiBusUser, setselectedMultiBusUser] = useState([]);
    const [optionGroupBusUnitUser, setOptionGroupBusUnitUser] = useState([{ options: [] }])
    register('app_business_unit_user');
    const [busUnitUserList, setBusUnitUserList] = useState([])

    //for multiselect
    useEffect(() => {
        if (businessUnits) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in businessUnits) {
                let data = {
                    label: businessUnits[i].app_business_unit_name,
                    value: businessUnits[i].app_businessunit_id
                }
                options.push(data)
            }
            setOptionGroup(options)
        }
        if (productList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in productList) {
                let data = {
                    label: productList[i].app_product_pos_item_code + " | " + productList[i].app_product_payment_code + " | " + productList[i].app_product_local_name,
                    value: productList[i].app_product_id
                }
                options.push(data)
            }
            setOptionGroupProduct(options)
        }
        if (sysUserList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in sysUserList) {
                let data = {
                    label: sysUserList[i].sys_user_keyid + " | " + sysUserList[i].sys_user_name,
                    value: sysUserList[i].sys_user_id
                }
                options.push(data)
            }
            setOptionGroupBusUnitUser(options)
        }
    }, [businessUnits, productList, sysUserList])

    const appBusinessUnit = (Items) => {
        if (Items.length > 0) {
            let Data = Items.map((item) => {
                let businessunits = businessUnits.find(businessunits => businessunits.app_businessunit_id === item.value)
                if (businessunits) {
                    return {
                        label: businessunits.app_business_unit_name,
                        value: businessunits.app_businessunit_id
                    }
                }
            }).filter(Boolean)
            setselectedMulti(Data)
            setValue("app_business_unit", Data)
        }
    }

    const appBuProduct = (Items) => {
        if (Items.length > 0 && productList) {
            let Data = Items.map((item) => {
                let productlist = productList.find(productlist => productlist.app_product_id === item.value)
                if (productlist) {
                    return {
                        label: productlist.app_product_pos_item_code + " | " + productlist.app_product_payment_code + " | " + productlist.app_product_local_name,
                        value: productlist.app_product_id
                    }
                }
            }).filter(Boolean)
            setselectedMultiProduct(Data)
            // setProductList(Data)
            setValue("app_business_unit_product", Data)
        }
    }

    const appBusUnitUserID = (Items) => {
        // console.log(Items);
        if (Items.length > 0) {
            let Data = Items.map((item) => {
                let sysuserlist = sysUserList.find(sysuserlist => sysuserlist.sys_user_id === item.value)
                if (sysuserlist) {
                    return {
                        label: sysuserlist.sys_user_name,
                        value: sysuserlist.sys_user_id
                    }
                }
            }).filter(Boolean)
            setselectedMultiBusUser(Data)
            setBusUnitUserList(Data)
            setValue("app_business_unit_user", Data)
        }
    }

    const handlePostChange = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroup.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_business_unit', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_business_unit', postArray)
                }
            }
        });
        setselectedMulti({
            app_businessunit_id: postArray,
        });
        setValue('app_business_unit', postArray)
    };

    const handleChangeProduct = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values
        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupProduct.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        console.log(postArray);
                        setValue('app_business_unit_product', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_business_unit_product', postArray)
                }
            }
        });
        // setProductList(postArray)
        setselectedMultiProduct( postArray);
        setValue('app_business_unit_product', postArray)
    };

    const handlePostChangeUsers = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupBusUnitUser.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_business_unit_user', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_business_unit_user', postArray)
                }
            }
        });
        setBusUnitUserList(postArray)
        setselectedMultiBusUser({
            sys_user_id: postArray,
        });
        setValue('app_business_unit_user', postArray)
    };

    const [confirmStatus, setConfirmStatus] = useState()
    const statusChange = (e) => {
        setConfirmStatus(e.target.value)
        if (e.target.value === "active") {
            setStatusTBC(true)
            setStatusModal(true)
        } else if (e.target.value === "inactive") {
            setStatusTBC(false);
            setStatusModal(true)
        }

    }

    const [statusModal, setStatusModal] = useState(false)
    const [statusTBC, setStatusTBC] = useState()

    const handleStatus = () => {
        setValue("is_active", confirmStatus)
        setStatusModal(false)
    }

    const closeClick = () => {
        const value = getValues("is_active");
        if (value === "inactive") {
            let dta = "active"
            setValue("is_active", dta)
            setStatusModal(false)
            console.log("singleValue", dta);
        } else if (value === "active") {
            let dta = "inactive"
            setValue("is_active", dta)
            setStatusModal(false)
            console.log("singleValue", dta);
        }

    }

    const updatebusinessunitDivision = (data) => {
        setSpinner(true)
        let value
        if (data.is_active === "active") {
            value = true
        } else if (data.is_active === "inactive") {
            value = false
        }

        let items = data.app_business_unit
        let Dta = []
        for (let i in items) {
            Dta.push(items[i].value)
        }

        const busunitDivData = {
            ...data,
            is_active: value,
            // app_business_unit: Dta
        }
        const updateData = {
            busUnitDivisionId: id,
            updateData: busunitDivData
        }

        $('#updatebusinessunitDivisions').attr("disabled", true)
        dispatch(updateBusinessUnitDivision(updateData))

    }

    const customStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: "#D2D4D1", // Change this to your desired background color
        }),
    };

    const removeSelectedProduct = (indexToRemove) => {
        const newData = selectedMultiProduct.filter((_, index) => index !== indexToRemove);
        setselectedMultiProduct(newData);
    }
    
    const columns2 = useMemo(
        () => [
            {
                Header: 'S.No',
                accessor: (row, index) => index + 1,
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Product Name',
                accessor: 'label',
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Action',
                accessor: (row, index) => index,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <button type='button' className="btn btn-danger btn-sm" onClick={() => { removeSelectedProduct(cellProps.value) }}>X</button>
                        </div>
                    );
                }
            },
    
        ],
        [selectedMultiProduct]
    );

    return (
        <React.Fragment>
            <StatusModal
                show={statusModal}
                onDeleteClick={handleStatus}
                onCloseClick={closeClick}
                data={statusTBC}
            />
            <SpinnerModal
                show={spinner}
            />
            <div className="page-content">
                <Breadcrumbs title="Update Business Unit Division" breadcrumbItem="Business Unit Divisions" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(updatebusinessunitDivision)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Business Unit Division ID <span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' {...register('app_business_unit_division_keyid', { required: true })} />
                                                {errors.app_business_unit_division_keyid && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Business Unit Division Name<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} type="text" className='form form-control' {...register('app_business_unit_division_name', { required: true })} />
                                                {errors.app_business_unit_division_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Business Units  <span className='text-danger'>*</span>
                                            </Label>
                                            <Col lg="4" md="6">
                                                {UpadatePermission ?
                                                    <Select
                                                        isDisabled
                                                        styles={customStyles}
                                                        value={selectedMulti.length > 0 ? selectedMulti : selectedMulti.app_businessunit_id}
                                                        isMulti={true}
                                                        onChange={handlePostChange}
                                                        options={optionGroup}
                                                        name={"app_businessunit_id"}
                                                        className="modal__input"
                                                    /> :
                                                    <Select
                                                        value={selectedMulti.length > 0 ? selectedMulti : selectedMulti.app_businessunit_id}
                                                        isMulti={true}
                                                        onChange={handlePostChange}
                                                        options={optionGroup}
                                                        name={"app_businessunit_id"}
                                                        className="modal__input"
                                                    />}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Products <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="4" md="6">
                                                {UpadatePermission ?
                                                    <Select
                                                        isDisabled
                                                        styles={customStyles}
                                                        value={selectedMultiProduct.length > 0 ? selectedMultiProduct : selectedMultiProduct}
                                                        isMulti={true}
                                                        onChange={handleChangeProduct}
                                                        options={optionGroupProduct}
                                                        name={"app_product_id"}
                                                        className="modal__input"
                                                    /> :
                                                    <Select
                                                        value={selectedMultiProduct.length > 0 ? selectedMultiProduct : selectedMultiProduct}
                                                        isMulti={true}
                                                        onChange={handleChangeProduct}
                                                        options={optionGroupProduct}
                                                        name={"app_product_id"}
                                                        className="modal__input"
                                                    />}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    {selectedMultiProduct.length > 0 ? <Row>
                                        <Col xs="12">
                                            <Card>
                                                <CardBody>
                                                    <TableContainer
                                                        columns={columns2}
                                                        data={selectedMultiProduct}
                                                        isGlobalFilter={true}
                                                        isTwoColumnTable={true}
                                                        isthemeGlobalFilter={true}
                                                        className="custom-header-css"
                                                        // customPageCount={pageCount}
                                                        customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row> : ""}
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Users <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="4" md="6">
                                                {UpadatePermission ?
                                                    <Select
                                                        isDisabled
                                                        styles={customStyles}
                                                        value={selectedMultiBusUser.length > 0 ? selectedMultiBusUser : selectedMultiBusUser.sys_user_id}
                                                        isMulti={true}
                                                        onChange={handlePostChangeUsers}
                                                        options={optionGroupBusUnitUser}
                                                        name={"sys_user_id"}
                                                        className="modal__input"
                                                    /> :
                                                    <Select
                                                        value={selectedMultiBusUser.length > 0 ? selectedMultiBusUser : selectedMultiBusUser.sys_user_id}
                                                        isMulti={true}
                                                        onChange={handlePostChangeUsers}
                                                        options={optionGroupBusUnitUser}
                                                        name={"sys_user_id"}
                                                        className="modal__input"
                                                    />}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="4" md="6">
                                                <select style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={DeletePermission} onInput={(e) => { statusChange(e) }} className="form-select d-inline" {...register('is_active')}>
                                                    <option hidden value="">Select Status</option>
                                                    <option value="active">ACTIVE</option>
                                                    <option value="inactive">INACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                                                    Back
                                                </Button>
                                                {!UpadatePermission && <Button id='updatebusinessunitDivisions' color='success' type='submit' className='m-2'>
                                                    Update
                                                </Button>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UpdateBusinessUnitDivision
