import {
  GET_APP_BUSINESS_UNIT,
  GET_APP_BUSINESS_UNIT_SUCCESS,
  GET_APP_BUSINESS_UNIT_FAIL,

  POST_APP_BUSINESS_UNIT,
  POST_APP_BUSINESS_UNIT_SUCCESS,
  POST_APP_BUSINESS_UNIT_FAIL,

  DELETE_APP_BUSINESS_UNIT,
  DELETE_APP_BUSINESS_UNIT_SUCCESS,
  DELETE_APP_BUSINESS_UNIT_FAIL,

  GET_APP_BUSINESS_UNIT_DETAIL,
  GET_APP_BUSINESS_UNIT_DETAIL_SUCCESS,
  GET_APP_BUSINESS_UNIT_DETAIL_FAIL,

  UPDATE_APP_BUSINESS_UNIT,
  UPDATE_APP_BUSINESS_UNIT_SUCCESS,
  UPDATE_APP_BUSINESS_UNIT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  appBusinessUnits: null,
  appBusinessUnit: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const AppBusinessUnits = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APP_BUSINESS_UNIT:
      return {
        ...state,
        loading: true,
        appBusinessUnits: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_BUSINESS_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        appBusinessUnits: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_BUSINESS_UNIT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
      case POST_APP_BUSINESS_UNIT:
        return {
          ...state,
          loading: true,
          appBusinessUnits: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case POST_APP_BUSINESS_UNIT_SUCCESS:
        return {
          ...state,
          loading: false,
          appBusinessUnit: action.payload,
          isCreated: true,
          isDeleted: false,
          isUpdated: false
        };
      case POST_APP_BUSINESS_UNIT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case DELETE_APP_BUSINESS_UNIT:
        return {
          ...state,
          loading: true,
          appBusinessUnits: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case DELETE_APP_BUSINESS_UNIT_SUCCESS:
        return {
          ...state,
          loading: false,
          appBusinessUnit: null,
          isCreated: false,
          isDeleted: true,
          isUpdated: false
        };
      case DELETE_APP_BUSINESS_UNIT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_BUSINESS_UNIT_DETAIL:
        return {
          ...state,
          loading: true,
          appBusinessUnits: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_BUSINESS_UNIT_DETAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          appBusinessUnit: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_BUSINESS_UNIT_DETAIL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case UPDATE_APP_BUSINESS_UNIT:
          return {
            ...state,
            loading: true,
            appBusinessUnits: null,
            appBusinessUnit: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
      case UPDATE_APP_BUSINESS_UNIT_SUCCESS:
          return {
            ...state,
            loading: false,
            appBusinessUnit: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: true
          };
      case UPDATE_APP_BUSINESS_UNIT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
    default:
      return state;
  }
};

export default AppBusinessUnits;


