import axios from 'axios';
import getHeader from 'config/header';
import { API_BASE_URL, BUSINESS_URL, DEPARTMENT_URL } from 'helpers/url_helper';
import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';




const ProductkeyID = (cell) => {
    return cell.value ? cell.value : "";
};

const Version = (cell) => {
    return cell.value ? cell.value + " " + "(" + cell.statusdata.proposal_status + ")" : '';
};

const Date = (cell) => {
    return cell.value ? cell.value : '';
};

const CloseQuantity = (cell) => {
    return cell.value ? cell.value : '';
};

const Amount = (cell) => {
    let total = 0;
    cell?.data?.customProducts?.forEach(item => total += parseFloat(item.rowTotal ? item.rowTotal : 0));

    const floatValue = parseFloat(total).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " +  " " +floatValue : ''} </span>);
};

const Remarks = (cell) => {
    return cell.value ? cell.value : '';
};

const Quantities = (cell) => {
    let total = 0;
    cell?.data?.customProducts?.forEach(item => total += parseFloat(item.quantity ? item.quantity : 0));
    return (<span className='ListPageFloatRight'>{total} </span>);
}

const ApprovalStatus = (cell) => {
    return (
        <Badge
            className={"font-size-12 badge-soft-" +
                (cell.value === "Approved" ? "success" : "danger" && cell.value === "Pending" ? "warning" : "danger")}
            pill
        >
            {cell.value}
        </Badge>
    )
};

const Unitprice = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    Version, Date, CloseQuantity, Amount, Remarks, Quantities, ProductkeyID, ApprovalStatus, Unitprice
};
