import {
  GET_BUSINESS_PRODUCTS_SUCCESS,
  GET_BUSINESS_PRODUCTS_FAIL,
  GET_BUSINESS_PRODUCTS,

  GET_BUSINESS_PRODUCTS_DETAIL_SUCCESS,
  GET_BUSINESS_PRODUCTS_DETAIL_FAIL,
  GET_BUSINESS_PRODUCTS_DETAIL,
  
  UPDATE_BUSINESS_PRODUCTS_SUCCESS,
  UPDATE_BUSINESS_PRODUCTS_FAIL,
  UPDATE_BUSINESS_PRODUCTS,
} from "./actionTypes";

const INIT_STATE = {
  products: null,
  loading: false,
  error: {},
};

const BusinessProducts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUSINESS_PRODUCTS:
      return {
        ...state,
        loading: true,
        products: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_BUSINESS_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };

    case GET_BUSINESS_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
      case GET_BUSINESS_PRODUCTS_DETAIL:
        return {
          ...state,
          loading: true,
          product: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_BUSINESS_PRODUCTS_DETAIL_SUCCESS:
        return {
          ...state,
          loading: false,
          product: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_BUSINESS_PRODUCTS_DETAIL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case UPDATE_BUSINESS_PRODUCTS:
          return {
            ...state,
            loading: true,
            updateProduct: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
      case UPDATE_BUSINESS_PRODUCTS_SUCCESS:
          return {
            ...state,
            loading: false,
            updateProduct: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: true
          };
      case UPDATE_BUSINESS_PRODUCTS_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
    default:
      return state;
  }
};

export default BusinessProducts;
