import React, { useEffect, useState } from 'react'
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container } from "reactstrap"
import { FormGroup, Label, Form } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import getHeader from 'config/header';
import { API_BASE_URL, OTP_TIMEOUT_URL } from 'helpers/url_helper';


const OtpValidTime = () => {

    const history = useHistory();
    const { register, setValue, handleSubmit } = useForm()

    useEffect(() => {
        const token = sessionStorage.getItem('authUser');
        axios.get(API_BASE_URL+OTP_TIMEOUT_URL, getHeader(token))
            .then(res => {
                // console.log("rews", res)
                const OtpLoginArray = res.data.app_otp_timeout
                setValue('app_otp_timeout', OtpLoginArray)
            })
            .catch(err => {
                console.log(err);
            });
    }, [])  


    const [editState, setEditState] = useState(false)
    const [createState, setCreateState] = useState(false)
    const [triggeredCancel, setTriggeredCancel] = useState(false)

    const createOtpLogin = (data) => {
        const token = sessionStorage.getItem("authUser")
        if (createState) {
            axios.post(API_BASE_URL+OTP_TIMEOUT_URL, data, getHeader(token))
                .then(res => {
                    toast.success("Saved")
                    setEditState(!editState)
                    setCreateState(false)
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            const app_otp_timeout = {
                ...data
            }
            let value = {
                app_otp_timeout
            }
            console.log("app_login_retry", value.app_otp_timeout);
            let Dta = value.app_otp_timeout
            axios.put(API_BASE_URL+OTP_TIMEOUT_URL, Dta, getHeader(token))
                .then(res => {
                    toast.success("Updated")
                    setEditState(!editState)
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    const handleEditState = () => {
        setEditState(!editState)
    }

    const handleCancelEditState = () => {
        setTriggeredCancel(!triggeredCancel)
        setEditState(!editState)
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="OTP Valid Time" handleEditState={handleEditState} handleCancelEditState={handleCancelEditState} isEditSmtpBtn={!editState} />
                <Container fluid>
                    <Card body lg="12">
                        <Row>
                            <Form onSubmit={handleSubmit(createOtpLogin)}>
                                <FormGroup row className="align-items-end">
                                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-start ">OTP Valid Time :</Label>
                                    <Col lg="2" md="6">
                                        <input readOnly={!editState} type="number" className='form form-control mt-1' {...register(`app_otp_timeout`)} />
                                    </Col>
                                </FormGroup>
                                <Row >
                                    <Col lg="6" className='d-flex justify-content-center ms-5'>
                                        <FormGroup>
                                            {editState &&
                                                <Button color="success" type="submit">{createState ? "Create" : "Update"}</Button>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    </Card>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default OtpValidTime




