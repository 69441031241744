export const GET_SMTP = "GET_SMTP";
export const GET_SMTP_SUCCESS = "GET_SMTP_SUCCESS";
export const GET_SMTP_FAIL = "GET_SMTP_FAIL";

export const POST_SMTP = "POST_SMTP";
export const POST_SMTP_SUCCESS = "POST_SMTP_SUCCESS";
export const POST_SMTP_FAIL = "POST_SMTP_FAIL";

export const UPDATE_SMTP = "UPDATE_SMTP";
export const UPDATE_SMTP_SUCCESS = "UPDATE_SMTP_SUCCESS";
export const UPDATE_SMTP_FAIL = "UPDATE_SMTP_FAIL";
