//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contactsaaa";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";


// Api Base Path 
export const API_BASE_URL = "/api/v2"
export const AUTH_TOKEN = "/auth/token"
export const SYSUSER_PROFILE= "/sysusers/profile"
export const LOGIN_ATTEMPT= "/loginattempt"


// Administration
export const SYS_ROLE_URL = "/sysroles"
export const SYS_USER_URL = "/sysusers"
export const APP_ROLE_URL = "/approles"
export const APP_USER_URL ="/appusers"

// PLatform Action
export const PLATFORM_ACTION = "/settings/actions"

// Business Action
export const BUSINESS_ACTION = "/settings/businessactions"

// Sys credentials
export const SYS_CREDENTIALS = '/sysroles' // id  + credentials  

// App Credentials
export const APP_CREDENTIALS ="/approles" // id + credentials

// Generic Industry
export const INDUSTRY = "/generic/industries"
export const INDUSTRY_ID = "/generic/industries"

//Business
export const BUSINESS_URL = "/businesses";
export const DEPARTMENT_URL = "/departments";
export const CONTACT_URL = "/contacts";
export const OWNER_URL = "/owners";
export const ADDRESS_URL = "/address";

// sys user profile  
export const SYS_USER_PROFILE ="/sysusers/profile"
 
//Business unit
export const BUSINESS_PRODUCTS_URL = "/appproducts";
export const APP_BUSINESS_UNITS_URL = "/businessunits";
export const BUSINESS_UNIT_DIVISION_URL = "/appdivisions";

export const APP_BUSINESS_UNIT_LINK_URL = "/appbusinessunitlink";


// opportunities
export const OPPORTUNITIES_URL ="/opportunities"


// Credits 
export const CREDITS_URL ="/credits"

// Login Attempts
export const LOGIN_RETRY_URL ="/settings/loginretry"

// Otp TimeOuts
export const OTP_TIMEOUT_URL ="/settings/otptimeout"

// Password Policy
export const PASSWORD_POLICY_URL ="/settings/passwordpolicy"

// Activities log
export const ACTIVITIES ="/activities"

//smtp
export const SMTP_URL = "/settings/smtp"

//Pricing Model
export const PRICING_MODEL_URL = "/pricingmodel"

//Accounts
export const ACCOUNTS_URL = "/appaccounts"


// sys user product 
 
export const SYS_USER_PRODUCT_URL = "/sysusers/products"


//Transaction
export const TRANSACTION_URL = "/transaction"

//Propsal version
export const PROPOSAL_VERSION_URL = "/proposalversion"

//quotation
export const QUOTATION_VERSION_URL = "/quotation"

//invoice
export const INVOICE_VERSION_URL = "/invoice"


//proposal opportunity get url
export const PROPOSAL_OPPORTUNITY_GET_URL = "/proposalversion/opportunity"

//finalize proposal
export const FINALIZE_PROPOSAL_URL = "/finalizeproposal"

//proposal clone version get by proposalid
export const PROPOSAL_CLONE_VERSION_URL = "/proposalversion/clone"

//opportunity clone version get gy opportunityid
export const OPPORTUNITY_CLONE_VERSION_URL = "/opportunities/clone"

//Application Template
export const APPLICATION_TEMPLATE_URL = "/applicationtemplate"

//synchronize product
export const PRODUCT_SYNCHRONIZE_URL = "/synchronize"

//coupon details

export const COUPON_POST_URL = "/synchronize/createcoupon"

export const COUPON_GET_URL = "/synchronize/getcoupon"

//Attachment
export const  ATTACHMENT_URL = "/settings/attachment"

//Downloads
export const DOWNLOAD_LOG_URL = "/downloadlog"

export const COUPON_GENERATE_URL = "/coupongenerate"

//Dashboard notification

export const NOTIFICATION_PENDING_STATUS_URL = "/approvalstatus/pending"
export const NOTIFICATION_APPROVAL_STATUS_URL = "/approvalstatus/approval"



