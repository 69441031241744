import { takeEvery, put, call } from "redux-saga/effects"

import { 
    getSmtpSuccess, getSmtpFail,
    postSmtpSuccess,postSmtpFail,
    updateSmtpSuccess,updateSmtpFail
} from "./actions";

import { GET_SMTP,POST_SMTP,UPDATE_SMTP } from "./actionTypes";

import { getSmtp,postSmtp,updateSmtp } from "helpers/backendApi_helpers";

function* fetchsmtp({ payload: payload }) {
    try{
        const response = yield call(getSmtp, payload);
        yield put(getSmtpSuccess(response))

    } catch(error){
        yield put(getSmtpFail(error))
    }
}

function* postsmtp({ payload: payload }){
    try{
        const response = yield call(postSmtp, payload);
        yield put(postSmtpSuccess(response))
    } catch(error){
        yield put(postSmtpFail(error))
    }
}

function* updatesmtp({ payload: payload }) {
    try{
        const response = yield call(updateSmtp, payload)
        yield put(updateSmtpSuccess(response))
    } catch(error){
        yield put(updateSmtpFail(error))
    }
}

function*Smtpsaga() {
 yield takeEvery(GET_SMTP,fetchsmtp)
 yield takeEvery(POST_SMTP,postsmtp)
 yield takeEvery(UPDATE_SMTP,updatesmtp)
}

export default Smtpsaga