import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';

// Column
import { BusinessName, BusinessStatus, BusinessUnitID } from './BusinessUnitListCol';
import axios from "axios";
import getHeader from "config/header";
import expiredSessionHandler from "common/expiredSessionHandler";
import { getAppBusinessUnits, deleteAppBusinessUnits } from "store/administration/businessUnit/actions";
import { useDispatch, useSelector } from "react-redux";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const BusinessUnits = props => {


  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const pageStatus = query.get("status")

  const dispatch = useDispatch();
  const history = useHistory()

  //meta title
  document.title = "Business Units | Maxims B2B Portal";

  const [businessUnits, setBusinessUnit] = useState([])
  // const [pageStatus, setPageStatus] = useState(status)
  const [permissions, setPermissions] = useState('')
  const [pageCount, setPageCount] = useState(1);
  // const [page, setPage] = useState(skip);
  // const [pageLimit, setPageLimit] = useState(limit);


  const businessUnitSate = useSelector(state => state.AppBusinessUnits)

  useEffect(() => {
    setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
  }, [])




  useEffect(() => {
    if (businessUnitSate.appBusinessUnits !== null) {
      setBusinessUnit(businessUnitSate.appBusinessUnits.result);
      setPageCount(Math.ceil(businessUnitSate.appBusinessUnits.summary.filteredsize / pageLimit) == 0 ?  1
      :
      Math.ceil(businessUnitSate.appBusinessUnits.summary.filteredsize / pageLimit));
    }
    if (businessUnitSate.isDeleted) {
      setDeleteModal(false);
      handlePageClick(page);
    }
    if (businessUnitSate.error) {
      setDeleteModal(false);
    }
  }, [businessUnitSate])


  const [customFilterData, setCustomFilterData] = useState("")

  useEffect(() => {
    handlePageClick()
  }, [pageStatus, pageLimit, page, customFilterData])

  useEffect(() => {
    if (customFilterData.length > 0) {
      history.push(`/businessUnits?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
    }
  }, [customFilterData])

  const handlePageClick = (index) => {

    let filter = `{"is_active":${pageStatus},"$or":[
      {"app_business_unit_keyid":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_business_unit_name":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`
    let skips = 0;
    if (page > 0) {
      skips = page * pageLimit;
      dispatch(getAppBusinessUnits({ filter: filter, limit: pageLimit, skip: skips }));

      // dispatch(getAppBusinessUnits({ filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: skips }));
    }
    else {
      dispatch(getAppBusinessUnits({ filter: filter, limit: pageLimit, skip: 0 }));

      // dispatch(getAppBusinessUnits({ filter: `{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }
  }


  // Company Column
  const columns = useMemo(
    () => [
      {
        Header: 'Business Unit ID',
        accessor: 'app_business_unit_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <BusinessUnitID {...cellProps} />;
        }
      },
      {
        Header: 'Business Unit Name',
        accessor: 'app_business_unit_name',
        filterable: true,
        Cell: (cellProps) => {
          return <BusinessName {...cellProps} />;
        }
      },
      {
        Header: 'CSMS Branch Code',
        accessor: 'app_csms_branch_code',
        filterable: true,
        Cell: (cellProps) => {
          return <BusinessName {...cellProps} />;
        }
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <BusinessStatus {...cellProps} />;
        }
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
          return (
            <div className="d-flex gap-3">
              {(permissionAction.includes("system.administration.businessunits.read")) ?
                <>
                  <button
                    onClick={() => {
                      history.push(`/viewBusinessUnit/${cellProps.row.original.app_businessunit_id}`)
                    }} className="btn btn-success btn-sm">View</button>
                </> : <div className="text-muted">No Access</div>
              }
              {(permissionAction.includes("system.administration.businessunits.read")) && (permissionAction.includes("system.administration.businessunits.update")) ||
                (permissionAction.includes("system.administration.businessunits.read")) && (permissionAction.includes("system.administration.businessunits.delete"))
                ?
                <>
                  <button
                    onClick={() => {
                      history.push(`/updateBusinessUnit/${cellProps.row.original.app_businessunit_id}`)
                    }} className="btn btn-info btn-sm">Edit</button>
                </> : <div className="text-muted">No Access</div>
              }

            </div>
            // <div className="d-flex gap-3">
            //   {(permissionAction.includes("system.administration.businessunits.read")) ?
            //     <>
            //       <button
            //         onClick={() => {
            //           history.push(`/updateBusinessUnit/${cellProps.row.original.app_businessunit_id}`)
            //         }} className="btn btn-success btn-sm">View</button>
            //     </> : <div className="text-muted">No Access</div>
            //   }
            // </div>
          );
        }
      },
    ],
    [businessUnits]
  );


  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [businessUnitTBD, setBusinessUnitTBD] = useState('')

  const onClickDelete = (businessUnit) => {
    setBusinessUnitTBD(businessUnit)
    setDeleteModal(true)
  }

  const handleDeleteBusinessUnit = () => {
    if (businessUnitTBD.app_businessunit_id) {
      const data = {
        businessUnitId: businessUnitTBD.app_businessunit_id
      };
      dispatch(deleteAppBusinessUnits(data));
    }
  }



  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBusinessUnit}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {permissions.includes("system.administration.businessunits.read") ? <Container fluid>
          <Breadcrumbs title="Business Units" breadcrumbItem="Administration" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={businessUnits}
                    isGlobalFilter={true}
                    isBusinessUnitsList={permissions.includes("system.administration.businessunits.write") ? true : false}
                    className="custom-header-css"
                    customPageCount={pageCount}
                    customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                  // customPageStatus={(data) => setPageStatus(data)}
                  // pageSizeTransfer={(data) => setPageLimit(data)}
                  // handlePageClick={handlePageClick}
                  // customPageIndex={page}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
          :
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                      <div>
                        <h1><i className='bx bxs-lock'></i></h1>
                        <h3>Access Denied</h3>
                        <div className="text-muted">You don't have permission to access this module</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
    </React.Fragment>
  );
};

BusinessUnits.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
};

export default BusinessUnits;


