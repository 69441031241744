import axios from 'axios';
import getHeader from 'config/header';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import { getGenericIndustry, getGenericIndustryId } from 'store/actions';
import * as url from "./../../../helpers/url_helper";

const CustKeyId = (cell) => {
    return cell.value ? cell.value : '';
};

const CompanyName = (cell) => {
    return cell.value ? cell.value : '';
};

const Industry = (cell) => {
    const token = sessionStorage.getItem('authUser')
    const [industry, setIndustry] = useState([])

    const genericIndustryState = useSelector(state => state.IndustryReducer)
    useEffect(() => {
        if (cell.value) {
            const id = cell.value
            //  dispatch(getGenericIndustryId(id))
            axios.get(url.API_BASE_URL + url.INDUSTRY + "/" + id, getHeader(token))
                .then(res => {
                    const industry = {
                        value: res.data.industry_id,
                        label: res.data.industry_name
                    }
                    setIndustry(industry.label);
                }).catch(err => {
                    console.log(err);
                })
        }
    }, [])

    // useEffect(() => {
    //     if (genericIndustryState.genericIndustryId !== null) {
    //         console.log(genericIndustryState.genericIndustryId);
    //         const industry = {
    //             value: genericIndustryState.genericIndustryId.industry_id,
    //             label: genericIndustryState.genericIndustryId.industry_name
    //         }
    //         setIndustry(industry.label);
    //     }
    // }, [genericIndustryState.genericIndustryId ])
    return industry ? industry : '';
};

const BusinessUnitOwner = (cell) => {
    const token = sessionStorage.getItem('authUser')

};

const Status = (cell) => {
    return (
        <Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
            {cell.value ? "ACTIVE" : "INACTIVE"}
        </Badge>
    )
};

export {
    CustKeyId,
    CompanyName,
    Industry,
    BusinessUnitOwner,
    Status
};