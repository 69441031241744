import { takeEvery, put, call } from "redux-saga/effects"
import {
getFinalizeProposalsSuccess,
getFinalizeProposalsFail,
getFinalizeProposalDetailSuccess,
getFinalizeProposalDetailFail,
postFinalizeProposalSuccess,
postFinalizeProposalFail,
confirmFinalizeProposalSuccess,
confirmFinalizeProposalFail
} from "./actions"

import {
getFinalizeProposal,
getAppFinalizeProposalDetail,
postAppFinalizeProposal,
confirmAppFinalizeProposal
}
  from "helpers/backendApi_helpers";

import {
  GET_FINALIZEPROPOSAL,
  GET_FINALIZEPROPOSAL_DETAIL,
  POST_FINALIZEPROPOSAL,
  CONFIRM_FINALIZEPROPOSAL
} from "./actionTypes";

function* fetchFinalizeProposal({ payload: payload }) {
  try {
    const response = yield call(getFinalizeProposal, payload);
    yield put(getFinalizeProposalsSuccess(response))
  } catch (error) {
    yield put(getFinalizeProposalsFail(error))
  }
}

function* getFinalizeProposalDetail({ payload: payload }) {
  try {
    const response = yield call(getAppFinalizeProposalDetail, payload);
    yield put(getFinalizeProposalDetailSuccess(response))
  } catch (error) {
    yield put(getFinalizeProposalDetailFail(error))
  }
}

function* postFinalizeProposal({ payload: payload }) {
    try {
      const response = yield call(postAppFinalizeProposal, payload);
      yield put(postFinalizeProposalSuccess(response))
    } catch (error) {
      yield put(postFinalizeProposalFail(error))
    }
  }


function* confirmFinalizeProposal({ payload: payload }) {
  try {
    const response = yield call(confirmAppFinalizeProposal, payload);
    yield put(confirmFinalizeProposalSuccess(response))
  } catch (error) {
    yield put(confirmFinalizeProposalFail(error))
  }
}

  

  
function* AppFinalizeProposalSaga() {
  yield takeEvery(GET_FINALIZEPROPOSAL, fetchFinalizeProposal)
  yield takeEvery(POST_FINALIZEPROPOSAL, postFinalizeProposal)
  yield takeEvery(GET_FINALIZEPROPOSAL_DETAIL, getFinalizeProposalDetail)
  yield takeEvery(CONFIRM_FINALIZEPROPOSAL, confirmFinalizeProposal)

}

export default AppFinalizeProposalSaga





