import React, { useState, useMemo, useEffect } from "react"
import { Row, Col, Card, CardBody, UncontrolledTooltip, Label } from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import axios from "axios"
// import { CustKeyId, CompanyName, Industry, BusinessUnitOwner, Status } from "pages/Customers/companies/CompanyListCol"
import getHeader from "config/header"
import Approval from "../../../../components/Common/ApproveModal";
import { useHistory, Link } from "react-router-dom"
import { API_BASE_URL, BUSINESS_URL } from "helpers/url_helper"
import { useDispatch, useSelector } from "react-redux"
import { getAppBusiness, getFinalizeProposals, getInvoices, getPricingmodels, getProposalVersion, updateInvoice, updatePricingmodel, updateProposalVersion } from "store/actions"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ApproveCreatePricingModal from "components/Common/ApprovalModelComponent/ApproveCreatePricingModal"
import { InvoiceID, Company, SiteName, SiteId, BusinessUnit, OpportunityId, Total, Status } from "../../../Finance/Invoices/InvoiceListCol";
import ApproveInvoiceModal from "components/Common/ApprovalModelComponent/ApproveInvoiceModal"
import { Amount, ProductkeyID, CloseQuantity, Date, Remarks, Version, Quantities } from "../../../Sales/Opportunities/OpportunitiesListCol"
import ApproveCustomProductModal from "components/Common/ApprovalModelComponent/ApproveCustomProductModal"

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

function ApprovalCustomProducts() {
    const query = useQuery();
    const history = useHistory()
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const dispatch = useDispatch();
    const queryMultistatus = query.get("queryMultistatus");
    const Busid = query.get("Busid");
    const [pageCount,setPageCount ] = useState(1);


    const proposalVersionReducerState = useSelector(state => state.ProposalVersionReducer)
    const [confirmProposalList, setConfirmProposalList] = useState([]);
    const [customFilterData, setCustomFilterData] = useState("");

    useEffect(() => {
        handlePageClick()
    }, [page, pageLimit, queryMultistatus, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/approvals/opportunities?tab=${2}&&skip=${0}&&limit=${pageLimit}&&queryMultistatus=${"all"}`)
        }
    }, [customFilterData])

    // const handlePageClick = (index) => {
    //     let filter = `{"confirm_proposal":${true},"$or":[
    //         {"proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}},
    //         {"proposal_version":{"$regex":"${customFilterData}","$options":"i"}}
    //       ]}`
    //     // dispatch(getProposalVersion({filter: `{"confirm_proposal":${true}}`,limit:0,skip:0}));
    //     dispatch(getProposalVersion({ filter: filter, limit: pageLimit, skip: 0 }));

    // }

    const handlePageClick = (index) => {
        let filter1 = `{"confirm_proposal":${true},"$or":[
            {"proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"proposal_version":{"$regex":"${customFilterData}","$options":"i"}}
          ]}`
        let filter2 = `{"confirm_proposal":${true},"approval_custom_pricingmodel_status":"${queryMultistatus}","$or":[
            {"proposal_keyid":{"$regex":"${customFilterData}","$options":"i"}},
            {"proposal_version":{"$regex":"${customFilterData}","$options":"i"}}
          ]}`

        let skips = 0;
        if (queryMultistatus === "all") {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getProposalVersion({ filter: filter1, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getProposalVersion({ filter: filter1, limit: pageLimit, skip: 0 }));
            }
        } else {
            if (page > 0) {
                skips = page * pageLimit;
                dispatch(getProposalVersion({ filter: filter2, limit: pageLimit, skip: skips }));
            }
            else {
                dispatch(getProposalVersion({ filter: filter2, limit: pageLimit, skip: 0 }));
            }
        }
    }


    useEffect(() => {
        if (proposalVersionReducerState.Proposalversions !== null) {
            setConfirmProposalList(proposalVersionReducerState.Proposalversions.result);
            setPageCount(Math.ceil(proposalVersionReducerState.Proposalversions.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(proposalVersionReducerState.Proposalversions.summary.filteredsize / pageLimit)
            );
        }
        if (proposalVersionReducerState.isUpdated) {
            handlePageClick()
        }
    }, [proposalVersionReducerState])


    const [customProductModal, setCustomProductModal] = useState(false);
    const [customDataTBA, setCustomDataTBA] = useState('')

    const onClickApproveCustomProduct = (customData) => {
        setCustomProductModal(true);
        setCustomDataTBA(customData);
    }

    const handleApproveCustomProduct = () => {

        if (customDataTBA.proposal_id) {
            const newData = {
                approval_custom_pricingmodel_status: "Approved"
            }
            const updateDatas = {
                proposalId: customDataTBA.proposal_id,
                updatedData: newData
            }
            dispatch(updateProposalVersion(updateDatas))
            setCustomProductModal(false);
        }
    };

    const handleRejectCustomProduct = () => {
        screenTop
        const customValue = {
            approval_custom_pricingmodel_status: "Rejected"
        }
        if (customDataTBA.proposal_id) {

            const updateDatas = {
                proposalId: customDataTBA.proposal_id,
                updatedData: customValue
            }
            dispatch(updateProposalVersion(updateDatas))
            setCustomProductModal(false);
        }
    };



    const columns = useMemo(
        () => [
            {
                Header: 'Proposal ID',
                accessor: 'proposal_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductkeyID  {...cellProps} />;
                }
            },
            {
                Header: 'Version',
                accessor: 'proposal_version',
                filterable: true,
                Cell: (cellProps) => {
                    return <Version statusdata={cellProps.row.original} {...cellProps} />;
                }
            },
            {
                Header: 'Date',
                accessor: 'proposal_date',
                filterable: true,
                Cell: (cellProps) => {
                    return <Date  {...cellProps} />;
                }
            },
            {
                Header: 'Quantities ',
                accessor: 'quantities',
                filterable: true,
                Cell: (cellProps) => {
                    return <Quantities {...cellProps} />;
                }
            },
            {
                Header: 'Amount',
                accessor: 'grandTotals',
                filterable: true,
                Cell: (cellProps) => {
                    return <Amount {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'approval_custom_pricingmodel_status',
                filterable: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {<href
                                className="text-info"
                                onClick={() => {
                                    const customData = cellProps.row.original;
                                    onClickApproveCustomProduct(customData);
                                }}
                            >
                                <i className='mdi mdi-checkbox-marked-outline font-size-18' id={"approveTooltip" + cellProps.row.original.proposal_id}></i>
                                <UncontrolledTooltip placement="top" target={"approveTooltip" + cellProps.row.original.proposal_id}>
                                    Approve
                                </UncontrolledTooltip>
                            </href>}
                        </div>
                    );
                }
            },
        ],
        [confirmProposalList]
    );
    return (
        <React.Fragment>

            <ApproveCustomProductModal
                show={customProductModal}
                onCloseClick={() => setCustomProductModal(false)}
                onApproveClick={handleApproveCustomProduct}
                onRejectClick={handleRejectCustomProduct}
                data={customDataTBA}
                title="Custom PricingModel"
            />

            <Row>
                <Label><u>Invoice </u></Label>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns}
                                data={confirmProposalList}
                                isGlobalFilter={true}
                                customPageSize={10}
                                className="custom-header-css"
                                customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                handlePageClick={handlePageClick}
                                // isCompanyDropdownforInvoiceList={true}
                                customPageCount={pageCount}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default ApprovalCustomProducts



