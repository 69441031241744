import React, { useState, useEffect, useMemo } from "react"
import { useLocation } from 'react-router-dom'
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap'
import axios from "axios";
import getHeader from "config/header";
import { useContext } from "react";
import creditData from "pages/Finance/Credits/Credit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as url from "../../../helpers/url_helper";
import TableContainer from "../TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessProducts, getPricingmodels, getSysUserProduct } from "store/actions";
import { useForm } from "react-hook-form";
import Select from 'react-select'
import { ProductID, ProductName, PosItemCode, ProductType, OriginalPrice, UnitPrice, DiscountUnitPrice, TierQuantity, RowTotal } from "./CustomOpportunityListCol"
import { toast } from "react-toastify";

function tierComponent({ show, onUpdatedData, onCloseClick, onData }) {
    const initLocalData = (onData) => {
        return {
            ...onData,
            flatRateList: onData?.flatRateList?.map(item => ({ ...item })) ?? []
        }
    }

    const [onLocalData, setOnLocalData] = useState(initLocalData(onData))
    const dispatch = useDispatch()


    const { setValue: setValue2, register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, getValues: getValues2 } = useForm()


    const [pricingModelList, setPricingModelList] = useState([])
    const [singlePricingModel, setSinglePricingModel] = useState()

    const pricingModelState = useSelector(state => state.PricingModelReducer)

    const [filteredProduct, setFilteredProduct] = useState()

    useEffect(() => {

        if (pricingModelState.PricingModels !== null) {
            setPricingModelList(pricingModelState.PricingModels.result)
        }
        if (singlePricingModel !== null) {
            let PricingModelIdMaped = singlePricingModel?.app_pricing_model_product?.map(item => item.app_product_id)
            function getProductDetails(productId) {
                return productList.find(product => product.app_product_id === productId);
            }

            const productDetailsArray = [];
            PricingModelIdMaped?.forEach(productId => {
                const productDetails = getProductDetails(productId);
                if (productDetails) {
                    productDetailsArray.push(productDetails);
                }
            });

            setFilteredProduct(productDetailsArray)
        }

    }, [pricingModelState, singlePricingModel])

    useEffect(() => {
        dispatch(getPricingmodels({ filter: `{"is_active":${true},"approval_status":"Approved","app_pricing_model_type":"Tier"}`, limit: 0, skip: 0 }))
    }, [])


    const [tierList, setTierList] = useState([])

    const [selectedMultiProduct, setselectedMultiProduct] = useState([]);
    const [optionGroupProduct, setOptionGroupProduct] = useState([{ options: [] }])
    register2('app_business_unit_product');
    const businessProductState = useSelector(state => state.BusinessProducts);
    const [productList, setProductList] = useState([])

    useEffect(() => {
        dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));
    }, [])

    useEffect(() => {
        if (businessProductState.products !== null) {
            setProductList(businessProductState.products.result);
        }
    }, [businessProductState])

    useEffect(() => {
        if (filteredProduct) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in filteredProduct) {
                let data = {
                    label: filteredProduct[i].app_product_pos_item_code + " | " + filteredProduct[i].app_product_payment_code + " | " + filteredProduct[i].app_product_name,
                    value: filteredProduct[i].app_product_id
                }
                options.push(data)
            }
            setOptionGroupProduct(options)
        }
    }, [filteredProduct])

    useEffect(() => {
        if (onData.pricingmodel_id && pricingModelList && filteredProduct) {
            // console.log(onData);
            let prod = onData?.tierList?.map(item => item.app_product_id);
            if (onData?.tierList?.length > 0) {
                appBuProduct(prod)
            }

            function getPricingModelDetails(pricingModelId) {
                return pricingModelList?.find(product => product.pricingmodel_id === pricingModelId);
            }
            const pricingModelDetails = getPricingModelDetails(onData?.pricingmodel_id);
            setSinglePricingModel(pricingModelDetails);
            setValue2("pricingmodel_id", onData?.pricingmodel_id)
            if (onData?.pricingmodel_id) {
                let data = {
                    label: pricingModelDetails?.pricingmodel_keyid + " | " + pricingModelDetails?.app_pricing_model_name,
                    value: pricingModelDetails?.pricingmodel_id
                }
                setselectedMultiPricingModel(data)
                // appBuProduct(prod)
            }
            setProductInputDisabled(false)
        }
    }, [onData, pricingModelList, filteredProduct])

    const appBuProduct = (Items) => {
        if (Items.length > 0 && filteredProduct) {
            let Data = Items.map((item) => {
                let productlist = filteredProduct.find(productlist => productlist.app_product_id === item)
                if (productlist) {
                    return {
                        label: productlist.app_product_pos_item_code + " | " + productlist.app_product_payment_code + " | " + productlist.app_product_name,
                        value: productlist.app_product_id
                    }
                }
            }).filter(Boolean)
            setselectedMultiProduct(Data)
            setValue2("app_business_unit_product", Data)
        }
    }

    const handleChangeProduct = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values
        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupProduct.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue2('app_business_unit_product', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue2('app_business_unit_product', postArray)
                }
            }
        });
        // setProductList(postArray)
        setselectedMultiProduct(postArray);
    };

    const createTier = (data) => {

        const allHaveQuantity = tierList.every(product => product.hasOwnProperty('quantity'));

        if (tierList.length > 0) {
            if (allHaveQuantity) {
                const pri_id = getValues2("pricingmodel_id")
                let newData = {
                    tierList,
                    pricingmodel_id: pri_id,
                    app_pricing_model_name: singlePricingModel.app_pricing_model_name,
                    app_pricing_model_qualifying_type: singlePricingModel.app_pricing_model_qualifying_type,
                    app_pricing_model_discount_type: singlePricingModel.app_pricing_model_discount_type,
                    tiers: singlePricingModel.tiers
                }

                onUpdatedData(newData)
                onCloseClick()
            } else {
                toast.error("Plese Enter the Product Quantity ")
            }
        } else {
            toast.error("Plese select One or More Product")
        }
    }

    useEffect(() => {
        let prod = []
        if (selectedMultiProduct && filteredProduct?.length > 0) {
            let Data = selectedMultiProduct.map((item) => {
                let productlist = filteredProduct?.find(productlist => productlist.app_product_id === item.value)
                prod.push(productlist)
            })
            init(prod)
        }
    }, [selectedMultiProduct, filteredProduct])



    useEffect(() => recalculatePrice(singlePricingModel), [singlePricingModel])

    const recalculatePrice = (singlePricingModel) => {

        let clonedData = tierList.map(item => ({ ...item }))

        clonedData.forEach(item => {
            let quantity = item.quantity
            if (singlePricingModel !== undefined && singlePricingModel.app_pricing_model_discount_type === "By Percentage") {

                let quantity = item.quantity
                // let applicableTier = 0;
                // // Iterate through tiers to find applicable discount
                // for (let tier of singlePricingModel.tiers) {
                //     if (parseInt(quantity) > parseInt(tier.app_tier_qualifying_input)) {
                //         applicableTier = parseInt(tier.app_tier_discount_value);
                //     }
                // }



                let applicableTier = 0;
                for (let i = 0; i < singlePricingModel.tiers.length; i++) {
                    let tier = singlePricingModel.tiers[i];
                    // Parsing input range dynamically
                    let qualifyingInput = parseInt(tier.app_tier_qualifying_input);
                    let lowerBound = 1; // Default lower bound
                    let upperBound = qualifyingInput;

                    if (i === singlePricingModel.tiers.length - 1) {
                        lowerBound = qualifyingInput;
                        upperBound = 99999999999999;
                    }

                    if (parseInt(quantity) >= lowerBound && parseInt(quantity) <= upperBound) {
                        applicableTier = parseInt(tier.app_tier_discount_value);
                        break;
                    }
                }


                let discountValue = parseInt(applicableTier) / 100
                let discount = discountValue * (item.unit_price * quantity)
                item.rowTotal = (item.unit_price * quantity) - discount;
                item.discounted_unit_price = item.rowTotal / quantity

            } else if (singlePricingModel !== undefined && singlePricingModel.app_pricing_model_discount_type === "By Value") {
                let quantity = item.quantity
                // let applicableDiscount = 0;
                // Iterate through tiers to find applicable discount
                // for (let tier of singlePricingModel.tiers) {
                //     if (parseInt(quantity) > parseInt(tier.app_tier_qualifying_input)) {
                //         applicableDiscount = parseInt(tier.app_tier_discount_value);
                //     }
                // }

                let applicableDiscount = 0;
                for (let i = 0; i < singlePricingModel.tiers.length; i++) {
                    let tier = singlePricingModel.tiers[i];
                    // Parsing input range dynamically
                    let qualifyingInput = parseInt(tier.app_tier_qualifying_input);
                    let lowerBound = 1; // Default lower bound
                    let upperBound = qualifyingInput;

                    if (i === singlePricingModel.tiers.length - 1) {
                        lowerBound = qualifyingInput;
                        upperBound = 99999999999999;
                    }

                    if (parseInt(quantity) >= lowerBound && parseInt(quantity) <= upperBound) {
                        applicableDiscount = parseInt(tier.app_tier_discount_value);
                        break;
                    }
                }

                let discountValue = applicableDiscount
                item.rowTotal = (parseFloat(item.unit_price) * parseInt(quantity)) - discountValue
                item.discounted_unit_price = item.rowTotal / parseInt(quantity)
            } else {
                // Else statement logic here ...

                item.rowTotal = item.unit_price * item.quantity
                item.discounted_unit_price = item.unit_price
            }
        })

        setTierList(clonedData)
    }


    const handleQty = (id, unit_price, qty) => {
        // console.log("singlePricingModel", singlePricingModel);
        let clonedData = tierList.map(item => ({ ...item }));
        let index = clonedData.findIndex(item => item.app_product_id === id);
        if (index < 0) return;
        // let rowTotal = parseInt(qty) * parseInt(unit_price);


        let rowTotal = (qty * unit_price)

        if (singlePricingModel !== undefined && singlePricingModel.app_pricing_model_discount_type === "By Percentage") {
            // Find the applicable tier based on quantity
            // let applicableTier = singlePricingModel.tiers.find(tier => parseInt(qty) >= parseInt(tier.app_tier_qualifying_input));


            // let applicableTier = 0;
            // Iterate through tiers to find applicable discount
            // for (let tier of singlePricingModel.tiers) {
            //     if (parseInt(qty) > parseInt(tier.app_tier_qualifying_input)) {
            //         applicableTier = parseInt(tier.app_tier_discount_value);
            //     }
            // }

            let applicableTier = 0;
            for (let i = 0; i < singlePricingModel.tiers.length; i++) {
                let tier = singlePricingModel.tiers[i];
                // Parsing input range dynamically
                let qualifyingInput = parseInt(tier.app_tier_qualifying_input);
                let lowerBound = 1; // Default lower bound
                let upperBound = qualifyingInput;

                if (i === singlePricingModel.tiers.length - 1) {
                    lowerBound = qualifyingInput;
                    upperBound = 99999999999999;
                }

                if (parseInt(qty) >= lowerBound && parseInt(qty) <= upperBound) {
                    applicableTier = parseInt(tier.app_tier_discount_value);
                    console.log("applicableTier", applicableTier);
                    break;
                }
            }



            if (applicableTier) {
                let discountValue = parseInt(applicableTier) / 100;
                let discount = discountValue * rowTotal;
                let discountedTotal = rowTotal - discount;
                let discountedUnitPrice = discountedTotal / qty

                clonedData[index] = {
                    ...clonedData[index],
                    discount: applicableTier + " %",
                    discounted_unit_price: discountedUnitPrice,
                    quantity: qty,
                    rowTotal: discountedTotal.toString()
                };

            } else {

                clonedData[index] = {
                    ...clonedData[index],
                    discount: "0 %",
                    discounted_unit_price: unit_price,
                    quantity: qty,
                    rowTotal: rowTotal.toString()
                };
            }

            setTierList(clonedData);
        } else if (singlePricingModel !== undefined && singlePricingModel.app_pricing_model_discount_type === "By Value") {

            let discountValue = singlePricingModel.app_pricing_model_discount_value;
            // let applicableDiscount = 0;
            // // Iterate through tiers to find applicable discount
            // for (let tier of singlePricingModel.tiers) {
            //     if (parseInt(qty) > parseInt(tier.app_tier_qualifying_input)) {
            //         applicableDiscount = parseInt(tier.app_tier_discount_value);
            //     }
            // }


            // let applicableDiscount = 0;
            // for (let tier of singlePricingModel.tiers) {
            //     // Parsing input range dynamically
            //     let qualifyingInput = parseInt(tier.app_tier_qualifying_input);
            //     let lowerBound = 1; // Default lower bound
            //     let upperBound = qualifyingInput;

            //     // Checking if qty falls within the dynamically calculated range
            //     if (parseInt(qty) >= lowerBound && parseInt(qty) <= upperBound) {
            //         applicableDiscount = parseInt(tier.app_tier_discount_value);
            //         console.log("applicableDiscount", applicableDiscount);
            //         // Exit the loop once a matching tier is found
            //         break;
            //     }
            // }


            let applicableDiscount = 0;
            for (let i = 0; i < singlePricingModel.tiers.length; i++) {
                let tier = singlePricingModel.tiers[i];
                // Parsing input range dynamically
                let qualifyingInput = parseInt(tier.app_tier_qualifying_input);
                let lowerBound = 1; // Default lower bound
                let upperBound = qualifyingInput;

                if (i === singlePricingModel.tiers.length - 1) {
                    lowerBound = qualifyingInput;
                    upperBound = 99999999999999;
                }

                if (parseInt(qty) >= lowerBound && parseInt(qty) <= upperBound) {
                    applicableDiscount = parseInt(tier.app_tier_discount_value);
                    break;
                }
            }




            let discountedTotal = rowTotal - applicableDiscount;
            if (discountedTotal < 0) {
                discountedTotal = 0;
            }
            // let discountedUnitPrice = unit_price - applicableDiscount


            let discountedUnitPrice = discountedTotal / qty
            clonedData[index] = {
                ...clonedData[index],
                discount: " $ " + applicableDiscount,
                discounted_unit_price: discountedUnitPrice,
                quantity: qty,
                rowTotal: discountedTotal.toString()
            };
            setTierList(clonedData);
        } else {
            clonedData[index] = {
                ...clonedData[index],
                discount: " $ 0 ",
                discounted_unit_price: unit_price,
                quantity: qty,
                rowTotal: rowTotal.toString()
            };
            setTierList(clonedData);
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, index) => index + 1,
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Product ID',
                accessor: 'app_product_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductID datas={cellProps.row.original} {...cellProps} />;
                }
            },
            {
                Header: 'Product Name',
                accessor: 'app_product_local_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductName  {...cellProps} />;
                }
            },
            {
                Header: 'POS Item Code',
                accessor: 'app_product_pos_item_code',
                filterable: true,
                Cell: (cellProps) => {
                    return <PosItemCode  {...cellProps} />;
                }
            },

            {
                Header: 'Product Type',
                accessor: 'product_type',
                filterable: true,
                Cell: (cellProps) => {
                    return <ProductType  {...cellProps} />;
                }
            },
            {
                Header: 'Original price',
                accessor: 'selling_price',
                filterable: true,
                Cell: (cellProps) => {
                    return <OriginalPrice  {...cellProps} />;
                }
            },
            {
                Header: 'Unit Price',
                accessor: 'unit_price',
                filterable: true,
                Cell: (cellProps) => {
                    return <UnitPrice {...cellProps} />;
                }
            },
            {
                Header: 'Discounted Unit Price',
                accessor: 'discounted_unit_price',
                filterable: true,
                Cell: (cellProps) => {
                    return <DiscountUnitPrice  {...cellProps} />;
                }
            },
            {
                Header: 'Quantity',
                accessor: 'quantity',
                filterable: true,
                Cell: (cellProps) => {
                    return <TierQuantity qty={cellProps.row.original.quantity} {...cellProps} handleQty={handleQty} {...cellProps} />;
                }
            },
            {
                Header: ' Row Total ',
                accessor: 'rowTotal',
                filterable: true,
                Cell: (cellProps) => {
                    return <RowTotal  {...cellProps} />;
                }
            },

        ],
        [tierList, singlePricingModel]
    );



    const init = (prod) => {
        if (onLocalData.pricingmodel_id) {
            const newData = prod.map(item => ({ ...item }))
            newData.forEach(item => {
                let ID = item.app_product_id
                let index = onLocalData.tierList.findIndex(dta => dta.app_product_id === ID)
                if (index >= 0) {
                    item.quantity = onLocalData.tierList[index].quantity
                    item.rowTotal = onLocalData.tierList[index].rowTotal
                    item.discounted_unit_price = onLocalData.tierList[index].discounted_unit_price
                    item.discount = onLocalData.tierList[index].discount
                } else {
                    let index = tierList.findIndex(dta => dta.app_product_id === ID)
                    if (index >= 0) {
                        item.quantity = tierList[index].quantity
                        item.rowTotal = tierList[index].rowTotal
                        item.discounted_unit_price = tierList[index].discounted_unit_price
                        item.discount = tierList[index].discount
                    }
                }
            })
            setTierList(newData)
        } else {
            const newData = prod.map(item => ({ ...item }))
            newData.forEach(item => {
                let ID = item.app_product_id
                let index = tierList.findIndex(dta => dta.app_product_id === ID)
                if (index >= 0) {
                    item.quantity = tierList[index].quantity
                    item.rowTotal = tierList[index].rowTotal
                    item.discounted_unit_price = tierList[index].discounted_unit_price
                    item.discount = tierList[index].discount
                }
            })
            setTierList(newData)
        }
    }


    const pricingModelID = (e) => {
        setselectedMultiProduct([])
        function getPricingModelDetails(pricingModelId) {
            return pricingModelList.find(product => product.pricingmodel_id === pricingModelId);
        }
        const pricingModelDetails = getPricingModelDetails(e);
        setTierList([])
        setSinglePricingModel(pricingModelDetails);
        setProductInputDisabled(false)
        setOnLocalData({
            id: onLocalData.id,
            tierList: []
        })
    }

    const displayTierValues = (singlePricingModel) => {
        const tiers = singlePricingModel.tiers;

        const tierValues = tiers.map((tier, index) => {
            const startRange = index === 0 ? 1 : parseInt(tiers[index - 1]?.app_tier_qualifying_input || 0) + 1;
            const endRange = index === tiers.length - 1 ? "Infinity" : tier.app_tier_qualifying_input;
            return (
                <div key={tier.localID}>
                    <h5>
                        <b>Qualifying Value:</b> {startRange} - {endRange}: <b>Discount:</b> {tier.app_tier_discount_value}
                    </h5>
                </div>
            );
        });
        return tierValues;
    }



    const [productInputDisabled, setProductInputDisabled] = useState(true)
    const customStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: "#D2D4D1", // Change this to your desired background color
        }),
    };



    const [selectedMultiPricingModel, setselectedMultiPricingModel] = useState([]);
    const [optionGroupPricingModel, setOptionGroupPricingModel] = useState([{ options: [] }])
    register2('pricingmodel_id');

    useEffect(() => {
        if (pricingModelList) {
            let options = []
            for (let i in pricingModelList) {
                let data = {
                    label: pricingModelList[i]?.pricingmodel_keyid + " | " + pricingModelList[i]?.app_pricing_model_name,
                    value: pricingModelList[i]?.pricingmodel_id
                }
                options.push(data)
            }
            setOptionGroupPricingModel(options)
        }
    }, [pricingModelList])


    const handleChangePricingModel = (Data) => {
        pricingModelID(Data?.value)
        setValue2('pricingmodel_id', Data.value)
        setselectedMultiPricingModel(Data);
    };

    return (
        <React.Fragment>
            <h4>Pricing Model - Tiers</h4>
            <Form onSubmit={handleSubmit2(createTier)}>

                <Row style={{ marginLeft: '10px' }}>
                    <Row className="mt-5">

                        <Col lg="8">
                            <FormGroup row>
                                <Label lg="1" md="2" className="d-flex justify-content-lg-end justify-content-md-end ">PricingModel <span style={{ color: "red" }}>*</span> </Label>
                                <Col lg="7" md="7">
                                    {/* <select onClick={(e) => { pricingModelID(e); }} className="form-select d-inline" aria-label="Default select example" {...register2("pricingmodel_id", { required: true })}>
                                        <option hidden value="">Pricing Model</option>
                                        {pricingModelList.map((pricingModel) => (
                                            <option key={pricingModel.pricingmodel_id} value={pricingModel.pricingmodel_id}>{pricingModel.pricingmodel_keyid} {" | "}{pricingModel.app_pricing_model_name}</option>
                                        ))}
                                    </select> */}
                                    <Select
                                        value={selectedMultiPricingModel}
                                        // isMulti={false}
                                        onChange={handleChangePricingModel}
                                        options={optionGroupPricingModel}
                                        name={"pricingmodel_id"}
                                        className="modal__input"
                                    />
                                    {errors2.pricingmodel_id && <p style={{ color: "red" }}>This Field is required</p>}
                                </Col>
                            </FormGroup>

                            <Row>
                                <Col lg="12" >
                                    <FormGroup row>
                                        <Label lg="1" md="2" className="d-flex justify-content-lg-end justify-content-md-end">Product <span style={{ color: "red" }}>*</span> </Label>
                                        <Col lg="7" md="7">

                                            <Select
                                                value={selectedMultiProduct.length > 0 ? selectedMultiProduct : selectedMultiProduct}
                                                isMulti={true}
                                                styles={productInputDisabled ? customStyles : " "}
                                                isDisabled={productInputDisabled}
                                                onChange={handleChangeProduct}
                                                options={optionGroupProduct}
                                                name={"app_product_id"}
                                                className="modal__input"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        {singlePricingModel !== undefined && (
                            <Col lg="4" md="4" sm="4" xs="4">
                                <div className="float-end">
                                    <h5><b> Qualifying Type: &nbsp; </b>{singlePricingModel.app_pricing_model_qualifying_type}</h5>
                                    <h5><b> Discount Type: &nbsp; </b>{singlePricingModel.app_pricing_model_discount_type}</h5>
                                    {/* {console.log("singlePricingModel", singlePricingModel)} */}
                                    {displayTierValues(singlePricingModel)}
                                </div>
                            </Col>
                        )}

                    </Row>
                </Row>

                <Row>
                    <Col xs="12">

                        <Card>
                            <CardBody>
                                <TableContainer
                                    columns={columns}
                                    data={tierList}
                                    // data={opportunitieses}
                                    // isGlobalFilter={true}
                                    // isAddOpportunities={permissions.includes("system.orders.order.write") ? true : false}
                                    customPageSize={10}
                                    className="custom-header-css"
                                // handlePageClick={handlePageClick}
                                // customPageCount={pageCount}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {/* <Col lg="12" className="d-flex justify-content-end">
                    <h4 className="me-4">
                        {"QTY " + " : " + `${getQuantity3(tierList.length > 0 ? tierList : '')}` + " | " + "RowTotal" + ": " + " $ " + `${getSubTotal3(tierList.length > 0 ? tierList : '')}`}
                    </h4>
                </Col> */}

                <Row>
                    <Col lg="12" className="d-flex justify-content-end">
                        <FormGroup>
                            {/* <Button color='secondary' type='button' onClick={() => setCreateQuotationPage(false)}>
                                Back
                            </Button> */}
                            <Button color='secondary' type='button' onClick={onCloseClick}>
                                Back
                            </Button>
                            <Button id='createQuotation' color='success' type='submit' className='m-2 me-4'>
                                Update
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>

        </React.Fragment>
    )
}

export default tierComponent