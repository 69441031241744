import React, { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Col, FormGroup, Label, Modal, ModalBody, Row } from "reactstrap"
import { Badge } from 'reactstrap';
import { useSelector } from "react-redux";

const CustomAlert = ({ show,onCloseClick,data}) => {

  const [generateCouponStatus, setGenerateCouponStatus] = useState("");

  const couponGenerateState = useSelector(state => state.CouponGenerateReducer)

  useEffect(() => {
    if (couponGenerateState?.createcouponGenerate !== null) {
      setGenerateCouponStatus(couponGenerateState?.createcouponGenerate?.status);
    }
  }, [couponGenerateState])


  return (
      <Modal isOpen={show} backdrop="static" centered={true} size='lg' style={{width:"40%"}}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
               {/* <h3 style={{ color: "#556EE6" }}> <span style={{ textTransform: "capitalize" }}>{name}</span></h3>  */}
              <p className='font-size-18'>Generate Download Coupon Status</p>
              <>
              <>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Col lg="12" md="12" className="justify-content-center">
                        {generateCouponStatus === "StatusError" && <Badge
                          className={"font-size-12 mt-3 badge-soft-" +
                            (generateCouponStatus === "StatusError" ? "danger" : "")}
                          pill
                        >
                          {generateCouponStatus}
                        </Badge>}

                        {generateCouponStatus === "Waiting for CSMS coupon status confirmation" && <Badge
                          className={"font-size-12 mt-3 badge-soft-" +
                            (generateCouponStatus === "Waiting for CSMS coupon status confirmation" ? "danger" : "")}
                          pill
                        >
                          {generateCouponStatus}
                        </Badge>}

                        {generateCouponStatus === "CSV file sucessfully generated" && <Badge
                          className={"font-size-14 mt-3 badge-soft-" +
                            (generateCouponStatus === "CSV file sucessfully generated" ? "success" : "")}
                          pill
                        >
                          {generateCouponStatus}
                        </Badge>}

                        {generateCouponStatus === "Coupon still processing" && <Badge
                          className={"font-size-14 mt-3 badge-soft-" +
                            (generateCouponStatus === "Coupon still processing" ? "warning" : "")}
                          pill
                        >
                          {generateCouponStatus}
                        </Badge>}

                        {generateCouponStatus === "Coupon already generated" && <Badge
                          className={"font-size-14 mt-3 badge-soft-" +
                            (generateCouponStatus === "Coupon already generated" ? "warning" : "")}
                          pill
                        >
                          {generateCouponStatus}
                        </Badge>}

                        {generateCouponStatus === "Coupon generation process started" && <Badge
                          className={"font-size-14 mt-3 badge-soft-" +
                            (generateCouponStatus === "Coupon generation process started" ? "warning" : "")}
                          pill
                        >
                          {generateCouponStatus}
                        </Badge>}
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </>
              </>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger btn-sm ms-2 mt-3 custom-width"
                onClick={onCloseClick}
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
   
  )
}
CustomAlert.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any
}

export default CustomAlert
