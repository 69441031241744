import {
  GET_BUSINESS_PRODUCTS,
  GET_BUSINESS_PRODUCTS_FAIL,
  GET_BUSINESS_PRODUCTS_SUCCESS,

  GET_BUSINESS_PRODUCTS_DETAIL_SUCCESS,
  GET_BUSINESS_PRODUCTS_DETAIL_FAIL,
  GET_BUSINESS_PRODUCTS_DETAIL,
  
  UPDATE_BUSINESS_PRODUCTS_SUCCESS,
  UPDATE_BUSINESS_PRODUCTS_FAIL,
  UPDATE_BUSINESS_PRODUCTS,

} from "./actionTypes";

export const getBusinessProducts = (payload) => ({
  type: GET_BUSINESS_PRODUCTS,
  payload: payload
});

export const getBusinessProductsSuccess = products => ({
  type: GET_BUSINESS_PRODUCTS_SUCCESS,
  payload: products,
});

export const getBusinessProductsFail = error => ({
  type: GET_BUSINESS_PRODUCTS_FAIL,
  payload: error,
});

//////////////////////////////
// Get By id product  //
//////////////////////////////

export const getBusinessProductDetail = (payload) => ({
  type: GET_BUSINESS_PRODUCTS_DETAIL,
  payload: payload
});

export const getBusinessProductDetailSuccess = businessUnit => ({
  type: GET_BUSINESS_PRODUCTS_DETAIL_SUCCESS,
  payload: businessUnit,
});

export const getBusinessProductDetailFail = error => ({
  type: GET_BUSINESS_PRODUCTS_DETAIL_FAIL,
  payload: error,
});


///////////////////////////
// Update product   //
///////////////////////////

export const updateBusinessProduct = (payload) => ({
  type: UPDATE_BUSINESS_PRODUCTS,
  payload: payload
});

export const updateBusinessProductSuccess = payload => ({
  type: UPDATE_BUSINESS_PRODUCTS_SUCCESS,
  payload: payload
});

export const updateBusinessProductFail = error => ({
  type: UPDATE_BUSINESS_PRODUCTS_FAIL,
  payload: error,
});


