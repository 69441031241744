import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Table, Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"

import classnames from 'classnames';
import axios from 'axios';
import getHeader from 'config/header';
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill';
import { formats, modules } from '../../../common/data/rich-text-options';
import 'react-quill/dist/quill.snow.css';
import { postApplicationTemplate } from 'store/actions'
import parse from 'html-react-parser';


function CreateApplicationTemplate() {

    const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()

    const dispatch = useDispatch();
    const history = useHistory()

    const [applicationTemplate, setapplicationTemplate] = useState()

    const [templateInformation, setTemplateInformation] = useState("")
    const [termsandCondition, setTermsandCondition] = useState("")
    const [paymentMethod, setPaymentMethod] = useState("")

    const createApplicationTemplate = (data) => {

        const newData = {
            ...data,
            template_information: templateInformation,
            terms_and_condition: termsandCondition,
            payment_method: paymentMethod
        }

        // console.log("newDAta", newData);

        // const convertHtmlToPlainText = (html) => {
        //     const div = document.createElement('div');
        //     div.innerHTML = html;
        //     return div.textContent || div.innerText || '';
        //   };

        // const convertHtmlToPlainText = (html) => {
        //     const tmp = document.createElement('div');
        //     tmp.innerHTML = html;
        
        //     // Traverse the DOM tree to retain alignment
        //     const convertNodeToText = (node) => {
        //       let text = '';
        //       if (node.nodeType === Node.TEXT_NODE) {
        //         text += node.nodeValue;
        //       } else if (node.nodeType === Node.ELEMENT_NODE) {
        //         const tagName = node.tagName.toLowerCase();
        //         const isBlockElement = ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tagName);
        //         const children = Array.from(node.childNodes).map(childNode => convertNodeToText(childNode)).join('');
        //         text += isBlockElement ? `\n${children}\n` : children;
        //       }
        //       return text;
        //     };
        
        //     return Array.from(tmp.childNodes).map(node => convertNodeToText(node)).join('');
        // };

        // const plainText = convertHtmlToPlainText(newData.terms_and_condition);

        // console.log('Plain Text:', plainText);

        dispatch(postApplicationTemplate(newData));

    }
    const templateState = useSelector(state => state.ApplicationTemplateReducer)

    useEffect(() => {
        if (templateState.isCreated) {
            history.goBack()
        }

    }, [templateState])

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Application Template" breadcrumbItem="Settings" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createApplicationTemplate)}>

                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Template Type</Label>
                                            <Col lg="7" md="6">
                                                <select className="form-select d-inline" {...register('template_type', { required: true })}>
                                                    <option hidden value=" ">select</option>
                                                    <option value="Quotation">Quotation</option>
                                                    <option value="Invoice">Invoice</option>
                                                </select>
                                                {errors.template_type && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Template Name<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <input type="text" className='form form-control' {...register('template_name', { required: true })} />
                                                {errors.template_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    {/* <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Template Information<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <input type="text" className='form form-control' {...register('template_information', { required: true })} />
                                                {errors.template_information && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Terms and Condition<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <input type="text" className='form form-control' {...register('terms_and_condition', { required: true })} />
                                                {errors.terms_and_condition && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>   
                                      <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Payment Methods<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <input type="text" className='form form-control' {...register('payment_method', { required: true })} />
                                                {errors.payment_method && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row> */}


                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Template Information <span style={{ color: "red" }}>* </span></Label>
                                            <Col lg="7" md="6">
                                                <ReactQuill value={templateInformation}  formats={formats} theme="snow" onChange={setTemplateInformation} />
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <Row>
                                        <FormGroup row>

                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Terms and Condition <span style={{ color: "red" }}>* </span></Label>
                                            <Col lg="7" md="6">
                                                <ReactQuill value={termsandCondition} formats={formats} theme="snow" onChange={setTermsandCondition} />
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <Row>

                                        <FormGroup row>

                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Payment Methods <span style={{ color: "red" }}>* </span></Label>
                                            <Col lg="7" md="6">
                                                <ReactQuill value={paymentMethod}  formats={formats} theme="snow" onChange={setPaymentMethod} />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => { history.goBack(); templateState.applicationTemplateDetail =  null }}>
                                                    Back
                                                </Button>
                                                <Button id="createbusinessunits" color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateApplicationTemplate
