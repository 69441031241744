import { takeEvery, put, call } from "redux-saga/effects"
import {
    getSysUsersSuccess, getSysUsersFail,
    getAppSysUserSuccess, getAppSysUserFail,
    postAppSysUsersSuccess, postAppSysUsersFail,
    deleteAppSysUsersSuccess, deleteAppSysUsersFail,
    updateSysUsersSuccess, updateSysUsersFail,
} from "./actions"

// Api Handler 
import {
    getSysUsers,
    postSysUsers,
    deleteSysUser,
    getSysUser,
    updateSysUser,

} from "helpers/backendApi_helpers";


import {
    DELETE_SYS_USERS,
    GET_SYS_USER,
    POST_SYS_USERS,
    UPDATE_SYS_USERS,
    GET_SYS_USERS
} from "./actionTypes";




function* fetchAppSysUsers({ payload: payload }) {
    try {
        const response = yield call(getSysUsers, payload);
        yield put(getSysUsersSuccess(response))
    } catch (error) {
        yield put(getSysUsersFail(error))
    }
}

function* postAppSysUsers({ payload: payload }) {
    try {
        const response = yield call(postSysUsers, payload);
        yield put(postAppSysUsersSuccess(response))
    } catch (error) {
        yield put(postAppSysUsersFail(error))
    }
}

function* deleteAppSysUsers({ payload: payload }) {
    try {
        const response = yield call(deleteSysUser, payload);
        yield put(deleteAppSysUsersSuccess(response))
    } catch (error) {
        yield put(deleteAppSysUsersFail(error))
    }
}

function* getAppSysUser({ payload: payload }) {
    try {
        const response = yield call(getSysUser, payload);
        yield put(getAppSysUserSuccess(response))
    } catch (error) {
        yield put(getAppSysUserFail(error))
    }
}

function* updateAppSysUsers({ payload: payload }) {
    try {
        const response = yield call(updateSysUser, payload);
        yield put(updateSysUsersSuccess(response))
    } catch (error) {
        yield put(updateSysUsersFail(error))
    }
}

function* SysUsersSaga() {
    yield takeEvery(GET_SYS_USERS, fetchAppSysUsers)
    yield takeEvery(POST_SYS_USERS, postAppSysUsers)
    yield takeEvery(DELETE_SYS_USERS, deleteAppSysUsers)
    yield takeEvery(GET_SYS_USER, getAppSysUser)
    yield takeEvery(UPDATE_SYS_USERS, updateAppSysUsers)
}

export default SysUsersSaga






