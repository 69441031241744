import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Card, CardBody, Col, FormGroup, Label, Modal, ModalBody, Row, Form, Button } from "reactstrap"
import TableContainer from '../TableContainer'
import { ProductID, ProductName, PosItemCode, ProductType, OriginalPrice, CustomUnitPrice, CustomQuantity, RowTotal } from "../ApprovalModelComponent/CustomOpportunityListCol"
import TableContainer2 from '../TableContainer2'
import { TransactionValue, TransactionId, TransactionType, TransactionDate } from "../../../../src/pages/Finance/Accounts/AccountListCol"
import { useForm } from 'react-hook-form'
import { getAttachment, getTransaction, postTransaction, updateProposalVersion } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { API_BASE_URL } from 'helpers/url_helper'
import axios from 'axios'
import pdfImage from "../../../assets/images/maxims/pdf_image.jpg"
import { toast } from 'react-toastify'



const AddTransactionModal = ({ show, onApproveClick, onCloseClick, onRejectClick, data, title, updatedData }) => {


  const { setValue, register, reset, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()


  const dispatch = useDispatch();
  const history = useHistory()


  const transactionColumns = useMemo(
    () => [
      {
        Header: 'Transaction ID',
        accessor: 'app_transaction_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionId {...cellProps} />;
        }
      }, {
        Header: 'Transaction Date',
        accessor: 'created_on',
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionDate {...cellProps} />
        }
      },
      {
        Header: 'Transaction Type',
        accessor: 'app_transaction_type',
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionType {...cellProps} />
        }
      },
      {
        Header: 'Transaction Value',
        accessor: 'app_transaction_value',
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionValue {...cellProps} />;
        }
      },
      // {
      //     Header: 'Action',
      //     Cell: (cellProps) => {
      //         return (
      //             <div className="d-flex gap-3">
      //                 {/* <button
      //                     onClick={() => {
      //                         history.push(`/updateTransaction/${cellProps.row.original.app_transaction_id}?Busid=${cellProps.row.original.app_business_id}`)
      //                     }} className="btn btn-success btn-sm" type='button'>Edit</button> */}
      //                 <button
      //                     onClick={() => {
      //                         setApproveModal(true)
      //                         setTransactionSingleData(cellProps.row.original)
      //                         // history.push(`/viewaccount/${cellProps.row.original.app_account_id}?busID=${cellProps.row.original.app_business_id}`)
      //                     }} className="btn btn-info btn-sm" type='button'>Approve</button>
      //             </div>
      //         );
      //     }
      // },
    ], []
  );



  const appTransactionState = useSelector(state => state.AppTransactionReducer);
  const [transactionDetails, setTransactionDetail] = useState([]);

  const [tirgerApi, setTrigerApi] = useState(false)
  const [customFilterData, setCustomFilterData] = useState("");
  useEffect(() => {
    handlePageClick()
  }, [customFilterData, tirgerApi])

  const handlePageClick = (index) => {

    let accountId = data?.app_account_id;
    let filter = JSON.stringify({
      "app_account_id": accountId,
      "$or": [
        { "app_transaction_keyid": { "$regex": customFilterData, "$options": "i" } },
        { "app_transaction_type": { "$regex": customFilterData, "$options": "i" } },
        { "created_on": { "$regex": customFilterData, "$options": "i" } },
        { "app_transaction_value": { "$regex": customFilterData, "$options": "i" } }
      ]
    });


    dispatch(getTransaction({ filter: filter, limit: 20, skip: 0 }));
    setTrigerApi(false)
  }

  useEffect(() => {
    if (appTransactionState.appTransactions !== null) {
      setTransactionDetail(appTransactionState.appTransactions.result);
      // setPageCount(Math.ceil(appTransactionState.appTransactions.summary.filteredsize / pageLimit));
    }
  }, [appTransactionState])



  const createTransaction = (Dta) => {
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`attach_file`, file);
    });

    const token = sessionStorage.getItem('authUser');
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    };




    if (files.length > 0) {
      axios.post(API_BASE_URL + "/filestorage", formData, config)
        .then(res => {

          const datas = {
            ...Dta,
            app_business_id: data.app_business_id,
            app_account_id: data.app_account_id,
            app_department_id: data.app_department_id,
            attachment_file: res.data.files_uploaded
          }

          // console.log("datas", datas);

          dispatch(postTransaction(datas))
          setFiles([])
          setImages([])
          reset()
          onCloseClick();
          setTrigerApi(true)
        }).catch(err => {
          console.log(err);
        });
    } else {
      toast.error("please upload one or more files")
    }
  }


  const handleTransactionTypeChange = (event) => {
    const selectedType = event.target.value;

    setTransactionReadOnly(false)

    // You can set the corresponding default value for app_transaction_value here
    switch (selectedType) {
      case 'DEPOSIT':
        setValue('app_transaction_value', "+");
        break;
      case 'CREDIT':
        setValue('app_transaction_value', "+");
        break;
      case 'DEBIT':
        setValue('app_transaction_value', "-");
        break;
      default:
        setValue('app_transaction_value', '');
        break;
    }
  };
  const [transactionReadOnly, setTransactionReadOnly] = useState(true)


  // const handleChange = (e) => {
  //   const selectedFiles = e.target.files;
  //   setFiles([...files, ...selectedFiles]);

  //   let imageData = e.target.files
  //   const len = imageData.length
  //   for (var i = 0; i < len; i++) {
  //     var imageBlob = imageData[i];
  //     var fileReader = new FileReader()  //1
  //     fileReader.onload = ({ target }) => {  // 3
  //       setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
  //     }
  //     fileReader.readAsDataURL(imageBlob) //2
  //   }
  // }
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([])
  const [removeButton, setRemoveButton] = useState(false)
  const [attachmentSize, setAttachmentSize] = useState("");
  const fileInputRef = useRef(null);

  const MAX_FILE_SIZE = attachmentSize.maxSize * 1024 * 1024;
  const MIN_FILE_SIZE = attachmentSize.minSize * 1024 * 1024;

  const handleChange = (e) => {

    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(file => {
      return file.size >= MIN_FILE_SIZE && file.size <= MAX_FILE_SIZE;
    });

    const validFilessss = selectedFiles.filter(file => {
      if (file.size < MIN_FILE_SIZE || file.size > MAX_FILE_SIZE) {
        alert(`File Size should be ${MIN_FILE_SIZE / (1024 * 1024)} - ${MAX_FILE_SIZE / (1024 * 1024)} MB and were not added.`);
        return false;
      }
      return true;
    });


    setFiles([...files, ...validFiles]);

    validFiles.forEach((file, index) => {
      const fileReader = new FileReader();
      fileReader.onload = ({ target }) => {
        setImages(prevState => ([...prevState, { seq_no: index, imgSrc: target.result }]));
      };
      fileReader.readAsDataURL(file);
    });
    if (validFiles.length === 0) {
      fileInputRef.current.value = '';
    }
  };

  const removeBtnClick = (i) => {
    let newImages = images.map(item => item)
    let newFiles = files.map(item => item)
    newImages.splice(i, 1)
    setImages(newImages)
    newFiles.splice(i, 1)
    setFiles(newFiles)
    if (newFiles.length === 0) {
      fileInputRef.current.value = '';
    }
  }

  const openImage = (image) => {
    let data = image.imgSrc;
    let w = window.open('about:blank');
    let iframe = document.createElement('iframe');
    iframe.src = data;
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    w.document.body.style.margin = '0';
    w.document.body.style.height = '100vh';
    w.document.body.appendChild(iframe);
  }

  const attachmentstate = useSelector(state => state.AttachmentReducer);

  useEffect(() => {
    dispatch(getAttachment())
  }, [])

  const [attachmentarray, setAttachmentarray] = useState([]);

  useEffect(() => {
    if (attachmentstate.attachment !== null) {
      setAttachmentarray(attachmentstate?.attachment?.extension);
      setAttachmentSize(attachmentstate?.attachment)
      // console.log("attachmentstate", attachmentstate?.attachment?.extension);
    }
  }, [attachmentstate])


  return (
    <Modal isOpen={show} backdrop="static" centered={true} size='xl'>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg="12">
            <Card body>
              <Form >
                <p className='font-size-22 d-flex justify-content-lg-center'>Do you want Account  Adjustment ? </p>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end">Account ID </Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data?.app_account_keyid} />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    {/* <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data} />
                      </Col>
                    </FormGroup> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Company</Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.app_business_name} />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label lg="5" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Site Name</Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.app_department_name} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Credit Limit</Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.credit_limit} />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup row>
                      <Label lg="5" md="4" className="d-flex justify-content-end " >Account Balance</Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + parseFloat(data.app_account_balance).toFixed(1)} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <FormGroup row>
                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                      Transaction Type<span className='text-danger'>*</span>
                    </Label>
                    <Col lg="6" md="6">
                      <select className="form-select d-inline" {...register('app_transaction_type', { required: true })} onChange={handleTransactionTypeChange} >
                        <option hidden value="">Select Transaction Type</option>
                        <option value="DEPOSIT">Deposit</option>
                        <option value="CREDIT">Credit</option>
                        <option value="DEBIT">Debit</option>
                      </select>
                      {errors.app_transaction_type && <span style={{ color: "red" }}>This Field is required</span>}
                    </Col>
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup row>
                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                      Transaction Value<span className='text-danger'>*</span>
                    </Label>
                    <Col lg="6" md="6">
                      <input type="text" readOnly={transactionReadOnly} className='form form-control' {...register('app_transaction_value', { required: true })} />
                      {errors.app_transaction_value && <span style={{ color: "red" }}>This Field is required</span>}
                    </Col>
                  </FormGroup>
                </Row>

                <Row>
                  <FormGroup row>
                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment File<span className='text-danger'>*</span></Label>
                    <Col lg="6" md="6">
                      <input className='form form-control' accept={attachmentarray} multiple type="file" onChange={handleChange} ref={fileInputRef} />
                      <div className="d-flex flex-wrap">{images?.map((image, i) => (
                        <div key={i} className="thumbSpan thumbContainer"  >
                          <img className='thumbImage ms-3' id="fileImg" src={image?.imgSrc}
                            onMouseEnter={() => setRemoveButton(true)}
                            onClick={() => openImage(image)}
                            onError={(e) => { e.target.src = pdfImage; }}
                            style={{
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              padding: "5px",
                              width: "125px",
                              height: "125px"
                            }}
                          />
                          {removeButton &&
                            <button type='button'
                              onClick={() => removeBtnClick(i)}
                              className='thumbClose btn btn-danger btn-sm'>
                              X
                            </button>
                          }
                        </div>
                      ))}
                      </div>
                      {errors.app_payment_file && <span style={{ color: "red" }}>This Field is required</span>}
                    </Col>
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup row>
                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment Notes<span className='text-danger'>*</span></Label>
                    <Col lg="6" md="6">
                      <input type="text" className='form form-control'  {...register('app_payment_notes', { required: true })} />
                      {errors.app_payment_notes && <span style={{ color: "red" }}>This Field is required</span>}
                    </Col>
                  </FormGroup>
                </Row>
                <Row className="mt-4">
                  <FormGroup row>
                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                    <Col lg="7" md="6">
                      <Button color='danger' type='button' className='m-2' onClick={() => { onCloseClick(); }}>
                        Close
                      </Button>
                      <Button color='success' id='createCreditBtn' type='submit' onClick={handleSubmit(dta => createTransaction(dta))} className='m-2' >
                        Add
                      </Button>
                    </Col>
                  </FormGroup>
                </Row>
                <u className='font-size-18 d-flex justify-content-lg-start'>Last 20 Transaction of {data.app_business_name} </u>
                <Row style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <TableContainer
                          columns={transactionColumns}
                          data={transactionDetails}
                          // isGlobalFilter={true}
                          className="custom-header-css"
                          // accountId={accountId}
                          customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                        // isAddTransaction={true}
                        // customPageCount={pageCount}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <FormGroup row>
                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                    <Col lg="7" md="6">
                      <Button color='info' id='createCreditBtn' type='button' className='m-2'
                        onClick={() => { history.push(`/viewaccount/${data.app_account_id}?Busid=${data.app_business_id}&&skip=0&&limit=10`) }}  >
                        View more Transaction History
                      </Button>
                    </Col>
                  </FormGroup>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <div className="text-center mt-3">
              <Button id="confirmProposalBtn" color='info' onClick={handleSubmit(dta => updateApproval(dta, "approve"))} type='button' className='m-2 me-4'  >
                Approve
              </Button>
              <Button id="draftBtn" color='secondary' onClick={handleSubmit(dta => updateApproval(dta, "reject"))} type='button' className='m-2'>
                Reject
              </Button>
              <Button color='danger' type='button' className='m-2' onClick={() => { onCloseClick(); }}>
                Back
              </Button>

            </div>
          </Col>
        </Row> */}
      </ModalBody>
    </Modal>
  )
}

AddTransactionModal.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any
}

export default AddTransactionModal