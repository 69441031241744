
import {
  GET_SYS_USER_PRODUCT,
  GET_SYS_USER_PRODUCT_SUCCESS,
  GET_SYS_USER_PRODUCT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  sysUserProducts: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const AppsysUserProductReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SYS_USER_PRODUCT:
      return {
        ...state,
        loading: true,
        sysUserProducts: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_SYS_USER_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        sysUserProducts: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_SYS_USER_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default AppsysUserProductReducer;





