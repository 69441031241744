import { takeEvery, put, call } from "redux-saga/effects"
import {
  getInvoicesFail,
  getInvoicesSuccess,
  postInvoiceSuccess,
  postInvoiceFail,
  deleteInvoiceFail,
  deleteInvoiceSuccess,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
  updateInvoiceSuccess,
  updateInvoiceFail,
  getInvoiceConfirmSuccess,
  getInvoiceConfirmFail
} from "./actions"

import {
  getAppInvoices,
  getAppInvoice,
  postAppInvoice,
  updateAppInvoice,
  deleteAppInvoice,
  getAppInvoiceConfirm
}
  from "helpers/backendApi_helpers";

import {
  DELETE_INVOICE,
  GET_INVOICE,
  GET_INVOICE_DETAIL,
  POST_INVOICE,
  UPDATE_INVOICE,
  GET_INVOICE_CONFIRM
} from "./actionTypes";

function* fetchInvoice({ payload: payload }) {
  try {
    const response = yield call(getAppInvoices, payload);
    yield put(getInvoicesSuccess(response))
  } catch (error) {
    yield put(getInvoicesFail(error))
  }
}

function* postInvoice({ payload: payload }) {
  try {
    const response = yield call(postAppInvoice, payload);
    yield put(postInvoiceSuccess(response))
  } catch (error) {
    yield put(postInvoiceFail(error))
  }
}

function* deleteInvoice({ payload: payload }) {
  try {
    const response = yield call(deleteAppInvoice, payload);
    yield put(deleteInvoiceSuccess(response))
  } catch (error) {
    yield put(deleteInvoiceFail(error))
  }
}

function* getInvoice({ payload: payload }) {
  try {
    const response = yield call(getAppInvoice, payload);
    yield put(getInvoiceDetailSuccess(response))
  } catch (error) {
    yield put(getInvoiceDetailFail(error))
  }
}

function* updateInvoice({ payload: payload }) {
  try {
    const response = yield call(updateAppInvoice, payload);
    yield put(updateInvoiceSuccess(response))
  } catch (error) {
    yield put(updateInvoiceFail(error))
  }
}

function* fetchInvoiceConfirm({ payload: payload }) {
  try {
    const response = yield call(getAppInvoiceConfirm, payload);
    yield put(getInvoiceConfirmSuccess(response))
  } catch (error) {
    yield put(getInvoiceConfirmFail(error))
  }
}

function* AppInvoiceSaga() {
  yield takeEvery(GET_INVOICE, fetchInvoice)
  yield takeEvery(POST_INVOICE, postInvoice)
  yield takeEvery(DELETE_INVOICE, deleteInvoice)
  yield takeEvery(GET_INVOICE_DETAIL, getInvoice)
  yield takeEvery(UPDATE_INVOICE, updateInvoice)
  yield takeEvery(GET_INVOICE_CONFIRM, fetchInvoiceConfirm)
}

export default AppInvoiceSaga





