import { takeEvery, put, call } from "redux-saga/effects"
import {
    getDepartmentsSuccess, getDepartmentsFail,
    getDepartmentSuccess, getDepartmentFail,
    postDepartmentSuccess, postDepartmentFail,
    deleteDepartmentSuccess, deleteDepartmentFail,
    updateDepartmentSuccess, updateDepartmentFail,
    getAllDepartmentSuccess,getAllDepartmentFail
} from "./actions"

// Api Handler 
import {
    getDepartments,
    getDepartment,
    postDepartment,
    updateDepartment,
    deleteDepartment,
    getAllDepartment

} from "helpers/backendApi_helpers";


import {
    GET_DEPARTMENTS,
    GET_DEPARTMENT,
    GET_ALL_DEPARTMENT,
    POST_DEPARTMENT,
    DELETE_DEPARTMENT,
    UPDATE_DEPARTMENT
} from "./actionTypes";




function* fetchAppDepartments({ payload: payload }) {
    try {
        const response = yield call(getDepartments, payload);
        yield put(getDepartmentsSuccess(response))
    } catch (error) {
        yield put(getDepartmentsFail(error))
    }
}

function* postAppDepartment({ payload: payload }) {
    try {
        const response = yield call(postDepartment, payload);
        yield put(postDepartmentSuccess(response))
    } catch (error) {
        yield put(postDepartmentFail(error))
    }
}

function* deleteAppDepartment({ payload: payload }) {
    try {
        const response = yield call(deleteDepartment, payload);
        yield put(deleteDepartmentSuccess(response))
    } catch (error) {
        yield put(deleteDepartmentFail(error))
    }
}

function* getAppDepartment({ payload: payload }) {
    try {
        const response = yield call(getDepartment, payload);
        yield put(getDepartmentSuccess(response))
    } catch (error) {
        yield put(getDepartmentFail(error))
    }
}

function* getAppAllDepartment({ payload: payload }) {
    try {
        const response = yield call(getAllDepartment, payload);
        yield put(getAllDepartmentSuccess(response))
    } catch (error) {
        yield put(getAllDepartmentFail(error))
    }
}

function* updateAppDepartment({ payload: payload }) {
    try {
        const response = yield call(updateDepartment, payload);
        yield put(updateDepartmentSuccess(response))
    } catch (error) {
        yield put(updateDepartmentFail(error))
    }
}

function* DepartmentSaga() {
    yield takeEvery(GET_DEPARTMENTS, fetchAppDepartments)
    yield takeEvery(POST_DEPARTMENT, postAppDepartment)
    yield takeEvery(DELETE_DEPARTMENT, deleteAppDepartment)
    yield takeEvery(GET_DEPARTMENT, getAppDepartment)
    yield takeEvery(GET_ALL_DEPARTMENT, getAppAllDepartment)
    yield takeEvery(UPDATE_DEPARTMENT, updateAppDepartment)
}

export default DepartmentSaga


    
    