import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import { Button, Col, FormGroup, Label, Modal, ModalBody, Row } from "reactstrap"
import { confirmFinalizeProposal, postCoupon, postTransaction, updateInvoice, updateOpportunities } from 'store/actions'

let finalizeProposalRemarks 


const ApproveInvoiceModal = ({ show, onCloseClick, data, title }) => {

  const { setValue, register, reset, handleSubmit, control, formState: { errors }, getValues } = useForm()
  const dispatch = useDispatch()


  const couponState = useSelector(state => state.CouponReducer)

  const updateApproval = (datas, type) => {
    finalizeProposalRemarks = datas
    if (type === "reject") {
      if (data.finalize_proposal_id) {
        const newData = {
          ...datas,
          invoice_approval_status: "Rejected",
        }
        const updateDatas = {
          finalizedProposalId: data.finalize_proposal_id,
          updateData: newData
        }
        console.log("sss", updateDatas);
        dispatch(confirmFinalizeProposal(updateDatas))

        const Datas = {
          opportunitiesId: data.opportunity_id,
          updatedData: {
            step: 2,
            status: "Rejected"
          }
        }
        dispatch(updateOpportunities(Datas))

        onCloseClick()

      const Data = {
        invoiceId: data.invoice_id,
        updatedData: {
          ...datas,
          invoice_approval_status: "Rejected",
        }
      }
      dispatch(updateInvoice(Data))
      }

    } else if (type === "approve") {

      if (data.finalize_proposal_id) {

        const today = new Date();

        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        const requestDate = `${day}-${month}-${year}`;

        const duplicateData = data

        let standardRequestProduct = duplicateData?.standardProducts.map((item) => {
          console.log("iiiiiiiiiiiiiii", item);
          return {
            "b2b_order_id": "566",
            "branch_code": "00000",
            "coupon_request_date": requestDate,
            "coupon_type": item.product_type,
            "item_code": item.app_product_pos_item_code,
            "payment_code": item.app_product_payment_code,
            "request_coupon_qty": item.quantity,
            "pricing_model_type": "standard",
            "app_product_id": item.app_product_id
          };
        });

        let customRequestProduct = duplicateData?.customProducts.map((item) => {
          return {
            "b2b_order_id": "566",
            "branch_code": "00000",
            "coupon_request_date": requestDate,
            "coupon_type": item.product_type,
            "item_code": item.app_product_pos_item_code,
            "payment_code": item.app_product_payment_code,
            "request_coupon_qty": item.quantity,
            "pricing_model_type": "custom",
            "app_product_id": item.app_product_id
          };
        });

        let flatRateRequestProductArray = duplicateData?.flatRateProducts.map((item, i) => (
          item?.flatRateList.map((value) => {
            return {
              "b2b_order_id": "566",
              "branch_code": "00000",
              "coupon_request_date": requestDate,
              "coupon_type": value?.product_type,
              "item_code": value.app_product_pos_item_code,
              "payment_code": value.app_product_payment_code,
              "request_coupon_qty": value.quantity,
              "pricing_model_type": "flatrate",
              "app_product_id": value.app_product_id,
              "group": i
            }
          })
        ));

        let tierRequestProductArray = duplicateData?.tierProducts.map((item, i) => (
          item?.tierList.map((value) => {
            return {
              "b2b_order_id": "566",
              "branch_code": "00000",
              "coupon_request_date": requestDate,
              "coupon_type": value.product_type,
              "item_code": value?.app_product_pos_item_code,
              "payment_code": value.app_product_payment_code,
              "request_coupon_qty": value.quantity,
              "pricing_model_type": "tier",
              "app_product_id": value.app_product_id,
              "group": i
            }
          })
        ));

        const flatRateRequestProduct = flatRateRequestProductArray.flat(1);
        const tierRequestProduct = tierRequestProductArray.flat(1);

        const finalRequestProduct = [...standardRequestProduct, ...customRequestProduct, ...flatRateRequestProduct, ...tierRequestProduct]

        let requestData = {
          "invoice_id": data.invoice_id,
          "createcoupon": finalRequestProduct
        }

        // console.log("requestData", requestData);
        dispatch(postCoupon(requestData))
      }

    }
  }

  useEffect(() => {
    if (couponState.isCreated) {
      // Approved status changed confirmFinalizeProposal
  
      const newData = {
        ...finalizeProposalRemarks,
        invoice_approval_status: "Approved",
      }
  
      const updateDatas = {
        finalizedProposalId: data.finalize_proposal_id,
        updateData: newData
      }
  
      dispatch(confirmFinalizeProposal(updateDatas))
  
      // Opportunity Status Changed to Approved
  
      const Datas = {
        opportunitiesId: data.opportunity_id,
        updatedData: {
          step: 4,
          status: "Approved"
        }
      }
      dispatch(updateOpportunities(Datas))
  
      // Approved status changed Invoice
  
      const Data = {
        invoiceId: data.invoice_id,
        updatedData: {
          ...finalizeProposalRemarks,
          invoice_approval_status: "Approved",
        }
      }
      dispatch(updateInvoice(Data))
  
      // create Transaction invoice approve amount
  
      let item = {
        app_account_id: data?.app_account_id,
        app_business_id: data?.app_business_id,
        app_department_id: data?.app_business_id,
        app_payment_notes: "Automatic",
        app_transaction_type: "DEBIT",
        app_transaction_value: `${"-" + data?.grandTotals}`,
      }
  
      dispatch(postTransaction(item))
      onCloseClick()
      reset()
  
  
      couponState.isCreated = false
    } 
    // else {
    //   toast.error("Create Coupon request Fail")
    // }
  },[couponState])


  return (
    <Modal isOpen={show} backdrop="static" centered={true} size='lg'>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <p className='font-size-18'>Do you want to Approve this {title}? </p>
              <>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Invoice ID </Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.invoice_keyid} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Invoice Date </Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.invoice_date} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Opportunity Id</Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.opportunity_keyid} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Company Name </Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.app_business_name} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Site Name </Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.app_department_name} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Quantity</Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={parseFloat(data.quantities).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Grand Total</Label>
                      <Col lg="7" md="6">
                        <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + " " + parseFloat(data.grandTotals).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Remarks </Label>
                      <Col lg="7" md="6">
                        <input type="text" className='form form-control'  {...register('finalize_proposal_remarks', { required: true })} />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <Button id="confirmProposalBtn" color='success' onClick={handleSubmit(dta => updateApproval(dta, "approve"))} type='button' className='m-2 '  >
                Approve
              </Button>
              <Button id="draftBtn" color='danger' onClick={handleSubmit(dta => updateApproval(dta, "reject"))} type='button' className='m-2'>
                Reject
              </Button>
              <Button color='warning' type='button' className='m-2' onClick={() => { onCloseClick(); }}>
                Back
              </Button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ApproveInvoiceModal.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any
}

export default ApproveInvoiceModal