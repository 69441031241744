import {
    GET_PLATFORM_ACTIONS,
    GET_PLATFORM_ACTIONS_SUCCESS,
    GET_PLATFORM_ACTIONS_FAIL,
  } from "./actionTypes";
  
  const INIT_STATE = {
    actions: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
  };
  
  const AppPlatformActions = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_PLATFORM_ACTIONS:
        return {
          ...state,
          loading: true,
          actions: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_PLATFORM_ACTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          actions: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_PLATFORM_ACTIONS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      default:
        return state;
    }
  };
  
  export default AppPlatformActions;
  