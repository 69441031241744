import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useHistory } from 'react-router-dom'
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"

import JSZip from 'jszip';

function AzureSSO() {
    const history = useHistory()

    const { register, formState: { errors }, getValues } = useForm()

    const [status, setStatus] = useState({
        state: false,
        name: "NO"
    })

    // On Change of RoleStatus
    const statusChange = () => {
        const value = getValues('is_active')
        if (value) {
            setStatus({
                state: true,
                name: 'YES'
            })
        } else {
            setStatus({
                state: false,
                name: 'NO'
            })
        }
    }

    const [permissions, setPermissions] = useState('')

    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])

    // const handleDownload = () => {
    //     const zip = new JSZip();
    //     const folder = zip.folder('my_folder');

    //     // Add files to the folder
    //     folder.file('file1.txt', 'File 1 content');
    //     folder.file('file2.txt', 'File 2 content');

    //     // Generate the zip with password
    //     zip.generateAsync({ type: 'blob', password: 'mypassword', })
    //         .then(content => {
    //             // Create a blob URL for the zip file
    //             const zipBlob = window.URL.createObjectURL(content);

    //             // Create a link element
    //             const link = document.createElement('a');
    //             link.href = zipBlob;
    //             link.download = 'my_files.zip';

    //             // Append the link to the body
    //             document.body.appendChild(link);

    //             // Programmatically click the link to trigger the download
    //             link.click();

    //             // Remove the link element
    //             document.body.removeChild(link);
    //         });
    // }

    const [couponList, setCouponList] = useState([
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CMDFAG356HGF"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CLKJHGRS5SJ"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CERT3453292CN"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CSYER3478HJC"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CLSHD6BE98D"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CKJDSHRE6EJW7"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CSDKJDS7632J"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "C37443H87WLK"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "C9H4D9D6TDH"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CJD63NJE7R9F"
        },
        {
            "app_product_keyid": "PU5BC3536B",
            "app_product_name": "CorpDrink",
            "app_product_pos_item_code": "0800032446",
            "product_type": "Print",
            "coupon_no": "CMNR58RE3SE"
        },

    ])

    const exportCSV = () => {
        const csvData = getCsvData();
        const zip = new JSZip();
        zip.file('coupons.csv', csvData);
        zip.generateAsync({ type: 'blob', compression: 'DEFLATE', })
            .then(function (content) {
                saveAs(content, 'coupons.zip');
            });
    };

    const getCsvData = () => {
        const headers = Object.keys(couponList[0]).join(',');
        const rows = couponList.map(coupon => Object.values(coupon).join(','));
        return headers + '\n' + rows.join('\n');
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Azure SSO" breadcrumbItem="Settings" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form >
                                    <Row>
                                        <Col lg="12">
                                            <h5>Basic SAML Configuration</h5>
                                        </Col>
                                    </Row>
                                    <Row className="SSO_Metadata">
                                        <Col lg="12">
                                            <h5 style={{ color: "steelblue" }}>SSO Metadata XML Download</h5>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <h5>Set up Azure AD SAML Toolkit</h5>
                                        </Col>
                                    </Row>
                                    <Row className="mt-5">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Login URL <span style={{ color: "red" }}>*</span>  :</Label>
                                            <Col lg="5" md="6">
                                                <input type="text" className="form-control " placeholder="Login URL" />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Azure AD identifier <span style={{ color: "red" }}>*</span>  :</Label>
                                            <Col lg="5" md="6">
                                                <input className="form-control" type="text" placeholder="Azure AD identifier" />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status  <span style={{ color: "red" }}>*</span>  :</Label>
                                            <Col lg="5" md="6">
                                                <div className="form-check form-switch form-switch-lg mt-1 mb-3">
                                                    <input type="checkbox" className="form-check-input" onInput={statusChange} defaultChecked={false} {...register('is_active')} />
                                                    <label className="form-check-label" htmlFor="roleStatus">
                                                        {status.name}
                                                    </label>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button color='success' id='UpdateBtn' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    {/* <button type="button" onClick={handleDownload}>Download Zip</button> */}

                                    {/* <button type="button" onClick={exportCSV}>Export Coupons</button> */}
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default AzureSSO

