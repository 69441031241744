import React, { useEffect, useState, useMemo } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Label, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";
import Breadcrumbs from "components/Common/Breadcrumb";
import logo from "../../../assets/images/maxims/maxims.png";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "components/Common/TableContainer";
import { UnitPrice, ProductID, Discount, DiscountUnitPrice, Qty, SubTotal } from "../PricingModelComponent/CustomOpportunityListCol";
import { useFieldArray, useForm } from "react-hook-form";
import { PDFViewer, ReactPDF, PDFDownloadLink } from "@react-pdf/renderer";
import ConfirmQuotationModal from "../confirmQuotationModal";
import { getApplicationTemplate, getApplicationTemplateDetail, updateQuotation } from "store/actions";
import QuotationDownload from "./QuotationDownload";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

let grandTotals

function QuotationPreview({ onCloseClick, quotationData }) {


  const { setValue, register, handleSubmit, watch, control, formState: { errors }, getValues } = useForm()

  document.title = "Quotation Detail";
  const dispatch = useDispatch();

  const [standardProductList, setStandardProductList] = useState([]);
  const [customProductList, setCustomProductList] = useState([]);
  const [flatRateProductList, setFlatRateProductList] = useState([]);
  const [tierProductList, setTierProductList] = useState([]);

  useEffect(() => {
    console.log("quotationData", quotationData);
    if (quotationData.standardProducts !== null && quotationData.standardProducts.length > 0) {
      setStandardProductList(quotationData?.standardProducts);
    }

    if (quotationData.customProducts !== null && quotationData.customProducts.length > 0) {
      setCustomProductList(quotationData?.customProducts);
    }
    if (quotationData.flatRateProducts !== null && quotationData.flatRateProducts !== undefined) {
      setValue("flatRateProducts", quotationData?.flatRateProducts)
      setFlatRateProductList(quotationData?.flatRateProducts)
    }

    if (quotationData.tierProducts !== null && quotationData.tierProducts !== undefined) {
      setValue("tierProducts", quotationData?.tierProducts)
      setTierProductList(quotationData?.tierProducts)
    }

    if (quotationData?.application_template_id) {
      let res = quotationData;
      setValue("template_information", res.template_information);
      setValue("terms_and_condition", res.terms_and_condition);
      setValue("payment_method", res.payment_method);
      setValue("application_template_id", res.application_template_id)
      setActiveConfirmBtn(true)
      setSelectedTemplateRes(res)
    }
  }, [quotationData])


  const columns = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'app_product_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductID datas={cellProps.row.original} {...cellProps} />;
        }
      },
      {
        Header: 'Unit Price',
        accessor: 'unit_price',
        filterable: true,
        Cell: (cellProps) => {
          return <UnitPrice {...cellProps} />;
        }
      },
      {
        Header: 'Discount',
        accessor: 'discount',
        filterable: true,
        Cell: (cellProps) => {
          return <Discount {...cellProps} />;
        }
      },

      {
        Header: 'Discounted Unit Price',
        accessor: 'discounted_unit_price',
        filterable: true,
        Cell: (cellProps) => {
          return <DiscountUnitPrice {...cellProps} />;
        }
      },
      {
        Header: 'Qty',
        accessor: 'quantity',
        filterable: true,
        Cell: (cellProps) => {
          return <Qty {...cellProps} />;
        }
      },
      {
        Header: 'Sub Total',
        accessor: 'rowTotal',
        filterable: true,
        Cell: (cellProps) => {
          return <SubTotal {...cellProps} />;
        }
      },
    ],

    [standardProductList, customProductList, flatRateProductList, tierProductList,]
  );





  const getGrandTotal = (standardProductList, customProductList, flatRateProductList, tierProductList) => {
    let total1 = 0;
    standardProductList?.forEach(item => total1 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total2 = 0;
    customProductList?.forEach(item => total2 += parseFloat(item.rowTotal ? item.rowTotal : 0));

    let total3 = 0;
    flatRateProductList?.forEach(a => a?.flatRateList?.forEach(b => total3 += parseFloat(b.rowTotal ? b.rowTotal : 0)))

    let total4 = 0;
    tierProductList?.forEach(a => a?.tierList?.forEach(b => total4 += parseFloat(b.rowTotal ? b.rowTotal : 0)))

    let grandTotal = total1 + total2 + total3 + total4;

    grandTotals = parseFloat(grandTotal).toFixed(1)
    return parseFloat(grandTotals).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }


  const { fields, append, remove } = useFieldArray({
    control,
    name: "flatRateProducts",
  });

  const { fields: fieldsTier, append: appendTier, remove: removeTier } = useFieldArray({
    control,
    name: "tierProducts"
  });

  const displayTierValues = (tierProductList) => {
    const tiers = tierProductList?.tiers;

    const tierValues = tiers?.map((tier, index) => {
      const startRange = index === 0 ? 1 : parseInt(tiers[index - 1]?.app_tier_qualifying_input || 0) + 1;
      const endRange = index === tiers.length - 1 ? "Infinity" : tier.app_tier_qualifying_input;
      return (
        <div key={tier.localID}>
            <span style={{fontSize:"15px"}}>
            Qualifying Value: {startRange} - {endRange}: Discount: {tier.app_tier_discount_value}
          </span>
        </div>
      );
    });
    return tierValues;
  }



  const [confirmQuotationModal, setConfirmQuotationModal] = useState(false);

  const confirmQuotation = () => {
    setConfirmQuotationModal(true)
  }

  const templateState = useSelector(state => state.ApplicationTemplateReducer);
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");


  useEffect(() => {
    let filter = `{"template_type":"Quotation"}`
    dispatch(getApplicationTemplate({ filter, limit: 0, skip: 0 }))
  }, [])

  useEffect(() => {
    if (templateState.applicationTemplate !== null)
      setTemplateData(templateState.applicationTemplate.result)
    // setSelectedTemplateRes("")
  }, [templateState])

  const selectTemplateName = (e) => {
    setSelectedTemplateId(e.target.value)
  }

  useEffect(() => {
    if (selectedTemplateId) {
      const data = { templateId: selectedTemplateId };
      dispatch(getApplicationTemplateDetail(data));
    }
  }, [selectedTemplateId, quotationData])

  const [selectedTemplateRes, setSelectedTemplateRes] = useState("");

  useEffect(() => {
    if (templateState.applicationTemplateDetail !== null) {

      let res = templateState.applicationTemplateDetail;
      // console.log("res", res);
      setSelectedTemplateRes(res)
      selectedTemplateId ? setValue("template_information", res.template_information) : "";
      selectedTemplateId ? setValue("terms_and_condition", res.terms_and_condition) : "";
      selectedTemplateId ? setValue("payment_method", res.payment_method) : "";
      selectedTemplateId ? setValue("application_template_id", res.application_template_id) : "";
    }
  }, [templateState, selectedTemplateId, quotationData]);

  const [activeConfirmBtn, setActiveConfirmBtn] = useState(false);


  const parseRichText = (richText, listItemNumber = 1) => {
    const containsHtmlTags = /<[^>]+>/g.test(richText);

    if (!containsHtmlTags) {
      return richText;
    }

    const htmlParser = new DOMParser();
    const parsedHtml = htmlParser.parseFromString(richText, 'text/html');
    const elements = parsedHtml.body.childNodes;

    const renderElements = (nodes, listType, parentNode, indent = '') => {
      let result = '';
      Array.from(nodes).forEach((node, index) => {
        if (node.nodeType === 1) {
          const tagName = node.tagName.toLowerCase();
          if (tagName === 'br') {
            result += '\n';
          } else if (tagName === 'strong' || tagName === 'i' || tagName === 'em' || tagName === 'span' || tagName === 'u') {
            result += indent + node.textContent.trim() + '\n';
          } else if (tagName === 'ol' || tagName === 'ul') {
            result += renderElements(node.childNodes, tagName, node, indent);
          } else if (tagName === 'li') {
            const hasStrongChild = Array.from(node.childNodes).some(child => child.tagName && child.tagName.toLowerCase() === 'strong');
            result += `${indent}${listType === 'ol' ? `${listItemNumber++}. ` : '• '}${node.textContent.trim()}\n`;
          } else if (tagName === 'p') {
            result += renderElements(node.childNodes, listType, parentNode, indent);
          }
        } else if (node.nodeType === 3) {
          result += indent + node.textContent.trim() + '\n';
        }
      });
      return result;
    };

    return renderElements(elements, '', null);
  };
  
  const displayTierValues2 = (tierProductList) => {
    const tiers = tierProductList?.tiers;
    const tierValues = tiers?.map((tier, index) => {
      const startRange = index === 0 ? 1 : parseInt(tiers[index - 1]?.app_tier_qualifying_input || 0) + 1;
      const endRange = index === tiers.length - 1 ? "Infinity" : tier.app_tier_qualifying_input;

      return (
        { text: `Qualifying Value: ${startRange} - ${endRange}: Discount: ${tier.app_tier_discount_value}\n`, margin: [0, 0] }
      );
    });
    return tierValues;
  }

  const getSubTotal = (data) => {
    let total = 0
    data?.forEach(item => total += parseFloat(item.rowTotal ? item.rowTotal : 0))
    return parseFloat(total).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  }

  const getQuantity = (data) => {
    let total = 0;
    data?.forEach(item => total += parseFloat(item.quantity ? item.quantity : 0));
    return parseFloat(total).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
  };


  const handleDownload = () => {
    const templateInformation = parseRichText(quotationData.template_information);
    const termsCondition = parseRichText(quotationData.terms_and_condition);
    const paymentMethod = parseRichText(quotationData.payment_method);
    const docDefinition = {
      content: [
        {
          stack: [
            {
              image: logo,
              width: 80,
              height: 30,
              alignment: 'center',
            },
            { text: 'Quotation', fontSize: 15, marginTop: 5, marginBottom: 10, alignment: 'center', color: "#49535C" }
          ],
          style: 'invoiceHeader'
        },

        {
          columns: [
            {
              width: 250,

              stack: [
                {
                  text: [{ text: 'Company Name : ', fontSize: 14, bold: true, color: "#49535C" },
                  { text: quotationData.app_business_name + " | " + quotationData.app_department_name, fontSize: 12, color: "#49535C" }]
                }
              ]
            },
            {
              width: 50,
              stack: [
                {
                  text: '    ',
                }
              ]

            },
            {
              width: 250,

              stack: [
                {
                  text: [{ text: 'Quotation Number : ', fontSize: 14, bold: true, color: "#49535C" },
                  { text: quotationData.quotation_keyid, fontSize: 12, color: "#49535C" }]
                }
              ]
            }
          ],

        },
        '\n',

        {
          columns: [
            {
              width: 250,

              stack: [
                {
                  text: [{ text: 'Billing Details : ', fontSize: 14, bold: true, color: "#49535C", width: 100 },
                  { text: quotationData.app_billing_contact_name + " | " + quotationData.app_billing_address, fontSize: 12, color: "#49535C" }]
                }
              ]
            },
            {
              width: 50,
              stack: [
                {
                  text: '    ',
                }
              ]

            },
            {
              width: 250,

              stack: [
                {
                  text: [{ text: 'Date : ', fontSize: 14, bold: true, color: "#49535C" },
                  { text: quotationData.opportunity_date, fontSize: 12, color: "#49535C" }]
                }
              ]
            }
          ],

        },

        '\n',

        {
          columns: [
            {
              width: 250,

              stack: [
                {
                  text: [{ text: 'Delivery Details : ', fontSize: 14, bold: true, color: "#49535C", width: 35 },
                  { text: quotationData.app_delivery_contact_name + " | " + quotationData.app_delivery_address, fontSize: 12, color: "#49535C" }]
                }
              ]
            },
            {
              width: 50,
              stack: [
                {
                  text: '    ',
                }
              ]

            },
            {
              width: 250,

              stack: [
                {
                  text: [{ text: 'Reference : ', fontSize: 14, bold: true, color: "#49535C" },
                  { text: 'Remarks Here', fontSize: 12, color: "#49535C" }]
                }
              ]
            }
          ],

        },

        '\n',

        { text: 'Template Information: ', fontSize: 14, bold: true, color: "#49535C" },
        { text: templateInformation, fontSize: 12, color: "#49535C", marginBottom: 10 },


        standardProductList.length > 0 ? { text: 'Pricing Model - Standard', fontSize: 14, bold: true, color: "#49535C", marginBottom: 10 } : null,

        standardProductList.length > 0 ? {
          table: {
            headerRows: 1,
            marginTop: 10,
            marginBottom: 10,
            widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
              [
                { text: 'Product', style: 'tableHeader' },
                { text: 'Unit Price', style: 'tableHeader' },
                { text: 'Discount', style: 'tableHeader' },
                { text: 'Discounted Unit Price', style: 'tableHeader' },
                { text: 'Qty', style: 'tableHeader' },
                { text: 'Sub total', style: 'tableHeader' }
              ],
              // Add rows dynamically based on your product list data
              ...standardProductList.map(item => [
                { text: `${item.app_product_keyid} | ${item.app_product_local_name}`, style: 'tableCell' },
                { text: item.pack_unit, style: 'tableCell' },
                { text: item.discount, style: 'tableCell' },
                { text: `$${parseFloat(item.discounted_unit_price).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, style: 'tableCell' },
                { text: `${parseFloat(item.quantity).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, style: 'tableCell' },
                { text: `$${parseFloat(item.rowTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, style: 'tableCell' }
              ]),
              [
                { text: 'Subtotal', style: 'tableCell', colSpan: 4, alignment: 'right' },
                {},
                {},
                {},
                { text: getQuantity(standardProductList), style: 'tableCell' },
                { text: "$" + " " + getSubTotal(standardProductList), style: 'tableCell' }
              ]
            ]
          }
        } : null,

        customProductList.length > 0 ? { text: 'Pricing Model - Custom', fontSize: 14, bold: true, color: "#49535C", marginBottom: 10, marginTop: 10 } : null,

        customProductList.length > 0 ? {
          table: {
            headerRows: 1,
            marginTop: 10,
            marginBottom: 10,
            widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
              [
                { text: 'Product', style: 'tableHeader' },
                { text: 'Unit Price', style: 'tableHeader' },
                { text: 'Discount', style: 'tableHeader' },
                { text: 'Discounted Unit Price', style: 'tableHeader' },
                { text: 'Qty', style: 'tableHeader' },
                { text: 'Sub total', style: 'tableHeader' }
              ],
              // Add rows dynamically based on your product list data
              ...customProductList.map(item => [
                { text: `${item.app_product_keyid} | ${item.app_product_local_name}`, style: 'tableCell' },
                { text: item.pack_unit, style: 'tableCell', alignment: 'right' },
                { text: item.discount, style: 'tableCell', alignment: 'right' },
                { text: `$${parseFloat(item.discounted_unit_price).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, style: 'tableCell', alignment: 'right' },
                { text: `${parseFloat(item.quantity).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, style: 'tableCell', alignment: 'right' },
                { text: `$${parseFloat(item.rowTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, style: 'tableCell', alignment: 'right' }
              ]),
              [
                { text: 'Subtotal', style: 'tableCell', colSpan: 4, alignment: 'right' },
                {},
                {},
                {},
                { text: getQuantity(customProductList), style: 'tableCell', alignment: 'right' },
                { text: "$" + " " + getSubTotal(customProductList), style: 'tableCell', alignment: 'right' }
              ]
            ]
          }
        } : null,

        flatRateProductList.length > 0 ? { text: 'Pricing Model - Flat Rate', fontSize: 14, bold: true, color: "#49535C", marginTop: 10 } : null,

        ...flatRateProductList.map(flatRateProduct => ({
          stack: [
            { text: `Pricing Model Name: ${flatRateProduct.app_pricing_model_name}`, marginTop: 10, fontSize: 12, bold: true, color: "#49535C" },
            { text: `Qualifying Type: ${flatRateProduct.app_pricing_model_qualifying_type}`, fontSize: 12, color: "#49535C" },
            { text: `Qualifying Value: ${flatRateProduct.app_pricing_model_qualifying_value}`, fontSize: 12, color: "#49535C" },
            { text: `Discount Type: ${flatRateProduct.app_pricing_model_discount_type}`, fontSize: 12, color: "#49535C" },
            { text: `Discount Value: ${flatRateProduct.app_pricing_model_discount_value}`, marginBottom: 10, fontSize: 12, color: "#49535C" },
            {
              table: {
                marginTop: 10,
                marginBottom: 10,
                headerRows: 1,
                // widths: ['40%', '10%', '10%', '15%', '10%', '15%'],
                widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                  [
                    { text: 'Product', style: 'tableHeader' },
                    { text: 'Unit Price', style: 'tableHeader' },
                    { text: 'Discount', style: 'tableHeader' },
                    { text: 'Discounted Unit Price', style: 'tableHeader' },
                    { text: 'Qty', style: 'tableHeader' },
                    { text: 'Sub total', style: 'tableHeader' }
                  ],
                  ...flatRateProduct.flatRateList.map(item => ([
                    { text: `${item.app_product_keyid} | ${item.app_product_local_name}` },
                    { text: item.pack_unit, alignment: 'right' },
                    { text: item.discount, alignment: 'right' },
                    { text: `$${parseFloat(item.discounted_unit_price).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, alignment: 'right' },
                    { text: `${parseFloat(item.quantity).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, alignment: 'right' },
                    { text: `$${parseFloat(item.rowTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, alignment: 'right' }
                  ])),
                  [
                    { text: 'Subtotal', style: 'tableCell', colSpan: 4, alignment: 'right' },
                    {},
                    {},
                    {},
                    { text: getQuantity(flatRateProduct.flatRateList), style: 'tableCell', alignment: 'right' },
                    { text: "$" + " " + getSubTotal(flatRateProduct.flatRateList), style: 'tableCell', alignment: 'right' }
                  ]
                ]
              }
            }
          ]
        })),

        tierProductList.length > 0 ? { text: 'Pricing Model - Tier', fontSize: 14, bold: true, color: "#49535C", marginTop: 10 } : null,

        ...tierProductList.map(tierProduct => ({
          stack: [
            { text: `Pricing Model Name: ${tierProduct.app_pricing_model_name}`, marginTop: 10, fontSize: 12, bold: true, color: "#49535C" },
            { text: `Qualifying Type: ${tierProduct.app_pricing_model_qualifying_type}`, fontSize: 12, color: "#49535C" },
            { text: `Discount Value: ${tierProduct.app_pricing_model_discount_type}`, fontSize: 12, color: "#49535C" },
            { text: displayTierValues2(tierProduct), marginBottom: 10, fontSize: 12, color: "#49535C" },
            {
              table: {
                headerRows: 1,
                marginTop: 10,
                marginBottom: 10,
                // widths: ['40%', '10%', '10%', '15%', '10%', '15%'],
                widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                  [
                    { text: 'Product', style: 'tableHeader' },
                    { text: 'Unit Price', style: 'tableHeader' },
                    { text: 'Discount', style: 'tableHeader' },
                    { text: 'Discounted Unit Price', style: 'tableHeader' },
                    { text: 'Qty', style: 'tableHeader' },
                    { text: 'Sub total', style: 'tableHeader' }
                  ],
                  ...tierProduct.tierList.map(item => ([
                    { text: `${item.app_product_keyid} | ${item.app_product_local_name}` },
                    { text: item.pack_unit, alignment: 'right' },
                    { text: item.discount, alignment: 'right' },
                    { text: `$${parseFloat(item.discounted_unit_price).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, alignment: 'right' },
                    { text: `${parseFloat(item.quantity).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, alignment: 'right' },
                    { text: `$${parseFloat(item.rowTotal).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()}`, alignment: 'right' }
                  ])),
                  [
                    { text: 'Subtotal', style: 'tableCell', colSpan: 4, alignment: 'right' },
                    {},
                    {},
                    {},
                    { text: getQuantity(tierProduct.tierList), style: 'tableCell', alignment: 'right' },
                    { text: "$" + " " + getSubTotal(tierProduct.tierList), style: 'tableCell', alignment: 'right' },
                  ]
                ]
              }
            }

          ]
        })),

        { text: 'Terms And Condition: ', fontSize: 14, bold: true, color: "#49535C", marginTop: 10 },
        { text: termsCondition, fontSize: 12, color: "#49535C", marginBottom: 10 },

        {
          columns: [
            {
              width: 250,

              stack: [
                {
                  text: [{ text: 'Payment Method: ', fontSize: 14, bold: true, color: "#49535C", marginTop: 20 },
                  { text: paymentMethod, fontSize: 12, color: "#49535C", marginBottom: 10 }]
                }
              ]
            }

          ],

        },

        { text: 'Total: $' + getGrandTotal(standardProductList, customProductList, flatRateProductList, tierProductList), fontSize: 14, bold: true, color: "#49535C", marginTop: 10, alignment: 'right' },
      ],

      footer: {
        margin: [0, 8, 0, 0],
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            text: "2024 © Maxims B2B Portal.",
            alignment: 'center',
            color: "#49535C",
            fontSize: 10,
            lineHeight: 1.5
          },
          { width: '*', text: '' }
        ]
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 10],
        },
        companyName: {
          color: "#49535C",
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 5]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
          alignment: 'center'
        },
      },
      ownerPassword: '1',
      userPassword: '12345',
    };
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getBlob((blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Quotation - ${quotationData.quotation_keyid}.pdf`;
      link.click();
    });
  }


  const handlesaveQuotation = () => {

    const Data = {
      quotationId: quotationData.quotation_id,
      updatedData: {
        ...selectedTemplateRes,
      }
    }
    console.log("Data", Data);
    dispatch(updateQuotation(Data))


  }


  return (
    <React.Fragment>
      <ConfirmQuotationModal
        show={confirmQuotationModal}
        onCloseClick={() => { setConfirmQuotationModal(false) }}
        data={quotationData}
      />

      <div className="page-content" >
        <Container fluid>
          {!isEmpty(quotationData) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col>
                        <div>
                          <div className="d-flex justify-content-center">
                            <img src={logo} alt="logo" height="60" />
                          </div>
                          <div className="d-flex justify-content-center mt-3">
                            <h4>Quotation</h4>
                          </div>
                        </div>

                      </Col>
                    </Row>

                    <hr />
                    {/* -------------------------------------- */}
                    <Row>
                      <Col lg="2" className="mt-3">
                        <strong className="d-flex justify-content-end">Company &nbsp;&nbsp;&nbsp;</strong>
                      </Col>
                      <Col lg="4" className="mt-3">
                        <address>
                          <span>{quotationData.app_business_name}</span>  | <span>{quotationData.app_department_name}</span>
                        </address>
                      </Col>

                      <Col lg="2" className="mt-3 text-center">
                        <strong className="d-flex justify-content-end">Quatation Id&nbsp;&nbsp;&nbsp;</strong>
                      </Col>
                      <Col lg="4" className="mt-3">
                        <address>
                          <span>{quotationData.quotation_keyid}</span>
                          {/* {map(quatationDetail.Quatation.split(","), (item, key) => (
                            <React.Fragment key={key}>
                              <span>{item}</span>
                              <br />
                            </React.Fragment>
                          )
                          )} */}
                        </address>
                      </Col>
                    </Row>
                    {/* -------------------------------------- */}

                    <Row>
                      <Col lg="2">
                        <strong className="d-flex justify-content-end">Billing Details&nbsp;&nbsp;&nbsp;</strong>
                      </Col>
                      <Col lg="4">
                        <address>
                          {quotationData.app_billing_contact_name}
                          <br />
                          {quotationData.app_billing_address}
                          <br />
                          {quotationData.app_billing_contact_email}

                        </address>
                      </Col>

                      <Col lg="2">
                        <strong className="d-flex justify-content-end">Date&nbsp;&nbsp;&nbsp;</strong>
                      </Col>
                      <Col lg="4">
                        <address>
                          <span>{quotationData.opportunity_date}</span>
                        </address>
                      </Col>
                    </Row>
                    {/* --------------------------------------------- */}
                    <Row>
                      <Col lg="2">
                        <strong className="d-flex justify-content-end">Delivery Details&nbsp;&nbsp;&nbsp;</strong>
                      </Col>
                      <Col lg="4">
                        <address>
                          {quotationData.app_delivery_contact_name}
                          <br />
                          {quotationData.app_delivery_address}
                          <br />
                          {quotationData.app_delivery_contact_email}

                        </address>
                      </Col>

                      <Col lg="2">
                        <strong className="d-flex justify-content-end">Reference&nbsp;&nbsp;&nbsp;</strong>
                      </Col>
                      <Col lg="4">
                        <address>
                          <span>Remarks Here</span>
                        </address>
                      </Col>
                    </Row>

                    <hr />

                    {/* <Row className="justify-content-end">
                      <Label xs="auto" className="text-end">
                        Template
                      </Label>
                      <Col xs="3" className="text-end">
                        <select className="form-select" aria-label="Default select example">
                          <option value="">Select Template</option>
                        </select>
                      </Col>
                    </Row> */}


                    <Row>
                      <Label lg="7" md="8" className="d-flex justify-content-lg-end justify-content-md-end ">Template<span style={{ color: "red" }}>*</span> </Label>
                      <Col lg="5" md="6">
                        <select  {...register('application_template_id', { required: true })} onChange={(e) => { selectTemplateName(e); setActiveConfirmBtn(true) }} className="form-select d-inline" aria-label="Default select example">
                          <option hidden value="">select template </option>
                          {templateData.map((template) => (
                            <option key={template.application_template_id} value={template.application_template_id}>{template.template_name}</option>
                          ))}
                        </select>
                        {errors.application_template_id && <p style={{ color: "red" }}>This Field is required</p>}

                      </Col>
                    </Row>


                    <Row className="mt-3">
                      <Label >   <h4>Quotation Information</h4>  </Label>
                      <br></br>
                      <Col>
                        {/* <textarea rows="5" readOnly type="text" className='form form-control'  {...register('template_information', { required: true })} value={watch('template_information')} /> */}
                        <span dangerouslySetInnerHTML={{ __html: selectedTemplateRes?.template_information }} />
                      </Col>
                    </Row>

                    {
                      standardProductList.length > 0 && (
                        <Row className="mt-3">
                          <h4>Pricing Model - Standard</h4>
                          <Col>
                            <TableContainer
                              columns={columns}
                              data={standardProductList}
                            />
                          </Col>
                          {/* <Col lg="12" md="12" sm="12" className="d-flex justify-content-end  mb-5">
                            <h5 className="me-2">
                              {"QTY" + ":" + getQuantity(standardProductList) + " | " + "SubTotal: $" + getSubTotal(standardProductList)}
                            </h5>
                          </Col> */}

                        </Row>
                      )
                    }

                    {
                      customProductList.length > 0 && (
                        <Row className="mt-3">
                          <h4>Pricing Model - Custom</h4>
                          <Col>
                            <TableContainer
                              columns={columns}
                              data={customProductList}
                            />
                          </Col>
                        </Row>
                      )
                    }


                    {fields && fields.length > 0 && <Row>
                      <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                        <h4> Pricing Model - Flat Rate </h4>
                        <br></br>
                      </Col>
                    </Row>}

                    {fields && fields.length > 0 && fields.map((item, index) => (
                      <div key={item.id}>
                        <Row>

                          <Col lg="6" md="6" sm="6" xs="6">
                            <div className="float-start">
                            <span style={{fontSize:"15px"}}>   Pricing Model Name: &nbsp; {item.app_pricing_model_name}</span><br></br>
                                <span style={{fontSize:"15px"}}>  Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                <span style={{fontSize:"15px"}}>  Qualifying Value:&nbsp; {item.app_pricing_model_qualifying_value}</span><br></br>
                                <span style={{fontSize:"15px"}}>  Discount Type: &nbsp;  {item.app_pricing_model_discount_type}</span><br></br>
                                <span style={{fontSize:"15px"}}>  Discount Value: &nbsp;  {item.app_pricing_model_discount_value}</span><br></br>

                              {/* <h5 > <b> Pricing Model Name: &nbsp; </b> {item.app_pricing_model_name}</h5>
                              <h5 > <b> Qualifying Type: &nbsp; </b> {item.app_pricing_model_qualifying_type}</h5>
                              <h5 > <b> Qualifying Value:&nbsp;  </b>{item.app_pricing_model_qualifying_value}</h5>
                              <h5 > <b> Discount Type: &nbsp;  </b> {item.app_pricing_model_discount_type}</h5>
                              <h5 > <b> Discount Value: &nbsp;  </b> {item.app_pricing_model_discount_value}</h5> */}
                            </div>
                          </Col>

                        </Row>
                        <Row>
                          <Col xs="12">
                            <Card>
                              <CardBody>
                                <TableContainer
                                  columns={columns}
                                  data={item.flatRateList || []}
                                  customPageSize={10}
                                  className="custom-header-css"
                                />
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    ))}


                    {fieldsTier && fieldsTier.length > 0 && <Row>
                      <Col lg="6" md="6" sm="6" xs="6" className="d-flex justify-content-start">
                        <h4> Pricing Model - Tier </h4>
                        <br></br>
                      </Col>
                    </Row>}

                    {fieldsTier && fieldsTier.length > 0 && fieldsTier.map((item, index) => (
                      <div key={item.id}>
                        <Row>

                          <Col lg="6" md="6" sm="6" xs="6">
                            <div className="float-start">
                               {/* <h5><b> Pricing Model Name: &nbsp; </b>{item.app_pricing_model_name}</h5>
                              <h5><b> Qualifying Type: &nbsp; </b>{item.app_pricing_model_qualifying_type}</h5>
                              <h5><b> Discount Type: &nbsp; </b>{item.app_pricing_model_discount_type}</h5> */}
                              
                                  <span style={{fontSize:"15px"}}>Pricing Model Name: &nbsp;{item.app_pricing_model_name}</span><br></br>
                                <span style={{fontSize:"15px"}}>Qualifying Type: &nbsp; {item.app_pricing_model_qualifying_type}</span><br></br>
                                <span style={{fontSize:"15px"}}>Discount Type: &nbsp; {item.app_pricing_model_discount_type}</span><br></br>
                                {displayTierValues(item)}
                            </div>
                          </Col>

                        </Row>
                        <Row>
                          <Col xs="12">
                            <Card>
                              <CardBody>
                                <TableContainer
                                  columns={columns}
                                  data={item.tierList || []}
                                  customPageSize={10}
                                  className="custom-header-css"
                                />
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    ))}

                    <Row className="mt-3">
                      <Label> <h4>Terms and Conditions</h4> </Label>
                      <br />
                      <Col>
                        {/* <textarea rows={"5"} readOnly type="text" className='form form-control' {...register('terms_and_condition', { required: true })} value={watch('terms_and_condition')} /> */}
                        <span dangerouslySetInnerHTML={{ __html: selectedTemplateRes?.terms_and_condition }} />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Label> <h4>Payment Methods  </h4>   </Label>
                      <br />
                      <Col>
                        {/* <textarea rows="2" readOnly type="text" className='form form-control' {...register('payment_method', { required: true })} value={watch('payment_method')} /> */}
                        <span dangerouslySetInnerHTML={{ __html: selectedTemplateRes?.payment_method }} />
                      </Col>
                    </Row>

                    <hr />

                    <Row className="justify-content-end">
                      <Col>
                        <div className="d-flex gap-3 justify-content-end" >
                          {/* <strong className="mt-2">Total :  ${getTotal(quotationData.standardProducts !== null ? quotationData.standardProducts : [])}</strong> */}

                          <strong className="mt-2" style={{ fontSize: "20px" }}>Total :  ${" " + getGrandTotal(standardProductList, customProductList, getValues("flatRateProducts"), getValues("tierProducts"))}</strong>
                          <Button className="m-2" onClick={() => { onCloseClick(); templateState.applicationTemplateDetail = null }} >Back</Button>

                          {/* 
                          <PDFDownloadLink
                            document={<QuotationDownload quotationData={quotationData} tempdata={selectedTemplateRes} />}
                            type='button'
                            className='m-2'
                            fileName={`Quotation - ${quotationData.quotation_keyid}.pdf`}
                          >
                            {({ blob, url, loading, error }) => (
                              loading ? "Loading document..." : <Button className="btn btn-info" onClick={handleSaveQuotation} disabled={!activeConfirmBtn}>Save & Download</Button>
                            )}   
                          </PDFDownloadLink> */}


                          {!(quotationData.template_information) ? <Button type="button" className="btn btn-info m-2" onClick={handlesaveQuotation}>Save</Button> : ""}
                          {quotationData.template_information ? <PDFDownloadLink
                            document={<QuotationDownload quotationData={quotationData} />}
                            type='button' className='m-2'
                            fileName={`${"Quotation " + " - " + quotationData.quotation_keyid}.pdf`} >
                            {({ blob, url, loading, error }) => loading ? "Loading document..." : <Button type="button" className="btn btn-info  ">Download</Button>
                            }
                          </PDFDownloadLink> : " "}

                          {/* <Button className="btn btn-success m-2" onClick={confirmQuotation}>Confirm Quotation</Button> */}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment >
  );
};

export default withRouter(QuotationPreview);


