import {
    GET_APP_ROLES,
    GET_APP_ROLES_SUCCESS,
    GET_APP_ROLES_FAIL,

    GET_APP_ROLE,
    GET_APP_ROLE_SUCCESS,
    GET_APP_ROLE_FAIL,

    POST_APP_ROLES,
    POST_APP_ROLES_SUCCESS,
    POST_APP_ROLES_FAIL,

    DELETE_APP_ROLES,
    DELETE_APP_ROLES_SUCCESS,
    DELETE_APP_ROLES_FAIL,

    UPDATE_APP_ROLES,
    UPDATE_APP_ROLES_SUCCESS,
    UPDATE_APP_ROLES_FAIL,

} from "./actionTypes";

const INIT_STATE = {
    appRoles: null,
    appRole: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const AppRoles = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APP_ROLES:
            return {
                ...state,
                loading: true,
                appRoles: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                appRoles: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_ROLES:
            return {
                ...state,
                loading: true,
                appRoles: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                appRole: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_APP_ROLES:
            return {
                ...state,
                loading: true,
                appRoles: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_APP_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                appRole: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_APP_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_ROLE:
            return {
                ...state,
                loading: true,
                appRoles: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                appRole: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_ROLE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_APP_ROLES:
            return {
                ...state,
                loading: true,
                appRoles: null,
                appRole: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_APP_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                appRole: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_APP_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default AppRoles