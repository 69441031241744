import React from 'react';
import { Badge } from 'reactstrap';

const TierNo = (cell) => {
    return cell.value ? cell.value : '';
};

const TierName = (cell) => {
    return cell.value ? cell.value : '';
};

const Qualifying = (cell) => {
    return cell.value ? cell.value : '';
};

const DiscountCode = (cell) => {
    return cell.value ? cell.value : '';
};
const DiscountValue = (cell) => {
    return cell.value ? cell.value : '';
};
const Status = (cell) => {
    return (
        <Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
            {cell.value ? "ACTIVE" : "INACTIVE"}
        </Badge>
    )
};

export {
    TierNo,
    TierName,
    Qualifying,
    DiscountCode,
    Status,
    DiscountValue
};