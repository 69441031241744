import {
    GET_APP_SYNCHRONIZE,
    GET_APP_SYNCHRONIZE_SUCCESS,
    GET_APP_SYNCHRONIZE_FAIL,
  
  } from "./actionTypes";
  
  const INIT_STATE = {
    appSynchronize: null,
    loading: false,
    isGet: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
  };
  
  const AppSynchronize = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_APP_SYNCHRONIZE:
        return {
          ...state,
          loading: true,
          appSynchronize: null,
          isGet: false,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_SYNCHRONIZE_SUCCESS:
        return {
          ...state,
          loading: false,
          appSynchronize: action.payload,
          isCreated: false,
          isGet:true,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_SYNCHRONIZE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isGet: false,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      default:
        return state;
    }
  };
  
  export default AppSynchronize;
  
  
