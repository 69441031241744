import { takeEvery, put, call } from "redux-saga/effects"
import {
    getAccountSuccess,
    getAccountFail,
    postAccountSuccess,
    postAccountFail,
    deleteAccountSuccess,
    deleteAccountFail,
    getAccountDetailSuccess,
    getAccountDetailFail,
    updateAccountSuccess,
    updateAccountFail
} from "./actions"

import {
    getAccount,
    postAccount,
    deleteAccount,
    getAccountDetail,
    updateAccount
}
    from "helpers/backendApi_helpers";

import {
    DELETE_APP_ACCOUNT,
    GET_APP_ACCOUNT_DETAIL,
    GET_APP_ACCOUNT,
    POST_APP_ACCOUNT,
    UPDATE_APP_ACCOUNT
} from "./actionTypes";

function* fetchAppAccount({ payload: payload }) {
  try {
    const response = yield call(getAccount, payload);
    yield put(getAccountSuccess(response))
  } catch (error) {
    yield put(getAccountFail(error))
  }
}

function* postAppAccount({ payload: payload }) {
  try {
    const response = yield call(postAccount, payload);
    yield put(postAccountSuccess(response))
  } catch (error) {
    yield put(postAccountFail(error))
  }
}

function* deleteAppAccount({ payload: payload }) {
  try {
    const response = yield call(deleteAccount, payload);
    yield put(deleteAccountSuccess(response))
  } catch (error) {
    yield put(deleteAccountFail(error))
  }
}

function* getAppAccountDetail({ payload: payload }) {
  try {
    const response = yield call(getAccountDetail, payload);
    yield put(getAccountDetailSuccess(response))
  } catch (error) {
    yield put(getAccountDetailFail(error))
  }
}

function* updateAppAccount({ payload: payload }) {
  try {
    const response = yield call(updateAccount, payload);
    yield put(updateAccountSuccess(response))
  } catch (error) {
    yield put(updateAccountFail(error))
  }
}

function* AppAccountSaga() {
  yield takeEvery(GET_APP_ACCOUNT, fetchAppAccount)
  yield takeEvery(POST_APP_ACCOUNT, postAppAccount)
  yield takeEvery(DELETE_APP_ACCOUNT, deleteAppAccount)
  yield takeEvery(GET_APP_ACCOUNT_DETAIL, getAppAccountDetail)
  yield takeEvery(UPDATE_APP_ACCOUNT, updateAppAccount)
}

export default AppAccountSaga

