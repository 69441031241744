import {
    GET_CREDITS,
    GET_CREDITS_SUCCESS,
    GET_CREDITS_FAIL,

    GET_CREDIT,
    GET_CREDIT_SUCCESS,
    GET_CREDIT_FAIL,

    GET_ALL_CREDIT,
    GET_ALL_CREDIT_SUCCESS,
    GET_ALL_CREDIT_FAIL,

    POST_CREDIT,
    POST_CREDIT_SUCCESS,
    POST_CREDIT_FAIL,

    DELETE_CREDIT,
    DELETE_CREDIT_SUCCESS,
    DELETE_CREDIT_FAIL,

    UPDATE_CREDIT,
    UPDATE_CREDIT_SUCCESS,
    UPDATE_CREDIT_FAIL,
} from "./actionsTypes"

const INIT_STATE = {
    credits: null,
    credit: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
}

const creditsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CREDITS:
            return {
                ...state,
                loading: true,
                credits: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CREDITS_SUCCESS:
            return {
                ...state,
                loading: false,
                credits: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CREDITS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_CREDIT:
            return {
                ...state,
                loading: true,
                credits: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_CREDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                credit: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_CREDIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_CREDIT:
            return {
                ...state,
                loading: true,
                credits: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_CREDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                credit: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_CREDIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CREDIT:
            return {
                ...state,
                loading: true,
                credit: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CREDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                credit: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_CREDIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_CREDIT:
            return {
                ...state,
                loading: true,
                credits: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_CREDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                credits: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_CREDIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_CREDIT:
            return {
                ...state,
                loading: true,
                credit: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_CREDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                credit: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_CREDIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default creditsReducer;