import {
    GET_ATTACHMENT,
    GET_ATTACHMENT_SUCCESS,
    GET_ATTACHMENT_FAIL,

    POST_ATTACHMENT,
    POST_ATTACHMENT_SUCCESS,
    POST_ATTACHMENT_FAIL,

    UPDATE_ATTACHMENT,
    UPDATE_ATTACHMENT_SUCCESS,
    UPDATE_ATTACHMENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    attachment: null,
    loading: false,
    isCreated: false,
    isUpdated: false,
    error: {},
};

const AttachmentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_ATTACHMENT:
            return {
                ...state,
                loading: true,
                attachment: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_ATTACHMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                attachment: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_ATTACHMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ATTACHMENT:
            return {
                ...state,
                loading: true,
                attachment: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ATTACHMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                attachment: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ATTACHMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_ATTACHMENT:
            return {
                ...state,
                loading: true,
                attachment: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_ATTACHMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                attachment: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_ATTACHMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default AttachmentReducer;
