import React from "react"
import { Redirect } from "react-router-dom"

import UserProfile from "../pages/Authentication/user-profile"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Dashboard from "../pages/Dashboard/index"
import CompanyList from "../pages/Customers/companies/CompanyList"
import Heartbeat from "pages/Heartbeat"
import CreateCompany from "pages/Customers/companies/CreateCompany"
import Role from "pages/Administration/PortalRole/Role"
import CreateRole from "../pages/Administration/PortalRole/CreateRole"
import UpdateRole from "../pages/Administration/PortalRole/UpdateRole"
import CustomerUser from "pages/Administration/CustomerUsers/CustomerUser"
import CreateUser from "pages/Administration/PortalUser/CreateUser"
import User from "pages/Administration/PortalUser/UserList"
import CreateCustomerUser from "pages/Administration/CustomerUsers/CreateCustomerUser"
import UpdateUser from "pages/Administration/PortalUser/UpdateUser"
import UpdateCompany from "pages/Customers/companies/UpdateCompany"
import CustomerRolesManagement from "pages/Administration/CustomerRole/CustomerRoleList"
import UpdateCustomerUser from "pages/Administration/CustomerUsers/UpdateCustomerUser"
import Contracts from "pages/Divisions/Contracts/ContractsList"
import CreateContracts from "pages/Divisions/Contracts/CreateContract"
import UpdateContracts from "pages/Divisions/Contracts/UpdateContracts"
import BusinessUnits from "pages/Administration/BusinessUnit/BusinessUnitList"
import CreateBusinessUnit from "pages/Administration/BusinessUnit/CreateBusinessUnit"
import UpdateBusinessUnit from "pages/Administration/BusinessUnit/UpdateBusinessUnit"
import CreateCustomerRole from "pages/Administration/CustomerRole/CreateCustomerRole"
import UpdateCustomerRole from "pages/Administration/CustomerRole/UpdateCustomerRole"
import VerifyUser from "pages/Authentication/VerifyUser"
import SetupPassword from "pages/Authentication/CustomerSetupPassword"
import EmailSettings from "pages/Settings/SMTP/EmailSettings"
import Activity from "pages/Activity/Activity"
import ProductsList from "pages/Products/BusinessProducts/ProductsList"
import CreateProducts from "pages/Products/BusinessProducts/CreateProduct"
import UpdateProducts from "pages/Products/BusinessProducts/UpdateProduct"
import AzureSSo from "pages/Settings/Azure SSO/AzureSSO"
import PricingModelsList from "pages/Products/PricingModels/PricingModelsList"
import CreatePricingModels from "pages/Products/PricingModels/CreatePricingmodel"
import UpdatePricingModels from "pages/Products/PricingModels/UpdatePricingModel"
import Credits from "pages/Finance/Credits/CreditList"
import CreateCredit from "pages/Finance/Credits/CreateCredit"
import UpdateCredit from "pages/Finance/Credits/UpdateCredit"
import Approval from "pages/Approval/Approval"
import OtpValidTime from "../pages/Settings/OTPvalidtime/OtpValidTime"
import BusinessUnitDivisions from "pages/Administration/BusinessUnitDivision/BusinessUnitDivisionList"
import UpdateBusinessUnitDivision from "pages/Administration/BusinessUnitDivision/UpdateBusinessUnitDivision"
import CreateBusinessUnitDivision from "pages/Administration/BusinessUnitDivision/CreateBusinessUnitDivision"
import Securities from "pages/Settings/Securities/securities"
import ViewCredit from "pages/Finance/Credits/ViewCredit"
import Attachments from "pages/Settings/Attachments/Attachment"
import CustomerSites from "pages/Sales/CustomerSites/CustomerSitesList"
import Contacts from "pages/Sales/Contacts/ContactsList"
import Addresses from "pages/Sales/Addresses/AddressesList"
import updateCustomerSites from "pages/Sales/CustomerSites/UpdateCustomerSites"
import updateContact from "pages/Sales/Contacts/UpdateContact"
import updateaddress from "pages/Sales/Addresses/UpdateAddress"

import ComingSoon from "pages/ComingSoon"
import CreateOpportunities from "pages/Sales/Opportunities/CreateOpportunitiesList"
import Opportunities from "pages/Sales/Opportunities/OpportunitiesList"
import CreditAccount from "pages/Finance/Credits/CreditAccount"
import ViewProducts from "pages/Products/BusinessProducts/ViewProduct"
import ViewPricingModels from "pages/Products/PricingModels/ViewpricingModel"
import ViewCompany from "pages/Customers/companies/ViewCompany"
import createCustomerSites from "pages/Sales/CustomerSites/CreateCustomerSites"
import viewCustomerSites from "pages/Sales/CustomerSites/ViewCustomerSites"

import CreateContact from "pages/Sales/Contacts/CreateContact"
import viewContact from "pages/Sales/Contacts/ViewContact"
import createaddress from "pages/Sales/Addresses/CreateAddresses"
import viewaddress from "pages/Sales/Addresses/ViewAddresses"
import ViewBusinessUnit from "pages/Administration/BusinessUnit/ViewBusinessUnit"
import AccountList from "pages/Finance/Accounts/AccountList"
import ViewAccount from "pages/Finance/Accounts/ViewAccount"
import CreateTransaction from "pages/Finance/Accounts/CreateTransaction"
import ViewRole from "pages/Administration/PortalRole/ViewRole"
import ViewUser from "pages/Administration/PortalUser/ViewUser"
import ViewCustomerRole from "pages/Administration/CustomerRole/ViewCustomerRole"
import ViewCustomerUser from "pages/Administration/CustomerUsers/ViewCustomerUser"
import ViewBusinessUnitDivision from "pages/Administration/BusinessUnitDivision/ViewBusinessUnitDivision"
import UpdateTransaction from "pages/Finance/Accounts/UpdateTransaction"
import InvoiceList from "pages/Finance/Invoices/InvoiceList"
import ViewInvoice from "pages/Finance/Invoices/ViewInvoice"
import UpdateInvoice from "pages/Finance/Invoices/UpdateInvoice"
import ReturnsList from "pages/Finance/Returns/ReturnsList"
import ViewReturns from "pages/Finance/Returns/ViewReturns"
import UpdateReturns from "pages/Finance/Returns/UpdateReturns"
import CreateReturns from "pages/Finance/Returns/CreateReturns"

import { azureAuthenticate } from "pages/azureAuthenticate"
import ViewOpportunities from "pages/Sales/Opportunities/ViewOpportunities"
import SecondaryLogin from "pages/SecondaryLogin"
import SecondaryLogout from "pages/Authentication/SecondaryLogout"
import PricingModelApprovals from "pages/Settings/Approvals/PricingModelApprovals"
import CompanySiteApprovalList from "pages/Settings/Approvals/CompanySiteApproval"
import OpportunitiesApprovalList from "pages/Settings/Approvals/OpportunitiesApprovals"
import Approvals from "pages/Settings/Approvals/Approvals"
import CustomPricingModelList from "pages/Sales/CustomPricingModels/CustomPricingModelList"
import ApplicationTemplate from "pages/Settings/Template/ApplicationTemplateList"
import CreateApplicationTemplate from "pages/Settings/Template/CreateApplicationTemplate"
import UpdateApplicationTemplate from "pages/Settings/Template/UpdateApplicationTemplate"
const authProtectedRoutes = [
  // DASHBOARD
  { path: "/dashboard", component: Dashboard },

  // PROFILE
  { path: "/profile", component: UserProfile },

  // Roles
  { path: "/userRole", component: Role },
  { path: "/createRole", component: CreateRole },
  { path: "/updateRole/:id", component: UpdateRole },
  { path: "/viewRole/:id", component: ViewRole },

  // Customer Roles    
  { path: "/customerRoleManagement", component: CustomerRolesManagement },
  { path: "/createCustomerRole", component: CreateCustomerRole },
  { path: "/updateCustomerRole/:id", component: UpdateCustomerRole },
  { path: "/viewCustomerRole/:id", component: ViewCustomerRole},

  // Users
  { path: "/userList", component: User },
  { path: "/createUser", component: CreateUser },
  { path: "/updateUser/:id", component: UpdateUser },
  { path: "/viewUser/:id", component: ViewUser},


  // Companies
  { path: "/companyList", component: CompanyList },
  { path: "/createCompany", component: CreateCompany },
  { path: "/updateCompany/:id", component: UpdateCompany },
  { path: "/viewCompany/:id", component: ViewCompany },


  // Users
  { path: "/customerUser", component: CustomerUser },
  { path: "/createCustomerUser", component: CreateCustomerUser },
  { path: "/updateCustomerUser/:id", component: UpdateCustomerUser },
  { path: "/viewCustomerUser/:id", component: ViewCustomerUser },

  // Credits
  // { path: "/credits", component: Credits },
  // { path: "/createCredits", component: CreateCredit },
  // { path: "/updateCredits/:id", component: UpdateCredit },
  // { path: "/viewCredits", component: ViewCredit },
  //  { path: "/creditAccount", component: CreditAccount},

  // Contracts
  { path: "/contracts", component: Contracts },
  { path: "/createContract", component: CreateContracts },
  { path: "/updateContract/:id", component: UpdateContracts },

  // Business Units
  { path: "/businessUnits", component: BusinessUnits },
  { path: "/createBusinessUnit", component: CreateBusinessUnit },
  { path: "/updateBusinessUnit/:id", component: UpdateBusinessUnit },
  { path: "/viewBusinessUnit/:id", component: ViewBusinessUnit },


  // Business Unit Divisions
  { path: "/businessUnitDivision", component: BusinessUnitDivisions },
  { path: "/createBusinessUnitDivision", component: CreateBusinessUnitDivision },
  { path: "/updateBusinessUnitDivision/:id", component: UpdateBusinessUnitDivision },
  { path: "/viewBusinessUnitDivision/:id", component: ViewBusinessUnitDivision },


  // Products
  { path: "/products", component: ProductsList },
  { path: "/createProducts", component: CreateProducts },
  { path: "/updateProducts/:id", component: UpdateProducts },
  { path: "/viewProducts/:id", component: ViewProducts },

  // Pricing Models
  { path: "/pricingModels", component: PricingModelsList },
  { path: "/createPricingModels", component: CreatePricingModels },
  { path: "/updatePricingModels/:id", component: UpdatePricingModels },
  { path: "/viewPricingModels/:id", component: ViewPricingModels },

  //Custom Pricing Model
  { path: "/custompricingModels", component: CustomPricingModelList},

  //Sales
  { path: "/customerSites", component: CustomerSites },
  {path: "/createCustomerSites", component:createCustomerSites},
  { path: "/updateCustomerSites/:id", component: updateCustomerSites},
  { path: "/viewCustomerSites/:id", component: viewCustomerSites},
  
  { path: "/contacts", component: Contacts },
  { path: "/createContact", component: CreateContact},
  { path: "/updateContact/:id", component: updateContact},
  { path: "/viewContact/:id", component: viewContact},

  { path: "/addresses", component: Addresses },
  { path: "/createAddress", component: createaddress},
  { path: "/updateAddress/:id", component: updateaddress},
  { path: "/viewAddress/:id", component: viewaddress},
  
  
  
  //orders
  { path: "/opportunities", component:Opportunities },
  { path: "/createOpportunities", component: CreateOpportunities },
  { path: "/viewOpportunities/:id", component: ViewOpportunities },


  //Finance

  //Accounts
  { path: "/accountlist", component: AccountList},
  { path: "/viewaccount/:id", component: ViewAccount},
  { path: "/updateTransaction/:id", component: UpdateTransaction},
  { path: "/createTransaction", component: CreateTransaction},


  //Invoices
  { path: "/invoicelist", component: InvoiceList},
  { path: "/viewinvoice/:id", component: ViewInvoice},
  { path: "/Updateinvoice/:id", component: UpdateInvoice},

  //Returns
  { path: "/returnslist", component: ReturnsList},
  { path: "/viewreturns/:id", component: ViewReturns},
  { path: "/updatereturns/:id", component: UpdateReturns},
  { path: "/createReturns", component:CreateReturns},


  // ACTIVITY
  { path: "/activity", component: Activity },

  
  // SETTINGS
  { path: "/azureSSO", component: AzureSSo },
  { path: "/smtpSettings", component: EmailSettings },
  { path: "/securities", component: Securities },
  { path: "/otpValidTime", component: OtpValidTime },
  { path: "/attachment", component: Attachments },
  { path: "/applicationTemplate", component: ApplicationTemplate },
  { path: "/createApplicationTemplate", component: CreateApplicationTemplate },
  { path: "/updateApplicationTemplate/:id", component: UpdateApplicationTemplate },

  //APPROVALS
  { path: "/approvals", component: Approvals },
  { path: "/approvals/pricingmodels",component:PricingModelApprovals},
  { path: "/approvals/opportunities",component:OpportunitiesApprovalList},
  { path: "/approvals/companySites",component:CompanySiteApprovalList},


  // MISCELLANEOUS
  { path: "/comingSoon", component: ComingSoon },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard?skip=0&&limit=10&&transactionStatus=all" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },

  { path: "/verifyuser", component: VerifyUser },
  { path: "/setuppassword", component: SetupPassword },
  { path: "/forgot-password", component: ForgetPwd },

  { path: "/register", component: Register },
  { path: "/heartbeat", component: Heartbeat },
  { path: "/secondaryLogin", component: SecondaryLogin },
  { path: "/secondaryLogout", component: SecondaryLogout },

  { path: "/approval", component: Approval },
  { path: "/ssologin", component: azureAuthenticate },
]

export { publicRoutes, authProtectedRoutes }
