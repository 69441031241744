import {
    GET_PASSWORD_POLICYS,
    GET_PASSWORD_POLICYS_SUCCESS,
    GET_PASSWORD_POLICYS_FAIL,
  
    POST_PASSWORD_POLICY,
    POST_PASSWORD_POLICY_SUCCESS,
    POST_PASSWORD_POLICY_FAIL,
 
    UPDATE_PASSWORD_POLICY,
    UPDATE_PASSWORD_POLICY_SUCCESS,
    UPDATE_PASSWORD_POLICY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    passwordPolicys: null,
    loading: false,
    isCreated: false,
    isUpdated: false,
    error: {},
};

const PassWordPolicysReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PASSWORD_POLICYS:
            return {
                ...state,
                loading: true,
                passwordPolicys: null,
                isCreated: false,
                isUpdated: false
            };
        case GET_PASSWORD_POLICYS_SUCCESS:
            return {
                ...state,
                loading: false,
                passwordPolicys: action.payload,
                isCreated: false,
                isUpdated: false
            };
        case GET_PASSWORD_POLICYS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isUpdated: false
            };
        case POST_PASSWORD_POLICY:
            return {
                ...state,
                loading: true,
                passwordPolicys: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_PASSWORD_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                passwordPolicys: action.payload,
                isCreated: true,
                isUpdated: false
            };
        case POST_PASSWORD_POLICY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isUpdated: false
            };
               
          case UPDATE_PASSWORD_POLICY:
            return {
                ...state,
                loading: true,
                passwordPolicys: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_PASSWORD_POLICY_SUCCESS:
            return {
                ...state,
                loading: false,
                passwordPolicys: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_PASSWORD_POLICY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default PassWordPolicysReducer;
