import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState, useMemo } from "react"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, CardBody, UncontrolledTooltip } from "reactstrap"
import axios from 'axios';
import getHeader from 'config/header';
import TableContainer from 'components/Common/TableContainer'
import { TierNo, TierName, Qualifying, DiscountCode, DiscountValue, Status } from './Tiers/TierListCol';
import PricingModelContext from './PricingModelContext';
import CreateTiers from './Tiers/CreateTier';
import { toast } from 'react-toastify';
import { getBusinessProducts, postPricingmodel } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CreateTierModal from 'components/Common/CreateTier';
import { useCallback } from 'react';
import DeleteModal from 'components/Common/DeleteModal';

let tierForLogicalOps

function CreatePricingModels() {
    const dispatch = useDispatch();
    const history = useHistory()
    const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()
    const [pageState, setPageState] = useState(true)

    const [tiers, setTiers] = useState([])
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    // register('app_pricingmodel_start_date', { required: true });


    const [productList, setProductList] = useState([])
    const businessProductState = useSelector(state => state.BusinessProducts);
    const pricingModelState = useSelector(state => state.PricingModelReducer)

    useEffect(() => {
        setOptionGroupProduct()
        setselectedMultiProduct([])
        dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));
    }, [])

    useEffect(() => {
        if (businessProductState.products !== null) {
            setProductList(businessProductState.products.result);
        }
    }, [businessProductState,])

    //  manage product multi select
    const [selectedMultiProduct, setselectedMultiProduct] = useState([]);
    const [optionGroupProduct, setOptionGroupProduct] = useState([{ options: [] }])
    register('app_pricing_model_product', { required: true });

    useEffect(() => {
        if (productList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in productList) {
                let data = {
                    label: productList[i].app_product_pos_item_code + " | " + productList[i].app_product_payment_code + " | " + productList[i].app_product_local_name,
                    value: productList[i].app_product_id,
                    app_product_id: productList[i].app_product_id
                }
                options.push(data)
            }
            setOptionGroupProduct(options)
        }
    }, [productList])

    const handleChangeProduct = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values
        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupProduct.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_pricing_model_product', postArray)
                        errors.app_pricing_model_product = false
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_pricing_model_product', postArray)
                    errors.app_pricing_model_product = false
                }
            }
        });
        //   setBusUnitUserList(postArray)
        setselectedMultiProduct(postArray);
        if (postArray.length < 1) {
            errors.app_pricing_model_product = true
        }
    };

    useEffect(() => {
        tierForLogicalOps = tiers
    }, [pageState, tiers])



    const [addedTiersLists, setAddedtiersLists] = useState([])
    const [singleRowUpdatetierValue, setSingleRowUpdatetierValue] = useState()
    const [tierInputRange, setTierInputRange] = useState({ min: undefined, max: undefined })
    const [ReadOnly, setReadOnly] = useState(false)

    const initializeEdit = (index, read) => {
        setSingleRowUpdatetierValue(addedTiersLists[index])
        setReadOnly(read)
        setTierInputRange({
            min: addedTiersLists[index - 1]?.app_tier_qualifying_input ? parseInt(addedTiersLists[index - 1]?.app_tier_qualifying_input) + 1 : 1,
            max: addedTiersLists[index + 1]?.app_tier_qualifying_input ? parseInt(addedTiersLists[index + 1].app_tier_qualifying_input) - 1 : 9999999
        })
        setTierModal(true)
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Tier No',
                accessor: (row, index) => index + 1,
                filterable: true,
                Cell: (cellProps) => {
                    return <span>{cellProps.value}</span>;
                }
            },
            {
                Header: 'Tier Name',
                accessor: 'app_tier_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <TierName {...cellProps} />;
                }
            },
            {
                Header: 'Qualifying Input',
                accessor: 'app_tier_qualifying_input',
                filterable: true,
                Cell: (cellProps) => {
                    return <Qualifying {...cellProps} />;
                }
            },
            {
                Header: 'Discount Value',
                accessor: 'app_tier_discount_value',
                filterable: true,
                Cell: (cellProps) => {
                    return <DiscountValue {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {/* <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                const idTBD = cellProps.row.original.localID;
                                deleteTier(idTBD)
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                Delete
                                </UncontrolledTooltip>
                            </Link> */}

                            <Link
                                to="#"
                                className="text-info"
                                onClick={() => {
                                    initializeEdit(cellProps.row.index, addedTiersLists.length === cellProps.row.index + 1)
                                }}
                            >
                                <button className="btn btn-info btn-sm" id="deletetooltip">Edit</button>
                            </Link>

                            {/* <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const rowIndex = cellProps.row.index;
                                    handleDeleteRow(rowIndex);
                                }}
                            >
                                <button className="btn btn-danger btn-sm" id="deletetooltip">Delete</button>
                            </Link> */}


                        </div>
                    );
                }
            },
        ],
        [addedTiersLists]
    );


    const removeSelectedProduct = (indexToRemove) => {
        const newData = selectedMultiProduct.filter((_, index) => index !== indexToRemove);
        setselectedMultiProduct(newData);
    }

      const columns2 = useMemo(
        () => [
          {
            Header: 'S.No',
            accessor: (row, index) => index + 1,
            filterable: true,
            Cell: (cellProps) => {
              return <span>{cellProps.value}</span>;
            }
          },
          {
            Header: 'Product Name',
            accessor: 'label',
            filterable: true,
            Cell: (cellProps) => {
              return <span>{cellProps.value}</span>;
            }
          },
          {
            Header: 'Action',
            accessor: (row, index) => index ,
            Cell: (cellProps) => {
              return (
                <div className="d-flex gap-3">
                  <button type='button' className="btn btn-danger btn-sm" onClick={() => { removeSelectedProduct(cellProps.value) }}>X</button>
                </div>
              );
            }
          },

        ],
        [selectedMultiProduct]
      );

    const deleteTier = (id) => {
        if (id) {
            const newTier = tierForLogicalOps.filter(tier => tier.localID !== id)
            setTiers(newTier)
        }
    }

    const createPricingModel = (data) => {
        data = {
            ...data,
            tiers: addedTiersLists ? addedTiersLists : [],
            app_pricing_model_start_date: startDate.toString(),
            app_pricing_model_end_date: endDate.toString(),
            is_active: true
        }
        console.log(data);
        dispatch(postPricingmodel(data))
    }

    useEffect(() => {
        if (pricingModelState.isCreated) {
            history.push("/pricingModels?skip=0&&limit=10&&queryMultistatus=all")
        }

    }, [pricingModelState])

    const ctxValues = {
        setPageState, tiers, setTiers
    }

    const [pricingtype, setPricingtype] = useState('')
    const [tierModal, setTierModal] = useState(false)
    const [maxTierInput, setMaxTierInput] = useState("")
    const [discountType, setDiscountType] = useState()
    const [qualifyingType, setQualifyingType] = useState()
    const [qualifyingTypeBtn, setQualifyingBtn] = useState(false)
    const [discountTypeBtn, setDiscountTypebtn] = useState(false)
    const [endDateInputClick, setEndDateInputClick] = useState(true)
    const [deleteModal, setDeleteModal] = useState(false);

    const pricingType = (e) => {
        setPricingtype(e)
    }

    const getRndInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const handleDataSelection = (selectedData) => {
        function getData(prevState, selectedData) {
            prevState?.pop()
            let newData = [
                ...prevState,
                selectedData,
                {
                    ...selectedData,
                    app_tier_qualifying_input: (parseInt(selectedData.app_tier_qualifying_input) + 1).toString(),
                    localID: getRndInteger(1, 9999)
                }
            ]
            return newData
        }
        setAddedtiersLists((prevState) => getData(prevState, selectedData))
        setTierModal(false)
    };


    const handleDeleteRow = (index) => {
        const newData = [...addedTiersLists];
        newData.splice(index, 1)
        setAddedtiersLists(newData);
    };

    const handleUpdateTier = (data) => {
        const newData = addedTiersLists.map(item => ({ ...item }))
        const index = newData.findIndex(item => item.localID === data.localID)
        if (index >= 0) {
            newData[index] = data
            setAddedtiersLists(newData)
        }
        setSingleRowUpdatetierValue("")
        setTierModal(false)
    }

    useEffect(() => {
        setValue("approval_status", "Pending")
    }, [])


    const getTableData = useCallback(() => {
        const data = addedTiersLists.map(item => ({ ...item }))
        let newData = []
        let maxInput = 1
        data.forEach((item, index) => {
            const tierInput = parseInt(item.app_tier_qualifying_input)
            let value
            if (!(data.length === index + 1)) {    // !(data.length === index + 1)
                value = {
                    ...item,
                    app_tier_qualifying_input: maxInput + " - " + tierInput
                }
                maxInput = tierInput + 1
            } else {
                value = {
                    ...item,
                    app_tier_qualifying_input: (maxInput - 1) + "+"
                }
            }
            newData.push(value)
        })
        return newData
    }, [addedTiersLists])

    useEffect(() => {
        let maxValue = 0
        addedTiersLists.forEach(item => {
            let tierInput = parseInt(item.app_tier_qualifying_input)
            if (tierInput > maxValue) {
                maxValue = tierInput + 1
            }
        })
        setMaxTierInput(maxValue)
    }, [addedTiersLists])


    const handleDeleteAllTiers = () => {
        setAddedtiersLists([])
        setDeleteModal(false)
    }

   
    return (
        <React.Fragment>
            <PricingModelContext.Provider value={ctxValues}>
                {pageState ?
                    <>
                        <CreateTierModal
                            show={tierModal}
                            discountType={discountType}
                            qualifyingType={qualifyingType}
                            singleRowUpdatetierValue={singleRowUpdatetierValue}
                            tierInputRange={tierInputRange}
                            onCloseClick={() => { setTierModal(false); setSingleRowUpdatetierValue(""); setReadOnly(false) }}
                            onDataSelect={handleDataSelection}
                            handleUpdateTier={handleUpdateTier}
                            maxTierInput={maxTierInput}
                            ReadOnly={ReadOnly}
                        />
                        <DeleteModal
                            show={deleteModal}
                            onDeleteClick={handleDeleteAllTiers}
                            onCloseClick={() => setDeleteModal(false)} />


                        <div className="page-content">
                            <Breadcrumbs title="Create Pricing Model" breadcrumbItem="Products" />
                            <Container fluid>
                                <Row>
                                    <Col lg="12">
                                        <Card body>
                                            <Form>
                                                <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Pricing Model Name <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6">
                                                            <input type="text" className='form form-control' {...register('app_pricing_model_name', { required: true })} />
                                                            {errors.app_pricing_model_name && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Pricing Model Type<span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6">
                                                            <select className="form-select d-inline" onInput={(e) => { pricingType(e.target.value) }} {...register('app_pricing_model_type', { required: true })}>
                                                                <option hidden value="">Select Pricing Model Type</option>
                                                                <option value="Flat Rate">Flat Rate</option>
                                                                <option value="Tier">Tier</option>
                                                            </select>
                                                            {errors.app_pricing_model_type && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row>
                                                <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                            Products <span style={{ color: "red" }}>*</span>&nbsp; :
                                                        </Label>
                                                        <Col lg="5" md="6">
                                                            <Select
                                                                value={selectedMultiProduct}
                                                                isMulti={true}
                                                                onChange={handleChangeProduct}
                                                                options={optionGroupProduct}
                                                                name={"app_product_id"}
                                                                className="modal__input"
                                                            />
                                                            {errors.app_pricing_model_product && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row>

                                                {selectedMultiProduct.length > 0 ? <Row>
                                                    <Col xs="12">
                                                        <Card>
                                                            <CardBody>
                                                                <TableContainer
                                                                    columns={columns2}
                                                                    data={selectedMultiProduct}
                                                                    isGlobalFilter={true}
                                                                    isTwoColumnTable={true}
                                                                    isthemeGlobalFilter={true}
                                                                    className="custom-header-css"
                                                                    // customPageCount={pageCount}
                                                                    customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row> : ""}

                                                {pricingtype === "Flat Rate" || pricingtype === "Tier" ? <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end ">Qualifying Type <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6">
                                                            <select defaultValue="" onInput={(e) => { setQualifyingType(e.target.value); setQualifyingBtn(true) }} className="form-select d-inline" aria-label="Default select example" {...register("app_pricing_model_qualifying_type", { required: true })}>
                                                                <option hidden value="">Select Qualifying Type</option>
                                                                <option value="By PO Amount">PO Amount</option>
                                                                <option value="By PO Qty">PO Qty</option>
                                                            </select>
                                                            {errors.app_pricing_model_qualifying_type && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row> : ""}
                                                {pricingtype === "Flat Rate" ? <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end ">Qualifying Value <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6">
                                                            <input type="number" className='form form-control' {...register('app_pricing_model_qualifying_value', { required: true })} />
                                                            {errors.app_pricing_model_qualifying_value && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row> : ""}
                                                {pricingtype === "Flat Rate" || pricingtype === "Tier" ? <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end ">Discount Type <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6"> <select onInput={(e) => { setDiscountType(e.target.value); setDiscountTypebtn(true) }} defaultValue="" className="form-select d-inline" aria-label="Default select example" {...register("app_pricing_model_discount_type", { required: true })}>
                                                            <option hidden value="">Select Discount Type</option>
                                                            <option value="By Value">By Value</option>
                                                            <option value="By Percentage">By Percentage</option>
                                                        </select>
                                                            {errors.app_pricing_model_discount_type && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row> : ""}
                                                {pricingtype === "Flat Rate" ? <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Discount Value <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6">
                                                            <input type="number" className='form form-control' {...register('app_pricing_model_discount_value', { required: true })} />
                                                            {errors.app_pricing_model_discount_value && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row> : ""}
                                                {pricingtype === "Flat Rate" || pricingtype === "Tier" ? <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Start Date <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6">
                                                            <DatePicker minDate={new Date()} className='form form-control' placeholderText='YYYY-MM-DD' dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => { setStartDate(date); setEndDateInputClick(false) }} />
                                                            {errors.app_pricing_model_start_date && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row> : ""}
                                                {pricingtype === "Flat Rate" || pricingtype === "Tier" ? <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">End Date <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6">
                                                            <DatePicker disabled={endDateInputClick} minDate={new Date(startDate).setDate(new Date(startDate).getDate() + 1)} className='form form-control' placeholderText='YYYY-MM-DD' dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(date)} />
                                                            {errors.app_pricing_model_end_date && <p style={{ color: "red" }}>This Field is required</p>}
                                                        </Col>
                                                    </FormGroup>
                                                </Row> : ""}
                                                <Row>
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Approval Status <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                                        <Col lg="5" md="6">
                                                            <select value="Pending" style={{ backgroundColor: "#D2D4D1" }} disabled className="form-select d-inline" aria-label="Default select example" {...register("approval_status")}>
                                                                <option value="Pending">Pending</option>
                                                            </select>
                                                        </Col>
                                                    </FormGroup>
                                                </Row>
                                                {qualifyingTypeBtn && discountTypeBtn && (pricingtype === "Tier") ? <Row>
                                                    <Col className='d-flex justify-content-end' lg="12">
                                                        <FormGroup className='d-flex justify-content-end mt-3'>
                                                            <Button id='' color='success' type="button" onClick={() => { setTierModal(true) }} className=' btn-rounded m-2'>
                                                                +  Add Tier
                                                            </Button>
                                                            <Button id='' color='danger' type="button" onClick={() => { setDeleteModal(true) }} className=' btn-rounded m-2'>
                                                                Delete Tier
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row> : ""}
                                                {qualifyingTypeBtn && discountTypeBtn && (pricingtype === "Tier") ?
                                                    <Row>
                                                        <Col xs="12">
                                                            <Card>
                                                                <CardBody>
                                                                    <TableContainer
                                                                        columns={columns}
                                                                        data={getTableData()}
                                                                        isGlobalFilter={true}
                                                                        // isAddTier={true}
                                                                        // handleAddtier={() => setPageState(false)}
                                                                        customPageSize={10}
                                                                        className="custom-header-css"
                                                                    />
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row> : ""}
                                                <Row className="mt-4">
                                                    <FormGroup row>
                                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                                        <Col lg="5" md="6">
                                                            <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                                Back
                                                            </Button>
                                                            <Button id="createpricingmodel" color='success' type='button' onClick={handleSubmit(createPricingModel)} className='m-2'>
                                                                Create
                                                            </Button>
                                                        </Col>
                                                    </FormGroup>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </>

                    :
                    <CreateTiers />
                }
            </PricingModelContext.Provider>
        </React.Fragment>
    )
}

export default CreatePricingModels


///////////////////////////////                 drop down Api call                 ///////////////////////////////////////

// import React from 'react'
// import { Link, useHistory } from 'react-router-dom'
// import { useForm } from "react-hook-form"
// import { useEffect, useState, useMemo } from "react"
// import Breadcrumbs from "components/Common/Breadcrumb";
// import { Card, Col, Row, Button, Container, Form, FormGroup, Label, CardBody, UncontrolledTooltip } from "reactstrap"
// import axios from 'axios';
// import getHeader from 'config/header';
// import TableContainer from 'components/Common/TableContainer'
// import { TierNo, TierName, Qualifying, DiscountCode, DiscountValue, Status } from './Tiers/TierListCol';
// import PricingModelContext from './PricingModelContext';
// import CreateTiers from './Tiers/CreateTier';
// import { toast } from 'react-toastify';
// import { getBusinessProducts, postPricingmodel } from 'store/actions';
// import { useDispatch, useSelector } from 'react-redux';
// import Select from 'react-select'
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import CreateTierModal from 'components/Common/CreateTier';
// import { useCallback } from 'react';
// import DeleteModal from 'components/Common/DeleteModal';
// import { useRef } from 'react';
// import { API_BASE_URL, BUSINESS_PRODUCTS_URL } from 'helpers/url_helper';

// let tierForLogicalOps

// function CreatePricingModels() {


//     const dispatch = useDispatch();
//     const history = useHistory()
//     const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()
//     const [pageState, setPageState] = useState(true)

//     const [tiers, setTiers] = useState([])
//     const [startDate, setStartDate] = useState();
//     const [endDate, setEndDate] = useState();
//     // register('app_pricingmodel_start_date', { required: true });

//     const [productList, setProductList] = useState([])

//     const businessProductState = useSelector(state => state.BusinessProducts);
//     const pricingModelState = useSelector(state => state.PricingModelReducer)

//     const [limit, setLimit] = useState(25)
//     const [skip, setSkip] = useState(0)

//     useEffect(() => {
//         setOptionGroupProduct()
//         setselectedMultiProduct([])
//         dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: limit, skip: skip }));
//     }, [])


//     useEffect(() => {
//         if (businessProductState.products !== null) {
//             setProductList(businessProductState.products.result);
//             setSearchLimit(businessProductState?.products?.summary?.filteredsize)
//         }
//     }, [businessProductState])

//     //  manage product multi select
//     const [selectedMultiProduct, setselectedMultiProduct] = useState([]);
//     const [optionGroupProduct, setOptionGroupProduct] = useState([{ options: [] }])
//     register('app_pricing_model_product', { required: true });

//     useEffect(() => {
//         if (productList) {
//             let options = []
//             options.push({ label: "Select All", value: "select_all" })
//             for (let i in productList) {
//                 let data = {
//                     label: productList[i].app_product_pos_item_code + " | " + productList[i].app_product_payment_code + " | " + productList[i].app_product_local_name,
//                     value: productList[i].app_product_id,
//                     app_product_id: productList[i].app_product_id
//                 }
//                 options.push(data)
//             }
//             setOptionGroupProduct(options)
//         }

//     }, [productList])

//     const handleChangeProduct = (Data) => {
//         const postArray = [];
//         const selectedValues = new Set();

//         Data.forEach((option) => {
//             if (option.value === 'select_all') {
//                 const splicedOptions = optionGroupProduct.slice(1);
//                 splicedOptions.forEach((option) => {
//                     const optionValue = option.value.toString();
//                     if (!selectedValues.has(optionValue)) {
//                         postArray.push(option);
//                         selectedValues.add(optionValue);
//                         setValue('app_pricing_model_product', postArray);
//                         errors.app_pricing_model_product = false;
//                     }
//                 });
//             } else {
//                 const optionValue = option.value.toString();
//                 if (!selectedValues.has(optionValue)) {
//                     postArray.push(option);
//                     selectedValues.add(optionValue);
//                     setValue('app_pricing_model_product', postArray);
//                     errors.app_pricing_model_product = false;
//                 }
//             }
//         });

//         setselectedMultiProduct(postArray);

//         if (postArray.length < 1) {
//             errors.app_pricing_model_product = true;
//         }
//     };


//     useEffect(() => {
//         tierForLogicalOps = tiers
//     }, [pageState, tiers])



//     const [addedTiersLists, setAddedtiersLists] = useState([])
//     const [singleRowUpdatetierValue, setSingleRowUpdatetierValue] = useState()
//     const [tierInputRange, setTierInputRange] = useState({ min: undefined, max: undefined })
//     const [ReadOnly, setReadOnly] = useState(false)

//     const initializeEdit = (index, read) => {
//         setSingleRowUpdatetierValue(addedTiersLists[index])
//         setReadOnly(read)
//         setTierInputRange({
//             min: addedTiersLists[index - 1]?.app_tier_qualifying_input ? parseInt(addedTiersLists[index - 1]?.app_tier_qualifying_input) + 1 : 1,
//             max: addedTiersLists[index + 1]?.app_tier_qualifying_input ? parseInt(addedTiersLists[index + 1].app_tier_qualifying_input) - 1 : 9999999
//         })
//         setTierModal(true)
//     }

//     const columns = useMemo(
//         () => [
//             {
//                 Header: 'Tier No',
//                 accessor: (row, index) => index + 1,
//                 filterable: true,
//                 Cell: (cellProps) => {
//                     return <span>{cellProps.value}</span>;
//                 }
//             },
//             {
//                 Header: 'Tier Name',
//                 accessor: 'app_tier_name',
//                 filterable: true,
//                 Cell: (cellProps) => {
//                     return <TierName {...cellProps} />;
//                 }
//             },
//             {
//                 Header: 'Qualifying Input',
//                 accessor: 'app_tier_qualifying_input',
//                 filterable: true,
//                 Cell: (cellProps) => {
//                     return <Qualifying {...cellProps} />;
//                 }
//             },
//             {
//                 Header: 'Discount Value',
//                 accessor: 'app_tier_discount_value',
//                 filterable: true,
//                 Cell: (cellProps) => {
//                     return <DiscountValue {...cellProps} />;
//                 }
//             },
//             {
//                 Header: 'Action',
//                 Cell: (cellProps) => {
//                     return (
//                         <div className="d-flex gap-3">
//                             {/* <Link
//                                 to="#"
//                                 className="text-danger"
//                                 onClick={() => {
//                                 const idTBD = cellProps.row.original.localID;
//                                 deleteTier(idTBD)
//                                 }}
//                             >
//                                 <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
//                                 <UncontrolledTooltip placement="top" target="deletetooltip">
//                                 Delete
//                                 </UncontrolledTooltip>
//                             </Link> */}

//                             <Link
//                                 to="#"
//                                 className="text-info"
//                                 onClick={() => {
//                                     initializeEdit(cellProps.row.index, addedTiersLists.length === cellProps.row.index + 1)
//                                 }}
//                             >
//                                 <button className="btn btn-info btn-sm" id="deletetooltip">Edit</button>
//                             </Link>

//                             {/* <Link
//                                 to="#"
//                                 className="text-danger"
//                                 onClick={() => {
//                                     const rowIndex = cellProps.row.index;
//                                     handleDeleteRow(rowIndex);
//                                 }}
//                             >
//                                 <button className="btn btn-danger btn-sm" id="deletetooltip">Delete</button>
//                             </Link> */}


//                         </div>
//                     );
//                 }
//             },
//         ],
//         [addedTiersLists]
//     );


//     const removeSelectedProduct = (indexToRemove) => {
//         const newData = selectedMultiProduct.filter((_, index) => index !== indexToRemove);
//         setselectedMultiProduct(newData);
//     }

//     const columns2 = useMemo(
//         () => [
//             {
//                 Header: 'S.No',
//                 accessor: (row, index) => index + 1,
//                 filterable: true,
//                 Cell: (cellProps) => {
//                     return <span>{cellProps.value}</span>;
//                 }
//             },
//             {
//                 Header: 'Product Name',
//                 accessor: 'label',
//                 filterable: true,
//                 Cell: (cellProps) => {
//                     return <span>{cellProps.value}</span>;
//                 }
//             },
//             {
//                 Header: 'Action',
//                 accessor: (row, index) => index,
//                 Cell: (cellProps) => {
//                     return (
//                         <div className="d-flex gap-3">
//                             <button type='button' className="btn btn-danger btn-sm" onClick={() => { removeSelectedProduct(cellProps.value) }}>X</button>
//                         </div>
//                     );
//                 }
//             },

//         ],
//         [selectedMultiProduct]
//     );

//     const deleteTier = (id) => {
//         if (id) {
//             const newTier = tierForLogicalOps.filter(tier => tier.localID !== id)
//             setTiers(newTier)
//         }
//     }

//     const createPricingModel = (data) => {
//         data = {
//             ...data,
//             tiers: addedTiersLists ? addedTiersLists : [],
//             app_pricing_model_start_date: startDate.toString(),
//             app_pricing_model_end_date: endDate.toString(),
//             is_active: true
//         }
//         console.log(data);
//         dispatch(postPricingmodel(data))
//     }

//     useEffect(() => {
//         if (pricingModelState.isCreated) {
//             history.push("/pricingModels?skip=0&&limit=10&&queryMultistatus=all")
//         }
//     }, [pricingModelState])

//     const ctxValues = {
//         setPageState, tiers, setTiers
//     }

//     const [pricingtype, setPricingtype] = useState('')
//     const [tierModal, setTierModal] = useState(false)
//     const [maxTierInput, setMaxTierInput] = useState("")
//     const [discountType, setDiscountType] = useState()
//     const [qualifyingType, setQualifyingType] = useState()
//     const [qualifyingTypeBtn, setQualifyingBtn] = useState(false)
//     const [discountTypeBtn, setDiscountTypebtn] = useState(false)
//     const [endDateInputClick, setEndDateInputClick] = useState(true)
//     const [deleteModal, setDeleteModal] = useState(false);

//     const pricingType = (e) => {
//         setPricingtype(e)
//     }

//     const getRndInteger = (min, max) => {
//         return Math.floor(Math.random() * (max - min + 1)) + min;
//     }

//     const handleDataSelection = (selectedData) => {
//         function getData(prevState, selectedData) {
//             prevState?.pop()
//             let newData = [
//                 ...prevState,
//                 selectedData,
//                 {
//                     ...selectedData,
//                     app_tier_qualifying_input: (parseInt(selectedData.app_tier_qualifying_input) + 1).toString(),
//                     localID: getRndInteger(1, 9999)
//                 }
//             ]
//             return newData
//         }
//         setAddedtiersLists((prevState) => getData(prevState, selectedData))
//         setTierModal(false)
//     };


//     const handleDeleteRow = (index) => {
//         const newData = [...addedTiersLists];
//         newData.splice(index, 1)
//         setAddedtiersLists(newData);
//     };

//     const handleUpdateTier = (data) => {
//         const newData = addedTiersLists.map(item => ({ ...item }))
//         const index = newData.findIndex(item => item.localID === data.localID)
//         if (index >= 0) {
//             newData[index] = data
//             setAddedtiersLists(newData)
//         }
//         setSingleRowUpdatetierValue("")
//         setTierModal(false)
//     }

//     useEffect(() => {
//         setValue("approval_status", "Pending")
//     }, [])


//     const getTableData = useCallback(() => {
//         const data = addedTiersLists.map(item => ({ ...item }))
//         let newData = []
//         let maxInput = 1
//         data.forEach((item, index) => {
//             const tierInput = parseInt(item.app_tier_qualifying_input)
//             let value
//             if (!(data.length === index + 1)) {    // !(data.length === index + 1)
//                 value = {
//                     ...item,
//                     app_tier_qualifying_input: maxInput + " - " + tierInput
//                 }
//                 maxInput = tierInput + 1
//             } else {
//                 value = {
//                     ...item,
//                     app_tier_qualifying_input: (maxInput - 1) + "+"
//                 }
//             }
//             newData.push(value)
//         })
//         return newData
//     }, [addedTiersLists])

//     useEffect(() => {
//         let maxValue = 0
//         addedTiersLists.forEach(item => {
//             let tierInput = parseInt(item.app_tier_qualifying_input)
//             if (tierInput > maxValue) {
//                 maxValue = tierInput + 1
//             }
//         })
//         setMaxTierInput(maxValue)
//     }, [addedTiersLists])


//     const handleDeleteAllTiers = () => {
//         setAddedtiersLists([])
//         setDeleteModal(false)
//     }



//     const [isLoading, setIsLoading] = useState(false);

//     const handleSearchFilter = (data) => {
//         if (data.length > 0) {
//             let filter = `{"is_active":true,"$or":[
//                 {"app_product_payment_code":{"$regex":"${data}","$options":"i"}},
//                 {"app_product_local_name":{"$regex":"${data}","$options":"i"}},
//                 {"app_product_pos_item_code":{"$regex":"${data}","$options":"i"}}
//             ]}`
//             dispatch(getBusinessProducts({ filter: filter, limit: limit, skip: 0 }));
//         } else {
//             dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: limit, skip: 0 }));
//             setSkip(0)
//         }
//     }

//     const [searchLimit, setSearchLimit] = useState("")

//     const handleLoadMoreOptions = () => {

//         if (skip < searchLimit) {
//             console.log("searchLimit", searchLimit);
//             setIsLoading(true);
//             setSkip(skip + 25)
//             let Value = skip + 25

//             setTimeout(() => {
//                 const token = sessionStorage.getItem('authUser')
//                 axios.get(`${API_BASE_URL}${BUSINESS_PRODUCTS_URL}?filter={"is_active":true}&limit=${limit}&skip=${Value}`, getHeader(token))
//                     .then((res) => {
//                         console.log("res", res);

//                         let moreOptions = []
//                         for (let i in res.data.result) {
//                             let data = {
//                                 label: productList[i].app_product_pos_item_code + " | " + productList[i].app_product_payment_code + " | " + productList[i].app_product_local_name,
//                                 value: productList[i].app_product_id,
//                                 app_product_id: productList[i].app_product_id
//                             }
//                             moreOptions.push(data)
//                         }
//                         setOptionGroupProduct([...optionGroupProduct, ...moreOptions]);
//                         setIsLoading(false);
//                     }).catch((err) => {
//                         console.log("err", err);
//                     })
//             }, 100);
//         }

//     };

//     const handleScrollBottom = () => {
//         if (!isLoading) {
//             handleLoadMoreOptions();
//         }
//     };

//     return (
//         <React.Fragment>
//             <PricingModelContext.Provider value={ctxValues}>
//                 {pageState ?
//                     <>
//                         <CreateTierModal
//                             show={tierModal}
//                             discountType={discountType}
//                             qualifyingType={qualifyingType}
//                             singleRowUpdatetierValue={singleRowUpdatetierValue}
//                             tierInputRange={tierInputRange}
//                             onCloseClick={() => { setTierModal(false); setSingleRowUpdatetierValue(""); setReadOnly(false) }}
//                             onDataSelect={handleDataSelection}
//                             handleUpdateTier={handleUpdateTier}
//                             maxTierInput={maxTierInput}
//                             ReadOnly={ReadOnly}
//                         />
//                         <DeleteModal
//                             show={deleteModal}
//                             onDeleteClick={handleDeleteAllTiers}
//                             onCloseClick={() => setDeleteModal(false)} />


//                         <div className="page-content">
//                             <Breadcrumbs title="Create Pricing Model" breadcrumbItem="Products" />
//                             <Container fluid>
//                                 <Row>
//                                     <Col lg="12">
//                                         <Card body>
//                                             <Form>
//                                                 <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Pricing Model Name <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6">
//                                                             <input type="text" className='form form-control' {...register('app_pricing_model_name', { required: true })} />
//                                                             {errors.app_pricing_model_name && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row>
//                                                 <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Pricing Model Type<span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6">
//                                                             <select className="form-select d-inline" onInput={(e) => { pricingType(e.target.value) }} {...register('app_pricing_model_type', { required: true })}>
//                                                                 <option hidden value="">Select Pricing Model Type</option>
//                                                                 <option value="Flat Rate">Flat Rate</option>
//                                                                 <option value="Tier">Tier</option>
//                                                             </select>
//                                                             {errors.app_pricing_model_type && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row>
//                                                 <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
//                                                             Products <span style={{ color: "red" }}>*</span>&nbsp; :
//                                                         </Label>
//                                                         <Col lg="5" md="6">

//                                                             <Select
//                                                                 onInputChange={handleSearchFilter}
//                                                                 onMenuScrollToBottom={handleScrollBottom}
//                                                                 value={selectedMultiProduct}
//                                                                 isMulti={true}
//                                                                 onChange={handleChangeProduct}
//                                                                 options={optionGroupProduct}
//                                                                 name={"app_product_id"}
//                                                                 className="modal__input"
//                                                                 styles={{ menu: (provided) => ({ ...provided, zIndex: 9999 }) }} // Example styling
//                                                                 isLoading={isLoading}
//                                                             />
//                                                             {errors.app_pricing_model_product && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row>

//                                                 {/* // onMenuScrollToBottom={handleScrollBottom}
//                                                                 // onMenuScrollToTop={handleScrollTop}
//                                                                 // value={selectedMultiProduct}
//                                                                 // isMulti={true}
//                                                                 // onChange={handleChangeProduct}
//                                                                 // options={optionGroupProduct}
//                                                                 // name={"app_product_id"}
//                                                                 // className="modal__input" */}

//                                                 {selectedMultiProduct.length > 0 ? <Row>
//                                                     <Col xs="12">
//                                                         <Card>
//                                                             <CardBody>
//                                                                 <TableContainer
//                                                                     columns={columns2}
//                                                                     data={selectedMultiProduct}
//                                                                     isGlobalFilter={true}
//                                                                     isTwoColumnTable={true}
//                                                                     isthemeGlobalFilter={true}
//                                                                     className="custom-header-css"
//                                                                     // customPageCount={pageCount}
//                                                                     customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
//                                                                 />
//                                                             </CardBody>
//                                                         </Card>
//                                                     </Col>
//                                                 </Row> : ""}

//                                                 {pricingtype === "Flat Rate" || pricingtype === "Tier" ? <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end ">Qualifying Type <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6">
//                                                             <select defaultValue="" onInput={(e) => { setQualifyingType(e.target.value); setQualifyingBtn(true) }} className="form-select d-inline" aria-label="Default select example" {...register("app_pricing_model_qualifying_type", { required: true })}>
//                                                                 <option hidden value="">Select Qualifying Type</option>
//                                                                 <option value="By PO Amount">PO Amount</option>
//                                                                 <option value="By PO Qty">PO Qty</option>
//                                                             </select>
//                                                             {errors.app_pricing_model_qualifying_type && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row> : ""}
//                                                 {pricingtype === "Flat Rate" ? <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end ">Qualifying Value <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6">
//                                                             <input type="number" className='form form-control' {...register('app_pricing_model_qualifying_value', { required: true })} />
//                                                             {errors.app_pricing_model_qualifying_value && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row> : ""}
//                                                 {pricingtype === "Flat Rate" || pricingtype === "Tier" ? <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end ">Discount Type <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6"> <select onInput={(e) => { setDiscountType(e.target.value); setDiscountTypebtn(true) }} defaultValue="" className="form-select d-inline" aria-label="Default select example" {...register("app_pricing_model_discount_type", { required: true })}>
//                                                             <option hidden value="">Select Discount Type</option>
//                                                             <option value="By Value">By Value</option>
//                                                             <option value="By Percentage">By Percentage</option>
//                                                         </select>
//                                                             {errors.app_pricing_model_discount_type && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row> : ""}
//                                                 {pricingtype === "Flat Rate" ? <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Discount Value <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6">
//                                                             <input type="number" className='form form-control' {...register('app_pricing_model_discount_value', { required: true })} />
//                                                             {errors.app_pricing_model_discount_value && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row> : ""}
//                                                 {pricingtype === "Flat Rate" || pricingtype === "Tier" ? <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Start Date <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6">
//                                                             <DatePicker minDate={new Date()} className='form form-control' placeholderText='YYYY-MM-DD' dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => { setStartDate(date); setEndDateInputClick(false) }} />
//                                                             {errors.app_pricing_model_start_date && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row> : ""}
//                                                 {pricingtype === "Flat Rate" || pricingtype === "Tier" ? <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">End Date <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6">
//                                                             <DatePicker disabled={endDateInputClick} minDate={new Date(startDate).setDate(new Date(startDate).getDate() + 1)} className='form form-control' placeholderText='YYYY-MM-DD' dateFormat="yyyy-MM-dd" selected={endDate} onChange={(date) => setEndDate(date)} />
//                                                             {errors.app_pricing_model_end_date && <p style={{ color: "red" }}>This Field is required</p>}
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row> : ""}
//                                                 <Row>
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Approval Status <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
//                                                         <Col lg="5" md="6">
//                                                             <select value="Pending" style={{ backgroundColor: "#D2D4D1" }} disabled className="form-select d-inline" aria-label="Default select example" {...register("approval_status")}>
//                                                                 <option value="Pending">Pending</option>
//                                                             </select>
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row>
//                                                 {qualifyingTypeBtn && discountTypeBtn && (pricingtype === "Tier") ? <Row>
//                                                     <Col className='d-flex justify-content-end' lg="12">
//                                                         <FormGroup className='d-flex justify-content-end mt-3'>
//                                                             <Button id='' color='success' type="button" onClick={() => { setTierModal(true) }} className=' btn-rounded m-2'>
//                                                                 +  Add Tier
//                                                             </Button>
//                                                             <Button id='' color='danger' type="button" onClick={() => { setDeleteModal(true) }} className=' btn-rounded m-2'>
//                                                                 Delete Tier
//                                                             </Button>
//                                                         </FormGroup>
//                                                     </Col>
//                                                 </Row> : ""}
//                                                 {qualifyingTypeBtn && discountTypeBtn && (pricingtype === "Tier") ?
//                                                     <Row>
//                                                         <Col xs="12">
//                                                             <Card>
//                                                                 <CardBody>
//                                                                     <TableContainer
//                                                                         columns={columns}
//                                                                         data={getTableData()}
//                                                                         isGlobalFilter={true}
//                                                                         // isAddTier={true}
//                                                                         // handleAddtier={() => setPageState(false)}
//                                                                         customPageSize={10}
//                                                                         className="custom-header-css"
//                                                                     />
//                                                                 </CardBody>
//                                                             </Card>
//                                                         </Col>
//                                                     </Row> : ""}
//                                                 <Row className="mt-4">
//                                                     <FormGroup row>
//                                                         <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
//                                                         <Col lg="5" md="6">
//                                                             <Button color='secondary' type='button' onClick={() => history.goBack()}>
//                                                                 Back
//                                                             </Button>
//                                                             <Button id="createpricingmodel" color='success' type='button' onClick={handleSubmit(createPricingModel)} className='m-2'>
//                                                                 Create
//                                                             </Button>
//                                                         </Col>
//                                                     </FormGroup>
//                                                 </Row>
//                                             </Form>
//                                         </Card>
//                                     </Col>
//                                 </Row>
//                             </Container>
//                         </div>
//                     </>

//                     :
//                     <CreateTiers />
//                 }
//             </PricingModelContext.Provider>
//         </React.Fragment>
//     )
// }

// export default CreatePricingModels