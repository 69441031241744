import axios from 'axios';
import getHeader from 'config/header';
import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';

const ContactName = (cell) => {
    return cell.value ? cell.value : '';
};

const Company = (cell) => {
    // let Data =  cell.value
    // let Dta = Data.map((item) => item.app_business_name)
    // return Dta ? Dta.join(" , ") : ''

    return cell.value ? cell.value : '';
};

const Site = (cell) => {
    return cell.value ? cell.value : '';
};


const Position = (cell) => {
    return cell.value ? cell.value : '';
};

const Department = (cell) => {
    return cell.value ? cell.value: '';
};

const Email = (cell) => {
    return cell.value ? cell.value: '';
};

const Status = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

export {
    ContactName,
    Company,
    Site,
    Position,
    Department,
    Email,
    Status,
    // SiteID
};