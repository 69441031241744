import {
    GET_SYS_ROLES,
    GET_SYS_ROLES_SUCCESS,
    GET_SYS_ROLES_FAIL,

    GET_SYS_ROLE,
    GET_SYS_ROLE_SUCCESS,
    GET_SYS_ROLE_FAIL,

    POST_SYS_ROLES,
    POST_SYS_ROLES_SUCCESS,
    POST_SYS_ROLES_FAIL,

    DELETE_SYS_ROLES,
    DELETE_SYS_ROLES_SUCCESS,
    DELETE_SYS_ROLES_FAIL,

    UPDATE_SYS_ROLES,
    UPDATE_SYS_ROLES_SUCCESS,
    UPDATE_SYS_ROLES_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    appSysRoles: null,
    appSysRole: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const AppSysRoles = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SYS_ROLES:
            return {
                ...state,
                loading: true,
                appSysRoles: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                appSysRoles: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_SYS_ROLES:
            return {
                ...state,
                loading: true,
                appSysRoles: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_SYS_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                appSysRole: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_SYS_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_SYS_ROLES:
            return {
                ...state,
                loading: true,
                appSysRoles: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_SYS_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                appSysRole: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_SYS_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_ROLE:
            return {
                ...state,
                loading: true,
                appSysRoles: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                appSysRole: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_SYS_ROLE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_SYS_ROLES:
            return {
                ...state,
                loading: true,
                appSysRoles: null,
                appSysRole: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_SYS_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                appSysRole: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_SYS_ROLES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default AppSysRoles;
