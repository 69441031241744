import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'reactstrap'

const SiteID = (cell) => {
    return cell.value ? cell.value : '';
};

const SiteName = (cell) => {
    return cell.value ? cell.value : '';
};

const BusinessUnits = (cell) => {
    return cell.value ? cell.value : '';
};

const Business = (cell) => {
    return cell.value ? cell.value : '';
};


const SiteDescription = (cell) => {
    return cell.value ? cell.value : '';
};

const SiteStatus = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};

export {
    SiteID,
    SiteName,
    SiteDescription,
    SiteStatus,
    BusinessUnits,
    Business
};