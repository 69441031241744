import {
    GET_ADDRESSES,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_FAIL,

    GET_ADDRESS,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAIL,

    GET_ALL_ADDRESS,
    GET_ALL_ADDRESS_SUCCESS,
    GET_ALL_ADDRESS_FAIL,

    POST_ADDRESS,
    POST_ADDRESS_SUCCESS,
    POST_ADDRESS_FAIL,

    DELETE_ADDRESS,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_FAIL,

    UPDATE_ADDRESS,
    UPDATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    addresses: null,
    address: null,
    singleAddress: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const addressReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ADDRESSES:
            return {
                ...state,
                loading: true,
                addresses: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ADDRESSES_SUCCESS:
            return {
                ...state,
                loading: false,
                addresses: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ADDRESSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_ADDRESS:
            return {
                ...state,
                loading: true,
                addresses: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                address: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_ADDRESS:
            return {
                ...state,
                loading: true,
                addresses: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                address: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ADDRESS:
            return {
                ...state,
                loading: true,
                singleAddress: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                singleAddress: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_ADDRESS:
            return {
                ...state,
                loading: true,
                addresses: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                addresses: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_ALL_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_ADDRESS:
            return {
                ...state,
                loading: true,
                address: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_ADDRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                address: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_ADDRESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default addressReducer;
