export const GET_SYS_USERS = "GET_SYS_USERS"
export const GET_SYS_USERS_SUCCESS = "GET_SYS_USERS_SUCCESS"
export const GET_SYS_USERS_FAIL = "GET_SYS_USERS_FAIL"

export const POST_SYS_USERS = "POST_SYS_USERS"
export const POST_SYS_USERS_SUCCESS = "POST_SYS_USERS_SUCCESS"
export const POST_SYS_USERS_FAIL = "POST_SYS_USERS_FAIL"

export const DELETE_SYS_USERS = "DELETE_SYS_USERS"
export const DELETE_SYS_USERS_SUCCESS = "DELETE_SYS_USERS_SUCCESS"
export const DELETE_SYS_USERS_FAIL = "DELETE_SYS_USERS_FAIL"

export const GET_SYS_USER = "GET_SYS_USER"
export const GET_SYS_USER_SUCCESS = "GET_SYS_USER_SUCCESS"
export const GET_SYS_USER_FAIL = "GET_SYS_USER_FAIL"

export const UPDATE_SYS_USERS = "UPDATE_SYS_USERS"
export const UPDATE_SYS_USERS_SUCCESS = "UPDATE_SYS_USERS_SUCCESS"
export const UPDATE_SYS_USERS_FAIL = "UPDATE_SYS_USERS_FAIL"