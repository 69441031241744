import {
    GET_APP_CREDENTIALS,
    GET_APP_CREDENTIALS_SUCCESS,
    GET_APP_CREDENTIALS_FAIL,

    POST_APP_CREDENTIALS,
    POST_APP_CREDENTIALS_SUCCESS,
    POST_APP_CREDENTIALS_FAIL

} from "./actionTypes"

const INIT_STATE = {
    appCredentials: null,
    appCredentials: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
}

const appCredentialsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APP_CREDENTIALS:
            return {
                ...state,
                loading: true,
                appCrededentials: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                appCrededentials: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APP_CREDENTIALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_CREDENTIALS:
            return {
                ...state,
                loading: true,
                appCrededentials: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                appCrededential: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APP_CREDENTIALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
}

export default appCredentialsReducer

