import axios from 'axios'
import getHeader from 'config/header'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, Row, Table } from "reactstrap"
import { getBusinessProducts } from 'store/actions'

const CreateTierModal = (props) => {

    const { show, onCloseClick, onsubmitClick, onDataSelect, discountType,
        qualifyingType, singleRowUpdatetierValue, handleUpdateTier, maxTierInput, tierInputRange, ReadOnly } = props
    const { handleSubmit, register, getValues, unregister, setValue, formState: { errors }, reset } = useForm()

    const getRndInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const [updateBtn, setUpdatebtn] = useState()

    useEffect(() => {
        if (singleRowUpdatetierValue?.localID) {
            setValue("app_tier_name", singleRowUpdatetierValue.app_tier_name)
            setValue("app_tier_qualifying_input", singleRowUpdatetierValue.app_tier_qualifying_input)
            setValue("app_tier_discount_Type", singleRowUpdatetierValue.app_tier_discount_Type)
            setValue("app_tier_qualifying_type", singleRowUpdatetierValue.app_tier_qualifying_type)
            setValue("app_tier_discount_value", singleRowUpdatetierValue.app_tier_discount_value)
            setUpdatebtn(true)
        } else {
            setUpdatebtn(false)
        }
    }, [singleRowUpdatetierValue])

    const [maxError, setMaxError] = useState(false)
    const [rangeError, setRangeError] = useState(false)

    const selectedBusinessUnits = (data) => {
        data = {
            ...data,
            is_active: true,
            localID: getRndInteger(1, 9999)
        }
        if (parseInt(data.app_tier_qualifying_input) < maxTierInput) {
            setMaxError(true)
        } else {
            setMaxError(false)
            onDataSelect(data);
            reset()
        }
    };

    const onUpdate = () => {

        const data = {
            app_tier_name: getValues("app_tier_name"),
            app_tier_qualifying_input: getValues("app_tier_qualifying_input"),
            app_tier_discount_Type: getValues("app_tier_discount_Type"),
            app_tier_discount_value: getValues("app_tier_discount_value"),
            localID: singleRowUpdatetierValue?.localID
        }
        const tierInput = parseInt(data.app_tier_qualifying_input)
        if ((tierInput >= tierInputRange.min) && (tierInput <= tierInputRange.max)) {
            setRangeError(false)
            handleUpdateTier(data)
            reset()
        } else {
            setRangeError(true)
        }


    }

    return (
        <Modal backdrop="static" isOpen={show} centered={true} size='lg'>
            <Form onSubmit={handleSubmit(selectedBusinessUnits)}>
                <ModalBody className="py-5 px-3 pe-3" style={{ maxHeight: '500px', overflow: 'auto' }}>
                    <div className="mt-2 mb-2">
                        {/* <h3 className='d-flex justify-content-center mb-2'>Add Product</h3> */}

                        {/* <Row>
                            <FormGroup row>
                                <Label lg="4" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Tier No<span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                <Col lg="8" md="6">
                                    <input type="number" className='form form-control' {...register('app_tier_no', { required: true })} />
                                    {errors.app_tier_no && <p style={{ color: "red" }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row> */}
                        <Row>
                            <FormGroup row>
                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Tier Name <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                <Col lg="8" md="6">
                                    <input type="text" className='form form-control' {...register('app_tier_name', { required: true })} />
                                    {errors.app_tier_name && <p style={{ color: "red" }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup row>
                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Qualifying Type <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                <Col lg="8" md="6">
                                    <input type="text"  value={qualifyingType} readOnly style={{ backgroundColor: "#D2D4D1" }} className='form form-control' {...register('app_tier_qualifying_type', { required: true })} />
                                    {errors.app_tier_qualifying_type && <p style={{ color: "red" }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup row>
                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Qualifying Input <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                <Col lg="8" md="6">
                                    <input type="number" readOnly={ReadOnly} style={ReadOnly ? { backgroundColor: "#D2D4D1" } : {}} className='form form-control' {...register('app_tier_qualifying_input', { required: true })} />
                                    {errors.app_tier_qualifying_input && <p style={{ color: "red" }}>This Field is required</p>}
                                    {maxError && <p style={{ color: "red" }}>Minimum number is {maxTierInput}</p>}
                                    {rangeError && <p style={{ color: "red" }}>Allowed value range is from {tierInputRange.min} to {tierInputRange.max}</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup row>
                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Discount Type <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                <Col lg="8" md="6">
                                    <input type="text" value={discountType} readOnly style={{ backgroundColor: "#D2D4D1" }} className='form form-control' {...register('app_tier_discount_Type', { required: true })} />
                                    {errors.app_tier_discount_Type && <p style={{ color: "red" }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup row>
                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Discount Value <span style={{ color: "red" }}>*</span>&nbsp; :</Label>
                                <Col lg="8" md="6">
                                    <input type="number" className='form form-control' {...register('app_tier_discount_value', { required: true })} />
                                    {errors.app_tier_discount_value && <p style={{ color: "red" }}>This Field is required</p>}
                                </Col>
                            </FormGroup>
                        </Row>
                        <Row>
                            <Label lg="3" md="3" />
                            <Col lg="7" md="7">
                                <div className="text-center mt-3">
                                    {updateBtn ? <button
                                        className="btn btn-success btn-md ms-2"
                                        onClick={onUpdate}
                                        type="button"
                                    >
                                        Update Tier
                                    </button>
                                        :
                                        <button
                                            className="btn btn-success btn-md ms-2"
                                            type="submit"
                                        >
                                            Add Tier
                                        </button>}
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-md ms-2"
                                        onClick={() => { reset(); onCloseClick() }}
                                    >
                                        close
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Form>
        </Modal>
    )
}




export default CreateTierModal

