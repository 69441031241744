import { takeEvery, put, call } from "redux-saga/effects"
import {
    getPricingmodelsFail,
    getPricingmodelsSuccess,
    getPricingmodelSuccess,
    getPricingmodelFail,
    postPricingmodelSuccess,
    postPricingmodelFail,
    updatePricingmodelSuccess,
    updatePricingmodelFail,
    deletePricingmodelFail,
    deletePricingmodelSuccess,
} from "./actions"

import {
  getPricingModels,
  getPricingModel,
  postPricingModel,
  updatePricingModel,
  deletePricingModel
 
}
  from "helpers/backendApi_helpers";

import {
  GET_PRICING_MODELS,
  GET_PRICING_MODEL,
  POST_PRICING_MODEL,
  UPDATE_PRICING_MODEL,
  DELETE_PRICING_MODEL,
} from "./actionTypes";

function* fetchPricingmodels({ payload: payload }) {
  try {
    const response = yield call(getPricingModels, payload);
    yield put(getPricingmodelsSuccess(response))
  } catch (error) {
    yield put(getPricingmodelsFail(error))
  }
}


function* getPricingmodel({ payload: payload }) {
    try {
      const response = yield call(getPricingModel, payload);
      yield put(getPricingmodelSuccess(response))
    } catch (error) {
      yield put(getPricingmodelFail(error))
    }
  }
  

function* postPricingmodel({ payload: payload }) {
  try {
    const response = yield call(postPricingModel, payload);
    yield put(postPricingmodelSuccess(response))
  } catch (error) {
    yield put(postPricingmodelFail(error))
  }
}


function* updatePricingmodel({ payload: payload }) {
  try {
    const response = yield call(updatePricingModel, payload);
    yield put(updatePricingmodelSuccess(response))
  } catch (error) {
    yield put(updatePricingmodelFail(error))
  }
}

function* deletePricingmodel({ payload: payload }) {
    try {
      const response = yield call(deletePricingModel, payload);
      yield put(deletePricingmodelSuccess(response))
    } catch (error) {
      yield put(deletePricingmodelFail(error))
    }
  }

function* PricingModelSaga() {
  yield takeEvery( GET_PRICING_MODELS, fetchPricingmodels)
  yield takeEvery( GET_PRICING_MODEL, getPricingmodel)
  yield takeEvery( POST_PRICING_MODEL, postPricingmodel)
  yield takeEvery( UPDATE_PRICING_MODEL, updatePricingmodel)
  yield takeEvery( DELETE_PRICING_MODEL, deletePricingmodel)

}

export default PricingModelSaga;





