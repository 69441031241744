import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { getSysUsers, getAppSysUser } from 'store/administration/portalUser/actions'

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  FormGroup
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
// import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/maxims/user.png"
// actions
import { editProfile, getAppSysRole, getSysUsersProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = () => {

  //meta title
  document.title = "Profile | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const history = useHistory()

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState("");
  const [roleName, setRoleName] = useState('')

  // const sysuserState = useSelector(state => state.sysUsersReducer);

  // useEffect(() => {
  //   const sysUserID = "syusr_cjm34350gtk8scd59t60"
  //   // dispatch(getSysUsers({filter: `{"is_active":true}`,limit:0,skip:0}));
  //   dispatch(getAppSysUser({ sysUserID }))
  // }, [])



  // useEffect(() => {
  //   if (sysuserState.sysUser !== null) {
  //     console.log("name", sysuserState.sysUser);
  //     setname(sysuserState.sysUser.sys_user_name);
  //     setemail(sysuserState.sysUser.sys_user_email);
  //     setidx(sysuserState.sysUser.sys_user_keyid);
  //   }
  // }, [sysuserState])



  //////////







  const sysUserProfilState = useSelector(state => state.Dashboard)
  useEffect(() => {
    dispatch(getSysUsersProfile())
  }, [])

  const sysUserState = useSelector(state => state.sysUsersReducer)

  useEffect(() => {
    if (sysUserProfilState.sysUserProfile !== null) {
      let userID = sysUserProfilState.sysUserProfile.sys_user_id
      if (userID) {
        const data = { sysUserID: userID }
        dispatch(getAppSysUser(data))
      }
    }
  }, [sysUserProfilState])

  const sysRoleState = useSelector(state => state.AppSysRoles);

  useEffect(() => {
    if (sysUserState.sysUser !== null) {
      setname(sysUserState.sysUser.sys_user_name)
      setemail(sysUserState.sysUser.sys_user_email);
      setidx(sysUserState.sysUser.sys_user_keyid);
      let roleID = sysUserState.sysUser.sys_user_roles
      roleID.map((id) => {
        const data = { roleID: id }
        dispatch(getAppSysRole(data))
      })
    }
  }, [sysUserState])

  useEffect(() => {
    // console.log("sysRoleState.appSysRole", sysRoleState.appSysRole);
    if (sysRoleState.appSysRole !== null) {
      let value = []
      value.push(sysRoleState.appSysRole.sys_role_name)
      let Data = value.map((item) => item).join(", ")
      setRoleName(Data)
    }
  }, [sysRoleState])

  // const { error, success } = useSelector(state => ({
  //   error: state.Profile.error,
  //   success: state.Profile.success,
  // }));

  // useEffect(() => {
  //   if (sessionStorage.getItem("authUser")) {
  //     const obj = sessionStorage.getItem("authUser")
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       setname(obj.displayName);
  //       console.log("username",obj.displayName);
  //       setemail(obj.email);
  //       setidx(obj.uid);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       setname(obj.username);
  //       setemail(obj.email);
  //       setidx(obj.uid);
  //     }
  //     setTimeout(() => {
  //       dispatch(resetProfileFlag());
  //     }, 3000);
  //   }
  // }, [dispatch,success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || '',
      email: email || '',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    // onSubmit: (values) => {
    //   dispatch(editProfile(values));
    // }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb /}
          {/ <Breadcrumb title="Skote" breadcrumbItem="Profile" /> */}

          <Row>
            <Col lg="12">
              {/* {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null} */}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">Email: {email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="mb-4">Profile</h4>
          {/* 
          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    // onChange={validation.handleChange}
                    // onBlur={validation.handleBlur}
                    // value={validation.values.username || ""}
                    defaultValue="admin"
                    // invalid={
                    //   validation.touched.username && validation.errors.username ? true : false
                    // }
                  />    
                    {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                  ) : null}
                  <Input name="idx" value={idx} type="hidden" /> 
                </div>
                <div className="text-center mt-4">
                  <Button type="button" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card> */}

          <Row>
            <Col lg="12">
              <Card body>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                  <Row className="mt-5">
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User Name<span style={{ color: "red" }}>*</span>  :</Label>
                      <Col lg="5" md="6">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="User Name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                        // defaultValue="admin"
                        // invalid={
                        //   validation.touched.username && validation.errors.username ? true : false
                        // }
                        />
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row >
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Email<span style={{ color: "red" }}>*</span>  :</Label>
                      <Col lg="5" md="6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                        // defaultValue="admin"
                        // invalid={
                        //   validation.touched.email && validation.errors.email ? true : false
                        // }
                        />
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row >
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Phone Number<span style={{ color: "red" }}>*</span>  :</Label>
                      <Col lg="5" md="6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Phone Number"
                        // onChange={validation.handleChange}
                        // onBlur={validation.handleBlur}
                        // value={validation.values.phonenumber || ""}
                        // defaultValue="admin"
                        // invalid={
                        //    validation.touched.phonenumber && validation.errors.phonenumber ? true : false
                        // }
                        />
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row >
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Address<span style={{ color: "red" }}>*</span>  :</Label>
                      <Col lg="5" md="6">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Address"
                        // onChange={validation.handleChange}
                        // onBlur={validation.handleBlur}
                        // value={validation.values.address || ""}
                        // defaultValue="admin"
                        // invalid={
                        //    validation.touched.address && validation.errors.address ? true : false
                        // }
                        />
                      </Col>
                    </FormGroup>
                  </Row>
                  {/* <Row >
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status  <span style={{ color: "red" }}>*</span>  :</Label>
                      <Col lg="5" md="6">
                        <div className="form-check form-switch form-switch-lg mt-1 mb-3">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" htmlFor="roleStatus">
                            profile
                          </label>
                        </div>
                      </Col>
                    </FormGroup>
                  </Row> */}

                  <Row className="mt-4">
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                      <Col lg="8" md="8">
                        <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                          Back
                        </Button>
                        {/* <Button  id="createRoleBtn" color='success' type='submit' className='m-2'>
                          Update
                        </Button> */}
                      </Col>
                    </FormGroup>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
