import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';

const ProductName = (cell) => {
    return cell.value ? cell.value : '';
};

const ProductID = (cell) => {
    return cell.value ? cell.value : '';
};

const POSItemCode = (cell) => {
    return cell.value ? cell.value : '';
};

const SellingPrice = (cell) => {
    let floatValue = parseFloat(cell.value);
    if (isNaN(floatValue)) return null;
    floatValue = floatValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (
        <span className='ListPageFloatRight'> {"$" + " " + floatValue} </span>
    );
    // const floatValue = parseFloat(cell.value).toFixed(1);
    // return (<span className='ListPageFloatRight'> {floatValue ? " $ " + floatValue : ''} </span>);
};

const ProductType = (cell) => {
    // return cell.value === "D" ? "Digital " : "Print"

    return cell.value ? cell.value : '';
};

const Tags = (cell) => {
    const [tag, setTag] = useState([])
    useEffect(() => {
        let name = ""
        for (let i in cell.value) {
            name += " " + cell.value[i] + " ,"
        }
        setTag(name.slice(0, -1));
    }, [])

    return tag ? tag : '';
};


const UnitPrice = (cell) => {
    return cell.value ? "HKD $" + cell.value : '';
};

const ProductStatus = (cell) => {
    return (<Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        {cell.value ? "ACTIVE" : "INACTIVE"}
    </Badge>)
};


export {
    ProductName,
    ProductID,
    ProductStatus,
    POSItemCode,
    UnitPrice,
    Tags, SellingPrice,
    ProductType
};