
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Table, Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"

import classnames from 'classnames';
import axios from 'axios';
import getHeader from 'config/header';
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill';
import { formats, modules } from '../../../common/data/rich-text-options';
import 'react-quill/dist/quill.snow.css';
import { getApplicationTemplateDetail, updateApplicationTemplate } from 'store/actions'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'


function UpdateApplicationTemplate() {

    const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()

    const dispatch = useDispatch();
    const history = useHistory()
    const { id } = useParams()


    const [templateInformation, setTemplateInformation] = useState("")
    const [termsandCondition, setTermsandCondition] = useState("")
    const [paymentMethod, setPaymentMethod] = useState("")


    useEffect(() => {
        if (id) {
            const data = { templateId: id };
            dispatch(getApplicationTemplateDetail(data))
        }
    }, [id])


    const templateState = useSelector(state => state.ApplicationTemplateReducer)

    useEffect(() => {

        if (templateState.applicationTemplateDetail !== null) {
            setValue("template_name", templateState.applicationTemplateDetail.template_name)
            setValue("template_type", templateState.applicationTemplateDetail.template_type)
            // setValue("template_information", templateState.applicationTemplateDetail.template_information)
            // setValue("terms_and_condition", templateState.applicationTemplateDetail.terms_and_condition)
            // setValue("payment_method", templateState.applicationTemplateDetail.payment_method)
            setTemplateInformation(templateState.applicationTemplateDetail.template_information)
            setTermsandCondition(templateState.applicationTemplateDetail.terms_and_condition)
            setPaymentMethod(templateState.applicationTemplateDetail.payment_method)
        }

        if (templateState.isUpdated) {
            history.goBack()
        }

    }, [templateState])



    const updateTemplate = (data) => {

        const newData = {
            ...data,
            template_information: templateInformation,
            terms_and_condition: termsandCondition,
            payment_method: paymentMethod
        }

        const updateData = {
            templateId: id,
            updatedData: newData
        }

        dispatch(updateApplicationTemplate(updateData));

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Application Template" breadcrumbItem="Settings" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(updateTemplate)}>

                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Template Type</Label>
                                            <Col lg="7" md="6">
                                                <select className="form-select d-inline" {...register('template_type')}>
                                                    <option hidden value=" ">select</option>
                                                    <option value="Quotation">Quotation</option>
                                                    <option value="Invoice">Invoice</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Template Name<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <input type="text" className='form form-control' {...register('template_name', { required: true })} />
                                                {errors.template_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    {/* <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Template Information<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <input type="text" className='form form-control' {...register('template_information', { required: true })} />
                                                {errors.template_information && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Terms and Condition<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <input type="text" className='form form-control' {...register('terms_and_condition', { required: true })} />
                                                {errors.terms_and_condition && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>   
                                      <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Payment Methods<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <input type="text" className='form form-control' {...register('payment_method', { required: true })} />
                                                {errors.payment_method && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row> */}


                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Template Information <span style={{ color: "red" }}>* </span></Label>
                                            <Col lg="7" md="6">
                                                <ReactQuill value={templateInformation} formats={formats} theme="snow" onChange={setTemplateInformation} />
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <Row>
                                        <FormGroup row>

                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Terms and Condition <span style={{ color: "red" }}>* </span></Label>
                                            <Col lg="7" md="6">
                                                <ReactQuill value={termsandCondition}  formats={formats} theme="snow" onChange={setTermsandCondition} />
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <Row>

                                        <FormGroup row>

                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Payment Methods <span style={{ color: "red" }}>* </span></Label>
                                            <Col lg="7" md="6">
                                                <ReactQuill value={paymentMethod} formats={formats} theme="snow" onChange={setPaymentMethod} />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => { history.goBack(); templateState.applicationTemplateDetail =  null }}>
                                                    Back
                                                </Button>
                                                <Button id="updateTemplate" color='success' type='submit' className='m-2'>
                                                    Update
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UpdateApplicationTemplate
