import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip, Label } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { Business, SiteName, SiteID, BusinessUnits, SiteStatus } from '../../../Sales/CustomerSites/CustomerSitesListCol';
import { getAllDepartment, getDepartments, getOpportunitieses, updateOpportunities, } from "store/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TableContainer from "components/Common/TableContainer"
import ApproveCreateCompanySiteModal from "components/Common/ApprovalModelComponent/ApproveCreateCompanySiteModal";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

const CreateCompanySiteApprovalList = props => {
    document.title = "Company Site | Maxims B2B Portal";
    const history = useHistory()
    const dispatch = useDispatch();

    const query = useQuery();
    const page = query.get("skip")
    const pageLimit = query.get("limit")
    const [pageCount, setPageCount] = useState(1);
    const Busid = query.get("Busid")
    const SiteId = query.get("SiteId")
    const pageStatus = query.get("status")


    //============global search=====================================//

    const departmentState = useSelector(state => state.departmentReducer)
    const [sites, setSites] = useState([])

    const [customFilterData, setCustomFilterData] = useState("")
    useEffect(() => {
        handlePageClick()
    }, [pageLimit, page, Busid, SiteId, pageStatus, customFilterData])

    useEffect(() => {
        if (customFilterData.length > 0) {
            history.push(`/approvals/companySites?tab=${1}&&skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)

        }
    }, [customFilterData])

    const handlePageClick = (index) => {
        let filter = `{"is_active":${pageStatus},"$or":[
           {"app_business_name":{"$regex":"${customFilterData}","$options":"i"}},
           {"app_department_name":{"$regex":"${customFilterData}","$options":"i"}},
           {"app_department_keyid":{"$regex":"${customFilterData}","$options":"i"}},
           {"app_business_unit_name":{"$regex":"${customFilterData}","$options":"i"}}
        ]}`
        let skips = 0
        if (page > 0) {
            skips = page * pageLimit;
            dispatch(getAllDepartment({ filter: filter, limit: pageLimit, skip: skips }));
        }
        else {
            dispatch(getAllDepartment({ filter: filter, limit: pageLimit, skip: 0 }));
        }

    }

    useEffect(() => {
        if (departmentState.departments !== null) {
            setSites(departmentState.departments.result)
            setPageCount(Math.ceil(departmentState.departments.summary.filteredsize / pageLimit) == 0 ? 1
                :
                Math.ceil(departmentState.departments.summary.filteredsize / pageLimit)
            );
        }
        if (departmentState.error) {
            // console.log("err", creditsState.error);
        }
    }, [departmentState])

    // Company Column
    const columns = useMemo(
        () => [
            {
                Header: 'Company',
                accessor: 'app_business_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <Business {...cellProps} />
                }
            },
            {
                Header: 'Site Name',
                accessor: 'app_department_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteName {...cellProps} />
                }
            },
            {
                Header: 'Site ID',
                accessor: 'app_department_keyid',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteID {...cellProps} />
                }
            },
            {
                Header: 'Business Unit',
                accessor: 'app_business_unit_name',
                filterable: true,
                Cell: (cellProps) => {
                    return <BusinessUnits {...cellProps} />
                }
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                filterable: true,
                Cell: (cellProps) => {
                    return <SiteStatus {...cellProps} />
                }
            },
            // {
            //     Header: 'Action',
            //     Cell: (cellProps) => {
            //         const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
            //         return (
            //             <div className="d-flex gap-3">
            //                 {<href
            //                     className="text-info"
            //                     onClick={() => {
            //                         const departmentData = cellProps.row.original;
            //                         console.log("companyData",departmentData);
            //                         onClickApprove(departmentData);
            //                     }}
            //                 >
            //                     <i className='mdi mdi-checkbox-marked-outline font-size-18' id={"approveTooltip" + cellProps.row.original.app_department_id}></i>
            //                     <UncontrolledTooltip placement="top" target={"approveTooltip" + cellProps.row.original.app_department_id}>
            //                         Approve
            //                     </UncontrolledTooltip>
            //                 </href>}
            //             </div>
            //         );
            //     }
            // },

            {
                Header: 'Actions',
                Cell: (cellProps) => {
                    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
                    return (
                        <div className="d-flex gap-3">
                            {((permissionAction.includes("system.settings.approval.read")) &&
                                (permissionAction.includes("system.settings.approval.update"))) ?
                                <>
                                    <button
                                        onClick={() => {
                                            const departmentData = cellProps.row.original;
                                            onClickApprove(departmentData);
                                        }}
                                        className="btn btn-success btn-sm">View</button>
                                </> : <div className="text-muted">No Access</div>
                            }
                        </div>
                    );
                }
            },
        ], [sites]
    );

    const [approveCompanySiteModal, setApproveCompanySiteModal] = useState(false);
    const [departmentData, setDepartmentData] = useState('')

    const onClickApprove = (departmentData) => {
        setDepartmentData(departmentData);
        setApproveCompanySiteModal(true);
    };

    useEffect(() => {
        if (departmentState.isUpdated) {
            setApproveCompanySiteModal(false);
            handlePageClick()
        }
    }, [departmentState])

    const handleApproveCompanySite = () => {
        const companySiteValue = {
            is_active: true,
            is_approve: true,
            approval_status: "Approved"
        }
        if (departmentData.app_department_id) {
            const updateData = {
                app_department_id: departmentData.app_department_id,
                updateData: companySiteValue
            }
            console.log("DDDDDDDDDDDD", updateData);
            // dispatch(updateOpportunities(updateData))
        }
    };

    const handleRejectCompanySite = () => {
        screenTop
        const companySiteValue = {
            is_active: false,
            is_approve: false,
            approval_status: "Rejected"
        }
        if (departmentData.app_department_id) {
            const updateData = {
                app_department_id: departmentData.app_department_id,
                updateData: companySiteValue
            }
            console.log(updateData);
            //   dispatch(updateOpportunities(updateData))
        }
    };


    return (
        <React.Fragment>
            <ApproveCreateCompanySiteModal
                show={approveCompanySiteModal}
                onApproveClick={handleApproveCompanySite}
                onRejectClick={handleRejectCompanySite}
                onCloseClick={() => setApproveCompanySiteModal(false)}
                data={departmentData}
                title="Company Site"
            />

            <Row>
                {/* <Label><u>Company Site</u></Label> */}
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns}
                                data={sites}
                                isGlobalFilter={true}
                                customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                                customPageSize={10}
                                className="custom-header-css"
                                handlePageClick={handlePageClick}
                                customPageCount={pageCount}
                                isCompanyDropdown={false}
                                isSiteDropdown={false}

                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default CreateCompanySiteApprovalList;


