import { takeEvery, put, call } from "redux-saga/effects"
import {
    getBusinessesSuccess, getBusinessesFail,
    getBusinessSuccess, getBusinessFail,
    postBusinessSuccess, postBusinessFail,
    deleteBusinessSuccess, deleteBusinessFail,
    updateBusinessSuccess, updateBusinessFail,
} from "./actions"

// Api Handler 
import {
    getBusinesses,
    getBusiness,
    postBusiness,
    updateBusiness,
    deleteBusiness,

} from "helpers/backendApi_helpers";


import {
    GET_BUSINESSES,
    GET_BUSINESS,
    POST_BUSINESS,
    DELETE_BUSINESS,
    UPDATE_BUSINESS
} from "./actionTypes";




function* fetchAppBusinesses({ payload: payload }) {
    try {
        const response = yield call(getBusinesses, payload);
        yield put(getBusinessesSuccess(response))
    } catch (error) {
        yield put(getBusinessesFail(error))
    }
}

function* postAppBusiness({ payload: payload }) {
    try {
        const response = yield call(postBusiness, payload);
        yield put(postBusinessSuccess(response))
    } catch (error) {
        yield put(postBusinessFail(error))
    }
}

function* deleteAppBusiness({ payload: payload }) {
    try {
        const response = yield call(deleteBusiness, payload);
        yield put(deleteBusinessSuccess(response))
    } catch (error) {
        yield put(deleteBusinessFail(error))
    }
}

function* getAppBusiness({ payload: payload }) {
    try {
        const response = yield call(getBusiness, payload);
        yield put(getBusinessSuccess(response))
    } catch (error) {
        yield put(getBusinessFail(error))
    }
}

function* updateAppBusiness({ payload: payload }) {
    try {
        const response = yield call(updateBusiness, payload);
        yield put(updateBusinessSuccess(response))
    } catch (error) {
        yield put(updateBusinessFail(error))
    }
}

function* BusinessSaga() {
    yield takeEvery(GET_BUSINESSES, fetchAppBusinesses)
    yield takeEvery(POST_BUSINESS, postAppBusiness)
    yield takeEvery(DELETE_BUSINESS, deleteAppBusiness)
    yield takeEvery(GET_BUSINESS, getAppBusiness)
    yield takeEvery(UPDATE_BUSINESS, updateAppBusiness)
}

export default BusinessSaga

