import {
    POST_OPPORTUNITY_CLONE_VERSION,
    POST_OPPORTUNITY_CLONE_VERSION_SUCCESS,
    POST_OPPORTUNITY_CLONE_VERSION_FAIL
  } from "./actionTypes";
     
     const INIT_STATE = {
       opportunitycloneversion: null,
       loading: false,
       isCreated: false,
       isDeleted: false,
       isUpdated: false,
       isGoted:false,
       error: {},
     };
   
     const OpportunityCloneVersionReducer = (state = INIT_STATE, action) => {
       switch (action.type) {
           case POST_OPPORTUNITY_CLONE_VERSION:
             return {
               ...state,
               loading: true,
               opportunitycloneversion: null,
               isCreated: false,
               isDeleted: false,
               isUpdated: false,
               isGoted: false
             };
           case POST_OPPORTUNITY_CLONE_VERSION_SUCCESS:
             return {
               ...state,
               loading: false,
               opportunitycloneversion: action.payload,
               isCreated: true,
               isDeleted: false,
               isUpdated: false,
               isGoted: true
             };
           case POST_OPPORTUNITY_CLONE_VERSION_FAIL:
             return {
               ...state,
               loading: false,
               error: action.payload,
               isCreated: false,
               isDeleted: false,
               isUpdated: false,
               isGoted: false
             };
         default:
           return state;
       }
     };
     
     export default OpportunityCloneVersionReducer;
     
     
     