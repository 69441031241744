import React, { useState, useEffect, useMemo } from "react"
import { useLocation } from 'react-router-dom'
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap'
import axios from "axios";
import getHeader from "config/header";
import { useContext } from "react";
import creditData from "pages/Finance/Credits/Credit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as url from "../../../helpers/url_helper";
import TableContainer from "../TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessProducts, getPricingmodels, getSysUserProduct } from "store/actions";
import { useForm } from "react-hook-form";
import Select from 'react-select'
import { ProductID, ProductName, PosItemCode, ProductType, OriginalPrice, UnitPrice, StandardQuantity, RowTotal } from "./CustomOpportunityListCol"
import { toast } from "react-toastify";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

function standardComponent({ show, onUpdatedData, onCloseClick, onData }) {

  const query = useQuery();
  const skip = query.get("skip")
  const limit = query.get("limit")
  const Busid = query.get("Busid")
  const SiteId = query.get("SiteId")
  const pageStatus = query.get("status")

  const history = useHistory()
  const location = useLocation();
  const dispatch = useDispatch()
  const pathname = location.pathname

  const { setValue: setValue2, register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, getValues: getValues2 } = useForm()

  const [standardList, setStandardList] = useState([])


  const handleQty = (id, unit_price, qty) => {

    if (qty >= 0) {
      let clonedData = standardList.map(item => ({ ...item }))
      let index = clonedData.findIndex(item => item.app_product_id === id)
      if (index < 0) return
      // let rowTotal = (parseInt(qty) * parseInt(unit_price))

      let rowTotal = (qty * unit_price)
      clonedData[index] = {
        ...clonedData[index],
        quantity: qty,
        rowTotal: rowTotal.toString(),
        discounted_unit_price: unit_price,
        discount: " 0 ",
      }
      setStandardList(clonedData)
    } else {
      let clonedData = standardList.map(item => ({ ...item }))
      let index = clonedData.findIndex(item => item.app_product_id === id)
      clonedData[index] = {
        ...clonedData[index],
        quantity: "0",
        rowTotal: "0",
        discounted_unit_price: "0",
        discount: " 0 ",
      }

      // console.log("new", clonedData);
      setStandardList(clonedData)
    }


  }

  //  Standard List 
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: (row, index) => index + 1,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Product ID',
        accessor: 'app_product_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductID datas={cellProps.row.original} {...cellProps} />;
        }
      },
      {
        Header: 'Product Name',
        accessor: 'app_product_local_name',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'POS Item Code',
        accessor: 'app_product_pos_item_code',
        filterable: true,
        Cell: (cellProps) => {
          return <PosItemCode  {...cellProps} />;
        }
      },

      {
        Header: 'Product Type',
        accessor: 'product_type',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductType  {...cellProps} />;
        }
      },
      {
        Header: 'Original price',
        accessor: 'selling_price',
        filterable: true,
        Cell: (cellProps) => {
          return <OriginalPrice  {...cellProps} />;
        }
      },
      {
        Header: 'Unit Price',
        accessor: 'unit_price',
        filterable: true,
        Cell: (cellProps) => {
          return <UnitPrice {...cellProps} />;
        }
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        filterable: true,
        Cell: (cellProps) => {
          return <StandardQuantity qty={cellProps.row.original.quantity} {...cellProps} handleQty={handleQty} />;
        }
      },
      {
        Header: ' Row Total ',
        accessor: 'rowTotal',
        filterable: true,
        Cell: (cellProps) => {
          return <RowTotal {...cellProps} />;
        }
      }
    ], [standardList]
  );

  const [selectedMultiProduct, setselectedMultiProduct] = useState([]);
  const [optionGroupProduct, setOptionGroupProduct] = useState([{ options: [] }])
  const [productList, setProductList] = useState([])

  register2('app_business_unit_product');

  const sysUserProductState = useSelector(state => state.AppsysUserProductReducer)

  useEffect(() => {
    dispatch(getSysUserProduct())
  }, [])


  useEffect(() => {
    if (sysUserProductState.sysUserProducts !== null) {
      setProductList(sysUserProductState.sysUserProducts.result);
    }
  }, [sysUserProductState])

  useEffect(() => {
    if (productList) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in productList) {
        let data = {
          label: productList[i].app_product_pos_item_code + " | " + productList[i].app_product_payment_code + " | " + productList[i].app_product_name,
          value: productList[i].app_product_id
        }
        options.push(data)
      }
      setOptionGroupProduct(options)
    }
  }, [productList])

  useEffect(() => {
    let prod = onData.map(item => item.app_product_id);
    if (onData.length > 0) {
      appBuProduct(prod)
    }
  }, [onData, productList])

  const appBuProduct = (Items) => {
    if (Items.length > 0 && productList) {
      let Data = Items.map((item) => {
        let productlist = productList.find(productlist => productlist.app_product_id === item)
        if (productlist) {
          return {
            label: productlist.app_product_pos_item_code + " | " + productlist.app_product_payment_code + " | " + productlist.app_product_name,
            value: productlist.app_product_id
          }
        }
      }).filter(Boolean)
      // Data.unshift({ label: "Select All", value: "select_all" })
      // setOptionGroupProduct(Data)

      setselectedMultiProduct(Data)
      setValue2("app_business_unit_product", Data)
    }
  }

  const handleChangeProduct = (Data) => {
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values
    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroupProduct.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            setValue2('app_business_unit_product', postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue2('app_business_unit_product', postArray)
        }
      }
    });
    // setProductList(postArray)
    setselectedMultiProduct(postArray);
  };

  const createStandard = () => {

    const allHaveQuantity = standardList.every(product => product.hasOwnProperty('quantity'));

    if (standardList.length > 0) {
      if (allHaveQuantity) {
        onUpdatedData(standardList)
        onCloseClick()
      } else {
        toast.error("Plese Enter the Product Quantity ")
      }
    } else {
      toast.error("Plese select One or More Product")
    }


  }

  useEffect(() => {
    let prod = []
    if (selectedMultiProduct && productList.length > 0) {
      let Data = selectedMultiProduct.map((item) => {
        let productlist = productList.find(productlist => productlist.app_product_id === item.value)
        prod.push(productlist)
      })
      init(prod)
    }
  }, [selectedMultiProduct, productList])

  const init = (prod) => {

    if (onData.length > 0) {
      const newData = prod.map(item => ({ ...item }))
      newData.forEach(item => {
        let ID = item.app_product_id
        let index = onData?.findIndex(dta => dta.app_product_id === ID)
        if (index >= 0) {
          item.quantity = onData[index].quantity
          item.rowTotal = onData[index].rowTotal
          item.discounted_unit_price = onData[index].discounted_unit_price
          item.discount = onData[index].discount
        } else {
          let index = standardList.findIndex(dta => dta.app_product_id === ID)
          if (index >= 0) {
            item.quantity = standardList[index].quantity
            item.rowTotal = standardList[index].rowTotal
            item.discounted_unit_price = standardList[index].discounted_unit_price
            item.discount = standardList[index].discount
          }
        }
      })
      setStandardList(newData)
    } else {
      const newData = prod.map(item => ({ ...item }))
      newData.forEach(item => {
        let ID = item.app_product_id
        let index = standardList.findIndex(dta => dta.app_product_id === ID)
        if (index >= 0) {
          item.quantity = standardList[index].quantity
          item.rowTotal = standardList[index].rowTotal
          item.discounted_unit_price = standardList[index].discounted_unit_price
          item.discount = standardList[index].discount
        }
      })
      setStandardList(newData)
    }
  }

  const getSubTotal3 = (data) => {
    let total = 0;
    if (Array.isArray(data)) {
      data.forEach(item => total += parseFloat(item.rowTotal ? item.rowTotal : 0));
    }
    return parseFloat(total).toFixed(1);
  }

  const getQuantity3 = (data) => {
    let total = 0;
    if (Array.isArray(data)) {
      data.forEach(item => total += parseFloat(item.quantity ? item.quantity : 0));
    }
    return total;
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit2(createStandard)}>
        <h4>Pricing Model - Standard</h4>
        <Row className="mt-5">
          <Col lg="8" >
            <FormGroup row>
              <Label lg="1" md="2" className="d-flex justify-content-lg-start justify-content-md-start">Product <span style={{ color: "red" }}>*</span> </Label>
              <Col lg="7" md="7">
                {/* <Col lg="5" md="8"> */}
                <Select
                  value={selectedMultiProduct.length > 0 ? selectedMultiProduct : selectedMultiProduct}
                  isMulti={true}
                  onChange={handleChangeProduct}
                  options={optionGroupProduct}
                  name={"app_product_id"}
                  className="modal__input"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>


        <Row>

          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={standardList}
                  customPageSize={10}
                  className="custom-header-css"
                // data={opportunitieses}
                // isGlobalFilter={true}
                // isAddOpportunities={permissions.includes("system.orders.order.write") ? true : false}
                // handlePageClick={handlePageClick}
                // customPageCount={pageCount}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Col lg="12" className="d-flex justify-content-end">
          <h4 className="me-4">
            {"QTY " + " : " + `${getQuantity3(standardList.length > 0 ? standardList : '')}` + " | " + "RowTotal" + ": " + " $ " + `${getSubTotal3(standardList.length > 0 ? standardList : '')}`}
          </h4>
        </Col> */}

        <Row>
          <Col lg="12" className="d-flex justify-content-end me-4">
            <FormGroup>
              {/* <Button color='secondary' type='button' onClick={() => setCreateQuotationPage(false)}>
                    Back
                </Button> */}
              <Button color='secondary' type='button' onClick={onCloseClick}>
                Back
              </Button>
              <Button id='createQuotation' color='success' type='submit' className='m-2 me-4'>
                Update
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>

    </React.Fragment>
  )

}

export default standardComponent