export const GET_BUSINESSES = "GET_BUSINESSES"
export const GET_BUSINESSES_SUCCESS = "GET_BUSINESSES_SUCCESS"
export const GET_BUSINESSES_FAIL = "GET_BUSINESSES_FAIL"

export const POST_BUSINESS = "POST_BUSINESS"
export const POST_BUSINESS_SUCCESS = "POST_BUSINESS_SUCCESS"
export const POST_BUSINESS_FAIL = "POST_BUSINESS_FAIL"

export const DELETE_BUSINESS = "DELETE_BUSINESS"
export const DELETE_BUSINESS_SUCCESS = "DELETE_BUSINESS_SUCCESS"
export const DELETE_BUSINESS_FAIL = "DELETE_BUSINESS_FAIL"

export const GET_BUSINESS = "GET_BUSINESS"
export const GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS"
export const GET_BUSINESS_FAIL = "GET_BUSINESS_FAIL"

export const UPDATE_BUSINESS = "UPDATE_BUSINESS"
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS"
export const UPDATE_BUSINESS_FAIL = "UPDATE_BUSINESS_FAIL"