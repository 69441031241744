import {
  GET_ACTIVITIES,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAIL,
} from "./actionTypes";

export const getActivitiesList = (payload) => ({
  type: GET_ACTIVITIES,
  payload: payload
});

export const getActivitiesSuccess = products => ({
  type: GET_ACTIVITIES_SUCCESS,
  payload: products,
});

export const getActivitiesFail = error => ({
  type: GET_ACTIVITIES_FAIL,
  payload: error,
});



