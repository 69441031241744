/* EVENTS */
export const GET_BUSINESS_PRODUCTS = "GET_BUSINESS_PRODUCTS";
export const GET_BUSINESS_PRODUCTS_SUCCESS = "GET_BUSINESS_PRODUCTS_SUCCESS";
export const GET_BUSINESS_PRODUCTS_FAIL = "GET_BUSINESS_PRODUCTS_FAIL"

export const GET_BUSINESS_PRODUCTS_DETAIL_SUCCESS = "GET_BUSINESS_PRODUCTS_DETAIL_SUCCESS";
export const GET_BUSINESS_PRODUCTS_DETAIL_FAIL = "GET_BUSINESS_PRODUCTS_DETAIL_FAIL";
export const GET_BUSINESS_PRODUCTS_DETAIL = "GET_BUSINESS_PRODUCTS_DETAIL";

export const UPDATE_BUSINESS_PRODUCTS_SUCCESS = "UPDATE_BUSINESS_PRODUCTS_SUCCESS";
export const UPDATE_BUSINESS_PRODUCTS_FAIL = "UPDATE_BUSINESS_PRODUCTS_FAIL";
export const UPDATE_BUSINESS_PRODUCTS = "UPDATE_BUSINESS_PRODUCTS";
