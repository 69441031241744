import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';

// Column
import { Name, ID, LoginEmail, UserRoleName, Status, CreatedDate, LastUpdateDate } from './UserListCol';
import getHeader from "config/header";
import axios from "axios";
import expiredSessionHandler from "common/expiredSessionHandler";
import { useDispatch, useSelector } from "react-redux";
import { getSysUsers, deleteAppSysUsers } from 'store/administration/portalUser/actions'
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const User = props => {

  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const pageStatus = query.get("status")

  const history = useHistory()
  const dispatch = useDispatch()
  document.title = "Users | Maxims B2B Portal";

  const [permissions, setPermissions] = useState('')
  const [modal, setModal] = useState(false);
  const [users, setUsers] = useState([])
  // const [pageStatus, setPageStatus] = useState(true)
  const [pageCount, setPageCount] = useState(1);
  // const [page, setPage] = useState(0);
  // const [pageLimit, setPageLimit] = useState(10);

  useEffect(() => {
    setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
  }, [])

  const sysUserState = useSelector(state => state.sysUsersReducer)

  // useEffect(() => {
  //   console.log("sysUserState", sysUserState);
  // }, [sysUserState])


  useEffect(() => {
    if (sysUserState.sysUsers !== null) {
      setUsers(sysUserState.sysUsers.result);
      setPageCount(Math.ceil(sysUserState.sysUsers.summary.filteredsize / pageLimit) == 0 ? 1
        :
        Math.ceil(sysUserState.sysUsers.summary.filteredsize / pageLimit)
      );
    }
    if (sysUserState.isDeleted) {
      setDeleteModal(false);
      handlePageClick(page);
    }
    if (sysUserState.error) {
      setDeleteModal(false);
    }
  }, [sysUserState])

  const [customFilterData, setCustomFilterData] = useState("")

  useEffect(() => {
    // dispatch(getSysRoles({ filter: `{"is_active":${pageStatus}}`, limit: 5, skip: 0 }));
    handlePageClick()
  }, [pageStatus, pageLimit, page, customFilterData, customFilterData])


  useEffect(() => {
    if (customFilterData.length > 0) {
      history.push(`/userList?skip=${0}&&limit=${pageLimit}&&status=${pageStatus}`)
    }
  }, [customFilterData])

  const handlePageClick = (index) => {
    let filter = `{"is_active":${pageStatus},"$or":[
        {"sys_user_keyid":{"$regex":"${customFilterData}","$options":"i"}},
        {"sys_user_name":{"$regex":"${customFilterData}","$options":"i"}},
        {"sys_user_email":{"$regex":"${customFilterData}","$options":"i"}}
      ]}`
    let skips = 0;
    // setPage(page);
    if (page > 0) {
      skips = page * pageLimit;
      dispatch(getSysUsers({ filter: filter, limit: pageLimit, skip: skips }));

      // dispatch(getSysUsers({ filter:`{"is_active":${pageStatus}}`, limit: pageLimit, skip: skips }));
    }
    else {
      dispatch(getSysUsers({ filter: filter, limit: pageLimit, skip: 0 }));

      // dispatch(getSysUsers({ filter:`{"is_active":${pageStatus}}`, limit: pageLimit, skip: 0 }));
    }
  }




  // Company Column
  const columns = useMemo(
    () => [
      {
        Header: 'User ID',
        accessor: 'sys_user_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        }
      },
      {
        Header: 'User Name',
        accessor: 'sys_user_name',
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        Header: 'User Email',
        accessor: 'sys_user_email',
        filterable: true,
        Cell: (cellProps) => {
          return <LoginEmail {...cellProps} />;
        }
      },
      {
        Header: 'Roles',
        accessor: 'sys_role_info',
        filterable: true,
        Cell: (cellProps) => {
          return <UserRoleName {...cellProps} />;
        }
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
          return (

            //   <div className="d-flex gap-3">
            //   {(permissionAction.includes("system.administration.portalusers.read")) ?
            //     <>
            //       <button
            //         onClick={() => {
            //           history.push(`/updateUser/${cellProps.row.original.sys_user_id}`)
            //         }} className="btn btn-success btn-sm">View</button>
            //     </> : <div className="text-muted">No Access</div>
            //   }
            // </div>
            <div className="d-flex gap-3">
              {(permissionAction.includes("system.administration.portalroles.read")) ?
                <>
                  <button
                    onClick={() => {
                      history.push(`/viewUser/${cellProps.row.original.sys_user_id}`)
                    }} className="btn btn-success btn-sm">View</button>
                </> : <div className="text-muted">No Access</div>
              }
              {(permissionAction.includes("system.administration.portalusers.read")) && (permissionAction.includes("system.administration.portalusers.update")) ||
                (permissionAction.includes("system.administration.portalusers.read")) && (permissionAction.includes("system.administration.portalusers.delete"))
                ?
                <>
                  <button
                    onClick={() => {
                      history.push(`/updateUser/${cellProps.row.original.sys_user_id}`)
                    }} className="btn btn-info btn-sm">Edit</button>
                </> : <div className="text-muted">No Access</div>
              }

            </div>
          );
        }
      },
    ], [users]
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setCustomer(null);
    } else {
      setModal(true);
    }
  };

  //delete customer
  // const [deleteModal, setDeleteModal] = useState(false);
  // const [userTBD, setUserTBD] = useState('')

  // const onClickDelete = (user) => {
  //   setUserTBD(user);
  //   setDeleteModal(true);
  // };

  // const handleDeleteCustomer = () => {
  // };

  // const handleCompanyClick = () => {
  //   setCustomerList("");
  //   setIsEdit(false);
  //   toggle();
  // };


  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [userTBD, setUserTBD] = useState('')

  const onClickDelete = (sysRole) => {
    setUserTBD(sysRole)
    setDeleteModal(true)
  }

  const handleDeleteSysUser = async () => {
    if (userTBD.sys_user_id) {
      const data = {
        businessUnitId: userTBD.sys_user_id
      };
      await dispatch(deleteAppSysUsers(data));
    }
  }


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSysUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        {permissions.includes("system.administration.portalusers.read") ?
          <Container fluid>
            <Breadcrumbs title="Users Management" breadcrumbItem="Portal" />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={users}
                      isGlobalFilter={true}
                      customPageCount={pageCount}
                      className="custom-header-css"
                      isAddUserList={permissions.includes("system.administration.portalusers.write") ? true : false}
                      customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}

                    // handleCompanyClick={handleCompanyClick}
                    // customPageStatus={(data) => setPageStatus(data)}
                    // customPageSize={10}
                    // handlePageClick={handlePageClick}
                    // customPageIndex={page}
                    // pageSizeTransfer={(data) => setPageLimit(data)}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          :
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                      <div>
                        <h1><i className='bx bxs-lock'></i></h1>
                        <h3>Access Denied</h3>
                        <div className="text-muted">You don't have permission to access this module</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
    </React.Fragment>
  );
};

User.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
};

export default User;
