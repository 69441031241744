import React, { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Col, FormGroup, Label, Modal, ModalBody, Row, Card, CardBody, Badge } from "reactstrap"
import TableContainer from "../TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getCoupon, getCouponStatus, getRequestCouponStatus } from "store/actions";
import { ProductID, ProductName, PosItemCode, ProductType, OriginalPrice, UnitPrice, DiscountUnitPrice, FlatQuantity, RowTotal } from "../../Common/PricingModelComponent/CustomOpportunityListCol"



const RequestStatusModal = ({ data, show, onApproveClick, onCloseClick, name, title }) => {

  const location = useLocation()
  const dispatch = useDispatch()
  const couponStatusState = useSelector(state => state.CouponReducer)


  useEffect(() => {
    if (show && data) {
      let value = {
        "requestId": `${data}`
        // "requestId": "XTTRGMINN6NF3BJ"
      }
      dispatch(getRequestCouponStatus(value))
    }
  }, [show, data])


  // useEffect(() => {
  //   if (show) {
  //     let value = {
  //       "requestId": 'J56OS5UGZQDNNWX'
  //     }
  //     dispatch(getRequestCouponStatus(value))
  //   }
  // }, [show])


  const [requestStatus, setRequestStatus] = useState([])
  useEffect(() => {
    if (couponStatusState?.requestcouponstatus !== null) {
      setRequestStatus(couponStatusState?.requestcouponstatus
      )
    }
  }, [couponStatusState])



  const dowmloadListColumns = useMemo(
    () => [
      {
        Header: 'S.No',
        accessor: (row, index) => index + 1,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Coupon Name',
        accessor: 'coupon_name',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'Item Code',
        accessor: 'item_code',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },

      {
        Header: 'Coupon Type',
        accessor: 'coupon_type',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName {...cellProps} />;
        }
      },
      {
        Header: 'CSMS Request ID',
        accessor: 'request_id',
        filterable: true,
        Cell: (cellProps) => {
          return <ProductName  {...cellProps} />;
        }
      },
      {
        Header: 'CSMS Request Status',
        accessor: 'coupon_status',
        filterable: true,
        Cell: ({ value }) => {
          if (value === "Requested") {
            return (
              <Badge className="font-size-12 badge-soft-warning" pill>
                {value}
              </Badge>
            );
          } else if (value === "Confirmed") {
            return (
              <Badge className="font-size-12 badge-soft-success" pill>
                {value}
              </Badge>
            );
          } else {
            return value ? value : '';
          }
        }
      }


    ], []
  );


  return (
    <Modal isOpen={show} backdrop="static" centered={true} style={{ maxWidth: '90%' }}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <p className='font-size-18'>Coupon Request Status (CSMS) </p>
              <>
                <Row style={{ maxHeight: "500px", overflowY: "auto" }}>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <TableContainer
                          columns={dowmloadListColumns}
                          data={requestStatus}
                          isGlobalFilter={false}
                          className="custom-header-css"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

RequestStatusModal.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any
}

export default RequestStatusModal