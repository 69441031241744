import React, { useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"
import axios from 'axios'
import getHeader from 'config/header'
import getFormHeader from 'config/formHeader'
import StatusModal from 'components/Common/StatusModal'
import { useDispatch, useSelector } from 'react-redux'
import { getBusinesses, getCredit, getCredits, getDepartment, getDepartments, updateCredit } from 'store/actions'
import departmentReducer from 'store/customers/company/department/reducer'
import { API_BASE_URL, DEPARTMENT_URL } from 'helpers/url_helper'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

function ViewCredit() {
  const { id } = useParams()
  const query = useQuery()
  const BusID = query.get("busID")
  const [removeButton, setRemoveButton] = useState(false)
  const dispatch = useDispatch();

  const history = useHistory()
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm()

  const businessState = useSelector(state => state.businessReducer);
  const creditsState = useSelector(state => state.creditsReducer);
  const departmentState = useSelector(state => state.departmentReducer);

  const [businessList, setBusinessList] = useState([])


  useEffect(() => {
    dispatch(getBusinesses({ filter: `{is_active:true}`, limit: 0, skip: 0 }))
  }, [])
  useEffect(() => {
    if (businessState.businesses !== null) {
      setBusinessList(businessState.businesses.result)
    }
  }, [businessState])


  const [attachment, setAttachment] = useState("")
  const [attachmentName, setAttachmentName] = useState("")
  useEffect(() => {
    if (businessList && id && BusID) {
      dispatch(getCredit({ id, BusID }))

    }
  }, [businessList])
  useEffect(() => {
    if (creditsState.credit !== null && BusID) {
      // console.log(creditsState);
      setValue("app_credit_business_id", creditsState.credit.app_credit_business_id)
      setValue("app_business_site_id", creditsState.credit.app_business_site_id)
      setValue("app_credit_type", creditsState.credit.app_credit_type)
      setValue("credit_value", creditsState.credit.credit_value)
      setValue("oracle_site_ID", creditsState.credit.oracle_site_ID)
      setValue("payment_notes", creditsState.credit.payment_notes)
      setValue("status", creditsState.credit.status)
      //  setValue("is_active", creditsState.is_active)
      // if (creditsState.is_active === true) {
      //   setValue("is_active", "active")
      // } else if (creditsState.is_active === false) {
      //   setValue("creditsState", "inactive")
      //  }
      setImagePreview(true)
      // statusChange()
      setAttachment(creditsState.credit.transaction_attachment)
      loadFileName(creditsState.credit.transaction_attachment)
      const companyID = BusID;
      dispatch(getDepartments({ companyID, filter: `{is_active:true}`, limit: 0, skip: 0 }));
    }

  }, [creditsState]);

  useEffect(() => {
    if (departmentState.departments !== null && creditsState.credit !== null) {
      setSiteList(departmentState.departments.result);
      const token = sessionStorage.getItem('authUser')
      axios.get(API_BASE_URL + DEPARTMENT_URL + "/" + creditsState.credit.app_credit_business_id + '/' + creditsState.credit.app_business_site_id, getHeader(token))
        .then((res) => {
          setValue("app_business_site_id", res.data.department_id)
        }).catch(err => {
          console.log(err);
        })
    }
  }, [departmentState])


  const loadFileName = (file) => {
    let fileArray = file.split("/")
    let fileName = fileArray[fileArray.length - 1].split("_")
    let shortName = fileName[fileName.length - 1]
    setAttachmentName(shortName)
  }

  const openFile = () => {
    window.open(attachment, "_blank")
  }

  const [file, setFile] = useState('')
  const [imagePreview, setImagePreview] = useState(false)

  $('#fileInp').change((input) => {
    setImagePreview(true)
    $('#fileImg').css('display', 'block')
    if (input.target.files && input.target.files[0]) {
      var fileReader = new FileReader()
      fileReader.onload = ({ target }) => {
        $('#fileImg').attr('src', target.result)
      }
      fileReader.readAsDataURL(input.target.files[0])
      let bodyFormData = new FormData()
      bodyFormData.append('attachment', input.target.files[0])
      setFile(bodyFormData)
    }
  })

  const openImage = (e) => {
    let data = e.target.currentSrc;
    let w = window.open('about:blank');
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  }

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      let fileLocation = ""
      const token = sessionStorage.getItem('authUser')
      axios.post("/api/v2/upload", file, getFormHeader(token))
        .then(res => {
          fileLocation = res.data.Location
          resolve(fileLocation)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  const UpdateCredit = async (data) => {
    let transaction_attachment = ""
    if (file) {
      transaction_attachment = await uploadFile(file)
    }

    let values = {
      ...data,
      transaction_attachment: attachment ? attachment : transaction_attachment,
    }
    console.log(values)
    $('#updateCreditBtn').attr("disabled", true)
    dispatch(updateCredit({ id, BusID, values }))

  }
  useEffect(() => {
    if (creditsState.isUpdated) {
      history.goBack()
    }
  }, [creditsState])


  const [siteList, setSiteList] = useState([])
  const getBusinessId = (e) => {
    const companyID = e.target.value
    dispatch(getDepartments({ companyID, filter: `{is_active:true}`, limit: 0, skip: 0 }));
  }

  useEffect(() => {
    if (departmentState.department !== null) {
      setSiteList(departmentState.departments.result);
    }
  }, [departmentState])


  const handleChange = e => {
    if (e.target.files[0]) {
      setFile(e.target.files[0])
      var fileReader = new FileReader()
      fileReader.onload = ({ target }) => {
        $("#fileImg").attr("src", target.result)
      }
      fileReader.readAsDataURL(e.target.files[0])
      setAttachment(true)
    }
  }

  // status change
  const [confirmStatus, setConfirmStatus] = useState()
  const statusChange = (e) => {
    setConfirmStatus(e.target.value)
    if (e.target.value === "active") {
      setStatusTBC(true)
      setStatusModal(true)
    } else if (e.target.value === "inactive") {
      setStatusTBC(false);
      setStatusModal(true)
    }
  }

  const [statusModal, setStatusModal] = useState(false)
  const [statusTBC, setStatusTBC] = useState()

  const handleStatus = () => {
    setValue("is_active", confirmStatus)
    setStatusModal(false)
  }

  const closeClick = () => {
    const value = getValues("is_active");
    if (value === "inactive") {
      let dta = "active"
      setValue("is_active", dta)
      setStatusModal(false)
      console.log("singleValue", dta);
    } else if (value === "active") {
      let dta = "inactive"
      setValue("is_active", dta)
      setStatusModal(false)
      console.log("singleValue", dta);
    }
  }

  return (
    <React.Fragment>
      <StatusModal
        show={statusModal}
        onDeleteClick={handleStatus}
        onCloseClick={closeClick}
        data={statusTBC}
      />
      <div className="page-content">
        <Breadcrumbs title="View Credit" breadcrumbItem="Customers" />
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card body>
                <Form onSubmit={handleSubmit(UpdateCredit)}>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> B2B Credit ID  <span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        {/* <input readOnly style={{ backgroundColor: "#D2D4D1" }} type="text" className='form form-control' {...register('b2b_credit_key_id', { required: true })} /> */}
                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} type="text" className='form form-control' {...register('b2b_credit_key_id',)} />
                        {/* {errors.b2b_credit_key_id && (<p style={{ color: "red" }}>This Field is required</p>)} */}
                      </Col>
                    </FormGroup>
                  </Row>

                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Company Name <span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        <select style={{ backgroundColor: "#D2D4D1" }} disabled onInput={(e) => { getBusinessId(e) }} defaultValue="" id="app_credit_business_id" className={`form-select d-inline ${errors.app_credit_business_id && 'is-invalid'}`} aria-label="Default select example" {...register("app_credit_business_id", { required: true })}>
                          <option disabled hidden value="">Select the Company Name</option>
                          {businessList.map((business) => (
                            <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                          ))}
                        </select>
                        {errors.app_credit_business_id && (<p style={{ color: "red" }}>This Field is required</p>)}
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                        Company Site <span className='text-danger'>*</span>{" "}</Label>
                      <Col lg="4" md="6">
                        <select style={{ backgroundColor: "#D2D4D1" }} disabled defaultValue="" id="app_business_site_id" className={`form-select d-inline ${errors.app_business_site_id && 'is-invalid'}`} aria-label="Default select example" {...register("app_business_site_id", { required: true })}>
                          <option disabled hidden value="">Select the Company Site</option>
                          {siteList.map((site) => (
                            <option key={site.department_id} value={site.department_id}>{site.department_name}</option>
                          ))}
                        </select>
                        {errors.app_business_site_id && (<p style={{ color: "red" }}>This Field is required</p>)}
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Oracle Site ID  <span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} type="text" className={`form-select d-inline ${errors.oracle_site_ID && 'is-invalid'}`} {...register('oracle_site_ID', { required: true })} />
                        {errors.oracle_site_ID && <p style={{ color: "red" }}>This Field is required</p>}
                      </Col>
                    </FormGroup>
                  </Row>
                  <hr />
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Credit Type <span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        <select disabled style={{ backgroundColor: "#D2D4D1" }} defaultValue="" id="app_business_unit" className={`form-select d-inline ${errors.app_credit_type && 'is-invalid'}`} aria-label="Default select example" {...register("app_credit_type", { required: true })}>
                          <option disabled hidden value="">Select the Credit Type</option>
                          <option value="deposit">Deposit</option>
                        </select>
                        {errors.app_credit_type && (<p style={{ color: "red" }}>This Field is required</p>)}
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Value <span className='text-danger'>*</span></Label>
                      <Col lg="4" md="6">
                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} type="Number" className='form form-control' {...register('credit_value', { required: true })} />
                        {errors.credit_value && (<p style={{ color: "red" }}>This Field is required</p>)}
                      </Col>
                    </FormGroup>
                  </Row>

                  {attachment && (
                    <Row>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end" > Payment File
                        </Label>
                        <Col lg="4" md="6">
                          <div>
                            <Col lg="4" md="6">
                              <div type="button" className="btn btn-secondary" onMouseEnter={() => setRemoveButton(true)} onMouseLeave={() => setTimeout(() => { setRemoveButton(false) }, 3000)}
                                style={{ borderRadius: "4px", padding: "5px ", width: "150px", }}
                              >
                                {removeButton && (
                                  <button type="button"
                                    onClick={() => {
                                      setAttachment("")
                                      setFile("")
                                    }}
                                    style={{ borderRadius: "4px", marginTop: "14px", padding: "2px", paddingBottom: "2px", marginLeft: "-50px", width: "20px", }} className="thumbClose btn btn-danger btn-sm"
                                  >
                                    X
                                  </button>
                                )}
                                {attachmentName}
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </FormGroup>
                    </Row>
                  )
                  }
                  {/* <Row>
                      <FormGroup row>
                          <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Payment File(PDF/JPG/PNG)</Label>
                          <Col lg="4" md="6">
                              <input id="fileInp" type="file" className='form form-control' />
                              {errors.payment_file && (<p style={{ color: "red" }}>This Field is required</p>)}
                          </Col>
                      </FormGroup>
                  </Row> */}
                  {imagePreview && attachment && (
                    <Row>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " > Image Preview
                        </Label>
                        <Col lg="4" md="6" className="d-flex justify-content-lg-left justify-content-md-left" >
                          <div className="imagePreviewDiv d-flex justify-content-lg-left justify-content-md-left">
                            <Col lg="4" md="6">
                              <img style={{ backgroundColor: "#D2D4D1" }} className=" imagePreviewImg" src={attachment} onClick={openImage} id="fileImg" onError={() => setImagePreview(false)} width={100} height={100} />
                            </Col>
                          </div>
                        </Col>
                      </FormGroup>
                    </Row>
                  )}
                  {!attachment && (
                    <Row>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end" > Payment File(PDF/JPG/PNG)
                        </Label>
                        <Col lg="4" md="6">
                          <input readOnly style={{ backgroundColor: "#D2D4D1" }} id="fileInp" type="file" className="form form-control" />
                          {errors.payment_file && (<p style={{ color: "red" }}> This Field is required </p>)}
                        </Col>
                      </FormGroup>
                    </Row>
                  )}
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end" > Payment Notes
                      </Label>
                      <Col lg="4" md="6">
                        <input readOnly style={{ backgroundColor: "#D2D4D1" }} type="text" className="form form-control" {...register("payment_notes")} />
                        {/* {errors.payment_notes && <p style={{ color: "red" }}>This Field is required</p>} */}
                      </Col>
                    </FormGroup>
                  </Row>
                  {/* <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                      <Col lg="4" md="6">
                        <select onInput={(e) => { statusChange(e) }} className="form-select d-inline" {...register('is_active')}>
                          <option hidden value="">Select Status</option>
                          <option value="active">Active</option>
                          <option value="inactive">In-active</option>
                        </select>
                      </Col>
                    </FormGroup>
                  </Row> */}
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                      <Col lg="4" md="6">
                        <select disabled style={{ backgroundColor: "#D2D4D1" }} className="form-select d-inline" {...register('status')}>
                          {/* <option hidden value="">Select Status</option> */}
                          <option value="in progress">In Progress</option>
                          <option value="reject">Reject</option>
                          <option value="approved">Approved</option>
                        </select>
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                      <Col lg="5" md="6">
                        <Button color='secondary' type='button' onClick={() => history.goBack()}>
                          Back
                        </Button>
                        {/* <Button id="updateCreditBtn" color="success" type="submit" className="m-2" >
                          Update
                        </Button> */}
                      </Col>
                    </FormGroup>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewCredit

