import { takeEvery, put ,call  } from "redux-saga/effects";
import {
    getappCredentialsSuccess, getappCredentialsFail,
    postAppCredentialsSuccess, postAppCredentialsFail,
} from "./actions"


// Api handler
import {
    getAppCredentials,
    postAppCredentials,
} from "helpers/backendApi_helpers"


import {
    GET_APP_CREDENTIALS,
    POST_APP_CREDENTIALS,
} from "./actionTypes"


function * fetchAppUsers({ payload :payload}) {
    try{
        const response = yield call(getAppCredentials, payload)
        yield put(getappCredentialsSuccess(response))
    } catch (error) {
        yield put (getappCredentialsFail(error))
    }
}

function* postAppUsers({payload : payload }){
    try{
        const response = yield call(postAppCredentials, payload)
        yield put(postAppCredentialsSuccess(response))
    } catch (error){
        yield put (postAppCredentialsFail(error))
    }
 }

 function* AppCredentialsSaga(){
    yield takeEvery(GET_APP_CREDENTIALS,fetchAppUsers)
    yield takeEvery(POST_APP_CREDENTIALS, postAppUsers)
 }

 export default AppCredentialsSaga