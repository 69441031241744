import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,

    GET_SYS_USERS_PROFILE,
    GET_SYS_USERS_PROFILE_SUCCESS,
    GET_SYS_USERS_PROFILE_FAIL,

    GET_NOTIFICATION_APPROVAL_STATUS,
    GET_NOTIFICATION_APPROVAL_STATUS_SUCCESS,
    GET_NOTIFICATION_APPROVAL_STATUS_FAIL,

    GET_NOTIFICATION_PENDING_STATUS,
    GET_NOTIFICATION_PENDING_STATUS_SUCCESS,
    GET_NOTIFICATION_PENDING_STATUS_FAIL


} from "./actionTypes";

export const getSysUsersProfile = (payload) => ({
    type: GET_SYS_USERS_PROFILE,
    payload: payload
})

export const getSysUsersProfileSuccess = sysUsers => ({
    type: GET_SYS_USERS_PROFILE_SUCCESS,
    payload: sysUsers
})

export const getSysUsersProfileFail = error => ({
    type: GET_SYS_USERS_PROFILE_FAIL,
    payload: error
})

//xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx---------------xxxxxxxxxxxxxxxxxxxxxxxxxxx

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});


//======================================================
export const getNotificationApprovalStatus = (payload) => ({
    type: GET_NOTIFICATION_APPROVAL_STATUS,
    payload: payload
})

export const getNotificationApprovalStatusSuccess = approvalstatus => ({
    type: GET_NOTIFICATION_APPROVAL_STATUS_SUCCESS,
    payload: approvalstatus
})

export const getNotificationApprovalStatusFail = error => ({
    type: GET_NOTIFICATION_APPROVAL_STATUS_FAIL,
    payload: error
})


export const getNotificationPendingStatus = (payload) => ({
    type: GET_NOTIFICATION_PENDING_STATUS,
    payload: payload
})

export const getNotificationPendingStatusSuccess = approvalstatus => ({
    type: GET_NOTIFICATION_PENDING_STATUS_SUCCESS,
    payload: approvalstatus
})

export const getNotificationPendingStatusFail = error => ({
    type: GET_NOTIFICATION_PENDING_STATUS_FAIL,
    payload: error
})

