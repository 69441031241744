import React, { useState, useEffect } from "react"
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import axios from "axios";
import getHeader from "config/header";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as url from "./../../helpers/url_helper";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

function CompanySelectInvoiceList() {
  const query = useQuery();
  const skip = query.get("skip")
  const limit = query.get("limit")
  const Busid = query.get("Busid")
  const queryMultistatus = query.get("queryMultistatus")



  const history = useHistory()
  const location = useLocation();
  const [businessList, setBusinessList] = useState([])

  // const pathname = window.location.pathname
  const pathname = location.pathname

  useEffect(() => {
    const token = sessionStorage.getItem('authUser')

    if (location.pathname === '/invoicelist') {


      if (Busid === null) {
        axios.get(url.API_BASE_URL + url.BUSINESS_URL + '?filter={"is_active" : true}', getHeader(token))
          .then((res) => {
            setBusinessList(res.data.result)
            history.push(`${pathname}?skip=${Number(skip)}&&limit=${Number(limit)}&&queryMultistatus=${queryMultistatus}&&Busid=${null}`)
          }).catch(err => {
            console.log(err);
          })
      } else {
        axios.get(url.API_BASE_URL + url.BUSINESS_URL + '?filter={"is_active" : true}', getHeader(token))
          .then((res) => {
            setBusinessList(res.data.result)
            history.push(`${pathname}?skip=${Number(skip)}&&limit=${Number(limit)}&&queryMultistatus=${queryMultistatus}&&Busid=${Busid}`)
          }).catch(err => {
            console.log(err);
          })
      }
    }

  }, [])

  const selectedID = (e) => {
      history.push(`${pathname}?skip=${Number(skip)}&&limit=${Number(limit)}&&queryMultistatus=${queryMultistatus}&&Busid=${e.target.value}`)
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          {location.pathname !== "/dashboard" &&
            <select onInput={(e) => selectedID(e)} value={Busid} className="form-select d-inline" aria-label="Default select example">
              <option value="null">Company</option>
              {businessList.map(business => (
                <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
              ))}
            </select>}
        </Col>
      </Row>

    </React.Fragment>
  )
}

export default CompanySelectInvoiceList



