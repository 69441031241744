import {
    GET_DOWNLOAD_LOG,
    GET_DOWNLOAD_LOG_SUCCESS,
    GET_DOWNLOAD_LOG_FAIL,
  
    POST_DOWNLOAD_LOG,
    POST_DOWNLOAD_LOG_SUCCESS,
    POST_DOWNLOAD_LOG_FAIL,
  
    DELETE_DOWNLOAD_LOG,
    DELETE_DOWNLOAD_LOG_SUCCESS,
    DELETE_DOWNLOAD_LOG_FAIL,
  
    GET_DOWNLOAD_LOG_DETAIL_SUCCESS,
    GET_DOWNLOAD_LOG_DETAIL_FAIL,
    GET_DOWNLOAD_LOG_DETAIL,
  } from "./actionTypes";
  
  /////////////////////////
  //  Get download log  //
  /////////////////////////
  
  export const getDownloadLog = (payload) => ({
    type: GET_DOWNLOAD_LOG,
    payload: payload
  });
  
  export const getDownloadLogSuccess = downloadlog => ({
    type: GET_DOWNLOAD_LOG_SUCCESS,
    payload: downloadlog,
  });
  
  export const getDownloadLogFail = error => ({
    type: GET_DOWNLOAD_LOG_FAIL,
    payload: error,
  });
  
  //////////////////////////////
  // Get download log detail  //
  //////////////////////////////
  
  export const getDownloadLogDetail = (payload) => ({
    type: GET_DOWNLOAD_LOG_DETAIL,
    payload: payload
  });
  
  export const getDownloadLogDetailSuccess = downloadlog => ({
    type: GET_DOWNLOAD_LOG_DETAIL_SUCCESS,
    payload: downloadlog,
  });
  
  export const getDownloadLogDetailFail = error => ({
    type: GET_DOWNLOAD_LOG_DETAIL_FAIL,
    payload: error,
  });
  
  ////////////////////////
  // Post downloadlog ///
  ////////////////////////
  
  export const postDownloadLog = (payload) => ({
    type: POST_DOWNLOAD_LOG,
    payload: payload
  });
  
  export const postDownloadLogSuccess = downloadlog => ({
    type: POST_DOWNLOAD_LOG_SUCCESS,
    payload: downloadlog,
  });
  
  export const postDownloadLogFail = error => ({
    type: POST_DOWNLOAD_LOG_FAIL,
    payload: error,
  });
  
  ///////////////////////////
  // Delete download log //
  //////////////////////////
  
  export const deleteDownloadLog = (payload) => ({
    type: DELETE_DOWNLOAD_LOG,
    payload: payload
  });
  
  export const deleteDownloadLogSuccess = () => ({
    type: DELETE_DOWNLOAD_LOG_SUCCESS,
  });
  
  export const deleteDownloadLogFail = error => ({
    type: DELETE_DOWNLOAD_LOG_FAIL,
    paACCOUNT
  });
  
  
  
  