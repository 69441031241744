import React from "react"
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Collapse,
    Nav, NavItem, NavLink, TabContent, TabPane, CardText, Table
} from "reactstrap"
import classnames from 'classnames';
import axios from 'axios';
import getHeader from 'config/header';
import { useDispatch, useSelector } from "react-redux";
import { getBusinessActions } from "store/actions/businessActions/actions";
import { postAppCredentials } from "store/credentials/appUserCredentials/actions";
import { postAppRoles } from "helpers/backendApi_helpers";

function CreateCustomerRole() {

    const history = useHistory()
    const { register, handleSubmit, formState: { errors } } = useForm()

    // Get Permissions
    const [permissionName, setPermissionName] = useState(null)
    const [permissions, setPermissions] = useState([])

    const dispatch = useDispatch()

    const businessactions = useSelector(state => state.AppBusinessActions.actions)

    useEffect(() => {
        if (businessactions !== null) {
            setPermissionName(businessactions.app_name)
            setPermissions(businessactions.modules)
        }
    }, [businessactions])

    // business Action Api 
    useEffect(() => {
        dispatch(getBusinessActions());
    }, [dispatch])

    // Accordions Tab State
    const [col, setCol] = useState([])
    const [activeTab, setactiveTab] = useState("");


    const appCredentialState = useSelector(state => state.appCredentialsReducer)

    useEffect(() => {
        if (appCredentialState.isCreated) {
            history.goBack()
            appCredentialState.isCreated = false
        }
    }, [appCredentialState])

    const createCustomerRole = async (data) => {
        $('#createCustomerRoleBtn').attr('disabled', true)
        let value
        if (data.is_active === "active") {
            value = true
        } else if (data.is_active === "inactive") {
            value = false
        }

        const newCustomerRoleData = {
            ...data,
            is_active: value,
        }

        const appRoleIdCredential = async (roleId, newCredentialData) => {
            const credentialData = {
                appRoleId: roleId,
                data: newCredentialData
            }
            console.log("credentialData", credentialData);
            dispatch(postAppCredentials(credentialData))
            $('#createCustomerRoleBtn').attr('disabled', false)
        }

        try {
            delete newCustomerRoleData.credential
            const response = await postAppRoles(newCustomerRoleData)
            if (response) {
                const customerRoleID = response.app_role_id
                const newCredentialData = {
                    credentials: []
                }
                let dataPermissions = data.credential
                for (let i in dataPermissions) {
                    let credentialData = {
                        credential: dataPermissions[i]
                    }
                    newCredentialData.credentials.push(credentialData)
                }
                await appRoleIdCredential(customerRoleID, newCredentialData)
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }

    const [selectedPermissions, setSelectedPermissions] = useState({}); // Separate state for selected permissions

    const toggleAccordionCollapse = (index) => {
        let colItems = [...col];
        if (colItems[index] === null || colItems[index] === undefined || colItems[index] === false) {
            for (let i in colItems) {
                colItems[i] = false;
            }
            colItems[index] = true;
            setactiveTab(index + '-' + "0");
        } else {
            colItems[index] = false;
        }
        setCol(colItems);
    };

    const toggleAccordionTabs = (index, subIndex) => {
        let activeTab = index + '-' + subIndex;
        setactiveTab(activeTab);
    };

    const handleSelectAll = (index, subIndex) => {
        const selectedPermissionsCopy = { ...selectedPermissions };
        selectedPermissionsCopy[`${index}-${subIndex}`] = !selectedPermissions[`${index}-${subIndex}`];

        // Toggle all function checkboxes for the submodule
        permissions[index].submodules[subIndex].functions.forEach((_, functIndex) => {
            selectedPermissionsCopy[`${index}-${subIndex}-${functIndex}`] = selectedPermissionsCopy[`${index}-${subIndex}`];
        });

        setSelectedPermissions(selectedPermissionsCopy);
    };

    const handleFunctionCheckbox = (index, subIndex, functIndex) => {
        const selectedPermissionsCopy = { ...selectedPermissions };
        selectedPermissionsCopy[`${index}-${subIndex}-${functIndex}`] = !selectedPermissions[`${index}-${subIndex}-${functIndex}`];

        // Check if all function checkboxes for the submodule are selected, then set submodule checkbox to true
        const allFunctionsSelected = permissions[index].submodules[subIndex].functions.every(
            (_, i) => selectedPermissionsCopy[`${index}-${subIndex}-${i}`]
        );
        selectedPermissionsCopy[`${index}-${subIndex}`] = allFunctionsSelected;

        setSelectedPermissions(selectedPermissionsCopy);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Customer Role" breadcrumbItem=" Customer Role" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(createCustomerRole)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Role Name<span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="8" md="8">
                                                <input type="text" className='form form-control' {...register('app_role_name', { required: true })} />
                                                {errors.app_role_name && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Role Description<span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="8" md="8">
                                                <input type="text" className='form form-control' {...register('app_role_description', { required: true })} />
                                                {errors.app_role_description && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Permissions <span style={{ color: "steelblue" }}>[{permissionName}]</span></Label>

                                            <Col lg="8" md="8">
                                                <div className="accordion" id="accordion">
                                                    {permissions && permissions.map((permission, index) => {
                                                        return (
                                                            <div key={index} className="accordion-item">
                                                                <h2 className="accordion-header" id="headingTwo">
                                                                    <button
                                                                        className={classnames("accordion-button", "fw-medium", { collapsed: !col[index] })}
                                                                        type="button"
                                                                        onClick={() => toggleAccordionCollapse(index)}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        {permission.module_name}
                                                                    </button>
                                                                </h2>
                                                                <Collapse isOpen={col[index] ? col[index] : false} className="accordion-collapse">
                                                                    <div className="accordion-body">
                                                                        <div className="text-muted">
                                                                            <Nav tabs className="d-flex justify-content-between">
                                                                                {permission.submodules.map((subModules, subIndex) => {
                                                                                    return (
                                                                                        <NavItem key={subIndex}>
                                                                                            <NavLink
                                                                                                style={{ cursor: "pointer" }}
                                                                                                className={classnames({
                                                                                                    active: activeTab === (index + '-' + subIndex),
                                                                                                })}
                                                                                                onClick={() => {
                                                                                                    toggleAccordionTabs(index, subIndex);
                                                                                                }}
                                                                                            >
                                                                                                {subModules.module_name}
                                                                                            </NavLink>
                                                                                        </NavItem>
                                                                                    );
                                                                                })}
                                                                            </Nav>
                                                                            <TabContent activeTab={activeTab} className="p-3 text-muted">
                                                                                {permission.submodules.map((submodules, subIndex) => {
                                                                                    return (
                                                                                        <TabPane key={subIndex} tabId={index + '-' + subIndex}>
                                                                                            <Row>
                                                                                                <Col sm="12">
                                                                                                    <div className="mb-0">
                                                                                                        <Table striped hover>
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>#</th>
                                                                                                                    <th>Function Name</th>
                                                                                                                    <th onClick={() => handleSelectAll(index, subIndex)}>
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            checked={selectedPermissions[`${index}-${subIndex}`]}
                                                                                                                            // onClick={() => handleSelectAll(index, subIndex)}
                                                                                                                            value={submodules.permission} {...register(`credential`)}
                                                                                                                        />
                                                                                                                        &nbsp;&nbsp;Select All
                                                                                                                    </th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            {submodules.functions.map((funct, functIndex) => {
                                                                                                                return (
                                                                                                                    <tbody key={functIndex}>
                                                                                                                        <tr>
                                                                                                                            <td>{functIndex + 1}</td>
                                                                                                                            <td onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}>{funct.function_name}</td>
                                                                                                                            <td onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}>
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    checked={selectedPermissions[`${index}-${subIndex}-${functIndex}`]}
                                                                                                                                    // onClick={() => handleFunctionCheckbox(index, subIndex, functIndex)}
                                                                                                                                    value={funct.permission}  {...register(`credential`)}
                                                                                                                                />
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                );
                                                                                                            })}
                                                                                                        </Table>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </TabPane>
                                                                                    );
                                                                                })}
                                                                            </TabContent>
                                                                        </div>
                                                                    </div>
                                                                </Collapse>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="8" md="8">
                                                <select className="form-select d-inline" {...register('is_active')}>
                                                    {/* <option hidden value="">Select Status</option> */}
                                                    <option value="inactive">INACTIVE</option>
                                                    <option value="active">ACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="8" md="8">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button id="createCustomerRoleBtn" color='success' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default CreateCustomerRole