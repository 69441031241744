import { takeEvery, put, call } from "redux-saga/effects"
import {
    getSysCredentialsSuccess, getSysCredentialsFail,
    postSysCredentialsSuccess, postSysCredentialsFail,
    // getAppSysUserSuccess, getAppSysUserFail,
    // deleteAppSysUsersSuccess, deleteAppSysUsersFail,
    // updateSysUsersSuccess, updateSysUsersFail,
} from "./actions"

// Api Handler 
import {
    getSysCredentials,
    postSysCredentials,
    // deleteSysUser,
    // getSysUser,
    // updateSysUser,

} from "helpers/backendApi_helpers";


import {
    GET_SYS_CREDENTIALS,
    // GET_SYS_USER,
    POST_SYS_CREDENTIALS,
    // UPDATE_SYS_USERS,
    // DELETE_SYS_USERS,
} from "./actionTypes";




function* fetchAppSysUsers({ payload: payload }) {
    try {
        const response = yield call(getSysCredentials, payload);
        yield put(getSysCredentialsSuccess(response))
    } catch (error) {
        yield put(getSysCredentialsFail(error))
    }
}

function* postAppSysUsers({ payload: payload }) {
    try {
        const response = yield call(postSysCredentials, payload);
        yield put(postSysCredentialsSuccess(response))
    } catch (error) {
        yield put(postSysCredentialsFail(error))
    }
}

// function* deleteAppSysUsers({ payload: payload }) {
//     try {
//         const response = yield call(deleteSysUser, payload);
//         yield put(deleteAppSysUsersSuccess(response))
//     } catch (error) {
//         yield put(deleteAppSysUsersFail(error))
//     }
// }

// function* getAppSysUser({ payload: payload }) {
//     try {
//         const response = yield call(getSysUser, payload);
//         yield put(getAppSysUserSuccess(response))
//     } catch (error) {
//         yield put(getAppSysUserFail(error))
//     }
// }

// function* updateAppSysUsers({ payload: payload }) {
//     try {
//         const response = yield call(updateSysUser, payload);
//         yield put(updateSysUsersSuccess(response))
//     } catch (error) {
//         yield put(updateSysUsersFail(error))
//     }
// }

function* SysCredentialsSaga() {
    yield takeEvery(GET_SYS_CREDENTIALS, fetchAppSysUsers)
    yield takeEvery(POST_SYS_CREDENTIALS, postAppSysUsers)
    // yield takeEvery(DELETE_SYS_USERS, deleteAppSysUsers)
    // yield takeEvery(GET_SYS_USER, getAppSysUser)
    // yield takeEvery(UPDATE_SYS_USERS, updateAppSysUsers)
}

export default SysCredentialsSaga






