import {
    GET_ATTACHMENT,
    GET_ATTACHMENT_SUCCESS,
    GET_ATTACHMENT_FAIL,

    POST_ATTACHMENT,
    POST_ATTACHMENT_SUCCESS,
    POST_ATTACHMENT_FAIL,

    UPDATE_ATTACHMENT,
    UPDATE_ATTACHMENT_SUCCESS,
    UPDATE_ATTACHMENT_FAIL,
} from "./actionTypes";


//////////////////////////////
// Get By id Attachment //
//////////////////////////////

export const getAttachment = (payload) => ({
    type: GET_ATTACHMENT,
    payload: payload
});

export const getAttachmentSuccess = attachment => ({
    type: GET_ATTACHMENT_SUCCESS,
    payload: attachment,
});

export const getAttachmentFail = error => ({
    type: GET_ATTACHMENT_FAIL,
    payload: error,
});

///////////////////////////////
// Post Attachment   ///
//////////////////////////////

export const postAttachment = (payload) => ({
    type: POST_ATTACHMENT,
    payload: payload
});

export const postAttachmentSuccess = attachment => ({
    type: POST_ATTACHMENT_SUCCESS,
    payload: attachment,
});

export const postAttachmentFail = error => ({
    type: POST_ATTACHMENT_FAIL,
    payload: error,
});


///////////////////////////////
// Update Attachment  //
//////////////////////////////

export const updateAttachment = (payload) => ({
    type: UPDATE_ATTACHMENT,
    payload: payload
});

export const updateAttachmentSuccess = payload => ({
    type: UPDATE_ATTACHMENT_SUCCESS,
    payload: payload
});

export const updateAttachmentFail = error => ({
    type: UPDATE_ATTACHMENT_FAIL,
    payload: error,
});


