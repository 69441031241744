import {
    GET_BUSINESS_ACTIONS,
    GET_BUSINESS_ACTIONS_SUCCESS,
    GET_BUSINESS_ACTIONS_FAIL,
} from './actionTypes'

const INIT_STATE ={
    actions: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
}

const AppBusinessActions =(state = INIT_STATE, action) =>{
    switch (action.type) {
        case GET_BUSINESS_ACTIONS:
          return {
            ...state,
            loading: true,
            actions: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_BUSINESS_ACTIONS_SUCCESS:
          return {
            ...state,
            loading: false,
            actions: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_BUSINESS_ACTIONS_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        default:
          return state;
      }
}
export default AppBusinessActions