import PropTypes from 'prop-types'
import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"

// const StatusModal = ({ show, onDeleteClick, onCloseClick, data , lockData}) => {

//     const value = () => {   
//         if (data === true) {
//             return "Active"
//         } else if (data === false) {
//             return "In-active"
//         }

        
//     }

//     const asd = () => {   
//         if (lockData === true) {
//             return "Lock"
//         } else if (lockData === false) {
//             return "Unlock"
//         }
//     }

//     return (
//         <Modal isOpen={show} toggle={onCloseClick} centered={true}>
//             <ModalBody className="py-3 px-5">
//                 <Row>
//                     <Col lg={12}>
//                         <div className="text-center">
//                             <i
//                                 className="mdi mdi-alert-circle-outline"
//                                 style={{ fontSize: "9em", color: "orange" }}
//                             />
//                             <h2>Confirm Want to {value()} ?</h2>
//                             <h2>Confirm Want to {asd()} ?</h2>
//                         </div>
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col>
//                         <div className="text-center mt-3">
//                             <button
//                                 type="button"
//                                 className="btn btn-success btn-lg ms-2"
//                                 onClick={onDeleteClick}
//                             >
//                                 Yes
//                             </button>
//                             <button
//                                 type="button"
//                                 className="btn btn-danger btn-lg ms-2"
//                                 onClick={onCloseClick}
//                             >
//                                 No
//                             </button>
//                         </div>
//                     </Col>
//                 </Row>
//             </ModalBody>
//         </Modal>
//     )
// }


// const StatusModal = ({ show, onDeleteClick, onCloseClick, data, lockData }) => {

//     const value = (data) => {
//       return data ? "Active" : "In-active";
//     }
  
//     const asd = (lockData) => {
//       return lockData ? "Lock" : "Unlock";
//     }
  
//     return (
//       <Modal isOpen={show} toggle={onCloseClick} centered={true}>
//         <ModalBody className="py-3 px-5">
//           <Row>
//             <Col lg={12}>
//               <div className="text-center">
//                 <i
//                   className="mdi mdi-alert-circle-outline"
//                   style={{ fontSize: "9em", color: "orange" }}
//                 />
//              {  data ?  <h2>Confirm Want to {value(data)} ?</h2> : ""}
//                {lockData ? <h2>Confirm Want to {asd(lockData)} ?</h2> : ""}
//               </div>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               <div className="text-center mt-3">
//                 <button
//                   type="button"
//                   className="btn btn-success btn-lg ms-2"
//                   onClick={onDeleteClick}
//                 >
//                   Yes
//                 </button>
//                 <button
//                   type="button"
//                   className="btn btn-danger btn-lg ms-2"
//                   onClick={onCloseClick}
//                 >
//                   No
//                 </button>
//               </div>
//             </Col>
//           </Row>
//         </ModalBody>
//       </Modal>
//     )
//   }
  

const StatusModal = ({ show, onDeleteClick, onCloseClick, data, lockData }) => {
    const value = (data) => {
      return data ? "ACTIVE" : "INACTIVE";
    }
  
    const asd = (lockData) => {
      return lockData ? "Lock" : "Unlock";
    }
  
    return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i
                  className="mdi mdi-alert-circle-outline"
                  style={{ fontSize: "9em", color: "orange" }}
                />
                <h2>Confirm Want to {value(data)} ?</h2>
                {/* {data && <h2>Confirm Want to {value(data)} ?</h2>}
                {lockData && <h2>Confirm Want to {asd(lockData)} ?</h2>} */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mt-3">
                <button
                  type="button"
                  className="btn btn-success btn-lg ms-2"
                  onClick={onDeleteClick}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-lg ms-2"
                  onClick={onCloseClick}
                >
                  No
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }
  

StatusModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any
}

export default StatusModal
