import {
   POST_OPPORTUNITY_CLONE_VERSION,
   POST_OPPORTUNITY_CLONE_VERSION_SUCCESS,
   POST_OPPORTUNITY_CLONE_VERSION_FAIL
 } from "./actionTypes";
 
 
 //////////////////////////////
 // Get By id OPPORTUNITIES  //
 //////////////////////////////
 
 export const postOpportunityCloneVersion  = (payload) => ({
     type: POST_OPPORTUNITY_CLONE_VERSION,
     payload: payload
 });
 
 export const postOpportunityCloneVersionSuccess = businessUnit => ({
     type: POST_OPPORTUNITY_CLONE_VERSION_SUCCESS,
     payload: businessUnit,
 });
 
 export const postOpportunityCloneVersionFail = error => ({
     type: POST_OPPORTUNITY_CLONE_VERSION_FAIL,
     payload: error,
 });
 
 
 
 
 