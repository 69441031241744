import {
    GET_BUSINESSES,
    GET_BUSINESSES_SUCCESS,
    GET_BUSINESSES_FAIL,

    GET_BUSINESS,
    GET_BUSINESS_SUCCESS,
    GET_BUSINESS_FAIL,

    POST_BUSINESS,
    POST_BUSINESS_SUCCESS,
    POST_BUSINESS_FAIL,

    DELETE_BUSINESS,
    DELETE_BUSINESS_SUCCESS,
    DELETE_BUSINESS_FAIL,

    UPDATE_BUSINESS,
    UPDATE_BUSINESS_SUCCESS,
    UPDATE_BUSINESS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    businesses: null,
    business: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const businessReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BUSINESSES:
            return {
                ...state,
                loading: true,
                businesses: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESSES_SUCCESS:
            return {
                ...state,
                loading: false,
                businesses: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESSES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_BUSINESS:
            return {
                ...state,
                loading: true,
                businesses: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_BUSINESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_BUSINESS:
            return {
                ...state,
                loading: true,
                businesses: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                business: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_BUSINESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESS:
            return {
                ...state,
                loading: true,
                business: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_BUSINESS:
            return {
                ...state,
                loading: true,
                // sysUsers: null,
                business: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_BUSINESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default businessReducer;
