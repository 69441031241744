import React, { useMemo, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"
import axios from 'axios'
import getHeader from 'config/header'
import getFormHeader from 'config/formHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartments, getBusinesses, postCredit, postAccount, geAccountDetail, getTransactionDetail, postTransaction, getAttachment } from 'store/actions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { API_BASE_URL } from 'helpers/url_helper'
import pdfImage from "../../../assets/images/maxims/pdf_image.jpg"
import { toast } from 'react-toastify'

function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

function CreateTransaction() {
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();

    const history = useHistory()
    const query = useQuery();
    const AccId = query.get("AccId");
    const { id } = useParams();
    const BusID = query.get("Busid");

    const dispatch = useDispatch();

    // const [aa, setaa] = useState()
    // useEffect(() => {
    //     const token = sessionStorage.getItem('authUser')
    //     axios.get(API_BASE_URL + "/filestorage/66239bb24a60136897b1de38", getHeader(token))
    //         .then(res => {
    //             console.log(res);
    //             setaa(res.data)
    //         }).catch(err => {
    //             console.log(err);
    //         })
    // }, [])



    //Get account id details
    const appAccountsState = useSelector(state => state.AppAccounts);
    const appTransactionState = useSelector(state => state.AppTransactionReducer);

    const [accountValues, setAccountValues] = useState({})
    const [accountStatus, setAccountStatus] = useState(false)
    const [oracleCreditStatus, setOracleCreditStatus] = useState(false)

    useEffect(() => {
        if (appAccountsState.appAccount !== null) {
            let res = appAccountsState.appAccount;
            setAccountValues(res)
            if (res.is_active === true) {
                setAccountStatus("Active")
            } else if (res.is_active === false) {
                setAccountStatus("Inactive")
            }

            if (res.oracle_credit_status === true) {
                setOracleCreditStatus("Active")
            } else if (res.oracle_credit_status === false) {
                setOracleCreditStatus("Inactive")
            }
        }
        if (appTransactionState.isCreated) {
            backButton()
        }

    }, [appAccountsState, appTransactionState])

    //file attachment
    // const [removeButton, setRemoveButton] = useState(false)
    // const [files, setFiles] = useState([]);
    // const [images, setImages] = useState([])

    // const removeBtnClick = (i) => {
    //     let newImages = images.map(item => ({ ...item }))
    //     let newFiles = files.map(item => ({ ...item }))
    //     newImages.splice(i, 1)
    //     setImages(newImages)
    //     newFiles.splice(i, 1)
    //     setFiles(newFiles)
    // }

    // const handleChange = (e) => {
    //     const chosenFiles = Array.prototype.slice.call(e.target.files)
    //     handleFiles(chosenFiles)
    //     let imageData = e.target.files
    //     const len = imageData.length
    //     for (var i = 0; i < len; i++) {
    //         var imageBlob = imageData[i];
    //         var fileReader = new FileReader()  //1
    //         fileReader.onload = ({ target }) => {  // 3
    //             setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
    //         }
    //         fileReader.readAsDataURL(imageBlob) //2
    //     }
    // }

    // const handleFiles = (inputFile) => {
    //     const filesData = [...files]
    //     inputFile.some(file => {
    //         if (filesData.findIndex((f) => f.name === file.name) === -1) {
    //             filesData.push(file)
    //         }
    //     })
    //     setFiles(filesData)
    // }

    // const getAwsStorageUrl = (file) => {
    //     return new Promise((resolve, reject) => {
    //         const
    //             token = localStorage.getItem('reposeCmsToken')
    //         axios.get("/business/api/v1/storage/signedurlput?image_folder=product_images", getBusinessHeader(token))
    //             .then(async res => {
    //                 let fileKey = res.data.filekey
    //                 let signedURLPut = res.data.signed_url_put
    //                 await uploadImage(file, signedURLPut)
    //                 resolve(fileKey)
    //             }).catch(err => {
    //                 console.log(err);
    //                 reject(err)
    //             })
    //     })
    // }

    // const uploadImage = (file, signedURLPut) => {
    //     return new Promise(async (resolve, reject) => {
    //         await fetch(signedURLPut, {
    //             method: 'PUT',
    //             headers: getImageHeader(),
    //             mode: 'cors',
    //             body: file
    //         })
    //         resolve()
    //     })
    // }

    // const getImageHeader = () => {
    //     // { 'Content-Type': 'image/png' }
    //     const myHeaders = new Headers()
    //     myHeaders.append('Access-Control-Allow-Origin', '*')
    //     return myHeaders
    // }

    // const getImages = (list) => {
    //     let data = []
    //     for (let i in list) {
    //         data.push({ seq_no: parseInt(i) + 1, image_filekey: list[i] })
    //     }
    //     return data
    // }

    // const openImage = (e) => {
    //     let data = e.target.currentSrc;
    //     let w = window.open('about:blank');
    //     let image = new Image();
    //     image.src = data;
    //     setTimeout(function () {
    //         w.document.write(image.outerHTML);
    //     }, 0);
    // }

    // const onDragEnd = (result) => {
    //     if (!result.destination) {
    //         return;
    //     }
    //     const startIndex = result.source.index;
    //     const endIndex = result.destination.index;
    //     const newItems = Array.from(images);
    //     const newFiles = Array.from(files)
    //     const [removed] = newItems.splice(startIndex, 1);
    //     const [removedFiles] = newFiles.splice(startIndex, 1)
    //     newItems.splice(endIndex, 0, removed);
    //     newFiles.splice(endIndex, 0, removedFiles)
    //     newItems.forEach((item, index) => {
    //         item.seq_no = index + 1;
    //     });
    //     setImages(newItems)
    //     setFiles(newFiles)
    // }



    const [transactionType, setTransactionType] = useState('');

    const handleTransactionTypeChange = (event) => {
        const selectedType = event.target.value;
        setTransactionType(selectedType);

        setTransactionReadOnly(false)

        // You can set the corresponding default value for app_transaction_value here
        switch (selectedType) {
            case 'DEPOSIT':
                setValue('app_transaction_value', "+");
                break;
            case 'CREDIT':
                setValue('app_transaction_value', "+");
                break;
            case 'DEBIT':
                setValue('app_transaction_value', "-");
                break;
            default:
                setValue('app_transaction_value', '');
                break;
        }
    };

    const [transactionReadOnly, setTransactionReadOnly] = useState(true)

    const backButton = () => {
        const { AccountId, Busid, skip, limit } = JSON.parse(sessionStorage.getItem("viewaccountListQuery"))
        history.push(`/viewaccount/${AccountId}?Busid=${Busid}&&skip=${skip}&&limit=${limit}`)
    }


    // const handleChange = (e) => {
    //     const selectedFiles = e.target.files;
    //     setFiles([...files, ...selectedFiles]);

    //     let imageData = e.target.files
    //     const len = imageData.length
    //     for (var i = 0; i < len; i++) {
    //         var imageBlob = imageData[i];
    //         var fileReader = new FileReader()  //1
    //         fileReader.onload = ({ target }) => {  // 3
    //             setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
    //         }
    //         fileReader.readAsDataURL(imageBlob) //2
    //     }
    // }

    // const handleChange = (e) => {
    //     const selectedFiles = Array.from(e.target.files);
    //     setFiles([...files, ...selectedFiles]);

    //     const imageData = e.target.files;
    //     const len = imageData.length;

    //     for (let i = 0; i < len; i++) {
    //         const imageBlob = imageData[i];
    //         const fileReader = new FileReader();
    //         fileReader.onload = ({ target }) => {
    //             setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]));
    //         };
    //         fileReader.readAsDataURL(imageBlob);
    //     }
    // };


    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([])
    const [removeButton, setRemoveButton] = useState(false)
    const [attachmentSize, setAttachmentSize] = useState("");
    const fileInputRef = useRef(null);

    const MAX_FILE_SIZE = attachmentSize.maxSize * 1024 * 1024;
    const MIN_FILE_SIZE = attachmentSize.minSize * 1024 * 1024;


    const handleChange = (e) => {

        const selectedFiles = Array.from(e.target.files);
        const validFiles = selectedFiles.filter(file => {
            return file.size >= MIN_FILE_SIZE && file.size <= MAX_FILE_SIZE;
        });

        const validFilessss = selectedFiles.filter(file => {
            if (file.size < MIN_FILE_SIZE || file.size > MAX_FILE_SIZE) {
                alert(`File Size should be ${MIN_FILE_SIZE / (1024 * 1024)} - ${MAX_FILE_SIZE / (1024 * 1024)} MB and were not added.`);
                return false;
            }
            return true;
        });

        setFiles([...files, ...validFiles]);

        validFiles.forEach((file, index) => {
            const fileReader = new FileReader();
            fileReader.onload = ({ target }) => {
                setImages(prevState => ([...prevState, { seq_no: index, imgSrc: target.result }]));
            };
            fileReader.readAsDataURL(file);
        });

        if (validFiles.length === 0) {
            fileInputRef.current.value = '';
        }
    };

    const removeBtnClick = (i) => {
        let newImages = images.map(item => item)
        let newFiles = files.map(item => item)
        newImages.splice(i, 1)
        setImages(newImages)
        newFiles.splice(i, 1)
        setFiles(newFiles)
        if (newFiles.length === 0) {
            fileInputRef.current.value = '';
        }
    }

    const openImage = (image) => {
        let data = image.imgSrc;
        let w = window.open('about:blank');
        let iframe = document.createElement('iframe');
        iframe.src = data;
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        w.document.body.style.margin = '0';
        w.document.body.style.height = '100vh';
        w.document.body.appendChild(iframe);
    }

    const CreateTransaction = (transactionValue) => {

        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`attach_file`, file);
        });

        const token = sessionStorage.getItem('authUser');
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        };
        
        if (files.length > 0) {
            axios.post(API_BASE_URL + "/filestorage", formData, config)
                .then(res => {

                    const datas = {
                        ...transactionValue,
                        app_transaction_value: transactionValue.app_transaction_value,
                        app_transaction_type: transactionValue.app_transaction_type,
                        app_business_id: accountValues.app_business_id,
                        app_account_id: accountValues.app_account_id,
                        app_department_id: accountValues.app_department_id,
                        attachment_file: res.data.files_uploaded

                    }

                    dispatch(postTransaction(datas))
                    setFiles([])

                }).catch(err => {
                    console.log(err);
                });
        }else {
            toast.error("please upload one or more files")
        }
    }

    const attachmentstate = useSelector(state => state.AttachmentReducer);

    useEffect(() => {
        dispatch(getAttachment())
    }, [])

    const [attachmentarray, setAttachmentarray] = useState([]);

    useEffect(() => {
        if (attachmentstate.attachment !== null) {
            setAttachmentarray(attachmentstate?.attachment?.extension);
            setAttachmentSize(attachmentstate?.attachment)
            // console.log("attachmentstate", attachmentstate?.attachment?.extension);
        }
    }, [attachmentstate])

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Transaction" breadcrumbItem=" Account" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(CreateTransaction)}>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Account ID </Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues.app_account_keyid} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountStatus} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Company</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues.app_business_name} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Site Name</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues.app_department_name} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Credit Limit</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + " " + parseFloat(accountValues.credit_limit).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Account Balance</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={" $ " + " " + parseFloat(accountValues.app_account_balance).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString()} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Oracle Site ID</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues.oracle_site_ID} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Oracle Status</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={oracleCreditStatus} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                                                Transaction Type<span className='text-danger'>*</span>
                                            </Label>
                                            <Col lg="4" md="6">
                                                <select className="form-select d-inline" {...register('app_transaction_type', { required: true })} onChange={handleTransactionTypeChange} >
                                                    <option hidden value="">Select Transaction Type</option>
                                                    <option value="DEPOSIT">Deposit</option>
                                                    <option value="CREDIT">Credit</option>
                                                    <option value="DEBIT">Debit</option>
                                                </select>
                                                {errors.app_transaction_type && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                                                Transaction Value<span className='text-danger'>*</span>
                                            </Label>
                                            <Col lg="4" md="6">
                                                <input type="text" readOnly={transactionReadOnly} className='form form-control' {...register('app_transaction_value', { required: true })} />
                                                {errors.app_transaction_value && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    {/* accept='application/pdf, image/png, image/jpeg'
                                'image/png,image/jpeg, .pdf, .xlsx, .ods'
                                    'application/vnd.ms-excel' */}
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment File<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input className='form form-control' accept={attachmentarray} 
                                                maxLength={1} multiple type="file" onChange={handleChange}
                                                ref={fileInputRef}
                                                />
                                                <div className="d-flex flex-wrap">{images?.map((image, i) => (
                                                    <div key={i} className="thumbSpan thumbContainer"  >
                                                        <img className='thumbImage ms-3' id="fileImg" src={image?.imgSrc} alt="pdf"
                                                            onMouseEnter={() => setRemoveButton(true)}
                                                            onClick={() => openImage(image)}
                                                            onError={(e) => { e.target.src = pdfImage; }}
                                                            style={{
                                                                border: "1px solid #ddd",
                                                                borderRadius: "4px",
                                                                padding: "5px",
                                                                width: "125px",
                                                                height: "125px"
                                                            }}
                                                        />
                                                        {removeButton &&
                                                            <button type='button'
                                                                onClick={() => removeBtnClick(i)}
                                                                className='thumbClose btn btn-danger btn-sm'>
                                                                X
                                                            </button>
                                                        }
                                                    </div>
                                                ))}
                                                </div>
                                                {errors.app_payment_file && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment Notes<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input type="text" className='form form-control'  {...register('app_payment_notes', { required: true })} />
                                                {errors.app_payment_notes && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button color='success' id='createCreditBtn' type='submit' className='m-2' >
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateTransaction




// const handleChange = (e) => {
//     const selectedFiles = e.target.files;
//     setFiles([...files, ...selectedFiles]);

//     const len = selectedFiles.length;
//     for (let i = 0; i < len; i++) {
//         const file = selectedFiles[i];
//         const reader = new FileReader();

//         // Check if the file is an image
//         if (file.type.startsWith('image/')) {
//             reader.onload = ({ target }) => {
//                 setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]));
//             };
//             reader.readAsDataURL(file);
//         } else if (file.type === 'application/pdf') { // Check if the file is a PDF
//             reader.onload = ({ target }) => {
//                 // Add PDF preview logic here
//                 const pdfPreview = target.result; // Assuming you'll set this as the source for a PDF preview
//                 // Update state or display the PDF preview
//             };
//             reader.readAsArrayBuffer(file);
//         } else {
//             // Handle other file types if needed
//         }
//     }
// };


{/* <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment File<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="images" direction="horizontal">
                                                        {(provided) => (
                                                            <div className="thumbContainer" {...provided.droppableProps} ref={provided.innerRef}>
                                                                {images.map((image, i) => (
                                                                    <Draggable key={i} draggableId={i.toString()} index={i}>
                                                                        {(provided) => (
                                                                            <div key={i} className="thumbSpan" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                                <img className='thumbImage ms-3' id="fileImg" src={image.imgSrc}
                                                                                    onMouseEnter={() => setRemoveButton(true)}
                                                                                    // onClick={(e) => openImage(e)}
                                                                                    style={{
                                                                                        border: "1px solid #ddd",
                                                                                        borderRadius: "4px",
                                                                                        padding: "5px",
                                                                                        width: "75px",
                                                                                        height: "75px"
                                                                                    }}
                                                                                />
                                                                                {removeButton &&
                                                                                    <button type='button'
                                                                                        onClick={() => removeBtnClick(i)}
                                                                                        className='thumbClose btn btn-danger btn-sm'>
                                                                                        X
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                <input className='form form-control' multiple type="file" accept="image" onChange={handleChange} />
                                                {errors.app_payment_file && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row> */}