import axios from 'axios';
import getHeader from 'config/header';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge } from 'reactstrap';
import login from 'store/auth/login/reducer';

const InvoiceID = (cell) => {
     return cell.value ? cell.value: '';
  
};
const Company = (cell) => {
    return cell.value ? cell.value : '';
 
};


  //     let data = cell.value;
    //     console.log("dataaaaa",data.invoice_keyid);
    // // let companyname = data.map((item) => item.app_business_name);
    //  return companyname ? companyname : "";

   // let data = cell.value;
    // let companyname = data.map((item) => item.app_business_name);
    // return companyname ? companyname : "";

const SiteId = (cell) => {
    return cell.value ? cell.value : '';
};

const SiteName = (cell) => {
    return cell.value ? cell.value: '';
};

const BusinessUnit = (cell) => {
    const floatValue = parseFloat(cell.value).toFixed(1);
    return (<span className='ListPageFloatRight'> {floatValue ? " $ " + floatValue : ''} </span>);
};

const OpportunityId = (cell) => {
    return cell.value ? cell.value  : '';
};

const Total = (cell) => {
    let floatValue = parseFloat(cell.value);
    if (isNaN(floatValue)) return null;
    floatValue = floatValue.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toLocaleString();
    return (
        <span className='ListPageFloatRight'> {"$" + " " + floatValue} </span>
    );
};

const Status = (cell) => {
    return (
        // <Badge className={"font-size-12 badge-soft-" + (cell.value ? "success" : "danger")}>
        //     {/* {cell.value ? "ACTIVE" : "INACTIVE"} */}
        //     {cell.value ? "" : ""}
        // </Badge>

        <Badge
            className={"font-size-12 badge-soft-" +
                (cell.value === "Approved" ? "success" : "danger" && cell.value === "Pending" ? "warning" : "danger")}
            pill
        >
            {cell.value}
        </Badge>

        //      <Badge
        //      className={"font-size-12 badge-soft-" +
        //          (cell.value === "Approved" ? "success" : "danger" && cell.value === "Pending" ? "warning" : "danger")}
        //      pill
        //  >
        //      {cell.value}
        //  </Badge>

    )
};

const TransactionValue = (cell) => {

    const floatValue = parseFloat(cell.value).toFixed(1);

    if (!isNaN(floatValue)) {
        const displayValue = floatValue >= 0 ? `+${floatValue}` : floatValue;
        return (
            <span className=''>{displayValue ? `$ ${displayValue}` : ''}</span>
        );
    }
    // } else {
    //     // Handle invalid or non-numeric values
    //     return (
    //         <span className=''>Invalid Value</span>
    //     );
    // }
};

export {
    InvoiceID,
    Company,
    SiteId,
    SiteName,
    BusinessUnit,
    OpportunityId,
    Total,
    Status,
};
