import {
    GET_OTP_TIMEOUT,
    GET_OTP_TIMEOUT_SUCCESS,
    GET_OTP_TIMEOUT_FAIL,

    POST_OTP_TIMEOUT,
    POST_OTP_TIMEOUT_SUCCESS,
    POST_OTP_TIMEOUT_FAIL,

    UPDATE_OTP_TIMEOUT,
    UPDATE_OTP_TIMEOUT_SUCCESS,
    UPDATE_OTP_TIMEOUT_FAIL,
} from "./actionTypes";



//////////////////////////////
// Get By id OTP_TIMEOUT  //
//////////////////////////////

export const getOtpTimeout = (payload) => ({
    type: GET_OTP_TIMEOUT,
    payload: payload
});

export const getOtpTimeoutSuccess = otpTimeOut => ({
    type: GET_OTP_TIMEOUT_SUCCESS,
    payload: otpTimeOut,
});

export const getOtpTimeoutFail = error => ({
    type: GET_OTP_TIMEOUT_FAIL,
    payload: error,
});

/////////////////////////////
// Post sys OTP_TIMEOUTs ///
///////////////////////////

export const postOtpTimeout = (payload) => ({
    type: POST_OTP_TIMEOUT,
    payload: payload
});

export const postOtpTimeoutSuccess = otpTimeOut => ({
    type: POST_OTP_TIMEOUT_SUCCESS,
    payload: otpTimeOut,
});

export const postOtpTimeoutFail = error => ({
    type: POST_OTP_TIMEOUT_FAIL,
    payload: error,
});



//////////////////////////////
// Update sys OTP_TIMEOUTs  //
/////////////////////////////

export const updateOtpTimeout = (payload) => ({
    type: UPDATE_OTP_TIMEOUT,
    payload: payload
});

export const updateOtpTimeoutSuccess = payload => ({
    type: UPDATE_OTP_TIMEOUT_SUCCESS,
    payload: payload
});

export const updateOtpTimeoutFail = error => ({
    type: UPDATE_OTP_TIMEOUT_FAIL,
    payload: error,
});


