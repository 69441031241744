/* EVENTS */

// Get business Unit
export const GET_APP_BUSINESS_UNIT = "GET_APP_BUSINESS_UNIT";
export const GET_APP_BUSINESS_UNIT_SUCCESS = "GET_APP_BUSINESS_UNIT_SUCCESS";
export const GET_APP_BUSINESS_UNIT_FAIL = "GET_APP_BUSINESS_UNIT_FAIL"

// Post Business Unit
export const POST_APP_BUSINESS_UNIT = "POST_APP_BUSINESS_UNIT";
export const POST_APP_BUSINESS_UNIT_SUCCESS = "POST_APP_BUSINESS_UNIT_SUCCESS";
export const POST_APP_BUSINESS_UNIT_FAIL = "POST_APP_BUSINESS_UNIT_FAIL"

// Delete Business Unit
export const DELETE_APP_BUSINESS_UNIT = "DELETE_APP_BUSINESS_UNIT";
export const DELETE_APP_BUSINESS_UNIT_SUCCESS = "DELETE_APP_BUSINESS_UNIT_SUCCESS";
export const DELETE_APP_BUSINESS_UNIT_FAIL = "DELETE_APP_BUSINESS_UNIT_FAIL"

// Get Business Unit by id
export const GET_APP_BUSINESS_UNIT_DETAIL = "GET_APP_BUSINESS_UNIT_DETAIL";
export const GET_APP_BUSINESS_UNIT_DETAIL_SUCCESS = "GET_APP_BUSINESS_UNIT_DETAIL_SUCCESS";
export const GET_APP_BUSINESS_UNIT_DETAIL_FAIL = "GET_APP_BUSINESS_UNIT_DETAIL_FAIL";

// Update Business Unit
export const UPDATE_APP_BUSINESS_UNIT = "UPDATE_APP_BUSINESS_UNIT";
export const UPDATE_APP_BUSINESS_UNIT_SUCCESS = "UPDATE_APP_BUSINESS_UNIT_SUCCESS";
export const UPDATE_APP_BUSINESS_UNIT_FAIL = "UPDATE_APP_BUSINESS_UNIT_FAIL";