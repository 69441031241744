import {
    GET_PASSWORD_POLICYS,
    GET_PASSWORD_POLICYS_SUCCESS,
    GET_PASSWORD_POLICYS_FAIL,
   
    POST_PASSWORD_POLICY,
    POST_PASSWORD_POLICY_SUCCESS,
    POST_PASSWORD_POLICY_FAIL,

    UPDATE_PASSWORD_POLICY,
    UPDATE_PASSWORD_POLICY_SUCCESS,
    UPDATE_PASSWORD_POLICY_FAIL,
} from "./actionTypes";

/////////////////////////
//  Get PasswordPolicy //
/////////////////////////


export const getPassWordPolicys = (payload) => ({
    type: GET_PASSWORD_POLICYS,
    payload: payload,
})

export const getPassWordPolicysSuccess = PasswordPolicys => ({
    type: GET_PASSWORD_POLICYS_SUCCESS,
    payload: PasswordPolicys
})

export const getPassWordPolicysFail = error => ({
    type: GET_PASSWORD_POLICYS_FAIL,
    payload: error
})

//////////////////////////
// Post PasswordPolicy ///
//////////////////////////

export const postPassWordPolicy = (payload) => ({
    type: POST_PASSWORD_POLICY,
    payload: payload
});

export const postPassWordPolicySuccess = PasswordPolicy => ({
    type: POST_PASSWORD_POLICY_SUCCESS,
    payload: PasswordPolicy,
});

export const postPassWordPolicyFail = error => ({
    type: POST_PASSWORD_POLICY_FAIL,
    payload: error,
});

///////////////////////////
// Update PasswordPolicy  //
///////////////////////////

export const updatePassWordPolicy = (payload) => ({
    type: UPDATE_PASSWORD_POLICY,
    payload: payload
});

export const updatePassWordPolicySuccess = payload => ({
    type: UPDATE_PASSWORD_POLICY_SUCCESS,
    payload: payload
});

export const updatePassWordPolicyFail = error => ({
    type: UPDATE_PASSWORD_POLICY_FAIL,
    payload: error,
});


