export const GET_OWNERS = "GET_OWNERS"
export const GET_OWNERS_SUCCESS = "GET_OWNERS_SUCCESS"
export const GET_OWNERS_FAIL = "GET_OWNERS_FAIL"

export const POST_OWNER = "POST_OWNER"
export const POST_OWNER_SUCCESS = "POST_OWNER_SUCCESS"
export const POST_OWNER_FAIL = "POST_OWNER_FAIL"

export const DELETE_OWNER = "DELETE_OWNER"
export const DELETE_OWNER_SUCCESS = "DELETE_OWNER_SUCCESS"
export const DELETE_OWNER_FAIL = "DELETE_OWNER_FAIL"

export const GET_OWNER = "GET_OWNER"
export const GET_OWNER_SUCCESS = "GET_OWNER_SUCCESS"
export const GET_OWNER_FAIL = "GET_OWNER_FAIL"

export const UPDATE_OWNER = "UPDATE_OWNER"
export const UPDATE_OWNER_SUCCESS = "UPDATE_OWNER_SUCCESS"
export const UPDATE_OWNER_FAIL = "UPDATE_OWNER_FAIL"