import {
  GET_APP_COUPON,
  GET_APP_COUPON_FAIL,
  GET_APP_COUPON_SUCCESS,


  GET_APP_COUPON_STATUS,
  GET_APP_COUPON_STATUS_SUCCESS,
  GET_APP_COUPON_STATUS_FAIL,

  GET_APP_COUPON_STATUS_BY_INVOICE_ID,
  GET_APP_COUPON_STATUS_BY_INVOICE_ID_SUCCESS,
  GET_APP_COUPON_STATUS_BY_INVOICE_ID_FAIL,
  
    GET_APP_REQUEST_COUPON_STATUS,
  GET_APP_REQUEST_COUPON_STATUS_SUCCESS,
  GET_APP_REQUEST_COUPON_STATUS_FAIL,



  POST_APP_COUPON,
  POST_APP_COUPON_FAIL,
  POST_APP_COUPON_SUCCESS,

} from "./actionTypes";

const INIT_STATE = {
  createcoupon: null,
  requestcouponstatus: null,
  getcoupon: null,
  getcouponstatus: null,
  getcouponstatusbyinvoiceid: null,
  loading: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  error: {},
};

const CouponReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APP_COUPON:
      return {
        ...state,
        loading: true,
        getcoupon: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        getcoupon: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_STATUS:
      return {
        ...state,
        loading: true,
        getcouponstatus: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        getcouponstatus: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_STATUS_BY_INVOICE_ID:
      return {
        ...state,
        loading: true,
        getcouponstatusbyinvoiceid: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_STATUS_BY_INVOICE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getcouponstatusbyinvoiceid: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case GET_APP_COUPON_STATUS_BY_INVOICE_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
       case GET_APP_REQUEST_COUPON_STATUS:
        return {
          ...state,
          loading: true,
          requestcouponstatus: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_REQUEST_COUPON_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          requestcouponstatus: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_REQUEST_COUPON_STATUS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
    case POST_APP_COUPON:
      return {
        ...state,
        loading: true,
        createcoupon: null,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    case POST_APP_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        createcoupon: action.payload,
        isCreated: true,
        isDeleted: false,
        isUpdated: false
      };
    case POST_APP_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isCreated: false,
        isDeleted: false,
        isUpdated: false
      };
    default:
      return state;
  }
};

export default CouponReducer;


