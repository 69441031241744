import PropTypes from 'prop-types'
import React from "react"
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Col, FormGroup, Label, Modal, ModalBody, Row } from "reactstrap"

const ProposalCloneVersionModal = ({ show,onProposalCloneConfirmClick, onCloseClick, name, data,title }) => {

  const location = useLocation()

  const parseFloatSymbol = (cell) => {
    const floatValue = parseFloat(cell).toFixed(1);

    if (!isNaN(floatValue)) {
      const displayValue = floatValue >= 0 ? `+ ${floatValue}` : floatValue;
      return displayValue;
    }
  }
  return (
    <Modal isOpen={show} backdrop="static" centered={true} size='lg'>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              {/* <h3 style={{ color: "#556EE6" }}> <span style={{ textTransform: "capitalize" }}>{name}</span></h3> */}
              <p className='font-size-18'>Do you want to Clone  this Proposal? </p>


              {/* {location.pathname.substring(0,12) === "/viewaccount" && */}
                 <>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Proposal ID </Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.proposal_keyid} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Proposal Version</Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={data.proposal_version + " " + data.proposal_status} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Transaction Type </Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={name.app_transaction_type} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup row>
                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Transaction Value</Label>
                        <Col lg="7" md="6">
                          <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={parseFloatSymbol(name.app_transaction_value)} />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>  */}
                </> 
              {/* } */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success ms-2"
                onClick={onProposalCloneConfirmClick}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ProposalCloneVersionModal.propTypes = {
  onCloseClick: PropTypes.func,
  onApproveClick: PropTypes.func,
  show: PropTypes.any
}

export default ProposalCloneVersionModal