import React from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"
import axios from 'axios'
import getHeader from 'config/header'
import getFormHeader from 'config/formHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartments, getBusinesses, postCredit } from 'store/actions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function CreateCredit() {
    const history = useHistory()
    const dispatch = useDispatch()

    const { register, handleSubmit, formState: { errors }, getValues } = useForm();

    const businessState = useSelector(state => state.businessReducer);
    const creditsState = useSelector(state => state.creditsReducer);
    const departmentState = useSelector(state => state.departmentReducer);

    const [siteList, setSiteList] = useState([]);
    const [businessList, setBusinessList] = useState([]);

    //company name list
    useEffect(() => {
        dispatch(getBusinesses({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));
    }, []);
    useEffect(() => {
        if (businessState.businesses !== null) {
            setBusinessList(businessState.businesses.result)
        }
    }, [businessState]);

    // company site list
    const getBusinessId = (e) => {
        const companyID = e.target.value
        dispatch(getDepartments({ companyID, filter: { "is_active": true }, limit: 0, skip: 0 }));
    };
    useEffect(() => {
        if (departmentState.departments !== null) {
            setSiteList(departmentState.departments.result);
        }
    }, [departmentState]);


 

    //uploading
    // const [file, setFile] = useState('')
    // const [imagePreview, setImagePreview] = useState(false)
    // $('#fileInp').change((input) => {
    //     setImagePreview(true)
    //     $('#fileImg').css('display', 'block')
    //     if (input.target.files && input.target.files[0]) {
    //         var fileReader = new FileReader()
    //         fileReader.onload = ({ target }) => {
    //             $('#fileImg').attr('src', target.result)
    //         }
    //         fileReader.readAsDataURL(input.target.files[0])
    //         let bodyFormData = new FormData()
    //         bodyFormData.append('attachment', input.target.files[0])
    //         setFile(bodyFormData)
    //     }
    // })

    // const openImage = (e) => {
    //     let data = e.target.currentSrc;
    //     let w = window.open('about:blank');
    //     let image = new Image();
    //     image.src = data;
    //     setTimeout(function () {
    //         w.document.write(image.outerHTML);
    //     }, 0);
    // }

    // const uploadFile = (file) => {
    //     return new Promise((resolve, reject) => {
    //         let fileLocation = ""
    //         const token = sessionStorage.getItem('authUser')
    //         axios.post("/api/v2/upload", file, getFormHeader(token))
    //             .then(res => {
    //                 fileLocation = res.data.Location
    //                 resolve(fileLocation)
    //                 console.log(fileLocation);
    //             })
    //             .catch(err => {
    //                 reject(err)
    //             })
    //     })
    // }

    useEffect(() => {
        if (creditsState.isCreated) {
            history.goBack()
        }
    }, [creditsState])

 


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const newItems = Array.from(images);
        const newFiles = Array.from(files)
        const [removed] = newItems.splice(startIndex, 1);
        const [removedFiles] = newFiles.splice(startIndex, 1)
        newItems.splice(endIndex, 0, removed);
        newFiles.splice(endIndex, 0, removedFiles)
        newItems.forEach((item, index) => {
            item.seq_no = index + 1;
        });
        setImages(newItems)
        setFiles(newFiles)
    }

    // new upload image code 
    const [removeButton, setRemoveButton] = useState(false)
    const [imageState, setImageState] = useState(false)
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([])

    const removeBtnClick = (i) => {
        let newImages = images.map(item => ({ ...item }))
        let newFiles = files.map(item => ({ ...item }))
        newImages.splice(i, 1)
        setImages(newImages)
        newFiles.splice(i, 1)
        setFiles(newFiles)
    }

    const handleChange = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleFiles(chosenFiles)
        let imageData = e.target.files
        const len = imageData.length
        for (var i = 0; i < len; i++) {
            var imageBlob = imageData[i];
            var fileReader = new FileReader()  //1
            fileReader.onload = ({ target }) => {  // 3
                setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
            }
            fileReader.readAsDataURL(imageBlob) //2
            setImageState(true)
        }
    }

    const handleFiles = (inputFile) => {
        const filesData = [...files]
        inputFile.some(file => {
            if (filesData.findIndex((f) => f.name === file.name) === -1) {
                filesData.push(file)
            }
        })
        setFiles(filesData)
    }

    const getAwsStorageUrl = (file) => {
        return new Promise((resolve, reject) => {
            const token = sessionStorage.getItem('authUser')
            axios.get("/api/v2/storage/signedurlput?image_folder=credit_images", getHeader(token))
                .then(async res => {
                    let fileKey = res.data.filekey
                    let signedURLPut = res.data.signed_url_put
                    await uploadImage(file, signedURLPut)
                    resolve(fileKey)
                }).catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    const uploadImage = (file, signedURLPut) => {
        return new Promise(async (resolve, reject) => {
            await fetch(signedURLPut, {
                method: 'PUT',
                headers: getImageHeader(),
                mode: 'cors',
                body: file
            })
            resolve()
        })
    }

    const getImages = (list) => {
        let data = []
        for (let i in list) {
            data.push({ seq_no: parseInt(i) + 1, image_filekey: list[i] })
        }
        return data
    }

    const openImage = (e) => {
        let data = e.target.currentSrc;
        let w = window.open('about:blank');
        let image = new Image();
        image.src = data;
        setTimeout(function () {
            w.document.write(image.outerHTML);
        }, 0);
    }

    const getImageHeader = () => {
        // { 'Content-Type': 'image/png' }
        const myHeaders = new Headers()
        myHeaders.append('Access-Control-Allow-Origin', '*')
        return myHeaders
    }


    // post credit data
    const CreateCredit = async (data) => {
        // let transaction_attachment = ""
        // if (file) {
        //     transaction_attachment = await uploadFile(file)
        // }
        // let value = {
        //     ...data,
        //     transaction_attachment
        // }

        let fileKeyList = []
        try {
            if (files) {
                // let len = files.length
                // console.log("len", files);
                // for (let i = 0; i < len; i++) {
                //     console.log(files[i]);
                //     let key = await getAwsStorageUrl(files[i])
                //     fileKeyList.push(key)
                // }
            }

            const values = {
                ...data,
                // image_filekey: fileKeyList[0],
                // image: {
                //     image_filekey: fileKeyList[0]
                // },

                // images: getImages(fileKeyList),
            }
            // console.log("Create Credit", values);
            $('#createCreditBtn').attr("disabled", true);
            dispatch(postCredit(values));

        } catch (error) {
            console.log(error);
        }

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Create Credit" breadcrumbItem="Credit Account" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(CreateCredit)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Company Name <span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <select onInput={(e) => { getBusinessId(e) }} defaultValue="" id="app_business_id" className={`form-select d-inline ${errors.app_business_id && 'is-invalid'}`} aria-label="Default select example" {...register("app_business_id", { required: true })}>
                                                    <option disabled hidden value="">Select the Company Name</option>
                                                    {businessList.map((business) => (
                                                        <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_business_id && (<p style={{ color: "red" }}>This Field is required</p>)}
                                            </Col>
                                        </FormGroup>
                                    </Row>

                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">
                                                Company Site <span className='text-danger'>*</span>{" "}</Label>
                                            <Col lg="4" md="6">
                                                <select defaultValue="" id="app_department_id" className={`form-select d-inline ${errors.app_department_id && 'is-invalid'}`} aria-label="Default select example" {...register("app_department_id", { required: true })}>
                                                    <option disabled hidden value="">Select the Company Site</option>
                                                    {siteList.map((site) => (
                                                        <option key={site.app_department_id} value={site.app_department_id}>{site.app_department_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_department_id && (<p style={{ color: "red" }}>This Field is required</p>)}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Oracle Site ID  <span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input type="text" className={`form-select d-inline ${errors.oracle_site_ID && 'is-invalid'}`} {...register('oracle_site_ID', { required: true })} />
                                                {errors.oracle_site_ID && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Credit Type <span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <select defaultValue="" id="app_business_unit" className={` form form-control form-select d-inline ${errors.app_credit_type && 'is-invalid'}`} aria-label="Default select example" {...register("app_credit_type", { required: true })}>
                                                    <option disabled hidden value="">Select the Credit Type</option>
                                                    <option value="deposit">Deposit</option>
                                                    <option value="adjustment (+)">Adjustment (+)</option>
                                                    <option value="adjustment (-)">Adjustment (-)</option>
                                                </select>
                                                {errors.app_credit_type && (<p style={{ color: "red" }}>This Field is required</p>)}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end"> Value <span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input type="Number" className={` form form-control form-select d-inline ${errors.credit_value && 'is-invalid'}`} {...register('credit_value', { required: true })} />
                                                {errors.credit_value && (<p style={{ color: "red" }}>This Field is required</p>)}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    {/* <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Payment Attachment <span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input id="fileInp" type="file" className={` form form-control form-select d-inline ${errors.payment_file && 'is-invalid'}`} />
                                                {errors.payment_file && (<p style={{ color: "red" }}>This Field is required</p>)}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    {imagePreview && <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Image Preview</Label>
                                            <Col lg="4" md="6" className="d-flex justify-content-lg-left justify-content-md-left">
                                                <div className="imagePreviewDiv d-flex justify-content-lg-left justify-content-md-left">
                                                    <img className="imagePreviewImg" onClick={openImage} id="fileImg" onError={() => setImagePreview(false)} width={100} height={100} />
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Row>} */}


                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Payment Attachment</Label>
                                            <Col lg="4" md="6">
                                                <input className='form form-control' multiple type="file" accept="image" onChange={handleChange} />
                                                {/* {errors.payment_notes && <p style={{ color: "red" }}>This Field is required</p>} */}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup row >
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Image</Label>
                                            <Col lg="4" md="6">
                                                <DragDropContext onDragEnd={onDragEnd} >
                                                    <Droppable droppableId="images" direction="horizontal">
                                                        {(provided) => (
                                                            <div className="thumbContainer" {...provided.droppableProps} ref={provided.innerRef}>
                                                                {images.map((image, i) => (

                                                                    <Draggable key={i} draggableId={i.toString()} index={i}>
                                                                        {(provided) => (
                                                                            <div key={i} className="thumbSpan" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>

                                                                                <img className='thumbImage ms-3' id="fileImg" src={image.imgSrc}
                                                                                    onMouseEnter={() => setRemoveButton(true)}
                                                                                    style={{
                                                                                        border: "1px solid #ddd",
                                                                                        borderRadius: "4px",
                                                                                        padding: "5px",
                                                                                        width: "75px",
                                                                                        height: "75px"
                                                                                    }}
                                                                                />
                                                                                {removeButton &&
                                                                                    <button type='button'
                                                                                        onClick={() => removeBtnClick(i)}
                                                                                        className='thumbClose btn btn-danger btn-sm'>
                                                                                        X
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </Col>
                                        </FormGroup>
                                    </Row>


                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Payment Notes</Label>
                                            <Col lg="4" md="6">
                                                <input type="text" className='form form-control' {...register('payment_notes',)} />
                                                {/* {errors.payment_notes && <p style={{ color: "red" }}>This Field is required</p>} */}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="4" md="6">
                                                <select className="form-select d-inline" {...register('status')}>
                                                    {/* <option hidden value="">Select Status</option> */}
                                                    <option value="in progress">In Progress</option>
                                                    <option value="reject">Reject</option>
                                                    <option value="approved">Approved</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button color='success' id='createCreditBtn' type='submit' className='m-2'>
                                                    Create
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateCredit
