import React, { useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"
import axios from 'axios'
import getHeader from 'config/header'
import getFormHeader from 'config/formHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartments, getBusinesses, postCredit, postAccount, geAccountDetail, getTransactionDetail} from 'store/actions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

function UpdateTransaction() {
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
    const dispatch = useDispatch();
    const history = useHistory()
    const query = useQuery();
    const BusID = query.get("Busid")
    const { id } = useParams()


    //----------------------Get account id details----------------------------------------//
    const appAccountsState = useSelector(state => state.AppAccounts);

    const [accountValues, setAccountValues] = useState({})
    const [accountStatus, setAccountStatus] = useState(false)
    const [oracleCreditStatus, setOracleCreditStatus] = useState(false)

    useEffect(() => {
        if (appAccountsState.appAccount !== null) {
            let res = appAccountsState.appAccount;
            setAccountValues(res)
            if (res.is_active === true) {
                setAccountStatus("Active")
            } else if (res.is_active === false) {
                setAccountStatus("Inactive")
            }

            if (res.oracle_credit_status === true) {
                setOracleCreditStatus("Active")
            } else if (res.oracle_credit_status === false) {
                setOracleCreditStatus("Inactive")
            }
        }
    }, [appAccountsState])


//-------------------------update transaction details---------------------------//
    const appTransactionState = useSelector(state => state.AppTransactionReducer);

    useEffect(() => {
        if (id) {
            let transactionID = id
            const data = { transactionID }
            dispatch(getTransactionDetail(data))
        }
    }, [])

    useEffect(() => {

        console.log("updateappTransactionState",appTransactionState);
        if(appTransactionState.appTransaction !== null){
          
            let res = appTransactionState.appTransaction;
            setValue("app_transaction_type",res.app_transaction_type);
            setValue("app_transaction_value",res.app_transaction_value)
            setValue("app_payment_notes",res.app_payment_notes)

        }
    },[appTransactionState])
    
//---------------------file attachment------------------------------------//
    const [removeButton, setRemoveButton] = useState(false)
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([])

    const removeBtnClick = (i) => {
        let newImages = images.map(item => ({ ...item }))
        let newFiles = files.map(item => ({ ...item }))
        newImages.splice(i, 1)
        setImages(newImages)
        newFiles.splice(i, 1)
        setFiles(newFiles)
    }

    const handleChange = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleFiles(chosenFiles)
        let imageData = e.target.files
        const len = imageData.length
        for (var i = 0; i < len; i++) {
            var imageBlob = imageData[i];
            var fileReader = new FileReader()  //1
            fileReader.onload = ({ target }) => {  // 3
                setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
            }
            fileReader.readAsDataURL(imageBlob) //2
        }
    }

    const handleFiles = (inputFile) => {
        const filesData = [...files]
        inputFile.some(file => {
            if (filesData.findIndex((f) => f.name === file.name) === -1) {
                filesData.push(file)
            }
        })
        setFiles(filesData)
    }

    const getAwsStorageUrl = (file) => {
        return new Promise((resolve, reject) => {
            const
                token = localStorage.getItem('reposeCmsToken')
            axios.get("/business/api/v1/storage/signedurlput?image_folder=product_images", getBusinessHeader(token))
                .then(async res => {
                    let fileKey = res.data.filekey
                    let signedURLPut = res.data.signed_url_put
                    await uploadImage(file, signedURLPut)
                    resolve(fileKey)
                }).catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    const uploadImage = (file, signedURLPut) => {
        return new Promise(async (resolve, reject) => {
            await fetch(signedURLPut, {
                method: 'PUT',
                headers: getImageHeader(),
                mode: 'cors',
                body: file
            })
            resolve()
        })
    }

    const getImageHeader = () => {
        // { 'Content-Type': 'image/png' }
        const myHeaders = new Headers()
        myHeaders.append('Access-Control-Allow-Origin', '*')
        return myHeaders
    }

    const getImages = (list) => {
        let data = []
        for (let i in list) {
            data.push({ seq_no: parseInt(i) + 1, image_filekey: list[i] })
        }
        return data
    }

    const openImage = (e) => {
        let data = e.target.currentSrc;
        let w = window.open('about:blank');
        let image = new Image();
        image.src = data;
        setTimeout(function () {
            w.document.write(image.outerHTML);
        }, 0);
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const newItems = Array.from(images);
        const newFiles = Array.from(files)
        const [removed] = newItems.splice(startIndex, 1);
        const [removedFiles] = newFiles.splice(startIndex, 1)
        newItems.splice(endIndex, 0, removed);
        newFiles.splice(endIndex, 0, removedFiles)
        newItems.forEach((item, index) => {
            item.seq_no = index + 1;
        });
        setImages(newItems)
        setFiles(newFiles)
    }

    const CreateTransaction = async(data) => {
        console.log("data", data);

        let fileKeyList = []
        try {
            if (files) {
                let len = files.length
                for (let i = 0; i < len; i++) {
                    let key = await getAwsStorageUrl(files[i])
                    fileKeyList.push(key)
                }
            }
            data = {
                ...data,
                app_account_id: AccId,
                image_filekey: fileKeyList[0],
                image: {
                    image_filekey: fileKeyList[0]
                },

                images: getImages(fileKeyList),
            }
            console.log("data", data);
            // const token = localStorage.getItem('reposeCmsToken')
            // axios.post("/business/api/v1/sales/products", data, getBusinessHeader(token))
            //     .then(res => {
            //         console.log("respost", res);
            //         history.push('/products')
            //         toast.success("Sucessfully Added")
            //     }).catch(err => {
            //         console.log(err);
            //         toast.error("Failed to Add")
            //     })
        } catch (error) {
            console.log(error);
        }
    }

    // const BackButton = () => {
    //     const { AccountId, Busid} = JSON.parse(sessionStorage.getItem("viewaccountListQuery"))
    //     return history.push(`/viewaccount/${AccountId}?Busid=${Busid}`)
    // }

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Update Transaction" breadcrumbItem=" Account" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(CreateTransaction)}>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Account ID <span className='text-danger'>*</span></Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control'value={accountValues.app_account_keyid} />
                                                    
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountStatus} />

                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Company</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues.app_business_name} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Site Name</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues.app_department_name} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Credit Limit</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues.credit_limit} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Account Balance</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control'  value={" $ " + parseFloat(accountValues.app_account_balance).toFixed(1)} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Oracle Site ID</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={accountValues.oracle_site_ID} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Oracle Status</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control' value={oracleCreditStatus} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Transaction Type<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <select className="form-select d-inline" {...register('app_transaction_type', { required: true })}>
                                                    <option hidden value="">Select Transaction Type</option>
                                                    <option value="DEPOSIT">Deposit</option>
                                                    <option value="CREDIT">Credit</option>
                                                    <option value="DEBIT">Debit</option>
                                                </select>
                                                {errors.app_transaction_type && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Transaction Value<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input type="text" className='form form-control'  {...register('app_transaction_value', { required: true })} />
                                                {errors.app_transaction_value && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment File<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="images" direction="horizontal">
                                                        {(provided) => (
                                                            <div className="thumbContainer" {...provided.droppableProps} ref={provided.innerRef}>
                                                                {images.map((image, i) => (
                                                                    <Draggable key={i} draggableId={i.toString()} index={i}>
                                                                        {(provided) => (
                                                                            <div key={i} className="thumbSpan" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                                <img className='thumbImage ms-3' id="fileImg" src={image.imgSrc}
                                                                                    onMouseEnter={() => setRemoveButton(true)}
                                                                                    // onClick={(e) => openImage(e)}
                                                                                    style={{
                                                                                        border: "1px solid #ddd",
                                                                                        borderRadius: "4px",
                                                                                        padding: "5px",
                                                                                        width: "75px",
                                                                                        height: "75px"
                                                                                    }}
                                                                                />
                                                                                {removeButton &&
                                                                                    <button type='button'
                                                                                        onClick={() => removeBtnClick(i)}
                                                                                        className='thumbClose btn btn-danger btn-sm'>
                                                                                        X
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                <input className='form form-control' multiple type="file" accept="image" onChange={handleChange} />
                                                {errors.app_payment_file && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment Notes<span className='text-danger'>*</span></Label>
                                            <Col lg="4" md="6">
                                                <input type="text" className='form form-control'  {...register('app_payment_notes', { required: true })} />
                                                {errors.app_payment_notes && <span style={{ color: "red" }}>This Field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                {/* <Button color='secondary' type='button' onClick={() => { BackButton() }}>
                                                    Back
                                                </Button> */}
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                <Button color='success' id='createCreditBtn' type='submit' className='m-2'>
                                                    update
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UpdateTransaction
