/* EVENTS */

// Get business Unit
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAIL = "GET_INVOICE_FAIL"

// Post Business Unit
export const POST_INVOICE = "POST_INVOICE";
export const POST_INVOICE_SUCCESS = "POST_INVOICE_SUCCESS";
export const POST_INVOICE_FAIL = "POST_INVOICE_FAIL"

// Delete Business Unit
export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAIL = "DELETE_INVOICE_FAIL"

// Get Business Unit by id
export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL";

// Update Business Unit
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAIL = "UPDATE_INVOICE_FAIL";


export const GET_INVOICE_CONFIRM = "GET_INVOICE_CONFIRM";
export const GET_INVOICE_CONFIRM_SUCCESS = "GET_INVOICE_CONFIRM_SUCCESS";
export const GET_INVOICE_CONFIRM_FAIL = "GET_INVOICE_CONFIRM_FAIL"