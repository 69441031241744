import { takeEvery, put, call } from "redux-saga/effects"
import {
  getOpportunitiesesFail,
  getOpportunitiesesSuccess,
  postOpportunitiesSuccess,
  postOpportunitiesFail,
  deleteOpportunitiesFail,
  deleteOpportunitiesSuccess,
  getOpportunitiesDetailSuccess,
  getOpportunitiesDetailFail,
  updateOpportunitiesSuccess,
  updateOpportunitiesFail
} from "./actions"

import {
  getAppOpportunitieses,
  getAppOpportunities,
  postAppOpportunities,
  updateAppOpportunities,
  deleteAppOpportunities
}
  from "helpers/backendApi_helpers";

import {
  DELETE_OPPORTUNITIES,
  GET_OPPORTUNITIES,
  GET_OPPORTUNITIES_DETAIL,
  POST_OPPORTUNITIES,
  UPDATE_OPPORTUNITIES
} from "./actionTypes";

function* fetchOpportunities({ payload: payload }) {
  try {
    const response = yield call(getAppOpportunitieses, payload);
    yield put(getOpportunitiesesSuccess(response))
  } catch (error) {
    yield put(getOpportunitiesesFail(error))
  }
}

function* postOpportunities({ payload: payload }) {
  try {
    const response = yield call(postAppOpportunities, payload);
    yield put(postOpportunitiesSuccess(response))
  } catch (error) {
    yield put(postOpportunitiesFail(error))
  }
}

function* deleteOpportunities({ payload: payload }) {
  try {
    const response = yield call(deleteAppOpportunities, payload);
    yield put(deleteOpportunitiesSuccess(response))
  } catch (error) {
    yield put(deleteOpportunitiesFail(error))
  }
}

function* getOpportunities({ payload: payload }) {
  try {
    const response = yield call(getAppOpportunities, payload);
    yield put(getOpportunitiesDetailSuccess(response))
  } catch (error) {
    yield put(getOpportunitiesDetailFail(error))
  }
}

function* updateOpportunities({ payload: payload }) {
  try {
    const response = yield call(updateAppOpportunities, payload);
    yield put(updateOpportunitiesSuccess(response))
  } catch (error) {
    yield put(updateOpportunitiesFail(error))
  }
}

function* AppOpportunitiesSaga() {
  yield takeEvery(GET_OPPORTUNITIES, fetchOpportunities)
  yield takeEvery(POST_OPPORTUNITIES, postOpportunities)
  yield takeEvery(DELETE_OPPORTUNITIES, deleteOpportunities)
  yield takeEvery(GET_OPPORTUNITIES_DETAIL, getOpportunities)
  yield takeEvery(UPDATE_OPPORTUNITIES, updateOpportunities)
}

export default AppOpportunitiesSaga





