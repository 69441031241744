import React, { useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, CardBody } from "reactstrap"
import { useForm } from "react-hook-form"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import axios from 'axios'
import getHeader from 'config/header'
import getFormHeader from 'config/formHeader'
import StatusModal from 'components/Common/StatusModal'
import { useDispatch, useSelector } from 'react-redux'
import { geAccountDetail, getBusinesses, getCredit, getCredits, getDepartment, getDepartments, updateCredit } from 'store/actions'
import departmentReducer from 'store/customers/company/department/reducer'
import TableContainer from 'components/Common/TableContainer'
import ApproveModal from 'components/Common/ApproveModal'

const UpdateInvoice = () => {
    const history = useHistory();

    const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()


    const invoiceList = {
        app_invoice_id: "INV12341234",
        app_business_name: "McDonald",
        app_site_id: "S58428E425",
        app_site_name: "Thanjavur",
        app_businessunit_name: "Business Unit 3",
        app_opportunity_id: "OPP7890",
        app_total: "1000",
        app_status: "active",
        app_transaction_created_on: "2024-01-12"
    }

    const [removeButton, setRemoveButton] = useState(false)
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([])

    const removeBtnClick = (i) => {
        let newImages = images.map(item => ({ ...item }))
        let newFiles = files.map(item => ({ ...item }))
        newImages.splice(i, 1)
        setImages(newImages)
        newFiles.splice(i, 1)
        setFiles(newFiles)
    }

    const handleChange = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleFiles(chosenFiles)
        let imageData = e.target.files
        const len = imageData.length
        for (var i = 0; i < len; i++) {
            var imageBlob = imageData[i];
            var fileReader = new FileReader()  //1
            fileReader.onload = ({ target }) => {  // 3
                setImages(prevState => ([...prevState, { seq_no: i, imgSrc: target.result }]))
            }
            fileReader.readAsDataURL(imageBlob) //2
        }
    }

    const handleFiles = (inputFile) => {
        const filesData = [...files]
        inputFile.some(file => {
            if (filesData.findIndex((f) => f.name === file.name) === -1) {
                filesData.push(file)
            }
        })
        setFiles(filesData)
    }

    const getAwsStorageUrl = (file) => {
        return new Promise((resolve, reject) => {
            const
                token = localStorage.getItem('reposeCmsToken')
            axios.get("/business/api/v1/storage/signedurlput?image_folder=product_images", getBusinessHeader(token))
                .then(async res => {
                    let fileKey = res.data.filekey
                    let signedURLPut = res.data.signed_url_put
                    await uploadImage(file, signedURLPut)
                    resolve(fileKey)
                }).catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    const uploadImage = (file, signedURLPut) => {
        return new Promise(async (resolve, reject) => {
            await fetch(signedURLPut, {
                method: 'PUT',
                headers: getImageHeader(),
                mode: 'cors',
                body: file
            })
            resolve()
        })
    }

    const getImageHeader = () => {
        // { 'Content-Type': 'image/png' }
        const myHeaders = new Headers()
        myHeaders.append('Access-Control-Allow-Origin', '*')
        return myHeaders
    }

    const getImages = (list) => {
        let data = []
        for (let i in list) {
            data.push({ seq_no: parseInt(i) + 1, image_filekey: list[i] })
        }
        return data
    }

    const openImage = (e) => {
        let data = e.target.currentSrc;
        let w = window.open('about:blank');
        let image = new Image();
        image.src = data;
        setTimeout(function () {
            w.document.write(image.outerHTML);
        }, 0);
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const newItems = Array.from(images);
        const newFiles = Array.from(files)
        const [removed] = newItems.splice(startIndex, 1);
        const [removedFiles] = newFiles.splice(startIndex, 1)
        newItems.splice(endIndex, 0, removed);
        newFiles.splice(endIndex, 0, removedFiles)
        newItems.forEach((item, index) => {
            item.seq_no = index + 1;
        });
        setImages(newItems)
        setFiles(newFiles)
    }

    const BackButton = () => {
        return history.push(`/invoicelist?skip=${0}&&limit=${10}&&status=${true}`)
    }

    const updateInvoice = (data) => {
        console.log(data);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Update Invoice" breadcrumbItem="Invoice" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(updateInvoice)}>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Company</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control'  {...register(`app_business_name`, { required: true })} value={invoiceList.app_business_name}  placeholder='company name' />
                                                    {/* {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>} */}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Site</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control'  {...register(`app_site_name`, { required: true })} value={invoiceList.app_site_name} placeholder='site name' />
                                                    {/* {errors.app_department_id && <p style={{ color: "red" }}>This Field is required</p>} */}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end" >Opportunity ID</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control'  {...register(`app_opportunity_id`, { required: true })} value={invoiceList.app_opportunity_id} placeholder='opportunity id' />
                                                    {/* {errors.app_account_keyid && <p style={{ color: "red" }}>This Field is required</p>} */}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Invoice ID</Label>
                                                <Col lg="7" md="6">
                                                    <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" className='form form-control'  {...register(`app_invoice_id`, { required: true })} value={invoiceList.app_invoice_id} placeholder='INV12341234' />
                                                    {/* {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>} */}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr></hr>

                                    {/* <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Invoice Template</Label>
                                            </FormGroup>
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Customer Contact</Label>
                                                <Col lg="7" md="6">
                                                    <input className='form form-control'  {...register(`app_department_keyid`, { required: false })}/>
                                                    {/* {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>} */}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Customer Address</Label>
                                                <Col lg="7" md="6">
                                                    <input type="text" className='form form-control'  {...register(`app_department_keyid`, { required: false})}/>
                                                    {/* {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>} */}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Customer Instruction</Label>
                                                <Col lg="7" md="6">
                                                    <input className='form form-control'  {...register(`app_department_keyid`, { required: false })}/>
                                                    {/* {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>} */}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {/* <Row>
                                        <Col>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment File<span className='text-danger'>*</span></Label>
                                            <Col lg="7" md="6">
                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    <Droppable droppableId="images" direction="horizontal">
                                                        {(provided) => (
                                                            <div className="thumbContainer" {...provided.droppableProps} ref={provided.innerRef}>
                                                                {images.map((image, i) => (
                                                                    <Draggable key={i} draggableId={i.toString()} index={i}>
                                                                        {(provided) => (
                                                                            <div key={i} className="thumbSpan" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                                <img className='thumbImage ms-3' id="fileImg" src={image.imgSrc}
                                                                                    onMouseEnter={() => setRemoveButton(true)}
                                                                                    // onClick={(e) => openImage(e)}
                                                                                    style={{
                                                                                        border: "1px solid #ddd",
                                                                                        borderRadius: "4px",
                                                                                        padding: "5px",
                                                                                        width: "75px",
                                                                                        height: "75px"
                                                                                    }}
                                                                                />
                                                                                {removeButton &&
                                                                                    <button type='button'
                                                                                        onClick={() => removeBtnClick(i)}
                                                                                        className='thumbClose btn btn-danger btn-sm'>
                                                                                        X
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                <input className='form form-control' multiple type="file" accept="image" onChange={handleChange} />
                                                {errors.app_payment_file && <span style={{ color: "red" }}>This Field is required</span>} 
                                            </Col>
                                        </FormGroup>
                                        </Col>
                                    </Row> */}

                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " >Payment Notes</Label>
                                                <Col lg="7" md="6">
                                                    <input className='form form-control'  {...register(`app_department_keyid`, { required: false })}/>
                                                    {/* {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>} */}
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                                <Col lg="7" md="6">
                                                    <select className="form-select d-inline"  {...register(`app_department_keyid`, { required: false })}>
                                                        {/* <option hidden value="">Select Status</option> */}
                                                        <option value="inactive">INACTIVE</option>
                                                        <option value="active">ACTIVE</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end " ></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => { BackButton() }}>
                                                    Back
                                                </Button>
                                                <Button id='updateinvoice' color='success' type='submit' className='m-2'>
                                                    Update
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
}
export default UpdateInvoice