import {
    GET_APPLICATION_TEMPLATE,
    GET_APPLICATION_TEMPLATE_SUCCESS,
    GET_APPLICATION_TEMPLATE_FAIL,
 
    GET_APPLICATION_TEMPLATE_DETAIL,
    GET_APPLICATION_TEMPLATE_DETAIL_SUCCESS,
    GET_APPLICATION_TEMPLATE_DETAIL_FAIL,
  
    POST_APPLICATION_TEMPLATE,
    POST_APPLICATION_TEMPLATE_SUCCESS,
    POST_APPLICATION_TEMPLATE_FAIL,
  
    DELETE_APPLICATION_TEMPLATE,
    DELETE_APPLICATION_TEMPLATE_SUCCESS,
    DELETE_APPLICATION_TEMPLATE_FAIL,
    
    UPDATE_APPLICATION_TEMPLATE_SUCCESS,
    UPDATE_APPLICATION_TEMPLATE_FAIL,
    UPDATE_APPLICATION_TEMPLATE,
  } from "./actionTypes";


const INIT_STATE = {
    applicationTemplate: null,
    applicationTemplateDetail: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const ApplicationTemplateReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case  GET_APPLICATION_TEMPLATE:
            return {
                ...state,
                loading: true,
                applicationTemplate: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case  GET_APPLICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationTemplate: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case  GET_APPLICATION_TEMPLATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };

        case GET_APPLICATION_TEMPLATE_DETAIL:
            return {
                ...state,
                loading: true,
                applicationTemplateDetail: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APPLICATION_TEMPLATE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationTemplateDetail: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_APPLICATION_TEMPLATE_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APPLICATION_TEMPLATE:
            return {
                ...state,
                loading: true,
                applicationTemplateDetail: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APPLICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationTemplateDetail: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_APPLICATION_TEMPLATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_APPLICATION_TEMPLATE:
            return {
                ...state,
                loading: true,
                applicationTemplateDetail: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_APPLICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationTemplateDetail: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_APPLICATION_TEMPLATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };

        case UPDATE_APPLICATION_TEMPLATE:
            return {
                ...state,
                loading: true,
                applicationTemplateDetail: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_APPLICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                loading: false,
                applicationTemplateDetail: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_APPLICATION_TEMPLATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default ApplicationTemplateReducer;


