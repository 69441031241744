import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'
import classnames from "classnames"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input, Table, TabContent, TabPane, Collapse, CardBody } from "reactstrap"
import axios from 'axios';
import getHeader from 'config/header';
import expiredSessionHandler from 'common/expiredSessionHandler'
import { postBusinessUnitDivision } from 'store/administration/businessUnitDivision/actions'
import { use } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getAppBusinessUnits, getBusinessProducts, getSysUsers } from 'store/actions'
import * as url from "./../../../helpers/url_helper";
import TableContainer from 'components/Common/TableContainer'

function CreateBusinessUnitDivision() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()

  const businessUnitDivisionState = useSelector(state => state.BUDivisionReducer);
  const businessUnitSate = useSelector(state => state.AppBusinessUnits)
  const businessProductState = useSelector(state => state.BusinessProducts);
  const sysUserState = useSelector(state => state.sysUsersReducer)


  useEffect(() => {
    setOptionGroup('')
    setselectedMulti([])
    dispatch(getAppBusinessUnits({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));

    setOptionGroupProduct()
    setselectedMultiProduct([])
    dispatch(getBusinessProducts({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));

    setOptionGroupBusUnitUser()
    setselectedMultiBusUser([])
    dispatch(getSysUsers({ filter: `{"is_active":true}`, limit: 0, skip: 0 }))
  }, [])

  useEffect(() => {
    if (businessUnitSate.appBusinessUnits !== null) {
      setBusinessUnit(businessUnitSate.appBusinessUnits.result);
    }
    if (businessProductState.products !== null) {
      setProductList(businessProductState.products.result);
    }
    if (sysUserState.sysUsers !== null) {
      setSysUserList(sysUserState.sysUsers.result);
    }
  }, [businessUnitSate, businessProductState, sysUserState])

  // Manage Departments's MultiSelect
  const [businessUnits, setBusinessUnit] = useState([])
  const [selectedMulti, setselectedMulti] = useState([]);
  const [optionGroup, setOptionGroup] = useState([{ options: [] }])
  register('app_business_unit');

  //  manage product multi select
  const [selectedMultiProduct, setselectedMultiProduct] = useState([]);
  const [optionGroupProduct, setOptionGroupProduct] = useState([{ options: [] }])
  register('app_business_unit_product');
  const [productList, setProductList] = useState([])

  // Manage Business Unit User MultiSelect
  const [selectedMultiBusUser, setselectedMultiBusUser] = useState([]);
  const [optionGroupBusUnitUser, setOptionGroupBusUnitUser] = useState([{ options: [] }])
  register('app_business_unit_user');
  const [busUnitUserList, setBusUnitUserList] = useState([])
  const [sysUserList, setSysUserList] = useState([])


  useEffect(() => {
    if (businessUnits) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in businessUnits) {
        let data = {
          label: businessUnits[i].app_business_unit_name,
          value: businessUnits[i].app_businessunit_id
        }
        options.push(data)
      }
      setOptionGroup(options)
    }
    if (productList) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in productList) {
        let data = {
          label: productList[i].app_product_pos_item_code + " | " + productList[i].app_product_payment_code + " | " + productList[i].app_product_local_name,
          value: productList[i].app_product_id
        }
        options.push(data)
      }
      setOptionGroupProduct(options)
    }
    if (sysUserList) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in sysUserList) {
        let data = {
          label: sysUserList[i].sys_user_keyid + " | " + sysUserList[i].sys_user_name,
          value: sysUserList[i].sys_user_id
        }
        options.push(data)
      }

      setOptionGroupBusUnitUser(options)
    }
  }, [businessUnits, productList, sysUserList])

  const handlePostChange = (Data) => {
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values

    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroup.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            setValue('app_business_unit', postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue('app_business_unit', postArray)
        }
      }
    });
    setselectedMulti({
      app_businessunit_id: postArray,
    });
  };

  const handleChangeProduct = (Data) => {
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values
    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroupProduct.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            setValue('app_business_unit_product', postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue('app_business_unit_product', postArray)
        }
      }
    });
    //   setBusUnitUserList(postArray)
    setselectedMultiProduct(postArray);
  };

  const handlePostChangeUsers = (Data) => {
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values

    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroupBusUnitUser.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            setValue('app_business_unit_user', postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue('app_business_unit_user', postArray)
        }
      }
    });
    setselectedMultiBusUser({
      sys_user_id: postArray,
    });
  };

  const CreateBusinessUnitDivision = (data) => {
    // $('#createbusinessunits').attr("disabled", true)
    let value
    if (data.is_active === "active") {
      value = true
    } else if (data.is_active === "inactive") {
      value = false
    }
    let items = data.app_business_unit
    let Dta = []
    for (let i in items) {
      Dta.push(items[i].value)
    }
    const BUDdata = {
      ...data,
      app_business_unit_division_name: data.app_business_unit_division_name,
      is_active: value,
      // app_business_unit: Dta,
      // permissions: BusinessUnitIDa
    }
    console.log("BUDdata", BUDdata);

    dispatch(postBusinessUnitDivision(BUDdata));
  }

  useEffect(() => {
    if (businessUnitDivisionState.isCreated) {
      history.goBack()
    }
  }, [businessUnitDivisionState])


  const removeSelectedProduct = (indexToRemove) => {
    const newData = selectedMultiProduct.filter((_, index) => index !== indexToRemove);
    setselectedMultiProduct(newData);
  }

  const columns2 = useMemo(
    () => [
      {
        Header: 'S.No',
        accessor: (row, index) => index + 1,
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Product Name',
        accessor: 'label',
        filterable: true,
        Cell: (cellProps) => {
          return <span>{cellProps.value}</span>;
        }
      },
      {
        Header: 'Action',
        accessor: (row, index) => index,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <button type='button' className="btn btn-danger btn-sm" onClick={() => { removeSelectedProduct(cellProps.value) }}>X</button>
            </div>
          );
        }
      },

    ],
    [selectedMultiProduct]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Create Business Unit Division" breadcrumbItem="Business Unit Divisions" />
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card body>
                <Form onSubmit={handleSubmit(CreateBusinessUnitDivision)}>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end">Business Unit Division Name <span className='text-danger'>*</span></Label>
                      <Col lg="5" md="6">
                        <input type="text" className='form form-control' {...register('app_business_unit_division_name', { required: true })} />
                        {errors.app_business_unit_division_name && <p style={{ color: "red" }}>This Field is required</p>}
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                        Business Units <span className='text-danger'>*</span>
                      </Label>
                      <Col lg="5" md="6">
                        <Select
                          value={selectedMulti.app_businessunit_id}
                          isMulti={true}
                          onChange={handlePostChange}
                          options={optionGroup}
                          name={"app_businessunit_id"}
                          className="modal__input"
                        />
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                        Users <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Col lg="5" md="6">
                        <Select
                          value={selectedMultiBusUser.sys_user_id}
                          isMulti={true}
                          onChange={handlePostChangeUsers}
                          options={optionGroupBusUnitUser}
                          name={"sys_user_id"}
                          className="modal__input"
                        />
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                        Products <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Col lg="5" md="6">
                        <Select
                          value={selectedMultiProduct}
                          isMulti={true}
                          onChange={handleChangeProduct}
                          options={optionGroupProduct}
                          name={"app_product_id"}
                          className="modal__input"
                        />
                      </Col>
                    </FormGroup>
                  </Row>
                  {selectedMultiProduct.length > 0 ? <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <TableContainer
                            columns={columns2}
                            data={selectedMultiProduct}
                            isGlobalFilter={true}
                            isTwoColumnTable={true}
                            isthemeGlobalFilter={true}
                            className="custom-header-css"
                            // customPageCount={pageCount}
                            customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row> : ""}
                  <Row>
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                      <Col lg="5" md="6">
                        <select className="form-select d-inline" {...register('is_active')}>
                          <option value="inactive">INACTIVE</option>
                          <option value="active">ACTIVE</option>
                        </select>
                      </Col>
                    </FormGroup>
                  </Row>
                  <Row className="mt-4">
                    <FormGroup row>
                      <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                      <Col lg="5" md="6">
                        <Button color='secondary' type='button' onClick={() => { history.goBack() }}>
                          Back
                        </Button>
                        <Button id="createbusinessunits" color='success' type='submit' className='m-2'>
                          Create
                        </Button>
                      </Col>
                    </FormGroup>
                  </Row>

                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateBusinessUnitDivision
