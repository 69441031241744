import Breadcrumbs from "components/Common/Breadcrumb"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Company from "./modules/Company"
import React, { useState } from "react"
import SysUser from "./modules/SysUser"
// import PricingModal from "./modules/PricingModal"

function Approvals() {

    const permissions = JSON.parse([sessionStorage.getItem("userCred")])


    return (
        <React.Fragment>
            <div className="page-content">
                {permissions.includes("system.customers.business.approval" ) ?
                    <Container fluid>
                        <Breadcrumbs title="Approvals" />
                       {/* {permissions.includes("system.customers.business.approval") && <Company />} */}
                       {/* <PricingModal/> */}
                       {/* {permissions.includes("system.customers.business.approval") && <PricingModel/>} */}

                        {/* <SysUser /> */}
                    </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>}
            </div>
        </React.Fragment>
    )
}
export default Approvals
