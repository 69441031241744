import { useEffect } from "react"
import { useState } from "react"
import LeftSection from "./LeftSection";
import { Label } from "reactstrap"
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import getHeader from "config/header";
import * as url from "./../../helpers/url_helper";

function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

function Approval() {

    const query = useQuery()
    const { ID } = query.get("id") ? query.get("id") : ""
    const [approvalName, setApprovalName] = useState("")
    const [btnName, setBtnName] = useState("Approve")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let approvalName = query.get("approvalName") ? query.get("approvalName") : ""
        setApprovalName(approvalName)
        $("#approveBtn").attr("disabled", true)
        setIsLoading(true)
        let API_NAME = ""
        switch (approvalName) {
            case "Site":
                API_NAME = "/sites"
                break;
            case "User":
                API_NAME = "/users"
                break;
            default:
                break;
        }
        const token = sessionStorage.getItem("authUser")
        axios.get(url.API_BASE_URL+"/"+ API_NAME + "/" + ID, '', getHeader(token))
            .then(res => {
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
                console.log(err);
                $("#approveBtn").attr("disabled", false)
            })
    }, [])

    const approve = () => {
        $("#approveBtn").attr("disabled", true)
        setIsLoading(true)
        let API_NAME = ""
        switch (approvalName) {
            case "Site":
                API_NAME = "/sites"
                break;
            case "User":
                API_NAME = "/users"
                break;
            default:
                break;
        }
        const token = sessionStorage.getItem("authUser")
        if (token && ID && API_NAME) {
            axios.put(url.API_BASE_URL+"/approval/" + API_NAME + "/" + ID, '', getHeader(token))
                .then(res => {
                    setIsLoading(false)
                }).catch(err => {
                    setIsLoading(false)
                    console.log(err);
                    $("#approveBtn").attr("disabled", false)
                })
        } else {
            setIsLoading(false)
            $("#approveBtn").attr("disabled", false)
        }
    }

    return (
        <div className="row approvalCont">
            <LeftSection />
            <div className="rightSection col-lg-8 col-sm-12">
                <div className="rightContent">
                    <h3 className="text-center">
                        {approvalName} Approval
                    </h3>
                    <div className="text-muted text-center text-underline mb-4 p-4">
                        <u>{approvalName} Details</u>
                    </div>
                    <div className="form-group row mb-4">
                        <div className="col-4">
                            <Label>{approvalName} Name</Label>
                        </div>
                        <div className="col-8">
                            <input readOnly disabled type="text" className="form form-control" />
                        </div>
                    </div>
                    <div className="form-group row mb-4">
                        <div className="col-4">
                            <Label>{approvalName} Description</Label>
                        </div>
                        <div className="col-8">
                            <input readOnly disabled type="text" className="form form-control" />
                        </div>
                    </div>
                    <div className="form-group row mb-4">
                        <div className="col-4">
                            <Label>Created By</Label>
                        </div>
                        <div className="col-8">
                            <input readOnly disabled type="text" className="form form-control" />
                        </div>
                    </div>
                    <div className="form-group row ">
                        {
                            isLoading ?
                                <>
                                    <button className="btn btn-success btn-large"><i className='bx bx-loader-alt bx-spin'></i></button>
                                </>
                                :
                                <>
                                    <button id="approveBtn" className="btn btn-success btn-large" onClick={approve}><i className='bx bx-check-circle'></i> {btnName}</button>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Approval