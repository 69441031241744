import {
 POST_PROPOSAL_CLONE_VERSION,
 POST_PROPOSAL_CLONE_VERSION_SUCCESS,
 POST_PROPOSAL_CLONE_VERSION_FAIL

  } from "./actionTypes";
  
  const INIT_STATE = {
    proposalcloneversion: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
  };

  const ProposalCloneVersionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_PROPOSAL_CLONE_VERSION:
          return {
            ...state,
            loading: true,
            proposalcloneversion: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case POST_PROPOSAL_CLONE_VERSION_SUCCESS:
          return {
            ...state,
            loading: false,
            proposalcloneversion: action.payload,
            isCreated: true,
            isDeleted: false,
            isUpdated: false
          };
        case POST_PROPOSAL_CLONE_VERSION_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
      default:
        return state;
    }
  };
  
  export default ProposalCloneVersionReducer;
  
  
  