import React, { useContext } from "react"
import PropTypes from 'prop-types'
import { Link, useLocation } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import CompanySelect from "./CompanySelect"
import { Button } from "reactstrap"
import BusinessunitSelect from "./BusinessunitSelect"
import CompanySelectCredit from "./CompanySelectCredit"
import SiteSelectCredit from "./SiteSelectCredit"

const Breadcrumb = props => {

  const location = useLocation()

  return (
    <>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="col-lg-12 mb-0 font-size-18">{props.title}&nbsp;</h4>
              {props.isCustomerUserCompany && <CompanySelect />}
              {props.isBusUnit && <BusinessunitSelect />}
            </div>
            {props.isTransactions ?
              <>
                <Link to="/accounts" style={{ backgroundColor: "#91A1EF" }} className="btn btn-sm btn-info"> <i className='bx bx-left-arrow-alt'></i> Back</Link>
              </>
              :
              (location.pathname !== '/smtpSettings') && (location.pathname !== '/attachment') &&
              <div className="page-title-right">
                {props.breadcrumbItem && <ol className="breadcrumb m-0">
                  <BreadcrumbItem active>
                    <span >{props.breadcrumbItem}</span>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <span >{props.title}</span>
                  </BreadcrumbItem>
                </ol>}
              </div>}

            {(props.isShowEditBtnCreatePermission && props.isShowEditBtnUpdatePermission) ? "" :
              location.pathname === '/smtpSettings' && props.isShowEditBtnCreatePermission && (props.isEditSmtpBtn ?
                <Button type="button" size="sm" onClick={props.handleEditState} color="info">Edit</Button>
                :
                <Button type="button" size="sm" onClick={props.handleCancelEditState} color="danger">Cancel</Button>
              )}

            {(props.isShowEditBtnCreatePermission && props.isShowEditBtnUpdatePermission) ? "" :
              location.pathname === '/smtpSettings' && props.isShowEditBtnUpdatePermission && (props.isEditSmtpBtn ?
                <Button type="button" size="sm" onClick={props.handleEditState} color="info">Edit</Button>
                :
                <Button type="button" size="sm" onClick={props.handleCancelEditState} color="danger">Cancel</Button>
              )}

            {location.pathname === '/smtpSettings' && (props.isShowEditBtnCreatePermission && props.isShowEditBtnUpdatePermission) && (props.isEditSmtpBtn ?
              <Button type="button" size="sm" onClick={props.handleEditState} color="info">Edit</Button>
              :
              <Button type="button" size="sm" onClick={props.handleCancelEditState} color="danger">Cancel</Button>
            )}

            {/* {location.pathname === '/smtpSettings' && (props.isEditSmtpBtn ?
              <Button type="button" size="sm" onClick={props.handleEditState} color="info">Edit</Button>
              :
              <Button type="button" size="sm" onClick={props.handleCancelEditState} color="danger">Cancel</Button>
            )} */}

            {location.pathname === '/attachment' && (props.isEditSmtpBtn ?
              <Button type="button" size="sm" onClick={props.handleEditState} color="info">Edit</Button>
              :
              <Button type="button" size="sm" onClick={props.handleCancelEditState} color="danger">Cancel</Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {props.isCompanyDropdown && <h2 className="col-lg-12 mb-0 font-size-18">Company &nbsp; : &nbsp;</h2>}
              {props.isCompanyDropdown && <CompanySelectCredit />}
              {props.isSiteDropdown && <h2 className="col-lg-12 mb-0 font-size-18">&nbsp; &nbsp; &nbsp; Site &nbsp; : &nbsp;</h2>}
              {props.isSiteDropdown && <SiteSelectCredit />}
              {props.isSiteCredit && <h2 className="col-lg-12 mb-0 font-size-18">Company &nbsp; : &nbsp;</h2>}
              {props.isSiteCredit && <CompanySelectCredit />}&nbsp;&nbsp;&nbsp;&nbsp;
              {/* {props.isSiteCredit && <h2 className="col-lg-12 mb-0 font-size-18">Site &nbsp; : &nbsp;</h2>}
              {props.isSiteCredit && <CompanySelectCredit />} */}
            </div>
          </div>
        </ Col>
      </Row>
    </>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  isCompany: PropTypes.bool,
  isCompanyDropdown: PropTypes.bool,
  isSiteDropdown: PropTypes.bool,
  isBusUnit: PropTypes.bool
}

Breadcrumb.defaultProps = {
  isCompany: false,
  isCompanyDropdown: false,
  isSiteDropdown: false,
  isBusUnit: false
}

export default Breadcrumb
