import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import getHeader from "config/header";
import expiredSessionHandler from "common/expiredSessionHandler";
import { useHistory } from "react-router-dom";
import { getSysUsersProfile } from 'store/dashboard/actions'
import { getAppSysUser } from "store/administration/portalUser/actions"
import { getAppSysRole } from "store/administration/portalRole/actions"
import BarChart from "components/Common/Chart/BarChart";
import NotificationComp from "components/Common/Chart/NotificationComp";

let value = []

const Dashboard = props => {

  const history = useHistory()
  const [modal, setmodal] = useState(false);
  const dispatch = useDispatch();
  const [subscribemodal, setSubscribemodal] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  // const reports = [
  //   { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
  //   { title: "Orders Value", iconClass: "bx-archive-in", description: "$35, 723" },
  //   {
  //     title: "Company",
  //     iconClass: "bx-purchase-tag-alt",
  //     description: "23",
  //   }
  // ];

  useEffect(() => {
    // setTimeout(() => {
    //   setSubscribemodal(true);
    // }, 2000);
  }, []);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  // const onChangeChartPeriod = pType => {
  //   setPeriodType(pType);
  //   dispatch(onGetChartsData(pType));
  // };

  // useEffect(() => {
  //   dispatch(onGetChartsData("yearly"));
  // }, [dispatch]);



  ///////////////////////////////////////////////////////////////////////////////////

  //meta title
  document.title = "Dashboard | B2B Maxims";

  // const [userName, setUserName] = useState('')
  // const [roleName, setRoleName] = useState('')

  // const sysUserProfilState = useSelector(state => state.Dashboard)
  // const sysUserState = useSelector(state => state.sysUsersReducer)
  // const sysRoleState = useSelector(state => state.AppSysRoles);

  // useEffect(() => {
  //   dispatch(getSysUsersProfile())
  // }, [])


  // useEffect(() => {
  //   if (sysUserProfilState.sysUserProfile !== null) {
  //     let userID = sysUserProfilState.sysUserProfile.sys_user_id
  //     if (userID) {
  //       const data = { sysUserID: userID }
  //       dispatch(getAppSysUser(data))
  //     }
  //   }
  // }, [sysUserProfilState])


  // useEffect(() => {
  //   if (sysUserState.sysUser !== null) {
  //     setUserName(sysUserState.sysUser.sys_user_name)
  //     let roleID = sysUserState.sysUser.sys_user_roles
  //     roleID.map((id) => {
  //       const data = { roleID: id }
  //       dispatch(getAppSysRole(data))
  //     })
  //   }
  // }, [sysUserState])

  // useEffect(() => {
  //   if (sysRoleState.appSysRole !== null) {
  //     value.push(sysRoleState.appSysRole.sys_role_name)

  //     function removeDuplicates(value) {
  //       return value.filter((item,
  //         index) => value.indexOf(item) === index);
  //     }
  //     let Dta = removeDuplicates(value);
  //     let Data = Dta.map((item) => item).join(", ")
  //     setRoleName(Data)
  //   }
  // }, [sysRoleState])


  //////////////////////////////////////////////////////////////////////////////////

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
          />
          <Row>

            {/* <Col xl="4">
              <Row>
                <WelcomeComp name={userName} role={roleName} />
              </Row>
            </Col>

            <Col xl="8">
              <BarChart />
            </Col> */}

            {/* <Col xl="8">
              <Row>
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex" style={{ padding: "12.6px" }}>
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col> */}
          </Row>

          <Row>
            <Col lg="12">
              <NotificationComp />
            </Col>
          </Row>



          <Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
