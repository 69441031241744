import { takeEvery, put, call } from "redux-saga/effects"
import {
    getAddressesSuccess, getAddressesFail,
    getAddressSuccess, getAddressFail,
    getAllAddressSuccess, getAllAddressFail,
    postAddressSuccess, postAddressFail,
    deleteAddressSuccess, deleteAddressFail,
    updateAddressSuccess, updateAddressFail,
} from "./actions"

// Api Handler 
import {
    getAddresses,
    getAddress,
    getAllAddress,
    postAddress,
    updateAddress,
    deleteAddress,
} from "helpers/backendApi_helpers";


import {
    GET_ADDRESSES,
    GET_ADDRESS,
    GET_ALL_ADDRESS,
    POST_ADDRESS,
    DELETE_ADDRESS,
    UPDATE_ADDRESS
} from "./actionTypes";


function* fetchAppAddresses({ payload: payload }) {
    try {
        const response = yield call(getAddresses, payload);
        yield put(getAddressesSuccess(response))
    } catch (error) {
        yield put(getAddressesFail(error))
    }
}

function* postAppAddress({ payload: payload }) {
    try {
        const response = yield call(postAddress, payload);
        yield put(postAddressSuccess(response))
    } catch (error) {
        yield put(postAddressFail(error))
    }
}

function* deleteAppAddress({ payload: payload }) {
    try {
        const response = yield call(deleteAddress, payload);
        yield put(deleteAddressSuccess(response))
    } catch (error) {
        yield put(deleteAddressFail(error))
    }
}

function* getAppAddress({ payload: payload }) {
    try {
        const response = yield call(getAddress, payload);
        yield put(getAddressSuccess(response))
    } catch (error) {
        yield put(getAddressFail(error))
    }
}

function* getAppAllAddress({ payload: payload }) {
    try {
        const response = yield call(getAllAddress, payload);
        yield put(getAllAddressSuccess(response))
    } catch (error) {
        yield put(getAllAddressFail(error))
    }
}

function* updateAppAddress({ payload: payload }) {
    try {
        const response = yield call(updateAddress, payload);
        yield put(updateAddressSuccess(response))
    } catch (error) {
        yield put(updateAddressFail(error))
    }
}

function* AddressSaga() {
    yield takeEvery(GET_ADDRESSES, fetchAppAddresses)
    yield takeEvery(POST_ADDRESS, postAppAddress)
    yield takeEvery(DELETE_ADDRESS, deleteAppAddress)
    yield takeEvery(GET_ADDRESS, getAppAddress)
    yield takeEvery(GET_ALL_ADDRESS, getAppAllAddress)
    yield takeEvery(UPDATE_ADDRESS, updateAppAddress)
}

export default AddressSaga


    