import React, { useMemo } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import TableContainer from 'components/Common/TableContainer'
import Select from "react-select";
import classnames from "classnames"
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Card, Col, Row, Button, Container, Form, FormGroup, Label,
  Nav, NavItem, NavLink, TabContent, TabPane, CardBody, UncontrolledTooltip, Table, Collapse
} from "reactstrap"
import axios from 'axios';
import getHeader from 'config/header';
import { Status, CompanyName } from './company/CompanyCol';
import { getSysRoles } from "store/administration/portalRole/actions"
import { getAppBusinessUnits } from "store/administration/businessUnit/actions"
import { postAppSysUsers } from "store/administration/portalUser/actions"
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessUnitDivisions } from 'store/actions'
import BusinessUnitModal from 'components/Common/BusinessUnitsModal'
import { object } from 'yup'
import { toast } from 'react-toastify'

let tempSysRoleID

function CreateUser() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { register, getValues, unregister, setValue } = useForm()
  const [roles, setRoles] = useState([])

  // CUSTOM VALIDATION
  const [custErrors, setCustErrors] = useState({
    sys_user_name: false,
    sys_user_email: false,
    sys_user_password: false,
    sys_user_keyid: false,
    sys_user_roles: false,
    sys_user_business_unit: false
  })

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };





  // Status State
  // const [status, setStatus] = useState({
  //     state: false,
  //     name: "Inactive"
  // })

  // Password Field
  const [isPassword, setIsPassword] = useState(true)

  // On Change of RoleStatus
  // const statusChange = () => {
  //     const value = getValues('user.is_active')
  //     if (value) {
  //         setStatus({
  //             state: true,
  //             name: 'Active'
  //         })
  //     } else {
  //         setStatus({
  //             state: false,
  //             name: 'Inactive'
  //         })
  //     }
  // }

  // Azure SSO State
  const [azureSSO, setAzureSSO] = useState({
    state: false,
    name: "Inactive"
  })

  //on change Azure SSO
  const SSOChange = () => {
    const value = getValues('user.azure_sso')
    if (value) {
      setAzureSSO({
        state: true,
        name: 'Active'
      })
      setIsPassword(false)
    } else {
      setAzureSSO({
        state: false,
        name: 'Inactive'
      })
      setIsPassword(true)
    }
  }

  useEffect(() => {
    if (azureSSO.state) {
      setIsPassword(false)
      unregister('sys_user_password')
    } else {
      setIsPassword(true)
      register('sys_user_password', { required: true })
    }
  }, [setIsPassword, azureSSO])

  const watchCompanyFields = (e) => {
    if (watchUserState) {
      const inputID = e.target.id
      const inputValue = e.target.value
      if (inputValue) {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: false }))
      } else {
        setCustErrors((prevState) => ({ ...prevState, [inputID]: true }))
      }
      if (inputID === "sys_user_email") {
        const result = validateEmail(inputValue)
        if (result) {
          setCustErrors((prevState) => ({ ...prevState, sys_user_email: false }))
        } else {
          setCustErrors((prevState) => ({ ...prevState, sys_user_email: true }))
          return
        }
      }
    }
  }
  // Create User
  const [watchUserState, setWatchUserState] = useState(false)

  const sysUserState = useSelector(state => state.sysUsersReducer)

  useEffect(() => {
    if (sysUserState.isCreated) {
      setUserID(sysUserState.sysUser.sys_user_id)
      // setactiveTab("2")
      history.goBack()
    }
    if (sysUserState.error.ErrorDetail !== undefined) {
      
      toast.error(sysUserState.error.ErrorDetail)
      setButtonState(true)
      $('#createUserBtn').attr("disabled", false)
    }
  }, [sysUserState])


  const createUser = () => {
    setWatchUserState(true)
    const userData = getValues('user')
    setButtonState(false)
    $('#createUserBtn').attr("disabled", true)
    if (userData.azure_sso === false) {
      if (!userData.sys_user_name) {
        setCustErrors((prevState) => ({ ...prevState, sys_user_name: true }))
      }
      if (!userData.sys_user_password) {
        setCustErrors((prevState) => ({ ...prevState, sys_user_password: true }))
      }
      if (!userData.sys_user_business_unit) {
        setCustErrors((prevState) => ({ ...prevState, sys_user_business_unit: true }))
      }
      if (!userData.sys_user_roles) {
        setCustErrors((prevState) => ({ ...prevState, sys_user_roles: true }))
      }
      if (userData.sys_user_email) {
        const result = validateEmail(userData.sys_user_email)
        if (result) {
          setCustErrors((prevState) => ({ ...prevState, sys_user_email: false }))
        } else {
          setCustErrors((prevState) => ({ ...prevState, sys_user_email: true }))
          setButtonState(true)
          $('#createUserBtn').attr("disabled", false)
          return
        }
      }

      // console.log(userData);
      // if (userData.sys_user_business_unit && userData.sys_user_email && userData.sys_user_name && userData.sys_user_password && userData.sys_user_roles) {
      if (userData.sys_user_email && userData.sys_user_name && userData.sys_user_password && userData.sys_user_roles) {
        const token = sessionStorage.getItem('authUser')

        let roleID = userData.sys_user_roles
        let Dta = []
        let RoleName = []
        for (let i in roleID) {
          Dta.push(roleID[i].value)
          RoleName.push(roleID[i].label)
        }

        let busUnit = userData.sys_user_business_unit
        let bUnit = []
        for (let i in busUnit) {
          bUnit.push(busUnit[i].value)
        }

        let value
        if (userData.is_active === "active") {
          value = true
        } else if (userData.is_active === "inactive") {
          value = false
        }

        const data = {
          ...userData,
          sys_user_roles: Dta,
          is_azure_user: false,
          is_active: value,
          // sys_user_role_id: Dta,
          // sys_user_role_name: RoleName,
          // sys_user_business_unit: bUnit,
        }
        // console.log("data", data);

        dispatch(postAppSysUsers(data))


      } else {
        setButtonState(true)
        $('#createUserBtn').attr("disabled", false)
      }
    } else if (userData.azure_sso === true) {

      if (!userData.sys_user_name) {
        setCustErrors((prevState) => ({ ...prevState, sys_user_name: true }))
      }
      if (!userData.sys_user_roles) {
        setCustErrors((prevState) => ({ ...prevState, sys_user_roles: true }))
      }
      if (userData.sys_user_email) {
        const result = validateEmail(userData.sys_user_email)
        if (result) {
          setCustErrors((prevState) => ({ ...prevState, sys_user_email: false }))
        } else {
          setCustErrors((prevState) => ({ ...prevState, sys_user_email: true }))
          setButtonState(true)
          $('#createUserBtn').attr("disabled", false)
          return
        }
      }

      // console.log(userData);
      if (userData.sys_user_email && userData.sys_user_name  && userData.sys_user_roles) {

        let roleID = userData.sys_user_roles
        let Dta = []
        let RoleName = []
        for (let i in roleID) {
          Dta.push(roleID[i].value)
          RoleName.push(roleID[i].label)
        }

        let busUnit = userData.sys_user_business_unit
        let bUnit = []
        for (let i in busUnit) {
          bUnit.push(busUnit[i].value)
        }

        let value
        if (userData.is_active === "active") {
          value = true
        } else if (userData.is_active === "inactive") {
          value = false
        }

        const data = {
          ...userData,
          sys_user_roles: Dta,
          is_azure_user: true,
          is_active: value,
        }
        // console.log("data", data);

        dispatch(postAppSysUsers(data))


      } else {
        setButtonState(true)
        $('#createUserBtn').attr("disabled", false)
      }
    }
  }


  const [activeTab, setactiveTab] = useState("1")
  const [buttonState, setButtonState] = useState(true)

  const [userID, setUserID] = useState("")




  // Manage Role MultiSelect

  // const [usersID, setUserRoleID] = useState([])
  const [selectedMulti, setselectedMulti] = useState([]);
  register('user.sys_user_roles', { required: true });


  const [selectedRole, setSelectedRole] = useState([])
  const [accordionShow, setAccordionShow] = useState(false)

  const handlePostChange = (Data) => {
    let len = Data.length
    if (len > 0) {
      setAccordionShow(true)
    } else {
      setAccordionShow(false)
    }
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values

    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroup.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            setValue('user.sys_user_roles', postArray)
            setSelectedRole(postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue('user.sys_user_roles', postArray)
          setSelectedRole(postArray)
        }
      }
    });
    setselectedMulti({
      sys_role_id: postArray,
    });
  };


  const [optionGroup, setOptionGroup] = useState([{ options: [] }])
  const [roleList, setRoleList] = useState([])

  // Get Roles
  const sysRolesState = useSelector(state => state.AppSysRoles)

  useEffect(() => {
    setOptionGroup('')
    setselectedMulti([])
    if (sysRolesState.appSysRoles !== null) {
      setRoleList(sysRolesState.appSysRoles.result)
    }
  }, [sysRolesState])

  useEffect(() => {
    dispatch(getSysRoles({ filter: `{"is_active":true}`, limit: 0, skip: 0 }))
  }, [])



  useEffect(() => {
    if (roleList) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in roleList) {
        let data = {
          label: roleList[i].sys_role_name,
          value: roleList[i].sys_role_id
        }
        options.push(data)
      }
      setOptionGroup(options)
    }
  }, [roleList])


  // Business unit  MultiSelect

  const [busUnitListID, setBusinessUnitListID] = useState([])
  const [selectedMultiBus, setselectedMultiBus] = useState([]);
  // register('user.sys_user_business_unit', { required: true });

  const [optionGroupBus, setOptionGroupBus] = useState([{ options: [] }])
  const [businessUnitList, setBusinessUnitList] = useState([])

  const businessUnitState = useSelector(state => state.AppBusinessUnits)

  useEffect(() => {
    dispatch(getAppBusinessUnits({ filter: `{"is_active":true}`, limit: 0, skip: 0 }));
  }, [])

  useEffect(() => {
    setOptionGroupBus('')
    setselectedMultiBus([])
    if (businessUnitState.appBusinessUnits !== null) {
      setBusinessUnitList(businessUnitState.appBusinessUnits.result)
      setAppBusinessUnit(businessUnitState.appBusinessUnits.result)
    }
  }, [businessUnitState])

  useEffect(() => {
    if (businessUnitList) {
      let options = []
      options.push({ label: "Select All", value: "select_all" })
      for (let i in businessUnitList) {
        let data = {
          label: businessUnitList[i].app_business_unit_name,
          value: businessUnitList[i].app_businessunit_id,
        }
        options.push(data)
      }
      setOptionGroupBus(options)
    }
  }, [businessUnitList])


  const handlePostChangeBus = (Data) => {
    console.log(Data);
    const postArray = [];
    const selectedValues = new Set(); // Use a Set to store unique values

    Data.forEach((option) => {
      if (option.value === 'select_all') {
        const splicedOptions = optionGroupBus.slice(1);
        splicedOptions.forEach((option) => {
          const optionValue = option.value.toString();
          if (!selectedValues.has(optionValue)) {
            postArray.push(option);
            selectedValues.add(optionValue);
            setValue('user.sys_user_business_unit', postArray)
          }
        });
      } else {
        const optionValue = option.value.toString();
        if (!selectedValues.has(optionValue)) {
          postArray.push(option);
          selectedValues.add(optionValue);
          setValue('user.sys_user_business_unit', postArray)
        }
      }
    });
    setBusinessUnitListID(postArray)
    setselectedMultiBus({
      app_businessunit_id: postArray,
    });
  };


  // Get Permissions
  const [appBusinessUnit, setAppBusinessUnit] = useState([])
  const [sysRoles, setSysRoles] = useState([])


  // Accordions Tab State
  const [col, setCol] = useState([])
  const [activeTabAccord, setactiveTabAccord] = useState("");


  useEffect(() => {
    if (sysRolesState.appSysRoles !== null && selectedRole) {
      let resValue = sysRolesState.appSysRoles.result
      let Data = resValue.filter(items => selectedRole.map(val => val.value).includes(items.sys_role_id));
      setSysRoles(Data)
    }
  }, [sysRolesState, selectedRole])


  // Business Unit Division 
  const [businessUnitDivisions, setBusinessUnitDivisions] = useState([])
  const businessUnitDivisionState = useSelector(state => state.BUDivisionReducer)

  useEffect(() => {
    dispatch(getBusinessUnitDivisions({ filter: '{"is_active":true}', limit: 5, skip: 0 }));
  }, [])

  useEffect(() => {
    if (businessUnitDivisionState.BUDivisions !== null) {
      setBusinessUnitDivisions(businessUnitDivisionState.BUDivisions.result);
    }
  }, [businessUnitDivisionState])


  const [statusModal, setStatusModal] = useState(false)
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([])

  const handleDataSelection = (selectedData) => {
    // console.log("Selected Data:", selectedData);
    // setSelectedBusinessUnit(selectedData);

    let data = appBusinessUnit.filter(item => {
      return selectedData.businessUnits.includes(item.app_businessunit_id);
    })
    let newData = selectedBusinessUnit.map(item => ({ ...item }))
    let index = newData.findIndex(item => tempSysRoleID === Object.keys(item)[0])
    if (index >= 0) {
      newData[index] = {
        [tempSysRoleID]: data
      }
    } else {
      let obj = {
        [tempSysRoleID]: data
      }
      newData.push(obj)
    }
    setSelectedBusinessUnit(newData)
  };


  return (
    <React.Fragment>
      <BusinessUnitModal
        show={statusModal}
        onDeleteClick={() => setStatusModal(false)}
        onCloseClick={() => setStatusModal(false)}
        onDataSelect={handleDataSelection}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Create Portal User" breadcrumbItem=" Portal User" />
          <div className="checkout-tabs mb-4">
            <Row>
              <Col xl="2" sm="3">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                    // onClick={() => {
                    //   setactiveTab("1")
                    // }}
                    >
                      <i className="bx bx-buildings d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">User Details</p>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                      <NavLink
                          className={classnames({ active: activeTab === "2" })}
                      // onClick={() => {
                      //   setactiveTab("2")
                      // }}
                      >
                          <i className="bx bx-buildings d-block check-nav-icon mt-4 mb-2" />
                          <p className="font-weight-bold mb-4">Customers</p>
                      </NavLink>
                  </NavItem> */}
                </Nav>
              </Col>
              <Col xl="10" sm="9">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>

                      {/* Users */}

                      <TabPane tabId="1">
                        <Container fluid>
                          <Row>
                            <Col lg="12">
                              <Form>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="2" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Azure AD SSO</Label>
                                    <Col lg="9" md="8">
                                      <div className="form-check form-switch form-switch-lg mb-3">
                                        <input type="checkbox" className="form-check-input" id="azureStatus" onInput={SSOChange} defaultChecked={false} {...register('user.azure_sso')} />
                                        <label className="form-check-label" htmlFor="azureStatus">
                                          {azureSSO.name}
                                        </label>
                                      </div>
                                    </Col>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="2" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User Name <span style={{ color: "red" }}>*</span></Label>
                                    <Col lg="9" md="8">
                                      <input onInput={watchCompanyFields} type="text" autoComplete='off' className='form form-control' id='sys_user_name' {...register('user.sys_user_name', { required: true })} />
                                      {custErrors.sys_user_name && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row>
                                <Row>
                                  <FormGroup row>
                                    <Label lg="2" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User email<span style={{ color: "red" }}>*</span></Label>
                                    <Col lg="9" md="8">
                                      <input onInput={watchCompanyFields} type="email" autoComplete='off' className='form form-control' id='sys_user_email' {...register('user.sys_user_email', { required: true })} />
                                      {custErrors.sys_user_email && <p style={{ color: "red" }}>Valid email is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row>
                                {isPassword && <Row>
                                  <FormGroup row>
                                    <Label lg="2" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Password<span style={{ color: "red" }}>*</span></Label>
                                    <Col lg="9" md="8">
                                      <input onInput={watchCompanyFields} type="password" autoComplete='off' className='form form-control' id='sys_user_password' {...register('user.sys_user_password', { required: true })} />
                                      {(custErrors.sys_user_password) && <p style={{ color: "red" }}>This Field is required</p>}
                                    </Col>
                                  </FormGroup>
                                </Row>}
                                <>
                                  {/* <Row>
                                    <FormGroup row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Business Unit<span style={{ color: "red" }}>*</span></Label>
                                        <Col lg="5" md="6">
                                            <select onInput={watchCompanyFields} defaultValue="" className="form-select d-inline" aria-label="Default select example" id='sys_user_business_unit' {...register('user.sys_user_business_unit', { required: true })}>
                                                <option disabled hidden value="">Business unit</option>
                                                {businessUnitList.map((businessunit, i) => (
                                                    <option value={businessunit.app_businessunit_id} key={i}>{businessunit.app_business_unit_name}</option>
                                                ))}
                                            </select>
                                            {custErrors.sys_user_business_unit && <p style={{ color: "red" }}>This Field is required</p>}                                                                        </Col>
                                    </FormGroup>
                                </Row> */}
                                  {/* <Row>
                                    <FormGroup row>
                                        <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                            Business Unit
                                        </Label>
                                        <Col lg="5" md="6">
                                            <Select
                                                value={selectedMultiBus.app_businessunit_id}
                                                isMulti={true}
                                                onChange={handlePostChangeBus}
                                                options={optionGroupBus}
                                                name={"app_businessunit_id"}
                                                className="modal__input"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Row>
                                {busUnitListID.length >= 1 &&
                                    <Row>
                                        <Label lg="3" md="4"></Label>
                                        <Col lg="5" md="6">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th className="text">Selected BusinessUnit</th>
                                                        <th className="text-center">Permission</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {busUnitListID.map((item) => (
                                                        <tr key={item.value}>
                                                            <td>{item.label}</td>
                                                            <td>
                                                                <input type="checkbox" {...register(`user.read.${item.value}`)} />{' '} Read
                                                            </td>
                                                            <td>
                                                                <input type="checkbox" {...register(`user.write.${item.value}`)} />{' '} Write
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>} */}
                                </>

                                <Row>
                                  <FormGroup row>
                                    <Label lg="2" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                      Roles <span style={{ color: "red" }}>*</span>
                                      {/* Roles and Business Units<span style={{ color: "red" }}>*</span> */}
                                    </Label>
                                    <Col lg="9" md="8">
                                      <Select
                                        value={selectedMulti.sys_role_id}
                                        isMulti={true}
                                        onChange={handlePostChange}
                                        options={optionGroup}
                                        name={"sys_role_id"}
                                        className="modal__input"
                                        maxMenuHeight={250}
                                      />
                                    </Col>
                                  </FormGroup>
                                </Row>
                                {/* {accordionShow && <Row>
                                  <FormGroup row>
                                    <Label lg="2" md="4" className="d-flex justify-content-lg-end justify-content-md-end "></Label>
                                    <Col lg="9" md="8">
                                      <div className="accordion" id="accordion">
                                        {sysRoles.map((permission, index) => {
                                          const toggleAccordionCollapse = (index) => {
                                            let colItems = [...col]
                                            if (colItems[index] === null || colItems[index] === undefined || colItems[index] === false) {
                                              for (let i in colItems) {
                                                colItems[i] = false
                                              }
                                              colItems[index] = true
                                              setactiveTabAccord(index + '-' + "0")
                                            } else {
                                              colItems[index] = false
                                            }
                                            setCol(colItems)
                                          }

                                          return (
                                            <div key={index} className="accordion-item">
                                              <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                  className={classnames(
                                                    "accordion-button",
                                                    "fw-medium",
                                                    { collapsed: !col[index] }
                                                  )}
                                                  type="button"
                                                  onClick={() => toggleAccordionCollapse(index)}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {permission.sys_role_name}
                                                </button>
                                              </h2>
                                              <Collapse isOpen={col[index] ? col[index] : false} className="accordion-collapse">
                                                <div className="accordion-body">
                                                  <div className="text-muted">
                                                    <TabContent activeTab={activeTabAccord} className="p-3 text-muted">
                                                      <Row>
                                                        <Col className='d-flex justify-content-end'>
                                                          <Button className='me-2' id={index}
                                                            onClick={() => {
                                                              tempSysRoleID = permission.sys_role_id
                                                              setStatusModal(true)
                                                            }}
                                                            color="info" type="button"> Add Business Units </Button>
                                                        </Col>
                                                      </Row>
                                                      {(selectedBusinessUnit
                                                        && selectedBusinessUnit[selectedBusinessUnit.findIndex(item => Object.keys(item)[0] === permission.sys_role_id)]
                                                        && selectedBusinessUnit[selectedBusinessUnit.findIndex(item => Object.keys(item)[0] === permission.sys_role_id)][permission.sys_role_id]
                                                        && selectedBusinessUnit[selectedBusinessUnit.findIndex(item => Object.keys(item)[0] === permission.sys_role_id)][permission.sys_role_id].length > 0) &&
                                                        <Row>
                                                          <Col>
                                                            <div className="mb-0">
                                                              <Table striped hover>
                                                                <thead>
                                                                  <tr>
                                                                    <th>#</th>
                                                                    <th>Business Units </th>
                                                                    <th>Read</th>
                                                                    <th>Write</th>
                                                                  </tr>
                                                                </thead>
                                                                <tbody>
                                                                  {selectedBusinessUnit[selectedBusinessUnit.findIndex(item => Object.keys(item)[0] === permission.sys_role_id)][permission.sys_role_id].map((data, BUDIndex) => (
                                                                    <tr key={BUDIndex}>
                                                                      <td>{BUDIndex + 1}</td>
                                                                      <td>{data.app_business_unit_name}</td>
                                                                      <td><input type="checkbox" /></td>
                                                                      <td><input type="checkbox" /></td>
                                                                    </tr>
                                                                  ))}
                                                                </tbody>
                                                              </Table>
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                      }
                                                    </TabContent>
                                                  </div>
                                                </div>
                                              </Collapse>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </Col>
                                  </FormGroup>
                                </Row>} */}
                                <Row>
                                  <FormGroup row>
                                    <Label lg="2" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                    <Col lg="9" md="8">
                                      <select className="form-select d-inline" {...register('user.is_active')}>
                                        {/* <option hidden value="">Select Status</option> */}
                                        <option value="inactive">INACTIVE</option>
                                        <option value="active">ACTIVE</option>
                                      </select>
                                    </Col>
                                  </FormGroup>
                                </Row>
                              </Form>
                            </Col>
                          </Row>
                        </Container>
                      </TabPane>

                      {/* Customers */}

                      {/* <TabPane
                          tabId="2"
                          id="v-pills-payment"
                          role="tabpanel"
                          aria-labelledby="v-pills-payment-tab">
                          <Container fluid>
                              {customerTableState ?
                                  <TableContainer
                                      columns={customerColumns}
                                      data={owner}
                                      isGlobalFilter={true}
                                      isLinkCustomer={true}
                                      customPageSize={10}
                                      handleAddCustomer={handleAddCustomer}
                                      className="custom-header-css" />
                                  :
                                  <div>
                                      <h4 id="zzz" style={{ textDecoration: "underline" }}>Link Company</h4>
                                      <Row className="mt-4 d-flex justify-content-center">
                                          <Col lg="6">
                                              <Table striped bordered>
                                                  <thead>
                                                      <tr>
                                                          <th>#</th>
                                                          <th>Name</th>
                                                          <th className="text-center">Access</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {businessList.map((business, i) => (
                                                          <tr key={i}>
                                                              <th scope="row">{i + 1}</th>
                                                              <td>{business.app_business_name}</td>
                                                              <td className="text-center">
                                                                  <input type="checkbox"
                                                                      checked={business.access ? true : false}
                                                                      disabled={disabledCheckbox || (business.access ? true : false)}
                                                                      onChange={() => { }}
                                                                      onClick={(e) => updateBusinessAccess(business.access ? false : true, business.app_business_id)} />
                                                              </td>
                                                          </tr>
                                                      ))}
                                                  </tbody>
                                              </Table>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <div className="text-end">
                                              <Button className="mt-2" onClick={() => setCustomerTableState(true)} color="danger">Close</Button>
                                          </div>
                                      </Row>
                                  </div>}
                          </Container>
                      </TabPane> */}
                    </TabContent>
                  </CardBody>
                </Card>
                {/* <Col sm="6">
                        <Link
                            to="/userList"
                            className="btn text-muted d-none d-sm-inline-block btn-link"
                        >
                            <i className="mdi mdi-arrow-left me-1 text-danger" /> <span className="text-danger">Close</span>
                        </Link>
                    </Col> */}
                <Row className="ms-2">
                  <FormGroup row>

                    <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> </Label>
                    <Col lg="5" md="6">
                      {activeTab === "1" &&
                        <>
                          <Button className='me-2' id="backUserBtn" onClick={() => { history.goBack() }} color="secondary" type="button">
                            Back
                          </Button>
                          <Button className='ms-2' id="createUserBtn" onClick={createUser} color="success" type="button">{buttonState ? "Create" : <> Please Wait <i className='bx bx-loader-alt bx-spin'></i></>}</Button>
                        </>
                      }
                    </Col>
                  </FormGroup>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default CreateUser

