import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, CardBody } from "reactstrap"
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getSmtp, postSmtp, updateSmtp } from "store/actions";

function EmailSettings() {

    const { handleSubmit, register, setValue, formState: { errors } } = useForm()
    const dispatch = useDispatch();
    const [editState, setEditState] = useState(false)
    const [createState, setCreateState] = useState(true)
    const [triggeredCancel, setTriggeredCancel] = useState(false)

    const [permissions, setPermissions] = useState('')
    useEffect(() => {
        setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
    }, [])


    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const CreatePermission = (permissionAction.includes("system.settings.email.write")) ? true : false
    const UpdatePermission = (permissionAction.includes("system.settings.email.update")) ? true : false


    useEffect(() => {
        dispatch(getSmtp())
    }, [triggeredCancel])

    const smtpState = useSelector(state => state.SmtpReducer)

    useEffect(() => {
        if (smtpState.smtp !== null) {
            setValue("app_setting_id", smtpState.smtp.app_setting_id)
            setValue("app_smtp_user", smtpState.smtp.app_smtp_user)
            setValue("app_smtp_password", smtpState.smtp.app_smtp_password)
            setValue("app_smtp_hostaddress", smtpState.smtp.app_smtp_hostaddress)
            setValue("app_smtp_mailfrom", smtpState.smtp.app_smtp_mailfrom)
            setCreateState(false)
        }
        if (smtpState.isCreated) {
            dispatch(getSmtp())
            toast.success("Saved")
            setEditState(!editState)
            setCreateState(false)
        }
        else if (smtpState.isUpdated) {
            dispatch(getSmtp())
            toast.success("Updated")
            setEditState(!editState)
        }
    }, [smtpState])

    const submitSMTP = (data) => {
        if (createState) {
            dispatch(postSmtp({ data }))
        } else {
            dispatch(updateSmtp({ data }))
        }
    }

    const handleEditState = () => {
        setEditState(!editState)
    }

    const handleCancelEditState = () => {
        setTriggeredCancel(!triggeredCancel)
        setEditState(!editState)
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Email Settings"
                    handleEditState={(UpdatePermission || CreatePermission) && handleEditState}
                    handleCancelEditState={handleCancelEditState}
                    isEditSmtpBtn={!editState}
                    isShowEditBtnCreatePermission ={CreatePermission && createState ? true : false}
                    isShowEditBtnUpdatePermission ={UpdatePermission}
                    breadcrumbItem="Settings" />
                {permissions.includes("system.settings.email.read") ? <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(submitSMTP)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Username <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input readOnly={!editState} style={!editState ? { backgroundColor: "#D2D4D1" } : {}} className="form-control form-control-solid" type="text" {...register("app_smtp_user", { required: true })} />
                                                {errors.app_smtp_user && <span className="text-danger">This field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Password <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input readOnly={!editState} style={!editState ? { backgroundColor: "#D2D4D1" } : {}}  className="form-control form-control-solid" type="password" {...register("app_smtp_password", { required: true })} />
                                                {errors.app_smtp_password && <span className="text-danger">This field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Mail Host Address <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input readOnly={!editState} style={!editState ? { backgroundColor: "#D2D4D1" } : {}}  className="form-control form-control-solid" type="text" {...register("app_smtp_hostaddress", { required: true })} />
                                                {errors.app_smtp_hostaddress && <span className="text-danger">This field is required</span>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Mail From <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input readOnly={!editState} style={!editState ? { backgroundColor: "#D2D4D1" } : {}}  className="form-control form-control-solid" type="text" {...register("app_smtp_mailfrom", { required: true })} />
                                                {errors.app_smtp_mailfrom && <span className="text-danger">This field is required</span>}
                                            </Col>
                                        </FormGroup>

                                    </Row>
                                    {editState && <Row>
                                        <Col lg="6" className="text-center ms-5">
                                            {CreatePermission && createState && <Button color="success" type="submit">Create</Button>}
                                            {UpdatePermission && !createState && <Button color="success" type="submit">Update</Button>}
                                        </Col>
                                    </Row>}
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                    :
                    <Container fluid>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                                            <div>
                                                <h1><i className='bx bxs-lock'></i></h1>
                                                <h3>Access Denied</h3>
                                                <div className="text-muted">You don't have permission to access this module</div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </React.Fragment>
    )
}
export default EmailSettings