import {
    GET_ADDRESSES,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_FAIL,

    GET_ADDRESS,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_FAIL,

    GET_ALL_ADDRESS,
    GET_ALL_ADDRESS_SUCCESS,
    GET_ALL_ADDRESS_FAIL,

    POST_ADDRESS,
    POST_ADDRESS_SUCCESS,
    POST_ADDRESS_FAIL,

    DELETE_ADDRESS,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_FAIL,

    UPDATE_ADDRESS,
    UPDATE_ADDRESS_SUCCESS,
    UPDATE_ADDRESS_FAIL,
} from "./actionTypes";


/////////////////////////
//  Get sys Addresses  //
/////////////////////////


export const getAddresses = (payload) => ({
    type: GET_ADDRESSES,
    payload: payload
})

export const getAddressesSuccess = address => ({
    type: GET_ADDRESSES_SUCCESS,
    payload: address
})

export const getAddressesFail = error => ({
    type: GET_ADDRESSES_FAIL,
    payload: error
})

//////////////////////////////
// Get By id Addresses //
//////////////////////////////

export const getAddress = (payload) => ({
    type: GET_ADDRESS,
    payload: payload
});

export const getAddressSuccess = address => ({
    type: GET_ADDRESS_SUCCESS,
    payload: address,
});

export const getAddressFail = error => ({
    type: GET_ADDRESS_FAIL,
    payload: error,
});

//////////////////////////////
//    Get All Addresses    //
/////////////////////////////

export const getAllAddress = (payload) => ({
    type: GET_ALL_ADDRESS,
    payload: payload
});

export const getAllAddressSuccess = address => ({
    type: GET_ALL_ADDRESS_SUCCESS,
    payload: address,
});

export const getAllAddressFail = error => ({
    type: GET_ALL_ADDRESS_FAIL,
    payload: error,
});

////////////////////////
// Post sys Addresses     ///
////////////////////////

export const postAddress = (payload) => ({
    type: POST_ADDRESS,
    payload: payload
});

export const postAddressSuccess = address => ({
    type: POST_ADDRESS_SUCCESS,
    payload: address,
});

export const postAddressFail = error => ({
    type: POST_ADDRESS_FAIL,
    payload: error,
});

///////////////////////////
// Delete sys Addresses //
//////////////////////////

export const deleteAddress= (payload) => ({
    type: DELETE_ADDRESS,
    payload: payload
});

export const deleteAddressSuccess = () => ({
    type: DELETE_ADDRESS_SUCCESS,
});

export const deleteAddressFail = error => ({
    type: DELETE_ADDRESS_FAIL,
    payload: error,
});

///////////////////////////
// Update sys Addresses  //
///////////////////////////

export const updateAddress = (payload) => ({
    type: UPDATE_ADDRESS,
    payload: payload
});

export const updateAddressSuccess = payload => ({
    type: UPDATE_ADDRESS_SUCCESS,
    payload: payload
});

export const updateAddressFail = error => ({
    type: UPDATE_ADDRESS_FAIL,
    payload: error,
});


