

import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label } from "reactstrap"
import axios from 'axios';
import getHeader from 'config/header';
import Select from 'react-select'
import StatusModal from 'components/Common/StatusModal';
import { WithContext as ReactTags } from 'react-tag-input';
import { getAllDepartment, getAppBusinessUnits, getBusinessProductDetail, getBusinesses, getContact, getDepartment, getDepartments, updateBusinessProduct, updateContacts, updateDepartment } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search])
}

function viewContact() {

    const query = useQuery();
    const BusId = query.get("BusId")
    const { id } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const { setValue, register, handleSubmit, formState: { errors }, getValues } = useForm()

    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const UpadatePermission = (permissionAction.includes("system.sales.contact.update")) ? false : true
    const DeletePermission = (permissionAction.includes("system.sales.contact.delete")) ? false : true



    const [businessList, setBusinessList] = useState([])
    const [departments, setDepartment] = useState([])
    const businessesState = useSelector(state => state.businessReducer)
    const departmentState = useSelector(state => state.departmentReducer)

    useEffect(() => {
        dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: "", skip: '' }))
    }, [])

    useEffect(() => {
        if (businessesState.businesses !== null) {
            const res = businessesState.businesses.result
            setBusinessList(res)
        }
    }, [businessesState])


    useEffect(() => {
        if (departmentState.departments !== null) {
            setDepartment(departmentState.departments.result)
        }
    }, [departmentState])

    useEffect(() => {
        dispatch(getAllDepartment({ filter: `{"is_active":true`, limit: 0, skip: 0 }))
    }, [])

    const selectBusiness = (e) => {
        // setDepartment([])
        // let companyID = e.target.value
        // dispatch(getDepartments({ companyID, filter: true, limit: 0, skip: 0 }))
    }

    useEffect(() => {
        if (id && BusId) {
            let contactID = id
            let businessID = BusId
            const data = { contactID, businessID }
            dispatch(getContact(data))
        }
    }, [])

    const contactState = useSelector(state => state.contactsReducer)

    useEffect(() => {
        if (contactState.singlecontact !== null && departmentState.departments !== null) {
            const contactData = contactState.singlecontact
            setValue("app_contact_name", contactData.app_contact_name)
            setValue("app_business_id", contactData.app_business_id)
            setValue("app_department_id", contactData.app_department_id)
            setValue("position", contactData.position)
            setValue("department", contactData.department)
            setValue("office_phone", contactData.office_phone)
            setValue("mobile_phone", contactData.mobile_phone)
            setEditOfficePhone(contactData.office_phone)
            setEditMobilePhone(contactData.mobile_phone)
            setValue("app_contact_email", contactData.app_contact_email)
            setValue("remarks", contactData.remarks)
            if (contactData.is_active === true) {
                setValue("is_active", "active")
            } else if (contactData.is_active === false) {
                setValue("is_active", "inactive")
            }
        }
        if (contactState.isUpdated) {
            history.goBack()
        }
    }, [contactState, departmentState])




    const updateContact = (contactValue) => {
        if (contactValue.app_contact_name && contactValue.department && contactValue.position && contactValue.app_contact_email && contactValue.office_phone && contactValue.mobile_phone) {

            let statusValue
            if (contactValue.is_active === "active") {
                statusValue = true
            } else if (contactValue.is_active === "inactive") {
                statusValue = false
            }

            const value = {
                ...contactValue,
                is_active: statusValue,
                app_contact_email: (contactValue.app_contact_email).toLowerCase()
            }

            const data = {
                companyID: BusId,
                editContactID: id,
                value
            }
            dispatch(updateContacts(data))
        }
    }

    const [editOfficePhone, setEditOfficePhone] = useState()
    const [countryCode3, setCountryCode3] = useState("")
    register("office_phone")

    useEffect(() => {
        if (editOfficePhone) {
            setValue("office_phone", editOfficePhone)
        } else {
            setValue("office_phone", "")
        }
    }, [editOfficePhone])

    const changeCountry3 = (e) => {
        if (e !== undefined) {
            let value = getCountryCallingCode(e)
            setCountryCode3(value);
        } else {
            setCountryCode3()
        }
    }

    const isValidEditOfficePhone = () => {
        if (!editOfficePhone) {
            return false;
        }
        const phoneNumber = `${editOfficePhone}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

        return parsedPhoneNumber && parsedPhoneNumber.isValid();
    };

    const [editMobilePhone, setEditMobilePhone] = useState()
    const [countryCode4, setCountryCode4] = useState("")
    register("mobile_phone")

    useEffect(() => {
        if (editMobilePhone) {
            setValue("mobile_phone", editMobilePhone)
        } else {
            setValue("mobile_phone", "")
        }
    }, [editMobilePhone])

    const changeCountry4 = (e) => {
        if (e !== undefined) {
            let value = getCountryCallingCode(e)
            setCountryCode4(value);
        } else {
            setCountryCode4()
        }
    }

    const isValidEditMobilePhone = () => {
        if (!editMobilePhone) {
            return false;
        }
        const phoneNumber = `${editMobilePhone}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

        return parsedPhoneNumber && parsedPhoneNumber.isValid();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="View Contact" breadcrumbItem="Contacts" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(updateContact)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select disabled style={{ backgroundColor: "#D2D4D1" }} onInput={selectBusiness} className="form-select d-inline" aria-label="Default select example" {...register("app_business_id", { required: true })}>
                                                    <option value="">Company</option>
                                                    {businessList.map((business) => (
                                                        <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Site <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select disabled style={{ backgroundColor: "#D2D4D1" }} className={`form-select d-inline `} aria-label="Default select example" {...register("app_department_id", { required: true })}>
                                                    <option hidden value="">Select the Site</option>
                                                    {departments.map((department) => (
                                                        <option key={department.app_department_id} value={department.app_department_id}>{department.app_department_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_department_id && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Name <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="Enter Contact Person Name" {...register(`app_contact_name`, { required: true })} />
                                                {errors.app_contact_name && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Position <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="Enter Position" {...register(`position`, { required: true })} />
                                                {errors.position && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Department <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input readOnly style={{ backgroundColor: "#D2D4D1" }} className={`form form-control`} placeholder="Enter Department" {...register(`department`, { required: true })} />
                                                {errors.department && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row >
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Email <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input readOnly style={{ backgroundColor: "#D2D4D1" }} type="email" className={`form form-control`} placeholder="Enter Email" {...register(`app_contact_email`, { required: true })} />
                                                {errors.app_contact_email && <p style={{ color: "red" }}>Valid Email is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    {/* <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Office <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input readOnly style={ { backgroundColor: "#D2D4D1" } } maxLength={"20"} className={`form form-control `} placeholder="Enter office phone" {...register(`office_phone`, { required: true })} />
                                                {errors.office_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Mobile <span style={{ color: "red" }}>*</span>
                                            </Label>
                                            <Col lg="5" md="6">
                                                <input readOnly style={ { backgroundColor: "#D2D4D1" } } maxLength={"20"} className={`form form-control`} placeholder="Enter mobile phone" {...register(`mobile_phone`, { required: true })} />
                                                {errors.mobile_phone && <p style={{ color: 'red' }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row> */}


                                    <Row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                            Office <span style={{ color: "red" }}>*</span> {countryCode3 && <span style={{ color: "steelblue" }}>{`(+${countryCode3})`}</span>}
                                        </Label>
                                        <Col lg="5" md="6">
                                            <FormGroup row
                                                className="form form-control form-phone" >
                                                <PhoneInput
                                                    disabled
                                                    style={{ backgroundColor: "#D2D4D1" }}
                                                    onCountryChange={e => changeCountry3(e)}
                                                    placeholder="Enter Office Phone Number"
                                                    value={editOfficePhone}
                                                    onChange={setEditOfficePhone} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                            Mobile <span style={{ color: "red" }}>*</span>  {countryCode4 && <span style={{ color: "steelblue" }}>{`(+${countryCode4})`}</span>}
                                        </Label>
                                        <Col lg="5" md="6">
                                            <FormGroup row
                                                className="form form-control form-phone" >
                                                <PhoneInput
                                                    disabled
                                                    style={{ backgroundColor: "#D2D4D1" }}
                                                    onCountryChange={e => changeCountry4(e)}
                                                    placeholder="Enter Mobile Phone Number"
                                                    value={editMobilePhone}
                                                    onChange={setEditMobilePhone} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">
                                                Remarks
                                            </Label><Col lg="5" md="6">
                                                <input readOnly style={{ backgroundColor: "#D2D4D1" }} className="form form-control" placeholder="Enter Remarks" {...register(`remarks`, { required: true })} />
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="5" md="6">
                                                <select disabled style={{ backgroundColor: "#D2D4D1" }} className="form-select d-inline" {...register('is_active', { required: true })}>
                                                    {/* <option hidden value="">Select Status</option> */}
                                                    <option value="inactive">INACTIVE</option>
                                                    <option value="active">ACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row className="mt-4">
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default viewContact


