import {
    GET_APP_ACCOUNT,
    GET_APP_ACCOUNT_SUCCESS,
    GET_APP_ACCOUNT_FAIL,
  
    POST_APP_ACCOUNT,
    POST_APP_ACCOUNT_SUCCESS,
    POST_APP_ACCOUNT_FAIL,
  
    DELETE_APP_ACCOUNT,
    DELETE_APP_ACCOUNT_SUCCESS,
    DELETE_APP_ACCOUNT_FAIL,
  
    GET_APP_ACCOUNT_DETAIL_SUCCESS,
    GET_APP_ACCOUNT_DETAIL_FAIL,
    GET_APP_ACCOUNT_DETAIL,
    
    UPDATE_APP_ACCOUNT_SUCCESS,
    UPDATE_APP_ACCOUNT_FAIL,
    UPDATE_APP_ACCOUNT,
  } from "./actionTypes";
  
  const INIT_STATE = {
    appAccount: null,
    appAccounts: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
  };
  
  const AppAccounts = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_APP_ACCOUNT:
        return {
          ...state,
          loading: true,
          appAccounts: null,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_ACCOUNT_SUCCESS:
        return {
          ...state,
          loading: false,
          appAccounts: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
      case GET_APP_ACCOUNT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isCreated: false,
          isDeleted: false,
          isUpdated: false
        };
        case POST_APP_ACCOUNT:
          return {
            ...state,
            loading: true,
            appAccount: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case POST_APP_ACCOUNT_SUCCESS:
          return {
            ...state,
            loading: false,
            appAccount: action.payload,
            isCreated: true,
            isDeleted: false,
            isUpdated: false
          };
        case POST_APP_ACCOUNT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case DELETE_APP_ACCOUNT:
          return {
            ...state,
            loading: true,
            appAccount: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case DELETE_APP_ACCOUNT_SUCCESS:
          return {
            ...state,
            loading: false,
            appAccount: null,
            isCreated: false,
            isDeleted: true,
            isUpdated: false
          };
        case DELETE_APP_ACCOUNT_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_APP_ACCOUNT_DETAIL:
          return {
            ...state,
            loading: true,
            appAccount: null,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_APP_ACCOUNT_DETAIL_SUCCESS:
          return {
            ...state,
            loading: false,
            appAccount: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case GET_APP_ACCOUNT_DETAIL_FAIL:
          return {
            ...state,
            loading: false,
            error: action.payload,
            isCreated: false,
            isDeleted: false,
            isUpdated: false
          };
        case UPDATE_APP_ACCOUNT:
            return {
              ...state,
              loading: true,
              appAccount: null,
              isCreated: false,
              isDeleted: false,
              isUpdated: false
            };
        case UPDATE_APP_ACCOUNT_SUCCESS:
            return {
              ...state,
              loading: false,
              appAccount: action.payload,
              isCreated: false,
              isDeleted: false,
              isUpdated: true
            };
        case UPDATE_APP_ACCOUNT_FAIL:
            return {
              ...state,
              loading: false,
              error: action.payload,
              isCreated: false,
              isDeleted: false,
              isUpdated: false
            };
      default:
        return state;
    }
  };
  
  export default AppAccounts;
  
  
