import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import { authProtectedRoutes, publicRoutes } from './routes';
import Authmiddleware from './routes/route';
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import './assets/scss/theme.scss';
import { useState } from 'react';
import fakeBackend from './helpers/AuthType/fakeBackend';

fakeBackend();


const App = (props) => {
  
  function getLayout() {
    return VerticalLayout;
  }

  const Layout = getLayout();

  return (
    <React.Fragment>
      <MsalProvider instance={props.instance}>
        <Router basename={"/platform"}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
        <ToastContainer />
        {/* <WrappedView /> */}
      </MsalProvider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
  instance: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
