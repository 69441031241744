import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip, } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../../components/Common/DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../../components/Common/TableContainer';



// Column
import {
   ContractsName, ContractsStatus, CreatedDate, ContractDescription, ContractBusiness, ContractsSite } from './ContractsListCol';
import axios from "axios";
import { getUserProfileSuccess } from "store/actions";

import getHeader from "config/header";

const Contracts = props => {

  const history = useHistory()

  //meta title
  document.title = "Contracts | Maxims B2B Portal";

  const dispatch = useDispatch();


  const [contracts, setContracts]= useState([])

useEffect(() => {

},[])



  // Company Column
  const columns = useMemo(
    () => [
      {
        Header: 'Contracts Name',
        accessor: 'app_contract_name',
        filterable: true,
        Cell: (cellProps) => {
          return <ContractsName {...cellProps} />;
        }
      },
      {
        Header:'Company',
        accessor : 'app_contract_business',
        filterable : true,
        Cell:(cellProps) =>{
          return<ContractBusiness {...cellProps} />
        }
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <ContractsStatus {...cellProps} />;
        }
      },

      {
        Header: 'Site',
        accessor: 'department_id',
        filterable: true,
        Cell: (cellProps) => {
          return <ContractsSite {...cellProps } busID = {cellProps.row.original.app_contract_business} />;
        }
      },
      {
        Header: 'Created Date',
        accessor: 'created_on',
        filterable: true,
        Cell: (cellProps) => {
          return <CreatedDate {...cellProps} />;
        }
      },
      
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const contractData = cellProps.row.original;
                  history.push(`/updateContract/${contractData.app_contract_id}`)
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const contractData = cellProps.row.original;
                  onClickDelete(contractData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);
  const [contractTBD, setContractTBD] = useState('')

  const onClickDelete = (contracts) => {
    setContractTBD(contracts);
    setDeleteModal(true);
  };

  const handleDeleteCustomer = () => {
  
  };


 

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Contracts" breadcrumbItem="Contracts" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={contracts}
                    isGlobalFilter={true}
                    isContractsList={true}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Contracts.propTypes = {
  customers: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
};

export default Contracts;


