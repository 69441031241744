import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";
import TableContainer from '../../../components/Common/TableContainer';
import { PricingModelType, PricingModelCode, PricingModelName, Status, ValidPeriod, ApprovalStatus } from './PricingModelsListCol';
import axios from "axios";
import getHeader from "config/header";
import expiredSessionHandler from "common/expiredSessionHandler";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getPricingmodels } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const PricingModelsList = props => {


  const query = useQuery();
  const page = query.get("skip")
  const pageLimit = query.get("limit")
  const queryMultistatus = query.get("queryMultistatus")

  const dispatch = useDispatch();
  const history = useHistory()
  document.title = "Pricing Models | Maxims B2B Portal ";

  const [permissions, setPermissions] = useState('')
  const [pageCount, setPageCount] = useState(1);
  const [pricingModelList, setPricingModelList] = useState([])

  const pricingModelState = useSelector(state => state.PricingModelReducer)

  useEffect(() => {
    setPermissions(JSON.parse(sessionStorage.getItem('userCred')))
  }, [])


  useEffect(() => {
    if (pricingModelState.PricingModels !== null) {
      setPricingModelList(pricingModelState.PricingModels.result);
      setPageCount(Math.ceil(pricingModelState.PricingModels.summary.filteredsize / pageLimit) == 0 ? 1
        :
        Math.ceil(pricingModelState.PricingModels.summary.filteredsize / pageLimit)
      );
    }
    if (pricingModelState.isDeleted) {
      handlePageClick(page);
    }
    if (pricingModelState.error) {
    }
  }, [pricingModelState])


  //========================global filter=============================//

  const [customFilterData, setCustomFilterData] = useState("")
  useEffect(() => {
    handlePageClick()
  }, [page, pageLimit, queryMultistatus, customFilterData])

  useEffect(() => {
    if (customFilterData.length > 0) {
      history.push(`/pricingModels?skip=${0}&&limit=${pageLimit}&&queryMultistatus=${"all"}`)
    }
  }, [customFilterData])

  const handlePageClick = (index) => {

    let filter1 = `{"$or":[
      {"pricingmodel_keyid":{"$regex": "${customFilterData}", "$options": "i"}},
      {"app_pricing_model_name": {"$regex": "${customFilterData}", "$options": "i"}},
      {"app_pricing_model_type":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`
    let filter2 = `{"approval_status":"${queryMultistatus}","$or":[
      {"pricingmodel_keyid":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_pricing_model_name":{"$regex":"${customFilterData}","$options":"i"}},
      {"app_pricing_model_type":{"$regex":"${customFilterData}","$options":"i"}}
    ]}`

    let skips = 0;
    if (queryMultistatus === "all") {
      if (page > 0) {
        skips = page * pageLimit;
        dispatch(getPricingmodels({ filter: filter1, limit: pageLimit, skip: skips }));
      }
      else {
        dispatch(getPricingmodels({ filter: filter1, limit: pageLimit, skip: 0 }));
      }
    } else {
      if (page > 0) {
        skips = page * pageLimit;
        dispatch(getPricingmodels({ filter: filter2, limit: pageLimit, skip: skips }));
      }
      else {
        dispatch(getPricingmodels({ filter: filter2, limit: pageLimit, skip: 0 }));
      }

    }
  }


  const columns = useMemo(
    () => [
      {
        Header: 'Pricing Model ID',
        accessor: 'pricingmodel_keyid',
        filterable: true,
        Cell: (cellProps) => {
          return <PricingModelCode {...cellProps} />;
        }
      },
      {
        Header: 'Pricing Model Name',
        accessor: 'app_pricing_model_name',
        filterable: true,
        Cell: (cellProps) => {
          return <PricingModelName {...cellProps} />;
        }
      },
      {
        Header: 'Pricing Model Type',
        accessor: 'app_pricing_model_type',
        filterable: true,
        Cell: (cellProps) => {
          return <PricingModelType {...cellProps} />;
        }
      },
      {
        Header: 'Valid Period',
        accessor: 'app_pricing_model_start_date',
        filterable: true,
        Cell: (cellProps) => {
          return <ValidPeriod EndDate={cellProps.row.original.app_pricing_model_end_date} {...cellProps} />;
        }
      },
      {
        Header: 'Approval Status',
        accessor: 'approval_status',
        filterable: true,
        Cell: (cellProps) => {
          return <ApprovalStatus {...cellProps} />;
        }
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        filterable: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'Actions',
        Cell: (cellProps) => {
          const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
          return (
            <div className="d-flex gap-3">
              {(permissionAction.includes("system.product.pricingmodel.read")) ?
                <>
                  <button
                    onClick={() => {
                      const pricingModelsData = cellProps.row.original;
                      history.push(`/viewPricingModels/${pricingModelsData.pricingmodel_id}`)
                    }} className="btn btn-success btn-sm">View</button>
                </> : <div className="text-muted">No Access</div>
              }
              {(permissionAction.includes("system.product.pricingmodel.read")) && (permissionAction.includes("system.product.pricingmodel.update")) ||
                (permissionAction.includes("system.product.pricingmodel.read")) && (permissionAction.includes("system.product.pricingmodel.delete"))
                ?
                <>
                  <button
                    onClick={() => {
                      const pricingModelsData = cellProps.row.original;
                      history.push(`/updatePricingModels/${pricingModelsData.pricingmodel_id}`)
                    }} className="btn btn-info btn-sm">Edit</button>
                </> : <div className="text-muted">No Access</div>
              }

            </div>
          );
        }
      },
    ], [pricingModelList]
  );



  return (
    <React.Fragment>

      <div className="page-content">
        {permissions.includes("system.product.pricingmodel.read") ? <Container fluid>
          <Breadcrumbs title="Pricing Models" breadcrumbItem="Products" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={pricingModelList}
                    isGlobalFilter={true}
                    isAddPricingModels={permissions.includes("system.product.pricingmodel.write") ? true : false}
                    className="custom-header-css"
                    customPageCount={pageCount}
                    customGlobalFilterDataTransfer={(data) => setCustomFilterData(data)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
          :
          <Container fluid>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-center align-items-center text-center" style={{ height: "500px" }}>
                      <div>
                        <h1><i className='bx bxs-lock'></i></h1>
                        <h3>Access Denied</h3>
                        <div className="text-muted">You don't have permission to access this module</div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
    </React.Fragment>
  );
};

export default PricingModelsList;
