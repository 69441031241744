import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { set, useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Select from 'react-select'

import Breadcrumbs from "components/Common/Breadcrumb";
import { Card, Col, Row, Button, Container, Form, FormGroup, Label, Input } from "reactstrap"

import axios from 'axios';
import getHeader from 'config/header';
import StatusModal from 'components/Common/StatusModal'
import { getBusinesses, getAppRoles, getAppUser, getAppUserSuccess, getAppUsers, getDepartments } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import * as url from "./../../../helpers/url_helper";

function UpdateCustomerUser() {
    const { id } = useParams();
    const history = useHistory()
    const dispatch = useDispatch()
    const { setValue, register, handleSubmit, formState: { errors, isSubmitSuccessful }, getValues } = useForm()
    const [roles, setRoles] = useState([])
    const [businessList, setBusinessList] = useState([])

    const permissionAction = JSON.parse(sessionStorage.getItem('userCred'))
    const UpadatePermission = (permissionAction.includes("system.administration.customerusers.update")) ? false : true
    const DeletePermission = (permissionAction.includes("system.administration.customerusers.delete")) ? false : true

    // Get 
    const appRolesState = useSelector(state => state.AppRoles)
    const appUsersState = useSelector(state => state.appUserReducer)
    const businessesState = useSelector(state => state.businessReducer)
    const departmentState = useSelector(state => state.departmentReducer)

    useEffect(() => {

        dispatch(getAppRoles({ filter: `{"is_active":true}`, limit:0, skip: 0 }))
        dispatch(getBusinesses({ filter: `{"is_active":${true}}`, limit: "", skip: '' }))
    }, [])

    useEffect(() => {
        if (businessesState.businesses !== null) {
            setBusinessList(businessesState.businesses.result)
        }
        if (departmentState.departments !== null) {
            setSiteList(departmentState.departments.result)
        }
        if (appRolesState.appRoles !== null) {
            // setOptionGroupRole('')
            // setselectedMultiRole([])
            setRoles(appRolesState.appRoles.result)
        }

    }, [businessesState, departmentState, appRolesState])

    useEffect(() => {
        if (id && roles && businessList) {
            dispatch(getAppUser(id))
        }
    }, [id, businessList, roles])

    useEffect(() => {
        if (appUsersState.appUser !== null) {
            let customerUserData = appUsersState.appUser
            setValue("app_business_id", customerUserData.app_business_id)
            setValue("app_user_keyid", customerUserData.app_user_keyid)
            setValue("app_user_email", customerUserData.app_user_email)
            setValue("app_user_name", customerUserData.app_user_name)
            setselectedMulti(customerUserData.app_user_department)
            setValue("app_user_department", customerUserData.app_user_department)
            sysRolesID(customerUserData.app_user_roles ? customerUserData.app_user_roles : [])
            if (customerUserData.is_active === true) {
                setValue("is_active", "active")
            } else if (customerUserData.is_active === false) {
                setValue("is_active", "inactive")
            }

            if (customerUserData.is_lock_app_user === true) {
                setValue("is_lock_app_user", "active")
            } else if (customerUserData.is_lock_app_user === false) {
                setValue("is_lock_app_user", "inactive")
            }

            let companyID = customerUserData.app_business_id
            if (companyID) {
                dispatch(getDepartments({ companyID, filter: `{"is_active":true}`, limit: 0, skip: 0 }))
            }
        }
    }, [appUsersState])

    // Manage Departments's MultiSelect
    const [selectedMulti, setselectedMulti] = useState([]);
    const [siteList, setSiteList] = useState([])
    const [optionGroup, setOptionGroup] = useState([{ options: [] }])
    register('app_user_department', { required: true });

    // const [usersID, setUserRoleID] = useState([])
    const [selectedMultiRole, setselectedMultiRole] = useState([]);
    register('app_user_roles', { required: true });
    const [optionGroupRole, setOptionGroupRole] = useState([{ options: [] }])

    const selectBusiness = (e) => {
        setOptionGroup('')
        setselectedMulti([])
        let companyID = e.target.value
        dispatch(getDepartments({ companyID, filter: true, limit: 0, skip: 0 }))
    }

    useEffect(() => {
        if (siteList) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in siteList) {
                let data = {
                    label: siteList[i].app_department_name,
                    value: siteList[i].app_department_id
                }
                options.push(data)
            }
            setOptionGroup(options)
        }
        if (roles) {
            let options = []
            options.push({ label: "Select All", value: "select_all" })
            for (let i in roles) {
                let data = {
                    label: roles[i].app_role_name,
                    value: roles[i].app_role_id
                }
                options.push(data)
            }
            setOptionGroupRole(options)
        }
    }, [siteList, roles])


    const sysRolesID = (Items) => {
        if (Items.length > 0) {
            let Data = Items.map((item) => {
                let role = roles.find(role => role.app_role_id === item)
                if (role) {
                    return {
                        label: role.app_role_name,
                        value: role.app_role_id
                    }
                }
            }).filter(Boolean)
            setselectedMultiRole(Data)
            setValue("app_user_roles", Data)
        }
    }

    const handlePostChange = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroup.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_user_department', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_user_department', postArray)
                }
            }
        });
        setselectedMulti({
            app_department_id: postArray,
        });
    };

    const handlePostChangeRole = (Data) => {
        const postArray = [];
        const selectedValues = new Set(); // Use a Set to store unique values

        Data.forEach((option) => {
            if (option.value === 'select_all') {
                const splicedOptions = optionGroupRole.slice(1);
                splicedOptions.forEach((option) => {
                    const optionValue = option.value.toString();
                    if (!selectedValues.has(optionValue)) {
                        postArray.push(option);
                        selectedValues.add(optionValue);
                        setValue('app_user_roles', postArray)
                    }
                });
            } else {
                const optionValue = option.value.toString();
                if (!selectedValues.has(optionValue)) {
                    postArray.push(option);
                    selectedValues.add(optionValue);
                    setValue('app_user_roles', postArray)
                }
            }
        });

        setselectedMultiRole({
            app_role_id: postArray,
        });
    };


    // status change
    const [confirmStatus, setConfirmStatus] = useState()
    const statusChange = (e) => {
        setConfirmStatus(e.target.value)
        if (e.target.value === "active") {
            setStatusTBC(true)
            setStatusModal(true)
        } else if (e.target.value === "inactive") {
            setStatusTBC(false);
            setStatusModal(true)
        }

    }

    const [statusModal, setStatusModal] = useState(false)
    const [statusTBC, setStatusTBC] = useState()

    const handleStatus = () => {
        setValue("is_active", confirmStatus)
        setStatusModal(false)
    }

    const closeClick = () => {
        const value = getValues("is_active");
        if (value === "inactive") {
            let dta = "active"
            setValue("is_active", dta)
            setStatusModal(false)
            console.log("singleValue", dta);
        } else if (value === "active") {
            let dta = "inactive"
            setValue("is_active", dta)
            setStatusModal(false)
            console.log("singleValue", dta);
        }

    }

    const [confirmLockStatus, setConfirmLockStatus] = useState()
    const lockStatusChange = (e) => {
        setConfirmLockStatus(e.target.value)
        if (e.target.value === "active") {
            setStatusLockTBC(true)
            setStatusLockModal(true)
        } else if (e.target.value === "inactive") {
            setStatusLockTBC(false);
            setStatusLockModal(true)
        }

    }

    const [statusLockModal, setStatusLockModal] = useState(false)
    const [statusLockTBC, setStatusLockTBC] = useState()

    const handleLockStatus = () => {
        setValue("is_lock_app_user", confirmLockStatus)
        setStatusLockModal(false)
    }

    const closeClickLock = () => {
        const value = getValues("is_lock_app_user");
        if (value === "inactive") {
            let dta = "active"
            setValue("is_lock_app_user", dta)
            setStatusLockModal(false)
            console.log("singleValue", dta);
        } else if (value === "active") {
            let dta = "inactive"
            setValue("is_lock_app_user", dta)
            setStatusLockModal(false)
            console.log("singleValue", dta);
        }

    }

    const updateUser = (data) => {
        let roleID = data.app_user_roles
        let Dta = []
        for (let i in roleID) {
            Dta.push(roleID[i].value)
        }

        let value
        if (data.is_active === "active") {
            value = true
        } else if (data.is_active === "inactive") {
            value = false
        }

        let lockValue

        if (data.is_lock_app_user === "active") {
            lockValue = true
            let Attempt = { failed_attempt: 999 };
            const token = sessionStorage.getItem('authUser')
            axios.put(url.API_BASE_URL+url.LOGIN_ATTEMPT+"/"+data.app_user_email, Attempt, getHeader(token))
                .then((failRes) => {
                    //       console.log("failed_attempt", failRes.data.failed_attempt);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (data.is_lock_app_user === "inactive") {
            lockValue = false

            let Attempt = {
                failed_attempt: 0,
                lock_upto: new Date(),
                otp_failed_attempt: 0,
                otp_lock_upto: new Date()
            };
            const token = sessionStorage.getItem('authUser')
            axios.put(url.API_BASE_URL+url.LOGIN_ATTEMPT+"/"+ data.app_user_email, Attempt, getHeader(token))
                .then((failRes) => {
                    //  console.log("failed_attempt", failRes.data.otp_failed_attempt);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        const values = {
            ...data,
            app_user_roles: Dta,
            is_active: value,
            is_lock_app_user: lockValue
        }
        const token = sessionStorage.getItem('authUser')
        axios.put(url.API_BASE_URL+url.APP_USER_URL+"/"+id, values, getHeader(token))
            .then(res => {
                history.goBack()
            }).catch(err => {
                console.log(err);
            })
    }

    const customStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: "#D2D4D1", // Change this to your desired background color
        }),
    };

    return (
        <React.Fragment>
            <StatusModal
                show={statusModal}
                onDeleteClick={handleStatus}
                onCloseClick={closeClick}
                data={statusTBC}
            />
            <StatusModal
                show={statusLockModal}
                onDeleteClick={handleLockStatus}
                onCloseClick={closeClickLock}
                lockData={statusLockTBC}
            />
            <div className="page-content">
                <Breadcrumbs title="Update Customer User" breadcrumbItem=" Customer User" />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card body>
                                <Form onSubmit={handleSubmit(updateUser)}>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User ID<span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={{ backgroundColor: "#D2D4D1" }} readOnly type="text" autoComplete='off' className='form form-control' {...register('app_user_keyid', { required: true })} />
                                                {errors.app_user_keyid && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User Name <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} type="text" className='form form-control' {...register('app_user_name', { required: true })} />
                                                {errors.app_user_name && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">User Email <span style={{ color: "red" }}>*</span></Label>
                                            <Col lg="5" md="6">
                                                <input style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} readOnly={UpadatePermission} type="text" className='form form-control' {...register('app_user_email', { required: true })} />
                                                {errors.app_user_email && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Role
                                            </Label>
                                            <Col lg="5" md="6">
                                                {UpadatePermission ?
                                                    <Select
                                                        isDisabled
                                                        styles={customStyles}
                                                        value={selectedMultiRole.length > 0 ? selectedMultiRole : selectedMultiRole.app_role_id}
                                                        isMulti={true}
                                                        onChange={handlePostChangeRole}
                                                        options={optionGroupRole}
                                                        name={"app_role_id"}
                                                        className="modal__input"
                                                    /> :
                                                    <Select
                                                        value={selectedMultiRole.length > 0 ? selectedMultiRole : selectedMultiRole.app_role_id}
                                                        isMulti={true}
                                                        onChange={handlePostChangeRole}
                                                        options={optionGroupRole}
                                                        name={"app_role_id"}
                                                        className="modal__input"
                                                    />}

                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Company <span style={{ color: "red" }}>*</span> </Label>
                                            <Col lg="5" md="6">
                                                <select style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={UpadatePermission} onInput={selectBusiness} className="form-select d-inline" aria-label="Default select example" {...register("app_business_id", { required: true })}>
                                                    <option value="">Company</option>
                                                    {businessList.map((business) => (
                                                        <option key={business.app_business_id} value={business.app_business_id}>{business.app_business_name}</option>
                                                    ))}
                                                </select>
                                                {errors.app_business_id && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="  d-flex justify-content-lg-end justify-content-md-end " >
                                                Sites
                                            </Label>
                                            <Col lg="5" md="6">

                                                {/* {console.log(selectedMulti)} */}
                                                {UpadatePermission ?
                                                    <Select
                                                        isDisabled
                                                        styles={customStyles}
                                                        value={selectedMulti.length > 0 ? selectedMulti : selectedMulti.app_department_id}
                                                        isMulti={true}
                                                        onChange={handlePostChange}
                                                        options={optionGroup}
                                                        name={"app_department_id"}
                                                        className="modal__input"
                                                    /> :
                                                    <Select
                                                        value={selectedMulti.length > 0 ? selectedMulti : selectedMulti.app_department_id}
                                                        isMulti={true}
                                                        onChange={handlePostChange}
                                                        options={optionGroup}
                                                        name={"app_department_id"}
                                                        className="modal__input"
                                                    />}
                                                {errors.app_user_department && <p style={{ color: "red" }}>This Field is required</p>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end ">Status</Label>
                                            <Col lg="5" md="6">
                                                <select style={DeletePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={DeletePermission} onInput={(e) => { statusChange(e) }} className="form-select d-inline" {...register('is_active')}>
                                                    <option hidden value="">Select Status</option>
                                                    <option value="active">ACTIVE</option>
                                                    <option value="inactive">INACTIVE</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end "> Lock Status</Label>
                                            <Col lg="5" md="6">
                                                <select style={UpadatePermission ? { backgroundColor: "#D2D4D1" } : {}} disabled={UpadatePermission} onInput={(e) => { lockStatusChange(e) }} className="form-select d-inline" {...register('is_lock_app_user')}>
                                                    <option hidden value="">Select Status</option>
                                                    <option value="active">Locked</option>
                                                    <option value="inactive">Unlock</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup row>
                                            <Label lg="3" md="4" className="d-flex justify-content-lg-end justify-content-md-end  "></Label>
                                            <Col lg="5" md="6">
                                                <Button color='secondary' type='button' onClick={() => history.goBack()}>
                                                    Back
                                                </Button>
                                                {!UpadatePermission && <Button color='success' type='submit' className='m-2'>
                                                    Update
                                                </Button>}
                                            </Col>
                                        </FormGroup>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UpdateCustomerUser