import {
    GET_BUSINESS_UNIT_DIVISIONS,
    GET_BUSINESS_UNIT_DIVISIONS_SUCCESS,
    GET_BUSINESS_UNIT_DIVISIONS_FAIL,

    GET_BUSINESS_UNIT_DIVISION,
    GET_BUSINESS_UNIT_DIVISION_SUCCESS,
    GET_BUSINESS_UNIT_DIVISION_FAIL,

    POST_BUSINESS_UNIT_DIVISION,
    POST_BUSINESS_UNIT_DIVISION_SUCCESS,
    POST_BUSINESS_UNIT_DIVISION_FAIL,

    DELETE_BUSINESS_UNIT_DIVISION,
    DELETE_BUSINESS_UNIT_DIVISION_SUCCESS,
    DELETE_BUSINESS_UNIT_DIVISION_FAIL,

    UPDATE_BUSINESS_UNIT_DIVISION,
    UPDATE_BUSINESS_UNIT_DIVISION_SUCCESS,
    UPDATE_BUSINESS_UNIT_DIVISION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    BUDivisions: null,
    BUDivision: null,
    singleBUDivision: null,
    loading: false,
    isCreated: false,
    isDeleted: false,
    isUpdated: false,
    error: {},
};

const BUDivisionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BUSINESS_UNIT_DIVISIONS:
            return {
                ...state,
                loading: true,
                BUDivisions: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESS_UNIT_DIVISIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                BUDivisions: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESS_UNIT_DIVISIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_BUSINESS_UNIT_DIVISION:
            return {
                ...state,
                loading: true,
                BUDivision: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case POST_BUSINESS_UNIT_DIVISION_SUCCESS:
            return {
                ...state,
                loading: false,
                BUDivision: action.payload,
                isCreated: true,
                isDeleted: false,
                isUpdated: false
            };
        case POST_BUSINESS_UNIT_DIVISION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_BUSINESS_UNIT_DIVISION:
            return {
                ...state,
                loading: true,
                BUDivision: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case DELETE_BUSINESS_UNIT_DIVISION_SUCCESS:
            return {
                ...state,
                loading: false,
                BUDivision: null,
                isCreated: false,
                isDeleted: true,
                isUpdated: false
            };
        case DELETE_BUSINESS_UNIT_DIVISION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESS_UNIT_DIVISION:
            return {
                ...state,
                loading: true,
                singleBUDivision: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESS_UNIT_DIVISION_SUCCESS:
            return {
                ...state,
                loading: false,
                singleBUDivision: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case GET_BUSINESS_UNIT_DIVISION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_BUSINESS_UNIT_DIVISION:
            return {
                ...state,
                loading: true,
                BUDivision: null,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        case UPDATE_BUSINESS_UNIT_DIVISION_SUCCESS:
            return {
                ...state,
                loading: false,
                BUDivision: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: true
            };
        case UPDATE_BUSINESS_UNIT_DIVISION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                isCreated: false,
                isDeleted: false,
                isUpdated: false
            };
        default:
            return state;
    }
};

export default BUDivisionReducer;
